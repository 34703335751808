<template>
    <component
        :is="kitComponent"
        :value="value"
        :target="target"
        :title="title"
        :hasInput="hasInput"
        :min="min"
        :max="max"
        :options="options"
        v-model="currentValue"
        @change="handleChange"
    ></component>
</template>
<script>
import kitConfig from './kits.config.js';
export default {
    name: 'Kits',
    props: {
        title: String,
        type: String,
        target: String,
        value: {
            required: true
        },
        hasInput: true, //color picker 是否可以手动输入色号
        min: Number, //slider滑块的最小最大值
        max: Number,
        options: Array //Select option list
    },
    data() {
        return {
            currentType: this.type,
            currentValue: this.value
        };
    },
    watch: {
        type(val) {
            this.currentType = val;
        },
        value(val) {
            this.currentValue = val;
        }
    },
    provide() {
        return {
            root: this
        };
    },

    computed: {
        kitComponent() {
            return kitConfig.find(item => item.type === this.type).component;
        }
    },
    methods: {
        handleChange(val) {
            console.log('kits change', val);
            this.$emit('change', { type: this.target, value: val });
        }
    }
};
</script>