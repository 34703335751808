<template>
    <!--
        插件官网：https://summernote.org/
        此插件依赖Bootstrap
        当前插件依赖的Bootstrap样式已被修改为特殊对应版本，使其依赖标识为class：['description-wrap']。
    -->
    <div class="pt-summernote">
        <div ref="summernote" v-html="value"></div>
    </div>
</template>
<script>
// import 'summernote';
import 'summernote/dist/summernote-lite';
import 'summernote/dist/summernote-lite.css';

export default {
    name: 'ptRichtext',

    props: {
        code: [String, Number], //组件唯一标识
        value: String
    },

    data() {
        return {
            editor: null
        };
    },

    mounted() {
        const _this = this;
        const setting = {
            fontSizes: ['12', '13', '14', '15', '16', '18', '20', '22', '24', '36'],
            toolbar: [
                ['fontsize', ['fontsize']],
                ['style', ['bold', 'italic', 'underline', 'strikethrough']],
                ['para', ['height']],
                ['color', ['forecolor', 'backcolor']],
                ['inset', ['link']],
                ['misc', ['undo', 'redo']]
            ],
            height: 180,
            disableGrammar: true,
            spellCheck: false,
            // dialogsFade: true,
            disableDragAndDrop: true,
            dialogsInBody: true,
            popover: false
        };
        this.editor = $(this.$refs.summernote);
        console.log('this.editor', this.editor);

        this.editor.summernote && this.editor.summernote(setting);
        this.editor.on('summernote.change', function(contents, $editable) {
            _this.$emit('change', $editable);
        });
    },

    beforeDestroy() {
        this.editor && this.editor.summernote('destroy');
    },

    methods: {
        update() {
            this.$nextTick(() => {
                this.editor && this.editor.summernote('code', this.value);
            });
        }
    },

    watch: {
        code(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.update();
            }
        }
    }
};
</script>

<style lang="scss">
@import './bootstrap.css';

.pt-summernote {
    .note-editor.note-frame {
        // z-index: 3;

        [class^='note-icon-']:before,
        [class*=' note-icon-']:before {
            font-size: 12px;
        }

        .btn-sm,
        .btn-group-sm > .btn {
            padding: 3px 8px;
        }

        .note-para .dropdown-menu {
            left: auto;
            right: 0;
            min-width: 100px;
        }
    }
}

.note-modal {
    &-content {
        padding: 48px 80px;
        min-width: 460px;
        max-width: 80%;
        border-radius: 2px;
    }
    &-header {
        padding: 0;
        border: none;

        button.close {
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 0;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 16px;
        }
    }

    &-title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 26px;
        color: #091e42;
        text-align: center;
    }

    &-body {
        padding: 40px 0;

        label {
            margin-bottom: 8px !important;
            line-height: 1;
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            color: #091e42;
        }

        .note-input {
            font-family: 'Open Sans';
            -webkit-appearance: none;
            background-color: #ffffff;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: inherit;
            outline: none;
            padding: 0 15px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
            height: 32px;
            line-height: 32px;

            &:hover {
                border-color: #c0c4cc;
            }

            &:focus {
                border-color: #76ca20;
                outline: 0;
                box-shadow: none;
            }
        }

        .note-form-group {
            margin: 0;
        }

        .checkbox {
            margin: 0;

            label {
                line-height: 16px;
            }
        }
    }
    &-footer {
        height: auto;
        padding: 40px 0 0;
        border-top: 1px solid #ebecf0;

        .note-btn {
            min-width: 104px;
            float: none;
            padding: 0 20px;
            font-size: 12px;
            width: auto;
            height: 28px;
            line-height: 28px;
            border: none;
            background-color: #ff6f00;
            display: inline-block;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
            border-radius: 4px;
            border-width: 1px;
            border-style: solid;
            margin: 0;
            white-space: nowrap;
            cursor: pointer;
            outline: none;
            -webkit-appearance: none;
            box-sizing: border-box;
            transition: 0.2s linear;

            &:hover {
                background-color: #f06900;
            }
        }
    }
}
</style>
