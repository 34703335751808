import { Message, Notification } from 'element-ui';
import i18n from '@/assets/i18n';
import axios from 'axios';
import cache from './cache';
import { supplant } from '@/common/utils/string.utils';
import mockServer from './mock';

/**
 * 遍历resources映射文件,构造出一个service构造函数
 * @param configs resources映射文件 Array
 * @returns {Clazz}
 */
export default function (configs) {
    const apis = {};

    configs.forEach(function (config) {
        const envConfigs = process.env.VUE_APP_CONFIGS || {};
        const host = envConfigs.backend || '';
        const { name, url, method, query, isGraphql, cache } = Object.assign(
            { url: '/graphql', method: 'post', isGraphql: true, cache: false },
            config
        ); //默认为graphql

        /**
         * 动态构造方法
         * @param supplants Object 变量内插对象
         * @param data 调用该方法传入的值
         * @param customConfig 请求配置信息，例如：timeout等
         * @returns {*}
         */
        apis[name] = function (supplants, data = {}, customConfig = {}) {
            let params = {
                headers: {},
                method: method.toUpperCase(),
                cache,
                url: host + (supplants ? supplant(url, supplants) : url)
            };
            const methodKey = params.method === 'GET' ? 'params' : 'data';
            if (data) {
                params[methodKey] = isGraphql
                    ? {
                          operationName: name,
                          query,
                          variables: data
                      }
                    : data;
            }

            // 从缓存中获取
            if (params.method === 'GET' && cache) {
                const cacheKey = cache.generateKey(params);
                const cacheVal = cache.get(cacheKey);
                if (cacheVal !== null) {
                    console.log('get data from cache, cacheKey: ', cacheKey);
                    return Promise.resolve(cacheVal);
                }
            }

            // 扩展其他自定义字段
            customConfig && (params = Object.assign(params, customConfig));

            //标准化返回结果,使用reject接收error,resolve接收success
            return axios
                .request(params)
                .then(res => {
                    let resData = res.data;
                    const { code, message, status } = res.data || {};
                    if (status && status.toLowerCase() === 'error') {
                        const rj = {
                            code,
                            message,
                            data: res.data
                        };
                        // if (code === 500) {
                        //     // Notification.error({
                        //     //     dangerouslyUseHTMLString: true,
                        //     //     duration: 0,
                        //     //     position: 'bottom-right',
                        //     //     message: `${i18n.t('error.common_500')}`
                        //     // });
                        //     Message({
                        //         showClose: true,
                        //         message: i18n.t('error.common_500'),
                        //         type: 'error'
                        //     });
                        // }
                        return Promise.reject(rj);
                    }
                    // 设置缓存
                    if (method === 'GET' && cache) {
                        const cacheKey = cache.generateKey(params);
                        cache.set(cacheKey, res.data);
                        console.log('set data cache, cacheKey: ', cacheKey);
                    }

                    if (isGraphql) {
                        const { data } = res.data || {};

                        if (data) {
                            const keys = Object.keys(data || {});
                            resData = keys.length === 1 ? data[keys[0]] : data;
                        } else {
                            return Promise.reject({ code: 'NO_CONTENT' });
                        }
                    }
                    return Promise.resolve(resData);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        return Promise.reject(error);
                    }
                    if (error.response && error.response.data) {
                        return Promise.reject(error.response.data);
                    }
                    return Promise.reject(error);
                });
        };
    });

    return apis;
}
