<template>
    <header :class="$style.topnav" v-show="visibleMenu">
        <div :class="$style.logo">
            <pt-icon :icon="logo" :iconStyle="{ width: '28px', height: '28px' }"></pt-icon>
            <account :userInfo="userInfo"></account>
        </div>
        <div class="x-wrap" :class="$style.nav">
            <ul>
                <li v-for="(menu, $index) in menus" :key="menu.routerName">
                    <router-link
                        :to="{ name: menu.routerName }"
                        :key="$index"
                        v-slot="{ href, navigate, isActive }"
                        v-permission="{ ...menu.permission }"
                    >
                        <a :class="getClass(menu.routerName, isActive)" :href="href" @click="navigate">
                            <span>{{ menu.name }}</span>
                        </a>
                    </router-link>
                </li>
            </ul>
        </div>
        <div :class="$style.tools_container">
            <div v-if="timeRemain && isTrial" :class="$style.divider"></div>
            <el-popover
                v-if="timeRemain && isTrial"
                placement="bottom-end"
                width="560"
                trigger="hover"
                :popper-class="`${$style.popover_container} ${$style.free_trail_container}`"
                :open-delay="200"
                :close-delay="222"
            >
                <p :class="$style.trail_end_day">
                    {{ $t('menu.end_day', { timeRemain: timeRemain2 }) }}
                </p>
                <p :class="$style.trail_enjoy">{{ $t('menu.enjoy') }}</p>
                <li
                    :class="$style.trail_feature"
                    v-for="(feature, index) in advancedFeatures"
                    :key="index"
                    v-html="feature"
                />
                <p :class="$style.trail_end_tip">{{ $t('menu.end_tip') }}</p>
                <pt-button :class="$style.popover_btn" color="purple" size="medium" @click="contactUs">{{
                    $t('menu.contact_us')
                }}</pt-button>

                <p :class="$style.tools" v-if="timeRemain" slot="reference">
                    <span :class="$style.tools_text">{{ $t('menu.free_trail', { timeRemain: timeRemain }) }}</span>
                    <pt-icon
                        :class="$style.free_trail_icon"
                        icon="free-trail"
                        :iconStyle="{ width: '24px', height: '24px', marginLeft: '8px' }"
                    />
                </p>
            </el-popover>
        </div>
    </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { isSingleSpa } from '@/common/services/singleSpa.service';
import Account from '@/components/account/Account.vue';
import analyticsService from '@/common/services/analytics.service';
import permissionsConfig from '@/common/configs/permissions.config';
import intercomSerice from '@/common/services/intercom.service';
import { getCampaignReportRouteName } from '@/components/campaign/campaign.util';
import interactiveDemoService from '@/components/interactive-demo/interactive-demo.service';
import dayjs from 'dayjs';

export default {
    name: 'MainMenu',

    data() {
        const menus = [
            {
                name: this.$t('menu.users'),
                routerName: 'Users',
                icon: 'pt-icon--team',
                analytics: 'navigation_user',
                permission: {
                    keys: [permissionsConfig.asideMenuUserGroup],
                    canVisible: false
                }
            },
            {
                name: this.$t('menu.engagement'),
                routerName: getCampaignReportRouteName() === 'CampaignReport' ? 'Campaign' : 'Engagement',
                icon: 'pt-icon--engage',
                analytics: 'navigation_engage',
                permission: {
                    keys: [permissionsConfig.asideMenuEngage],
                    canVisible: false
                }
            },
            {
                name: this.$t('menu.goals'),
                routerName: 'Goals',
                icon: 'icon-manage-data',
                analytics: 'navigation_goal',
                permission: {
                    keys: [permissionsConfig.asideMenuSettings],
                    canVisible: false
                }
            },
            {
                name: this.$t('menu.data_settings'),
                routerName: 'DataSettings',
                icon: 'icon-manage-data',
                analytics: 'navigation_datasetting',
                permission: {
                    keys: [permissionsConfig.asideMenuSettings],
                    canVisible: false
                }
            }
        ];
        const advancedFeatures = [
            this.$t('menu.feature1'),
            this.$t('menu.feature2'),
            this.$t('menu.feature3'),
            this.$t('menu.feature4'),
            this.$t('menu.feature5')
        ];
        const { sid } = this.$route.query;
        const isDemoProfile = interactiveDemoService.isDemoProfile(sid);
        return {
            isDemoProfile,
            menus,
            timeRemain: null,
            advancedFeatures,
            showCongratulate: false,
            congratulateText: ''
        };
    },

    watch: {
        'packageInfo.endTime': {
            immediate: true,
            handler() {
                if (this.freeTrailTimer) clearInterval(this.freeTrailTimer);
                this.timeRemain = null;
                if (this.packageInfo?.endTime) {
                    this.refreshFreetrail();
                    this.freeTrailTimer = setInterval(() => this.refreshFreetrail(), 1000 * 60 * 60);
                }
            }
        },
        $route: {
            immediate: true,
            handler() {
                if (this.showCongratulate) this.showCongratulate = false;
                // this.getAllCollaborativeUser(); // 发现切换路由多次调用此接口，且没找到用到的地方 故注掉
            }
        }
    },
    computed: {
        ...mapState('main', ['userInfo', 'profileInfo', 'hideMainMenu', 'packageInfo']),

        visibleMenu() {
            return !isSingleSpa() && !this.hideMainMenu;
        },

        isTrial() {
            return this.packageInfo?.packageBaseConf?.packageType === 1;
        },

        logo() {
            return this.theme === 'blue' ? 'icon-logo' : 'pt-icon--overlay';
        },

        //theme: ['dark', 'blue', 'white']
        theme() {
            return 'dark';
        },

        currentIsSetup() {
            return ['Setup', 'CodeOverview', 'SetEvents', 'IdentifyUsers', 'CodeVerify'].includes(this.$route.name);
        },

        currentIsDataSetting() {
            return [
                'DataSettings',
                'EventSettings',
                'EventPropertySettings',
                'ConversionSettings',
                'UserPropertySettings'
            ].includes(this.$route.name);
        },

        timeRemain2() {
            if (!this.timeRemain) return null;
            return this.$locale.current() !== 'JP'
                ? this.timeRemain
                : this.timeRemain?.startsWith('残り')
                ? this.timeRemain.slice(2)
                : this.timeRemain.slice(0, 2);
        }
    },

    methods: {
        ...mapMutations('main', ['UPDATE_ASIDE_FOLDED']),
        ...mapActions('main', ['getAllCollaborativeUser']),
        getClass(routerName, isActive) {
            const currentIsDataSetting = [
                'DataSettings',
                'EventSettings',
                'EventPropertySettings',
                'ConversionSettings',
                'UserPropertySettings'
            ].includes(routerName);
            const classList = [this.$style.menuItem];
            (isActive ||
                (currentIsDataSetting && this.currentIsSetup) ||
                (routerName === 'Engagement' && this.$route.name === 'CampaignOnboarding')) &&
                classList.push(this.$style.active);
            return classList;
        },

        refreshFreetrail() {
            const expire = new Date(this.packageInfo?.endTime).getTime() - Date.now() <= 0;
            if (expire) {
                clearInterval(this.freeTrailTimer);
                this.timeRemain = null;
            } else this.timeRemain = this.getTimeRemainForNow();
        },

        getTimeRemainForNow() {
            const timeRemain = dayjs(new Date(this.packageInfo?.endTime))?.diff(new Date(), 'hour');
            if (timeRemain <= 24) return this.$t('menu.time_end');
            return this.$t('menu.time_remain', { remain: Math.floor(timeRemain / 24) });
        },

        contactUs() {
            intercomSerice?.contact();
        }
    },

    components: {
        Account
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.topnav {
    background-color: $pt-black-600;
    // border-bottom: 1px solid $pt-black-40;
    position: relative;
    height: $page-menu-height;
    min-width: $page-min-width;
    user-select: none;

    .nav {
        position: relative;
        height: 100%;
    }

    .logo {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);
        z-index: 10;
    }

    .tools_container {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        top: 0;
        right: 40px;
        z-index: 10;
        .divider {
            width: 1px;
            height: 32px;
            margin: 0 20px;
            background-color: $pt-black-600;
        }
    }

    .tools {
        display: flex;
        align-items: center;
        height: 100%;
        line-height: 100%;
        position: relative;
        color: $pt-black-90;
        cursor: pointer;
        &:hover {
            .tools_text {
                color: $pt-white;
            }
            .free_trail_icon {
                fill: #bd10e0;
            }

            :global {
                .el-progress-circle {
                    & > svg {
                        path:last-child {
                            stroke: $pt-black-600;
                        }
                    }
                }
            }
        }
    }

    ul {
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    li {
        @extend %flex-center;

        a {
            height: 100%;
            color: $pt-black-90;
            font-size: 13px;
            letter-spacing: 0;
            text-align: center;
            position: relative;
            padding: 0 20px;
            @extend %flex-center;
            font-weight: bold;

            &::after {
                content: '';
                width: 100%;
                height: 4px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $pt-green-60;
                opacity: 0;
            }

            &:hover {
                color: $pt-white;
                font-weight: bold;
            }

            &.active {
                color: $pt-white;
                font-weight: bold;

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

.popover_container {
    margin-top: 18px !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0 0 30px 0 rgba(9, 30, 66, 0.12) !important;
    text-align: center !important;

    &.free_trail_container {
        border-bottom-color: #bd10e0 !important;
        div:last-child {
            user-select: none !important;
            top: -8px !important;
            border-width: 8px !important;
            border-top-width: 0 !important;
            left: unset;
            right: 24px;
            border-bottom-color: #bd10e0 !important;
            &::after {
                border-bottom-color: #bd10e0 !important;
            }
        }
    }
}

.tools_text {
    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.congratulations_icon {
    margin: 48px 0 12px;
}

.congratulations_text {
    white-space: pre-line;
    font-size: 16px;
    line-height: 24px;
    color: $pt-white;
}

.free_trail_icon {
    fill: #bd10e0;
    transition: fill 0.5s ease;
}

.trail_end_day {
    margin-top: -1px;
    height: 88px;
    font-size: 20px;
    font-weight: 500;
    line-height: 88px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #bd10e0;
    color: #ffffff;
}

.trail_enjoy {
    margin: 26px 0 16px 60px;
    font-size: 13px;
    color: #091e42;
    text-align: left;
}

.trail_feature {
    margin-left: 60px;
    text-align: left;
    color: $pt-green-60 !important;
    & + & {
        margin-top: 6px;
    }
    > b {
        font-weight: 500;
        color: #091e42;
    }
}

.trail_end_tip {
    width: 448px;
    margin-left: 60px;
    margin-top: 16px;
    font-size: 13px;
    line-height: 19px;
    color: #091e42;
    text-align: left;
}

.popover_btn {
    margin-top: 24px;
    margin-bottom: 36px;
}
</style>
