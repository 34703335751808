"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVersionData = exports.useCampaignReport = void 0;
var campaignTest_apis_1 = require("@/apis/campaignTest.apis");
var index_1 = require("@/store/index");
var date_utils_1 = require("@/common/utils/date.utils");
var pValue_1 = require("@common/utils/pValue");
function formatConfigData(remoteData, versionID) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    // console.log('formatConfigData remoteData ====>', remoteData);
    var res = __assign(__assign({}, remoteData), { conditions: (remoteData === null || remoteData === void 0 ? void 0 : remoteData.conditions) || [], schedule: (remoteData === null || remoteData === void 0 ? void 0 : remoteData.schedule) || [], editorStatus: {
            devices: [],
            changes: []
        } });
    delete res.reportData;
    var searchVersion = versionID
        ? [(_a = remoteData.versions) === null || _a === void 0 ? void 0 : _a.find(function (version) { return version.id === versionID; })]
        : (_b = remoteData.versions) !== null && _b !== void 0 ? _b : [];
    if (versionID && ((_d = (_c = remoteData.versions) === null || _c === void 0 ? void 0 : _c.find(function (version) { return version.id === versionID; })) === null || _d === void 0 ? void 0 : _d.controlGroup) === true)
        searchVersion = (_e = remoteData.versions) !== null && _e !== void 0 ? _e : []; // 对照组也全搜
    if (searchVersion === null || searchVersion === void 0 ? void 0 : searchVersion.some(function (version) { return version.terminals.some(function (terminal) { return terminal.type === 1 && terminal.status === 1; }); }))
        res.editorStatus.devices.push('Mobile');
    if (searchVersion === null || searchVersion === void 0 ? void 0 : searchVersion.some(function (version) { return version.terminals.some(function (terminal) { return terminal.type === 2 && terminal.status === 1; }); }))
        res.editorStatus.devices.push('PC');
    if (searchVersion === null || searchVersion === void 0 ? void 0 : searchVersion.some(function (version) {
        return version.terminals.some(function (terminal) { var _a; return ((_a = terminal.popup) === null || _a === void 0 ? void 0 : _a.length) >= 1 && terminal.status === 1; });
    }))
        res.editorStatus.changes.push('Popup');
    if (searchVersion === null || searchVersion === void 0 ? void 0 : searchVersion.some(function (version) {
        return version.terminals.some(function (terminal) { var _a; return ((_a = terminal.inline) === null || _a === void 0 ? void 0 : _a.length) >= 1 && terminal.status === 1; });
    }))
        res.editorStatus.changes.push('Inline');
    if (searchVersion === null || searchVersion === void 0 ? void 0 : searchVersion.some(function (version) {
        return version.terminals.some(function (terminal) { var _a; return ((_a = terminal.stickybar) === null || _a === void 0 ? void 0 : _a.length) >= 1 && terminal.status === 1; });
    }))
        res.editorStatus.changes.push('Stickybar');
    if (res.editorStatus.changes.length === 0 &&
        (searchVersion === null || searchVersion === void 0 ? void 0 : searchVersion.some(function (version) { return version.terminals.some(function (terminal) { return terminal.code && terminal.status === 1; }); })))
        res.editorStatus.changes.push('CodeMode');
    if (!versionID)
        res.versionScreenshot = (_g = (_f = remoteData.versions) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.screenshot;
    else
        res.versionScreenshot = (_j = (_h = remoteData.versions) === null || _h === void 0 ? void 0 : _h.find(function (version) { return version.id === versionID; })) === null || _j === void 0 ? void 0 : _j.screenshot;
    return res;
}
function formatCreaterData(remoteData) {
    var _a, _b, _c, _d;
    var res = {
        updateBy: { count: remoteData === null || remoteData === void 0 ? void 0 : remoteData.updateBy },
        createBy: { count: remoteData === null || remoteData === void 0 ? void 0 : remoteData.createBy },
        updateTime: { count: (remoteData === null || remoteData === void 0 ? void 0 : remoteData.updateTime) ? (_a = new Date(remoteData === null || remoteData === void 0 ? void 0 : remoteData.updateTime)) === null || _a === void 0 ? void 0 : _a.format() : '--' },
        createTime: { count: (remoteData === null || remoteData === void 0 ? void 0 : remoteData.createTime) ? (_b = new Date(remoteData === null || remoteData === void 0 ? void 0 : remoteData.createTime)) === null || _b === void 0 ? void 0 : _b.format() : '--' },
        lastPauseTime: {
            count: (remoteData === null || remoteData === void 0 ? void 0 : remoteData.lastPauseTime) ? (_c = new Date(remoteData === null || remoteData === void 0 ? void 0 : remoteData.lastPauseTime)) === null || _c === void 0 ? void 0 : _c.format() : '--'
        },
        firstRunTime: { count: (remoteData === null || remoteData === void 0 ? void 0 : remoteData.firstRunTime) ? (_d = new Date(remoteData === null || remoteData === void 0 ? void 0 : remoteData.firstRunTime)) === null || _d === void 0 ? void 0 : _d.format() : '--' }
    };
    return res;
}
function formatCount(count) {
    if (typeof count !== 'number' || !count)
        return '0';
    return parseFloat(count === null || count === void 0 ? void 0 : count.toFixed(2)) + '';
}
function formatRate(rate) {
    var _a;
    if (typeof rate !== 'number' || !rate)
        return '0%';
    return ((_a = (rate * 100)) === null || _a === void 0 ? void 0 : _a.toFixed(2)) + '%';
}
function formatData(current, last) {
    var res = { count: '', rate: '' };
    res.count = formatCount(current.count ? current.count : 0);
    res.rate = formatRate(current.rate ? current.rate : 0);
    return res;
}
function formatVisitorData(remoteData, controlGroupData) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = remoteData.goals, goals = _j === void 0 ? [] : _j, reportData = remoteData.reportData, hasForm = remoteData.hasForm;
    var data = (_a = reportData === null || reportData === void 0 ? void 0 : reportData.userCount) !== null && _a !== void 0 ? _a : {};
    var res = {};
    for (var dataKey in data) {
        var key = dataKey;
        if (!data[key])
            res[key] = key === 'engageGoal' ? [] : { count: '0', rate: '0' };
        else {
            if (key === 'engageGoal') {
                res[key] = (_b = data[key]) === null || _b === void 0 ? void 0 : _b.map(function (goal) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                    var goalRes = __assign({ name: (_a = goals === null || goals === void 0 ? void 0 : goals.find(function (targetGoal) { return targetGoal.id === goal.id; })) === null || _a === void 0 ? void 0 : _a.name, id: goal.id }, formatData(goal === null || goal === void 0 ? void 0 : goal.current, goal.last));
                    if (controlGroupData) {
                        var controlVisitors = (_c = (_b = controlGroupData === null || controlGroupData === void 0 ? void 0 : controlGroupData.engageView) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.count;
                        var controlConversions = parseInt((_g = (_f = (_e = (_d = controlGroupData === null || controlGroupData === void 0 ? void 0 : controlGroupData.engageGoal) === null || _d === void 0 ? void 0 : _d.find(function (controlGoal) { return controlGoal.id === goal.id; })) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.count) !== null && _g !== void 0 ? _g : '0');
                        var controlRate = controlConversions / controlVisitors;
                        var variationVisitors = (_j = (_h = data === null || data === void 0 ? void 0 : data.engageView) === null || _h === void 0 ? void 0 : _h.current) === null || _j === void 0 ? void 0 : _j.count;
                        var variationConversions = parseInt((_o = (_m = (_l = (_k = data === null || data === void 0 ? void 0 : data.engageGoal) === null || _k === void 0 ? void 0 : _k.find(function (controlGoal) { return controlGoal.id === goal.id; })) === null || _l === void 0 ? void 0 : _l.current) === null || _m === void 0 ? void 0 : _m.count) !== null && _o !== void 0 ? _o : '0');
                        var variationRate = variationConversions / variationVisitors;
                        goalRes.lifting = !controlRate
                            ? '∞'
                            : (((variationRate - controlRate) / controlRate) * 100).toFixed(2) + '%';
                        if (parseInt(goalRes.lifting) > 0)
                            goalRes.lifting = '+' + goalRes.lifting;
                        goalRes.confidence = (0, pValue_1.default)({
                            controlVisitors: controlVisitors,
                            controlConversions: controlConversions,
                            variationVisitors: variationVisitors,
                            variationConversions: variationConversions
                        });
                    }
                    return goalRes;
                });
            }
            else if (key === 'avgVisitTime')
                res[key] = { count: date_utils_1.default.getDuration((_d = (_c = data[key]) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.count) };
            else if (key === 'engageSubmit')
                hasForm ? (res[key] = formatData((_e = data[key]) === null || _e === void 0 ? void 0 : _e.current, (_f = data[key]) === null || _f === void 0 ? void 0 : _f.last)) : null;
            else
                res[key] = formatData((_g = data[key]) === null || _g === void 0 ? void 0 : _g.current, (_h = data[key]) === null || _h === void 0 ? void 0 : _h.last);
        }
    }
    return res;
}
var useCampaignReport = function (where) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, err, campaigns, res;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!where.sid)
                    where.sid = (_b = index_1.default.state.main.profileInfo) === null || _b === void 0 ? void 0 : _b.sid;
                where.removeConfigFlag = true;
                return [4 /*yield*/, campaignTest_apis_1.default.getCampaigns({ where: where }, { isSwr: false })];
            case 1:
                _a = _c.sent(), err = _a[0], campaigns = _a[1];
                if (err)
                    return [2 /*return*/, false];
                res = [];
                return [4 /*yield*/, Promise.all(campaigns === null || campaigns === void 0 ? void 0 : campaigns.map(function (campaign) { return __awaiter(void 0, void 0, void 0, function () {
                        var campaignRes;
                        return __generator(this, function (_a) {
                            campaignRes = __assign(__assign(__assign({}, formatConfigData(campaign)), formatVisitorData(campaign)), formatCreaterData(campaign)
                            // displayConfig: campaign.displayConfig
                            );
                            res.push(campaignRes);
                            return [2 /*return*/];
                        });
                    }); }))];
            case 2:
                _c.sent();
                return [2 /*return*/, res];
        }
    });
}); };
exports.useCampaignReport = useCampaignReport;
var getVersionData = function (where, config) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _, versionsData, controlGroupId, controlGroupData, res, controlVersion;
    var _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                if (!where.sid)
                    where.sid = (_b = index_1.default.state.main.profileInfo) === null || _b === void 0 ? void 0 : _b.sid;
                return [4 /*yield*/, campaignTest_apis_1.default.getVersionData({ where: __assign(__assign({}, where), { sequentialFlag: true, multiVersion: true }) }, { isSwr: false })];
            case 1:
                _a = _g.sent(), _ = _a[0], versionsData = _a[1];
                versionsData === null || versionsData === void 0 ? void 0 : versionsData.sort(function (versionA, versionB) {
                    return config.versions.findIndex(function (version) { return versionA.versionID === version.id; }) -
                        config.versions.findIndex(function (version) { return versionB.versionID === version.id; });
                });
                controlGroupId = (_d = ((_c = config.versions.find(function (version) { return version.controlGroup; })) !== null && _c !== void 0 ? _c : config.versions[0])) === null || _d === void 0 ? void 0 : _d.id;
                controlGroupData = (_f = (_e = versionsData === null || versionsData === void 0 ? void 0 : versionsData.find(function (version) { return version.versionID === controlGroupId; })) === null || _e === void 0 ? void 0 : _e['reportData']) === null || _f === void 0 ? void 0 : _f['userCount'];
                res = [];
                return [4 /*yield*/, Promise.all(versionsData.map(function (version) { return __awaiter(void 0, void 0, void 0, function () {
                        var versionsData, campaignRes;
                        var _a, _b;
                        return __generator(this, function (_c) {
                            versionsData = config.versions.find(function (v) { return v.id === version.versionID; });
                            campaignRes = __assign(__assign(__assign({ belong: where.engageID, level: 1 }, formatConfigData(__assign(__assign(__assign({}, version), config), { id: version.versionID }), version.versionID)), formatVisitorData(__assign(__assign(__assign({}, version), config), { id: version.versionID, name: versionsData === null || versionsData === void 0 ? void 0 : versionsData.name }), controlGroupId !== version.versionID ? controlGroupData : undefined)), { name: versionsData === null || versionsData === void 0 ? void 0 : versionsData.name, versionDetail: __assign(__assign({}, versionsData), { asControlGroup: version.versionID === controlGroupId, rate: ((_b = (((_a = versionsData === null || versionsData === void 0 ? void 0 : versionsData.rate) !== null && _a !== void 0 ? _a : 0) * 100)) === null || _b === void 0 ? void 0 : _b.toFixed(0)) + '%' }) });
                            res.push(campaignRes);
                            return [2 /*return*/];
                        });
                    }); }))];
            case 2:
                _g.sent();
                controlVersion = res.findIndex(function (version) { var _a; return (_a = version === null || version === void 0 ? void 0 : version.versionDetail) === null || _a === void 0 ? void 0 : _a.asControlGroup; });
                if (controlVersion !== -1)
                    controlVersion = res.splice(controlVersion, 1)[0];
                res.unshift(controlVersion);
                return [2 /*return*/, res];
        }
    });
}); };
exports.getVersionData = getVersionData;
