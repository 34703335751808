<template>
    <ul class="el-select-group__wrap" v-show="visible">
        <li class="el-select-group__title">{{ label }}</li>
        <li>
            <ul class="el-select-group">
                <slot></slot>
            </ul>
        </li>
    </ul>
</template>

<script type="text/babel">
import Emitter from 'element-ui/src/mixins/emitter';

export default {
    mixins: [Emitter],

    name: 'PtOptionGroup',

    componentName: 'PtOptionGroup',

    props: {
        label: String,
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            visible: true
        };
    },

    watch: {
        disabled(val) {
            this.broadcast('PtOption', 'handleGroupDisabled', val);
        }
    },

    methods: {
        queryChange() {
            this.visible =
                this.$children &&
                Array.isArray(this.$children) &&
                this.$children.some(option => option.visible === true);
        }
    },

    created() {
        this.$on('queryChange', this.queryChange);
    },

    mounted() {
        if (this.disabled) {
            this.broadcast('PtOption', 'handleGroupDisabled', this.disabled);
        }
    }
};
</script>
