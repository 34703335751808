import engageApis from '@/apis/engage.apis';
export default {
    getTabCount({ rootState, commit }) {
        const sid = rootState.main.profileInfo.sid;
        return engageApis
            .countEngage(null, {
                where: {
                    sid: sid
                }
            })
            .then(
                countMeta => {
                    const allCount = countMeta.All;
                    const runCount = countMeta.Live;
                    commit('SET_COUNT_META', countMeta);

                    if (allCount) {
                        return Promise.resolve({
                            allCount,
                            runCount
                        });
                    } else {
                        return Promise.reject();
                    }
                },
                () => {
                    return Promise.reject();
                }
            );
    },

    setEngageDebugStatus({ commit }, status) {
        commit('SET_ENGAGE_DEBUG_STATUS', status);
    }
};
