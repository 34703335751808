import ImageConfig from '../image/image.config';
import TextConfig from '../text/text.config';
import ButtonConfig from '../button/button.config';
import uuidUtils from '@/common/utils/uuid.utils';
import cloneUtils from '@/common/utils/clone.utils';

const classicComponents = [
    {
        name: 'creative_editor.theme_image',
        code: 'image',
        config: ImageConfig
    },
    {
        name: 'creative_editor.theme_text',
        code: 'text',
        config: TextConfig
    },
    {
        name: 'creative_editor.theme_button',
        code: 'button',
        config: ButtonConfig
    }
];
const classicContent = classicComponents.map(item => {
    const { code, name, config } = item;
    const { content, settings } = config;
    let valueContent = cloneUtils.deep(content);
    let valueSettings = cloneUtils.deep(settings);
    if (code === 'image') {
        valueContent = cloneUtils.deep(content).map(item => {
            item.value.img = 'https://pteadpicture.ptengine.jp/image/1565677448470.png';
            item.value.width = 156;
            item.value.height = 156;
            item.value.borderRadius = 0;
            return item;
        });
    }
    valueSettings = Object.assign({}, settings, {
        noPadding: true,
        marginTop: 0,
        marginBottom: 0
    });
    return {
        code,
        name,
        value: {
            id: `pt_${uuidUtils.uuid_8bit()}`,
            content: valueContent,
            settings: valueSettings
        },
        default: true
    };
});

export default {
    hasSubModules: true, //是否包含子组件
    layoutOptions: [
        {
            code: 'imgLeft',
            icon: 'img-left'
        },
        {
            code: 'imgRight',
            icon: 'img-right'
        },
        {
            code: 'imgUp',
            icon: 'img-up'
        },
        {
            code: 'imgDown',
            icon: 'img-down'
        }
    ],
    alignmentOptions: [
        {
            code: 'left',
            icon: 'alignment-left'
        },
        {
            code: 'center',
            icon: 'alignment-center'
        },
        {
            code: 'right',
            icon: 'alignment-right'
        }
    ],
    marginOptions: {
        min: 0,
        max: 100
    },
    spaceingOptions: {
        min: 0,
        max: 300
    },
    content: classicContent,
    settings: {
        backgroundColor: '',
        layout: 'imgLeft',
        marginTop: 8,
        marginBottom: 8,
        spacing: 24,
        alignment: 'center',
        noPadding: false
    }
};
