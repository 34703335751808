import i18n from '@assets/i18n';
import cloneUtils from '@common/utils/clone.utils';

const inputDefaultContent = {
    placeholder: ''
};

const validatorRule = {
    url: [
        {
            type: 'required',
            trigger: 'blur',
            errMsg: i18n.t('error.common_3007')
        },
        {
            type: 'isUrl',
            trigger: 'blur',
            errMsg: i18n.t('error.common_3007')
        }
    ],
    tel: [
        {
            type: 'required',
            trigger: 'blur',
            errMsg: i18n.t('error.phone_format_err')
        },
        {
            type: 'isTelphone',
            trigger: 'blur',
            errMsg: i18n.t('error.phone_format_err')
        }
    ]
};

export default {
    defaultContent: cloneUtils.deep(inputDefaultContent),
    borderRadiusOptions: {
        min: 0,
        max: 100
    },
    borderWidthOptions: {
        min: 0,
        max: 50
    },
    fontSizeOptions: {
        min: 10,
        max: 30
    },
    heightOptions: {
        min: 22,
        max: 100
    },
    fontWeightOptions: [
        {
            code: 'normal',
            name: i18n.t('creative_editor.font_normal')
        },
        {
            code: 'bold',
            name: i18n.t('creative_editor.font_bold')
        }
    ],
    content: [
        {
            code: 'input',
            name: 'Input',
            value: cloneUtils.deep(inputDefaultContent),
            default: true
        }
    ],
    settings: {
        type: 'input',
        fontColor: '#000000',
        placeholderColor: '#b3bac5',
        fontSize: 14,
        fontWeight: 'normal',
        height: 32,
        backgroundColor: '#ffffff',
        borderRadius: 4,
        borderColor: '#c1c7d0',
        borderWidth: 1
    }
};
