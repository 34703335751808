import i18n from '@/assets/i18n';

/**
 * 数据结构说明：
 * audienceType: {
 *  name,
 *  code,
 *  subType, 次级类型 如visitPage 或 entryPage 的次级维度 pageType (page | combinedPage)
 *  defaultSubType, 默认的次级类型的值
 *  propertyType, 后台使用
 *  patternType, 字符类型: [String, Number, Date]
 *  propertyFunName, 属性字段，目前仅在用户属性中生效
 *  valueType, 前端配置的可选值列表，大部分后端请求返回
 *  eventType, 第四列可选项列表
 *  valueNeedQuery, 是否需要后端请求可选值列表
 *  allowCreate, 字符多选时，是否支持创建新条目
 *  authType, 需要校验的用户权限类型
 *  defaultCondition, 默认操作符
 *  hideRegion: 对某一地区隐藏该属性，主要用于中国区日本区兼容
 *  hideCondition: 是否隐藏操作符选择区域
 *  matchRange: 字段匹配类别: history_data/current_session/user_property
 * }
 */
export default {
    audienceType: [
        {
            label: 'engagement.engage_behavior',
            code: 'userBehaviour',
            options: [
                {
                    name: 'dimension.visitPage',
                    code: 'visitPage',
                    subType: 'pageType',
                    defaultSubType: 'page',
                    propertyType: 'STATIC',
                    patternType: 'STRING-D',
                    eventType: 'visitPage',
                    valueNeedQuery: true,
                    authType: 'audienceOngoingBehavior',
                    defaultCondition: '[()]',
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.event',
                    code: 'eventName',
                    propertyType: 'STATIC',
                    patternType: 'STRING-D',
                    eventType: 'event',
                    valueNeedQuery: true,
                    allowCreate: true,
                    authType: 'audienceOngoingBehavior',
                    defaultCondition: '==',
                    hideCondition: true,
                    cType: 'select',
                    matchRange: 'history_data'
                },
                // {
                //     name:'dimension.event_properties',
                //     code: 'eventPropertyName',
                //     propertyType: 'STATIC',
                //     patternType: 'STRING-D',
                //     eventType: 'eventProperties',
                //     valueNeedQuery: true,
                //     authType: 'audienceOngoingBehavior',
                //     defaultCondition: '==',
                //     hideCondition: true,
                //     matchRange: 'history_data'
                // },
                // {
                //     name:'dimension.user_properties',
                //     code: 'userPropertyName',
                //     propertyType: 'STATIC',
                //     patternType: 'STRING-D',
                //     eventType: 'userProperties',
                //     valueNeedQuery: true,
                //     authType: 'audienceOngoingBehavior',
                //     defaultCondition: '==',
                //     hideCondition: true,
                //     matchRange: 'history_data'
                // },
                {
                    name: 'dimension.visit_website',
                    code: 'visitWebsite',
                    propertyType: 'STATIC',
                    authType: 'audienceOngoingBehavior',
                    eventType: 'visitPage',
                    hideRegion: 'zh',
                    hide: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.visit_engage',
                    code: 'triggerEngage',
                    subType: 'engageType',
                    cType: 'campaign',
                    size: 'small',
                    defaultSubType: 'engage',
                    propertyType: 'STATIC',
                    patternType: 'STRING-D',
                    eventType: 'triggerEngage',
                    valueNeedQuery: true,
                    authType: 'audienceOngoingBehavior',
                    defaultCondition: '==',
                    matchRange: 'history_data'
                }
                // {
                //     name:'dimension.conversion',
                //     code: 'conversionName',
                //     propertyType: 'STATIC',
                //     patternType: 'STRING-D',
                //     eventType: 'conversion',
                //     valueNeedQuery: true,
                //     authType: 'audienceOngoingBehavior',
                //     matchRange: 'history_data'
                // },
                // {
                //     name:'dimension.engage',
                //     code: 'engageName',
                //     propertyType: 'STATIC',
                //     patternType: 'STRING-D',
                //     eventType: 'enage',
                //     valueNeedQuery: true,
                //     authType: 'audienceOngoingBehavior',
                //     matchRange: 'history_data'
                // }
            ]
        },
        {
            label: 'engagement.engage_behavior_properties',
            code: 'visitProperty',
            options: [
                {
                    name: 'dimension.visitType',
                    code: 'visitType',
                    propertyType: 'STATIC',
                    patternType: 'STRING-B',
                    valueType: 'visitType',
                    allowCreate: false,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.entryPage',
                    code: 'entryPage',
                    subType: 'pageType',
                    defaultSubType: 'page',
                    valueNeedQuery: true,
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    defaultCondition: '[()]',
                    matchRange: 'history_data'
                },

                {
                    name: 'dimension.sourceType',
                    code: 'sourceType',
                    propertyType: 'STATIC',
                    patternType: 'STRING-B',
                    valueType: 'sourceType',
                    allowCreate: false,
                    matchRange: 'history_data'
                },
                /*
                {
                    name:'dimension.searchKeyword',
                    code: 'searchKeyword',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                */
                {
                    name: 'dimension.sourceUrl',
                    code: 'sourceUrl',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    defaultCondition: '[()]',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.sourceHost',
                    code: 'sourceHost',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    defaultCondition: '[()]',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                /*
                {
                    name:'dimension.searchEngine',
                    code: 'searchEngine',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name:'dimension.social',
                    code: 'social',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                */
                {
                    name: 'dimension.campaignName',
                    code: 'utmCampaign',
                    propertyType: 'STATIC',
                    patternType: 'STRING-A',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.campaignSource',
                    code: 'utmSource',
                    propertyType: 'STATIC',
                    patternType: 'STRING-A',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.campaignMedium',
                    code: 'utmMedium',
                    propertyType: 'STATIC',
                    patternType: 'STRING-A',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.campaignTerm',
                    code: 'utmTerm',
                    propertyType: 'STATIC',
                    patternType: 'STRING-A',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.campaignContent',
                    code: 'utmContent',
                    propertyType: 'STATIC',
                    patternType: 'STRING-A',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.deviceType',
                    code: 'terminalType',
                    propertyType: 'STATIC',
                    patternType: 'STRING',
                    patternType: 'STRING-B',
                    valueType: 'terminalType',
                    allowCreate: false,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.os',
                    code: 'os',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.osVersion',
                    code: 'osVersion',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.browser',
                    code: 'browser',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.browserVersion',
                    code: 'browserVersion',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.resolution',
                    code: 'resolution',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.terminalBrand',
                    code: 'terminalBrand',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.countryRegion',
                    code: 'country',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                },
                {
                    name: 'dimension.region',
                    code: 'region',
                    propertyType: 'STATIC',
                    patternType: 'STRING-C',
                    valueNeedQuery: true,
                    matchRange: 'history_data'
                }
                // {
                //     name:'dimension.city',
                //     code: 'city',
                //     propertyType: 'STATIC',
                //     patternType: 'STRING-C',
                //     valueNeedQuery: true
                // }
            ]
        }
    ],
    audienceSubType: {
        pageType: [
            {
                code: 'page',
                name: 'dimension.include_params',
                tip: 'dimension.include_params_tip'
            },
            {
                code: 'combinedPage',
                name: 'dimension.exclude_params',
                tip: 'dimension.exclude_params_tip'
            }
        ]
    },
    audienceCondition: {
        string: [
            {
                cType: 'multiple',
                code: '[()]',
                hasValue: true,
                name: 'common.include'
            },
            {
                cType: 'multiple',
                code: '![()]',
                hasValue: true,
                name: 'common.exclude'
            },
            {
                defaultValue: true,
                cType: 'multiple',
                code: '==',
                hasValue: true,
                name: 'common.exact_match'
            },
            {
                cType: 'multiple',
                code: '!=',
                hasValue: true,
                name: 'common.not_equal'
            },
            // {
            //     cType: 'input',
            //     code: '[(',
            //     hasValue: true,
            //     name:'common.head_match'
            // },
            // { cType: 'input', code: ']', hasValue: true, name:'common.end_match' },
            // { cType: 'input', code: 'Rex', hasValue: true, name:'common.regex' },
            { code: '*', hasValue: false, name: 'common.not_empty' },
            { code: '!*', hasValue: false, name: 'common.empty' }
        ],
        number: [
            { defaultValue: true, cType: 'inputNumber', code: '>', name: '>', hasValue: true },
            { cType: 'inputNumber', code: '<', name: '<', hasValue: true },
            { cType: 'inputNumber', code: '>=', name: '≥', hasValue: true },
            { cType: 'inputNumber', code: '<=', name: '≤', hasValue: true },
            { cType: 'inputNumber', code: '!=', name: '≠', hasValue: true },
            { cType: 'inputNumber', code: '==', name: '＝', hasValue: true },
            {
                cType: 'inputDobuleNumber',
                code: 'between',
                name: 'common.number_between',
                hasValue: true
            },
            { code: '*', name: 'common.not_empty', hasValue: false },
            { code: '!*', name: 'common.number_empty', hasValue: false }
        ],
        time: [
            {
                type: 'none',
                code: 'today',
                hasValue: false,
                name: 'common.date_today'
            },
            {
                cType: 'date',
                type: 'absolute',
                code: '>=',
                hasValue: true,
                name: 'common.date_after'
            },
            {
                cType: 'date',
                type: 'absolute',
                code: '<=',
                hasValue: true,
                name: 'common.date_before'
            },
            {
                defaultValue: true,
                cType: 'date',
                type: 'relative',
                code: '==',
                hasValue: true,
                name: 'common.date_on'
            },
            {
                cType: 'date',
                type: 'absolute',
                code: '!=',
                hasValue: true,
                name: 'common.date_not_on'
            },
            {
                cType: 'inputNumber',
                type: 'relative',
                code: '>==',
                hasValue: true,
                name: 'common.date_more_than'
            },
            {
                cType: 'inputNumber',
                type: 'relative',
                code: '<==',
                hasValue: true,
                name: 'common.date_less_than'
            },
            {
                cType: 'inputNumber',
                type: 'relative',
                code: '===',
                hasValue: true,
                name: 'common.date_exactly_for'
            },
            {
                cType: 'dobuleDate',
                code: 'between',
                hasValue: true,
                name: 'common.date_between'
            },
            { type: 'none', code: '*', name: 'common.not_empty', hasValue: false },
            { type: 'none', code: '!*', name: 'common.empty', hasValue: false }
        ]
    },
    goalEventCondition: {
        string: [
            {
                cType: 'multiple',
                code: '[()]',
                hasValue: true,
                name: 'common.include'
            },
            // {
            //     cType: 'multiple',
            //     code: '![()]',
            //     hasValue: true,
            //     name:'common.exclude'
            // },
            {
                defaultValue: true,
                cType: 'multiple',
                code: '==',
                hasValue: true,
                name: 'common.exact_match'
            }
            // {
            //     cType: 'multiple',
            //     code: '!=',
            //     hasValue: true,
            //     name:'common.not_equal'
            // },
            // {
            //     cType: 'input',
            //     code: '[(',
            //     hasValue: true,
            //     name:'common.head_match'
            // },
            // { cType: 'input', code: ']', hasValue: true, name:'common.end_match' },
            // { cType: 'input', code: 'Rex', hasValue: true, name:'common.regex' },
            // { code: '*', hasValue: false, name:'common.not_empty' },
            // { code: '!*', hasValue: false, name:'common.empty' }
        ],
        number: [
            { cType: 'inputNumber', code: '>', name: '>', hasValue: true },
            { cType: 'inputNumber', code: '<', name: '<', hasValue: true },
            { cType: 'inputNumber', code: '>=', name: '≥', hasValue: true },
            { cType: 'inputNumber', code: '<=', name: '≤', hasValue: true },
            // { cType: 'inputNumber', code: '!=', name: '≠', hasValue: true },
            { defaultValue: true, cType: 'inputNumber', code: '==', name: '＝', hasValue: true },
            {
                cType: 'inputDobuleNumber',
                code: 'between',
                name: 'common.number_between',
                hasValue: true
            }
            // { code: '*', name:'common.not_empty', hasValue: false },
            // { code: '!*', name:'common.empty', hasValue: false }
        ],
        time: [
            // {
            //     type: 'none',
            //     code: 'today',
            //     hasValue: false,
            //     name:'common.date_today'
            // },
            {
                defaultValue: true,
                cType: 'date',
                type: 'absolute',
                code: '>=',
                hasValue: true,
                name: 'common.date_after'
            },
            {
                cType: 'date',
                type: 'absolute',
                code: '<=',
                hasValue: true,
                name: 'common.date_before'
            },
            {
                cType: 'date',
                type: 'relative',
                code: '==',
                hasValue: true,
                name: 'common.date_on'
            },
            // {
            //     cType: 'date',
            //     type: 'absolute',
            //     code: '!=',
            //     hasValue: true,
            //     name:'common.date_not_on'
            // },
            // {
            //     cType: 'inputNumber',
            //     type: 'relative',
            //     code: '>==',
            //     hasValue: true,
            //     name:'common.date_more_than'
            // },
            // {
            //     cType: 'inputNumber',
            //     type: 'relative',
            //     code: '<==',
            //     hasValue: true,
            //     name:'common.date_less_than'
            // },
            // {
            //     cType: 'inputNumber',
            //     type: 'relative',
            //     code: '===',
            //     hasValue: true,
            //     name:'common.date_exactly_for'
            // },
            {
                cType: 'dobuleDate',
                code: 'between',
                hasValue: true,
                name: 'common.date_between'
            }
            // {
            //     type: 'none',
            //     code: '*',
            //     name:'common.not_empty',
            //     hasValue: false
            // },
            // {
            //     type: 'none',
            //     code: '!*',
            //     name:'common.empty',
            //     hasValue: false
            // }
        ]
    },
    audienceValue: {
        terminalType: [
            { code: '2', name: 'common.pc' },
            { code: '1', name: 'common.smartphone' },
            { code: '4', name: 'common.tablet' }
        ],
        visitType: [
            { code: 'NV', name: 'common.new_visits' },
            { code: 'RV', name: 'common.returning_visits' }
        ],
        sourceType: [
            { code: 'SearchEngine', name: 'dimension.searchEngine' },
            { code: 'Referral', name: 'dimension.referral' },
            { code: 'Campaign', name: 'dimension.campaign' },
            { code: 'SocialSite', name: 'dimension.socialMedia' },
            { code: 'Direct', name: 'dimension.direct' }
        ]
    },
    audienceEvent: {
        event: [
            {
                defaultValue: true,
                code: 'hasOccured',
                hasTimes: true,
                name: 'dimension.has_occured'
            },
            {
                code: 'hasNotOccured',
                name: 'dimension.has_not_occured'
            }
        ],
        triggerEngage: [
            {
                defaultValue: true,
                code: 'hasOccured',
                hasTimes: false,
                name: 'dimension.has_read'
            },
            {
                code: 'hasNotOccured',
                name: 'dimension.has_not_read'
            }
        ],
        visitPage: [
            {
                defaultValue: true,
                code: 'hasOccured',
                hasTimes: true,
                name: 'dimension.has_visited'
            },
            {
                code: 'hasNotOccured',
                name: 'dimension.has_not_visited'
            }
        ],
        conversion: [
            {
                defaultValue: true,
                code: 'hasCompleted',
                name: 'dimension.has_completed'
            },
            {
                code: 'hasNotCompleted',
                name: 'dimension.has_not_completed'
            }
        ],
        enage: [
            {
                defaultValue: true,
                code: 'hasViewed',
                name: 'dimension.has_viewed'
            },
            {
                code: 'hasNotViewed',
                name: 'dimension.has_not_viewed'
            },
            { code: 'hasClicked', name: 'dimension.has_clicked' },
            {
                code: 'hasNotClicked',
                name: 'dimension.has_not_clicked'
            },
            { code: 'hasClosed', name: 'dimension.has_closed' },
            {
                code: 'hasNotClosed',
                name: 'dimension.has_not_closed'
            }
        ],
        visitTime: [
            { cType: 'inputNumber', code: '>', name: '>' },
            { cType: 'inputNumber', code: '<', name: '<' },
            { defaultValue: true, cType: 'inputNumber', code: '>=', name: '≥' },
            { cType: 'inputNumber', code: '<=', name: '≤' },
            // { cType: 'inputNumber', code: '!=', name: '≠' },
            { cType: 'inputNumber', code: '==', name: '＝' },
            {
                cType: 'inputNumber',
                code: 'between',
                name: 'dimension.range_between'
            }
        ]
    },
    audienceTargetHistory: [
        /*        {
            name:'dimension.current_session',
            code: 'current_session',
            defaultValue: true,
            matchRange: 'current_session'
        }, */
        {
            name: 'dimension.today',
            code: 'today',
            matchRange: 'history_data',
            authType: 'audienceOngoingBehavior'
        },
        {
            name: 'dimension.thisweek',
            code: 'thisWeek',
            matchRange: 'history_data',
            authType: 'audienceOngoingBehavior'
        },
        {
            name: 'dimension.thismonth',
            code: 'thisMonth',
            matchRange: 'history_data',
            authType: 'audienceOngoingBehavior'
        },
        {
            name: 'dimension.lastdays',
            code: 'lastDays',
            matchRange: 'history_data',
            defaultValue: true,
            cType: 'inputNumber',
            authType: 'audienceOngoingBehavior'
        },
        {
            name: 'dimension.since',
            code: 'since',
            matchRange: 'history_data',
            cType: 'date',
            authType: 'audienceOngoingBehavior'
        },
        {
            name: 'dimension.on',
            code: 'on',
            matchRange: 'history_data',
            cType: 'date',
            authType: 'audienceOngoingBehavior'
        },
        {
            name: 'dimension.between',
            code: 'between',
            matchRange: 'history_data',
            cType: 'date',
            authType: 'audienceOngoingBehavior'
        }
    ],
    //默认用户属性列表
    staticUserProperty: [
        'first seen',
        'last active',
        'country',
        'region',
        'city',
        'user id',
        'os',
        'browser',
        'initial referrer',
        'initial referring domain',
        'total sessions'
    ],
    guideInfo: [
        {
            code: 'anonymous',
            guideType: 'anonymous_users',
            name: 'users.anonymous_name',
            info: 'users.anonymous_info',
            description: 'users.learn_more',
            definition: 'users.anonymous_definition',
            target: 'users.anonymous_target',
            measure: 'users.anonymous_measure',
            icon: 'users-a-details',
            background: 'users-a'
        },
        {
            code: 'known',
            guideType: 'known_users',
            name: 'users.known_name',
            info: 'users.known_info',
            description: 'users.learn_more',
            definition: 'users.known_definition',
            target: 'users.known_target',
            measure: 'users.known_measure',
            icon: 'users-b-details',
            background: 'users-b'
        },
        {
            code: 'loyal',
            guideType: 'loyal_users',
            name: 'users.loyal_name',
            info: 'users.loyal_info',
            description: 'users.learn_more',
            definition: 'users.loyal_definition',
            target: 'users.loyal_target',
            measure: 'users.loyal_measure',
            icon: 'users-c-details',
            background: 'users-c'
        },
        {
            code: 'custom',
            default: true,
            guideType: 'custom_users',
            name: 'users.new_name',
            title: 'users.new_title',
            description: 'users.new_description',
            info: 'users.new_info',
            definition: 'users.new_definition',
            definition_title: 'users.new_description_title',
            target: 'users.new_target',
            target_title: 'users.new_target_title',
            measure: 'users.new_measure',
            measure_title: 'users.new_measure_title',
            icon: 'icon-add'
        }
    ],
    userListCsvHeader: [
        {
            code: 'name',
            name: 'name',
            type: 'CUSTOM',
            dataType: 'string',
            patternType: 'STRING'
        },
        {
            code: 'email',
            name: 'email',
            type: 'CUSTOM',
            dataType: 'string',
            patternType: 'STRING'
        },
        {
            code: 'initial referring domain',
            name: 'initial referring domain',
            type: 'STATIC',
            dataType: 'string',
            patternType: 'STRING'
        },
        {
            code: 'distinct user ID',
            name: 'distinct user ID',
            type: 'STATIC',
            dataType: 'string',
            patternType: 'STRING'
        },
        {
            code: 'last active',
            name: 'last active',
            type: 'STATIC',
            dataType: 'string',
            patternType: 'TIME'
        },
        {
            code: 'first seen',
            name: 'first seen',
            type: 'STATIC',
            dataType: 'string',
            patternType: 'TIME'
        },
        {
            code: 'city',
            name: 'city',
            type: 'STATIC',
            dataType: 'string',
            patternType: 'STRING'
        }
    ]
};
