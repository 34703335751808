"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var CLOSE_EVENT = 'close';
var OPEN_EVENT = 'open';
var CLOSED_EVENT = 'closed';
var OPENED_EVENT = 'opened';
var CONFIRM_EVENT = 'confirm';
var UPDATE_MODEL_EVENT = 'update:modelValue';
exports.default = (0, composition_api_1.defineComponent)({
    name: 'PtDialog',
    props: {
        appendToBody: {
            type: Boolean,
            default: false
        },
        beforeClose: {
            type: Function
        },
        destroyOnClose: {
            type: Boolean,
            default: false
        },
        center: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: ''
        },
        customBodyHeight: {
            type: String,
            default: '50vh'
        },
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        closeOnPressEscape: {
            type: Boolean,
            default: false
        },
        modal: {
            type: Boolean,
            default: true
        },
        showClose: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        openDelay: {
            type: Number,
            default: 0
        },
        closeDelay: {
            type: Number,
            default: 0
        },
        modelValue: {
            type: Boolean,
            required: true
        },
        modalClass: String,
        width: {
            type: String
        },
        size: {
            type: String,
            default: 'medium'
        },
        hasScroll: {
            type: Boolean,
            default: false
        },
        iconKey: {
            type: String
        },
        cancelButtonInfo: {
            type: Object,
            default: function () { return ({
                color: 'darkblue'
            }); }
        },
        cancelText: {
            type: String
        },
        showCancelButton: {
            type: Boolean,
            default: true
        },
        confirmButtonInfo: {
            type: Object,
            default: function () { return ({
                color: 'green'
            }); }
        },
        confirmText: {
            type: String
        },
        showConfirmButton: {
            type: Boolean,
            default: true
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        hasHeader: {
            type: Boolean,
            default: true
        },
        zIndex: {
            type: Number
        }
    },
    emits: [OPEN_EVENT, OPENED_EVENT, CLOSE_EVENT, CLOSED_EVENT, UPDATE_MODEL_EVENT, CONFIRM_EVENT],
    setup: function (props, ctx) {
        var dialogRef = (0, composition_api_1.ref)(null);
        var dialog = (0, composition_api_1.ref)();
        function useDialog(props, ctx, targetRef) {
            var visible = (0, composition_api_1.ref)(false);
            var closed = (0, composition_api_1.ref)(false);
            var openTimer = (0, composition_api_1.ref)(null);
            var closeTimer = (0, composition_api_1.ref)(null);
            var rendered = (0, composition_api_1.ref)(false);
            var modalRef = (0, composition_api_1.ref)(null);
            var normalizeWidth = function (width) {
                if (typeof width == 'number')
                    return "".concat(width, "px");
                else
                    return width;
            };
            var style = (0, composition_api_1.computed)(function () {
                var style = {};
                if (!props.fullscreen) {
                    if (props.width) {
                        style.width = normalizeWidth(props.width);
                    }
                }
                return style;
            });
            function handleConfirm() {
                ctx.emit(CONFIRM_EVENT);
            }
            function afterEnter() {
                ctx.emit(OPENED_EVENT);
            }
            function afterLeave() {
                ctx.emit(CLOSED_EVENT);
                ctx.emit(UPDATE_MODEL_EVENT, false);
                if (props.destroyOnClose) {
                    rendered.value = false;
                }
            }
            function beforeLeave() {
                ctx.emit(CLOSE_EVENT);
            }
            function clearTimer(timer) {
                clearTimeout(timer.value);
                timer.value = null;
            }
            function open() {
                clearTimer(closeTimer);
                clearTimer(openTimer);
                if (props.openDelay && props.openDelay > 0) {
                    openTimer.value = window.setTimeout(function () {
                        openTimer.value = null;
                        doOpen();
                    }, props.openDelay);
                }
                else {
                    doOpen();
                }
            }
            function close() {
                clearTimer(openTimer);
                clearTimer(closeTimer);
                if (props.closeDelay && props.closeDelay > 0) {
                    closeTimer.value = window.setTimeout(function () {
                        closeTimer.value = null;
                        doClose();
                    }, props.closeDelay);
                }
                else {
                    doClose();
                }
            }
            function hide(shouldCancel) {
                if (shouldCancel)
                    return;
                closed.value = true;
                visible.value = false;
            }
            function handleClose() {
                if (props.beforeClose) {
                    props.beforeClose(hide);
                }
                else {
                    close();
                    ctx.emit(CLOSE_EVENT);
                }
            }
            function onModalClick() {
                if (props.closeOnClickModal) {
                    handleClose();
                }
            }
            function doOpen() {
                if (typeof window === 'undefined') {
                    return;
                }
                visible.value = true;
            }
            function doClose() {
                var _a;
                visible.value = false;
                if (props.appendToBody)
                    (_a = dialog.value) === null || _a === void 0 ? void 0 : _a.remove();
            }
            (0, composition_api_1.watch)(function () { return props.modelValue; }, function (val) {
                if (val) {
                    closed.value = false;
                    open();
                    rendered.value = true; // enables lazy rendering
                    ctx.emit(OPEN_EVENT);
                    (0, composition_api_1.nextTick)(function () {
                        if (targetRef.value) {
                            targetRef.value.scrollTop = 0;
                        }
                    });
                }
                else {
                    if (visible.value) {
                        close();
                    }
                }
            });
            (0, composition_api_1.onMounted)(function () {
                if (props.modelValue) {
                    visible.value = true;
                    rendered.value = true; // enables lazy rendering
                    open();
                }
                if (props.appendToBody) {
                    document.body.appendChild(dialog.value);
                }
            });
            return {
                handleConfirm: handleConfirm,
                afterEnter: afterEnter,
                afterLeave: afterLeave,
                beforeLeave: beforeLeave,
                handleClose: handleClose,
                onModalClick: onModalClick,
                closed: closed,
                dialogRef: dialogRef,
                style: style,
                rendered: rendered,
                modalRef: modalRef,
                visible: visible
            };
        }
        return __assign(__assign({}, useDialog(props, ctx, dialogRef)), { dialogRef: dialogRef, dialog: dialog });
    }
});
