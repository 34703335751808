<template>
    <div :class="['page-header', hasBorder && 'page-header--border']">
        <div class="x-wrap" :class="[size === 'full' && 'x-wrap--full']">
            <div class="page-header_back" v-if="visibleBack">
                <a class="page-header_link" @click="handleBackClick">
                    <pt-icon
                        slot="icon"
                        icon="pt-icon--back"
                        :icon-style="{
                            width: '14px',
                            height: '14px',
                            fill: '#344563'
                        }"
                    ></pt-icon>
                    <pt-link type="text" color="black" dom-type="a">
                        <span>{{ backText }}</span>
                    </pt-link>
                </a>

                <slot name="tools"></slot>
            </div>

            <div class="page-header_title" v-if="visibleTitle">
                <div class="page-header_title-left">
                    <slot name="title">
                        <h1>
                            {{ title }}
                            <slot name="subTitle">
                                <p v-if="subTitle">{{ subTitle }}</p>
                            </slot>
                        </h1>
                    </slot>
                    <div class="page-header_left-tools">
                        <slot name="leftTools"></slot>
                    </div>
                </div>
                <div class="page-header_title-right">
                    <slot name="rightTools"></slot>
                </div>
            </div>

            <div class="page-header_tabs" v-if="visibleTab">
                <slot name="tabs"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',

    props: {
        title: String,
        subTitle: String,
        size: {
            type: String,
            default: 'default',
            enum: ['default', 'full']
        },
        visibleTitle: {
            type: Boolean,
            default: function() {
                return true;
            }
        },
        visibleBack: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        visibleTab: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        backText: {
            type: String,
            default: function() {
                return this.$t('common.back');
            }
        },
        backRouter: Object,
        historyBack: {
            type: Boolean,
            default: false
        },
        hasBorder: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            routeFrom: this.$route.params.from && this.$route.params.from === 'engage'
        };
    },
    methods: {
        handleBackClick() {
            this.backRouter ? this.$router.push(this.backRouter) : this.$emit('back');
        }
    }
};
</script>

<style lang="scss">
@import '@/styles/import.scss';

.page-header {
    background-color: $pt-white;
    position: relative;
    margin-bottom: 32px;

    &--border {
        border-bottom: 1px solid $pt-black-40;
    }

    &_back {
        height: 56px;
        display: flex;
        align-items: center;

        .link {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }
    }

    &_title {
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-size: 20px;
            font-weight: 700;
            color: $pt-black-600;
            width: 100%;
            position: relative;

            p {
                margin-top: 2px;
                color: $pt-black-600;
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 18px;
                position: absolute;
                top: 100%;
            }
        }

        &-left {
            margin-right: 20px;
            display: flex;
            align-items: center;
            flex: 1;
        }

        &-right {
            a {
                display: block;
            }
        }
    }

    &_left-tools {
        margin-left: 30px;
    }

    &_tabs {
        height: 48px;
        display: flex;
        justify-content: flex-start;

        .pt-tabs__nav {
            padding: 0;
            border-bottom: none;
        }
    }
}
</style>
