"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultOptions = void 0;
exports.defaultOptions = {
    groupName: undefined,
    behaviour: 'move',
    orientation: 'vertical',
    getChildPayload: undefined,
    animationDuration: 250,
    autoScrollEnabled: true,
    shouldAcceptDrop: undefined,
    shouldAnimateDrop: undefined,
};
