import i18n from '@assets/i18n';

const jpDefaultImg = 'https://pteadpicture.ptengine.cn/image/1565677506774.png';
const textDefaultContent =
    "<p style='line-height: 1.4;'><span style='font-size: 14px;'>Add a small description for your promo, explain its features, benefits and advantages.</span></p><p style='line-height: 1.4;'><b><span style='font-size:24px;'>￥999</span></b></p>";

const slideDefaultContent = {
    img: jpDefaultImg,
    imgName: '',
    imgWidth: 0,
    imgHeight: 0,
    width: 88,
    height: 88, //高度按比例生成
    sizeLock: true,
    text: textDefaultContent
};

export default {
    slideDefaultContent,
    numberOptions: [
        {
            code: 'two',
            name: '2'
        },
        {
            code: 'three',
            name: '3',
            default: true
        },
        {
            code: 'four',
            name: '4'
        },
        {
            code: 'five',
            name: '5'
        },
        {
            code: 'six',
            name: '6'
        },
        {
            code: 'seven',
            name: '7'
        },
        {
            code: 'eight',
            name: '8'
        }
    ],
    layoutOptions: [
        {
            code: 'imgLeft',
            icon: 'slide-img-left'
        },
        {
            code: 'imgRight',
            icon: 'slide-img-right'
        },
        {
            code: 'imgUp',
            icon: 'slide-img-up'
        },
        {
            code: 'imgDown',
            icon: 'slide-img-down'
        }
    ],
    alignmentOptions: [
        {
            code: 'left',
            icon: 'alignment-left'
        },
        {
            code: 'center',
            icon: 'alignment-center'
        },
        {
            code: 'right',
            icon: 'alignment-right'
        }
    ],
    switchStyleOptions: [
        {
            code: 'side',
            name: i18n.t('creative_editor.slides_style_side'),
            default: true
        },
        {
            code: 'bottom',
            name: i18n.t('creative_editor.slides_style_bottom')
        }
    ],
    playSpeedOptions: {
        min: 0,
        max: 99
    },
    content: Array.from({ length: 3 }, () =>
        Object.assign({}, slideDefaultContent)
    ),
    settings: {
        autoPlay: false,
        alignment: 'left',
        number: 'three',
        layout: 'imgLeft',
        switchStyle: 'side',
        playSpeed: 3
    },

    //编辑器内的状态管理，保存时删除
    editorState: {
        activeContentIndex: 0 //当前选中的slide序号
    }
};
