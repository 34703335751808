export default {
    UPDATE_USER_GROUP_INFO(state, userGroupInfo) {
        state.userGroupInfo = userGroupInfo;
    },
    UPDATE_USER_GROUP_NAME(state, name) {
        state.userGroupName = name;
    },
    UPDATE_USER_PROERTIES(state, properties) {
        state.userProperties = properties;
    }
};
