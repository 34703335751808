import baseConfig from '@/common/configs/base.config';
import validator from 'validator';

// 'use strict';
function PT() {
    var that = this;
    this.testAuth = [];
    this.authorityObj = {
        myself: {}, // 自身档案权限【创建者】
        sidAuth: {}
        // 管理档案权限【管理员、查看者】
    };

    // 全局变量, 全局配置
    this.global = Object.create(
        {
            setLocalStorage: function(key, value) {
                window.localStorage[key] = value;
                this['_' + key] = value;
            }
        },
        {
            // 版本zh, en, jp
            // 20161227,将项目中所有lang替换为region.为使登录中的用户不受影响,咱做下述处理
            region: {
                enumerable: true,
                get: function() {
                    return this._region || window.localStorage.region || window.localStorage.lang;
                },
                set: function(val) {
                    this.setLocalStorage('region', val);
                }
            },

            // 模式 dev, test, prod
            pattern: {
                enumerable: true,
                get: function() {
                    return this._pattern || window.localStorage.pattern;
                },
                set: function(val) {
                    this.setLocalStorage('pattern', val);
                }
            },
            //是否初次登陆
            firstLogin: {
                enumerable: true,
                get: function() {
                    return this._firstLogin || window.localStorage.firstLogin;
                },
                set: function(val) {
                    this.setLocalStorage('firstLogin', val);
                }
            },

            //用户id
            uid: {
                enumerable: true,
                get: function() {
                    return this._uid || window.localStorage[baseConfig.ACCOUNT_ID];
                },
                set: function(val) {
                    this.setLocalStorage(baseConfig.ACCOUNT_ID, val);
                }
            },

            //用户登陆邮箱
            useremail: {
                enumerable: true,
                get: function() {
                    return this._useremail || window.localStorage.useremail;
                },
                set: function(val) {
                    this.setLocalStorage('useremail', val);
                }
            },
            // 设置中心接口id(字符类型)
            sid: {
                enumerable: true,
                get: function() {
                    return this._sid || window.localStorage[baseConfig.LAST_PROFILE_ID];
                },
                set: function(val) {
                    this.setLocalStorage([baseConfig.LAST_PROFILE_ID], val);
                }
            },

            // 站点名称（档案名称)
            profilename: {
                enumerable: true,
                get: function() {
                    return this._profilename || window.localStorage.profilename;
                },
                set: function(val) {
                    this.setLocalStorage('profilename', val);
                }
            },

            /** 用户角色
             *   0 ： 创建者（是自己本身网站）
             *   1 ： 查看者（授权用户不可以编辑网站）
             *   2：  管理者（授权用户可以编辑网站）
             */
            userrole: {
                enumerable: true,
                get: function() {
                    return this._userrole || window.localStorage.userrole;
                },
                set: function(val) {
                    this.setLocalStorage('userrole', val);
                }
            },

            // 密码加密公钥（添加：登陆；使用：删除档案、修改密码、忘记密码、协作用户）
            publicKey: {
                enumerable: true,
                get: function() {
                    return this._publicKey || window.localStorage.publicKey;
                },
                set: function(val) {
                    this.setLocalStorage('publicKey', val);
                }
            },

            // 密码加密模
            exponent: {
                enumerable: true,
                get: function() {
                    return this._exponent || window.localStorage.exponent;
                },
                set: function(val) {
                    this.setLocalStorage('exponent', val);
                }
            },

            // 时区
            timezone: {
                enumerable: true,
                get: function() {
                    return this._timezone || window.localStorage.timezone;
                },
                set: function(val) {
                    this.setLocalStorage('timezone', val);
                }
            },
            /**
             * 是否直接跳转到档案创建页面
             */
            isAutoLogin: {
                enumerable: true,
                get: function() {
                    return this._isAutoLogin || window.localStorage.isAutoLogin;
                },
                set: function(val) {
                    this.setLocalStorage('isAutoLogin', val);
                }
            }
        }
    );

    //为了解码时遇到编码有问题的值而不报错写的函数 如果值异常没法解码时返回原来的值
    this.decodeURIComponent = function(val) {
        try {
            return decodeURIComponent(val);
        } catch (err) {
            return val;
        }
    };
    //道理同上
    this.decodeURI = function(val) {
        try {
            return decodeURI(val);
        } catch (err) {
            return val;
        }
    };
    //为了编码时遇到编码有问题的值而不报错写的函数 如果值异常没法编码时返回原来的值
    this.encodeURIComponent = function(val) {
        try {
            return encodeURIComponent(val);
        } catch (err) {
            return val;
        }
    };

    /**
     * 真正的throttle节流函数
     * 创建并返回一个像节流阀一样的函数，当重复调用函数的时候，最多每隔 wait毫秒调用一次该函数
     * @param func 执行函数
     * @param wait 时间间隔
     * @param options 如果你想禁用第一次首先执行的话，传递{leading: false}，
     *                如果你想禁用最后一次执行的话，传递{trailing: false}
     * @returns {Function}
     */
    this._throttle = function(func, wait, options) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if (!options) options = {};
        var later = function() {
            previous = options.leading === false ? 0 : new Date().getTime();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function() {
            var now = new Date().getTime();
            if (!previous && options.leading === false) previous = now;
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };
    /**
     * 真正的debounce防反弹函数
     * 防反跳。func函数在最后一次调用时刻的wait毫秒之后执行！
     * @param func 执行函数
     * @param wait 时间间隔
     * @param immediate 为true，debounce会在wai 时间间隔的开始调用这个函数
     * @returns {Function}
     */
    this._debounce = function(func, wait, immediate) {
        var timeout, args, context, timestamp, result;

        var later = function() {
            var last = new Date().getTime() - timestamp; // timestamp会实时更新

            if (last < wait && last >= 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                }
            }
        };

        return function() {
            context = this;
            args = arguments;
            timestamp = new Date().getTime();
            var callNow = immediate && !timeout;

            if (!timeout) {
                timeout = setTimeout(later, wait);
            }
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }
            return result;
        };
    };

    /**
     * 设置cookie
     * @param name 名称
     * @param value 值
     * @param daysToLive 有效期/天
     */
    this.setCookie = function(name, value, daysToLive) {
        var cookie = name + '=' + encodeURIComponent(value);
        if (typeof daysToLive === 'number') {
            cookie += '; max-age=' + daysToLive * 60 * 60 * 24; //分号空格
        }
        document.cookie = cookie;
    };
    // 根据名称获取指定Cookie指定值
    this.getCookie = function(name) {
        var pattern = RegExp(name + '=.[^;]*');
        var matched = document.cookie.match(pattern);
        if (matched) {
            return matched[0].split('=')[1];
        }
        return '';
    };

    // 验证URL
    this.isURL = function(url) {
        // 需要支持中日文 所以先注释掉这条正则 采用第三方的validator插件
        // var reg = new RegExp("^(http:\\/\\/|https:\\/\\/)?([\\w\\-]+\\.)+[\\w\\-]+(\\/[\\w\\-\\.\\/?\\@\\%\\!\\&=\\+\\~\\:\\#\\;\\,]*)?$");
        // return reg.test(url);
        return validator.isURL(url);
    };
    /**
     * 将字符串 转化为url对象
     * @param urlString
     * @returns {{source: *, protocol: string, host: *, port: *, query: *, params, file: *, hash: string, path: string, relative: *, segments: Array}}
     */
    this.parseURL = function(urlString) {
        var a = document.createElement('a');
        a.href = urlString;
        var urlObj = {
            source: urlString,
            protocol: a.protocol.replace(':', ''),
            host: a.hostname,
            port: a.port,
            query: pt.decodeURIComponent(a.search),
            params: (function() {
                var ret = {};
                var seg = pt
                    .decodeURIComponent(a.search)
                    .replace(/^\?/, '')
                    .split('&');
                var len = seg.length;
                var i = 0;
                var s;
                for (; i < len; i++) {
                    if (!seg[i]) {
                        continue;
                    }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            file: (pt.decodeURIComponent(a.pathname).match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: pt.decodeURIComponent(a.hash).replace('#', ''),
            path: pt.decodeURIComponent(a.pathname).replace(/^([^\/])/, '/$1'),
            relative: (pt.decodeURIComponent(a.href).match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: pt
                .decodeURIComponent(a.pathname)
                .replace(/^\//, '')
                .split('/')
        };

        //由于a标签会自动在path中添加 "/"  如 http://www.baidu.com?name=3 将和http://www.baidu.com/?name=3 返回同样的结果
        //所以需要对  path 进行特殊的处理
        var hostUrl = urlObj.protocol + '://' + urlObj.host + (urlObj.port ? ':' + urlObj.port : '') + urlObj.path;
        // 因为a链接的href会自动把中文转码 所以 进行比较查询时 也需要对原始urlSTring encode一下再进行indexof比较
        if (pt.decodeURIComponent(urlString).indexOf(hostUrl) < 0) {
            //判断一下不加 "/" 的url前面的部分 是否包含在传入的 urlString,如果不存在说明自动添加了 "/",需要去除
            urlObj.path = urlObj.path.replace(/^\//, '');
        }

        return urlObj;
    };

    // 日期格式化 (需要使用call,apply转化this)
    this.format = function(format) {
        var o = {
            'M+': this.getMonth() + 1, //month
            'd+': this.getDate(), //day
            'h+': this.getHours(), //hour
            'm+': this.getMinutes(), //minute
            's+': this.getSeconds(), //second
            'q+': Math.floor((this.getMonth() + 3) / 3), //quarter
            S: this.getMilliseconds() //millisecond
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(
                    RegExp.$1,
                    RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                );
            }
        }
        return format;
    };
}

// global
window.pt = new PT();
