<template>
    <div id="ptx" v-loading="visibleLoading">
        <router-view></router-view>
        <refresh-dialog></refresh-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import RefreshDialog from '@common/packages/refresh-dialog.vue';
import { isSingleSpa, sendPostMessage } from '@/common/services/singleSpa.service';

export default {
    name: 'PTX',
    data() {
        return {
            pageVersionCheckTick: null
        };
    },
    computed: {
        ...mapGetters('main', ['routerIsLoading']),
        visibleLoading() {
            return this.routerIsLoading && !this.$route.params.sid;
        }
    },
    created() {
        this.UPDATE_ENV_CONFIGS(process.env.VUE_APP_CONFIGS);

        // 监听浏览器返回事件
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.handlePopstate, false);
        }

        const isDevEnv = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
        if (!isDevEnv && window === window.parent) {
            const envConfigs = process.env.VUE_APP_CONFIGS || {};
            const isToActive =
                location.href.includes('/account/active?email=') || location.href.includes('/app/joinProject'); //TODO：new ptengine内暂时还没有激活流程，维持在ptx内激活
            !isToActive &&
                (location.href = location.host.includes('ptengine.com')
                    ? envConfigs.newEnPtengine
                    : envConfigs.newPtengine);
        }
    },
    mounted() {
        // this.pageVersionCheckHandler();
        // this.pageVersionCheckTick = setInterval(this.pageVersionCheckHandler, 1000 * 60 * 1);
        isSingleSpa() && this.observeElementHeight();
    },

    beforeDestroy() {
        window.removeEventListener('popstate', this.handlePopstate, false);
    },

    methods: {
        ...mapMutations('main', ['UPDATE_ENV_CONFIGS']),

        handlePopstate() {
            this.$eventBus.$emit('popstate');
        },

        async pageVersionCheckHandler() {
            const response = await axios.get(
                `${window.location.protocol}//${window.location.host}/?r=${new Date().getTime()}`
            );
            const docElement = document.createElement('html');
            docElement.innerHTML = response.data;
            const newVersion = this.getMeta(docElement, 'version');
            const currentVersion = this.getMeta(document, 'version');
            if (newVersion && currentVersion && currentVersion !== newVersion) {
                this.$eventBus.$emit('appVersionExpire');
                this.pageVersionCheckTick &&
                    (clearInterval(this.pageVersionCheckTick), (this.pageVersionCheckTick = null));
            }
        },

        getMeta(doc, metaName) {
            const metas = doc.getElementsByTagName('meta');
            for (let i = 0; i < metas.length; i++) {
                if (metas[i].getAttribute('name') === metaName) {
                    return metas[i].getAttribute('content');
                }
            }
            return '';
        },

        observeElementHeight() {
            const _self = this;
            const MutationObserver =
                window.MutationObserver || window.webkitMutationObserver || window.MozMutationObserver;
            let recordHeight = 0;
            const mutationObserver = new MutationObserver(function() {
                const width = window.getComputedStyle(_self.$el).getPropertyValue('width');
                const height = window.getComputedStyle(_self.$el).getPropertyValue('height');
                if (height === recordHeight) return;

                recordHeight = height;
                sendPostMessage('ExperienceResize', {
                    width,
                    height
                });
            });
            mutationObserver.observe(this.$el, {
                childList: true, // 子节点的变动（新增、删除或者更改）
                attributes: true, // 属性的变动
                characterData: true, // 节点内容或节点文本的变动
                subtree: true // 是否将观察器应用于该节点的所有后代节点
            });
        }
    },
    components: {
        RefreshDialog
    }
};
</script>

<style lang="scss">
#ptx {
    display: flex;
    min-height: 100%;
    width: 100%;
    position: relative;
}
</style>
