var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.report},[_c('page-header',{ref:"pageHeader",attrs:{"size":"full","hasBorder":true}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t('users.user_report_title')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.user_group_1')}},[_c('pt-icon',{attrs:{"icon":"pt-icon--help-new","icon-style":{
                        width: '16px',
                        height: '16px',
                        margin: '0 0 10px 8px',
                        fill: '#5E6C84'
                    }}})],1)],1),_c('div',{ref:"headerTools",class:_vm.$style['header-tools'],attrs:{"slot":"rightTools"},slot:"rightTools"},[_c('pt-icon',{directives:[{name:"role",rawName:"v-role",value:({
                    type: 'createUsergroup',
                    role: _vm.profileInfo.currentUserRole
                }),expression:"{\n                    type: 'createUsergroup',\n                    role: profileInfo.currentUserRole\n                }"}],attrs:{"icon":"icon-recommend","icon-style":{
                    width: '24px',
                    height: '24px'
                }},on:{"click":function($event){return _vm.toggleDialogVisible('steps', !_vm.dialogVisible.steps)}}}),_c('verify-package',{directives:[{name:"role",rawName:"v-role",value:({
                    type: 'createUsergroup',
                    role: _vm.profileInfo.currentUserRole
                }),expression:"{\n                    type: 'createUsergroup',\n                    role: profileInfo.currentUserRole\n                }"}],attrs:{"component":"icon","privilege-key":"CREATE_USER_GROUP","resolveCallback":_vm.createUsergroup,"rejectCallback":_vm.showAuthDialog}},[_c('pt-button',{attrs:{"icon":"ptx-icon-add","size":"medium","color":"blue"}},[_vm._v(_vm._s(_vm.$t('users.new_users')))])],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogVisible.steps),expression:"dialogVisible.steps"}],staticClass:"x-wrap x-wrap--full",class:_vm.$style.steps},[_c('div',{class:_vm.$style['steps-arrow'],style:(_vm.stepsArrowStyle)}),_c('div',{class:_vm.$style['steps-wrap']},[_c('p',[_vm._v(_vm._s(_vm.$t('users.description')))]),_c('pt-icon',{class:_vm.$style['steps-close'],attrs:{"icon":"pt-icon--close","icon-style":{
                    width: '16px',
                    height: '16px'
                }},on:{"click":function($event){return _vm.toggleDialogVisible('steps', false)}}}),_c('div',{class:_vm.$style['steps-container']},_vm._l((_vm.steps),function(step){return _c('div',{key:step.code},[_c('verify-package',{attrs:{"privilege-key":step.status === 1 && !step.default ? 'EDIT_USER_GROUP' : 'CREATE_USER_GROUP',"component":"link","resolveCallback":function () { return _vm.createUsergroup(step, true); },"rejectCallback":_vm.showAuthDialog}},[_c('div',{class:[_vm.$style.box, _vm.$style[("box-" + (step.code))]]},[_c('div',{class:_vm.$style['box-title']},[_c('h3',[_vm._v(_vm._s(_vm.$t(step.name)))]),(step.code !== 'custom')?_c('el-tooltip',{attrs:{"placement":"bottom","content":_vm.$t(step.info)}},[_c('pt-icon',{attrs:{"icon":"pt-icon--info","icon-style":{
                                            width: '16px',
                                            height: '16px',
                                            fill: '#5E6C84'
                                        }}})],1):_vm._e()],1),_c('div',{class:_vm.$style['box-description'],domProps:{"innerHTML":_vm._s(_vm.$t(step.description))}}),(!_vm.loading)?_c('div',{class:_vm.$style['box-status']},[(step.status === 1)?[_c('pt-icon',{attrs:{"icon":"icon-tick-mark","icon-style":{
                                            width: '20px',
                                            height: '20px',
                                            fill: '#091E42'
                                        }}}),_c('span',[_vm._v(_vm._s(_vm.$t('users.used')))])]:_c('div',{class:_vm.$style['box-status-icon']},[_c('pt-icon',{attrs:{"icon":"icon-arrow-right","icon-style":{
                                            width: '18px',
                                            height: '18px',
                                            fill: '#091E42'
                                        }}})],1)],2):_vm._e()])])],1)}),0)],1)]),(_vm.allPeopleData)?_c('users-report-all-people-card',{attrs:{"data":_vm.allPeopleData,"profileInfo":_vm.profileInfo},on:{"updateData":_vm.handleUpdateAllPeopleData}}):_vm._e(),_c('div',{staticClass:"x-wrap x-wrap--full"},[(_vm.cellListOrigin && _vm.cellListOrigin.length > 0)?_c('div',{class:_vm.$style.toolbar},[_c('div',{class:_vm.$style['toolbar-search']},[_c('pt-input',{ref:"searchInput",attrs:{"prefixIcon":"pt-icon--search-v2","size":"large","type":"text","inputStyle":"underline","prefix":true,"clearButton":_vm.clearSearch,"placeholder":_vm.searchStates.focus ? _vm.$t('users.search_placeholder') : ''},on:{"focus":_vm.searchInputFocus,"blur":_vm.searchInputBlur},model:{value:(_vm.searchStates.key),callback:function ($$v) {_vm.$set(_vm.searchStates, "key", $$v)},expression:"searchStates.key"}})],1),_c('div',{class:_vm.$style['toolbar-sort']},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleSortCommand}},[_c('div',{staticClass:"el-dropdown-link",class:_vm.$style['toolbar-sort__trigger']},[_vm._v(" "+_vm._s(_vm.$t('users.sort_by'))+" "+_vm._s(_vm.currentSort.name)+" "),_c('pt-icon',{attrs:{"icon":"pt-icon--hollow-arrow-down","icon-style":{
                                width: '12px',
                                height: '12px',
                                fill: '#344563'
                            }}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_vm._l((_vm.sortOptions),function(item){return [_c('el-dropdown-item',{key:item.code,attrs:{"divided":item.divided,"command":item.code}},[_vm._v(_vm._s(item.name))])]})],2)],1)],1)]):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.$style.container,attrs:{"element-loading-background":"transparent"}},[(!_vm.loading && _vm.cellListOrigin.length > 0)?[_vm._l((_vm.cellList),function(cell){return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!cell.isRendered),expression:"!cell.isRendered"}],key:cell.id,class:_vm.$style.cell,attrs:{"data-user-id":cell.id}},[_c('users-report-card',{attrs:{"info":cell,"profileInfo":_vm.profileInfo},on:{"showAuthDialog":_vm.showAuthDialog,"choose":function (val) { return (_vm.currentGroup = val); },"getData":_vm.handleGetData,"delete":function($event){_vm.dialogVisible.deleteGroup = true}}})],1)}),(_vm.cellList && _vm.cellList.length === 0)?_c('div',{class:_vm.$style.nodata},[_vm._v(_vm._s(_vm.$t('users.no_data')))]):_vm._e()]:_vm._e()],2)]),_c('el-dialog',{attrs:{"title":_vm.deleteUsersTitle,"visible":_vm.dialogVisible.deleteGroup,"width":"440px","center":""},on:{"update:visible":function($event){return _vm.$set(_vm.dialogVisible, "deleteGroup", $event)}}},[_c('span',[_vm._v(_vm._s(_vm.deleteUsersTips))]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('pt-button',{attrs:{"type":"outline","color":"black","size":"medium"},on:{"click":function($event){return _vm.toggleDialogVisible('deleteGroup', false)}}},[_vm._v(" "+_vm._s(_vm.$t('common.button_cancel'))+" ")]),(_vm.currentGroup && !_vm.currentGroup.engagements)?_c('pt-button',{style:({ marginLeft: '12px' }),attrs:{"size":"medium","color":"red"},on:{"click":_vm.handleCofirmDelete}},[_vm._v(" "+_vm._s(_vm.$t('common.button_delete'))+" ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }