var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"pt-clickoutside",rawName:"v-pt-clickoutside",value:(_vm.handleClickoutside),expression:"handleClickoutside"}],class:[
        _vm.prefix ? 'has-prefix' : '',
        _vm.hasContent ? 'has-content' : '',
        _vm.type === 'textarea' ? 'pt-textarea' : 'pt-input',
        _vm.size ? 'pt-input--' + _vm.size : '',
        _vm.invalid ? 'warning' : '',
        _vm.isFocus ? 'focus' : '',
        'pt-input_' + _vm.inputStyle,
        _vm.color ? 'pt-input_' + _vm.color : '',
        {
            'is-disabled': _vm.disabled
        }
    ]},[(_vm.prefix && _vm.prefixIcon)?_c('span',{staticClass:"pt-input__prefix",on:{"click":function($event){$event.stopPropagation();return _vm.togglePrefix($event)}}},[(_vm.prefix && _vm.prefixIcon)?_c('pt-icon',{attrs:{"icon":_vm.prefixIcon}}):_vm._e()],1):_vm._e(),(_vm.type !== 'textarea')?_c('input',{directives:[{name:"autofocus",rawName:"v-autofocus",value:(_vm.autofocus),expression:"autofocus"}],ref:"input",staticClass:"pt-input__inner",class:{
            hasContent: _vm.hasContent,
            'pt-input__inner--clearButton': _vm.clearButton
        },attrs:{"type":_vm.type,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"minlength":_vm.minlength,"autocomplete":_vm.autoComplete,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.currentValue},on:{"keydown":_vm.handleEnter,"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur}}):_c('div',{staticClass:"pt-textarea__container_parent"},[_c('div',{ref:"textarea__container",staticClass:"pt-textarea__container"},[_c('textarea',{directives:[{name:"autofocus",rawName:"v-autofocus",value:(_vm.autofocus),expression:"autofocus"}],ref:"textarea",staticClass:"pt-textarea__inner",class:{ hasContent: _vm.hasContent },attrs:{"name":_vm.name,"rows":_vm.rows,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"minlength":_vm.minlength},domProps:{"value":_vm.currentValue},on:{"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur}})])]),(_vm.clearButton && _vm.currentValue)?_c('span',{staticClass:"pt-input__inner__clearButton",on:{"click":_vm.handleClearButtonClick}},[_c('pt-icon',{attrs:{"icon":"pt-icon--clear"}})],1):_vm._e(),_c('p',{class:['pt-input__warning', _vm.errMsgPosition ? ("position-" + _vm.errMsgPosition) : '']},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.invalidTip)}})]),(_vm.showWordLimit)?_c('span',{staticClass:"pt-input__suffix"},[_c('span',{staticClass:"pt-input__suffix-inner"},[_c('span',{staticClass:"el-input__count"},[_c('span',{staticClass:"el-input__count-inner"},[_vm._v(_vm._s(((_vm.currentValue.length) + "/" + _vm.maxlength)))])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }