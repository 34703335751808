import i18n from '@assets/i18n';
import widgetConfig from './widget.config';
import { getBlockDomAndStyle } from '../../utils/blocks.util';
import { isNullOrUndefined } from '../../utils/creative.util';
import { widgetWrapPadding, widgetWrapMaskFlag } from '../../configs/creative.config';
/**
 * Style 书写顺序
 * 1. 定位属性:
 *  position, display, float, left, top, right, bottom, overflow, clear, z-index.
 * 2. 自身属性:
 *  width, height, padding, border, margin, background
 * 3. 文字样式:
 *  font-family, font-size, font-style, font-weight, font-varient, color
 * 4. 文本属性:
 *  text-align, vertical-align, text-wrap, text-transform, text-indent,text-decoration, letter-spacing, word-spacing, white-space, text-overflow
 * 5. css3中新增属性:
 *  content, box-shadow, border-radius, transform
 */

function getWidgetStyle(widgetId, widgetSettings) {
    const {
        width,
        height,
        borderRadius,
        backgroundColor,
        visibleShadow,
        backgroundImage,
        position,
        paddingTop,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight
    } = widgetSettings || {};
    const positionConfig = widgetConfig.positionOptions.find(item => item.code === position) || {};
    const { cssAnimationName } = positionConfig;
    const paddingTopStyle = isNullOrUndefined(paddingTop) ? widgetWrapPadding.top : paddingTop;
    const positionStyle = widgetConfig?.style?.position?.[position]?.({
        top: marginTop,
        bottom: marginBottom,
        left: marginLeft,
        right: marginRight
    });
    const backgroundImageStyle = backgroundImage
        ? `
            background-image: url(${backgroundImage});
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        `
        : '';
    const boxShadow = visibleShadow
        ? `
            box-shadow: rgba(0, 0, 0, 0.4) 0px 12px 27px 2px;
        `
        : '';
    const backgroundColorStyle = backgroundColor
        ? `
            background-color: ${backgroundColor};
        `
        : '';
    const widthStyle = isNullOrUndefined(width) || width === '' ? 'auto' : `${width}px`;
    const heightStyle = isNullOrUndefined(height) || height === '' ? 'auto' : `${height}px`;
    return `
        #${widgetId} {
            position: fixed;
            ${positionStyle}
            display: table !important;
            z-index: 2147483645;
            width: ${widthStyle};
            height: ${heightStyle};
            padding: ${paddingTopStyle}px 0 ${paddingTopStyle}px;
            ${backgroundColorStyle}
            ${backgroundImageStyle}
            color: rgb(52, 69, 99);
            opacity: 1 !important;
            cursor: default;
            border-radius: ${borderRadius}px;
            box-sizing: border-box;
            ${boxShadow}
            -webkit-animation: ${cssAnimationName} 0.5s linear;
            -moz-animation: ${cssAnimationName} 0.5s linear;
            animation:  ${cssAnimationName} 0.5s linear;
        }
        #${widgetId} b, 
        #${widgetId} strong{
            font-weight: bold;
            font-style: inherit;
        }
        #${widgetId} u{
            font-style: inherit;
            font-weight: inherit;
        }
        #${widgetId} i{
            font-style: italic;
            font-weight: inherit;
        }
        #${widgetId} p {
            line-height:24px;
            margin:0;
        }
        #${widgetId} h1,
        #${widgetId} h2,
        #${widgetId} h3,
        #${widgetId} h4,
        #${widgetId} h5,
        #${widgetId} h6{
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            margin-bottom:10px;
        }
        #${widgetId} h1{
            font-size: 36px;
        }
        #${widgetId} h2{
            font-size: 30px;
        }
        #${widgetId} h3{
            font-size: 24px;
        }
        #${widgetId} h4{
            font-size: 18px;
        }
        #${widgetId} h5{
            font-size: 14px;
        }
        #${widgetId} h6{
            font-size: 12px;
        }
        #${widgetId} .clearfix::before,
        #${widgetId} .clearfix::after {
            display: table;
            content: '';
        }
        #${widgetId} .clearfix::after {
            clear: both;
        }
        ${widgetConfig.style[positionConfig.cssAnimation]}
    `;
}

function getCloseBtnStyle(widgetId, widgetSettings) {
    const defaultLayout = widgetConfig.closeBtnLayoutOptions.find(item => item.default);
    const closeBntLayout = widgetConfig.closeBtnLayoutOptions.find(item => {
        return item.code === widgetSettings?.closeBntLayout;
    });
    const closeBtnStyle = widgetConfig.closeBtnStyleOptions.find(item => {
        return item.code === widgetSettings?.closeBtnStyle;
    });
    const position = closeBntLayout ? closeBntLayout.style : defaultLayout.style;
    const svgStyle = closeBtnStyle?.style || '';
    return `
        #${widgetId}_close-btn {
            position: absolute;
            ${position}
            width: ${widgetSettings?.closeBtnSize ?? 0}px;
            height: ${widgetSettings?.closeBtnSize ?? 0}px;
            cursor: pointer;
            user-select: none;
        }
        #${widgetId}_close-btn svg {
            ${svgStyle}
            fill: ${widgetSettings?.closeBtnColor ?? '#000'};
            -webkit-transition: 0.2s linear;
            -moz-transition: 0.2s linear;
            transition: 0.2s linear;
        }
        #${widgetId}_close-btn:hover svg {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg); 
        }
    `;
}

function getBrandingStyle(widgetId, widgetSettings) {
    const display = !widgetSettings?.visibleBranding ? 'display: none' : '';
    return `
        #${widgetId}_branding {
            ${widgetConfig.style.branding}
            ${display}
        }
    `;
}

function getCloseBtnHtml(widgetId, widgetSettings) {
    const closeBtnStyle = widgetConfig.closeBtnStyleOptions.find(item => {
        return item.code === widgetSettings?.closeBtnStyle;
    });
    const defaultStyle = widgetConfig.closeBtnStyleOptions.find(item => item.default);
    const svg = closeBtnStyle ? closeBtnStyle.svg : defaultStyle.svg;
    return `<div id="${widgetId}_close-btn" data-engage-action-close="true" data-engage-block="icon-close">${svg}</div>`;
}

function getBrandingHtml(widgetId) {
    const brandingLink = i18n.t('url.setting_engage_branding_link');
    return `
        <a id="${widgetId}_branding" href="${brandingLink}" target="_blank" data-engage-block="branding">
            Powered by Ptengine Experience
        </a>`;
}

function getBlocksStyleAndHtml({ widgetBlocks, widgetSettings, widgetId, belong, engageId, versionId }) {
    const htmls = [];
    const styles = [];
    const scripts = [];

    widgetBlocks &&
        widgetBlocks.forEach(block => {
            const { style, html, script } = getBlockDomAndStyle(
                block,
                widgetSettings,
                widgetId,
                belong,
                engageId,
                versionId
            );
            htmls.push(html);
            styles.push(style);
            script && scripts.push(script);
        });
    return {
        blocksHtml: htmls.join(''),
        blocksStyle: styles.join(''),
        blocksScript: scripts.join('')
    };
}

export default {
    /**
     * get widget html
     * @param {Object} widget
     * @param {String} tagetModel: ['visualModel', 'codeModel']
     */
    getHtml({ widget, tagetModel, belong }) {
        const { widgetId } = widget;
        const { widgetBlocks, widgetSettings } = widget.visualModel || {};
        const tagetModelIsCodeModel = tagetModel === 'codeModel';
        const closeBtnHtml = getCloseBtnHtml(widgetId, widgetSettings);
        const brandingHtml = tagetModelIsCodeModel ? '' : getBrandingHtml(widgetId, widgetSettings);
        const { blocksHtml } = getBlocksStyleAndHtml({
            widgetBlocks,
            widgetSettings,
            belong
        });
        const positionIsCenter = widgetSettings && widgetSettings.position === 'pos-center';
        const dataMask =
            positionIsCenter && tagetModelIsCodeModel ? `${widgetWrapMaskFlag}="${widgetWrapMaskFlag}"` : '';
        // PS: 用 ‘+’ 为了避免html中有换行
        return (
            `<div id="${widgetId}" ${dataMask} data-engage-block="engage" data-engage-widget-id="${widgetId}">` +
            blocksHtml +
            closeBtnHtml +
            brandingHtml +
            '</div>'
        );
    },

    getStyle({ widget, includeChildren = true }) {
        const { widgetId } = widget;
        const { widgetBlocks, widgetSettings } = widget.visualModel || {};
        const widgetStyle = getWidgetStyle(widgetId, widgetSettings);
        const closeBtnStyle = getCloseBtnStyle(widgetId, widgetSettings);
        const brandingStyle = getBrandingStyle(widgetId, widgetSettings);
        const { blocksStyle } = getBlocksStyleAndHtml({
            widgetBlocks,
            widgetSettings
        });
        return widgetStyle + closeBtnStyle + brandingStyle + (includeChildren ? blocksStyle : '');
    },

    /**
     * get widget script
     * @param {Object} widget
     * @param {String} belong: dialog/thumbnail/editor/normal
     */
    getScript({ widget, belong, engageId, versionId }) {
        const { widgetId } = widget;
        const { widgetBlocks, widgetSettings } = widget.visualModel || {};
        const { blocksScript } = getBlocksStyleAndHtml({
            widgetBlocks,
            widgetSettings,
            widgetId,
            belong,
            engageId,
            versionId
        });
        return blocksScript;
    }
};
