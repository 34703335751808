"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventListenerOptions = exports.detectOS = exports.isServer = void 0;
var isServer = function () { return typeof window === 'undefined'; };
exports.isServer = isServer;
var detectOS = function (ua) {
    ua = ua || navigator.userAgent;
    var ipad = /(iPad).*OS\s([\d_]+)/.test(ua);
    var iphone = !ipad && /(iPhone\sOS)\s([\d_]+)/.test(ua);
    var android = /(Android);?[\s/]+([\d.]+)?/.test(ua);
    var ios = iphone || ipad;
    return { ios: ios, android: android };
};
exports.detectOS = detectOS;
function getEventListenerOptions(options) {
    /* istanbul ignore if */
    if ((0, exports.isServer)())
        return false;
    if (!options) {
        throw new Error('options must be provided');
    }
    var isSupportOptions = false;
    var listenerOptions = {
        get passive() {
            isSupportOptions = true;
            return;
        }
    };
    /* istanbul ignore next */
    var noop = function () { };
    var testEvent = '__TUA_BSL_TEST_PASSIVE__';
    window.addEventListener(testEvent, noop, listenerOptions);
    window.removeEventListener(testEvent, noop, listenerOptions);
    var capture = options.capture;
    /* istanbul ignore next */
    return isSupportOptions ? options : typeof capture !== 'undefined' ? capture : false;
}
exports.getEventListenerOptions = getEventListenerOptions;
