<template>
    <transition name="fade">
        <div class="pt-popup" :class="{ 'short-popup': theme === 'short' }">
            <pt-scrollbar>
                <div
                    class="pt-popup__content"
                    ref="popupContent"
                    :class="[
                        {
                            hideFooter: hideFooter,
                            'is-overflow': isOverflow,
                            'is-middle': fixMiddle,
                            'has-confirm': !hideConfirm
                        },
                        customClass
                    ]"
                >
                    <a
                        class="pt-popup__close"
                        :class="[closeBtnPos]"
                        @click="onClosePopup('icon')"
                        v-if="!hideCloseBtn"
                    >
                        <pt-icon icon="pt-icon--close"></pt-icon>
                    </a>
                    <header class="pt-popup__header" v-if="!hideHeader">
                        <slot name="header">
                            <h3>
                                <template v-if="!hideHeaderIcon">
                                    <template v-if="headerIcon !== null">
                                        <pt-icon v-if="headerIcon !== ''" :icon="'icon-' + headerIcon"></pt-icon>
                                    </template>
                                    <template v-else>
                                        <pt-icon
                                            v-if="(theme === 'short' || warn) && !correct"
                                            icon="icon-warning"
                                        ></pt-icon>
                                        <pt-icon
                                            v-if="(theme === 'short' || warn) && correct"
                                            icon="icon-normal"
                                            class="normal"
                                        ></pt-icon>
                                    </template>
                                </template>
                                <span v-html="header"></span>
                            </h3>
                            <div
                                class="pt-popup__header-subtitle"
                                v-html="subtitle"
                                v-if="subtitle !== undefined"
                            ></div>
                            <pt-tooltip :content="$t('common.help')" :openDelay="500" v-if="helpLink">
                                <a class="help" target="_black" v-bind:href="helpLink">
                                    <pt-icon icon="icon-help"></pt-icon>
                                </a>
                            </pt-tooltip>
                            <pt-tooltip :content="tips" :openDelay="500" v-if="tips">
                                <a class="help">
                                    <pt-icon icon="icon-help"></pt-icon>
                                </a>
                            </pt-tooltip>
                        </slot>
                    </header>
                    <div class="pt-popup__main" v-if="!hideContent">
                        <slot name="content"></slot>
                    </div>
                    <footer class="pt-popup__footer" v-if="!hideFooter" :class="{ 'btn-center': btnCenter }">
                        <slot name="footer">
                            <pt-button
                                v-if="otherBtn"
                                size="large"
                                class="pt-btn_other"
                                theme="danger"
                                @click="otherHandler"
                                >{{ otherBtnText }}</pt-button
                            >
                            <a
                                class="pt-btn_left black"
                                @click="onClosePopup('btn')"
                                v-if="leftBtnText && !hideLeftBtn"
                                >{{ leftBtnText }}</a
                            >
                            <pt-button
                                v-if="rightBtnText && !hideRightBtn"
                                class="pt-btn_right"
                                :square="true"
                                :disabled="disableRightBtn"
                                :loading="rightBtnLoading"
                                size="large"
                                theme="primary"
                                @click="onSave"
                                >{{ rightBtnText }}</pt-button
                            >
                        </slot>

                        <div class="pt-popup__confirm" v-if="!hideConfirm">
                            <div class="pt-popup__confirm-content">
                                <slot name="confirmContent"></slot>
                            </div>
                            <div class="pt-popup__confirm-footer">
                                <slot name="confirmFooter">
                                    <a
                                        class="pt-btn_left black"
                                        @click="onCloseConfirm('btn')"
                                        v-if="confirmLeftBtnText"
                                        >{{ confirmLeftBtnText }}</a
                                    >
                                    <pt-button
                                        v-if="confirmRightBtnText"
                                        class="pt-btn_right"
                                        :square="true"
                                        :disabled="disableRightBtn"
                                        size="large"
                                        theme="primary"
                                        @click="onSaveConfirm"
                                        >{{ confirmRightBtnText }}</pt-button
                                    >
                                </slot>
                            </div>
                        </div>
                    </footer>
                    <div class="pt-popup__extra" v-if="!hideExtra">
                        <slot name="extra"></slot>
                    </div>
                    <slot></slot>
                </div>
            </pt-scrollbar>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ptPopup',

    props: {
        header: String,
        subtitle: String,
        theme: {
            default: 'default', //short 表示信息提示弹窗
            type: String,
            enum: ['default', 'sort']
        },
        leftBtnText: {
            type: String,
            default: 'Cancel'
        },
        rightBtnText: {
            type: String,
            default: 'Save'
        },
        closeBtnPos: {
            type: String,
            default: 'outside',
            enum: ['outside', 'inline']
        },
        confirmLeftBtnText: {
            type: String,
            default: 'Cancel'
        },
        confirmRightBtnText: {
            type: String,
            default: 'Save'
        },
        disableRightBtn: {
            type: Boolean,
            default: false
        },
        rightBtnLoading: {
            type: Boolean,
            default: false
        },
        otherBtnText: {
            type: String,
            default: 'other'
        },
        otherBtn: {
            type: Boolean,
            default: false
        },
        warn: {
            //高度不是信息提示弹窗，但是title是信息提示弹窗样式
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        hideHeaderIcon: {
            type: Boolean,
            default: false
        },
        hideContent: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideExtra: {
            type: Boolean,
            default: false
        },
        hideLeftBtn: {
            //隐藏左边的按钮（只留下一个按钮）
            type: Boolean,
            default: false
        },
        hideRightBtn: {
            type: Boolean,
            default: false
        },
        hideCloseBtn: {
            type: Boolean,
            default: false
        },
        hideConfirm: {
            type: Boolean,
            default: true
        },
        leftBtnSize: {
            type: String,
            default: 'small'
        },
        customHeight: {
            //可以自定义普通窗口高度
            type: [String, Number]
        },
        customClass: {
            type: String
        },
        fixMiddle: {
            type: Boolean,
            default: true
        },
        btnCenter: Boolean,
        //头部标题左侧的图表
        headerIcon: {
            type: String,
            default: null
        },
        correct: {
            type: Boolean,
            default: false
        },
        helpLink: String, //帮助链接
        tips: String, //帮助文案
        isEnableResize: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        popupHeight() {
            if (this.theme === 'short') return false;
            if (this.customHeight && !isNaN(+this.customHeight))
                return 'height: ' + this.customHeight + 'px' + ';min-height:' + this.customHeight + 'px';
            return 'height: ' + (window.innerHeight * 0.6 + 110) + 'px';
        },
        mainHeight() {
            if (this.theme === 'short') return false;
            if (window.innerHeight * 0.6 + 110 < 530) {
                return 393;
            } else {
                return window.innerHeight * 0.6 + 110 - 137;
            }
        },
        isOverflow() {
            if (this.isEnableResize) {
                return this.contentHeight > this.screenHeight;
            } else {
                return false;
            }
        }
    },
    data() {
        return {
            bodyHeight: document.body.clientHeight,
            screenHeight: document.body.clientHeight,
            contentHeight: null
        };
    },
    mounted() {
        this.contentHeight = this.$refs.popupContent.offsetHeight;
        window.onresize = () => {
            if (this.isEnableResize) {
                const that = this;
                return (() => {
                    window.screenHeight = document.body.clientHeight;
                    that.screenHeight = window.screenHeight;
                })();
            }
        };
    },
    methods: {
        handleClick(e) {
            this.$emit('click', e);
        },
        onClosePopup(e) {
            this.$emit('closePopup', e);
        },
        onSave(e) {
            this.$emit('savePopup', e);
        },
        onCloseConfirm(e) {
            this.$emit('closeConfirm', e);
        },
        onSaveConfirm(e) {
            this.$emit('saveConfirm', e);
        },
        otherHandler(e) {
            this.$emit('otherHandler', e);
        },

        // 外部调用
        resetContentHeight() {
            this.contentHeight = this.$refs.popupContent.offsetHeight;
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import.scss';

.pt-popup {
    width: 100%;
    height: 100%;
    background-color: rgba(3, 3, 3, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 70;
    line-height: 1;

    &.short-popup {
        .pt-popup__content {
            min-height: 50px;

            &.hideFooter {
                .pt-popup__main {
                    margin: 0;
                }
            }
        }
        .pt-popup__main {
            display: flex;
            justify-content: center;
        }
    }
    &__content {
        opacity: 1;
        background-color: $pt-white;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        position: relative;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 1010px;
        display: flex;
        flex-direction: column;
        &.is-overflow {
            margin-top: 136px;
            margin-bottom: 100px;

            &.is-middle {
                transform: none;
                top: 10%;
            }
        }
        &.is-middle {
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0;
        }
        &.has-confirm::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
    &__header {
        text-align: center;
        color: $pt-black-900 !important;
        padding: 48px 80px 0;
        @include media-xs {
            padding: 48px 10% 0;
        }

        h3 {
            font-size: 22px;
            font-family: Arial;
            color: $pt-black-900;
            margin: 0;
            svg {
                width: 22px;
                height: 22px;
                fill: $pt-warning;
                vertical-align: middle;
            }
            em {
                color: rgba(41, 182, 246, 0.16);
                font-style: normal;
            }
            svg.normal {
                width: 22px;
                height: 22px;
                fill: rgba(41, 182, 246, 0.16);
            }
        }
        &-subtitle {
            margin-top: 4px;
            font-size: 13px;
            color: $pt-black-600;
        }
        .help {
            position: absolute;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border: 1px solid $pt-black-30;
            border-radius: 50%;
            top: 20px;
            right: 20px;
            svg {
                width: 18px;
                height: 18px;
                vertical-align: -3px;
                fill: $pt-black-300;
            }
        }
    }
    &__close {
        position: absolute;
        width: 24px;
        height: 24px;
        cursor: pointer;
        svg {
            width: 24px;
            height: 24px;
            fill: $pt-black-50;
        }
        &.outside {
            top: 2px;
            right: -34px;
            &:hover {
                svg {
                    fill: $pt-white;
                }
            }
        }
        &.inline {
            top: 8px;
            right: 8px;
            &:hover {
                svg {
                    fill: $pt-black-50;
                }
            }
        }
    }
    &__main {
        font-size: 13px;
        margin-top: 48px;
        padding: 0 80px;
        flex: 1;
        @include media-xs {
            padding: 0 10%;
        }
    }
    &__footer {
        margin: 40px 0 48px 0;
        text-align: center;
        line-height: 15px;
        position: relative;
        > *:first-child {
            margin-left: 0 !important;
        }
        //  background-color: $pt-white !important;
        &.btn-center {
            .pt-btn_right {
                margin-left: 0;
            }
        }
        .pt-btn_left {
            padding: 0;
            vertical-align: bottom;
            line-height: 30px;
            text-decoration: underline;
            font-size: 14px;
            &:hover {
                text-decoration: none;
            }
            border: none;
            &.large {
                width: 148px;
                &:hover {
                    background-color: $pt-danger !important;
                    color: $pt-white;
                    border: 1px solid $pt-danger !important;
                }
            }
        }
        .pt-btn_right {
            margin-left: 25px;
            min-width: 128px;
            // padding: 0;
            vertical-align: top;
        }
        .pt-btn_other {
            width: 98px;
            padding: 0;
            vertical-align: top;
            margin-right: 30px;
        }
    }
    &__confirm {
        padding: 30px 40px;
        border: 1px solid $pt-black-40;
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgba(9, 30, 66, 0.12);
        background-color: $pt-white;
        position: absolute;
        bottom: 100%;
        left: 80px;
        right: 80px;
        margin-bottom: 20px;
        z-index: 10;

        &-content {
            color: $pt-black-600;
            font-size: 14px;
            line-height: 20px;
        }

        &-footer {
            margin-top: 20px;

            .pt-btn_right {
                position: relative;

                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    box-shadow: 2px -2px 2px rgba(9, 30, 66, 0.1);
                    border-style: solid;
                    border-width: 7px;
                    border-color: $pt-white $pt-white transparent transparent;
                    transform: rotate(135deg);
                    margin: 23px 0 0 -10px;
                }
            }
        }
    }
    &__extra {
        width: 100%;
        flex: 1;
    }
    p {
        margin: 0;
    }

    // media query
    @include media-lg {
        &__content {
            max-width: 90%;
        }
    }
    @include media-sm {
        &__content {
            width: 80%;
        }
        &__main {
            padding: 0 8%;
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
