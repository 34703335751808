import validatorFactory from '@/common/utils/validators.utils';

const findEle = (parent, type) => {
    return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type);
};

export default {
    name: 'validator',

    /** rules: [
     *   {type:required,errMsg:"此项不能为空"},
     *   {type:maxlength,value:10,trigger:'input',errMsg:"输入的最大字符数"},
     *   {type:minlength,value:5,trigger:'input',errMsg:"输入的最小字符数"}
     *  ]
     */
    bind: (el, binding, vnode) => {
        // console.log(el, binding, vnode);
        const vm = vnode.componentInstance; // current vueInstance
        const rules = binding.value;
        const $inp = findEle(el, 'input') || findEle(el, 'textarea');
        if (rules && rules instanceof Array) {
            rules.forEach(function (rule) {
                const type = rule.type; // validator stragegy(maxlength,required,...)
                const value = rule.value; // the value of validator stragegy
                const trigger = rule.trigger || 'blur'; // trigger type(such as click,input,blur,change,focus.default:blur)
                const errorMsg = rule.errMsg; // err message
                const callBack = validatorFactory[type];
                let invalid;
                if (callBack && typeof callBack !== 'function') return;
                const handler = function () {
                    if (!vm.invalid) {
                        invalid = value ? callBack($inp.value, value) : callBack($inp.value);
                        invalid ? vm.showError(errorMsg) : vm.clearError();
                    }
                };
                $inp.addEventListener(trigger, handler, false);
            });
        }
    }
};
