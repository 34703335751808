<template>
    <div :class="$style.accountPassword">
        <div :class="[$style.box, error.currentPassword && $style.boxError]">
            <div :class="$style.title">{{ $t('form.old_password') }}</div>
            <pt-input
                @focus="onFocus('currentPassword')"
                :warning="error.currentPassword"
                type="password"
                :maxlength="20"
                size="large"
                :placeholder="$t('form.old_password')"
                v-model="currentPassword"
            ></pt-input>
            <div :class="$style.warning" v-if="error.currentPassword">
                <span>{{ error.warningText.currentPassword }}</span>
            </div>
        </div>
        <div :class="[$style.box, error.newPassword && $style.boxError]">
            <div :class="$style.title">{{ $t('form.new_password') }}</div>
            <pt-input
                @focus="onFocus('newPassword')"
                :warning="error.newPassword"
                type="password"
                :maxlength="20"
                :minlength="8"
                size="large"
                :placeholder="$t('form.new_password')"
                v-model="newPassword"
            ></pt-input>
            <div :class="$style.warning" v-if="error.newPassword">
                <span>{{ error.warningText.newPassword }}</span>
            </div>
        </div>
        <div :class="[$style.box, error.confirmPassword && $style.boxError]">
            <div :class="$style.title">{{ $t('form.confirm_password') }}</div>
            <pt-input
                @focus="onFocus('confirmPassword')"
                :warning="error.confirmPassword"
                type="password"
                :maxlength="20"
                :minlength="8"
                size="large"
                :placeholder="$t('form.confirm_password')"
                v-model="confirmPassword"
            ></pt-input>
            <div :class="$style.warning" v-if="error.confirmPassword">
                <span>{{ error.warningText.confirmPassword }}</span>
            </div>
        </div>
        <pt-button size="large" block :class="$style.saveBtn" :loading="isLoading" :disabled="hasError" @click="save">{{
            $t('common.button_save')
        }}</pt-button>
    </div>
</template>

<script>
import md5 from 'js-md5';
import { mapGetters } from 'vuex';
import accountApis from '@/apis/account.apis';
import commonUtils from '@/common/utils/common.utils';
import userService from '@/common/services/user.service';

export default {
    name: 'accountPasswordSetting',

    props: {
        userInfo: Object
    },

    data() {
        return {
            isLoading: false,
            currentPassword: '', //当前密码
            newPassword: '', //新密码
            confirmPassword: '', //确认密码
            maxLen: 20,
            minLen: 6,
            error: {
                currentPassword: false,
                newPassword: false,
                confirmPassword: false,
                warningText: {
                    currentPassword: this.$t('account.account_setting.incorrectPassword'),
                    newPassword: this.$t('form.error.password_length'),
                    confirmPassword: this.$t('form.error.new_old_not_match')
                }
            }
        };
    },

    computed: {
        ...mapGetters('account', ['userId']),

        hasError() {
            const { newPassword, confirmPassword, currentPassword } = this.error;
            let hasError = newPassword || confirmPassword || currentPassword;
            let hasEmpty = !this.currentPassword || !this.newPassword || !this.confirmPassword;
            return hasError || hasEmpty;
        }
    },

    methods: {
        onFocus(name) {
            this.error[name] = false;
        },

        checkData() {
            this.error.newPassword =
                !this.newPassword || this.newPassword.length > this.maxLen || this.newPassword.length < this.minLen;
            this.error.confirmPassword = !this.confirmPassword || this.confirmPassword !== this.newPassword;
        },

        save() {
            this.checkData();
            this.hasError || this.updatePassword();
        },

        async updatePassword() {
            this.isLoading = true;
            const [err, res] = await commonUtils.awaitWrap(
                accountApis.updatePassword(null, {
                    where: {
                        aid: this.userInfo.id,
                        email: this.userInfo.email,
                        password: md5(this.currentPassword)
                    },
                    data: {
                        password: md5(this.newPassword)
                    }
                })
            );
            if (err) {
                this.isLoading = false;
                this.error.currentPassword = true;
                this.error.warningText.currentPassword = this.$t('account.account_setting.incorrectPassword');
            } else {
                userService.logout();
            }
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.accountPassword {
    .box {
        margin: 20px 74px 0;
        position: relative;
        @include media-sm {
            margin: 20px 37px 0;
        }

        .title {
            color: $pt-black-600;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        .pt-input__inner {
            font-size: 18px;
            line-height: 30px;
            &::-moz-placeholder {
                color: $pt-black-60;
                font-size: 12px;
            }
            &::-ms-input-placeholder {
                color: $pt-black-60;
                font-size: 12px;
            }
            &::-webkit-input-placeholder {
                color: $pt-black-60;
                font-size: 12px;
            }
        }

        .warning {
            line-height: 1;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            margin-top: 4px;

            span {
                color: $pt-danger-hover;
                font-size: 12px;
            }
        }
    }

    .boxError {
        margin-bottom: 28px;
    }

    .saveBtn {
        width: 120px !important;
        margin: 40px auto 0;
    }
}
</style>
