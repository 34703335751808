import i18n from '@assets/i18n';
import { getYouTubeEmbedUrl } from '../../utils/blocks.util';
import { widgetWrapPadding } from '../../configs/creative.config';

export default {
    getHtml(block, widgetSettings = {}, belong) {
        const id = block.blockId;
        const { url, autoplay } = block.blockContent[0].value;
        const shouldAutoPlay = belong === 'thumbnail' ? false : autoplay;
        const embedUrl = getYouTubeEmbedUrl(url, shouldAutoPlay);
        const iframe = embedUrl
            ? `<iframe title="${embedUrl}" src="${embedUrl}" frameborder="0" allow="autoplay" allowfullscreen=""></iframe>`
            : i18n.t('creative_editor.video_tips');
        return `<div id="${id}">${iframe}</div>`;
    },

    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const { url } = block.blockContent[0].value;
        const id = block.blockId;
        let { marginTop, marginBottom } = block.blockSettings;
        if (marginTop === undefined) marginTop = 0;
        if (marginBottom === undefined) marginBottom = 0;

        return url
            ? `
            #${id} {
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 30px;
                margin: ${marginTop}px 0 ${marginBottom}px;
                height: 0;
                line-height: 0;
                overflow: hidden;
                margin-left: ${paddingLeft}px;
                margin-right: ${paddingLeft}px;
            }

            #${id} iframe,
            #${id} object,
            #${id} embed {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            }
        `
            : `
            #${id} {
                position: relative;
                text-align: center;
                margin: ${marginTop}px 0 ${marginBottom}px;
            }
        `;
    }
};
