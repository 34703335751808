import autofocus from './autofocus';
import clickoutside from './clickoutside';
import repeatClick from './repeat-click';
import resize from './resize';
import copy from './copy';
import style from './style';
import refresh from './refresh';
import focus from './focus';
import maxlength from './max-length';
import dropdown from './dropdown';
import onlyNumber from './only-number';

export default [
    autofocus,
    copy,
    dropdown,
    focus,
    style,
    maxlength,
    clickoutside,
    onlyNumber,
    refresh,
    repeatClick,
    resize
];
