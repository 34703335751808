import store from '@/store';
import roleConfig from '@/common/configs/role.config';
import { changeAppRouter } from '@/common/services/singleSpa.service';

//
const hasEditRole = function(role) {
    role = role || store.state.main.profileInfo.currentUserRole;
    const { roles } = roleConfig;
    const roleItem = role && roles.find(item => item.key === role.toLocaleLowerCase());
    return roleItem && roleItem.canEdit;
};

/**
 * 判断用户身份
 * @param {Array<string>} roles - 要判断的身份列表
 */
const includeRoleWithStore = function(key, role) {
    const { roleLimited } = roleConfig;
    return roleLimited.includes(key) ? hasEditRole(role) : true;
};

const changeRouterToCampaignReport = function() {
    changeAppRouter('ExperienceList');
};

export default {
    hasEditRole,
    includeRoleWithStore,
    changeRouterToCampaignReport
};
