<template>
    <pt-popup-v2 width="400px" topColor="$pt-green-60" @close="closeAuthDialog">
        <div :class="$style.auth_dialog">
            <div :class="$style.image"></div>
            <div :class="$style.title">{{ $t(currentAuth.title) }}</div>
            <div :class="$style.desc">{{ $t(currentAuth.desc, { num: currentPrivilege[currentAuth.key] }) }}</div>
            <pt-button size="medium" @click="contactUs">{{ $t('menu.contact_us') }}</pt-button>
        </div>
    </pt-popup-v2>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import authConfig from './auth.config';
import intercomSerice from '@/common/services/intercom.service';
export default {
    name: 'AuthDialog',
    props: {
        type: {
            type: String,
            default: 'usergroups'
        }
    },
    data() {
        const authList = authConfig.authTypeList;
        return {
            authList
        };
    },
    computed: {
        ...mapGetters('main', ['packageInfo']),
        currentPrivilege() {
            return this.packageInfo.privilege || {};
        },
        currentAuth() {
            return this.authList.find(item => item.type === this.type);
        }
    },
    methods: {
        ...mapMutations('main', ['SET_AUTH_DIALOG_VISIBLE']),

        closeAuthDialog() {
            this.SET_AUTH_DIALOG_VISIBLE({ visible: false });
        },

        contactUs() {
            intercomSerice?.contact();
        }
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
.auth_dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $pt-black-600;
    padding: 68px 0 60px;
    .image {
        width: 100%;
        height: 120px;
        background: url('~@assets/images/plan/unlock.svg') no-repeat;
        background-size: 100%;
    }
    .title {
        margin-top: 28px;
        font-size: 20px;
        line-height: 28px;
        width: 100%;
        font-weight: 600;
        text-align: center;
    }
    .desc {
        text-align: center;
        width: 100%;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 36px;
    }
}
</style>
