"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteTooltip = exports.createTooltip = exports.tooltipEle = void 0;
var isDOMElement_1 = require("./../../../utils/isDOMElement");
var style_object_1 = require("../../style-object");
var getScroll_1 = require("../../../utils/getScroll");
var defaultTooltipStyle = {};
var tooltipEleExist = false;
exports.tooltipEle = null;
function createTooltip(targetEle, config, insertDOM) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var bindDOM = (typeof insertDOM === 'string'
        ? document.querySelector(insertDOM)
        : insertDOM);
    if (!(0, isDOMElement_1.isDOMElement)(bindDOM))
        bindDOM = document.body;
    var tooltipContent;
    var text1Ele;
    var text2Ele;
    var text3Ele;
    var tooltipTriangle;
    if (!exports.tooltipEle) {
        exports.tooltipEle = document.createElement('div');
        exports.tooltipEle.id = 'guide-tooltip';
        exports.tooltipEle.classList.add('guide-tooltip');
        tooltipContent = document.createElement('div');
        tooltipContent.id = 'guide-tooltip-content';
        text1Ele = document.createElement('div');
        text2Ele = document.createElement('div');
        text3Ele = document.createElement((config === null || config === void 0 ? void 0 : config.onClickTooltipButton) ? 'button' : 'div');
        text1Ele.id = 'guide-tooltip-text1';
        text2Ele.id = 'guide-tooltip-text2';
        text3Ele.id = 'guide-tooltip-text3';
        tooltipTriangle = document.createElement('div');
        tooltipTriangle.id = 'guide-tooltip-triangle';
    }
    else {
        tooltipEleExist = true;
        tooltipContent = exports.tooltipEle.querySelector('#guide-tooltip-content');
        text1Ele = exports.tooltipEle.querySelector('#guide-tooltip-text1');
        text2Ele = exports.tooltipEle.querySelector('#guide-tooltip-text2');
        text3Ele = exports.tooltipEle.querySelector('#guide-tooltip-text3');
        text3Ele === null || text3Ele === void 0 ? void 0 : text3Ele.remove();
        text3Ele = document.createElement((config === null || config === void 0 ? void 0 : config.onClickTooltipButton) ? 'button' : 'div');
        text3Ele.id = 'guide-tooltip-text3';
        tooltipTriangle = exports.tooltipEle.querySelector('#guide-tooltip-triangle');
    }
    if (!tooltipEleExist)
        bindDOM.append(exports.tooltipEle);
    exports.tooltipEle.style.cssText = (0, style_object_1.formatStyleObjectToString)(__assign(__assign({}, defaultTooltipStyle), ((config === null || config === void 0 ? void 0 : config.tooltipStyle) || {})));
    text1Ele.innerHTML = config === null || config === void 0 ? void 0 : config.text1;
    text2Ele.innerHTML = config === null || config === void 0 ? void 0 : config.text2;
    text3Ele.innerHTML = config === null || config === void 0 ? void 0 : config.text3;
    text1Ele.style.cssText = (0, style_object_1.formatStyleObjectToString)({ fontSize: '14px', color: '#fff' });
    text2Ele.style.cssText = (0, style_object_1.formatStyleObjectToString)({ fontSize: '14px', color: '#fff', marginTop: '24px' });
    if (!(config === null || config === void 0 ? void 0 : config.onClickTooltipButton))
        text3Ele.style.cssText = (0, style_object_1.formatStyleObjectToString)({
            fontSize: '16px',
            color: '#fff',
            marginTop: '24px',
            fontWeight: 500
        });
    if (!tooltipEleExist) {
        tooltipContent === null || tooltipContent === void 0 ? void 0 : tooltipContent.append(text1Ele, text2Ele, text3Ele);
        exports.tooltipEle.append(tooltipContent, tooltipTriangle);
    }
    else
        tooltipContent === null || tooltipContent === void 0 ? void 0 : tooltipContent.append(text3Ele);
    if (config === null || config === void 0 ? void 0 : config.onClickTooltipButton) {
        text3Ele.classList.add('guide-tooltip-btn');
        text3Ele.addEventListener('click', function (event) {
            var _a;
            event.stopPropagation();
            (_a = config === null || config === void 0 ? void 0 : config.onClickTooltipButton) === null || _a === void 0 ? void 0 : _a.call(config, event);
        });
    }
    if (!targetEle ||
        ((_a = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _a === void 0 ? void 0 : _a.left) ||
        ((_b = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _b === void 0 ? void 0 : _b.right) ||
        ((_c = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _c === void 0 ? void 0 : _c.top) ||
        ((_d = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _d === void 0 ? void 0 : _d.bottom) ||
        ((_e = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _e === void 0 ? void 0 : _e.transform)) {
        tooltipTriangle.style.opacity = '0';
        exports.tooltipEle.style.left = ((_f = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _f === void 0 ? void 0 : _f.left) || 'initial';
        exports.tooltipEle.style.top = ((_g = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _g === void 0 ? void 0 : _g.top) || 'initial';
        exports.tooltipEle.style.right = ((_h = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _h === void 0 ? void 0 : _h.right) || 'initial';
        exports.tooltipEle.style.bottom = ((_j = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _j === void 0 ? void 0 : _j.bottom) || 'initial';
        exports.tooltipEle.style.transform = ((_k = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _k === void 0 ? void 0 : _k.transform) || 'initial';
        if (!targetEle &&
            !((_l = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _l === void 0 ? void 0 : _l.left) &&
            !((_m = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _m === void 0 ? void 0 : _m.right) &&
            !((_o = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _o === void 0 ? void 0 : _o.top) &&
            !((_p = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _p === void 0 ? void 0 : _p.bottom) &&
            !((_q = config === null || config === void 0 ? void 0 : config.tooltipStyle) === null || _q === void 0 ? void 0 : _q.transform)) {
            var tooltipBoundary_1 = exports.tooltipEle.getBoundingClientRect();
            exports.tooltipEle.style.left = "calc(50% - ".concat(tooltipBoundary_1.width / 2, "px)");
            exports.tooltipEle.style.top = "calc(50% - ".concat(tooltipBoundary_1.height / 2, "px)");
        }
        return;
    }
    tooltipTriangle.style.opacity = '1';
    var tooltipBoundary = exports.tooltipEle.getBoundingClientRect();
    if (targetEle !== null) {
        var isVerticalCenter = false;
        var targetBoundaryFixed = targetEle.getBoundingClientRect();
        var scrollTop = (0, getScroll_1.getScrollTop)();
        var scrollLeft = (0, getScroll_1.getScrollLeft)();
        var targetBoundary = {
            width: targetBoundaryFixed.width,
            height: targetBoundaryFixed.height,
            top: targetBoundaryFixed.top + scrollTop,
            bottom: targetBoundaryFixed.bottom + scrollTop,
            left: targetBoundaryFixed.left + scrollLeft,
            right: targetBoundaryFixed.right + scrollLeft
        };
        console.log('targetBoundary', targetBoundary);
        if (targetBoundary.left - tooltipBoundary.width >= 20) {
            exports.tooltipEle.style.left = targetBoundary.left - tooltipBoundary.width - 20 + 'px';
            tooltipTriangle.style.left = tooltipBoundary.width - 13 + 'px';
            tooltipTriangle.style.transform = "rotate(45deg)";
        }
        else if (targetBoundary.right + tooltipBoundary.width + 20 > 0 &&
            targetBoundary.right + tooltipBoundary.width + 20 <= bindDOM.offsetWidth) {
            exports.tooltipEle.style.left = targetBoundary.right + 20 + 'px';
            tooltipTriangle.style.left = -11 + 'px';
            tooltipTriangle.style.transform = "rotate(-135deg)";
        }
        else {
            exports.tooltipEle.style.left = targetBoundary.left - (tooltipBoundary.width - targetBoundary.width) / 2 + 'px';
            isVerticalCenter = true;
            tooltipTriangle.style.left = tooltipBoundary.width / 2 - 12 + 'px';
        }
        var tooltilTop = 0;
        if (isVerticalCenter) {
            tooltilTop = targetBoundary.top - tooltipBoundary.height - 20;
            if (tooltilTop >= 0) {
                exports.tooltipEle.style.top = tooltilTop + 'px';
                tooltipTriangle.style.top = tooltipBoundary.height + 12 + 'px';
                tooltipTriangle.style.transform = "rotate(135deg)";
            }
            else {
                exports.tooltipEle.style.top = targetBoundary.bottom + 20 + 'px';
                tooltipTriangle.style.top = -24 + 'px';
                tooltipTriangle.style.transform = "rotate(90deg)";
            }
        }
        else {
            tooltilTop = (targetBoundary.bottom + targetBoundary.top) / 2 - tooltipBoundary.height / 2;
            if (tooltilTop > 0 && tooltilTop <= bindDOM.offsetHeight) {
                exports.tooltipEle.style.top = tooltilTop + 'px';
                tooltipTriangle.style.top = tooltipBoundary.height / 2 - 12 + 'px';
            }
            else if ((tooltilTop = targetBoundary.bottom - tooltipBoundary.height) >= 0) {
                exports.tooltipEle.style.top = tooltilTop + 'px';
                tooltipTriangle.style.top = tooltipBoundary.height - targetBoundary.height / 2 - 12 + 'px';
            }
            else {
                exports.tooltipEle.style.top = targetBoundary.top + 'px';
                tooltipTriangle.style.top = targetBoundary.height / 2 - 12 + 'px';
            }
        }
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        if ((config === null || config === void 0 ? void 0 : config.position) === 'top') {
            exports.tooltipEle.style.top = targetBoundary.top - tooltipBoundary.height - 20 + 'px';
            exports.tooltipEle.style.left = targetBoundary.left - (tooltipBoundary.width - targetBoundary.width) / 2 + 'px';
            tooltipTriangle.style.left = tooltipBoundary.width / 2 - 12 + 'px';
            tooltipTriangle.style.top = tooltipBoundary.height - 13 + 'px';
            tooltipTriangle.style.transform = "rotate(135deg)";
        }
        else if ((config === null || config === void 0 ? void 0 : config.position) === 'bottom') {
            var tooltipLeft = Math.min(windowWidth - 20 - tooltipBoundary.width, targetBoundary.left - (tooltipBoundary.width - targetBoundary.width) / 2);
            exports.tooltipEle.style.top = targetBoundary.bottom + 20 + 'px';
            exports.tooltipEle.style.left = tooltipLeft + 'px';
            // tooltipTriangle.style.left = tooltipBoundary.width / 2 - 12 + 'px';
            tooltipTriangle.style.left =
                Math.abs(tooltipLeft - targetBoundary.left) + targetBoundary.width / 2 - 12 + 'px';
            tooltipTriangle.style.top = -12 + 'px';
            tooltipTriangle.style.transform = "rotate(-45deg)";
        }
        else if ((config === null || config === void 0 ? void 0 : config.position) === 'left') {
            exports.tooltipEle.style.left = targetBoundary.left - tooltipBoundary.width - 20 + 'px';
            exports.tooltipEle.style.top = (targetBoundary.bottom + targetBoundary.top) / 2 - tooltipBoundary.height / 2 + 'px';
            tooltipTriangle.style.left = tooltipBoundary.width - 12 + 'px';
            tooltipTriangle.style.top = tooltipBoundary.height / 2 - 24 + 'px';
            tooltipTriangle.style.transform = "rotate(45deg)";
        }
        else if ((config === null || config === void 0 ? void 0 : config.position) === 'right') {
            exports.tooltipEle.style.left = targetBoundary.right + 20 + 'px';
            exports.tooltipEle.style.top = (targetBoundary.bottom + targetBoundary.top) / 2 - tooltipBoundary.height / 2 + 'px';
            tooltipTriangle.style.left = -24 + 'px';
            tooltipTriangle.style.top = tooltipBoundary.height / 2 - 12 + 'px';
            tooltipTriangle.style.transform = "rotate(-45deg)";
        }
    }
}
exports.createTooltip = createTooltip;
function deleteTooltip() {
    if (exports.tooltipEle) {
        exports.tooltipEle.remove();
        exports.tooltipEle = null;
        tooltipEleExist = false;
    }
}
exports.deleteTooltip = deleteTooltip;
var css = "\n    #guide-tooltip, .guide-tooltip {\n        position: absolute;\n        width: 400px;\n        padding: 32px;\n        border-radius: 10px;\n        background-color: #091E42;\n        border: 1px solid #344563;\n        box-shadow: 0 16px 20px 0 rgba(9, 30 ,66, 0.28);\n        z-index: 8996;\n        opacity: 1;\n        transition: all .3s ease;\n        top: 0px;\n        left: calc(50vw - 300px);\n        contain: layout style;\n    }\n    .guide-tooltip-text1, .guide-tooltip-text2 {\n        font-size: 14px;\n        color: #fff;\n    }\n    .guide-tooltip-text2, .guide-tooltip-text3 {\n        margin-top: 24px;\n    }\n    .guide-tooltip-text3 {\n        font-size: 16px;\n    }\n    .guide-tooltip-btn {\n        all: unset;\n        position: relative;\n        height: 40px;\n        padding: 0 16px;\n        margin-top: 20px;\n        background-color: white;\n        color: #091E42;\n        font-size: 16px;\n        border-radius: 2px;\n        cursor: pointer;\n        &:disabled {\n            opacity: .5;\n            cursor: not-allowed;\n        }\n    }\n    .guide-tooltip-btn:disabled {\n        opacity: .5;\n        cursor: not-allowed;\n    }\n    .guide-tooltip-btn::before {\n        content: '';\n        position: absolute;\n        left: 0;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        transition: all 0.2s ease;\n    }\n    .guide-tooltip-btn:hover::before {\n        background-color: rgba(9, 30, 66, 0.06);\n    }\n    #guide-tooltip-triangle {\n        position: absolute;\n        width: 24px;\n        height: 24px;\n        left: 0;\n        top: 0;\n        background-color: #091e42;\n        border-top: 1px solid #344563;\n        border-right: 1px solid #344563;\n        transform(-45deg);\n    }\n\n";
var head = document.head || document.getElementsByTagName('head')[0];
var style = document.createElement('style');
style.type = 'text/css';
head === null || head === void 0 ? void 0 : head.appendChild(style);
style === null || style === void 0 ? void 0 : style.appendChild(document === null || document === void 0 ? void 0 : document.createTextNode(css));
