import store from './store';
import actions from './actions';
import mutations from './mutations';

export default {
    namespaced: true,
    state: store.state,
    getters: store.getters,
    mutations: mutations,
    actions: actions
};
