import roleUtils from '@/common/utils/role';
import roleConfig from '@/common/configs/role.config';
import { checkModuleRole, isSingleSpa, isNpPackage } from '@/common/services/singleSpa.service';

function bind(el, binding) {
    const { type, role } = binding.value || {};
    if (type) {
        const roleType = roleConfig.roleMapping[type] || type;
        const canVisible =
            isSingleSpa() && isNpPackage() ? checkModuleRole(roleType) : roleUtils.includeRoleWithStore(type, role);
        canVisible ? el.classList.remove('no-role') : el.classList.add('no-role');
    } else {
        throw new Error(`need roles! Like v-role`);
    }
}

function unbind(el) {
    el.classList.remove('no-role');
}

export default {
    name: 'role',
    bind: bind,
    update: function(el, binding) {
        if (binding.value === binding.oldValue) return;
        bind(el, binding);
    },
    unbind: unbind
};
