"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rolesNew = exports.roleLimited = void 0;
var i18n_1 = require("@/assets/i18n/");
exports.roleLimited = {
    createUsergroup: 1,
    editUsergroup: 1,
    copyUsergroup: 1,
    engageWithUsergroup: 1,
    deleteUsergroup: 1,
    saveUsergroup: 1,
    saveNewUsergroup: 1,
    createCampaign: 1,
    createGoal: 1,
    crossDomain: 1
};
exports.rolesNew = {
    owner: {
        canEdit: true,
        name: i18n_1.default.t('user_setting.role_owner'),
        dsec: ''
    },
    admin: {
        canEdit: true,
        name: i18n_1.default.t('user_setting.role_admin'),
        desc: i18n_1.default.t('user_setting.role_admin_desc')
    },
    readonly: {
        canEdit: false,
        name: i18n_1.default.t('user_setting.role_readonly'),
        desc: i18n_1.default.t('user_setting.role_readonly_desc')
    }
};
var roles = [
    {
        key: 'owner',
        canEdit: true,
        name: i18n_1.default.t('user_setting.role_owner')
    },
    {
        key: 'admin',
        canEdit: true,
        name: i18n_1.default.t('user_setting.role_admin'),
        desc: i18n_1.default.t('user_setting.role_admin_desc')
    },
    {
        key: 'readonly',
        canEdit: false,
        name: i18n_1.default.t('user_setting.role_readonly'),
        desc: i18n_1.default.t('user_setting.role_readonly_desc')
    }
];
exports.default = {
    roles: roles,
    roleLimited: exports.roleLimited,
    rolesNew: exports.rolesNew
};
