<template>
    <div class="block-settings has-tab classic-settings">
        <div class="block-settings__header">
            <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
            <h3>{{ $t(block.blockName) }}</h3>
        </div>

        <div class="block-settings__tab">
            <settings-tab :tab="currentTab" @change="handleTabChange"></settings-tab>
        </div>

        <div class="block-settings__content-tab" v-if="currentTab === 'content'">
            <el-tabs v-model="currentContentCode">
                <el-tab-pane
                    v-for="item in content"
                    :key="item.code"
                    :label="$t(item.name)"
                    :name="item.code"
                ></el-tab-pane>
            </el-tabs>
        </div>

        <div class="block-settings__container">
            <!-- content -->
            <template v-if="currentTab === 'content'">
                <component
                    v-if="currentBlockConfig"
                    :is="currentBlockConfig.component"
                    :isSubModel="true"
                    :block="currentBlock"
                    :widgetIndex="widgetIndex"
                    @gotoWidget="handleGotoWidget"
                    @contentChange="handleBlockContentUpdate"
                    @settingsChange="handleBlockSettingsUpdate"
                ></component>
            </template>

            <div class="block-settings__item-wrap" v-else>
                <!-- Layout-->
                <div class="block-settings__item" v-if="columnHasMultiseriate">
                    <div class="block-settings__item-title">{{ $t('creative_editor.item_layout') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <div
                            class="block-item__icon"
                            v-for="item in layoutOptions"
                            :key="item.code"
                            :class="{'active': settings.layout === item.code}"
                            @click="handleLayoutClick(item.code)"
                        >
                            <pt-icon
                                :icon="'pt-icon--' + item.icon"
                                :iconStyle="{width: '100%', height: '100%'}"
                            ></pt-icon>
                        </div>
                    </div>
                </div>

                <!-- Spacing -->
                <div class="block-settings__item" v-if="columnHasMultiseriate">
                    <div class="block-settings__item-title">{{ $t('creative_editor.item_spacing') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-slider
                            class="block-slider pt-ml10 pt-mr20"
                            v-model="settings.spacing"
                            :show-tooltip="false"
                            :min="spaceingOptions.min"
                            :max="spaceingOptions.max"
                            @change="handleSettingsChange"
                        ></el-slider>
                        <pt-input-number
                            class="max-width-60"
                            size="large"
                            v-model="settings.spacing"
                            :min="spaceingOptions.min"
                            :max="spaceingOptions.max"
                            @change="handleSettingsChange"
                        ></pt-input-number>
                    </div>
                </div>

                <!-- Alignment -->
                <div class="block-settings__item" v-if="columnHasMultiseriate">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.creative_alignment') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-radio-group
                            size="small"
                            v-model="settings.alignment"
                            @change="handleSettingsChange"
                        >
                            <el-radio-button
                                v-for="item in alignmentOptions"
                                :key="item.code"
                                :label="item.code"
                            >
                                <pt-icon :icon="'pt-icon--' + item.icon"></pt-icon>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>

                <!-- Edge to edge -->
                <div class="block-settings__item">
                    <div class="block-settings__item-title two-cloumn no-margin">
                        <span>{{ $t('creative_editor.edge_to_edge') }}</span>

                        <div class="title-options">
                            <el-switch
                                class="el-switch__pt-small"
                                v-model="settings.noPadding"
                                :width="28"
                                @change="handleSettingsChange"
                            ></el-switch>
                        </div>
                    </div>
                </div>

                <!-- Background color -->
                <div class="block-settings__item">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.creative_widget_bg') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-color-picker
                            class="pt-mr10"
                            v-model="settings.backgroundColor"
                            @change="handleSettingsChange"
                        ></el-color-picker>
                        <el-input
                            size="small"
                            v-model="settings.backgroundColor"
                            @change="handleSettingsChange"
                        ></el-input>
                    </div>
                </div>

                <!-- Margin top -->
                <div class="block-settings__item">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.block_margin_top') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-slider
                            class="block-slider pt-ml10 pt-mr20"
                            v-model="settings.marginTop"
                            :show-tooltip="false"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></el-slider>
                        <pt-input-number
                            class="max-width-60"
                            size="large"
                            v-model="settings.marginTop"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></pt-input-number>
                    </div>
                </div>

                <!-- Margin bottom -->
                <div class="block-settings__item">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.block_margin_bottom') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-slider
                            class="block-slider pt-ml10 pt-mr20"
                            v-model="settings.marginBottom"
                            :show-tooltip="false"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></el-slider>
                        <pt-input-number
                            class="max-width-60"
                            size="large"
                            v-model="settings.marginBottom"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></pt-input-number>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from './classic.config';
import SettingsTab from '../SettingsTab';
import { tabOptions } from '../../configs/creative.config';
import blocksConfig from '../../configs/blocks.config';

export default {
    name: 'ClassicSettings',

    props: {
        block: {
            type: Object,
            required: true
        },
        widgetIndex: Number
    },

    data() {
        const defaultTab = tabOptions.find(item => item.default);
        const defaultContentTab = config.content.find(item => item.default);
        const { layoutOptions, alignmentOptions, marginOptions, spaceingOptions } = config;
        return {
            layoutOptions,
            alignmentOptions,
            marginOptions,
            spaceingOptions,

            currentTab: '',
            currentContentCode: '',

            content: [],
            settings: {}
        };
    },

    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        },

        currentBlockConfig() {
            return blocksConfig.find(item => item.code === this.currentContentCode);
        },

        currentBlockCompont() {
            return this.currentBlockConfig && this.currentBlockConfig.component;
        },

        currentBlock() {
            return {
                blockId: this.block.blockId,
                blockName: this.currentBlockConfig.name,
                blockContent: this.currentContentValue.content,
                blockSettings: this.currentContentValue.settings
            };
        },

        columnHasMultiseriate() {
            return this.settings.buttonNumber !== 'one';
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            const defaultTab = tabOptions.find(item => item.default);
            const defaultContentTab = config.content.find(item => item.default);

            this.currentTab = defaultTab.code;
            this.currentContentCode = defaultContentTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
        },

        handleBack() {
            this.$emit('back');
        },

        handleTabChange(tab) {
            this.currentTab = tab;

            if (tab === 'content' && !this.columnHasMultiseriate) {
                this.currentContentCode = this.content[0].code;
            }
            this.$refs.blockScrollbar && this.$refs.blockScrollbar.scrollToPos('top');
        },

        handleGotoWidget(val) {
            this.$emit('gotoWidget', val);
        },

        handleBlockContentUpdate(content, params) {
            this.$set(this.currentContentValue, 'content', content);
            this.handleContentChange(params);
        },

        handleBlockSettingsUpdate(settings) {
            this.$set(this.currentContentValue, 'settings', settings);
            this.handleSettingsChange();
        },

        handleContentChange(params) {
            this.$emit('contentChange', this.content, params);
        },

        handleSettingsChange() {
            this.$emit('settingsChange', this.settings);
        },

        handleLayoutClick(layout) {
            this.settings.layout = layout;
            this.handleSettingsChange();
        },

        notice() {
            let block = Object.assign({}, this.block, {
                style: this.style,
                content: this.content,
                settings: this.settings
            });
            this.$emit('change', block);
        }
    },

    watch: {
        'block.blockId': function() {
            this.init();
        }
    },

    components: {
        SettingsTab
    }
};
</script>
