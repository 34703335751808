<template>
    <a
        v-if="domType === 'a'"
        class="pt-link"
        :class="['pt-link--size-' + size, { 'pt-link--underline': underline }, 'pt-link--type-' + type]"
        :href="href"
        :target="target"
    >
        <slot></slot>
    </a>

    <button
        v-else-if="domType === 'btn'"
        class="pt-link"
        :class="['pt-link--size-' + size, 'pt-link--type-' + type, { 'pt-link--underline': underline }]"
        @click="handleDivClick"
    >
        <slot></slot>
    </button>

    <div
        v-else
        class="pt-link"
        @click="handleClick"
        :class="['pt-link--size-' + size, 'pt-link--type-' + type, { 'pt-link--underline': underline }]"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ptLink',

    props: {
        target: {
            type: String,
            default: '_self'
        },
        domType: {
            type: String,
            default: 'div',
            enum: ['btn', 'a', 'div']
        },
        href: {
            type: String
        },
        size: {
            type: String,
            default: 'default',
            enum: ['small', 'default', 'big', 'large', 'xlarge']
        },
        type: {
            type: String,
            default: 'default',
            enum: ['default', 'primary']
        },
        underline: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleClick() {
            this.$emit('back');
        },
        handleDivClick() {
            this.$emit('click');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/import.scss';

@mixin linkSize($svgSize, $fontSize) {
    font-size: $fontSize;

    svg {
        width: $svgSize !important;
        height: $svgSize !important;
    }
}

.pt-link {
    display: flex;
    align-items: center;
    color: $pt-black-600;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:hover {
        svg {
            fill: $pt-black-300;
        }
        span {
            color: $pt-black-900;
        }
    }

    svg {
        fill: $pt-black-90;
        margin-right: 4px;
        cursor: pointer;
    }

    //************size******************
    &--size-default {
        @include linkSize(12px, 14px);
    }
    &--size-small {
        @include linkSize(12px, 12px);
    }
    &--size-big {
        @include linkSize(16px, 14px);
    }
    &--size-large {
        @include linkSize(20px, 16px);
    }
    &--size-xlarge {
        @include linkSize(24px, 18px);
    }
    &--underline {
        text-decoration: underline;
    }
    &--type-default {
        color: $pt-black-900;
        &:hover,
        &:focus,
        &:active {
            color: $pt-black-1000 !important;
        }
    }
    &--type-primary {
        color: $pt-green-60;
        svg {
            fill: $pt-green-60;
        }
        &:hover,
        &:focus,
        &:active {
            color: $pt-black-1000 !important;
            svg {
                fill: $pt-black-1000;
            }
        }
    }
}
</style>
