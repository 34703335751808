"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatStyleObjectToString = exports.getStyleObjectFromString = void 0;
function formatStringToCamelCase(str) {
    var splitted = str.split('-');
    if (splitted.length === 1)
        return splitted[0];
    return (splitted[0] +
        splitted
            .slice(1)
            .map(function (word) { return word[0].toUpperCase() + word.slice(1); })
            .join(''));
}
;
var upperCaseReg = /[A-Z]/;
function formatCamelCaseStringToNormal(str) {
    var outStr = '';
    for (var _i = 0, str_1 = str; _i < str_1.length; _i++) {
        var char = str_1[_i];
        if (upperCaseReg.test(char))
            outStr += "-".concat(char.toLowerCase());
        else
            outStr += char;
    }
    return outStr;
}
;
function getStyleObjectFromString(str) {
    var style = {};
    str.split(';').forEach(function (el) {
        var _a = el.split(':'), property = _a[0], value = _a[1];
        if (!property)
            return;
        var formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });
    return style;
}
exports.getStyleObjectFromString = getStyleObjectFromString;
;
function formatStyleObjectToString(styleObj) {
    var styleString = Object.entries(styleObj).map(function (style) { return "".concat(formatCamelCaseStringToNormal(style[0]), ": ").concat(String(style[1])); }).join(';');
    return styleString;
}
exports.formatStyleObjectToString = formatStyleObjectToString;
;
