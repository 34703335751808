<template>
    <div class="pt-highmaps" v-loading="isLoading"></div>
</template>

<script>
import Highcharts from 'highcharts';
import highmapsService from './highmaps.service.js';
import cloneUtils from '../../../utils/clone.utils';

export default {
    name: 'ptHighmaps',

    props: {
        mapCode: {
            type: String,
            default: 'world', //'world', 'country'
        },
        series: {
            type: Array,
            default: function() {
                return [
                    // {code: 'China', region: 'Users', value: 75, showValue: 75}
                ]
            }
        },
    },

    data() {
        return {
            target: null,
            isLoading: true,
            currentOptions: null
        };
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.destroy();
    },

    methods: {
        init() {
            this.isLoading = false;
            this.optionsInit();
        },

        optionsInit() {
            const _self = this;
            highmapsService.optionsInit(
                this.mapCode,
                this.series,
                options => {
                    const createMapNavFunc = function() {
                        highmapsService.createMapNavigation(_self.$el, _self.mapCode, this, _self.handleMapClick);
                    };
                    options.plotOptions.series.point.events.click = function() {
                        let code = this.code;
                        if (Highcharts.mapDataIndex.Countries[this.highchartCode] && _self.mapCode !== code) {
                            _self.handleMapClick(code);
                        }
                    };
                    // 右下角的按钮
                    options.chart.events.load = createMapNavFunc;
                    options.chart.events.redraw = createMapNavFunc;
                    this.draw(options);
                }
            );
        },

        destroy() {
            if (this.target && this.target.destroy) this.target.destroy();
        },

        draw(options) {
            this.target = Highcharts.mapChart(this.$el, options);
        },

        update() {
            /* TODO highcharts版本升级到7.1.1之后，update方法失效 */
            // if(this.target && this.target.update) {
            //     let updateOptions = highmapsService.setStyleOptions(this.currentSettings);
            //     this.target.update(updateOptions);
            // } else {
                this.init();
            // }
        },

        // 重新排版
        refresh() {
            this.target && this.target.reflow();
        },

        // 地图上的点击事件
        handleMapClick(mapCode) {
            this.$emit('change', mapCode);
        }
    }
};
</script>

<style lang="scss">
.pt-highmaps {
    height: 100%;
    cursor: pointer;

    .highcharts-root {
        overflow: hidden;
    }
}
</style>
