/**
 * 该指令监听窗口resize，并返回当前窗口的innerWidth
 */

function insertedFn(el, binding) {
    var callback = binding.value;
    var debounce = 200;
    var options = {
        passive: true
    };
    var debounceTimeout = null;
    var onResize = function() {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(callback(window.innerWidth), debounce, options);
    };
    window.addEventListener('resize', onResize, options);
    el._onResize = {
        callback: callback,
        options: options
    };
}

function unbindFn(el) {
    var callback = el._onResize.callback;
    var options = el._onResize.options;
    window.removeEventListener('resize', callback, options);
    delete el._onResize;
}

export default {
    name: 'resize',
    inserted: insertedFn,
    unbind: unbindFn
};
