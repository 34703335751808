<template>
    <div class="block-settings has-tab form-settings">
        <div class="block-settings__header">
            <pt-button type="text" color="black" size="medium" @click="handleBack">{{
                $t('common.button_back')
            }}</pt-button>
            <h3>{{ $t('creative_editor.theme_form') }}</h3>
        </div>

        <div class="block-settings__tab">
            <settings-tab :tabOptions="formTabOptions" :tab="currentTab" @change="handleTabChange"></settings-tab>
        </div>

        <div class="block-settings__content-tab block-settings__content-tab--form" v-if="currentTab === 'content'">
            <el-tabs v-model="currentContentCode">
                <el-tab-pane
                    v-for="item in content"
                    :key="item.code"
                    :label="$t(item.name)"
                    :name="item.code"
                ></el-tab-pane>
            </el-tabs>
            <div class="block-settings__content-tab--design">
                <pt-button
                    :class="{ active: currentContentCode === 'design' }"
                    type="text"
                    color="black"
                    @click="handleDesignClick"
                    >{{ $t('creative_editor.tab_design') }}</pt-button
                >
            </div>
        </div>
        <div class="block-settings__container">
            <!-- Content -->
            <template v-if="currentTab === 'content'">
                <component
                    v-if="currentBlockConfig"
                    :is="currentBlockConfig.component"
                    :block="currentBlock"
                    :widgetIndex="widgetIndex"
                    :isSubModel="true"
                    refer="form"
                    tab="form"
                    @gotoWidget="handleGotoWidget"
                    @contentChange="handleBlockContentUpdate"
                    @settingsChange="handleBlockSettingsUpdate"
                ></component>

                <form-design-settings
                    :block="block"
                    :blockList="blockList"
                    :content="content"
                    :settings="settings"
                    v-if="currentContentCode === 'design'"
                    @contentChange="handleBlockContentUpdate"
                    @settingsChange="handleBlockSettingsUpdate"
                ></form-design-settings>
            </template>
            <!-- Data -->
            <div class="block-settings__item-wrap" v-else>
                <!-- <el-collapse v-model="activeDataBlock" accordion>
                    <el-collapse-item name="push" class="form-design-settings__block">
                <div slot="title" class="form-design-settings__title">Push to DB</div>-->
                <pt-scrollbar>
                    <div class="form-design-settings__content">
                        <div class="block-settings__container">
                            <div class="block-settings__item-wrap">
                                <!-- switch -->
                                <kits
                                    type="switch"
                                    :title="$t('creative_editor.item_db_switch')"
                                    target="pushToDB"
                                    :value="dataSettings.pushToDB"
                                    @change="handleBlockDataUpdate"
                                ></kits>
                                <div class="block-settings__hr"></div>
                                <!-- API -->
                                <div class="block-settings__item" v-if="dataSettings.pushToDB">
                                    <div class="block-settings__item-title">
                                        {{ $t('creative_editor.item_db_api') }}
                                    </div>
                                    <div class="block-settings__item-container">
                                        <el-input
                                            type="textarea"
                                            rows="4"
                                            v-model="dataSettings.dbApi"
                                            :placeholder="$t('creative_editor.item_db_api_placeholder')"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="block-settings__item" v-if="dataSettings.pushToDB">
                                    <br />
                                    <br />

                                    <p class="block-settings__item-tips">{{ $t('creative_editor.item_db_tips') }}</p>
                                    <br />
                                    <div class="data-sample" v-html="$t('creative_editor.item_db_sample')"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </pt-scrollbar>
                <!--  </el-collapse-item> -->
                <!--          <el-collapse-item name="notification" class="form-design-settings__block">
                        <div slot="title" class="form-design-settings__title">Notification</div>
                        <div class="form-design-settings__content">Notification Settings</div>
                    </el-collapse-item> 
                </el-collapse>-->
            </div>
        </div>
    </div>
</template>
<script>
import config from './form.config';
import blocksConfig from '../../configs/blocks.config';
import { formTabOptions } from '../../configs/creative.config';

import SettingsTab from '../SettingsTab';
import Kits from '../../kits/Kits.vue';
import FormDesignSettings from './FormDesignSettings.vue';

export default {
    name: 'FormSettings',
    props: {
        widgetIndex: Number,
        block: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeDataBlock: 'push',
            currentTab: '',
            currentContentCode: '',
            formTabOptions: [],
            content: [],
            settings: {},
            dataSettings: {},
            blockList: []
        };
    },
    created() {
        this.init();
    },
    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        },

        currentBlockConfig() {
            return blocksConfig.find(item => item.code === this.currentContentCode);
        },

        currentBlockCompont() {
            return this.currentBlockConfig && this.currentBlockConfig.component;
        },

        currentBlock() {
            return {
                blockId: this.block.blockId,
                blockName: this.currentBlockConfig.name,
                blockContent: this.currentContentValue.content,
                blockSettings: this.currentContentValue.settings,
                blockData: this.currentContentValue.dataSettings || {}
            };
        }
    },
    methods: {
        handleBack() {
            this.$emit('back');
        },
        init() {
            const defaultTab = formTabOptions.find(item => item.default);
            const defaultContentTab = config.content.find(item => item.default);

            this.currentTab = defaultTab.code;
            this.currentContentCode = defaultContentTab.code;

            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
            this.dataSettings = this.block.blockData;

            this.formTabOptions = formTabOptions;
            this.blockList = config.formBlocks;
        },
        handleGotoWidget(gotoIndex) {
            console.log('form setting goto widget');
            this.$emit('gotoWidget', gotoIndex);
        },
        handleTabChange(tab) {
            this.currentTab = tab;
            console.log('handleTabChange');
        },
        handleDesignClick() {
            this.currentContentCode = 'design';
        },
        handleBlockContentUpdate(content, params) {
            this.$emit('contentChange', this.content, params);
        },
        handleBlockSettingsUpdate(settings, type) {
            if (type === 'input') {
                this.$set(this.settings, type, settings);
                this.$set(this.settings.field, 'inputSettings', settings);
            }
            this.$emit('settingsChange', this.settings);
        },
        handleBlockDataUpdate(val) {
            //TODO form data settings
            console.log('handleBlockDataUpdate', val);
            this.$set(this.dataSettings, val.type, val.value);
            this.$emit('dataSettingsChange', this.dataSettings);
        }
    },
    components: {
        SettingsTab,
        FormDesignSettings,
        Kits
    }
};
</script>
<style lang="scss">
@import '@/styles/import.scss';
.data-sample {
    padding: 8px;
    color: $pt-black-600;
    background-color: $pt-black-20;
    border-radius: 4px;
}
.creative-editor {
    .form-settings {
        .block-settings__container {
            height: calc(100% - 64px - 80px);
        }
    }
}
</style>
