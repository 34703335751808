import ButtonConfig from '../blocks/button/button.config';
import ButtonServices from '../blocks/button/button.services';
import ButtonSettings from '../blocks/button/ButtonSettings.vue';

import ClassicConfig from '../blocks/classic/classic.config';
import ClassicServices from '../blocks/classic/classic.services';
import ClassicSettings from '../blocks/classic/ClassicSettings.vue';

import ImageConfig from '../blocks/image/image.config';
import ImageServices from '../blocks/image/image.services';
import ImageSettings from '../blocks/image/ImageSettings.vue';

import ImagetextConfig from '../blocks/imagetext/imagetext.config';
import ImagetextServices from '../blocks/imagetext/imagetext.services';
import ImagetextSettings from '../blocks/imagetext/ImagetextSettings.vue';

import TextConfig from '../blocks/text/text.config';
import TextServices from '../blocks/text/text.services';
import TextSettings from '../blocks/text/TextSettings.vue';

import VideoConfig from '../blocks/video/video.config';
import VideoServices from '../blocks/video/video.services';
import VideoSettings from '../blocks/video/VideoSettings.vue';

import SpacingConfig from '../blocks/spacing/spacing.config';
import SpacingServices from '../blocks/spacing/spacing.services';
import SpacingSettings from '../blocks/spacing/SpacingSettings.vue';

import CountdownConfig from '../blocks/countdown/countdown.config';
import CountdownSettings from '../blocks/countdown/CountdownSettings';
import CountdownServices from '../blocks/countdown/countdown.services';

import SlideConfig from '../blocks/slide/slide.config';
import SlideSettings from '../blocks/slide/SlideSettings';
import SlideServices from '../blocks/slide/slide.services';

import FormConfig from '../blocks/form/form.config';
import FormServices from '../blocks/form/form.services';
import FormSettings from '../blocks/form/FormSettings.vue';

import InputConfig from '../blocks/input/input.config';
import InputServices from '../blocks/input/input.services';
import InputSettings from '../blocks/input/InputSettings.vue';

import FieldConfig from '../blocks/field/field.config';
import FieldServices from '../blocks/field/field.services';
import FieldSettings from '../blocks/field/FieldSettings.vue';

export default [
    {
        name: 'creative_editor.theme_imagetext',
        code: 'imagetext',
        icon: 'pt-icon--block-image-text',
        config: ImagetextConfig,
        services: ImagetextServices,
        component: ImagetextSettings
    },
    {
        name: 'creative_editor.theme_classic',
        code: 'classicGroup',
        icon: 'pt-icon--block-classic-group',
        config: ClassicConfig,
        services: ClassicServices,
        component: ClassicSettings
    },
    {
        name: 'creative_editor.theme_text',
        code: 'text',
        icon: 'pt-icon--block-text',
        config: TextConfig,
        services: TextServices,
        component: TextSettings
    },
    {
        name: 'creative_editor.theme_image',
        code: 'image',
        icon: 'pt-icon--block-image',
        config: ImageConfig,
        services: ImageServices,
        component: ImageSettings
    },
    {
        name: 'creative_editor.theme_button',
        code: 'button',
        icon: 'pt-icon--block-button',
        config: ButtonConfig,
        services: ButtonServices,
        component: ButtonSettings
    },
    {
        name: 'creative_editor.theme_video',
        code: 'video',
        icon: 'pt-icon--block-video',
        config: VideoConfig,
        services: VideoServices,
        component: VideoSettings
    },
    {
        name: 'creative_editor.theme_spacing',
        code: 'spacing',
        icon: 'pt-icon--block-spacing',
        config: SpacingConfig,
        services: SpacingServices,
        component: SpacingSettings
    },
    // {
    //     name: 'creative_editor.theme_countdown',
    //     code: 'countDown',
    //     icon: 'block-countdown',
    //     config: CountdownConfig,
    //     services: CountdownServices,
    //     component: CountdownSettings,
    //     isNotAllowMutiple: true
    // },
    {
        name: 'creative_editor.theme_slide',
        code: 'slide',
        icon: 'pt-icon--block-slide',
        config: SlideConfig,
        services: SlideServices,
        component: SlideSettings
    },
    {
        name: 'creative_editor.theme_form',
        code: 'form',
        icon: 'pt-icon--block-form',
        config: FormConfig,
        services: FormServices,
        component: FormSettings,
        isNotAllowMutiple: true
    },
    {
        name: 'Input',
        code: 'input',
        icon: 'block-slide',
        showIt: function() {
            return false;
        },
        config: InputConfig,
        services: InputServices,
        component: InputSettings
    },
    {
        name: 'creative_editor.theme_form',
        code: 'field',
        icon: 'block-slide',
        config: FieldConfig,
        showIt: function() {
            return false;
        },
        services: FieldServices,
        component: FieldSettings
    }
];
