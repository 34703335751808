"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var roleNew_1 = require("@/common/utils/roleNew");
var state = {
    scrollbar: null,
    envConfigs: null,
    routerIsLoading: true,
    userInfo: null,
    profiles: null,
    profileInfo: null,
    packageInfo: null,
    profileTrackingCode: null,
    hideMainMenu: false,
    authDialogVisible: false,
    profileGuideInfo: null,
    accountProfileInfo: null,
    profileCollaborativeUser: null,
    queryToken: [],
    asideIsFolded: false,
    // roleList: ['read'], //身份信息
    permissionsDict: [],
    permissionList: [] //权限列表
};
var getters = {
    authDialogVisible: function (state) { return state.authDialogVisible; },
    envConfigs: function (state) { return state.envConfigs; },
    routerIsLoading: function (state) { return state.routerIsLoading; },
    userInfo: function (state) { return state.userInfo; },
    profiles: function (state) { return state.profiles; },
    profileInfo: function (state) { return state.profileInfo; },
    packageInfo: function (state) { return state.packageInfo; },
    profileTrackingCode: function (state) { return state.profileTrackingCode; },
    hideMainMenu: function (state) { return state.hideMainMenu; },
    canEdit: function (state) { var _a; return roleNew_1.default.hasEditRole((_a = state.profileInfo) === null || _a === void 0 ? void 0 : _a.currentUserRole); }
};
exports.default = {
    state: state,
    getters: getters
};
