"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var public_apis_1 = require("@/apis/public.apis");
var useVideo_1 = require("./useVideo");
var deviceTypeMap = {
    pc: { width: 1440, height: 768 },
    mobile: { width: 375, height: 667 },
    tablet: { width: 767, height: 1024 }
};
function addVersionScreenshot(remoteData) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all((_a = remoteData === null || remoteData === void 0 ? void 0 : remoteData.versions) === null || _a === void 0 ? void 0 : _a.map(function (version) { return __awaiter(_this, void 0, void 0, function () {
                        var terminals, thumbnail, targetTerminal, widgetMode, target, targetPop, video, img, e_1;
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
                        return __generator(this, function (_x) {
                            switch (_x.label) {
                                case 0:
                                    _x.trys.push([0, 4, , 5]);
                                    terminals = (_b = (_a = version === null || version === void 0 ? void 0 : version.terminals) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (terminalA, terminalB) { return terminalA.type === 2 && terminalB.type !== 2 ? -1 : 1; });
                                    thumbnail = { addFix: false, deviceType: 'pc' };
                                    targetTerminal = void 0;
                                    if (remoteData.engageVersion === '3.0') {
                                        // 优先截 popup然后是stickybar
                                        targetTerminal = terminals.find(function (terminal) { var _a, _b; return ((_a = terminal.popup) === null || _a === void 0 ? void 0 : _a.length) > 0 || ((_b = terminal.stickybar) === null || _b === void 0 ? void 0 : _b.length) > 0; });
                                        if (targetTerminal)
                                            thumbnail.customDomSelector = "[data-menu-action=\"insert.popup\"]";
                                        else { // 没有就截整页的图
                                            thumbnail.url = remoteData.baseURL;
                                        }
                                    }
                                    else {
                                        targetTerminal = terminals.find(function (terminal) { var _a, _b; return (_b = (_a = terminal === null || terminal === void 0 ? void 0 : terminal.widgets) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.widgetMode; });
                                        if (targetTerminal === undefined)
                                            return [2 /*return*/];
                                        widgetMode = (_d = (_c = targetTerminal.widgets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.widgetMode;
                                        if (widgetMode === 'visualModel' && ((_j = (_h = (_g = (_f = (_e = targetTerminal === null || targetTerminal === void 0 ? void 0 : targetTerminal.widgets) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.widgetNodes) === null || _g === void 0 ? void 0 : _g.html) === null || _h === void 0 ? void 0 : _h.indexOf) === null || _j === void 0 ? void 0 : _j.call(_h, 'data-engage-block="engage"')) !== -1)
                                            thumbnail.customDomSelector = "[data-engage-block=\"engage\"]";
                                        else
                                            thumbnail.customDomSelector = "body > *:nth-child(1)";
                                    }
                                    if (!targetTerminal) {
                                        if (terminals === null || terminals === void 0 ? void 0 : terminals[0])
                                            thumbnail.deviceType = terminals[0].type === 2 ? 'pc' : terminals[0].type === 1 ? 'mobile' : 'tablet';
                                        thumbnail.width = deviceTypeMap[thumbnail.deviceType].width;
                                        thumbnail.height = deviceTypeMap[thumbnail.deviceType].height;
                                    }
                                    else {
                                        target = targetTerminal;
                                        if (remoteData.engageVersion === '3.0') {
                                            targetPop = targetTerminal.popup.length > 0 ? targetTerminal.popup : targetTerminal.stickybar;
                                            target = targetPop === null || targetPop === void 0 ? void 0 : targetPop[0];
                                        }
                                        video = (0, useVideo_1.default)((_m = (_l = (_k = target === null || target === void 0 ? void 0 : target.widgets) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.widgetNodes) === null || _m === void 0 ? void 0 : _m.html);
                                        if (video) {
                                            thumbnail.isDelay = true;
                                        }
                                        thumbnail.htmlString = "\n                    <body>\n                        <style type=\"text/css\">\n                            ".concat(((_q = (_p = (_o = target === null || target === void 0 ? void 0 : target.widgets) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.widgetNodes) === null || _q === void 0 ? void 0 : _q.commonStyle) || '', "\n                            ").concat(((_t = (_s = (_r = target === null || target === void 0 ? void 0 : target.widgets) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.widgetNodes) === null || _t === void 0 ? void 0 : _t.css) || '', "\n                            * {\n                                animation: none !important;\n                                transition: none !important;\n                            }\n                        </style>\n                        ").concat((_w = (_v = (_u = target === null || target === void 0 ? void 0 : target.widgets) === null || _u === void 0 ? void 0 : _u[0]) === null || _v === void 0 ? void 0 : _v.widgetNodes) === null || _w === void 0 ? void 0 : _w.html, "\n                        ").concat(video ? video : '', "\n                    </body>\n                ");
                                    }
                                    if (!(remoteData.engageVersion !== '3.0'
                                        && (Boolean(version.controlGroup) === true
                                            || !targetTerminal))) return [3 /*break*/, 1];
                                    version.screenshot = "";
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, public_apis_1.default
                                        .otherServiceApi(null, {
                                        where: {
                                            service: 'draw',
                                            type: 'post',
                                            path: '/api/html2img',
                                            body: thumbnail
                                        }
                                    })];
                                case 2:
                                    img = _x.sent();
                                    if (img === null || img === void 0 ? void 0 : img.url)
                                        version.screenshot = img === null || img === void 0 ? void 0 : img.url;
                                    _x.label = 3;
                                case 3: return [3 /*break*/, 5];
                                case 4:
                                    e_1 = _x.sent();
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); }))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.default = addVersionScreenshot;
