const state = {
    userGroupInfo: null,
    userGroupName: '',
    userProperties: null
};

const getters = {
    usersFilter: state => state.userGroupInfo?.content || {},
    userGroupName: state => state.userGroupName || ''
};

export default {
    state,
    getters
};
