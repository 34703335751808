<template>
    <div class="block-settings__item">
        <div class="block-settings__item-title">{{ title }}</div>
        <div class="block-settings__item-container">
            <pt-input-number
                size="large"
                v-model="currentValue"
                :min="min"
                :max="max"
                @change="handleChange"
            ></pt-input-number>
        </div>
    </div>
</template>
<script>
export default {
    name: 'KitsInputNumber',
    props: {
        title: String,
        target: String,
        value: {
            required: true
        },
        min: Number,
        max: Number
    },
    data() {
        return {
            currentValue: this.value
        };
    },
    methods: {
        handleChange(val) {
            //

            console.log('KitsInputNumber', val);
            this.$emit('change', val, this.target);
        }
    }
};
</script>