"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var campaign_store_1 = require("@/components/campaign/setting/campaign.store");
var campaignStore = undefined;
var getters = {
    campaignStore: function () {
        if (!campaignStore)
            campaignStore = new campaign_store_1.default();
        return campaignStore;
    },
    test: function (a, b, c, d) {
        return 1;
    },
};
exports.default = getters;
