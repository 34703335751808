<template>
    <div class="pt-select" v-pt-clickoutside="handleClose">
        <div class="pt-input pt-input--small pt-input--suffix" @click="toggleMenu">
            <input
                class="pt-input__inner"
                ref="input"
                type="text"
                autocomplete="off"
                v-model.trim="query"
                :size="inputSize"
                :placeholder="currentPlaceholder"
                @blur="handleBlur"
                @focus="handleFocus"
                @input="handleInput"
                @keydown.up.prevent="selectUp"
                @keydown.down.prevent="selectDown"
                @keydown.enter.stop="selectOption"
                @keydown.esc.stop.prevent="hideDropdown"
            />
            <span class="pt-input__suffix">
                <span class="pt-input__suffix-inner">
                    <i class="pt-select__caret pt-input__icon el-icon-arrow-up" :class="{ 'is-reverse': visible }"></i>
                </span>
            </span>
        </div>

        <transition name="el-zoom-in-top">
            <div class="pt-select__dropdown" v-if="visible" @click="isClickOutEvent = false">
                <div class="pt-select__dropdown-empty" v-if="loading">
                    <span>{{ loadingText }}</span>
                </div>

                <template v-else>
                    <div class="pt-select__dropdown-wrap" v-if="searchList && searchList.length">
                        <pt-scrollbar ref="searchListScrollbar" @onReachEndY="onReachEndY">
                            <ul class="pt-select__dropdown-list">
                                <li
                                    class="pt-select__dropdown-item"
                                    v-for="(item, $index) in searchList"
                                    :key="item[labelKey]"
                                    :class="{ active: hasSelected(item), selected: $index === hoverIndex }"
                                    :title="item[labelKey]"
                                    @mouseenter="setHoverIndex($index)"
                                    @click="selectOptionClick(item)"
                                >
                                    <span>{{ item[labelKey] }}</span>
                                </li>
                            </ul>
                        </pt-scrollbar>
                    </div>

                    <div class="pt-select__dropdown-empty" v-else>
                        <span>{{ noMatchText }}</span>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import cloneUtils from '../../../utils/clone.utils';
import regUtils from '../../../utils/reg.utils';

export default {
    name: 'ptSelect',

    props: {
        //提示列表
        options: Array,
        //绑定值
        value: String,
        //作为 value 唯一标识的键名
        valueKey: {
            type: String,
            default: 'code'
        },
        //作为 name 唯一标识的键名
        labelKey: {
            type: String,
            default: 'name'
        },
        //是否正在从远程获取数据
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //远程加载时显示的文字
        loadingText: {
            type: String,
            default: function() {
                return this.$t('common.data_loading');
            }
        },
        //是否允许用户创建新条目
        allowCreate: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //搜索条件无匹配时显示的文字
        noMatchText: {
            type: String,
            default: function() {
                return this.$t('common.search_not_found');
            }
        },
        //占位符
        placeholder: String,
        //是否为远程搜索
        remote: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //远程搜索方法
        remoteMethod: Function,
        //允许取消选择
        cancelSelected: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //每行高度，影响滚动加载
        lineHeight: Number
    },

    data() {
        return {
            selected: null,
            query: '',
            hoverIndex: 0,
            hasInput: false,
            softFocus: false,
            softBlur: false,
            searchList: [], //搜索用到
            searchListTmp: [], //滚屏加载时的原始数据
            scrollPage: 0, //滚动条当前已显示的条数
            visible: false, //是否显示下拉列表
            menuVisibleOnFocus: false,
            searchFinish: true, //搜索状态
            isClickOutEvent: true,
            isSilentBlur: false
        };
    },

    computed: {
        inputSize() {
            return !this.query ? 1 : this.query.length + 1;
        },

        // 正则转义
        searchModel() {
            return regUtils.escapeRegExp(this.query);
        },

        // 当前键盘选中条目
        currentSelected() {
            return this.hoverIndex !== -1 && this.searchList[this.hoverIndex];
        },

        currentPlaceholder() {
            return this.visible && this.query ? this.query : this.placeholder;
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.selected = (this.options || []).find(o => o[this.valueKey] === this.value) || {
                [this.valueKey]: this.value,
                [this.labelKey]: this.value
            };
            this.query = this.selected[this.labelKey];
            this.handQueryChange();
            this.setSearchList(this.options);
        },

        setHoverIndex(index) {
            this.hoverIndex = index;
        },

        selectOption(e) {
            if (this.searchList[this.hoverIndex]) {
                this.handleOptionSelect(this.searchList[this.hoverIndex]);
            } else if (this.allowCreate) {
                const val = e.target.value;
                val &&
                    this.handleOptionSelect({
                        [this.labelKey]: val,
                        [this.valueKey]: val
                    });
            }
            this.softBlur = true;
        },

        selectDown() {
            this.hoverIndex++;
            if (this.hoverIndex === this.searchList.length) this.hoverIndex = -1;
            this.updateScrollbar(this.hoverIndex);
        },

        selectUp() {
            this.hoverIndex--;
            if (this.hoverIndex === -2) this.hoverIndex = this.searchList.length - 1;
            this.updateScrollbar(this.hoverIndex);
        },

        updateScrollbar(selectedIndex) {
            if (selectedIndex === -1 || !this.$refs.searchListScrollbar) return;
            let scrollbar = this.$refs.searchListScrollbar;
            let li = scrollbar.$el.querySelectorAll('li')[selectedIndex];
            if (li) {
                let liTop = li.offsetTop;
                let liHeight = this.lineHeight || li.offsetHeight;
                let scrollHeight = scrollbar.$el.offsetHeight;
                let scrollTop = scrollbar.getScrollTop();
                if (liTop < scrollTop || liTop + liHeight > scrollTop + scrollHeight) {
                    this.onReachEndY(); //主动调取数据
                    this.$nextTick(() => scrollbar.scrollToPos(liTop));
                }
            }
        },

        handleInput(event) {
            this.handQueryChange(event.target.value);
        },

        hasSelected(tag) {
            return this.selected && this.selected[this.valueKey] === tag[this.valueKey];
        },

        handleOptionSelect(option) {
            console.log('option select');
            this.selected = option;
            this.query = option[this.labelKey];
            this.$emit('change', option[this.valueKey]);
            this.handQueryChange();
            this.setSoftBlur();
            this.hideDropdown();
        },

        setSoftBlur() {
            this.$refs.input.blur();
        },

        handleClose() {
            // setTimeout(() => {
            //     if (!this.isClickOutEvent) {
            //         this.isClickOutEvent = true;
            //     } else {
            //         this.hideDropdown();
            //     }
            // }, 10);
            this.hideDropdown();
        },

        handleFocus() {
            if (!this.softFocus) {
                this.showDropdown();
                this.menuVisibleOnFocus = true;
                this.$emit('focus', event);
            } else {
                this.softFocus = false;
            }
        },

        handleBlur(event) {
            const val = event.target.value;
            setTimeout(() => {
                if (!this.softBlur) {
                    if (this.allowCreate) {
                        if (val) {
                            this.query = val;
                            this.handleOptionSelect({
                                [this.labelKey]: val,
                                [this.valueKey]: val
                            });
                        } else {
                            this.query = this.value;
                        }
                    } else {
                        val && !this.currentSelected && (this.query = this.selected[this.labelKey]);
                    }
                    this.$emit('blur', event);
                    this.softFocus = false;
                    this.softBlur = false;
                }
            }, 100);
        },

        handQueryChange(query) {
            // this.query = query;
            this.hasInput = !!query;

            let searchModel = regUtils.escapeRegExp(query);
            let debounceFn = debounce(200, () => {
                let data = cloneUtils.deep(this.options);
                let searchList = searchModel ? this.search(data, searchModel) : null;
                this.setSearchList(searchList);

                this.$nextTick(() => {
                    this.setHoverIndex(-1);
                    if (this.$refs.searchListScrollbar) {
                        this.$refs.searchListScrollbar.update();
                    }
                });
            });

            if (this.remote && typeof this.remoteMethod === 'function') {
                this.setHoverIndex(-1);
                this.remoteMethod(query);
            } else {
                if (query && this.options) {
                    debounceFn();
                    this.showDropdown();
                } else {
                    this.setHoverIndex(-1);
                    this.setSearchList(this.options);
                }
            }
        },

        toggleMenu() {
            if (this.menuVisibleOnFocus) {
                this.menuVisibleOnFocus = false;
            } else {
                if (this.visible) {
                    this.hideDropdown();
                } else {
                    this.showDropdown();
                }
            }
            this.$refs.input.focus();
        },

        selectOptionClick(item) {
            console.log('click itme', item);
            this.softBlur = true;
            this.isClickOutEvent = false;
            this.handleOptionSelect(item, true);
        },

        showDropdown() {
            if (this.options.length > 0) {
                this.visible = true;
            }
        },

        hideDropdown() {
            this.visible = false;
            this.menuVisibleOnFocus = false;
        },

        hasActive(item) {
            return this.selected && this.selected[this.labelKey] === item[this.labelKey];
        },

        search(treeList, searchKey) {
            return treeList.reduce((prev, curr, index) => {
                // 否则进行匹配
                var reg = new RegExp(searchKey, 'gi');
                if (reg.test(curr[this.labelKey])) {
                    prev.push(Object.assign({}, curr));
                }
                return prev;
            }, []);
        },

        // 按当前下拉菜单高度获取显示的最大条数
        getPaginationByHeight() {
            let minItems = 7; //默认最小7条
            if (this.$refs.searchListScrollbar) {
                let li = this.$refs.searchListScrollbar.$el.querySelectorAll('li')[0];
                let height = this.$refs.searchListScrollbar.$el.offsetHeight;
                let liHeight = this.lineHeight || (li && li.offsetHeight) || 25;
                let len = Math.floor(height / liHeight);
                return Math.max(len, minItems);
            } else {
                return minItems;
            }
        },

        setSearchList(list) {
            if (!list || !Array.isArray(list)) return;
            let len = this.getPaginationByHeight();
            let overflowLen = parseInt(len / 3);
            let screen = len + overflowLen; //初始化时，在最大条数上加一定溢出量，确保滚动条出现

            this.searchListTmp = list;
            // this.searchList = [...list];
            this.searchList = [...list].splice(0, screen);
            this.scrollPage = this.searchList.length;
        },

        // 当滚动至底
        onReachEndY() {
            if (this.scrollPage && this.searchList.length < this.searchListTmp.length) {
                let len = this.getPaginationByHeight();
                let list = [...this.searchListTmp].splice(this.scrollPage, len);

                this.searchList.push(...list);
                this.scrollPage += len;
            }
        }
    },

    watch: {
        options(newVal) {
            if (!this.searchModel) {
                this.setSearchList(newVal);
            }
            this.handQueryChange();
        },

        value(newVal) {
            this.init();
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import.scss';

.pt-select {
    position: relative;

    .pt-input {
        display: block;
        position: relative;
        font-size: 14px;
        display: inline-block;
        width: 100%;

        &--suffix {
            .pt-input__inner {
                padding-right: 30px;
            }
        }

        &--large {
            font-size: $input-large-font-size;

            & .pt-input__inner {
                height: $input-large-height;
            }
        }

        &--small {
            font-size: $input-small-font-size;

            & .pt-input__inner {
                height: $input-small-height;
            }
        }

        &--mini {
            font-size: $input-mini-font-size;

            & .pt-input__inner {
                height: $input-mini-height;
            }
        }

        &__inner {
            display: block;
            resize: vertical;
            padding: 5px 7px;
            cursor: pointer;
            line-height: 1.5;
            box-sizing: border-box;
            width: 100%;
            font-size: $font-size-base;
            color: $input-color;
            background-color: $color-white;
            background-image: none;
            border: $input-border;
            border-radius: 4px;
            transition: $border-transition-base;

            &::placeholder {
                color: $input-placeholder-color;
            }

            &:hover {
                border-color: $input-hover-border;
            }

            &:focus {
                outline: none;
                border-color: $input-focus-border;
            }
        }

        &__suffix {
            position: absolute;
            right: 5px;
            top: 0;
            height: 100%;
            color: #b3bab5;
            text-align: center;
            transition: all 0.3s;

            &-inner {
                pointer-events: all;
            }
        }

        &__icon {
            position: absolute;
            width: 25px;
            height: 100%;
            right: 0;
            top: 0;
            text-align: center;
            color: $input-icon-color;
            transition: all 0.3s;

            &:after {
                content: '';
                height: 100%;
                width: 0;
                display: inline-block;
                vertical-align: middle;
            }

            & + .pt-input__inner {
                padding-right: 35px;
            }
        }
    }

    &__caret {
        color: #b3bab5;
        font-size: 14px;
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg);
        cursor: pointer;

        &.is-reverse {
            -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
        }
    }

    &__wrap {
        -webkit-appearance: none;
        background-color: $pt-white;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        outline: none;
        padding: 4px 4px 4px 16px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        position: relative;

        &:hover {
            border-color: #c0c4cc;
        }

        &.focus {
            border-color: $pt-green-60;
        }
    }

    &__list {
        // &::before {
        //     content: attr(data-placeholder);
        //     font-size: 14px;
        //     color: #c0c4cc;
        //     position: absolute;
        //     left: 20px;
        //     top: 50%;
        //     transform: translateY(-50%);
        // }

        &.has-tags::before {
            display: none;
        }

        .scrollbar__holder {
            height: 100% !important;
        }

        &-wrap {
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            padding: 5px;

            &.focus {
                border-color: #76ca20;
            }
        }

        .inner {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            cursor: text;
            .placeholder {
                height: 22px;
                line-height: 22px;
                padding: 0 30px 0 12px;
                font-size: 12px;
                color: $pt-black-50;
                position: absolute;
            }
            .tag {
                box-sizing: border-box;
                border-color: transparent;
                margin: 2px;
                background-color: #f0f2f5;
                height: 24px;
                padding: 0 8px;
                line-height: 22px;
                white-space: nowrap;
                border-radius: 4px;
                font-size: 12px;
                color: #909399;
                max-width: 100%;
                display: flex;
                align-items: center;

                &.last-tag {
                    margin-bottom: 5px;
                }

                span {
                    @extend %text-ellipsis;
                    width: calc(100% - 16px);
                }

                .remove {
                    border-radius: 50%;
                    text-align: center;
                    position: relative;
                    cursor: pointer;
                    font-size: 12px;
                    height: 14px;
                    width: 14px;
                    vertical-align: middle;
                    background-color: #c0c4cc;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 4px;

                    &:hover {
                        background-color: #909399;
                    }

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: #fff;
                    }
                }
            }

            .tag-input {
                width: 100%;
                cursor: pointer;
                border: none;
                outline: none;
                background-color: transparent;
                color: #666;
                line-height: 1;
                height: 22px;
                padding-right: 30px;
                vertical-align: top;
                &::-moz-placeholder {
                    color: #c0c4cc;
                }
                &::-webkit-input-placeholder {
                    color: #c0c4cc;
                }
                &:-ms-input-placeholder {
                    color: #c0c4cc;
                }
            }
        }
    }

    &__dropdown {
        background: #fff;
        box-shadow: 0 2px 6px 0 #c0c4cc;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 1px;
        padding: 6px 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 10;

        &::before {
            content: '';
            top: 1px;
            margin-left: -6px;
            border-top-width: 0;
            border-bottom-color: $pt-white;
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 6px;
        }

        &-wrap {
            max-height: 180px;
            overflow: hidden;
        }

        &-item {
            display: flex;
            font-size: 12px;
            color: #ccc;
            align-items: center;
            cursor: pointer;
            margin: 2px 6px;
            padding: 4px 6px;
            color: #344563;
            word-break: break-word;

            &:hover {
                color: #160000 !important;
                background-color: #f5f7fa !important;
            }

            &.active {
                color: $pt-green-60 !important;
            }

            &.selected {
                background: #f5f7fa;
                border-radius: 4px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .user-icon {
                width: 26px;
                height: 26px;
                margin-right: 8px;

                svg {
                    width: 14px;
                    height: 14px;

                    &.time {
                        display: none;
                    }
                }

                .text {
                    font-size: 12px;
                    white-space: nowrap;

                    .word {
                        font-size: 13px;
                    }
                }
            }

            span.info {
                text-overflow: ellipsis;
            }

            span.chosen {
                background: #000;
                border-radius: 4px;
                padding: 0 4px;
                font-size: 12px;
                color: #fff;
                line-height: 18px;
                margin-left: 8px;
                display: none;
            }
            span.owner {
                display: none;
                margin-left: 12px;
            }

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &-empty {
            text-align: center;

            & > span {
                line-height: 34px;
                color: $pt-black-600;
            }
        }
    }
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center top;
}
.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0);
}
</style>
