<script>
import Popper from './vue-popper';
import {debounce} from 'throttle-debounce';
import Vue from 'vue';

/**
     * placement: [
     top,
     top-start,
     top-end,
     bottom,
     bottom-start,
     bottom-end,
     left,
     left-start,
     left-end,
     right,
     right-start,
     right-end
     ]
     */
export default {
    name: 'pt-tooltip',

    mixins: [Popper],

    props: {
        name: String,
        openDelay: {
            type: Number,
            default: 0
        },
        //点击触发时自定关闭的时间
        autoCloseDelay: {
            type: Number,
            default: 1000
        },
        disabled: Boolean,
        manual: Boolean,
        effect: {
            type: String,
            default: 'dark'
        },
        popperClass: String,
        content: [String, Number],
        visibleArrow: {
            default: false
        },
        transition: {
            type: String,
            default: 'fade-in-linear'
        },
        popperOptions: {
            default() {
                return {
                    boundariesPadding: 10,
                    gpuAcceleration: false
                };
            }
        },
        openOn: {
            type: String,
            default: 'mouseenter' //hover || click
        },
        checkTextOverflow: {
            //是否校验文字溢出，true：不溢出则会disabled
            type: Boolean,
            default: false
        },
        isNeedAddTextOverflowClass: {
            //是否要在判断溢出后，添加溢出样式。
            type: Boolean,
            default: false
        },
        offset: {
            //提示框出现的边距
            type: Number,
            default: 0
        },
        // 鼠标是否可以离开触发元素，移动到tip。正常情况下移开触发元素，也tip会消失。为true时，可以移动到tip上。
        canHoverToTip: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentDisabled: this.disabled
        };
    },

    beforeDestroy() {
        this.popperVM.$destroy();
        this.popperVM = undefined;
        //            this.debounceClose && this.debounceClose();
        if (this.$slots && this.$slots.default) {
            const vnode = this.$slots.default[0];
            if (vnode.data.on) {
                vnode.data.on.click = undefined;
                vnode.data.on.mouseenter = undefined;
                vnode.data.on.mouseleave = undefined;
            }
        }
    },
    beforeCreate() {
        this.popperVM = new Vue({
            data: { node: '' },
            parent: this,
            render(h) {
                return this.node;
            }
        }).$mount();

        this.debounceClose = debounce(0, this.handleClosePopperOnLeave);
    },

    render(h) {
        var _self = this;
        this.popperVM.node = h(
            'transition',
            {
                attrs: {
                    name: this.transition
                },
                on: {
                    afterLeave: this.doDestroy
                }
            },
            [
                h(
                    'div',
                    {
                        on: {
                            click: () => {
                                if (!this.canHoverToTip) {
                                    this.togglePreventClose();
                                }
                            },
                            mouseenter: this.togglePreventClose,
                            mouseleave: () => {
                                // _self.debounceClose();
                                _self.handleClosePopperOnLeave();
                                _self.togglePreventClose();
                            }
                        },

                        ref: 'popper',
                        directives: [
                            {
                                name: 'show',
                                value: !this.currentDisabled && this.showPopper
                                // value: !this.disabled && this.showPopper && this.content
                            }
                        ],

                        class: ['pt-tooltip__popper', 'is-' + this.effect, this.popperClass]
                    },
                    [this.$slots.content || this.content]
                )
            ]
        );

        if (!this.$slots.default) return this.$slots.default;

        const vnode = this.$slots.default[0];
        const data = (vnode.data = vnode.data || {});
        const on = (vnode.data.on = vnode.data.on || {});

        if (!_self.disabled) {
            on.click = this.addEventHandle(on.click, this.handleShowPopper);
            on.mouseenter = this.addEventHandle(on.mouseenter, this.handleShowPopper);
            on.mouseleave = this.addEventHandle(on.mouseleave, this.handleClosePopperOnLeave);
            data.staticClass = this.concatClass(data.staticClass, 'pt-tooltip');

            if (Boolean('ontouchend' in document)) {
                on.touchstart = this.addEventHandle(on.touchstart, this.handleShowPopper);
                on.touchend = this.addEventHandle(on.touchend, this.handleClosePopperOnLeave);
            }
        }

        return vnode;
    },

    mounted() {
        this.referenceElm = this.$el;
        this.checkText();
    },

    methods: {
        addEventHandle(old, fn) {
            return old ? (Array.isArray(old) ? old.concat(fn) : [old, fn]) : fn;
        },

        concatClass(a, b) {
            if (a && a.indexOf(b) > -1) return a;
            return a ? (b ? a + ' ' + b : a) : b || '';
        },

        //监测是否文字溢出
        checkText() {
            if (this.checkTextOverflow) {
                let contentDom = this.$el.querySelector('[tooltip-target]'),
                    // contentWrapWidth = contentDom ? jQuery(contentDom).width() : jQuery(this.$el).width(),
                    contentWrapWidth = contentDom ? contentDom.offsetWidth : this.$el.offsetWidth,
                    contentWidth = contentDom ? contentDom.scrollWidth : this.$el.scrollWidth;

                this.currentDisabled = contentWrapWidth >= contentWidth;

                //如果溢出，则添加溢出文字样式
                if (this.isNeedAddTextOverflowClass) {
                    let dom = contentDom || this.$el;
                    if (!this.currentDisabled) {
                        dom.classList.add('text-ellipsis');
                    } else {
                        dom.classList.remove('text-ellipsis');
                    }
                }
            }
        },

        handleShowPopper(e) {
            if (this.currentDisabled || this.manual || this.openOn != e.type) return;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.showPopper = true;
            }, this.openDelay);

            if (this.openOn === 'click') this.handleClosePopperOnClick();
        },

        _action() {
            if (this.preventClose || this.manual || this.openOn === 'click') return;
            clearTimeout(this.timeout);
            this.showPopper = false;
        },
        handleClosePopperOnLeave(e) {
            if (this.canHoverToTip) {
                // 不需要立刻隐藏
                setTimeout(this._action, 100);
            } else {
                this._action(e);
            }
        },

        togglePreventClose() {
            this.preventClose = !this.preventClose;
        },

        handleClosePopperOnClick() {
            this.closeTimeout = setTimeout(() => {
                clearTimeout(this.closeTimeout);
                this.showPopper = false;
            }, this.autoCloseDelay);
        }
    },
    updated() {
        this.checkText();
    },
    watch: {
        disabled(newVal) {
            this.currentDisabled = newVal;
        }
    }
};
</script>

<style lang="scss">
@import '../../styles/import';
$tooltip-content-margin: 4px !default;

.pt-tooltip {
}
.pt-tooltip__popper {
    position: absolute;
    left: 0;
    border-radius: 4px;
    padding: 5px 10px;
    z-index: 70;
    font-size: 13px;
    line-height: 1.5;
    max-width: 300px;
    word-wrap: break-word;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    .popper__arrow {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px;

        &::after {
            @extend .popper__arrow;
            content: ' ';
            border-width: 6px;
        }
    }

    &[x-placement^='top'] {
        margin-bottom: $tooltip-content-margin;

        .popper__arrow {
            bottom: -$tooltip-content-margin;
            border-top-color: $pt-black-900;
            border-bottom-width: 0;

            &::after {
                bottom: 4px;
                margin-left: -5px;
                border-top-color: $pt-black-900;
                border-bottom-width: 0;
            }
        }
    }
    &[x-placement^='bottom'] {
        margin-top: $tooltip-content-margin;

        .popper__arrow {
            top: -$tooltip-content-margin;
            border-top-width: 0;
            border-bottom-color: $pt-black-900;

            &::after {
                top: 1px;
                margin-left: -5px;
                border-top-width: 0;
                border-bottom-color: $pt-black-900;
            }
        }
    }
    &[x-placement^='right'] {
        margin-left: $tooltip-content-margin;

        .popper__arrow {
            left: -$tooltip-content-margin;
            border-right-color: $pt-black-900;
            border-left-width: 0;

            &::after {
                bottom: -5px;
                left: 1px;
                border-right-color: $pt-black-900;
                border-left-width: 0;
            }
        }
    }
    &[x-placement^='left'] {
        margin-right: $tooltip-content-margin;

        .popper__arrow {
            right: -$tooltip-content-margin;
            border-right-width: 0;
            border-left-color: $pt-black-900;

            &::after {
                right: 1px;
                bottom: -5px;
                margin-left: -5px;
                border-right-width: 0;
                border-left-color: $pt-black-900;
            }
        }
    }

    &.is-light {
        background: $pt-white;
        border: 1px solid $pt-black-900;

        &[x-placement^='top'] .popper__arrow {
            border-top-color: $pt-black-900;

            &::after {
                border-top-color: #fff;
            }
        }
        &[x-placement^='bottom'] .popper__arrow {
            border-bottom-color: $pt-black-900;

            &::after {
                border-bottom-color: #fff;
            }
        }
        &[x-placement^='left'] .popper__arrow {
            border-left-color: $pt-black-900;

            &::after {
                border-left-color: #fff;
            }
        }
        &[x-placement^='right'] .popper__arrow {
            border-right-color: $pt-black-900;

            &::after {
                border-right-color: #fff;
            }
        }
    }
    &.is-warning {
        color: $pt-white;
        background: $pt-danger;

        &[x-placement^='top'] {
            margin-bottom: $tooltip-content-margin;

            .popper__arrow {
                border-top-color: $pt-black-900;

                &::after {
                    border-top-color: #fff;
                }
            }
        }
        &[x-placement^='bottom'] {
            margin-bottom: $tooltip-content-margin;

            .popper__arrow {
                border-bottom-color: $pt-black-900;

                &::after {
                    border-bottom-color: #fff;
                }
            }
        }
        &[x-placement^='left'] {
            margin-bottom: $tooltip-content-margin;

            .popper__arrow {
                border-left-color: $pt-black-900;

                &::after {
                    border-left-color: #fff;
                }
            }
        }
        &[x-placement^='right'] {
            margin-bottom: $tooltip-content-margin;

            .popper__arrow {
                border-right-color: $pt-black-900;

                &::after {
                    border-right-color: #fff;
                }
            }
        }
    }
    &.is-dark {
        background: $pt-black-900;
        color: $pt-white;
    }
    &.is-blue {
        background: $pt-green-60;
        color: $pt-white;
        font-size: 13px;
        padding: 10px;
        line-height: 20px;
    }
    &.is-yellow {
        background: #efc72d;
        color: $pt-black-900;
    }
}
</style>
