import i18n from '@assets/i18n';
import engageApis from '@/apis/engage.apis';
import graphqlApis from '@/apis/graphql';
import goalApis from '@/apis/goal.apis';
import commonUtils from '@common/utils/common.utils';
import uuidUtils from '@common/utils/uuid.utils';
import cloneUtils from '@/common/utils/clone.utils';
import engageSettingUtils from '@/components/engagement/setting/setting.utils';
import store from '@/store';
import addVersionScreenshot from '@/common/services/addVersionScreenshot';

export default class EngageSettingStore {
    constructor() {
        const { sid, timeZone } = store.getters['main/profileInfo'];
        const { id, email } = store.getters['main/userInfo'];
        const uid = id;

        this.engage = null;
        this.originalCampaign = null;

        // 默认配置信息
        this.config = {
            maxVersionLen: 5,
            idNames: {
                creative: {
                    idName: 'creativeId',
                    vidName: 'versionCreativeId'
                },
                start: {
                    idName: 'startId',
                    vidName: 'versionStartId'
                },
                session: {
                    idName: 'sessionId',
                    vidName: 'versionSessionId'
                },
                stop: {
                    idName: 'stopId',
                    vidName: 'versionStopId'
                },
                schedule: {
                    idName: 'scheduleId',
                    vidName: 'versionscheduleId'
                }
            }
        };

        // 用户信息
        this.user = {
            uid,
            sid,
            email,
            timeZone
        };

        // 状态信息
        this.state = {
            isInline: false, //是否
            activePart: '', //当前访问节点
            allAudiences: null, //当前档案下所有可用的audiences
            allGoalList: null, //当前档案下所有可用的goal
            defaultAudience: {}, //当前当按下的默认audience
            currentVersionIndex: 0, //当前查看的ABtest版本序号
            currentTerminalType: null, //当前访问设备类型
            creativeTemplate: null, //当前creative模板信息
            fromCreativeTemplateList: false, //是否来自模板创建
            inEditing: false, //是否为编辑模式
            isCopyEditing: false, //是否为复制编辑操作
            profileBasicSetting: null, //档案基础设置信息
            template: null, //编辑模板原始信息
            route: null, //进入路由信息
            refer: null, //进入前的页面信息
            unfinishedItems: [], //未完成项目
            currentDebugEngageId: '',
            hasRemoveBrandingRule: true //是否可以关闭branding
        };
    }

    /**
     * 数据格式化。
     * 多版本迭代，统一数据结构
     * 接口返回的数据结构可见 ./engage.demo.json
     * 转换后的数据结构可见 ./engage.setting.json
     */
    _formatEngage(engage) {
        const previewUrl = commonUtils.completeProtocol(this.getDefaultPage());
        if (engage.engageVersion === '3.0') {
            return engageSettingUtils.formatCampaign(engage);
        } else {
            return engageSettingUtils.formatEngage(engage, previewUrl, this.state.hasRemoveBrandingRule);
        }
    }

    /**
     * 获取Engage配置
     */
    _fetchEngageInfo(engageId) {
        return engageApis.getEngagement(null, {
            where: {
                id: engageId
            }
        });
    }

    /**
     * 获取Engage默认名称,按序号生成
     */
    _fetchEngageName() {
        const { sid } = store.getters['main/profileInfo'];
        // return 'Default Engage Name';
        const params = {
            operationName: 'defaultEngageName',
            query: `query defaultEngageName($where: EngageWhereUniqueInput!){
                defaultEngageName(where:$where)
            }`,
            variables: {
                where: {
                    sid,
                    name: i18n.t('common.setting_engage_promo_name')
                }
            }
        };
        return graphqlApis.graphql(null, params);
    }
    /**
     * 获取Goal列表
     */
    _fetchGoalList() {
        return goalApis.getGoals(null, { where: { sid: this.user.sid } });
    }
    /**
     * 获取Audiences列表
     */
    _fetchAudiencesList() {
        const getUserFilterParams = {
            operationName: 'getUsergroups',
            query: `query getUsergroups($where: UsergroupWhereUniqueInput!){
                usergroups(where: $where){
                  usergroupID: id
                  source
                  name
                  content
                }
              }`,
            variables: {
                where: {
                    sid: this.user.sid,
                    timeZone: this.user.timeZone
                }
            }
        };

        return graphqlApis.graphql(null, getUserFilterParams);
    }

    /**
     * 获取档案信息
     */
    _fetchProfileBasicSetting() {
        return store.getters['main/profileInfo'];
    }

    /**
     * 获取Engage默认配置
     */
    _getNewEngage(engageName) {
        return {
            id: uuidUtils.uuid_16int(),
            isInline: false,
            name: engageName,
            status: 0, //默认engage状态为0 -> 草稿
            hasForm: false,
            //abtestRate: [1],
            abtestType: '',
            audiences: [this.state.defaultAudience],
            //   conversion: [],
            goals: [],
            versions: []
        };
    }
    /**
     * 获取Engage默认配置
     * forEngageVersionV3
     */
    _getNewEngageV3(engageName) {
        return {
            id: uuidUtils.uuid_16int(),
            engageVersion: '3.0', //Engage大版本号
            baseURL: '', //底图url
            name: engageName,

            status: 0, //默认engage状态为0 -> 草稿
            hasForm: false,
            //  abtestType: 'creative',
            audiences: [this.state.defaultAudience],
            goals: [],
            schedule: [],
            conditions: [],
            versions: []
        };
    }
    /**
     * 获取Engage version 3.0下personalization
     */
    _getNewVersionV3() {
        const defaultPage = this.getDefaultPage();
        return {
            versionId: uuidUtils.uuid_16int(),
            versionName: `${i18n.t('engagement.setting_engage_version')} 1`,
            versionRate: 100,
            screenshot: '', //截图url
            controlGroup: false,
            status: 0,
            terminals: engageSettingUtils.initTerminalsV3(),
            displayConfig: {
                previewUrl: commonUtils.completeProtocol(defaultPage),
                start: [],
                whenType: 'URL',
                when: [
                    {
                        type: 'URL',
                        value: defaultPage,
                        condition: '=='
                    }
                ],
                stop: [],
                schedule: [],
                session: [],
                timezone: {
                    profile: pt.global.timezone,
                    useVisitor: false
                },
                meta: {
                    start: {
                        checkbox1: false,
                        checkbox2: false,
                        checkbox3: false,
                        jsTrigger: false,
                        userStop: false,
                        seconds: '1',
                        inactiveSeconds: '5', //用户停止活动时长
                        position: '0%',
                        done: false,
                        hasEverChooseShowJs: false // 标识start触发是否保存过 ShownByJs触发条件
                    },
                    whenType: 'URL',
                    when: [
                        {
                            type: 'URL',
                            value: defaultPage,
                            condition: '=='
                        }
                    ],
                    session: {
                        userBehaviourContent: { children: [], operator: 'and' },
                        visitPropContent: { children: [], operator: 'and' }
                    },
                    stop: {
                        checkbox1: false,
                        checkbox2: false,
                        close: false,
                        closetimes: '1',
                        formSubmitted: false,
                        times: '5',
                        done: false
                    },
                    schedule: {
                        radio1: '1',
                        radio2: '1',
                        radio3: '1',
                        startDate: commonUtils.getTimestamp('start'),
                        endDate: commonUtils.getTimestamp('end'),
                        time: ['00:00', '23:59'],
                        week: [
                            {
                                status: true,
                                text: i18n.t('common.week_sun_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_mon_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_tue_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_wed_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_thu_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_fri_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_sat_short')
                            }
                        ],
                        done: false
                    },
                    timezone: {
                        profile: pt.global.timeZone,
                        useVisitor: false
                    }
                }
            }
        };
    }
    /**
     * 获取Engage版本默认配置
     */
    _getNewVersion() {
        const defaultPage = this.getDefaultPage();
        return {
            id: uuidUtils.uuid_16int(),
            name: `${i18n.t('engagement.setting_engage_version')} 1`,
            rate: 1,
            isSync: true,
            controlGroup: false,
            status: 0,
            screenshot: '', //截图url
            displayConfig: {
                previewUrl: commonUtils.completeProtocol(defaultPage),
                start: [],
                whenType: 'URL',
                when: [
                    {
                        type: 'URL',
                        value: defaultPage,
                        condition: '=='
                    }
                ],
                stop: [],
                schedule: [],
                session: [],
                timezone: {
                    profile: pt.global.timezone,
                    useVisitor: false
                },
                meta: {
                    start: {
                        checkbox1: false,
                        checkbox2: false,
                        checkbox3: false,
                        jsTrigger: false,
                        userStop: false,
                        seconds: '1',
                        inactiveSeconds: '5', //用户停止活动时长
                        position: '0%',
                        done: false,
                        hasEverChooseShowJs: false // 标识start触发是否保存过 ShownByJs触发条件
                    },
                    whenType: 'URL',
                    when: [
                        {
                            type: 'URL',
                            value: defaultPage,
                            condition: '=='
                        }
                    ],
                    session: {
                        userBehaviourContent: { children: [], operator: 'and' },
                        visitPropContent: { children: [], operator: 'and' }
                    },
                    stop: {
                        checkbox1: false,
                        checkbox2: false,
                        close: false,
                        closetimes: '1',
                        formSubmitted: false,
                        times: '5',
                        done: false
                    },
                    schedule: {
                        radio1: '1',
                        radio2: '1',
                        radio3: '1',
                        startDate: commonUtils.getTimestamp('start'),
                        endDate: commonUtils.getTimestamp('end'),
                        time: ['00:00', '23:59'],
                        week: [
                            {
                                status: true,
                                text: i18n.t('common.week_sun_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_mon_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_tue_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_wed_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_thu_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_fri_short')
                            },
                            {
                                status: true,
                                text: i18n.t('common.week_sat_short')
                            }
                        ],
                        done: false
                    },
                    timezone: {
                        profile: pt.global.timeZone,
                        useVisitor: false
                    }
                }
            },
            terminals: []
        };
    }

    /**
     * 获取Engage版本默认名称
     */
    _getVersionName(version, nameNumber = 0) {
        const reg = /(\d+)$/g;
        const defaultName = i18n.t('engagement.setting_engage_version');
        const count = reg.exec(version.versionName);
        let name = `${defaultName} ${nameNumber + 1}`;
        if (version.versionIsControlGroup) {
            name = i18n.t('engagement.control_group');
        } else if (count) {
            name = `${version.versionName.trim().replace(count[0], '')} ${nameNumber + 1}`;
        }
        return name;
    }
    /**
     * 根据type获取version abtest的内容
     */
    _getTypeVersions(type, id) {
        const versions = this.engage.versions;
        const typeVersions = this.engage[type];
        const typeNameConfig = this.config.idNames[type];

        let currentVersion = versions[this.state.currentVersionIndex];
        currentVersion.versionIsControlGroup && (currentVersion = versions.find(v => !v.versionIsControlGroup));
        const currentTypeVersion = typeVersions.find(item => {
            return item[typeNameConfig.idName] === currentVersion[typeNameConfig.vidName];
        });

        const newTypeVersion = cloneUtils.deep(currentTypeVersion);
        const newTypeVersionId = id || uuidUtils.uuid();
        newTypeVersion[typeNameConfig.idName] = newTypeVersionId;

        return newTypeVersion;
    }

    /**
     * 初始化Engage
     * @param {Object} route 初始化Engage对应的页面
     * @param {String} defaultName Engage默认名称
     * @param {Boolean} hasRemoveBrandingRule 是否可以关闭Branding
     */
    async init(route, defaultName, hasRemoveBrandingRule) {
        const { name, query, params } = route;
        const inEditing = name === 'EngagementEdit';
        const isInline = query.inline + '' === 'true';
        this.updateState('route', { name, query, params });
        this.updateState('refer', query.refer || '');
        this.updateState('isInline', isInline);
        this.updateState('hasRemoveBrandingRule', hasRemoveBrandingRule);
        this.updateState('fromCreativeTemplateList', name === 'CreativeTemplateList');
        this.updateState('inEditing', inEditing);
        this.updateState('profileBasicSetting', store.getters['main/profileInfo']);

        const [err, result] = await commonUtils.awaitWrap(this.getAllUsergroups());
        if (err) return Promise.reject();

        if (inEditing) {
            const engageId = params.engageId;
            if (engageId) {
                const [err, engagement] = await commonUtils.awaitWrap(this._fetchEngageInfo(engageId));
                if (err) return Promise.reject();
                if (engagement) {
                    const rejectPromise = await this.setEngage(engagement);
                    return rejectPromise;
                } else {
                    return Promise.reject();
                }
            } else {
                return Promise.reject();
            }
        } else {
            const [err, result] = await commonUtils.awaitWrap(this._fetchEngageName());
            if (err) return Promise.reject();
            const engageName = (result.data && result.data.defaultEngageName) || defaultName;
            const engage = isInline ? this._getNewEngageV3(engageName) : this._getNewEngage(engageName);
            const rejectPromise = await this.setEngage(engage, isInline);
            return rejectPromise;
        }
    }

    /**
     * 设置Engage具体信息
     */
    async setEngage(engage, isInline = false) {
        if (this.state.allAudiences) {
            const allAudiences = [].concat(this.state.allAudiences);
            allAudiences.map(item => {
                item.checked = (engage.audiences || []).some(o => o.audience_id === item.usergroupID);
                return item;
            });
            this.updateState('allAudiences', allAudiences);
            if (engage.engageVersion === '3.0') {
                this.state.inEditing || (engage.versions = [this._getNewVersionV3()]),
                    (this.engage = this._formatEngage(engage));
            } else {
                this.state.inEditing || ((engage.versions = [this._getNewVersion()]), (engage.isInline = isInline)),
                    (this.engage = this._formatEngage(engage));
            }
            this.originalCampaign = cloneUtils.deep(this.engage);
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }
    async getAllUsergroups() {
        const allUserGroups = await this._fetchAudiencesList();
        if (allUserGroups.data && allUserGroups.data.usergroups) {
            const { usergroups } = allUserGroups.data;
            const defaultAudience = usergroups.find(item => item.source === 'default');
            this.updateState('allAudiences', usergroups);
            this.updateState('defaultAudience', Object.assign(defaultAudience, { isInvalid: false }));
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }
    async getAllGoalList() {
        let allGoalList = await this._fetchGoalList();
        if (allGoalList) {
            allGoalList.map(item => {
                item.checked = false;
            });
            this.updateState('allGoalList', allGoalList);

            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }
    /**
     * Creative模板初始化
     * 模板仅有terminal数据
     * 需依据模板信息生成编辑器内所对应的engage数据结构
     */
    templateInit(route, inEditing, template = {}) {
        const { name, query, params } = route;
        const { isSync, terminal } = template.configs || {
            isSync: true,
            terminal: []
        };
        const engage = engageSettingUtils.formatEngage({
            versions: [
                {
                    isSync,
                    terminal
                }
            ]
        });
        this.engage = engage;
        this.updateState('template', template);
        this.updateState('route', { name, query, params });
        this.updateState('refer', 'template');
        this.updateState('inEditing', inEditing);
    }

    getState(key) {
        return this.state[key];
    }

    async getAllAudiencesArr() {
        if (!this.state.allAudiences) {
            const result = await this._fetchAudiencesList();
            result && this.updateState('allAudiences', result.data.allAudiences);
        }

        if (this.state.allAudiences) {
            return commonUtils.change2Array(this.state.allAudiences, 4);
        }
        return [];
    }

    getDefaultPage() {
        const { profileBasicSetting } = this.state;
        return (profileBasicSetting && (profileBasicSetting.domain || '').split(',')[0]) || '';
    }

    /**
     * 按类别ID查询对应的版本信息
     * @param {String} type
     * @param {String} typeId
     * @return {Object} version
     */
    getVersionInfoByType(type, typeId) {
        const idNames = this.config.idNames[type];
        return (
            idNames &&
            this.engage.versions.find(item => {
                return item[idNames.vidName] === typeId;
            })
        );
    }

    updateState(key, val) {
        if (Object.prototype.hasOwnProperty.call(this.state, key)) {
            this.state[key] = cloneUtils.deep(val);
        }
    }

    updateEngage(key, val) {
        if (Object.prototype.hasOwnProperty.call(this.engage, key)) {
            //对key为hasForm进行特殊处理，如果hasForm为true, 则不更新该值。因为产品要求记录该engage是否曾经设置过表单
            if (key !== 'hasForm') {
                this.engage[key] = cloneUtils.deep(val);
            } else if (!this.engage[key]) {
                this.engage[key] = cloneUtils.deep(val);
            }
        }
    }

    updateEngageVersion(type, index, key, val) {
        const version = this.engage[type][index];
        if (version && Object.prototype.hasOwnProperty.call(version, key)) {
            version[key] = cloneUtils.deep(val);
        }
    }

    /**
     * 更新Engage各版本比例
     */
    updateEngageVersionRate(type) {
        const len = this.engage[type].length;
        let cellLen = 0;
        if (1 % len !== 0) {
            cellLen = 100 - parseInt((1 / len) * 100) * len;
        }
        this.engage.versions.map((item, index) => {
            if (cellLen) {
                if (index < cellLen) {
                    item.versionRate = Math.ceil((1 / len) * 100);
                } else {
                    item.versionRate = Math.floor((1 / len) * 100);
                }
            } else {
                item.versionRate = (1 / len) * 100;
            }
        });
    }

    updateEngageVersionName(type, index, name) {
        const version = this.engage[type][index];
        version && (version.name = name);
    }

    updateEngageCreativeTerminals(versionindex, terminalType, key, val) {
        const version = this.engage.creative[versionindex];
        const terminal = version && version.terminals.find(terminal => terminal.terminalType === terminalType);
        terminal && Object.prototype.hasOwnProperty.call(terminal, key) && (terminal[key] = val);
    }

    updateEngageCreativeWidgets(creativeId, terminalType, widgetIndex, key, val) {
        const creative = this.engage.creative.find(item => item.creativeId === creativeId);
        const terminal = creative && creative.terminals.find(terminal => terminal.terminalType === terminalType);
        const widget = terminal && terminal.widgets[widgetIndex];
        widget && Object.prototype.hasOwnProperty.call(widget, key) && (widget[key] = val);
    }

    updateEngageCreative(creativeId, key, val) {
        const creative = this.engage.creative.find(item => item.creativeId === creativeId);
        creative && (creative[key] = val);
    }

    /**
     * 新增Engage版本, 按当前版本复制
     */
    addEngageVersion(type, controlGroup) {
        const reg = /(\d+)$/g;

        //记录当前版本列表中名称数字最大值
        let nameNum = 0;
        this.engage.versions
            .filter(v => !v.versionIsControlGroup)
            .forEach(v => {
                const count = reg.exec(v.versionName);
                count && (nameNum = Math.max(parseInt(count), nameNum));
            });

        // 查找当前非页面组的版本
        let currentVersion = this.engage.versions[this.state.currentVersionIndex];
        currentVersion.versionIsControlGroup &&
            (currentVersion = this.engage.versions.find(v => !v.versionIsControlGroup));

        const newVersion = cloneUtils.deep(currentVersion);
        newVersion.versionIsControlGroup = controlGroup;
        newVersion.versionId = uuidUtils.uuid_16int();
        newVersion.versionName = this._getVersionName(newVersion, nameNum);
        newVersion.versionPreviewUrlKey = '';
        newVersion.versionPreviewStart = null;

        if (controlGroup) {
            const typeNameConfig = this.config.idNames[type];
            const newTypeVersion = this._getTypeVersions(type);
            const newTypeVersionId = newTypeVersion[typeNameConfig.idName];
            const typeVersions = this.engage[type];
            newVersion[typeNameConfig.vidName] = newTypeVersionId;

            Object.assign(newTypeVersion, {
                terminals: []
            });
            typeVersions.push(newTypeVersion);
        } else if (type === 'creative') {
            const typeNameConfig = this.config.idNames[type];
            const newTypeVersion = this._getTypeVersions(type);
            const newTypeVersionId = newTypeVersion[typeNameConfig.idName];
            const typeVersions = this.engage[type];
            newVersion[typeNameConfig.vidName] = newTypeVersionId;

            (newTypeVersion.terminals || []).forEach(terminal => {
                (terminal.widgets || []).forEach(widget => {
                    widget.widgetId = `pt_${uuidUtils.uuid_8bit()}`;
                    widget.visualModel &&
                        widget.visualModel.widgetBlocks.forEach(block => {
                            block.blockId = `pt_${uuidUtils.uuid_8bit()}`;
                        });
                });
            });
            typeVersions.push(newTypeVersion);
        } else if (type === 'start') {
            const triggerTypes = ['start', 'stop', 'session'];
            const id = uuidUtils.uuid();
            triggerTypes.forEach(trigger => {
                const typeVersions = this.engage[trigger];
                const typeNameConfig = this.config.idNames[trigger];
                const newTypeVersion = this._getTypeVersions(trigger, id);
                const newTypeVersionId = newTypeVersion[typeNameConfig.idName];
                newVersion[typeNameConfig.vidName] = newTypeVersionId;
                typeVersions.push(newTypeVersion);
            });
        }

        this.engage.versions.push(newVersion);

        this.updateState('currentVersionIndex', this.engage.versions.length - 1);
    }

    /**
     * 删除Engage版本
     * @param {Number} index
     */
    deleteEngageVersion(type, version, index) {
        const triggerTypes = ['start', 'stop', 'session'];
        if (type === 'creative') {
            const typeConfig = this.config.idNames[type] || {};
            const typeIndex = this.engage[type].findIndex(item => {
                return item[typeConfig.idName] === version[typeConfig.vidName];
            });
            if (typeIndex === -1) return;

            this.engage[type].splice(typeIndex, 1);
        } else {
            triggerTypes.forEach(trigger => {
                const typeConfig = this.config.idNames[trigger] || {};
                const typeIndex = this.engage[trigger].findIndex(item => {
                    return item[typeConfig.idName] === version[typeConfig.vidName];
                });
                if (typeIndex === -1) return;

                this.engage[trigger].splice(typeIndex, 1);
            });
        }

        this.engage.versions.splice(index, 1);
        this.engage.versions.length === 1 && this.updateEngage('abtestType', '');
        if (type === 'creative') {
            this.updateEngageVersionRate(type);
        } else {
            triggerTypes.forEach(trigger => {
                this.updateEngageVersionRate(trigger);
            });
        }
        this.updateState('currentVersionIndex', 0);
    }

    /**
     * 暂停Engage版本
     * @param {String} verIndex
     */
    pauseEngageVersion(type, verIndex) {
        this.engage.versions[verIndex].versionRate = 0;
        const effectiveLen = this.engage.versions.filter(v => v.versionRate !== 0).length;
        let cellLen = 0;
        if (1 % effectiveLen !== 0) cellLen = 100 - parseInt((1 / effectiveLen) * 100) * effectiveLen;

        this.engage.versions.map((item, index) => {
            if (index !== verIndex && item.versionRate !== 0) {
                if (cellLen) {
                    if (index < cellLen) {
                        item.versionRate = Math.ceil((1 / effectiveLen) * 100);
                    } else item.versionRate = Math.floor((1 / effectiveLen) * 100);
                } else item.versionRate = (1 / effectiveLen) * 100;
            }
        });
    }

    /**
     * 获取最新Audiences
     */
    async refreshAllAudiencesList() {
        const result = await this._fetchAudiencesList();
        if (result.data && result.data.allAudiences) {
            // 同步选中状态
            const oldValue = this.state.allAudiences || [];
            const newValue = result.data.allAudiences.map(audience => {
                const oldItem = oldValue.find(item => item.id === audience.id);
                oldItem && (audience.checked = oldItem.checked);
                return audience;
            });
            this.updateState('allAudiences', newValue);
        }
    }

    _saveEngageRequest(postEngage, migrate) {
        return engageApis.addEngagement(null, { data: postEngage });
    }

    checkIsRepeatName() {
        const { sid } = store.getters['main/profileInfo'];
        const { engageId = '', engageName } = this.engage;
        return engageApis.isEngageNameRepeat(null, {
            where: {
                id: engageId,
                sid,
                name: engageName
            }
        });
    }

    _editEngageRequest(postEngage, engageId, sid, email) {
        return engageApis.updateEngagement(null, {
            where: {
                id: engageId,
                sid,
                email
            },
            data: postEngage
        });
    }

    /**
     * review upReview 保存的engage
     * @param {*} fingerprint
     */
    saveDebugEngage(fingerprint) {
        const { sid } = this.user;
        const { engageId } = this.engage;
        const postEngage = engageSettingUtils.serializeEngagePostData(this.engage, this.user, this.state.isCopyEditing);
        engageId && (postEngage.engage_id = engageId);
        return graphqlApis.setDebugEngage(null, {
            sid: sid,
            previewId: fingerprint,
            content: postEngage
        });
    }

    /**
     * 保存Engage
     */
    async saveEngage(callback, errcallback) {
        const { engageId, hasForm, engageVersion } = this.engage;
        const { uid, sid, email } = this.user;
        const postEngage =
            engageVersion === '3.0'
                ? engageSettingUtils.serializeCampaignPostData(this.engage, this.user, this.state.isCopyEditing)
                : engageSettingUtils.serializeEngagePostData(this.engage, this.user, this.state.isCopyEditing);
        await addVersionScreenshot(postEngage);
        if (this.state.inEditing) {
            postEngage.updateBy = uid;
            this._editEngageRequest(postEngage, engageId, sid, email).then(content => {
                callback && typeof callback === 'function' && callback(content);
            });
        } else {
            postEngage.createBy = uid;
            postEngage.updateBy = uid;
            this._saveEngageRequest(postEngage, true).then(
                content => {
                    store.commit('main/UPDATE_COUNTS', {
                        key: 'engageCount'
                    });
                    callback && typeof callback === 'function' && callback(content);
                },
                err => {
                    errcallback && typeof errcallback === 'function' && errcallback(err);
                }
            );
        }
    }

    clean() {
        this.engage = null;
        this.state.activePart = '';
        this.state.allAudiences = null;
        this.state.currentVersionIndex = 0;
        this.state.currentTerminalType = null;
        this.state.creativeTemplate = null;
        this.state.fromCreativeTemplateList = false;
        this.state.inEditing = false;
        this.state.isCopyEditing = false;
        this.state.profileBasicSetting = null;
        this.state.template = null;
        this.state.route = null;
        this.state.refer = null;
        this.state.unfinishedItems = [];
        this.state.currentDebugEngageId = '';
        this.state.hasRemoveBrandingRule = true;
    }

    async _getThumbnail(remoteData) {
        await Promise.all(
            remoteData.versions?.map(async version => {
                const terminals = version.terminals
                    .slice()
                    .sort((terminalA, terminalB) => (terminalA.type === 2 && terminalB.type !== 2 ? -1 : 1));
                const thumbnail = { htmlString: '', deviceType: 'pc', customDomSelector: '', addFix: false };
                let targetTerminal;
                if (remoteData.engageVersion === '3.0') {
                    thumbnail.customDomSelector = `[data-menu-action="insert.popup"]`;
                    targetTerminal = terminals.find(
                        terminal => terminal.popup?.length > 0 || terminal.stickybar?.length > 0
                    );

                    if (!targetTerminal) {
                        targetTerminal = terminals.find(terminal => terminal.inline?.length > 0);
                        thumbnail.url = remoteData.baseURL;
                        thumbnail.htmlString = undefined;
                        thumbnail.addFix = undefined;
                        if (targetTerminal) {
                            thumbnail.customDomSelector = targetTerminal?.inline?.[0]?.elementText?.selector?.string;
                            targetTerminal = undefined;
                        } else {
                            thumbnail.customDomSelector = undefined;
                            thumbnail.width = 1440;
                            thumbnail.height = 800;
                        }
                    }
                } else {
                    thumbnail.customDomSelector = `[data-engage-block="engage"]`;
                    targetTerminal = terminals.find(terminal => terminal?.widgets?.[0]?.widgetNodes?.html);
                    if (!targetTerminal) {
                        thumbnail.htmlString = undefined;
                        thumbnail.url = remoteData.baseURL;
                        thumbnail.customDomSelector = undefined;
                        thumbnail.width = 1440;
                        thumbnail.height = 800;
                    }
                }

                if (targetTerminal) {
                    let target = targetTerminal;
                    if (remoteData.engageVersion === '3.0') {
                        const targetPop =
                            targetTerminal.popup.length > 0 ? targetTerminal.popup : targetTerminal.stickybar;
                        target = targetPop?.[0];
                    }
                    if (!thumbnail.url) {
                        const { css = '', commonStyle = '', html = '' } = target?.widgets?.[0] || {};
                        thumbnail.htmlString = `<body><style type="text/css">${commonStyle}${css}</style>${html}</body>`;
                    }
                    thumbnail.deviceType =
                        targetTerminal?.type === 2 ? 'pc' : targetTerminal?.type === 1 ? 'mobile' : 'tablet';
                }

                if (remoteData.engageVersion !== '3.0' && version.controlGroup === true) {
                    version.screenshot = '';
                } else {
                    const img = await publicApis.otherServiceApi(null, {
                        where: {
                            service: 'draw',
                            type: 'post',
                            path: '/api/html2img',
                            body: thumbnail
                        }
                    });
                    if (img?.url) version.screenshot = img?.url;
                }
            })
        );
    }
}
