import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueAsyncManager from 'vue-async-manager';
Vue.use(VueAsyncManager, {
    mode: 'visible'
});
Vue.use(VueCompositionAPI);
import VueClipboard from 'vue-clipboard2';
import ElementUi from 'element-ui';
import i18n from '@/assets/i18n';
import App from './App.vue';
import store from './store';
import router from './router/router';
import ptDirectives from '@common/directives';
import PtPlugins from '@common/plugins/pt.plugin';
import ErrorPlugin from '@common/plugins/error.plugin';
import PtUi from '@ptengine/pt-ui-x';

import '@/apis/http.interceptor';
// import '@/apis/mock/mock';
import '@/assets/icons';
import '@common/utils/base.utils'; //定义全局变量window.pt
import '@/styles/theme/index.css';
import '@ptengine/pt-ui-x-styles'

const isDevEnv = process.env.NODE_ENV && process.env.NODE_ENV === 'development';

ptDirectives.forEach(directive => {
    Vue.directive(directive.name, directive);
});

Vue.config.productionTip = false;

Vue.use(PtUi);
Vue.use(ElementUi, {
    i18n: (key, value) => i18n.t(key, value)
});

Vue.use(PtPlugins);
isDevEnv || Vue.use(ErrorPlugin); //非测试环境使用错误日志收集
Vue.use(VueClipboard); //this.$copyText

new Vue({
    store,
    router,
    i18n,
    // 添加mounted，不然不会执行预编译
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    },
    render: h => h(App)
}).$mount('#app');
