var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.cell},[_c('LazyloadCard',{on:{"show":_vm.handleCardShow}},[_c('router-link',{attrs:{"to":{ name: 'UsersSettingOverview', params: { usersId: _vm.cellInfo.id } }}},[_c('div',{class:_vm.$style['cell-title']},[_c('div',{attrs:{"title":_vm.cellInfo.name}},[_c('p',{class:_vm.$style['cell-title-content']},[_vm._v(" "+_vm._s(_vm.cellInfo.name))])]),(_vm.cellInfo.description)?_c('el-tooltip',{attrs:{"placement":"top","content":_vm.cellInfo.description}},[_c('pt-icon',{attrs:{"icon":"pt-icon--info","icon-style":{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84'
                        }}})],1):_vm._e()],1),_c('div',{class:_vm.$style['cell-users']},[(_vm.cellInfo.users === undefined)?_c('Skeleton'):[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.cellInfo.users))+" ")],_c('span',{class:_vm.$style['cell-unit']},[_vm._v(_vm._s(_vm.$t('users.metrics_users')))])],2),_c('div',{class:_vm.$style['cell-actives']},[(_vm.cellInfo.actives === undefined)?_c('Skeleton',{attrs:{"customStyle":{
                        width: '32px'
                    }}}):[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.cellInfo.actives))+" ")],_c('span',{class:_vm.$style['cell-unit']},[_vm._v(_vm._s(_vm.$t('users.metrics_active_users')))])],2),_c('div',{class:_vm.$style['cell-actives']},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.cellInfo.engagements))+" "),_c('span',{class:_vm.$style['cell-unit']},[_vm._v(_vm._s(_vm.$t('users.metrics_running')))])]),_c('div',{class:_vm.$style['cell-tools']},[_c('pt-icon',{attrs:{"icon":"icon-arrow-right","icon-style":{
                        width: '18px',
                        height: '18px',
                        fill: '#091E42'
                    }}})],1)]),(_vm.moreOptions.length)?_c('div',{class:_vm.$style['cell-more']},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{class:_vm.$style['cell-dot']},[_c('pt-icon',{attrs:{"icon":"pt-icon--more-dot","icon-style":{
                            width: '18px',
                            height: '18px',
                            fill: '#091E42'
                        }}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_vm._l((_vm.moreOptions),function(item){return [(item.code !== 'delete' || _vm.cellInfo.canDelete)?_c('verify-package',{key:item.code,attrs:{"component":"link","privilege-key":item.authKey,"disabled":!item.authKey,"resolveCallback":function () { return _vm.handleCommand(_vm.cellInfo, item.code); },"rejectCallback":function () { return _vm.showAuthDialog(item.authKey); }}},[_c('el-dropdown-item',{attrs:{"divided":item.divided,"command":item.code}},[_vm._v(_vm._s(item.name))])],1):_vm._e()]})],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }