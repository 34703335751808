import store from '@/store';
import productConfig from '@/common/configs/product.config';
import i18n from '@assets/i18n';
import { isSingleSpa, sendPostMessage } from '@/common/services/singleSpa.service';

const shutdown = function() {
    if (window.Intercom) {
        window.Intercom('shutdown');
    }
};

const shutup = function() {
    const { email, name, id, signupSource } = store.state.main.userInfo || {};
    if (window.Intercom) {
        window.Intercom('boot', {
            app_id: 's36cqmu2',
            email,
            user_id: id,
            name,
            signup_source: signupSource
        });
    }
};

/**
 * 操作类型
 * 参考API：https://developers.intercom.com/docs/configuration
 *
 * @pararm {String} type: ['show','showMessages','showNewMessage','hide']
 */
const operation = function(type) {
    if (window.Intercom) {
        window.Intercom(type);
    }
};

const contact = function() {
    if (isSingleSpa()) {
        sendPostMessage('ExperienceShowIntercom', {});
        return;
    }

    if (window.Intercom) {
        operation('show');
    } else {
        tiggerEmail();
    }
};

const tiggerEmail = function() {
    let a = document.querySelector('#contact_us');
    if (a) {
        a.click();
    } else {
        const { email } = productConfig[i18n.locale.toLowerCase()] || {};
        if (email) {
            let a = document.createElement('a');
            a.setAttribute('id', 'contact_us');
            a.setAttribute('href', `mailto: ${email}`);
            a.style.visibility = 'hidden';
            document.body.appendChild(a);
            a.click();
        }
    }
};

export default { contact, shutdown, shutup, operation };
