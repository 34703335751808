<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App'
};
</script>

<style lang="scss">
@import '@/styles/element-variables.scss';
@import '@/styles/element-reset.scss';
@import '@/styles/main.scss';
</style>
