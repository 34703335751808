<template>
    <transition name="fade">
        <div :class="$style.popup_wrapper">
            <div
                :class="[$style.popup_container, topColor && $style.popup_border, containerClass ? containerClass : '']"
                :style="popupStyle"
            >
                <div :class="$style.popup_close" @click="handleCloseClick" v-if="showClose">
                    <pt-icon icon="pt-icon--close" :iconStyle="{ width: '12px', height: '12px' }"></pt-icon>
                </div>
                <div :class="$style.popup_content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'ptPopupV2',
    props: {
        width: {
            type: String,
            default: 'auto'
        },
        top: {
            type: String,
            default: '15vh'
        },
        showClose: {
            type: Boolean,
            default: function() {
                return true;
            }
        },
        topColor: String,
        containerClass: String
    },
    computed: {
        popupStyle() {
            let popupStyle = {
                width: this.width,
                marginTop: this.top
            };
            if (this.topColor) {
                popupStyle.borderColor = this.topColor;
            }
            return popupStyle;
        }
    },
    methods: {
        handleCloseClick() {
            this.$emit('close');
        }
    }
};
</script>
<style lang="scss" module>
@import '../../../styles/import.scss';
.popup_wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(52, 69, 99, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
    line-height: 1;
    .popup_container {
        max-width: 80%;
        min-width: 400px;
        width: auto;
        padding: 0 60px;
        position: relative;
        margin: 0 auto 50px;
        border-radius: 8px;
        z-index: 4000;
        background-color: $pt-white;
        &.popup_border {
            border-top-width: 8px;
            border-top-style: solid;
            border-color: $pt-green-60;
        }
        .popup_close {
            position: absolute;
            right: 14px;
            top: 12px;
            cursor: pointer;
            svg {
                fill: $pt-black-300;
            }
            &:hover {
                svg {
                    fill: $pt-black-600;
                }
            }
        }
    }
}
</style>
