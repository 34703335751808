import commonUtils from '@common/utils/common.utils';
import uuidUtils from '@common/utils/uuid.utils';
import i18n from '@assets/i18n';
import cloneUtils from '@/common/utils/clone.utils';
import baseConfig from '@/common/configs/base.config';
import store from '@/store';
import engageApis from '@/apis/engage.apis';
import goalApis from '@/apis/goal.apis';
import graphqlApis from '@/apis/graphql';
import campaignSettingUtils from '@/components/campaign/setting/setting.utils';
import interactiveDemoService from '@/components/interactive-demo/interactive-demo.service';
import addVersionScreenshot from '@/common/services/addVersionScreenshot';
import dequal from '@ptengine/pt-ui-x-utils/dequal';

export default class EngageSettingStore {
    constructor() {
        const { sid, timeZone } = store.getters['main/profileInfo'];
        const { id, email } = store.getters['main/userInfo'];
        this.engage = null;
        this.state = {
            allGoalList: null, //当前档案下所有的goal
            allUsergroups: null, //当前档案下所有可用的audiences
            defaultUsergroup: {}, //当前当按下的默认audience
            route: null, //进入路由信息
            refer: null,
            inEditing: false, //是否为编辑模式
            currentVersionIndex: 0,
            currentTerminalType: 2,
            unfinishedItems: [], //未完成项目
            isEdited: false //是否已完成编辑, 判断是否进入过编辑器
        };
        this.user = {
            uid: id,
            sid,
            email,
            timeZone
        };
    }

    async init(route, defaultName) {
        const { name, query, params } = route;
        const { sid, type } = query;
        const inEditing = name === 'CampaignEdit';
        this.updateState('route', { name, query, params });
        this.updateState('inEditing', inEditing);
        this.updateState('isEdited', inEditing);
        if (!inEditing) {
            const [err, result] = await commonUtils.awaitWrap(this._fetchEngageName());
            if (err) return Promise.reject();
            const engageName = (result.data && result.data.defaultEngageName) || defaultName;
            const demoType = interactiveDemoService.getDemoType(sid, type);
            const demoName =
                demoType === 'customerAcquisition'
                    ? i18n.t('interactive_demo.customer_title')
                    : i18n.t('interactive_demo.upsell_title');
            const engage = this._getNewCampaign(demoType ? demoName : engageName);
            engage.versions = [this._getVersion()];
            this.engage = engage;
        }
    }

    /**
     * 设置Engage具体信息
     */
    async setEngage(engage) {
        if (this.state.allUsergroups) {
            const allUsergroups = [].concat(this.state.allUsergroups);
            allUsergroups.map(item => {
                item.checked = (engage.audiences || []).some(o => o.audience_id === item.usergroupID);
                return item;
            });
            this.updateState('allUsergroups', allUsergroups);
            if (!this.state.inEditing) {
                engage.versions = [this._getVersion()];
            }
            this.engage = engage;
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }

    async getAllUsergroups() {
        const allUserGroups = await this._fetchAudiencesList();
        if (allUserGroups.data && allUserGroups.data.usergroups) {
            const { usergroups } = allUserGroups.data;
            const defaultAudience = usergroups.find(item => item.source === 'default');
            this.updateState('allUsergroups', usergroups);
            this.updateState('defaultUsergroup', Object.assign(defaultAudience, { isInvalid: false }));
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }
    async getAllGoalList() {
        let allGoalList = await this._fetchGoalList();

        if (allGoalList) {
            allGoalList.map(item => {
                item.checked = false;
            });
            this.updateState('allGoalList', allGoalList);
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    }
    updateState(key, val) {
        if (Object.prototype.hasOwnProperty.call(this.state, key)) {
            this.state[key] = cloneUtils.deep(val);
        }
    }

    updateEngage(key, val) {
        if (this.engage && Object.prototype.hasOwnProperty.call(this.engage, key)) {
            this.engage[key] = cloneUtils.deep(val);
        }
    }

    updateEngageVersion(type, index, key, val) {
        const version = this.engage[type][index];
        if (version && Object.prototype.hasOwnProperty.call(version, key)) {
            version[key] = cloneUtils.deep(val);
        }
    }
    /**
     * 保存Engage
     */
    async saveEngage(callback, errCallback) {
        const { id, hasForm } = this.engage;
        const { uid, sid, email } = this.user;
        const postEngage = campaignSettingUtils.serializeCampaignPostData(
            this.engage,
            this.user,
            this.state.isCopyEditing
        );

        await addVersionScreenshot(postEngage);
        if (this.state.inEditing) {
            postEngage.updateBy = uid;
            this._editEngageRequest(postEngage, id, sid, email).then(
                content => {
                    callback && typeof callback === 'function' && callback(content);
                },
                err => {
                    const { code } = err;
                    code === 'PTX_PACKAGE_LIMIT_ERROR' &&
                        store.commit('main/SET_AUTH_DIALOG_VISIBLE', { visible: true, type: 'EDIT_CAMPAIGN' });
                    errCallback && typeof errCallback === 'function' && errCallback(err);
                }
            );
        } else {
            postEngage.createBy = uid;
            postEngage.updateBy = uid;
            this._saveEngageRequest(postEngage, true).then(
                content => {
                    store.commit('main/UPDATE_COUNTS', {
                        key: 'engageCount'
                    });
                    content?.count &&
                        store.commit('main/UPDATE_PACKAGE_USAGE', { type: 'campaigns', count: content.count });
                    callback && typeof callback === 'function' && callback(content);
                },
                err => {
                    errCallback && typeof errCallback === 'function' && errCallback(err);
                }
            );
        }
    }

    _saveEngageRequest(postEngage) {
        return engageApis.addEngagement(null, { data: postEngage });
    }

    _editEngageRequest(postEngage, engageId, sid, email) {
        return engageApis.updateEngagement(null, {
            where: {
                id: engageId,
                sid,
                email
            },
            data: postEngage
        });
    }
    checkIsRepeatName() {
        const { id = '', name } = this.engage;
        return engageApis.isEngageNameRepeat(null, {
            where: {
                id,
                sid: this.user.sid,
                name
            }
        });
    }
    /**
     * 获取Engage配置
     */
    _fetchEngageInfo(engageId) {
        return engageApis.getEngagement(null, {
            where: {
                id: engageId
            }
        });
    }

    /**
     * 获取Engage默认名称,按序号生成
     */
    _fetchEngageName() {
        // return 'Default Engage Name';
        const params = {
            operationName: 'defaultEngageName',
            query: `query defaultEngageName($where: EngageWhereUniqueInput!){
                defaultEngageName(where:$where)
            }`,
            variables: {
                where: {
                    sid: this.user.sid,
                    name: i18n.t('common.setting_engage_promo_name')
                }
            }
        };
        return graphqlApis.graphql(null, params);
    }

    /**
     * 获取Audiences列表
     */
    _fetchAudiencesList() {
        const getUserFilterParams = {
            operationName: 'getUsergroups',
            query: `query getUsergroups($where: UsergroupWhereUniqueInput!){
                usergroups(where: $where){
                  usergroupID: id
                  source
                  name
                  content
                }
              }`,
            variables: {
                where: {
                    sid: this.user.sid,
                    timeZone: this.user.timeZone
                }
            }
        };

        return graphqlApis.graphql(null, getUserFilterParams);
    }
    /**
     * 获取Goal列表
     */
    _fetchGoalList() {
        return goalApis.getGoals(null, { where: { sid: this.user.sid } });
    }
    /**
     * 获取Engage默认配置
     * forEngageVersionV3
     */
    _getNewCampaign(engageName) {
        const { domain } = store.getters['main/profileInfo'];

        return {
            id: uuidUtils.uuid_16int(),
            engageVersion: '3.0', //Engage大版本号
            baseURL: domain, //编辑页面的url
            screenshot: '', //编辑页面的截图
            name: engageName,
            status: 0, //默认engage状态为0 -> 草稿
            //  abtestType: 'creative',
            usergroups: dequal(this.state.defaultUsergroup, {}) ? [] : [this.state.defaultUsergroup],
            goals: [],
            schedule: [
                {
                    versionData: [],

                    versionMeta: {
                        start: '1',
                        stop: '1',
                        delivery: '1',
                        startDate: commonUtils.getTimestamp('start'),
                        stopDate: commonUtils.getTimestamp('stop'),

                        time: ['00:00', '23:59'],
                        week: [
                            {
                                status: true,
                                code: 'sun'
                            },
                            {
                                status: true,
                                code: 'mon'
                            },
                            {
                                status: true,
                                code: 'tue'
                            },
                            {
                                status: true,
                                code: 'wed'
                            },
                            {
                                status: true,
                                code: 'thu'
                            },
                            {
                                status: true,
                                code: 'fri'
                            },
                            {
                                status: true,
                                code: 'sat'
                            }
                        ]
                    },
                    timezone: {
                        profile: this.user.timeZone,
                        useVisitor: false
                    }
                }
            ],
            conditions: [
                {
                    when: [],
                    session: {
                        operator: 'and',
                        children: []
                    }
                }
            ],
            versions: [],
            hasVariant: false,
            hasForm: false,
            isVersionEvent: false
        };
    }

    /**
     * 获取Engage version 3.0下personalization
     */
    _getVersion() {
        return {
            versionId: uuidUtils.uuid_16int(),
            versionName: `${i18n.t('engagement.setting_engage_version')} 1`,
            versionRate: 100,
            versionHasError: false,
            // screenshot: '', //截图url
            controlGroup: false,
            status: 0,
            terminals: this._getTerminals()
        };
    }

    /**
     * 初始化terminal
     */
    _getTerminals() {
        return [
            {
                id: uuidUtils.uuid_16bit(),
                type: 2,
                status: 1,
                order: 1,
                popup: [],
                stickybar: [],
                inline: []
            },
            {
                id: uuidUtils.uuid_16bit(),
                type: 1,
                status: 0,
                order: 2,
                popup: [],
                stickybar: [],
                inline: []
            }
        ];
    }

    /**
     * 获取Engage版本默认名称
     */
    _getVersionName(version, nameNumber = 0) {
        const reg = /(\d+)$/g;
        const defaultName = i18n.t('engagement.setting_engage_version');
        const count = reg.exec(version.versionName);
        let name = `${defaultName} ${nameNumber + 1}`;
        if (version.controlGroup) {
            name = i18n.t('engagement.control_group');
        } else if (count) {
            name = `${version.versionName.trim().replace(count[0], '')} ${nameNumber + 1}`;
        }
        return name;
    }

    /**
     * 更新Engage各版本比例
     */
    updateEngageVersionRate() {
        const len = this.engage.versions.length;
        let cellLen = 0;
        if (1 % len !== 0) {
            cellLen = 100 - parseInt((1 / len) * 100) * len;
        }
        this.engage.versions.map((item, index) => {
            if (cellLen) {
                if (index < cellLen) {
                    item.versionRate = Math.ceil((1 / len) * 100);
                } else {
                    item.versionRate = Math.floor((1 / len) * 100);
                }
            } else {
                item.versionRate = (1 / len) * 100;
            }
        });
    }

    /**
     * 新增Engage版本, 按当前版本复制
     */
    addEngageVersion(isControlGroup) {
        const reg = /(\d+)$/g;

        //记录当前版本列表中名称数字最大值
        let nameNum = 0;
        this.engage.versions
            .filter(v => !v.controlGroup)
            .forEach(v => {
                const count = reg.exec(v.versionName);
                count && (nameNum = Math.max(parseInt(count), nameNum));
            });

        // 查找当前非页面组的版本
        let currentVersion = this.engage.versions[this.state.currentVersionIndex];
        currentVersion.controlGroup && (currentVersion = this.engage.versions.find(v => !v.controlGroup));

        const newVersion = cloneUtils.deep(currentVersion);
        newVersion.controlGroup = isControlGroup;
        newVersion.versionId = uuidUtils.uuid_16int();
        newVersion.versionName = this._getVersionName(newVersion, nameNum);

        if (isControlGroup) {
            newVersion.terminals = [];
        } else {
            (newVersion.terminals || []).forEach(terminal => {
                const { inline, popup, stickybar } = terminal;
                [
                    {
                        key: 'inline',
                        value: inline
                    },
                    {
                        key: 'popup',
                        value: popup
                    },
                    {
                        key: 'stickybar',
                        value: stickybar
                    }
                ].forEach(item => {
                    const { key, value } = item;
                    (value || []).forEach(v => {
                        v[key + 'Id'] = uuidUtils.uuid_16int();
                    });
                });
            });
        }
        this.engage.versions.push(newVersion);
        this.engage.versions?.sort((a, b) => a.controlGroup - b.controlGroup); //默认将对照组放末尾
        this.updateEngageVersionRate();
        this.updateState(
            'currentVersionIndex',
            this.engage.versions.findIndex(v => v.versionId === newVersion.versionId)
        );
    }

    /**
     * 删除Engage版本
     * @param {Number} versionId
     */
    deleteEngageVersion(versionId) {
        const index = this.engage.versions?.findIndex(version => version.versionId === versionId);
        this.engage.versions.splice(index, 1);
        this.engage.versions?.sort((a, b) => a.controlGroup - b.controlGroup); //默认将对照组放末尾
        this.updateEngageVersionRate();
        this.updateState('currentVersionIndex', 0);
    }

    clean() {
        this.engage = null;
        this.state.allUsergroups = null;
        this.state.currentVersionIndex = 0;
        this.state.inEditing = false;
        this.state.isCopyEditing = false;
        this.state.route = null;
        this.state.refer = null;
        this.state.unfinishedItems = [];
    }

    campaignHasInline(campaign) {
        return (campaign?.versions || []).some(v => {
            const { controlGroup, terminals } = v || {};
            return (
                !controlGroup &&
                (terminals || []).some(t => {
                    const { status, popup, stickybar } = t || {};
                    const hasError =
                        status === 1 && [...(popup || []), ...(stickybar || [])].some(layout => layout.popupHasError);
                    return t.status === 1 && !hasError && (t?.inline || []).length > 0;
                })
            );
        });
    }

    /**
     * 校验是否显示发布确认弹框，主要针对有inline修改的campaign提示防闪码提示。
     */
    showPublishConfirmDialog(campaign) {
        const showAntiFlickerTip = !localStorage.getItem(baseConfig.ANTI_FLICKER_TIP);
        const hasInline = this.campaignHasInline(campaign);
        return showAntiFlickerTip && hasInline;
    }
}
