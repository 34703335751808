import PtSortableDraggable from './src/Draggable.vue';
import PtSortableContainer from './src/Container.vue';

PtSortableDraggable.install = function(Vue) {
    Vue.component(PtSortableDraggable.name, PtSortableDraggable);
};

PtSortableContainer.install = function(Vue) {
    Vue.component(PtSortableContainer.name, PtSortableContainer);
};

export default { PtSortableDraggable, PtSortableContainer };
