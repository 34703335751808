/**
 * 产品设置相关api
 */

import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'getEventProperties',
        query: `query getEventProperties($where:SettingWhereUniqueInput!){
            eventProperties(where:$where){
                id
                sid
                name
                displayName
                source
                type
                description
                createTime
                updateTime
                status
            }
        }`
    },
    {
        name: 'updateEventProperty',
        query: `mutation updateEventProperty($data: PropertyCreateInput!, $where: SettingWhereUniqueInput!){
            updateEventProperty(data:$data, where:$where) {
                id
            }
        }`
    },
    {
        name: 'createEventProperties',
        query: `mutation createEventProperties($data:PropertiesCreateInput!){
            createEventProperties(data:$data)
        }`
    },
    {
        name: 'getEvents',
        query: `query getEvents($where:SettingWhereUniqueInput!){
            events(where:$where){
                id
                sid
                name
                displayName
                source
                description
                createTime
                updateTime
                status
            }
        }`
    },
    {
        name: 'updateEvent',
        query: `mutation updateEvent($data:EventUpdateInput!,$where: SettingWhereUniqueInput!){
            updateEvent(data:$data, where:$where) {
                id
            }
        }`
    },
    {
        name: 'createEvent',
        query: `mutation createEvent($data:EventCreateInput!){
            createEvent(data:$data)
        }`
    },
    {
        name: 'getUserProperties',
        query: `query getUserProperties($where:SettingWhereUniqueInput!){
            userProperties(where:$where){
                id
                sid
                name
                displayName
                source
                type
                description
                createTime
                updateTime
                status
            }
        }`
    },
    {
        name: 'updateUserProperty',
        query: `mutation updateUserProperty($data:PropertyCreateInput!,$where: SettingWhereUniqueInput!){
            updateUserProperty(data:$data, where:$where) {
                id
            }
        }`
    },
    {
        name: 'createUserProperties',
        query: `mutation createUserProperties($data:PropertiesCreateInput!){
            createUserProperties(data:$data)
        }`
    },
    {
        name:'generateUploadSignedUrl',
        query:`mutation generateUploadSignedUrl($input:UploadSignedUrlInput!){
            generateUploadSignedUrl(input:$input){
              url
              fields
            }
          }`
    },
    {
        name:'createUserPropertiesSyncTask',
        query:`mutation createUserPropertiesSyncTask($input:UserPropertiesSyncTaskInput){
            createUserPropertiesSyncTask(input:$input)
          }`
    }
];

export default resourceWrapper(resources);
