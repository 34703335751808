<template>
    <div class="block-settings" :class="{ 'has-tab': !isSubModel }">
        <template v-if="!isSubModel">
            <div class="block-settings__header">
                <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
                <h3>{{ $t(block.blockName) }}</h3>
            </div>

            <div class="block-settings__tab">
                <settings-tab :tab="currentTab" @change="handleTabChange"></settings-tab>
            </div>
        </template>

        <div class="block-settings__container">
            <pt-scrollbar ref="blockScrollbar">
                <div class="block-settings__item-wrap">
                    <!-- content -->
                    <template v-if="currentTab === 'content'">
                        <!-- type @fixed 应产品要求先上fixedDate一种模式 因为另一种模式目前暂时有逻辑缺陷，看市场需求在决定要不要-->
                        <!--<div class="block-settings__item">
                            <div class="block-settings__item-title">Type</div>
                            <div class="block-settings__item-container">
                                <el-select
                                    size="small"
                                    v-model="currentContentValue.type"
                                    @change="handleTypeChange"
                                >
                                    <el-option
                                        v-for="item in typeOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>-->
                        <!-- fixed Date -->
                        <template v-if="currentContentValue.type === 'fixedDate'">
                            <!-- date-picker -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.item_title_end_date') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <el-date-picker
                                        type="datetime"
                                        popper-class="small"
                                        align="right"
                                        :picker-options="pickerOptions"
                                        :clearable="false"
                                        :editable="false"
                                        format="yyyy/MM/dd HH:mm:ss"
                                        v-model="currentContentValue.endTime"
                                        @change="handleDateChange"
                                        size="small"
                                    ></el-date-picker>
                                </div>
                            </div>
                            <!-- timezone choose radio-->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.item_title_timezone') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <div class="block-action">
                                        <div class="block-action__radio">
                                            <el-radio
                                                v-model="currentContentValue.timezone.useVisitors"
                                                :label="false"
                                                @change="handleActionChange"
                                            >
                                                {{ $t('creative_editor.profile_timezone') }}(GTM {{ profileTimezone }})
                                            </el-radio>
                                        </div>
                                    </div>
                                    <div class="block-action">
                                        <div class="block-action__radio">
                                            <el-radio
                                                v-model="currentContentValue.timezone.useVisitors"
                                                :label="true"
                                                @change="handleActionChange"
                                                >{{ $t('creative_editor.visitor_timezone') }}</el-radio
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- fixed Time -->
                        <template v-else>
                            <!-- hr -->
                            <div class="block-settings__hr"></div>

                            <div class="block-settings__item" v-for="(item, index) in dateOptions" :key="index">
                                <div class="block-settings__item-title">{{ item.name }}</div>
                                <div class="block-settings__item-container flex-layout">
                                    <el-slider
                                        class="block-slider pt-ml10 pt-mr20"
                                        v-model="currentContentValue.date[item.code]"
                                        :show-tooltip="false"
                                        :min="item.min"
                                        :max="item.max"
                                        @change="handleContentChange"
                                    ></el-slider>
                                    <pt-input-number
                                        class="max-width-60"
                                        size="large"
                                        v-model="currentContentValue.date[item.code]"
                                        :min="item.min"
                                        :max="item.max"
                                        @change="handleContentChange"
                                    ></pt-input-number>
                                </div>
                            </div>
                        </template>

                        <!-- hr -->
                        <div class="block-settings__hr"></div>

                        <!-- labels -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title two-cloumn">
                                <span>{{ $t('creative_editor.countdown_labels') }}</span>
                                <div class="title-options">
                                    <el-switch
                                        class="el-switch__pt-small"
                                        v-model="currentContentValue.labels.enable"
                                        :width="28"
                                        @change="handleContentChange"
                                    ></el-switch>
                                </div>
                            </div>
                        </div>
                        <template v-if="currentContentValue.labels.enable">
                            <!-- label language -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.countdown_labels_language') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <el-select
                                        size="small"
                                        v-model="currentContentValue.labels.language"
                                        @change="handleLanguageChange"
                                    >
                                        <el-option
                                            v-for="item in languageOptions"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <!-- label fontSize -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">{{ $t('creative_editor.item_font_size') }}</div>
                                <div class="block-settings__item-container">
                                    <pt-input-number
                                        size="large"
                                        v-model="currentContentValue.labels.fontSize"
                                        :min="fontSizeOptions.min"
                                        :max="fontSizeOptions.max"
                                        @change="handleContentChange"
                                    ></pt-input-number>
                                </div>
                            </div>
                            <!-- label fontColor -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.item_font_color') }}
                                </div>
                                <div class="block-settings__item-container flex-layout">
                                    <el-color-picker
                                        class="pt-mr10"
                                        v-model="currentContentValue.labels.fontColor"
                                        @change="handleContentChange"
                                    ></el-color-picker>
                                    <el-input
                                        size="small"
                                        v-model="currentContentValue.labels.fontColor"
                                        @change="handleContentChange"
                                    ></el-input>
                                </div>
                            </div>
                        </template>
                        <!-- hr -->
                        <div class="block-settings__hr"></div>

                        <!-- Numbers Config (fontSize & fontWeight)-->
                        <div class="block-settings__item-group two-cloumn">
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.item_title_number_size') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <pt-input-number
                                        size="large"
                                        v-model="currentContentValue.numbers.fontSize"
                                        :max="fontSizeOptions.max"
                                        :min="fontSizeOptions.min"
                                        @change="handleContentChange"
                                    ></pt-input-number>
                                </div>
                            </div>
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.item_stroke_weight') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <el-select
                                        size="small"
                                        v-model="currentContentValue.numbers.fontWeight"
                                        @change="handleContentChange"
                                    >
                                        <el-option
                                            v-for="item in fontWeightOptions"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <!-- Number color -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">
                                {{ $t('creative_editor.item_title_number_color') }}
                            </div>
                            <div class="block-settings__item-container flex-layout">
                                <el-color-picker
                                    class="pt-mr10"
                                    v-model="currentContentValue.numbers.fontColor"
                                    @change="handleContentChange"
                                ></el-color-picker>
                                <el-input
                                    size="small"
                                    v-model="currentContentValue.numbers.fontColor"
                                    @change="handleContentChange"
                                ></el-input>
                            </div>
                        </div>
                        <!-- Number backgroundColor -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.creative_widget_bg') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <el-color-picker
                                    class="pt-mr10"
                                    v-model="currentContentValue.numbers.backgroundColor"
                                    @change="handleContentChange"
                                ></el-color-picker>
                                <el-input
                                    size="small"
                                    v-model="currentContentValue.numbers.backgroundColor"
                                    @change="handleContentChange"
                                ></el-input>
                            </div>
                        </div>
                        <!-- Number separatorColor -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">
                                {{ $t('creative_editor.item_title_separator_color') }}
                            </div>
                            <div class="block-settings__item-container flex-layout">
                                <el-color-picker
                                    class="pt-mr10"
                                    v-model="currentContentValue.numbers.separatorColor"
                                    @change="handleContentChange"
                                ></el-color-picker>
                                <el-input
                                    size="small"
                                    v-model="currentContentValue.numbers.separatorColor"
                                    @change="handleContentChange"
                                ></el-input>
                            </div>
                        </div>
                    </template>

                    <!-- settings -->
                    <template v-else>
                        <div class="block-settings__content-tab">
                            <el-tabs v-model="currentSettingTab">
                                <el-tab-pane
                                    v-for="item in settingTabOptions"
                                    :key="item.code"
                                    :label="item.name"
                                    :name="item.code"
                                ></el-tab-pane>
                            </el-tabs>
                        </div>
                        <!-- layout -->
                        <div class="block-settings__item-wrap" v-if="currentSettingTab === 'layout'">
                            <!-- labels layout -->
                            <template v-if="hasTimeLabels">
                                <div class="block-settings__item">
                                    <div class="block-settings__item-title">
                                        {{ $t('creative_editor.item_layout') }}
                                    </div>
                                    <div class="block-settings__item-container flex-layout">
                                        <div
                                            class="block-item__icon"
                                            v-for="item in layoutOptions"
                                            :key="item.code"
                                            :class="{ active: settings.layout === item.code }"
                                            @click="handleLayoutClick(item.code)"
                                        >
                                            <pt-icon :icon="item.icon"></pt-icon>
                                        </div>
                                    </div>
                                </div>
                                <!-- hr -->
                                <div class="block-settings__hr"></div>
                            </template>
                            <!-- Background color -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.creative_widget_bg') }}
                                </div>
                                <div class="block-settings__item-container flex-layout">
                                    <el-color-picker
                                        class="pt-mr10"
                                        v-model="settings.backgroundColor"
                                        @change="handleSettingsChange"
                                    ></el-color-picker>
                                    <el-input
                                        size="small"
                                        v-model="settings.backgroundColor"
                                        @change="handleSettingsChange"
                                    ></el-input>
                                </div>
                            </div>

                            <!-- Margin top -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.block_margin_top') }}
                                </div>
                                <div class="block-settings__item-container flex-layout">
                                    <el-slider
                                        class="block-slider pt-ml10 pt-mr20"
                                        v-model="settings.marginTop"
                                        :show-tooltip="false"
                                        :min="marginOptions.min"
                                        :max="marginOptions.max"
                                        @change="handleSettingsChange"
                                    ></el-slider>
                                    <pt-input-number
                                        class="max-width-60"
                                        size="large"
                                        v-model="settings.marginTop"
                                        :min="marginOptions.min"
                                        :max="marginOptions.max"
                                        @change="handleSettingsChange"
                                    ></pt-input-number>
                                </div>
                            </div>

                            <!-- Margin bottom -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.block_margin_bottom') }}
                                </div>
                                <div class="block-settings__item-container flex-layout">
                                    <el-slider
                                        class="block-slider pt-ml10 pt-mr20"
                                        v-model="settings.marginBottom"
                                        :show-tooltip="false"
                                        :min="marginOptions.min"
                                        :max="marginOptions.max"
                                        @change="handleSettingsChange"
                                    ></el-slider>
                                    <pt-input-number
                                        class="max-width-60"
                                        size="large"
                                        v-model="settings.marginBottom"
                                        :min="marginOptions.min"
                                        :max="marginOptions.max"
                                        @change="handleSettingsChange"
                                    ></pt-input-number>
                                </div>
                            </div>
                        </div>

                        <!-- endAction -->
                        <div class="block-settings__item-wrap" v-if="currentSettingTab === 'action'">
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.countdown_action_title') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <div class="block-action" v-for="item in actionRadioOptions" :key="item.code">
                                        <div class="block-action__radio">
                                            <el-radio
                                                v-model="settings.endAction"
                                                :label="item.code"
                                                @change="handleActionChange"
                                                >{{ item.name }}</el-radio
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="settings.endAction === 'showMsg'">
                                <div class="block-settings__hr"></div>
                                <!-- richtext endMessage -->
                                <div class="block-settings__item">
                                    <div class="block-settings__item-container">
                                        <pt-richtext
                                            ref="textEditor"
                                            :code="currentContentCode"
                                            :value="settings.endMessage"
                                            @change="handleTextChange"
                                        ></pt-richtext>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </pt-scrollbar>
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import config from './countdown.config';
import SettingsTab from '../SettingsTab';
import commonUtils from '@/common/utils/common.utils';
import { tabOptions } from '../../configs/creative.config';
import PtRichtext from '@ptengine/pt-ui-x/components/richtext/index.js';

export default {
    name: 'CountdownSettings',
    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        widgetIndex: Number
    },
    inject: ['editor'],
    data() {
        const defaultTab = tabOptions.find(item => item.default); // settings or content
        const defaultContentTab = config.content.find(item => item.default);
        const defaultSettingTab = config.settingTabOptions.find(item => item.default);
        const {
            typeOptions,
            dateOptions,
            allRegionlanguageOptions,
            fontSizeOptions,
            fontWeightOptions,
            layoutOptions,
            actionRadioOptions,
            marginOptions,
            timezoneOptions,
            settingTabOptions
        } = config;
        return {
            typeOptions,
            dateOptions,
            allRegionlanguageOptions,
            fontSizeOptions,
            fontWeightOptions,
            layoutOptions,
            actionRadioOptions,
            marginOptions,
            timezoneOptions,
            settingTabOptions,
            defaultContentTab,
            defaultTab,
            defaultSettingTab,
            currentTab: '',
            currentContentCode: '',
            currentSettingTab: '',
            content: [],
            settings: {},
            profileTimezone: window.pt.global.timezone,
            pickerOptions: {
                // Can't choose the future more than 99 days
                disabledDate(time) {
                    let timezone = pt.global.timezone;
                    let minuteTime = 1000 * 60;
                    let maxTime = 98 * 24 * 60 * 60 * 1000;
                    let minTime = 1 * 24 * 60 * 60 * 1000;
                    let profileOffsetGMT = dayjs().utcOffset(timezone)._offset;
                    let targetTime = new Date().getTime() + maxTime;
                    let nowOffsetGMT = new Date().getTimezoneOffset();
                    let targetDate = new Date(targetTime + nowOffsetGMT * minuteTime + profileOffsetGMT * minuteTime);
                    return time.getTime() > targetDate.getTime() || time.getTime() < new Date().getTime() - minTime;
                }
            }
        };
    },
    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            contentConfig &&
                contentConfig.value &&
                contentConfig.value.timezone &&
                (contentConfig.value.timezone.profile = this.profileTimezone);
            return (contentConfig && contentConfig.value) || {};
        },
        languageOptions() {
            const region = window.pt.global.region;
            const excludeLanMap = {
                en: 'jp',
                zh: 'jp',
                jp: 'zh'
            };
            const excludeLan = excludeLanMap[region] || 'jp'; // 为了兼容欧美区其他语言
            const res = this.allRegionlanguageOptions.filter(item => item.code !== excludeLan);
            return res || [];
        },
        hasTimeLabels() {
            return this.currentContentValue.labels.enable;
        },
        isRenderMsg() {
            return (
                this.currentSettingTab === 'action' &&
                this.currentTab === 'settings' &&
                this.settings.endAction === 'showMsg' &&
                this.editor.currentWidget.widgetMode === 'visualModel' &&
                this.editor.visibleSettingsType === 'blockSettings'
            );
        }
    },
    watch: {
        isRenderMsg(newVal) {
            console.log('watch isRenderMsg newVal', newVal);
            this.settings.isRenderMsg = newVal;
            console.log('this.settings.isRenderMsg', this.settings.isRenderMsg);
            this.handleSettingsChange();
        }
    },
    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.currentSettingTab = this.defaultSettingTab.code;
            this.currentContentCode = this.defaultContentTab.code; // content的默认标签code
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
        },
        handleBack() {
            this.settings.isRenderMsg = false;
            this.handleSettingsChange();
            this.$emit('back');
        },
        handleTabChange(tab) {
            this.currentTab = tab;
            if (tab === 'content') {
                this.currentContentCode = this.content[0].code;
            }
            this.$refs.blockScrollbar && this.$refs.blockScrollbar.scrollToPos('top');
        },
        handleContentChange() {
            this.$emit('contentChange', this.content);
        },
        handleTypeChange() {
            console.log('handleTypeChange from type select option change');
            this.handleContentChange();
        },
        handleLanguageChange() {
            this.handleContentChange();
        },
        handleSettingsChange() {
            console.log('handleSettingChange ', this.settings);
            this.$emit('settingsChange', this.settings);
        },
        handleLayoutClick(layout) {
            this.settings.layout = layout;
            this.handleSettingsChange();
        },
        handleActionChange(value) {
            this.$nextTick(() => {
                this.handleSettingsChange();
            });
        },
        handleTextChange(value) {
            this.$set(this.settings, 'endMessage', value);
            this.handleSettingsChange();
        },
        handleDateChange(value) {
            this.currentContentValue.endTime = commonUtils.getDateStr(value);
            console.log(this.currentContentValue.endTime);
            this.handleContentChange();
        }
    },
    created() {
        this.init();
    },
    components: {
        SettingsTab,
        PtRichtext
    },
    beforeDestroy() {
        // this.settings.isRenderMsg = false;
        // this.editor.currentBlock &&
        //     (this.editor.currentBlock.blockSettings.isRenderMsg = false);
        // this.handleSettingsChange();
        // console.log('beforeDestory ', this.block.blockSettings);
    }
};
</script>
