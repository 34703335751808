<template>
    <pt-scrollbar>
        <div class="form-design-settings__wrap block-settings">
            <el-collapse v-model="activeBlock" accordion>
                <el-collapse-item
                    v-for="block in formBlocks"
                    :key="block.formCode"
                    :name="block.formCode"
                    class="form-design-settings__block"
                >
                    <div slot="title" class="form-design-settings__title">{{$t(block.name)}}</div>
                    <!-- <pt-scrollbar> -->
                    <div class="form-design-settings__content">
                        <component
                            v-if="block.config"
                            :is="block.component"
                            :block="block"
                            :isSubModel="true"
                            refer="form"
                            tab="design"
                            @contentChange="handleBlockContentUpdate"
                            @settingsChange="handleBlockSettingsUpdate"
                        ></component>
                        <span v-else>{{block.name + ' settings'}}</span>
                    </div>
                    <!-- </pt-scrollbar> -->
                </el-collapse-item>
            </el-collapse>
        </div>
    </pt-scrollbar>
</template>
<script>
import ButtonSettings from '../button/ButtonSettings.vue';
export default {
    name: 'FormDesignSettings',
    props: {
        block: {
            type: Object,
            required: true
        },
        blockList: Array,
        content: Array,
        settings: Object
    },
    data() {
        return {
            activeBlock: ''
        };
    },
    computed: {
        formBlocks() {
            let formBlocks = [];
            this.blockList.forEach(block => {
                const { code, component, config, name, formCode } = block;
                let blockContent = this.content.find(item => item.code === block.code);
                console.log('formBlocks', block, blockContent);
                let blockValue = blockContent && blockContent.value;
                let blockId = blockValue && blockValue.id;
                let blockName = '';
                let formBlockContent = (blockValue && blockValue.content) || [];
                let blockSettings = this.settings[formCode] || {};
                formBlocks.push(
                    Object.assign(block, {
                        blockId,
                        blockName,
                        blockContent: formBlockContent,
                        blockSettings
                    })
                );
            });

            return formBlocks;
        }
    },
    methods: {
        handleChange(block) {
            this.activeBlock = block;
        },
        handleBlockContentUpdate(content, params) {
            //TODO
            console.log('form design settings handleBlockContentUpdate', content, params);
        },
        handleBlockSettingsUpdate(val, type) {
            this.$emit('settingsChange', val, type);
            console.log('form design settings handleBlockSettingsUpdate', val, type);
        }
    },
    provide() {
        return {
            formDesign: this
        };
    },
    components: {
        ButtonSettings
    }
};
</script>
<style lang="scss">
@import '@/styles/import.scss';
.form-design-settings {
    &__title {
        padding-left: 20px;
        font-weight: 600;
    }
    &__content {
        .block-settings__item-wrap {
            padding: 8px 0 !important;
            // max-height: 420px;
            // height: calc(100% - 300px) !important;
        }
    }
    &__block {
        min-height: 48px;
        // height: 100%;
    }
    /*  &__wrap.block-settings {
        height: 95% !important;
    } */
}
</style>