import i18n from '@assets/i18n';
import dayjs from 'dayjs';
import momentTimezone from 'moment-timezone';
import localesConfig from '@/common/configs/locales.config';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import('dayjs/locale/ja.js');
import('dayjs/locale/en.js');
import('dayjs/locale/zh-cn.js');

function setDayjsLocal() {
    const { locale } = i18n;
    const localeInfo = localesConfig.find(item => item.key === locale);
    if (localeInfo) {
        const key = localeInfo.dayjsKey;
        require(`dayjs/locale/${key}.js`);
        dayjs.locale(key);
        dayjs.Ls[key].weekStart = 1; // 设置周起始日为周一
    }
}
setDayjsLocal();
/**
 * 日期格式化
 * see http://blog.stevenlevithan.com/archives/date-time-format
 */
var dateFormat = (function() {
    var token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g;
    var timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g;
    var timezoneClip = /[^-+\dA-Z]/g;
    var pad = function(val, len) {
        val = String(val);
        len = len || 2;
        while (val.length < len) val = '0' + val;
        return val;
    };

    // Regexes and supporting functions are cached through closure
    return function(date, mask, utc) {
        var dF = dateFormat;

        // You can't provide utc if you skip other args (use the "UTC:" mask prefix)
        if (arguments.length === 1 && Object.prototype.toString.call(date) === '[object String]' && !/\d/.test(date)) {
            mask = date;
            date = undefined;
        }

        // Passing date through Date applies Date.parse, if necessary
        date = date ? new Date(date) : new Date();
        if (isNaN(date)) throw SyntaxError('invalid date');

        mask = String(dF.masks[mask] || mask || dF.masks['default']);

        // Allow setting the utc argument via the mask
        if (mask.slice(0, 4) === 'UTC:') {
            mask = mask.slice(4);
            utc = true;
        }

        var _ = utc ? 'getUTC' : 'get';
        var d = date[_ + 'Date']();
        var D = date[_ + 'Day']();
        var m = date[_ + 'Month']();
        var y = date[_ + 'FullYear']();
        var H = date[_ + 'Hours']();
        var M = date[_ + 'Minutes']();
        var s = date[_ + 'Seconds']();
        var L = date[_ + 'Milliseconds']();
        var o = utc ? 0 : date.getTimezoneOffset();
        var flags = {
            d: d,
            dd: pad(d),
            ddd: dF.i18n.dayNames[D],
            dddd: dF.i18n.dayNames[D + 7],
            m: m + 1,
            mm: pad(m + 1),
            mmm: dF.i18n.monthNames[m],
            mmmm: dF.i18n.monthNames[m + 12],
            yy: String(y).slice(2),
            yyyy: y,
            h: H % 12 || 12,
            hh: pad(H % 12 || 12),
            H: H,
            HH: pad(H),
            M: M,
            MM: pad(M),
            s: s,
            ss: pad(s),
            l: pad(L, 3),
            L: pad(L > 99 ? Math.round(L / 10) : L),
            t: H < 12 ? 'a' : 'p',
            tt: H < 12 ? 'am' : 'pm',
            T: H < 12 ? 'A' : 'P',
            TT: H < 12 ? 'AM' : 'PM',
            Z: utc ? 'UTC' : (String(date).match(timezone) || ['']).pop().replace(timezoneClip, ''),
            o: (o > 0 ? '-' : '+') + pad(Math.floor(Math.abs(o) / 60) * 100 + (Math.abs(o) % 60), 4),
            S: ['th', 'st', 'nd', 'rd'][d % 10 > 3 ? 0 : (((d % 100) - (d % 10) !== 10) * d) % 10]
        };

        return mask.replace(token, function($0) {
            return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
        });
    };
})();

Date.prototype.format = function(interval = '/') {
    var s = '';
    var mouth = this.getMonth() + 1 >= 10 ? this.getMonth() + 1 : '0' + (this.getMonth() + 1);
    var day = this.getDate() >= 10 ? this.getDate() : '0' + this.getDate();
    s += this.getFullYear() + interval; // 获取年份。
    s += mouth + interval; // 获取月份。
    s += day; // 获取日。
    return s; // 返回日期。
};

// Some common format strings
dateFormat.masks = {
    default: 'ddd mmm dd yyyy HH:MM:ss',
    shortDate: 'm/d/yy',
    mediumDate: 'mmm d, yyyy',
    longDate: 'mmmm d, yyyy',
    fullDate: 'dddd, mmmm d, yyyy',
    shortTime: 'h:MM TT',
    mediumTime: 'h:MM:ss TT',
    longTime: 'h:MM:ss TT Z',
    isoDate: 'yyyy-mm-dd',
    isoTime: 'HH:MM:ss',
    isoDateTime: "yyyy-mm-dd'T'HH:MM:ss",
    isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
};

// Internationalization strings
dateFormat.i18n = {
    dayNames: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ],
    monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
};

/**
 * 不污染Date及prototype对象为原则
 * @type {{add: module.exports.add}}
 */
/**
 * 是否是日期类型
 * @param obj
 * @returns {boolean}
 */
function isDate(obj) {
    return Object.prototype.toString(obj) === '[object Date]';
}

/**
 * 将字符串解析为日期对象
 * @param str
 * @returns {Date}
 */
function parse(str) {
    return new Date(str);
}

/**
 * 获取该日期所在月份的第一天
 * @param date
 * @returns {*}
 */
function getFirstDayOfDate(date) {
    var cloned = this.clone(date);
    cloned.setDate(1);
    return cloned;
}

/**
 * 清除时分秒和毫秒
 * @param date
 */
function clearTime(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

/**
 * 克隆日期对象
 * @param date
 * @returns {Date}
 */
function clone(date) {
    return new Date(date.getTime());
}

/**
 * 增加(减少)天数
 * @param date
 * @param num
 * @returns {*}
 */
function addDays(date, num) {
    date.setDate(date.getDate() + num);
    return date;
}

/**
 * 月份操作
 * @param date
 * @param num
 * @returns {*}
 */
function addMonths(date, num) {
    date.setMonth(date.getMonth() + num);
    return date;
}

/**
 * 年份操作
 * @param date
 * @param num
 */
function addYears(date, num) {
    date.setFullYear(date.getFullYear() + num);
    return date;
}

/**
 * 比较两个日期,如果第一个大于第二个则返回1, 小于则返回-1, 相等则返回0
 * @param date1
 * @param date2
 * @returns {number}
 */
function compare(date1, date2) {
    if (date1.valueOf() < date2.valueOf()) {
        return -1;
    } else if (date1.valueOf() > date2.valueOf()) {
        return 1;
    }
    return 0;
}

/**
 * 通过增加时间来改变原始
 * api-link: http://momentjs.cn/docs/#/manipulating/add/
 */
function momentAdd(date, num, type = 'days', format = 'YYYY/MM/DD') {
    return dayjs(new Date(date))
        .add(num, type)
        .format(format);
}

/**
 * @param {Number} num
 * @param {String} type: [year, month, week, day]
 * @param {Number} isStart
 */
function getMomentTime({ num, type, isStart, timezone, format = 'YYYY/MM/DD' }) {
    setDayjsLocal();
    const nowTime = new Date().getTime();
    const momentItem = timezone ? formatTimezone(nowTime, timezone) : dayjs();
    const date = dayjs(momentItem).add(num, type);
    return (isStart ? date.startOf(type) : date.endOf(type)).format(format);
}

/**
 *
 * @param {*} time : string | number
 * @param {*} localTimezone : string | undefined
 * @param {*} format : YYYY/MM/DD
 * @returns
 */
function formatTimezone(time, localTimezone, format = 'YYYY/MM/DD') {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs(time)
        .tz(localTimezone)
        .format(format);
}

function getDateForTimezone(timezone, date) {
    const targetDate = date || new Date();
    const localTimezone = momentTimezone.tz.guess();
    const targetTime = momentTimezone.tz(targetDate, timezone || localTimezone);
    return new Date(momentTimezone(targetTime).tz(localTimezone));

    // dayjs.extend(utc);
    // dayjs.extend(timezone);
    // const targetDate = date || new Date();
    // return new Date(
    //     dayjs(targetDate)
    //         .tz(localTimezone)
    //         .format('YYYY/MM/DD')
    // );
}

/**
 * 获取两个时间之间的天数
 * api-link: http://momentjs.cn/docs/#/displaying/difference/
 * @param {String} startDate: '2020/03/20'
 * @param {*} endDate: '2020/03/31'
 * @returns {Number}
 */
function getDateDiff(startDate, endDate, type = 'days', includeToday = true) {
    const a = dayjs(new Date(endDate));
    const b = dayjs(new Date(startDate));
    const days = a.diff(b, type);
    return includeToday ? days + 1 : days;
}

/**
 * 获取连续时间
 * @param {*} startDate: '2020/03/24'
 * @param {*} endDate: '2020/03/31'
 */
function getDays(startDate, endDate, format = 'YYYY/MM/DD') {
    const dates = [];
    let currDate = dayjs(new Date(startDate)).startOf('day');
    const lastDate = dayjs(new Date(endDate)).startOf('day');
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        currDate = currDate.add(1, 'days');
        dates.push(currDate.clone().format(format));
    }

    return [startDate, ...dates, endDate];
}

/**
 * 获取24小时数组
 */
function getHours() {
    return Array(24)
        .fill('')
        .map((item, index) => {
            const num = index < 10 ? `0${index}` : index;
            return `${num}:00`;
        });
}

function getDuration(timestamp) {
    timestamp = Number.parseInt(timestamp);
    const days = timestamp / 1000 / 60 / 60 / 24;
    const daysRound = Math.floor(days);
    const hours = timestamp / 1000 / 60 / 60 - 24 * daysRound;
    const hoursRound = Math.floor(hours);
    const minutes = timestamp / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    const minutesRound = Math.floor(minutes);
    const seconds = Number.parseInt(
        timestamp / 1000 - 24 * 60 * 60 * daysRound - 60 * 60 * hoursRound - 60 * minutesRound
    );
    let time = `${minutesRound > 9 ? minutesRound : '0' + minutesRound}:${seconds > 9 ? seconds : '0' + seconds}`;
    hoursRound && (time = `${hoursRound > 9 ? hoursRound : '0' + hoursRound}:${time}`);
    return time;
}

function getPrevMonth(date) {
    const dateObj = dayjs(date).add(-1, 'month');
    return {
        startTime: dateObj.startOf('month'),
        endTime: dateObj.endOf('month')
    };
}

/**
 * 获取指定日期
 * state: {
 *  value,
 *  isIncludeToday
 * }
 */
function getDate(dateKey, timezone, state) {
    let startTime = null;
    let endTime = null;
    switch (dateKey) {
        case 'today':
            startTime = endTime = getMomentTime({
                num: 0,
                type: 'day',
                isStart: true,
                timezone
            });
            break;
        case 'yesterday':
            startTime = endTime = getMomentTime({
                num: -1,
                type: 'day',
                isStart: true,
                timezone
            });
            break;
        case 'this_week':
            startTime = getMomentTime({
                num: 0,
                type: 'week',
                isStart: true,
                timezone
            });
            endTime = getMomentTime({
                num: 0,
                type: 'day',
                isStart: false,
                timezone
            });
            break;
        case 'last_week':
            startTime = getMomentTime({
                num: -1,
                type: 'week',
                isStart: true,
                timezone
            });

            endTime = getMomentTime({
                num: -1,
                type: 'week',
                isStart: false,
                timezone
            });
            break;
        case 'this_month':
            startTime = getMomentTime({
                num: 0,
                type: 'month',
                isStart: true,
                timezone
            });
            endTime = getMomentTime({
                num: 0,
                type: 'day',
                isStart: false,
                timezone
            });
            break;
        case 'last_month':
            startTime = getMomentTime({
                num: -1,
                type: 'month',
                isStart: true,
                timezone
            });
            endTime = getMomentTime({
                num: -1,
                type: 'month',
                isStart: false,
                timezone
            });
            break;
        case 'past':
            const { value, isIncludeToday } = state;
            const startDays = -(value - (isIncludeToday ? 1 : 0));
            const endDays = isIncludeToday ? 0 : -1;
            startTime = getMomentTime({
                num: startDays,
                type: 'day',
                isStart: true,
                timezone
            });
            endTime = getMomentTime({
                num: endDays,
                type: 'day',
                isStart: false,
                timezone
            });
            break;
    }
    return {
        startTime,
        endTime
    };
}

export default {
    isDate,
    parse,
    format: dateFormat,
    getFirstDayOfDate,
    clearTime,
    clone,
    addDays,
    addMonths,
    addYears,
    compare,
    momentAdd,
    getMomentTime,
    getDate,
    getDateForTimezone,
    formatTimezone,
    getDateDiff,
    getDays,
    getHours,
    getDuration,
    getPrevMonth
};
