<template>
    <label
        class="pt-radio"
        :class="[classes ? classes : '', theme ? 'pt-radio__theme-' + theme : '', isChecked ? 'pt-radio__checked' : '']"
    >
        <div class="pt-radio__wrapper">
            <input
                type="radio"
                :name="name"
                :value="val"
                :disabled="disabled"
                :checked="isChecked"
                @change="onChange"
            />
            <span class="pt-radio_outer"></span>
            <span class="pt-radio_inner"></span>
        </div>
        <pt-svg class="pt-radio__svg" :use-id="icon" v-if="icon"></pt-svg>
        <div class="pt-radio__text" v-if="label || $slots.default">
            <slot>{{ label }}</slot>
        </div>
    </label>
</template>

<script>
export default {
    name: 'ptRadio',
    props: {
        classes: null,
        theme: String,
        name: String,
        label: String,
        value: {
            type: [Number, String, Boolean],
            required: true
        },
        val: {
            type: [Number, String, Boolean],
            required: true
        },
        icon: [Number, String],
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isChecked() {
            this.$emit('isChecked', this.val == this.value || this.checked);
            return this.val == this.value || this.checked;
        }
    },
    created() {
        if (this.checked) {
            this.$emit('input', this.val);
        }
    },
    methods: {
        onChange(e) {
            this.$emit('input', this.val);
            this.$emit('change', this.val, this.name);
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import.scss';

$radio-size: 16px;
$radio-unchecked-color: #ccc;
$radio-border-width: 1px;

.pt-radio {
    display: flex;
    align-items: center;
    height: 26px;
    margin: 0;

    &__wrapper {
        position: relative;
        width: $radio-size;
        height: $radio-size;
        cursor: pointer;

        input[type='radio'] {
            position: absolute;
            visibility: hidden;
            &:checked ~ .pt-radio_outer {
                background-color: $pt-green-60;
                border-color: $pt-green-60;
            }
            &:checked ~ .pt-radio_inner {
                opacity: 1;
                background-color: #464646;
                transform: scale(0.25);
            }
        }
    }
    &_outer {
        position: absolute;
        top: 0;
        left: 0;
        width: $radio-size;
        height: $radio-size;
        border: $radio-border-width solid #464646;
        border-radius: 100%;
        transition: border 0.25s linear;
    }
    &_inner {
        position: absolute;
        width: $radio-size;
        height: $radio-size;
        top: 0;
        left: 0;
        background-color: $pt-green-60;
        opacity: 0;
        transform: scale(1);
        transition-duration: 0.25s;
        transition-property: transform, opacity, background-color;
        border-radius: 100%;
    }
    &__svg {
        width: 14px;
        height: 14px;
        margin: 0 3px 0 5px;
        fill: #999;
    }
    &__text {
        padding-left: 6px;
        cursor: pointer;
        font-size: 13px;
        color: #091e42;
        font-weight: 400;
    }
    &__theme {
        &-dark {
            .pt-radio_outer {
                border-color: #464646;
            }
            .pt-radio__text {
                color: #999;
            }
            &.pt-radio__checked {
                .pt-radio__text,
                &:hover .pt-radio__text {
                    color: $pt-green-60;
                }
                .pt-radio__svg,
                &:hover .pt-radio__svg {
                    fill: $pt-green-60;
                }
            }
            &:hover {
                .pt-radio__text {
                    color: $pt-white;
                }
                .pt-radio__svg {
                    fill: $pt-white;
                }
            }
            input[type='radio'] {
                &:checked ~ .pt-radio_inner {
                    background-color: #2e2e3a;
                }
            }
        }
        &-light {
            .pt-radio_outer {
                border-color: #e0e0e0;
            }
            &.pt-radio__checked {
                .pt-radio__text,
                &:hover .pt-radio__text {
                    color: $pt-green-60;
                }
                .pt-radio__svg,
                &:hover .pt-radio__svg {
                    fill: $pt-green-60;
                }
            }
            &:hover {
                .pt-radio__text {
                    color: #444;
                }
                .pt-radio__svg {
                    fill: #444;
                }
            }

            input[type='radio'] {
                &:checked ~ .pt-radio_inner {
                    background-color: $pt-white;
                }
            }
        }
    }
}
</style>
