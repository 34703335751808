<template>
    <div :class="$style.goals">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Goals'
};
</script>

<style lang="scss" module>
.goals {
    height: 100%;
    min-height: calc(100vh - 50px);
    overflow: hidden;
}
</style>
