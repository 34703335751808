<template>
    <div
        class="scrollbar__holder ps-container"
        :class="['ps-theme-'+theme, (disabled ? 'isDisabled' : '')]"
    >
        <slot></slot>
    </div>
</template>


<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
    name: 'ptScrollbar',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        wheelSpeed: {
            type: Number,
            default: 1
        },
        wheelPropagation: {
            type: Boolean,
            default: false
        },
        swipePropagation: {
            type: Boolean,
            default: true
        },
        minScrollbarLength: {
            type: Number,
            default: 20
        },
        maxScrollbarLength: Number,
        useBothWheelAxes: {
            type: Boolean,
            default: false
        },
        suppressScrollX: {
            type: Boolean,
            default: true
        },
        suppressScrollY: {
            type: Boolean,
            default: false
        },
        scrollXMarginOffset: {
            type: Number,
            default: 0
        },
        scrollYMarginOffset: {
            type: Number,
            default: 0
        },
        theme: {
            type: String,
            default: 'light'
        },
        scrollTo: {
            validator: function(value) {
                return !(isNaN(value) && ['top', 'middle', 'bottom'].indexOf(value) == -1);
            }
        },
        autoUpdate: {
            type: Boolean,
            default: true
        },
        swipeEasing: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            Ps: null, //滚动条实例
            options: [
                'wheelSpeed',
                'swipeEasing',
                'wheelPropagation',
                'swipePropagation',
                'minScrollbarLength',
                'maxScrollbarLength',
                'useBothWheelAxes',
                'suppressScrollX',
                'suppressScrollY',
                'scrollXMarginOffset',
                'scrollYMarginOffset',
                'theme'
            ],
            events: [
                {
                    eventName: 'ps-scroll-y',
                    eventFunc: 'onScrollY'
                },
                {
                    eventName: 'ps-scroll-x',
                    eventFunc: 'onScrollX'
                },
                {
                    eventName: 'ps-scroll-up',
                    eventFunc: 'onScrollUp'
                },
                {
                    eventName: 'ps-scroll-down',
                    eventFunc: 'onScrollDown'
                },
                {
                    eventName: 'ps-scroll-left',
                    eventFunc: 'onScrollLeft'
                },
                {
                    eventName: 'ps-scroll-right',
                    eventFunc: 'onScrollRight'
                },
                {
                    eventName: 'ps-y-reach-start',
                    eventFunc: 'onReachStartY'
                },
                {
                    eventName: 'ps-y-reach-end',
                    eventFunc: 'onReachEndY'
                },
                {
                    eventName: 'ps-x-reach-start',
                    eventFunc: 'onReachStartX'
                },
                {
                    eventName: 'ps-x-reach-end',
                    eventFunc: 'onReachEndX'
                }
            ],
            autoUpdateTimeoutRange: 60,
            size: null,
            autoUpdateTimeout: null,
            currentScrollTop: 0
        };
    },

    mounted() {
        if (!this.disabled) this.init();
    },

    beforeDestroy() {
        this.destroy();
    },

    methods: {
        init() {
            this.setHeight();
            this.scrollToPos();
            this.initialize();
            this.setAutoUpdate();
            this.addEvent();
        },

        setHeight() {
            let parentNode = this.$el.parentNode;
            if (parentNode) {
                let maxHeight = parseFloat(getComputedStyle(parentNode).maxHeight);
                let contentHeight = this.$el.scrollHeight;

                if (!Number.isNaN(maxHeight) && contentHeight <= maxHeight) {
                    this.$el.removeAttribute('style');
                } else {
                    let offsetHeight = parentNode.offsetHeight;
                    let scrollHeight = parentNode.scrollHeight;
                    if (offsetHeight < scrollHeight) this.$el.style.height = offsetHeight - 40 + 'px';
                }
            }
        },

        reset() {
            this.destroy();
            this.initialize();
        },

        initialize() {
            this.Ps = new PerfectScrollbar(this.$el, this.getOptions());
        },

        destroy() {
            if (!this.disabled && this.Ps) {
                this.clearAutoUpdate();
                this.Ps.destroy(this.$el);
            }
        },

        update() {
            this.Ps.update(this.$el);
            this.setAutoUpdate();
        },

        scrollToPos(scrollTop) {
            if (isNaN(scrollTop)) {
                let scrollHeight = this.$el.scrollHeight;
                let clientHeight = this.$el.clientHeight;

                switch (scrollTop) {
                    case 'top':
                        scrollTop = 0;
                        break;
                    case 'middle':
                        scrollTop = scrollHeight > clientHeight ? (scrollHeight - clientHeight) / 2 : 0;
                        break;
                    case 'bottom':
                        scrollTop = scrollHeight;
                        break;
                }
            }
            this.$el.scrollTop = scrollTop;
        },

        getOptions() {
            let currentOptions = {};
            // currentOptions['handlers'] = this.disabled ? ['selection'] : ['click-rail', 'drag-scrollbar', 'keyboard', 'wheel', 'touch'];
            this.options.forEach(option => {
                currentOptions[option] = this[option];
            });
            return currentOptions;
        },
        resetHeight() {
            let currentSize = this.getDomSize();
            if (currentSize !== this.size) {
                this.size = currentSize;
                this.setHeight();
                this.update();
                return true;
            }
            return false;
        },
        setAutoUpdate() {
            if (this.autoUpdate) {
                this.autoUpdateTimeout = setTimeout(() => {
                    let currentSize = this.getDomSize();

                    if (currentSize !== this.size) {
                        this.size = currentSize;
                        this.clearAutoUpdate();
                        this.setHeight();
                        this.update();
                        return;
                    }

                    this.setAutoUpdate();
                }, this.autoUpdateTimeoutRange);
            }
        },

        clearAutoUpdate() {
            clearTimeout(this.autoUpdateTimeout);
        },

        getDomSize() {
            return this.$el.scrollHeight + this.$el.scrollWidth + this.$el.offsetHeight + this.$el.offsetWidth;
        },

        addEvent() {
            this.events.forEach(event => {
                this.$el.addEventListener(event.eventName, this[event.eventFunc]);
            });
        },

        //获取当前滚动条位置
        getScrollTop() {
            return typeof this.$el.scrollTop !== 'undefined' ? this.$el.scrollTop : 0;
        },

        //获取当前滚动条位置
        getScrollLeft() {
            return typeof this.$el.scrollLeft !== 'undefined' ? this.$el.scrollLeft : 0;
        },

        onScrollY() {
            let scrollTop = this.$el.scrollTop;
            if (scrollTop != this.currentScrollTop) {
                this.currentScrollTop = scrollTop;
                this.$emit('onScrollY', scrollTop);
            }
        },

        onScrollX() {
            this.$el.querySelector('.ps__thumb-x').style.opacity = 0.6;
            setTimeout(
                function() {
                    this.querySelector('.ps__thumb-x').style.opacity = 0;
                }.bind(this.$el),
                800
            );
            this.$emit('onScrollX');
        },

        onScrollUp() {
            this.$emit('onScrollUp');
        },

        onScrollDown() {
            this.$emit('onScrollDown');
        },

        onScrollLeft() {
            this.$emit('onScrollLeft');
        },

        onScrollRight() {
            this.$emit('onScrollRight');
        },

        onReachStartY() {
            this.$emit('onReachStartY');
        },

        onReachEndY() {
            this.$emit('onReachEndY');
        },

        onReachStartX() {
            this.$emit('onReachStartX');
        },

        onReachEndX() {
            this.$emit('onReachEndX');
        }
    },

    watch: {
        disabled() {
            this.reset();
        },

        suppressScrollX() {
            this.reset();
        },

        suppressScrollY() {
            this.reset();
        },

        scrollTo(newVal) {
            this.scrollToPos(newVal);
            this.update();
        }
    }
};
</script>

<style lang="scss">
@import '@/styles/import.scss';
@import './css/main.scss';

.scrollbar__holder {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0px auto;
    padding: 0px;

    &:not(.isDisabled) {
        overflow: hidden;
    }
}

.ps-theme-light {
    @include ps-container(
        map_merge(
            $ps-theme-default,
            (
                rail-default-opacity: 0.4
            )
        )
    );
}
.ps-theme-vx {
    @include ps-container(
        map_merge(
            $ps-theme-default,
            (
                rail-default-opacity: 0.4 !important
            )
        )
    );
}
.ps-theme-drak {
    @include ps-container($ps-theme-default);
}
.ps-theme-hide {
    @include ps-container(
        map_merge(
            $ps-theme-default,
            (
                rail-default-opacity: 0
            )
        )
    );
}
</style>
