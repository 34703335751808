<template>
    <div
        v-pt-clickoutside="handleClickoutside"
        :class="[
            prefix ? 'has-prefix' : '',
            hasContent ? 'has-content' : '',
            type === 'textarea' ? 'pt-textarea' : 'pt-input',
            size ? 'pt-input--' + size : '',
            invalid ? 'warning' : '',
            isFocus ? 'focus' : '',
            'pt-input_' + inputStyle,
            color ? 'pt-input_' + color : '',
            {
                'is-disabled': disabled
            }
        ]"
    >
        <span class="pt-input__prefix" v-if="prefix && prefixIcon" @click.stop="togglePrefix">
            <pt-icon v-if="prefix && prefixIcon" :icon="prefixIcon"></pt-icon>
        </span>

        <!-- TODO: v-show="inputStyle === 'underline' ? prefixClicked || hasContent : true" -->
        <input
            v-autofocus="autofocus"
            v-if="type !== 'textarea'"
            class="pt-input__inner"
            :class="{
                hasContent: hasContent,
                'pt-input__inner--clearButton': clearButton
            }"
            :type="type"
            :name="name"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :maxlength="maxlength"
            :minlength="minlength"
            :autocomplete="autoComplete"
            :min="min"
            :max="max"
            :value="currentValue"
            ref="input"
            @keydown="handleEnter"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
        />

        <div v-else class="pt-textarea__container_parent">
            <div class="pt-textarea__container" ref="textarea__container">
                <textarea
                    v-autofocus="autofocus"
                    class="pt-textarea__inner"
                    :class="{ hasContent: hasContent }"
                    :value="currentValue"
                    ref="textarea"
                    :name="name"
                    :rows="rows"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :readonly="readonly"
                    :maxlength="maxlength"
                    :minlength="minlength"
                    @input="handleInput"
                    @focus="handleFocus"
                    @blur="handleBlur"
                ></textarea>
            </div>
        </div>

        <span class="pt-input__inner__clearButton" v-if="clearButton && currentValue" @click="handleClearButtonClick">
            <pt-icon icon="pt-icon--clear"></pt-icon>
        </span>
        <p :class="['pt-input__warning', errMsgPosition ? `position-${errMsgPosition}` : '']">
            <span v-html="invalidTip"></span>
        </p>

        <span class="pt-input__suffix" v-if="showWordLimit">
            <span class="pt-input__suffix-inner">
                <span class="el-input__count">
                    <span class="el-input__count-inner">{{ `${currentValue.length}/${maxlength}` }}</span>
                </span>
            </span>
        </span>
    </div>
</template>

<script>
import ptIcon from '../../icon';
export default {
    name: 'ptInput',
    data() {
        return {
            currentValue: this.value,
            hasContent: Boolean(this.value),
            last: 0,
            isFocus: false,
            invalid: this.warning,
            prefixClicked: false,
            invalidTip: this.warningText,
            clickPrefix: false
        };
    },
    directives: {
        focus: {
            inserted: function(el, { value }) {
                if (value) {
                    el.focus();
                }
            }
        }
    },
    props: {
        color: {
            type: String,
            enum: ['green']
        },
        isWatchInput: {
            type: Boolean,
            default: true
        },
        value: [String, Number],
        placeholder: String,
        size: {
            type: String,
            default: 'default',
            enum: ['default', 'big', 'large', 'xlarge']
        },
        readonly: Boolean,
        autofocus: {
            type: Boolean,
            default: false
        },
        disabled: Boolean,
        type: {
            type: String,
            default: 'text'
        },
        inputStyle: {
            type: String,
            default: 'border',
            enum: ['border', 'underline', 'line']
        }, //有边框
        name: String,
        rows: {
            type: Number,
            default: () => 2
        },
        autoComplete: {
            type: String,
            default: 'new-password'
        },
        maxlength: Number,
        minlength: Number,
        showWordLimit: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        max: {},
        min: {},
        warning: Boolean, //错误样式
        warningText: String, // 错误提示
        prefix: {
            type: Boolean,
            default: false
        },
        //  prefixText: String,
        prefixIcon: String,
        clearButton: {
            type: Boolean | Function,
            default: false
        },
        rules: {
            type: Array
        },
        enableEnter: {
            type: Boolean,
            default: false
        },
        errMsgPosition: {
            type: String,
            default: 'bottom'
        },
        trimSpace: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(val, oldValue) {
            this.setCurrentValue(val, 'watch');
        },
        warning(val) {
            this.invalid = val;
        },
        warningText(val) {
            this.invalidTip = val;
        }
    },
    methods: {
        focus() {
            this.clearError();
            (this.$refs.input || this.$refs.textarea).focus();
        },
        blur() {
            (this.$refs.input || this.$refs.textarea).blur();
        },
        updateHeight() {
            this.checkText();
            // this.$refs.textarea__scroll.style.height = this.$refs.textarea.offsetHeight + 'px';
            this.$refs.textarea__container.style.height = this.$refs.textarea.offsetHeight + 'px';
        },
        handleClearButtonClick() {
            const isFunction = function(functionToCheck) {
                return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
            };
            if (isFunction(this.clearButton)) {
                this.clearButton();
            }
            this.setCurrentValue('', 'input');
        },
        checkText() {
            if (this.type === 'textarea') {
                var textarea = this.$refs.textarea;
                // if (textarea && textarea.clientHeight < textarea.scrollHeight) {
                //     this.$refs.textarea__scroll.style.display = 'block';
                // } else {
                //     this.$refs.textarea__scroll.style.display = 'none';
                // }
            }
        },

        handleEnter(event) {
            if ((window.event ? event.keyCode : event.which) === 13) {
                event.target.blur();
                this.$emit('enter', event);
            }
        },
        handleClickoutside() {
            this.$nextTick(() => {
                this.clickPrefix = false;
                if (!this.clickPrefix) {
                    this.prefixClicked = false;
                }
            });
        },
        togglePrefix(event) {
            this.prefixClicked = !this.prefixClicked;
            this.clickPrefix = true;
            this.$nextTick(() => {
                if (this.prefixClicked) {
                    this.handleFocus(event);
                }
            });
        },
        handleFocus(event) {
            this.invalid = false;
            this.invalidTip = '';
            this.isFocus = true;
            this.checkHasConten(event.target.value, 'focus');
            if (this.type === 'text' && this.prefix && this.prefixIcon) {
                // console.log('handleFocus');
                this.$refs.input.focus();
            }
            this.$emit('focus', event);
        },

        handleInput(event) {
            this.setCurrentValue(event.target.value, 'input');
            this.$nextTick(() => {
                this.checkText();
            });
        },

        handleBlur(event) {
            this.isFocus = false;
            this.checkHasConten(event.target.value, 'blur');
            this.$emit('blur', event);
        },

        checkHasConten(value, type) {
            this.hasContent = !(type === 'focus' || !value);
        },

        setCurrentValue(value, type) {
            if (value === this.currentValue) return;
            // if (type === 'watch') this.checkHasConten(value, type)
            this.currentValue = value;
            this.checkHasConten(value, type);
            this.$emit('input', this.trimSpace && typeof value === 'string' ? value?.trim() : value);
            this.$emit('change', this.trimSpace && typeof value === 'string' ? value?.trim() : value);
        },

        // 暴露给外部的可以使pt-input获得焦点
        focus() {
            if (this.type === 'textarea') {
                this.$refs.textarea.focus();
            } else {
                this.$refs.input.focus();
            }
        },
        showError(msg) {
            this.invalid = true;
            this.invalidTip = msg;
        },
        clearError() {
            this.invalid = false;
            this.invalidTip = '';
        }
    },
    components: {
        ptIcon
    }
};
</script>

<style lang="scss">
@import './input.scss';
</style>
