<template>
    <div :class="['js_butterBar', $style.butterbar, packageUsageRate >= 100 && $style.warning]" v-if="visibleBar">
        <div class="x-wrap" :class="$style.content" v-if="noActivation">
            <p :class="$style.desc">{{ $t('data_settings.install_check_status_warn') }}</p>
            <pt-button :class="$style.btn" color="orange" size="small" @click="handleInstall">{{
                $t('data_settings.button_install')
            }}</pt-button>
        </div>
        <div class="x-wrap" :class="$style.content" v-if="packageExcess">
            <p :class="$style.desc">
                <span v-if="packageUsageRate >= 100">
                    {{ $t('plan.uu_excess', { used: (packageInfo.privilege || {}).monthlyActiveUsers }) }}
                </span>
                <span v-else>
                    {{
                        $t('plan.uu_warning', {
                            used: (packageInfo.usage || {}).monthlyActiveUsers,
                            total: (packageInfo.privilege || {}).monthlyActiveUsers
                        })
                    }}
                </span>
            </p>
            <pt-button :class="[$style.btn, 'js_contact']" color="orange" size="small" href @click="contactUs">{{
                $t('plan.get_higher_limits')
            }}</pt-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import intercomSerice from '@/common/services/intercom.service';
import { isSingleSpa } from '@/common/services/singleSpa.service';

export default {
    name: 'ButterBar',

    computed: {
        ...mapGetters('main', ['profileInfo', 'packageInfo', 'hideMainMenu']),

        visibleBar() {
            return !isSingleSpa() && (this.noActivation || this.packageExcess);
        },

        // 档案未激活
        noActivation() {
            return !this.profileInfo.isActived && !this.hideMainMenu && this.$route.name !== 'Setup';
        },

        // 套餐UU使用比例数
        packageUsageRate() {
            const { usage, privilege } = this.packageInfo;
            const UURate = (usage?.monthlyActiveUsers || 0) / (privilege?.monthlyActiveUsers || 0);
            return Number.isNaN(UURate) ? 0 : Number.parseInt(UURate * 100);
        },

        //
        packageExcess() {
            return this.packageUsageRate > 80;
        }
    },

    methods: {
        showAuthDialog(type = 'CREATE_CAMPAIGN') {
            this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: type });
        },

        handleInstall() {
            this.$router.push({ name: 'Setup' });
        },

        contactUs() {
            intercomSerice?.contact();
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.butterbar {
    min-width: $page-min-width;
    border: 1px solid $pt-warning-hover;
    border-left: none;
    border-right: none;
    background-color: rgba(250, 97, 0, 0.2);

    &.warning {
        border: 1px solid $pt-danger;
        background-color: rgba(239, 83, 80, 0.2);
    }

    .content {
        color: $pt-black-600;
        font-size: 14px;
        letter-spacing: 0;
        padding: 8px 0;
        @extend %flex-center;
    }

    .desc {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .btn {
        margin-left: 56px;
    }
}
</style>
