<template>
    <el-radio-group 
        size="small"
        v-model="currentTab" 
        @change="changeTab">
        <el-radio-button 
            v-for="btn in tabOptions"
            :key="btn.code"
            :label="btn.code">
            {{btn.name}}
        </el-radio-button>
    </el-radio-group>
</template>

<script>
import { tabOptions } from '../configs/creative.config';
export default {
    name: 'SettingsTab',
    
    props: {
        tab: {
            type: String,
            default: function() {
                return tabOptions.find((item) => item.default).code;
            }
        },
        tabOptions: {
            type: Array,
            default: function() {
                return [...tabOptions];
            }
        }
    },

    data() {
        return {
            currentTab: this.tab
        };
    },

    methods: {
        changeTab(tab) {
            this.$emit('change', tab);
        }
    },

    watch: {
        tab: function(newVal) {
            this.currentTab = newVal;
        }
    }
};
</script>
