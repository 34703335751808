"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEventBus = void 0;
var composition_api_1 = require("@vue/composition-api");
// 与vue3 router保持一致
// 返回的是reactive对象，注意别解构使用，会丢失响应性，要解构使用得自行computed.
function useEventBus() {
    var proxy = (0, composition_api_1.getCurrentInstance)().proxy;
    return proxy.$eventBus;
}
exports.useEventBus = useEventBus;
