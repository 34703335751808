import store from '@/store';
import { getNewPteUserInfo } from '@/common/services/singleSpa.service';

/**
 * 判断是否拥有权限
 *
 * @param {Array<string|number>} permissions - 要判断的权限列表
 */
const includePermissionWithStore = function(permissions: Array<string> = []): boolean {
    // 账户级别的权限
    const { permissionList, permissionsDict } = store?.state?.main || { permissionList: [], permissionsDict: [] };

    // 档案级别的权限
    const { permissions: prefilePremissions } = getNewPteUserInfo() || {};
    const { permissionIdList, permissionDict } = prefilePremissions || {};

    const dictList = [...(permissionsDict || []), ...(permissionDict || [])];
    const idList: string[] = [...(permissionList || []), ...(permissionIdList || [])];

    if (!permissions.length) return true;
    return permissions.some(id => {
        const pInfo = dictList.find(item => item.id === id || item.name === id);
        if (pInfo) {
            /**
             * status = [-1,1,0];
             * 1: 完全可用
             * 0: 部分可用
             * -1: 不可用
             */
            const status: number = pInfo.status;
            if (status === 1) {
                return true;
            } else if (status === -1) {
                return false;
            } else {
                return idList.includes(pInfo.id);
            }
        }
        return true;
    });
};

export default {
    includePermissionWithStore
};
