"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var base_config_1 = require("@/common/configs/base.config");
var singleSpa_service_1 = require("@/common/services/singleSpa.service");
var hooks_1 = require("@/hooks");
var cookie_utils_1 = require("@/common/utils/cookie.utils");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'PtxLoading',
    setup: function () {
        var eventBus = (0, hooks_1.useEventBus)();
        var router = (0, hooks_1.useRouter)();
        (0, composition_api_1.onMounted)(function () {
            if ((0, singleSpa_service_1.isSingleSpa)())
                (0, singleSpa_service_1.initSingleSpa)();
            eventBus.$on('ExperienceStartCallBack', handleInitFinish, false);
        });
        (0, composition_api_1.onUnmounted)(function () {
            eventBus.$off('ExperienceStartCallBack', handleInitFinish, false);
        });
        function handleInitFinish(_a) {
            var token = _a.token, uid = _a.uid, path = _a.path, cookieInfo = _a.cookieInfo;
            if (!token || !uid) {
                (0, singleSpa_service_1.sendPostMessage)('ExperienceLogout');
                throw new Error("[Experience] init failed. token: ".concat(Boolean(token), ", uid: ").concat(uid));
            }
            localStorage.setItem(base_config_1.default.ACCOUNT_ID, uid);
            localStorage.setItem(base_config_1.default.ACCOUNT_TOKEN, token);
            if (cookieInfo) {
                Object.keys(cookieInfo).forEach(function (key) {
                    cookie_utils_1.default.setItem(key, cookieInfo[key]);
                });
            }
            router.push({ path: path });
        }
    }
});
