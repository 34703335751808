import i18n from '@/assets/i18n';
import dateUtils from '@/common/utils/date.utils';
import cloneUtils from '@/common/utils/clone.utils';
import commonUtils from '@common/utils/common.utils';
import permissionUtils from '@/common/utils/permission';
import permissionsConfig from '@/common/configs/permissions.config';
import settingConfig from '@/components/engagement/setting/setting.config';
import userGroupConfig from '@/components/users/usergrp-config';
import { metricConfig, conditionPatternList, overviewTableColumns } from './campaign.config';

import type { RemoteCampaign, CampaignData, CampaignDataGoal, Usergroup, Goal } from '@/common/types/CampaignTypes';
import type {ProfileInfo} from '@/common/types/ProfileInfoTypes';

export const getCampaignInfo = function(campaign: RemoteCampaign, profileInfo: ProfileInfo) {
    function getGoals() {
        if (!campaign) return '';
        return campaign?.goals.reduce((acc: string, cur: Goal, index: number, arr) => {
            acc += cur.name;
            arr.length - 1 !== index && (acc += '/');
            return acc;
        }, '') || '--';
    }

    function getUserGroups() {
        if (!campaign) return '';
        return campaign?.audiences.reduce((acc: string, cur: any, index: number, arr) => {
            acc += cur.name;
            arr.length - 1 !== index && (acc += '/');
            return acc;
        }, '');
    }

    function getPageConditions() {
        if (!campaign) return '';

        const when = campaign?.conditions[0]?.when;
        if (!when || when.length === 0 || when[0].type === 'ALL') {
            return i18n.t('campaign_settings.all_site');
        } else {
            let whenResult = '';
            when.forEach(item => {
                whenResult += conditionPatternList?.[item?.condition] + ' ';
                whenResult += Array.isArray(item.value) ? item.value.join(', ') : item.value;
                whenResult += '\n\n';
            });
            return whenResult;
        }
    }

    function getV2PageConditions() {
        let conditionsString: any = '';
        campaign.versions.forEach((version, index) => {
            if (campaign.abtestType !== 'start' && index > 0) return;
            
            const displayConfig: any = version.displayConfig;
            if (displayConfig.when.length > 0 && displayConfig.when[0]) {
                const { condition } = displayConfig.when[0];
                if (displayConfig.when[0].type === 'ALL') {
                    conditionsString += i18n.t('engagement.trigger_when_all');
                } else {
                    let conditionText = (settingConfig as any).entryPatternList.find((item: any) => item.value === condition).text;
                    let triggerPageText = displayConfig.when
                        .map((item: any) => item.value)
                        .join(' ');
                    conditionsString += `${conditionText} ${triggerPageText}\n\n`;
                }
            }
        });
        return conditionsString;
    }

    function getSessionConditions() {
        if (!campaign) return '';
        let sessionStringList = [];
        const session = campaign?.conditions[0]?.session;
        const sessionChildren = session?.children;
        if (!sessionChildren || sessionChildren.length === 0) {
            sessionStringList.push(i18n.t('campaign_settings.all_session'));
        } else {
            const audienceValueKey = Object.keys(userGroupConfig.audienceValue);
            sessionStringList = sessionChildren.map((filter: any, index: number) => {
                const { name, condition, value, type, patternType } = filter;
                const conditionText = getConditionText(filter.condition, filter.patternType) ?? '';
                if (type === 'eventName')
                    return i18n.t('engagement.show_when_session_event', {
                        event: value && value.join(', ')
                    });
                else if (type === 'visitPage')
                    return i18n.t('engagement.show_when_session_visitpage', {
                        condition: conditionText,
                        value: '\n' + value?.join('\n')
                    });
                else if (audienceValueKey.includes(type)) {
                    const valueNameList =
                        value &&
                        (userGroupConfig as any).audienceValue[type] &&
                        value.map((v: any) => {
                            const valueItem = (userGroupConfig as any).audienceValue[type].find(
                                (item: any) => item.code === v
                            );
                            return valueItem.name;
                        });
                    return i18n.t('engagement.show_when_session_visitproperty', {
                        type: name,
                        condition: conditionText,
                        value: valueNameList && valueNameList.join(', ')
                    });
                } else {
                    return i18n.t('engagement.show_when_session_visitproperty', {
                        type: name,
                        condition: conditionText,
                        value:
                            (name === 'Entry Page' || name === 'URL Source' || name === 'Source Host' ? '\n' : '') +
                            value?.join(
                                name === 'Entry Page' || name === 'URL Source' || name === 'Source Host'
                                    ? '\n'
                                    : ', '
                            )
                    });
                }
            });
        }
        return sessionStringList.join('/n')
    }

    function getV2SessionConditions() {
        let sessionString: any = '';
        campaign.versions.forEach((version, index) => {
            if (campaign.abtestType !== 'start' && index > 0) return;
            
            const displayConfig: any = version.displayConfig;
            if (displayConfig.session) {
                let textArr:any = [];
                let audienceValueKey = Object.keys(userGroupConfig.audienceValue);
                displayConfig.session.forEach((item: any) => {
                    const { value } = item;
                    const { children } = value;
                    let propertyText = '';
                    children.forEach((filter: any, index: number) => {
                        propertyText += index === 0 ? '' : i18n.t('common.separator');
                        const { name, value, type } = filter;
                        const conditionText = getConditionText(filter.condition, filter.patternType) || '';
                        if (type === 'eventName') {
                            propertyText +=
                                '<span class="condition">' +
                                i18n.t('engagement.show_when_session_event', {
                                    event: value && value.join(', ')
                                });
                            ('</span>');
                        } else if (type === 'visitPage') {
                            propertyText +=
                                '<span class="condition">' +
                                i18n.t('engagement.show_when_session_visitpage', {
                                    condition: conditionText,
                                    value: value && value.join(', ')
                                }) +
                                '</span>';
                        } else if (audienceValueKey.includes(type)) {
                            const valueNameList =
                                value &&
                                (userGroupConfig as any).audienceValue[type] &&
                                value.map((v: any) => {
                                    const valueItem = (userGroupConfig as any).audienceValue[type].find(
                                        (item: any) => item.code === v
                                    );
                                    return valueItem.name;
                                });

                            propertyText +=
                                '<span class="condition">' +
                                i18n.t('engagement.show_when_session_visitproperty', {
                                    type: name,
                                    condition: conditionText,
                                    value: valueNameList && valueNameList.join(', ')
                                }) +
                                '</span>';
                        } else {
                            propertyText +=
                                '<span class="condition">' +
                                i18n.t('engagement.show_when_session_visitproperty', {
                                    type: name,
                                    condition: conditionText,
                                    value: value && value.join(', ')
                                }) +
                                '</span>';
                        }
                    });
                    textArr.push(propertyText);
                });
                if (textArr.length > 0) {
                    sessionString += textArr.join('and');
                }
            }
        });
        return sessionString || i18n.t('campaign_settings.all_session');
    }

    function getConditionText(value: any, patternType = 'STRING'): string | undefined {
        const conditionText = (userGroupConfig as any).audienceCondition[patternType.toLowerCase()].find(
            (item: any) => item.code === value
        );
        if (conditionText) {
            return conditionText.name;
        }
    }

    function getSchedule() {
        if (!campaign) return '';

        const schedule: any = campaign?.schedule[0];
        if (!schedule) return '';
        let start_text = '',
            end_text = '',
            week_text = '',
            time_text = '',
            time_zone = '';

        const { versionMeta } = schedule;
        // start date
        if (versionMeta.start == '1') {
            start_text = `<span>${i18n.t('engagement.start_showing_immediately')}</span>`;
        } else {
            const startDate = versionMeta.startDate && commonUtils.getFullDate(versionMeta.startDate);
            start_text = `${i18n.t('engagement.run_from')} <span> ${startDate} </span>${i18n.t(
                'engagement.run_from2'
            )}${i18n.t('engagement.comma')}`;
        }

        // end date
        if (versionMeta.stop === '1') {
            end_text = `<span>${i18n.t('engagement.stop_showing_never')}</span>`;
        } else {
            const endDate = versionMeta.stopDate && commonUtils.getFullDate(versionMeta.stopDate);
            end_text = `${i18n.t('engagement.date_to')} <span> ${endDate}</span>${i18n.t(
                'engagement.date_to2'
            )}`;
        }

        // week and time
        if (versionMeta.delivery !== '1') {
            let weeks = versionMeta.week.filter((item: any) => item.status);
            if (weeks.length == 7) {
                week_text = ` ${i18n.t('engagement.comma')}<span>${i18n.t(
                    'engagement.every_monday_to_sunday'
                )}</span> `;
            } else if (weeks.length == 0) {
            } else {
                let weekText = versionMeta.week.reduce((acc: string[], cur: any) => {
                    cur.status && acc.push(`<span>${i18n.t(`common.week_${cur.code}_short`)}</span>`);
                    return acc;
                }, []);
                week_text = ` ${i18n.t('engagement.comma')}${i18n.t(
                    'engagement.every'
                )} ${weekText.join(',')} `;
            }
            console.log('update:', schedule.timezone.useVisitor);
            time_text = `${i18n.t('engagement.from')} <span>${versionMeta.time.join(
                ` ${i18n.t('engagement.to')} `
            )}</span> `;
        }
        time_zone = `${
            schedule.timezone.useVisitor
                ? `(${i18n.t('engagement.visitors_timezone')})`
                : `(GTM ${profileInfo.timeZone})`
        }`;
        return '<span>' + start_text + end_text + week_text + time_text + time_zone + '</span>';
    }

    function getV2Schedule() {
        const displayConfig: any = campaign?.versions?.[0]?.displayConfig
        const versionMeta = displayConfig?.meta?.schedule || {};
        let start_text = '',
            end_text = '',
            week_text = '',
            time_text = '';

        // start date
        if (versionMeta.radio1 == '1') {
            start_text = `<span>${i18n.t('engagement.start_showing_immediately')}</span>`;
        } else {
            const startDate =
                versionMeta.startDate && commonUtils.getFullDate(versionMeta.startDate);
            start_text = `${i18n.t('engagement.run_from')} <span> ${startDate} </span>${i18n.t(
                'engagement.run_from2'
            )}${i18n.t('engagement.comma')}`;
        }

        // end date
        if (versionMeta.radio2 === '1') {
            end_text = `<span>${i18n.t('engagement.stop_showing_never')}</span>`;
        } else {
            const endDate =
                versionMeta.endDate && commonUtils.getFullDate(versionMeta.endDate);
            end_text = `${i18n.t('engagement.date_to')} <span> ${endDate}</span>${i18n.t('engagement.date_to2')}`;
        }

        // week and time
        if (versionMeta.radio3 !== '1') {
            let weeks = versionMeta.week.filter((item: any) => item.status);
            if (weeks.length == 7) {
                week_text = ` ${i18n.t('engagement.comma')}<span>${i18n.t(
                    'engagement.every_monday_to_sunday'
                )}</span> `;
            } else if (weeks.length !== 0) {
                let weekText = versionMeta.week.reduce((acc: any, cur: any) => {
                    cur.status && acc.push(`<span>${cur.text}</span>`);
                    return acc;
                }, []);
                week_text = ` ${i18n.t('engagement.comma')}${i18n.t('engagement.every')} ${weekText.join(',')} `;
            }

            time_text = `${i18n.t('engagement.from')} <span>${versionMeta.time.join(
                ` ${i18n.t('engagement.to')} `
            )}</span> ${
                displayConfig?.meta?.timezone.useVisitor
                    ? `(${i18n.t('engagement.visitors_timezone')})`
                    : `(GTM ${displayConfig.meta.timezone.profile})`
            }`;
        }
        return start_text + end_text + week_text + time_text;
    }

    const campaignIsV3 = campaign.engageVersion === '3.0';
    console.log('campaignIsV3', campaignIsV3)
    return [
        {
            name: i18n.t('campaign_settings.campaign_goals'),
            code: 'goal',
            htmlString: getGoals()
        },
        {
            name: i18n.t('campaign_settings.target_users'),
            code: 'userGroups',
            htmlString: getUserGroups()
        },
        {
            name: i18n.t('campaign_settings.session_conditions'),
            code: 'sessionConditions',
            htmlString: campaignIsV3 ? getSessionConditions() : getV2SessionConditions()
        },
        {
            name: i18n.t('campaign_settings.page_conditions'),
            code: 'pageConditions',
            htmlString: campaignIsV3 ? getPageConditions() : getV2PageConditions()
        },
        {
            name: i18n.t('campaign_settings.schedule'),
            htmlString: campaignIsV3 ? getSchedule() : getV2Schedule()
        }
    ]
}

export const formatCampaignData = function(campaignData: any, hasForm: boolean = true){
    const types: Array<string> = Object.keys(metricConfig);
    const result: any = {};
    types.forEach((type: string): void => {
        const typeData = campaignData[type];
        const columnConfig = cloneUtils.deep((metricConfig as any)[type] ?? []).filter((item: any) => {
            return item.columnKey !== 'engageSubmit' || hasForm
        });

        columnConfig.forEach((item: any, index: number) => {
            let trendType = 'value';
            const {columnKey, dataType, mainView} = item;
            const { current, last } = typeData?.[columnKey] || {};

            if (columnKey === 'engageGoal') {
                const goalData = typeData?.engageGoal || [];
                let value: any = {};
                let pastvalue: any = {};
                let rate: any = {};
                let pastrate: any = {};
                let trend: any = {};

                goalData.forEach((goal: CampaignDataGoal) => {
                    const { current, last, id } = goal;
                    value[id] = current.count ?? 0;
                    pastvalue[id] = last?.count ?? 0;
                    rate[id] = current?.rate ?? 0;
                    pastrate[id] = last?.rate ?? 0;
                    if (rate[id] > pastrate[id]) {
                        trend[id] = '+';
                    } else if (rate[id] < pastrate[id]) {
                        trend[id] = '-';
                    } else {
                        trend[id] = '--';
                    }
                });
                Object.assign(item, {
                    value,
                    pastvalue,
                    rate,
                    pastrate,
                    trend
                });
            } else {
                Object.assign(item, {
                    value: getValue(current?.count ?? 0, dataType),
                    pastvalue: getValue(last?.count ?? 0, dataType)
                });
                mainView === 'rate' && (
                    Object.assign(item, {
                        rate: current?.rate ?? 0,
                        pastrate: last?.rate ?? 0
                    }),
                    trendType = 'rate'
                )
                item.trend = getTrend(item, trendType);
            }
        })
        result[type] = columnConfig;
    })
    return result;

    function getTrend(target: any, type = 'rate', versionId?: string) {
        const cur = target[type] || 0;
        const pas = target['past' + type] || 0;
        if (versionId) {
            if (pas[versionId] === cur[versionId]) return '--';
            else return pas[versionId] > cur[versionId] ? '-' : '+';
        }
        if (pas === cur) return '--';
        else return pas > cur ? '-' : '+';
    }

    function getValue(value: any, dataType: string){
        return dataType === 'timestamp' ? dateUtils.getDuration(value) : typeof value === 'number' ? Math.floor(value * 100) / 100 : value;
    }
}

export const formatCampaignInsightData = function(campaignData: any, metricList: Array<any>){
    const result: any = [];
    const columnConfig = cloneUtils.deep(metricList) as any;
    return columnConfig.map((column:any):void=>{
        const {userCount} = campaignData;
        const { current, last } = userCount?.[column.funName] || {};
        let trendType = 'value';
        if(column.funName !== 'engageGoal'){
            Object.assign(column, {
                value: getValue(current?.count ?? 0, column.dataType),
                pastvalue: getValue(last?.count ?? 0, column.dataType)
            });
            column.columnKey !== 'engageView' && (
                Object.assign(column, {
                    rate: current?.rate ?? 0,
                    pastrate: last?.rate ?? 0
                }),
                trendType = 'rate'
            )
            column.trend = getTrend(column, trendType);
           
        }
        else if(userCount.engageGoal.length >0) {
            const goalData = userCount.engageGoal.find((goal: CampaignDataGoal)=>goal.id === column.columnKey.split('_')[1]) || {};
            
            const { current, last } = goalData;
            let value = current?.count ?? 0;
            let pastvalue = last?.count ?? 0;
            let rate = current?.rate ?? 0;
            let pastrate = last?.rate ?? 0;
            let trend: string = '';
            if (rate > pastrate) {
                trend = '+';
            } else if (rate < pastrate) {
                trend = '-';
            } else {
                trend = '--';
            }
            Object.assign(column, {
                value,
                pastvalue,
                rate,
                pastrate,
                trend,
                rateDiff: getPercentDiff(rate, pastrate, trend)
            });
        }
        if(column.mainView === 'value'){
            column.diff = getPercentDiff(column.value, column.pastvalue, column.trend);
        }
        else {
            column.diff = getPercentDiff(column.rate, column.pastrate, column.trend);
        }
        return column
    });
    function getTrend(target: any, type = 'rate', versionId?: string) {
        const cur = target[type] || 0;
        const pas = target['past' + type] || 0;
        if (versionId) {
            if (pas[versionId] === cur[versionId]) return '--';
            else return pas[versionId] > cur[versionId] ? '-' : '+';
        }
        if (pas === cur) return '--';
        else return pas > cur ? '-' : '+';
    }

    function getValue(value: any, dataType: string){
        return dataType === 'timestamp' ? dateUtils.getDuration(value) : value;
    }
    function getPercentDiff(current: number, past: number, trend: string) {
        if (trend === '--') return 0;
        else if (past === 0) {
            return 100;
        } else return ((Math.abs(current - past) / past) * 100).toFixed(2);
    }
}

export const getCampaignDataTrendTip = function(column: any, timeDiff: string, goalId: string) {
    const { columnKey, trend } = column;
    let tip = i18n.t('engagement.compare_tip', { text: timeDiff || '' });
    let compareValue: any = '';
    let compareRate: any = '';
    let trendString: any = trend === '--' ? '-' : trend;
    
    if (columnKey === 'engageView') {
        compareValue = Math.abs(column.value - column.pastvalue);
        compareRate = getPercentDiff(column.value, column.pastvalue, column.trend) + '%';
    } else if (columnKey === 'engageGoal') {
        compareValue = (Math.abs(column.rate[goalId] - column.pastrate[goalId]) * 100).toFixed(2) + '%';
        compareRate =
            getPercentDiff(
                column.rate[goalId],
                column.pastrate[goalId],
                column.trend[goalId]
            ) + '%';
            trendString = column.trend[goalId];
    } else {
        compareValue = (Math.abs(column.rate - column.pastrate) * 100).toFixed(2) + '%';
        compareRate = getPercentDiff(column.rate, column.pastrate, column.trend) + '%';
    }

    tip += trendString + compareValue + ' ( ' + trendString + compareRate + ')';
    return tip;

    function getPercentDiff(current: number, past: number, trend: string) {
        if (trend === '--') return 0;
        else if (past === 0) {
            return 100;
        } else return ((Math.abs(current - past) / past) * 100).toFixed(2);
    }
}

export const getCampaignReportRouteName = function(): 'CampaignReport' | 'EngagementReport' {
    const hasV2Permission = permissionUtils.includePermissionWithStore([permissionsConfig.campaignReportV2]);
    return hasV2Permission ? 'CampaignReport' : 'EngagementReport'
}