import inputConfig from './input.config';
function getInputDom(content, id) {
    // console.log('getInputDom', content);
    const { type, placeholder, lineNumber, dropdownOptions } = content;
    //TODO 根据不同的content type绘制不同的input组件
    if (type === 'longText') {
        return `<textarea class="engage-input" rows="${lineNumber}" data-field-type="${type}" placeholder="${placeholder}" ></textarea>`;
    } else if (type === 'dropdown') {
        //dropdown html
        let dropdownList = dropdownOptions.split(/\n/).map(item => {
            return '<li data-value="' + item + '">' + item + '</li>';
        });

        let dropdownListHtml = dropdownList.join('');
        return `<input class="engage-input" readonly type="text" placeholder="${placeholder}" autocomplete="off"/>
                <div id="${id}-suffix">
                    <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                    viewBox="0 0 8 4" enable-background="new 0 0 8 4" xml:space="preserve" style="width: 16px;">
                        <path d="M4,3.9c-0.4,0-0.8-0.2-1.1-0.5L0.4,1c-0.2-0.2-0.2-0.6,0-0.8C0.6,0,1,0,1.2,0.2l2.5,2.5c0.2,0.2,0.5,0.2,0.6,0l2.5-2.5
	C7,0,7.4,0,7.6,0.2c0.2,0.2,0.2,0.6,0,0.8L5.1,3.5C4.8,3.8,4.4,3.9,4,3.9z"/>
                    </svg>
                </div>
                <div id="${id}-dropdown">
                    <ul>
                       ${dropdownListHtml}
                    </ul>
                </div>`;
    }
    return `<input class="engage-input" type="text" placeholder="${placeholder}" autocomplete="off"/>`;
}
export default {
    getHtml(block) {
        //console.log('input.services.js');
        const id = block.blockId;
        const content = block.blockContent || [];
        const inputDom = getInputDom(content.value, id);
        return `<div id="${id}" data-engage-block="input">${inputDom}</div>`;
    },

    getStyle(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const settings = block.blockSettings || {};
        let {
            backgroundColor,
            height,
            borderColor,
            borderWidth,
            borderRadius,
            fontSize,
            fontColor,
            fontWeight,
            placeholderColor
        } = settings;
        const { type, lineNumber } = content.value;
        let totalHeight = height;
        let cursor = 'auto';
        if (type === 'longText') {
            totalHeight = lineNumber * height;
        }
        if (type === 'dropdown') {
            cursor = 'pointer';
        }
        return `
            #${id} {
                position: relative;
                cursor: ${cursor};
            }
            #${id}-suffix {
                display: flex;
                justify-content: center;
                position: absolute;
                right: 12px;
                top: 25%;
                width: 16px;
                height: 16px;
                fill: ${borderColor}
            }
            #${id}-dropdown {
                position: absolute;
                top: ${height + 4}px;
                width: 100%;
                box-shadow: 0 0 16px 0 #DFE1E6;
                background-color: #fff;
                padding: 12px 8px;
                display: none;
                z-index: 10;
                max-height: ${26 * 7 + 13}px;
                overflow-y: scroll;
            }
            #${id}-dropdown > ul {
                list-style: none;
                padding: 0;
            }
            #${id}-dropdown > ul > li {
                height: 26px;
                line-height: 26px;
                font-size: 13px;
                color: #5e6c84;
                border-radius: 4px;
                padding: 0 8px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            #${id}-dropdown > ul > li:hover {
                background-color: #f5f7fc;
            }
            #${id} .engage-input {
                    box-sizing : border-box;
                    height: ${totalHeight}px;
                    line-height: ${height}px;
                    background-color: ${backgroundColor};
                    border: ${borderWidth}px solid ${borderColor};
                    border-radius: ${borderRadius}px;
                    font-size: ${fontSize}px;
                    color: ${fontColor};
                    width: 100%;
                    font-weight: ${fontWeight};
                    text-align: left;
                    padding: 0 8px;
                    cursor: ${cursor};
                    resize: none;
                }
                #${id} .engage-input::-webkit-input-placeholder {
                    color: ${placeholderColor};
                    font-size: ${fontSize}px;
                }
                 #${id} .engage-input::-moz-placeholder {
                    color: ${placeholderColor};
                    font-size: ${fontSize}px;
                }
                 #${id} .engage-input::-ms-input-placeholder {
                    color: ${placeholderColor};
                    font-size: ${fontSize}px;
                }

            `;
    },

    getScript(block, widgetId, belong) {
        const id = block.blockId;
        const content = block.blockContent || [];
        // const settings = block.blockSettings || {};
        const { type } = content.value;
        const dropdownElParent = ['dialog'].includes(belong) ? '[data-preview-type="dialog"] ' : '';
        const ptInputConfig = {
            widgetId: widgetId,
            blockId: id,
            dropdownElParent: dropdownElParent,
            belong: belong
        };
        //TODO 事件绑定需要换一个高级的写法= =
        const mountedEvent = `function() {
            var self = this;
          
            self.dropdownUtils = {
                toggleDropdownMenu: function(e) {
                    
                    e.preventDefault();
                    var currentDisplay = dropdownListEl.style.display;
                    if(currentDisplay === 'block') {
                        dropdownListEl.style.display = 'none';
                    }
                    else {
                        dropdownListEl.style.display = 'block';
                    }
                },
                onSelectChange: function(e) {
                   
                    e.preventDefault();
                    e.stopPropagation();

                    var target = e.target.getAttribute('data-value');
                    dropdownInputEl.value = target;
                    dropdownListEl.style.display = 'none';
                }
            };
            
            var dropdownWrapper = config.dropdownElParent && document.querySelector(config.dropdownElParent + '') || document;
            
            var dropdownEl = document.querySelector(config.dropdownElParent + '#' + config.blockId);
         
            var dropdownInputEl = dropdownWrapper.querySelector('#${id} .engage-input');
            if(dropdownEl && '${type}' === 'dropdown') {
                var dropdownListEl = dropdownWrapper.querySelector('#${id}-dropdown');
                dropdownEl && dropdownEl.addEventListener('click', self.dropdownUtils.toggleDropdownMenu, true);
                dropdownListEl && dropdownListEl.addEventListener('click', self.dropdownUtils.onSelectChange, true);
            }
            
            
        }`;
        const unmountedEvent = `function(){
            var self = this;
             var dropdownWrapper = config.dropdownElParent && document.querySelector(config.dropdownElParent + '') || document;

            var dropdownEl = document.querySelector(config.dropdownElParent + '#' + config.blockId);
           
            var dropdownInputEl = dropdownWrapper.querySelector('#${id} .engage-input'); 
            if(dropdownEl && ${type === 'dropdown'} && ${belong === 'normal'}){
                var dropdownListEl = dropdownWrapper.querySelector('#${id}-dropdown');
                
                dropdownEl && dropdownEl.removeEventListener('click', self.dropdownUtils.toggleDropdownMenu);
                dropdownListEl && dropdownListEl.removeEventListener('click', self.dropdownUtils.onSelectChange, true);
            }
            
            window.ptEngage[config.widgetId] = [];
            

        }`;
        const updatedEvent = `function(){
           
            return
        }`;
        return `<script type="text/javascript">
            /** this is for dropdown js code here start*/
            ;(function(config) {
                window.ptEngage = window.ptEngage || {};
                window.ptEngage[config.widgetId] = window.ptEngage[config.widgetId] || [];

                var index = -1;
                var i = 0;
                for(i=0; i< window.ptEngage[config.widgetId].length; i++){
                    var item = window.ptEngage[config.widgetId][i];
                    if(item.blockId === config.blockId) {
                        index = i;
                        return
                    }
                } 
                index !== -1 && window.ptEngage[config.widgetId].splice(index, 1);

                //var index = window.ptEngage[config.widgetId].findIndex(function(o) { return o.blockId === config.blockId});
                //index !== -1 && window.ptEngage[config.widgetId].splice(index, 1);
                window.ptEngage[config.widgetId].push({
                    blockId: config.blockId,
                    mounted: ${mountedEvent},
                    unmounted: ${unmountedEvent},
                    updated: ${updatedEvent}
                })
            })(${JSON.stringify(ptInputConfig)});
        </script>`;
    }
};
