export default {
    SET_COUNT_META(state, countMeta) {
        state.countMeta = countMeta;
    },
    SET_CURRENT_DEBUG_ENGAGE_ID(state, debugEngageId) {
        state.currentDebugEngageId = debugEngageId;
    },
    CLEAR_TAB_COUNT(state) {
        state.engageMenuList = state.engageMenuList.map(item => {
            item.count = null;
            return item;
        });
    },
    CLEAR_COUNT_META(state) {
        state.countMeta = null;
    },
    SET_ENGAGE_DEBUG_STATUS(state, status) {
        state.engageInDebug = status;
    },
    UPDATE_CREATE_PORTAL(state, portal) {
        state.createPortal = portal;
    }
};
