// 自定义指令 添加style标签

export default {
    name: 'style',

    inserted: (el, binding) => {
        const cssText = binding.value;
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.setAttribute('id', 'engage_custom_css_home');
        // console.log('cssText inerted:', cssText);
        style.innerHTML = cssText;
        el.innerHTML = ''; // 先清空子元素 再添加 防止重复添加
        el.appendChild(style);
    },
    update: (el, binding) => {
        const cssText = binding.value;

        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.setAttribute('id', 'engage_custom_css_home');
        style.innerHTML = cssText;
        // console.log('cssText update', style.innerHTML);
        el.innerHTML = ''; // 先清空子元素 再添加 防止重复添加
        el.appendChild(style);
    }
};
