import store from '@/store';
import baseConfig from '@/common/configs/base.config';
import router from '@/router/router';
import roleUtils from '@/common/utils/role';
import analyticsService from '@/common/services/analytics.service';
import { eventBus } from '@/common/plugins/eventBus.plugin';
import cookieUtils from '@/common/utils/cookie.utils';

interface OriginPermission {
    permissionDict: {
        updateTime: number;
        description: string;
        id: string;
        name: string;
        status: number;
    }[];
    permissionIdList: string[];
}

export type RoleModuleKey =
    | 'isOwner'
    | 'experienceAdd'
    | 'experienceCopy'
    | 'experienceDelete'
    | 'experienceHeatmap'
    | 'experiencePause'
    | 'experiencePublish'
    | 'experienceReport'
    | 'experienceUpdate'
    | 'goalAdd'
    | 'goalDelete'
    | 'goalSaveAs'
    | 'goalUpdate'
    | 'memberSetting'
    | 'planUpgrade'
    | 'ptxCrossDomainSetting'
    | 'ptxEventPropertyUpdate'
    | 'ptxEventUpdate'
    | 'ptxUserPropertyUpdate'
    | 'userGroupAdd'
    | 'userGroupCopy'
    | 'userGroupDelete'
    | 'userGroupExportUserList'
    | 'userGroupSave'
    | 'userGroupSaveAs'
    | 'userGroupUpdate'
    | 'userGroupViewSegment';

// 默认线上sid
let newPteCollectId = '11b7dcb8';

// userInfo
let PT_USER_INFO: {
    packageInfo: any;
    newPtePackageInfo: any;
    permissions: OriginPermission | undefined;
    currentProfileIsNpPackage: boolean;
    hasExperienceV4Permission: boolean;
    roleModuleList: { [key in RoleModuleKey]?: boolean } & {
        userRole?: string;
    };
    useHashParam: boolean;
} = {
    packageInfo: null,
    newPtePackageInfo: null,
    currentProfileIsNpPackage: false,
    permissions: undefined,
    hasExperienceV4Permission: true,
    roleModuleList: {},
    useHashParam: false
};

// 校验权限列表: {limitType: callbackFn}
const checkAuthCallbackList: any = {};

export function isSingleSpa() {
    return window.parent !== window;
}

export function initSingleSpa() {
    window.addEventListener('message', handlePostmessage, false);
    document.addEventListener('click', handleDocumentClick, false);
    sendPostMessage('ExperienceStart', {});
    eventBus.$on('togglePopup', setPopupTheme, false);
}

export function getCollectId() {
    const collectIdStr = cookieUtils.getItem('ptx_' + newPteCollectId); //线上档案SID
    const collectId = collectIdStr ? Object.fromEntries(collectIdStr.split('&').map(s => s.split('='))) : null;
    return collectId;
}

function handlePostmessage(event: any) {
    if (!event || !event.data) return;
    const { command, message } = event.data || {};

    switch (command) {
        case 'ExperienceStartCallBack':
        case 'ExperienceChangeProfile':
            const {
                packageInfo,
                newPtePackageInfo,
                currentProfileIsNpPackage,
                permissions,
                roleModuleList,
                useHashParam
            } = message;
            PT_USER_INFO = {
                ...PT_USER_INFO,
                packageInfo,
                newPtePackageInfo,
                currentProfileIsNpPackage,
                permissions,
                hasExperienceV4Permission: true,
                roleModuleList,
                useHashParam
            };

            command === 'ExperienceStartCallBack'
                ? emit('ExperienceStartCallBack', message)
                : changeProfile(message.profile.sid, message.routerPath);
            break;
        case 'changeRouter':
            const routerName = message.name;
            const routerPath = message.path;
            if (router?.currentRoute?.name?.startsWith(routerName)) return; // 防止路由重复通知 多次执行组件mounted
            if (routerName) {
                router.push({
                    name: routerName,
                    params: router.currentRoute.params,
                    query: router.currentRoute.query
                });
            } else if (routerPath) {
                router.push({
                    path: routerPath
                });
            }
            break;
        case 'popupBgClick':
            emit('popupBgClick');
            break;
        case 'authCheckResolve':
            var callbackFn = message && checkAuthCallbackList[message.limitType];
            if (callbackFn && typeof callbackFn === 'function') {
                callbackFn();
                delete checkAuthCallbackList[message.limitType];
            }
            break;
        case 'routerChangeToV3Setting':
            const experienceId = message.id;
            if (experienceId) {
                router.push({
                    name: 'CampaignEdit',
                    params: { engageId: experienceId },
                    query: router.currentRoute.query
                });
            }
            break;
        default:
            break;
    }
}

function handleDocumentClick(e: Event) {
    sendPostMessage('ExperienceDocmentClick', {
        // target: e.target
    });
}

export function sendPostMessage(command: string, message?: any) {
    isSingleSpa() &&
        window.parent.postMessage(
            {
                command,
                message
            },
            '*'
        );
}

export function setPopupTheme(theme?: 'white' | 'black') {
    sendPostMessage('ExperienceTogglePopup', {
        theme
    });
}

export function changeAppRouter(appRouterName: string) {
    sendPostMessage('ExperienceChangeAppRouter', {
        appRouterName
    });
}

function changeProfile(sid: string, path?: string) {
    localStorage.setItem(baseConfig.LAST_PROFILE_ID, sid);
    store.dispatch('main/getProfileInfo', sid).then(
        profile => {
            const { domain, name, status, sid } = profile;
            if (sid) {
                roleUtils.changeRouterToCampaignReport();
                emit('changeProfile');
                analyticsService
                    .identifyUser(
                        {
                            id: store.state.main.userInfo?.id
                        },
                        {
                            last_project_name: name,
                            last_project_domain: domain,
                            last_project_is_active: status
                        }
                    )
                    .track();
            }
        },
        err => {
            console.log('ptx get profile or user guide info failed.', err);
        }
    );
}

function emit(command: string, message?: any) {
    eventBus?.$emit(command, message);
}

export function gotoNewPte() {
    const envConfigs = process.env.VUE_APP_CONFIGS || {};
    location.href = location.host.includes('ptengine.com') ? envConfigs.newEnPtengine : envConfigs.newPtengine;
}

export function isDev() {
    return location.host.startsWith('localhost');
}

export function getNewPteUserInfo() {
    return PT_USER_INFO;
}

export function isNpPackage() {
    return PT_USER_INFO.currentProfileIsNpPackage;
}

export function checkPackageAuth(limitType: string, editType: 'EDIT' | 'CREATE', callbackFn?: any) {
    if (isSingleSpa() && isNpPackage()) {
        sendPostMessage('ExperienceReachLimit', { limitType, editType });
        callbackFn && (checkAuthCallbackList[limitType] = callbackFn);
    } else {
        typeof callbackFn === 'function' && callbackFn();
    }
}

export function checkModuleRole(module: RoleModuleKey): boolean {
    return PT_USER_INFO?.roleModuleList?.[module] ?? false;
}

export function validateRoleIsAnalyzer(): boolean {
    return PT_USER_INFO?.roleModuleList.userRole === 'ANALYZER';
}

export function validateRoleIsReadOnly(): boolean {
    return PT_USER_INFO?.roleModuleList.userRole === 'READONLY';
}

export function showAuthDialog(limitType: string) {
    if (isSingleSpa() && isNpPackage()) {
        sendPostMessage('ExperienceShowAuthDialog', { limitType });
        return Promise.resolve();
    }
    return Promise.reject();
}

export function upgrade(key?: string) {
    sendPostMessage('ExperienceUpgrade', {
        type: key
    });
}
