"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMainScroll = exports.getScrollTop = exports.getScrollLeft = void 0;
function getScrollLeft() {
    var t;
    return (((t = document.documentElement) || (t = document.body.parentNode)) &&
        typeof t.scrollLeft == 'number'
        ? t
        : document.body).scrollLeft;
}
exports.getScrollLeft = getScrollLeft;
function getScrollTop() {
    var t;
    return (((t = document.documentElement) || (t = document.body.parentNode)) &&
        typeof t.scrollTop == 'number'
        ? t
        : document.body).scrollTop;
}
exports.getScrollTop = getScrollTop;
function getMainScroll() {
    var t;
    return ((t = document.documentElement) || (t = document.body.parentNode)) &&
        typeof t.scrollTop == 'number'
        ? t
        : document.body;
}
exports.getMainScroll = getMainScroll;
