"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var index_1 = require("@/hooks/index");
var axios_1 = require("axios");
var date_utils_1 = require("@/common/utils/date.utils");
var singleSpa_service_1 = require("@/common/services/singleSpa.service");
var analytics_service_1 = require("@/common/services/analytics.service");
var common_utils_1 = require("@/common/utils/common.utils");
var number_utils_1 = require("@/common/utils/number.utils");
var csv_util_1 = require("@/common/utils/csv.util");
var users_apis_1 = require("@/apis/users.apis");
var index_2 = require("@hooks/index");
var LazyloadCard_vue_1 = require("@/components/commons/LazyloadCard.vue");
var VerifyPackage_vue_1 = require("@/common/packages/verify-package/VerifyPackage.vue");
var Skeleton_vue_1 = require("@/components/commons/Skeleton.vue");
var usergrp_config_1 = require("@/components/users/usergrp-config");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'UsersReportCard',
    props: ['info', 'profileInfo'],
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit, root = _a.root;
        var router = (0, index_2.useRouter)();
        var commit = (0, index_1.useCommit)();
        var cellInfo = (0, composition_api_1.ref)(props.info);
        var requestId = 'FETCH_USER_LIST_ID';
        var moreOptions = [
            {
                name: root.$t('users.user_report_view'),
                code: 'viewGroup',
                roleKey: 'userGroupViewSegment'
            },
            {
                name: root.$t('users.users_operation_edit'),
                code: 'editGroup',
                authKey: 'EDIT_USER_GROUP',
                privilegeKey: 'userGroups',
                editType: 'EDIT',
                roleKey: 'userGroupUpdate'
            },
            {
                name: root.$t('users.user_report_export'),
                code: 'exportUserList',
                roleKey: 'userGroupExportUserList'
            },
            {
                name: root.$t('users.user_report_dulplicate'),
                code: 'dulplicate',
                privilegeKey: 'userGroups',
                authKey: 'CREATE_USER_GROUP',
                editType: 'CREATE',
                roleKey: 'userGroupCopy'
            },
            {
                name: root.$t('users.user_report_delete'),
                code: 'delete',
                divided: true,
                roleKey: 'userGroupDelete'
            }
        ].filter(function (item) { return item.code && (item.roleKey ? (0, singleSpa_service_1.checkModuleRole)(item.roleKey) : true); });
        var handleCommand = function (group, command) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                emit('choose', group);
                switch (command) {
                    case 'editGroup':
                        analytics_service_1.default.usePTX('ug_edit', { position: 'ug_list' }).track();
                        router.push({ name: 'UserEdit', params: { usersId: group.id } });
                        break;
                    case 'viewGroup':
                        router.push({ name: 'UsersSettingReport', params: { usersId: group.id } });
                        break;
                    case 'delete':
                        emit('delete');
                        break;
                    case 'dulplicate':
                        usersDuplicate();
                        break;
                    case 'engageWithThem':
                        (0, singleSpa_service_1.checkPackageAuth)('USE_CAMPAIN_USERGROUPS', 'Edit', function () {
                            analytics_service_1.default.usePTX('ug_engage_users').track();
                            analytics_service_1.default
                                .usePTX('cp_add_click', { position: 'ug_engage_users', editor_version: 'v2' })
                                .track();
                            router.push({ name: 'CampaignCreate', params: { usersId: group.id } });
                        });
                        break;
                    case 'exportUserList':
                        analytics_service_1.default.usePTX('ug_export', { position: 'ug_list' }).track();
                        downloadUserList();
                        break;
                }
                return [2 /*return*/];
            });
        }); };
        var showAuthDialog = function (key) {
            emit('showAuthDialog', key);
        };
        var formatNumber = function (num) {
            return (num !== undefined && number_utils_1.default.formatNumber(num)) || 0;
        };
        (0, composition_api_1.watch)(function () { return props.info; }, function () {
            cellInfo.value = props.info;
        }, {
            deep: true
        });
        return {
            cellInfo: cellInfo,
            moreOptions: moreOptions,
            handleCommand: handleCommand,
            showAuthDialog: showAuthDialog,
            formatNumber: formatNumber,
            handleCardShow: handleCardShow
        };
        function handleCardShow() {
            var _a;
            if (((_a = cellInfo.value) === null || _a === void 0 ? void 0 : _a.users) !== undefined)
                return;
            emit('getData', cellInfo.value);
        }
        function usersDuplicate() {
            return __awaiter(this, void 0, void 0, function () {
                var _a, name, content, description, guideType, _b, err, data;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _a = cellInfo.value, name = _a.name, content = _a.content, description = _a.description, guideType = _a.guideType;
                            return [4 /*yield*/, common_utils_1.default.awaitWrap(users_apis_1.default.createUsergroup(null, {
                                    data: {
                                        sid: props.profileInfo.sid,
                                        name: name + ' copy',
                                        content: content,
                                        guideType: guideType,
                                        description: description
                                    }
                                }))];
                        case 1:
                            _b = _c.sent(), err = _b[0], data = _b[1];
                            if (err) {
                                root.$message.error('dulplicate user group failed.');
                            }
                            else {
                                typeof (data === null || data === void 0 ? void 0 : data.count) === 'number' &&
                                    commit('main/UPDATE_PACKAGE_USAGE', { type: 'userGroups', count: data.count });
                                analytics_service_1.default.usePTX('ug_add_success', { position: 'ug_list_duplicate' }).track();
                                router.push({
                                    name: 'UsersSettingReport',
                                    params: {
                                        usersId: data.id
                                    }
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        /**
         * 导出该用户组下用户数据
         */
        function downloadUserList() {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var chartHeader, _b, err, detailData, user_list, chartData;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            axios_1.default.cancel(requestId);
                            chartHeader = usergrp_config_1.default.userListCsvHeader.map(function (item) { return item.name; });
                            return [4 /*yield*/, common_utils_1.default.awaitWrap(requestUserList(1000))];
                        case 1:
                            _b = _c.sent(), err = _b[0], detailData = _b[1];
                            if (err) {
                                root.$message.error('Fetch chart data fail!');
                            }
                            else if (!axios_1.default.isCancel(detailData)) {
                                user_list = detailData.user_list;
                                chartData = [chartHeader].concat((user_list &&
                                    user_list.map(function (item) {
                                        return usergrp_config_1.default.userListCsvHeader.map(function (row) {
                                            if (row.patternType.includes('TIME')) {
                                                try {
                                                    // 时间戳类型
                                                    if (item[row.code] && item[row.code].length === 13) {
                                                        return date_utils_1.default.formatTimezone(Number(item[row.code]), props.profileInfo.timeZone, 'YYYY/MM/DD HH:mm:ss');
                                                    }
                                                }
                                                catch (e) {
                                                    return item[row.code] || '--';
                                                }
                                            }
                                            return item[row.code] || '--';
                                        });
                                    })) ||
                                    []);
                                csv_util_1.default.init('userList', ((_a = cellInfo.value) === null || _a === void 0 ? void 0 : _a.name) || 'userGroup', chartData).download();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        /**
         * 获取该用户组条件下的所有用户
         */
        function requestUserList(limit) {
            if (limit === void 0) { limit = 100; }
            var properties = usergrp_config_1.default.userListCsvHeader.reduce(function (acc, cur) {
                var code = cur.code, type = cur.type, backup = cur.backup, patternType = cur.patternType;
                acc.push({
                    key: code,
                    type: type,
                    patternType: patternType
                });
                return acc;
            }, []);
            return users_apis_1.default.detailData(null, {
                where: {
                    sid: props.profileInfo.sid,
                    funName: 'userList',
                    audienceFilter: cellInfo.value.content,
                    timeZone: props.profileInfo.timeZone,
                    limit: limit,
                    properties: properties
                }
            }, { requestId: requestId });
        }
    },
    components: {
        VerifyPackage: VerifyPackage_vue_1.default,
        Skeleton: Skeleton_vue_1.default,
        LazyloadCard: LazyloadCard_vue_1.default
    }
});
