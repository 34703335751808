"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var state_1 = require("./state");
var getters_1 = require("./getters");
var actions_1 = require("./actions");
var mutations_1 = require("./mutations");
exports.default = {
    namespaced: true,
    state: state_1.default,
    getters: getters_1.default,
    mutations: mutations_1.default,
    actions: actions_1.default
};
