import usersApis from '@/apis/users.apis';
import commonUtils from '@/common/utils/common.utils';

export default {
    async getUsersProperties(context) {
        const { userProperties } = context.state;
        if (userProperties) return userProperties;

        const { sid, timeZone } = context.rootState.main.profileInfo || {};
        const [err, dictData] = await commonUtils.awaitWrap(
            usersApis.getDictData(null, {
                where: {
                    sid,
                    funName: 'userPropertyName',
                    timeZone,
                    limit: 100
                }
            })
        );
        if (err || !dictData) return [];

        const options = dictData.map(item => {
            const { key, type, source, sourceLists } = item;
            const matchRange = 'user_property';
            const defaultCondition = type === 'TIME' ? '>=' : '==';
            return {
                key,
                name: key,
                code: `${key}_${matchRange}`,
                sourceLists,
                propertyFunName: 'userPropertyValue',
                propertyType: source,
                patternType: type,
                eventType: 'userProperties',
                valueNeedQuery: true,
                defaultCondition,
                hideCondition: false,
                matchRange
            };
        });
        const propertyTypeGroups = commonUtils.groupBy(options, option => option.propertyType);
        const { CUSTOM = [], STATIC = [], API = [] } = propertyTypeGroups || {};
        const patternTypeGroups = [STATIC, CUSTOM, API].map(options => {
            const { TIME = [], NUMBER = [], STRING = [], TIMESTAMP = [] } =
                commonUtils.groupBy(options, option => option.patternType) || {};
            return [...NUMBER, ...STRING, ...TIME, ...TIMESTAMP];
        });
        const proerties = patternTypeGroups.reduce((acc, cur) => {
            cur = cur.map(item => {
                // 默认属性都去掉空和非空操作符
                const { propertyType, patternType } = item;
                propertyType === 'STATIC' && (item.patternType = patternType + '-A');
                return item;
            });
            acc.push(...cur);
            return acc;
        }, []);
        context.commit('UPDATE_USER_PROERTIES', proerties);
        return proerties;
    }
};
