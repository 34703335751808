"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@/store");
var router_1 = require("@/router/router");
var roleNew_config_1 = require("@/common/configs/roleNew.config");
var hasEditRole = function (role) {
    var _a;
    return Boolean((_a = roleNew_config_1.rolesNew[role]) === null || _a === void 0 ? void 0 : _a.canEdit);
};
var includeRoleWithStore = function (key, role) {
    return roleNew_config_1.roleLimited[key] ? hasEditRole(role) : true;
};
var changeRouterToCampaignReport = function (role) {
    var _a;
    router_1.default.push({
        name: 'UsersReport',
        params: {
            sid: (_a = store_1.default.state.main.profileInfo) === null || _a === void 0 ? void 0 : _a.sid
        }
    });
};
exports.default = {
    hasEditRole: hasEditRole,
    includeRoleWithStore: includeRoleWithStore,
    changeRouterToCampaignReport: changeRouterToCampaignReport
};
