import i18n from '@assets/i18n';
import uuidUtils from '@common/utils/uuid.utils';

export const modelOptions = [
    {
        code: 'visualModel',
        default: true
    },
    {
        code: 'codeModel'
    }
];

export const tabOptions = [
    {
        code: 'content',
        name: i18n.t('creative_editor.tab_content'),
        default: true
    },
    {
        code: 'settings',
        name: i18n.t('creative_editor.tab_settings')
    }
];
export const formDesignOptions = [
    {
        code: 'form',
        name: 'form',
        default: true
    },
    {
        code: 'design',
        name: 'design'
    }
];
export const formTabOptions = [
    {
        code: 'content',
        name: i18n.t('creative_editor.tab_content'),
        default: true
    },
    {
        code: 'data',
        name: i18n.t('creative_editor.tab_data')
    }
];

export const versionConfig = [
    {
        rate: 100,
        name: `${i18n.t('engagement.setting_engage_version')} 1`,
        sync: true,
        id: uuidUtils.uuid_16int()
    },
    {
        rate: 0,
        name: `${i18n.t('engagement.setting_engage_version')} 2`,
        sync: true,
        id: uuidUtils.uuid_16int()
    }
];

export const deviceConfig = [
    {
        code: 'pc',
        name: i18n.t('common.pc'),
        viewName: i18n.t('engagement.pc_view'),
        icon: 'pt-icon--pc',
        id: 2,
        default: true,
        enable: true,
        defaultWidth: 480,
        defaultMargin: {
            marginTop: 45,
            marginBottom: 45,
            marginLeft: 30,
            marginRight: 30
        },
        order: 1
    },
    {
        code: 'smartphone',
        name: i18n.t('common.smartphone'),
        viewName: i18n.t('engagement.smart_phone_view'),
        icon: 'pt-icon--sp',
        id: 1,
        enable: true,
        defaultWidth: 320,
        defaultMargin: {
            marginTop: 45,
            marginBottom: 45,
            marginLeft: 8,
            marginRight: 8
        },
        order: 2
    },
    {
        code: 'tablet',
        name: i18n.t('common.tablet'),
        viewName: i18n.t('engagement.tablet_view'),
        icon: 'pt-icon--tablet',
        id: 4,
        enable: true,
        defaultWidth: 480,
        defaultMargin: {
            marginTop: 45,
            marginBottom: 45,
            marginLeft: 30,
            marginRight: 30
        },
        order: 3
    }
];

export const widgetConfig = {
    id: `pt_${uuidUtils.uuid_8bit()}`,
    settings: {},
    style: {
        position: 'pos-left',
        width: 480,
        height: 218,
        'background-color': '#fff'
    }
};

export const uploadState = {
    backgroundImageMaxSize: 2 * 1024 * 1024, // 2MB
    uploadAccept: 'image/*',
    uploadUrl: '/imageUpload'
};

/**
 * widget wrap padding
 */
export const widgetWrapPadding = {
    top: 30,
    bottom: 30,
    left: 30,
    right: 30
};

/**
 * widget黑色遮罩层标识符，在code mode帮助文档中有描述
 * data-pt-showmask属性，在CodeModel下，如果在最外层添加此属性，则表示显示黑色遮罩
 * 由visualModel 切换至 codeModel 时。如果widget位置为居中显示，则默认添加此属性
 */
export const widgetWrapMaskFlag = 'data-pt-showmask';

/**
 * widget分步步骤数
 */
export const widgetsMaxCount = 5;
export const widgetsMinCount = 1;
