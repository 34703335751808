import i18n from '@assets/i18n';

export default {
    positionOptions: [
        {
            code: 'pos-left',
            icon: 'pos-left-bottom',
            cssAnimation: 'animationFadeInLeft',
            cssAnimationName: 'engage--fadeInLeft',
            default: true
        },
        {
            code: 'pos-center',
            icon: 'pos-center',
            cssAnimation: 'animationFadeIn',
            cssAnimationName: 'engage--fadeIn'
        },
        {
            code: 'pos-right',
            icon: 'pos-right-bottom',
            cssAnimation: 'animationFadeInRight',
            cssAnimationName: 'engage--fadeInRight'
        }
    ],
    closeBtnLayoutOptions: [
        {
            code: 'inside',
            name: i18n.t('creative_editor.close_button_inside'),
            style: `top: 10px;
                right: 10px;
            `
        },
        {
            code: 'outside',
            name: i18n.t('creative_editor.close_button_outside'),
            default: true,
            style: `top: 0;
                left: 100%;
                margin-left: 8px;
            `
        },
        {
            code: 'on',
            name: i18n.t('creative_editor.close_button_on'),
            style: `right: 0;
                top: 0;
                transform: translate(50%, -50%);
            `
        }
    ],
    closeBtnStyleOptions: [
        {
            code: 'styleA',
            icon: 'close2',
            default: true,
            style: `
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                border-radius: 50%;
            `,
            svg:
                '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" xml:space="preserve"><rect style="fill:none;" width="16" height="16"/><path d="M8,1C4.1,1,1,4.1,1,8c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7C15,4.1,11.9,1,8,1z M10.8,10c0.2,0.2,0.2,0.6,0,0.8s-0.6,0.2-0.8,0 l-2-2l-2,2c-0.2,0.2-0.6,0.2-0.8,0s-0.2-0.6,0-0.8l2-2l-2-2c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l2,2l2-2c0.2-0.2,0.6-0.2,0.8,0 s0.2,0.6,0,0.8l-2,2L10.8,10z"/></svg>'
        },
        {
            code: 'styleB',
            icon: 'close',
            style: `
                width: 100%;
                height: 100%;
            `,
            svg:
                '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 16 16" xml:space="preserve"><rect x="0" y="0" style="fill:none;" width="16" height="16"/><path d="M8.8,8l4.5-4.5c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0L8,7.2L3.5,2.7c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8 L7.2,8l-4.5,4.5c-0.2,0.2-0.2,0.6,0,0.8s0.6,0.2,0.8,0L8,8.8l4.5,4.5c0.2,0.2,0.6,0.2,0.8,0s0.2-0.6,0-0.8L8.8,8z"/></svg>'
        }
    ],
    closeButtonSizeOptions: {
        min: 12,
        max: 42
    },
    borderRadiusOptions: {
        min: 0,
        max: 100
    },
    paddingOptions: {
        min: 0,
        max: 100,
        items: [
            {
                code: 'paddingTop',
                name: i18n.t('creative_editor.item_padding_up')
            },
            {
                code: 'paddingLeft',
                name: i18n.t('creative_editor.item_padding_left')
            }
        ]
    },
    marginOptions: {
        min: 0,
        max: 100,
        items: [
            {
                code: 'marginLeft',
                name: i18n.t('creative_editor.item_margin_left'),
                position: ['pos-left']
            },
            {
                code: 'marginRight',
                name: i18n.t('creative_editor.item_margin_right'),
                position: ['pos-right']
            },
            {
                code: 'marginTop',
                name: i18n.t('creative_editor.item_margin_top'),
                position: []
            },
            {
                code: 'marginBottom',
                name: i18n.t('creative_editor.item_margin_bottom'),
                position: ['pos-left', 'pos-right']
            }
        ]
    },
    settings: {
        backgroundColor: '#FFFFFF',
        backgroundImage: null,
        borderRadius: 0,
        closeBntLayout: 'on',
        closeBtnColor: '#333',
        closeBtnSize: 20,
        closeBtnStyle: 'styleA',
        height: '',
        marginBottom: 45,
        marginLeft: 30,
        marginRight: 30,
        marginTop: 30,
        paddingLeft: 30,
        paddingTop: 30,
        position: 'pos-left',
        sameWithPreWidget: true,
        visibleBranding: true,
        visibleShadow: true,
        width: 480
    },
    style: {
        base: `position: fixed;
            color: rgb(52, 69, 99);
            z-index: 2147483645;
            cursor: default;
            box-sizing: border-box;
            display: table !important;
            opacity: 1 !important;
            padding: 30px;
        `,
        position: {
            'pos-left': function({ left = 30, bottom = 45 }) {
                return `left:${left}px;
                    bottom:${bottom}px;
                `;
            },
            'pos-center': function() {
                return `top:50%;
                    left:50%;
                    -webkit-transform:translate(-50%,-50%);
                    -moz-transform:translate(-50%,-50%);
                    transform:translate(-50%,-50%);
                    z-index:2147483647!important;
                `;
            },
            'pos-right': function({ bottom = 45, right = 30 }) {
                return `bottom:${bottom}px;
                    left:100%;
                    transform:translateX(-100%);
                    margin-left:-${right}px;
                `;
            }
        },
        branding: `
            position: absolute;
            bottom: -34px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
            font-size: 12px;
            white-space: nowrap;
            background-color: #333;
            color: #fff;
            border-radius: 16px;
            padding: 5px 12px;
            line-height: 12px;
            color: #fff;
            user-select: none;
            text-decoration: none;
            font-family: Arial,sans-serif;
            text-decoration: none !important; 
        `,
        animationFadeIn: `
            @-webkit-keyframes engage--fadeIn {
                from{opacity:0;}
                to {opacity:1;}
            }
            @-moz-keyframes engage--fadeIn {
                from{opacity:0;}
                to {opacity:1;}
            }
            @keyframes engage--fadeIn {
                from{opacity:0;}
                to {opacity:1;}
            }
        `,
        animationFadeInLeft: `
            @-webkit-keyframes engage--fadeInLeft {
                from {opacity:0; left:-100%;}
                to {opacity:1; left: 30px;}
            }
            @-moz-keyframes engage--fadeInLeft {
                from {opacity:0; left:-100%;}
                to {opacity:1; left: 30px;}
            }
            @keyframes engage--fadeInLeft {
                from {opacity:0; left:-100%;}
                to {opacity:1; left: 30px;}
            }
        `,
        animationFadeInRight: `
            @-webkit-keyframes engage--fadeInRight {
                from {opacity:0; right:-100%;}
                to {opacity:1; right: 30px;}
            }
            @-moz-keyframes engage--fadeInRight {
                from {opacity:0; right:-100%;}
                to {opacity:1; right: 30px;}
            }
            @keyframes engage--fadeInRight {
                from {opacity:0; right:-100%;}
                to {opacity:1; right: 30px;}
            }
        `,
        animationFadeInMargin: `
            @-webkit-keyframes engage--fadeInRightMargin {
                from {opacity:0; margin-left:0;}
                to {opacity:1; margin-left:-30px;}
            }
            @-moz-keyframes engage--fadeInRightMargin {
                from {opacity:0; margin-left:0;}
                to {opacity:1; margin-left:-30px;}
            }
            @keyframes engage--fadeInRightMargin {
                from {opacity:0; margin-left:0;}
                to {opacity:1; margin-left:-30px;}
            }
        `
    }
};
