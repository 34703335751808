import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'accountProfile',
        query: `query accountProfile($where: AccountProfileUniqueInput!){
            accountProfile(where:$where) {
                sid
                aid
                userListColumns
            }
        }`
    },
    {
        name: 'updateAccountProfile',
        query: `mutation updateAccountProfile($data: AccountProfileInput!, $where: AccountProfileUniqueInput!){
            updateAccountProfile(data:$data, where:$where) {
                sid,
                aid,
                userListColumns
            }
        }`
    },
    {
        name: 'createProfile',
        query: `mutation createProfile($data: ProfileCreateInput!){
            createProfile(data: $data){
                sid
                name
                type
                domain
                pteEnabled
                profileSource
                timeZone
                createTime
                updateTime
                status
                isActived
            }
        }`
    },
    {
        name: 'getProfiles',
        query: `query getProfiles($where: ProfilesWhereUniqueInput!){
                profiles(where:$where){
                    sid
                    type
                    name
                    domain
                    timeZone
                    pteEnabled
                    profileSource
                    createTime
                    updateTime
                    status
                    isActived
                }
            }`
    },
    {
        name: 'getProfileInfo',
        query: `query getProfileInfo($where: ProfileWhereUniqueInput!, $aid: String!){
            profile(where:$where, aid: $aid){
                ownerEmail
                sid
                siteid
                type
                name
                domain
                timeZone
                createTime
                updateTime
                isActived
                activeTime
                profileSource
                status
                engageCount
                engageRunningCount
                usergroupCount
                goalCount
                currentUserRole
                ptxUserRole
                ptiUserRole
                pteEnabled
                ptxEnabled
                ptengineCrossDomainSetting
                sevenPV
                sevenActiveUsers
                consumedProfile
                primaryDomain
                campaignStatus
                eventStatus
                isNpPackage
            }
        }`
    },
    {
        name: 'firstGuideProfile',
        query: `query firstGuideProfile($where: FirstGuideWhereUniqueInput!){
            firstGuideProfile(where:$where) {
                sid
                guideList
            }
        }`
    },
    {
        name: 'updateFirstGuideProfile',
        query: `mutation updateFirstGuideProfile($data:FirstGuideProfileUpdateInput!){
            updateFirstGuideProfile(data:$data)
        }`
    },
    {
        name: 'getProfilePackage',
        query: `query getProfilePackage($profileId:String!){
            getProfilePackage(profileId:$profileId) {
                sid
                packageId
                startTime
                endTime
                status
                isPtxPlan
                privilege{
                    monthlyActiveUsers
                    userGroups
                    goals
                    campaigns
                    useABTest
                    useCodeMode
                    useCSVDownload
                    useAI
                    hidePopupPtxLogo
                }
                packageBaseConf{
                    packageName
                    packageType
                    area
                    level
                    
                }
                usage{
                    monthlyActiveUsers
                    userGroups
                    goals
                    campaigns
                }
            }
        } `
    },
    {
        name: 'verifyPrivilege',
        query: `query verifyPrivilege($profileId: String!, $privilegeKey:String!){
            verifyPrivilege(profileId:$profileId, privilegeKey:$privilegeKey)
        } `
    },
    {
        name: 'createProfileFromPtengine',
        query: `mutation createProfileFromPtengine($data: [PtengineProfileCreateInput]!){
            createProfileFromPtengine(data: $data){
                sid
                type
                name
                domain
                timeZone
                createTime
                updateTime
                status
                pteEnabled
                isActived

            }
        }`
    },
    {
        name: 'getPtengineProfiles',
        query: `query getPtengineProfiles($where: PtengineProfilesWhereUniqueInput!){
            ptengineProfiles(where:$where){
                sid
                type
                name
                ownerEmail
                domain
                timeZone
                profileSource
                createTime
                updateTime
                status
                isActived
            }
        }`
    },
    {
        name: 'profileGuideStatus',
        query: `query profileGuideStatus($sid: String!) {
            profileGuideStatus(sid: $sid) {
                profileSource,
                profileIsActived,
                hasGoal,
                hasEngage,
                hasUsergroup,
                hasRunEngage
            }
        }`
    },
    {
        name: 'updateProfileCrossDomain',
        query: `query updateProfileCrossDomain($sid: String!) {
            updateProfileCrossDomain(sid: $sid)
        }`
    },
    {
        name: 'getAllCollaborativeUser',
        query: `query getAllCollaborativeUser($profileId: String!){
            getAllCollaborativeUser(profileId: $profileId) {
                aid
                name
                email
                role
                createtime
                status
            }
        }`
    },
    {
        name: 'activeCollaborativeUser',
        query: `mutation activeCollaborativeUser($data: AccountProfileInput!){
            activeCollaborativeUser(data:$data)
        }`
    },
    {
        name: 'addCollaborativeUser',
        query: `mutation addCollaborativeUser($data: AddCollaborativeUserInput!){
            addCollaborativeUser(data:$data)
        }`
    },
    {
        name: 'removeCollaborativeUser',
        query: `mutation removeCollaborativeUser($profileId: String!, $aid: String!){
            removeCollaborativeUser(profileId:$profileId, aid:$aid)
        }`
    },
    {
        name: 'updateUserRole',
        query: `mutation updateUserRole($profileId: String!, $aid:String!, $role:String!) {
            updateUserRole(profileId:$profileId, aid:$aid, role:$role)
        }`
    }
];

export default resourceWrapper(resources);
