"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVisible = exports.getDistanceToParent = exports.getElementCursor = exports.clearSelection = exports.isMobile = exports.addChildAt = exports.removeChildAt = exports.debounce = exports.removeClass = exports.addClass = exports.hasClass = exports.getParent = exports.hasParent = exports.listenScrollParent = exports.getParentRelevantContainerElement = exports.getVisibleRect = exports.hasScrollBar = exports.hasBiggerChild = exports.isScrollingOrHidden = exports.isScrolling = exports.getScrollingAxis = exports.getContainerRect = exports.getIntersectionOnAxis = exports.getIntersection = void 0;
var interfaces_1 = require("./interfaces");
var constants_1 = require("./constants");
var getIntersection = function (rect1, rect2) {
    return {
        left: Math.max(rect1.left, rect2.left),
        top: Math.max(rect1.top, rect2.top),
        right: Math.min(rect1.right, rect2.right),
        bottom: Math.min(rect1.bottom, rect2.bottom),
    };
};
exports.getIntersection = getIntersection;
var getIntersectionOnAxis = function (rect1, rect2, axis) {
    if (axis === 'x') {
        return {
            left: Math.max(rect1.left, rect2.left),
            top: rect1.top,
            right: Math.min(rect1.right, rect2.right),
            bottom: rect1.bottom,
        };
    }
    else {
        return {
            left: rect1.left,
            top: Math.max(rect1.top, rect2.top),
            right: rect1.right,
            bottom: Math.min(rect1.bottom, rect2.bottom),
        };
    }
};
exports.getIntersectionOnAxis = getIntersectionOnAxis;
var getContainerRect = function (element) {
    var _rect = element.getBoundingClientRect();
    var rect = {
        left: _rect.left,
        right: _rect.right,
        top: _rect.top,
        bottom: _rect.bottom,
    };
    if ((0, exports.hasBiggerChild)(element, 'x') && !(0, exports.isScrollingOrHidden)(element, 'x')) {
        var width = rect.right - rect.left;
        rect.right = rect.right + element.scrollWidth - width;
    }
    if ((0, exports.hasBiggerChild)(element, 'y') && !(0, exports.isScrollingOrHidden)(element, 'y')) {
        var height = rect.bottom - rect.top;
        rect.bottom = rect.bottom + element.scrollHeight - height;
    }
    return rect;
};
exports.getContainerRect = getContainerRect;
var getScrollingAxis = function (element) {
    var _a;
    var style = window.getComputedStyle(element);
    var overflow = style['overflow'];
    var general = overflow === 'auto' || overflow === 'scroll';
    if (general)
        return interfaces_1.ScrollAxis.xy;
    var overFlowX = style["overflow-x"];
    var xScroll = overFlowX === 'auto' || overFlowX === 'scroll';
    var overFlowY = style["overflow-y"];
    var yScroll = overFlowY === 'auto' || overFlowY === 'scroll' || ((_a = element === null || element === void 0 ? void 0 : element.className) === null || _a === void 0 ? void 0 : _a.indexOf('ps-container')) !== -1;
    if (xScroll && yScroll)
        return interfaces_1.ScrollAxis.xy;
    if (xScroll)
        return interfaces_1.ScrollAxis.x;
    if (yScroll)
        return interfaces_1.ScrollAxis.y;
    return null;
};
exports.getScrollingAxis = getScrollingAxis;
var isScrolling = function (element, axis) {
    var style = window.getComputedStyle(element);
    var overflow = style['overflow'];
    var overFlowAxis = style["overflow-".concat(axis)];
    var general = overflow === 'auto' || overflow === 'scroll';
    var dimensionScroll = overFlowAxis === 'auto' || overFlowAxis === 'scroll';
    return general || dimensionScroll;
};
exports.isScrolling = isScrolling;
var isScrollingOrHidden = function (element, axis) {
    var style = window.getComputedStyle(element);
    var overflow = style['overflow'];
    var overFlowAxis = style["overflow-".concat(axis)];
    var general = overflow === 'auto' || overflow === 'scroll' || overflow === 'hidden';
    var dimensionScroll = overFlowAxis === 'auto' || overFlowAxis === 'scroll' || overFlowAxis === 'hidden';
    return general || dimensionScroll;
};
exports.isScrollingOrHidden = isScrollingOrHidden;
var hasBiggerChild = function (element, axis) {
    if (axis === 'x') {
        return element.scrollWidth > element.clientWidth;
    }
    else {
        return element.scrollHeight > element.clientHeight;
    }
};
exports.hasBiggerChild = hasBiggerChild;
var hasScrollBar = function (element, axis) {
    return (0, exports.hasBiggerChild)(element, axis) && (0, exports.isScrolling)(element, axis);
};
exports.hasScrollBar = hasScrollBar;
var getVisibleRect = function (element, elementRect) {
    var currentElement = element;
    var rect = elementRect || (0, exports.getContainerRect)(element);
    currentElement = element.parentElement;
    while (currentElement) {
        if ((0, exports.hasBiggerChild)(currentElement, 'x') && (0, exports.isScrollingOrHidden)(currentElement, 'x')) {
            rect = (0, exports.getIntersectionOnAxis)(rect, currentElement.getBoundingClientRect(), 'x');
        }
        if ((0, exports.hasBiggerChild)(currentElement, 'y') && (0, exports.isScrollingOrHidden)(currentElement, 'y')) {
            rect = (0, exports.getIntersectionOnAxis)(rect, currentElement.getBoundingClientRect(), 'y');
        }
        currentElement = currentElement.parentElement;
    }
    return rect;
};
exports.getVisibleRect = getVisibleRect;
var getParentRelevantContainerElement = function (element, relevantContainers) {
    var current = element;
    var _loop_1 = function () {
        if (current[constants_1.containerInstance]) {
            var container_1 = current[constants_1.containerInstance];
            if (relevantContainers.some(function (p) { return p === container_1; })) {
                return { value: container_1 };
            }
        }
        current = current.parentElement;
    };
    while (current) {
        var state_1 = _loop_1();
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return null;
};
exports.getParentRelevantContainerElement = getParentRelevantContainerElement;
var listenScrollParent = function (element, clb) {
    var scrollers = [];
    setScrollers();
    function setScrollers() {
        var currentElement = element;
        while (currentElement) {
            if ((0, exports.isScrolling)(currentElement, 'x') || (0, exports.isScrolling)(currentElement, 'y')) {
                scrollers.push(currentElement);
            }
            currentElement = currentElement.parentElement;
        }
    }
    function dispose() {
        stop();
        scrollers = null;
    }
    ;
    function start() {
        if (scrollers) {
            scrollers.forEach(function (p) { return p.addEventListener('scroll', clb); });
            window.addEventListener('scroll', clb);
        }
    }
    function stop() {
        if (scrollers) {
            scrollers.forEach(function (p) { return p.removeEventListener('scroll', clb); });
            window.removeEventListener('scroll', clb);
        }
    }
    return {
        dispose: dispose,
        start: start,
        stop: stop
    };
};
exports.listenScrollParent = listenScrollParent;
var hasParent = function (element, parent) {
    var current = element;
    while (current) {
        if (current === parent) {
            return true;
        }
        current = current.parentElement;
    }
    return false;
};
exports.hasParent = hasParent;
var getParent = function (element, selector) {
    var current = element;
    while (current) {
        if (current.matches(selector)) {
            return current;
        }
        current = current.parentElement;
    }
    return null;
};
exports.getParent = getParent;
var hasClass = function (element, cls) {
    return (element.className
        .split(' ')
        .map(function (p) { return p; })
        .indexOf(cls) > -1);
};
exports.hasClass = hasClass;
var addClass = function (element, cls) {
    if (element) {
        var classes = element.className.split(' ').filter(function (p) { return p; });
        if (classes.indexOf(cls) === -1) {
            classes.unshift(cls);
            element.className = classes.join(' ');
        }
    }
};
exports.addClass = addClass;
var removeClass = function (element, cls) {
    if (element) {
        var classes = element.className.split(' ').filter(function (p) { return p && p !== cls; });
        element.className = classes.join(' ');
    }
};
exports.removeClass = removeClass;
var debounce = function (fn, delay, immediate) {
    var timer = null;
    return function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        if (timer) {
            clearTimeout(timer);
        }
        if (immediate && !timer) {
            fn.call.apply(fn, __spreadArray([null], params, false));
        }
        else {
            timer = setTimeout(function () {
                timer = null;
                fn.call.apply(fn, __spreadArray([null], params, false));
            }, delay);
        }
    };
};
exports.debounce = debounce;
var removeChildAt = function (parent, index) {
    return parent.removeChild(parent.children[index]);
};
exports.removeChildAt = removeChildAt;
var addChildAt = function (parent, child, index) {
    if (index >= parent.children.length) {
        parent.appendChild(child);
    }
    else {
        parent.insertBefore(child, parent.children[index]);
    }
};
exports.addChildAt = addChildAt;
var isMobile = function () {
    if (typeof window !== 'undefined') {
        if (window.navigator.userAgent.match(/Android/i) ||
            window.navigator.userAgent.match(/webOS/i) ||
            window.navigator.userAgent.match(/iPhone/i) ||
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPod/i) ||
            window.navigator.userAgent.match(/BlackBerry/i) ||
            window.navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
        else {
            return false;
        }
    }
    return false;
};
exports.isMobile = isMobile;
var clearSelection = function () {
    if (window.getSelection) {
        if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
        }
        else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges();
        }
    }
    else if (window.document.selection) {
        // IE?
        window.document.selection.empty();
    }
};
exports.clearSelection = clearSelection;
var getElementCursor = function (element) {
    if (element) {
        var style = window.getComputedStyle(element);
        if (style) {
            return style.cursor;
        }
    }
    return null;
};
exports.getElementCursor = getElementCursor;
var getDistanceToParent = function (parent, child) {
    var current = child;
    var dist = 0;
    while (current) {
        if (current === parent) {
            return dist;
        }
        dist++;
        current = current.parentElement;
    }
    return null;
};
exports.getDistanceToParent = getDistanceToParent;
function isVisible(rect) {
    return !(rect.bottom <= rect.top || rect.right <= rect.left);
}
exports.isVisible = isVisible;
