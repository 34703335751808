import graphqlApis from '@/apis/graphql';
import permissionUtils from '@/common/utils/permission';
import { busInit } from './eventBus.plugin';

export default function install(Vue) {
    // 设置GTM事件[实例方法]
    Vue.prototype.$setGtmEvent = function(event, category, action, label) {
        if (typeof window.dataLayer !== 'undefined') {
            try {
                window.dataLayer.push({
                    event: event || '',
                    category: category || '',
                    action: action || '',
                    label: label || ''
                });
            } catch (e) {
                console.log(e);
            }
            console.table(window.dataLayer[window.dataLayer.length - 1]);
        } else {
            console.info(arguments);
        }
    };

    // 设置PTE事件[实例方法]
    Vue.prototype.$setPteEvent = function(event) {
        if (typeof window._pt_sp_2 !== 'undefined') {
            try {
                window._pt_sp_2.push('setCustomVarV2', {
                    eventName: event
                });
            } catch (e) {
                console.log(e);
            }
            console.table(window._pt_sp_2[window._pt_sp_2.length - 1]);
        } else {
            console.info(arguments);
        }
    };

    // 全局事件
    Vue.prototype.$eventBus = busInit(Vue);

    // 全局接口调用方法
    Vue.prototype.$graphql = graphqlApis.graphql;

    // 全局校验权限
    Vue.prototype.$permission = function(permissions) {
        return permissionUtils.includePermissionWithStore(permissions);
    };

    // 全局校验权限
    Vue.prototype.$roles = function(roles) {
        return permissionUtils.includeRoleWithStore(roles);
    };
}
