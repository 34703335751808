<template>
    <div class="block-settings">
        <div class="block-settings__header" v-if="!isSubModel">
            <pt-button type="text" color="black" size="medium" @click="handleBack">{{
                $t('common.button_back')
            }}</pt-button>
            <h3>{{ $t(block.blockName) }}</h3>
        </div>
        <div class="block-settings__container">
            <!--  <pt-scrollbar ref="blockScrollbar"> -->
            <div class="block-settings__item-wrap">
                <!-- form design-->
                <template v-if="currentTab === 'design'">
                    <!-- Font color && placeholder color-->
                    <div class="block-settings__item-group two-cloumn">
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.item_font_color')"
                            target="fontColor"
                            :hasInput="false"
                            :value="settings.fontColor"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.item_placeholder_color')"
                            target="placeholderColor"
                            :hasInput="false"
                            :value="settings.placeholderColor"
                            @change="handleKitSettingsChange"
                        ></kits>
                    </div>
                    <!-- Font size & Stroke weight-->
                    <div class="block-settings__item-group two-cloumn">
                        <kits
                            type="inputNumber"
                            :title="$t('creative_editor.item_font_size')"
                            target="fontSize"
                            :min="fontSizeOptions.min"
                            :max="fontSizeOptions.max"
                            :value="settings.fontSize"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <kits
                            type="select"
                            :title="$t('creative_editor.item_stroke_weight')"
                            target="fontWeight"
                            :value="settings.fontWeight"
                            :options="fontWeightOptions"
                            @change="handleKitSettingsChange"
                        ></kits>
                    </div>
                    <!-- Height -->
                    <kits
                        type="slider"
                        :title="$t('creative_editor.item_height')"
                        target="height"
                        :min="heightOptions.min"
                        :max="heightOptions.max"
                        :value="settings.height"
                        @change="handleKitSettingsChange"
                    ></kits>
                    <div class="block-settings__hr"></div>

                    <div class="block-settings__item-group two-cloumn">
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.creative_widget_bg')"
                            target="backgroundColor"
                            :hasInput="false"
                            :value="settings.backgroundColor"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.item_border_color')"
                            target="borderColor"
                            :hasInput="false"
                            :value="settings.borderColor"
                            @change="handleKitSettingsChange"
                        ></kits>
                    </div>
                    <!--  border width -->
                    <kits
                        type="slider"
                        :title="$t('creative_editor.item_button_width')"
                        target="borderWidth"
                        :min="borderWidthOptions.min"
                        :max="borderWidthOptions.max"
                        :value="settings.borderWidth"
                        @change="handleKitSettingsChange"
                    ></kits>
                    <!-- Round corners -->
                    <kits
                        type="slider"
                        :title="$t('creative_editor.corner_radius')"
                        target="borderRadius"
                        :min="borderRadiusOptions.min"
                        :max="borderRadiusOptions.max"
                        :value="settings.borderRadius"
                        @change="handleKitSettingsChange"
                    ></kits>
                </template>
            </div>
            <!--  </pt-scrollbar> -->
        </div>
    </div>
</template>
<script>
import config from './input.config';
import SettingsTab from '../SettingsTab';
import Kits from '../../kits/Kits.vue';
import { tabOptions, formDesignOptions } from '../../configs/creative.config';
export default {
    name: 'InputSettings',
    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        //是否是表单提交按钮
        refer: String,
        tab: String
    },
    inject: ['formDesign'],
    data() {
        const currentTab = this.tab || '';
        const { fontSizeOptions, fontWeightOptions, borderWidthOptions, borderRadiusOptions, heightOptions } = config;
        const defaultTab =
            this.refer && this.refer === 'form'
                ? formDesignOptions.find(item => item.code === currentTab)
                : tabOptions.find(item => item.default);
        const defaultContentTab = config.content.find(item => item.default);
        return {
            defaultTab,
            defaultContentTab,

            fontSizeOptions,
            fontWeightOptions,
            borderWidthOptions,
            borderRadiusOptions,
            heightOptions,

            currentTab: '',
            currentContentCode: '',

            content: [],
            settings: {}
        };
    },
    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.currentContentCode = this.defaultContentTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
        },
        handleBack() {
            this.$emit('back');
        },
        handleContentChange(params) {
            this.$emit('contentChange', this.content, params);
        },
        handleKitChange(val) {
            this.$set(this.currentContentValue, val.type, val.value);
            this.handleContentChange();
        },
        handleKitSettingsChange(val) {
            this.$set(this.settings, val.type, val.value);
            this.$emit('settingsChange', this.settings, 'input');
        }
    },
    components: {
        Kits
    }
};
</script>
