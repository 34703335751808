"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTransformFixed = void 0;
var composition_api_1 = require("@vue/composition-api");
var resize_observer_polyfill_1 = require("resize-observer-polyfill");
var getScroll_1 = require("@ptengine/pt-ui-x-utils/getScroll");
var elementInfo_1 = require("@ptengine/pt-ui-x-utils/elementInfo");
var debounce_1 = require("@ptengine/pt-ui-x-utils/throttle-debounce/debounce");
function useTransformFixed() {
    var fixedDOM = (0, composition_api_1.ref)();
    var pageTop = null;
    var defaultPosition = '';
    var ro = null;
    var inFixed = null;
    var mainScroll = (0, getScroll_1.getMainScroll)();
    var handleScroll = function () {
        if (!fixedDOM.value || pageTop === null)
            return;
        var scrollTop = mainScroll.scrollTop;
        if (scrollTop > pageTop) {
            if (!inFixed) {
                if (!fixedDOM.value)
                    return;
                inFixed = true;
                if (defaultPosition !== 'absolute' && defaultPosition !== 'fixed' && defaultPosition !== 'sticky')
                    fixedDOM.value.style.position = 'absolute';
            }
            if (!fixedDOM.value)
                return;
            fixedDOM.value.style.transform = "translate3d(0, ".concat(scrollTop - pageTop - 2, "px, 0)");
        }
        else {
            if (inFixed) {
                window.requestAnimationFrame(function () {
                    if (inFixed)
                        inFixed = null;
                    if (!fixedDOM.value)
                        return;
                    if (defaultPosition !== 'absolute' && defaultPosition !== 'fixed' && defaultPosition !== 'sticky')
                        fixedDOM.value.style.position = 'static';
                    fixedDOM.value.style.transform = '';
                    fixedDOM.value.style.transition = '';
                });
            }
        }
    };
    var bindScroll = function () { return (0, composition_api_1.nextTick)(function () {
        var _a;
        if (!fixedDOM.value || pageTop !== null)
            return;
        pageTop = (0, elementInfo_1.getElementPageTop)(fixedDOM.value);
        defaultPosition = (_a = getComputedStyle(fixedDOM.value)) === null || _a === void 0 ? void 0 : _a.position;
        var observerFunc = (0, debounce_1.default)(233, false, function () {
            if (!fixedDOM.value)
                return;
            pageTop = (0, elementInfo_1.getElementPageTop)(fixedDOM.value);
            handleScroll();
        });
        ro = new resize_observer_polyfill_1.default(observerFunc);
        ro.observe(fixedDOM.value);
        setTimeout(function () { return handleScroll(); }, 200);
        window.addEventListener('scroll', handleScroll);
    }); };
    var unbindScroll = function () {
        window.removeEventListener('scroll', handleScroll);
        if (ro) {
            ro.disconnect();
            ro = null;
        }
        pageTop = null;
    };
    (0, composition_api_1.onActivated)(bindScroll);
    (0, composition_api_1.onDeactivated)(unbindScroll);
    (0, composition_api_1.onMounted)(bindScroll);
    (0, composition_api_1.onUnmounted)(unbindScroll);
    return ({
        fixedDOM: fixedDOM
    });
}
exports.useTransformFixed = useTransformFixed;
