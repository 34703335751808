"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDispatch = exports.useCommit = exports.useGetters = exports.useState = exports.useStore = void 0;
var index_1 = require("@store/index");
var composition_api_1 = require("@vue/composition-api");
function createComputedObj(obj, keys) {
    if (!(obj instanceof Object))
        return obj;
    var resObj = {};
    if (!keys || !(keys instanceof Array)) {
        var _loop_1 = function (key) {
            resObj[key] = (0, composition_api_1.computed)(function () { return obj[key]; });
        };
        for (var key in obj) {
            _loop_1(key);
        }
    }
    else {
        var _loop_2 = function (key) {
            if (obj.hasOwnProperty(key))
                resObj[key] = (0, composition_api_1.computed)(function () { return obj[key]; });
        };
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            _loop_2(key);
        }
    }
    return resObj;
}
// 返回整个store，自行computed
function useStore() {
    return index_1.default;
}
exports.useStore = useStore;
function useState(param1, param2) {
    if (!param1 && !param2)
        return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state);
    if (param1 instanceof Array && !param2)
        return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state, param1);
    if (typeof param1 === 'string' && (index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state[param1]) && !param2)
        return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state[param1]);
    if (typeof param1 === 'string' && (index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state[param1]) && param2 instanceof Array)
        return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state[param1], param2);
    return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.state);
}
exports.useState = useState;
function useGetters(param1, param2) {
    if (!param1 && !param2)
        return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.getters);
    if (param1 instanceof Array && !param2)
        return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.getters, param1);
    if (typeof param1 === 'string' && !param2) {
        var computedObj = createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.getters, Object.keys(index_1.default.getters).filter(function (key) { return key.startsWith(param1); }));
        var res_1 = {};
        Object.entries(computedObj).forEach(function (_a) {
            var key = _a[0], val = _a[1];
            return res_1[key.substr(param1.length + 1)] = val;
        });
        return res_1;
    }
    if (typeof param1 === 'string' && param2 instanceof Array) {
        var computedObj = createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.getters, Object.keys(index_1.default.getters).filter(function (key) { return key.startsWith(param1); }));
        var res_2 = {};
        Object.entries(computedObj).forEach(function (_a) {
            var key = _a[0], val = _a[1];
            var subKey = key.substr(param1.length + 1);
            if (param2.includes(subKey))
                res_2[subKey] = val;
        });
        return res_2;
    }
    return createComputedObj(index_1.default === null || index_1.default === void 0 ? void 0 : index_1.default.getters);
}
exports.useGetters = useGetters;
// useCommit和useDispatch没有做类似useGetters的扁平化处理，仅支持字符串检测
function useCommit() {
    return index_1.default.commit;
}
exports.useCommit = useCommit;
function useDispatch() {
    return index_1.default.dispatch;
}
exports.useDispatch = useDispatch;
