/**
 * 权限统一处理（localStorage中存储系统中使用的权限）
 * 分为：
 * （1）用户级权限
 * （2）档案级权限
 */
// require shim使用
var authority = {
    loginAuthority: loginAuthority,
    siteAuthority: siteAuthority
};
function loginAuthority(obj, callback) {
    console.log('loginAuthority', obj);
    var global = window.pt ? window.pt.global : window.localStorage;
    global.uid = obj.UID || obj.id;
    global.useremail = obj.email || (obj.content && obj.content.email); // email
    global.region = obj.content && obj.content.region ? obj.content.region : obj.region; // 版本[jp,en,zh]其对应的version为[0,1,3]
    return typeof callback === 'function' ? callback() : false;
}

function siteAuthority(obj, callback) {
    var global = window.pt ? window.pt.global : window.localStorage;
    global.sid = obj.sid; // sid
    global.timezone = obj.timeZone.indexOf(' ') > -1 ? obj.timeZone.split(' ')[1] : obj.timeZone; // 档案时区 =08:00
    global.profilename = obj.name || ''; // 档案名称
    return typeof callback === 'function' ? callback() : false;
}
export default authority;
