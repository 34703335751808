import i18n from '@assets/i18n';
import commonUtils from '@/common/utils/common.utils.js';
import cloneUtils from '@/common/utils/clone.utils';
// const defaultEndTimeStamp = parseInt(new Date().getTime()) + 2 * 24 * 60 * 60 * 1000;
const defaultEndTimeStr = commonUtils.getAddDateStr(2);
const defaultEndMessage = 'The countdown is finished.';
const countDownDefaultContent = {
    type: 'fixedDate', //fixedDate（指定截止日期）、fixedTime（指定时间段）
    date: {
        days: 2,
        hour: 23,
        minute: 59,
        second: 59
    },
    timezone: {
        useVisitors: false,
        profile: ''
    },
    endTime: defaultEndTimeStr,
    labels: {
        enable: false,
        language: localStorage.getItem('language') || 'en',
        fontSize: 12,
        fontColor: '#444444'
    },
    numbers: {
        fontSize: 20,
        fontWeight: 'bold',
        fontColor: '#FFFFFF',
        backgroundColor: '#091E42',
        separatorColor: '#091E42'
    }
};
export default {
    defaultContent: cloneUtils.deep(countDownDefaultContent),
    typeOptions: [
        {
            code: 'fixedDate', //指定截止日期
            name: i18n.t('creative_editor.countdown_fixed_date'),
            default: true
        },
        {
            code: 'fixedTime', //指定截止时间
            name: i18n.t('creative_editor.countdown_fixed_time')
        }
    ],
    dateOptions: [
        {
            code: 'days',
            name: i18n.t('creative_editor.countdown_days'),
            min: 0,
            max: 99
        },
        {
            code: 'hour',
            name: i18n.t('creative_editor.countdown_hours'),
            min: 0,
            max: 23
        },
        {
            code: 'minute',
            name: i18n.t('creative_editor.countdown_minutes'),
            min: 0,
            max: 59
        },
        {
            code: 'second',
            name: i18n.t('creative_editor.countdown_seconds'),
            min: 0,
            max: 59
        }
    ],
    labelsMap: {
        en: {
            days: 'Days',
            hour: 'Hours',
            minute: 'Minutes',
            second: 'Seconds'
        },
        zh: {
            days: '天',
            hour: '时',
            minute: '分',
            second: '秒'
        },
        jp: {
            days: '日',
            hour: '時間',
            minute: '分',
            second: '秒'
        }
    },
    allRegionlanguageOptions: [
        {
            code: 'en',
            name: i18n.t('creative_editor.countdown_labels_en') //此处需要i18n
        },
        {
            code: 'jp',
            name: i18n.t('creative_editor.countdown_labels_jp') //此处需要i18n
        },
        {
            code: 'zh',
            name: i18n.t('creative_editor.countdown_labels_zh') //此处需要i18n
        }
    ],
    layoutOptions: [
        {
            code: 'up', // text is on down, time is on up
            icon: 'countdown-up',
            default: true
        },
        {
            code: 'down', // text is on up,time is on down
            icon: 'countdown-down'
        }
    ],
    marginOptions: {
        min: 0,
        max: 100
    },
    settingTabOptions: [
        {
            code: 'layout',
            name: i18n.t('creative_editor.item_layout'),
            default: true
        },
        {
            code: 'action',
            name: i18n.t('creative_editor.item_action')
        }
    ],
    actionRadioOptions: [
        {
            code: 'hide', // hide countdown module when timer is finished.
            name: i18n.t('creative_editor.countdown_action_hide'),
            default: true
        },
        {
            code: 'showMsg', // show some message when timer is finished.
            name: i18n.t('creative_editor.countdown_action_show_msg')
        }
    ],
    // timezoneOptions:[
    //     {
    //         code:false,
    //         name:'Your Profile’s Time (GTM +09:00)',
    //         default:true
    //     },
    //     {
    //         code:true,
    //         name:'Visitor’s Time Zone'
    //     }
    // ],
    fontWeightOptions: [
        {
            code: 'normal',
            name: i18n.t('creative_editor.font_normal')
        },
        {
            code: 'bold',
            name: i18n.t('creative_editor.font_bold')
        }
    ],
    fontSizeOptions: {
        max: 30,
        min: 10
    },
    content: [
        {
            code: 'countdown',
            name: i18n.t('creative_editor.theme_countdown'),
            value: cloneUtils.deep(countDownDefaultContent),
            default: true
        }
    ],
    settings: {
        layout: 'up',
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: '',
        endAction: 'hide', // hide or showMsg
        endMessage: defaultEndMessage,
        noPadding: false,
        isRenderMsg: false
    }
};
