/**
 * @deprecated 此指令会有重复绑定问题 暂时没用
 * 在创建档案布码脚本的copy按钮，使用该指令。
 * 使用Clipboard插件，完成复制操作
 */
import Clipboard from 'clipboard';

export default {
    name: 'copy',

    inserted: (el, binding) => {
        var clipboard = new Clipboard(el, {
            text: () => binding.value.text
        });
        clipboard.on('success', () => {
            const fn = binding.value.success;
            fn && typeof fn === 'function' && fn();
        });
        clipboard.on('error', () => {
            const fn = binding.value.fail;
            fn && typeof fn === 'function' && fn();
        });
    }
};
