import Vue from 'vue';
import Vuex, { Store as StoreBase, CommitOptions, DispatchOptions } from 'vuex';
import main from './main';
import users from './users';
import engage from './engage';
import campaign from './campaign';
import scenario from './scenario';
import type { GetActionsType, GetMutationsType, GetGettersType, GetPayLoad, GetReturnType, GetStateType } from './typeEnhance';

const isDevEnv = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
Vue.config.devtools = isDevEnv;

Vue.use(Vuex);

const vuexOptions = {
    state: {
        testState1: '1',
        testState2: 2
    },
    getters: {
        testGetter1: () => 2,
        testGetter2: () => '3',
        ['test/Getter3']: () => false,
    },
    modules: {
        main,
        users,
        engage,
        campaign,
        scenario
    }
};

type VuexOptions = typeof vuexOptions;
type VuexOptionsOmitModlue<T extends keyof VuexOptions['modules']> = Omit<VuexOptions, 'modules'> & { modules: Omit<VuexOptions['modules'], T> };
type MutationsOmitModule<T extends keyof VuexOptions['modules']> = Readonly<GetMutationsType<VuexOptionsOmitModlue<T>>>;
type ActionsOmitModule<T extends keyof VuexOptions['modules']> = Readonly<GetActionsType<VuexOptionsOmitModlue<T>>>;

export type MutationsPattern<S, M> = {
    [key in keyof M]: M[key] extends (...args: any) => any ? (state: S, payload: any) => ReturnType<M[key]> : any;
}

export type GettersPattern<M extends keyof VuexOptions['modules'], S, G> = {
    [key in keyof G]: G[key] extends (...args: any) => any ? (
        state: Readonly<S>,
        getters: Readonly<G>,
        rootState: Readonly<GetStateType<VuexOptionsOmitModlue<M>> & VuexGenerateObj<M, S>>,
        rootGetters: Readonly<GetGettersType<VuexOptionsOmitModlue<M>> & ProptypeAddPrefix<G, `${M}/`>>
    ) => ReturnType<G[key]> : any;
}

export type ActionsPattern<M extends keyof VuexOptions['modules'], S, G, Mu, A> = {
    [key in keyof A]: A[key] extends (...args: any) => any ? (
        context: {
            state: Readonly<S>,
            getters: Readonly<G>,
            rootGetters: Readonly<GetGettersType<VuexOptionsOmitModlue<M>> & ProptypeAddPrefix<G, `${M}/`>>,
            rootState: Readonly<GetStateType<VuexOptionsOmitModlue<M>> & VuexGenerateObj<M, S>>,
            commit: <T extends keyof (MutationsOmitModule<M> & Readonly<ProptypeAddPrefix<Mu, `${M}/`>>)>
                (
                    type: T, 
                    payload?: T extends keyof MutationsOmitModule<M> ? GetPayLoad<MutationsOmitModule<M>, T> : VuexGetSecondParam<VuexRemovePrefix<Mu, T, `${M}/`>>, 
                    options?: CommitOptions
                ) => 
                    T extends keyof MutationsOmitModule<M> ?
                        GetReturnType<MutationsOmitModule<M>, T>
                            : VuexRemovePrefixReturnType<Mu, T, `${M}/`>;
            dispatch: <T extends keyof (ActionsOmitModule<M> & Readonly<ProptypeAddPrefix<A, `${M}/`>>)>
                (
                    type: T, 
                    payload?: T extends keyof ActionsOmitModule<M> ? GetPayLoad<ActionsOmitModule<M>, T> : VuexGetSecondParam<VuexRemovePrefix<A, T, `${M}/`>>, 
                    options?: DispatchOptions
                ) =>
                    T extends keyof ActionsOmitModule<M> ?
                        Promise<GetReturnType<ActionsOmitModule<M>, T>>
                            : VuexRemovePrefixReturnType<A, T, `${M}/`>;
        },
        payload: VuexGetSecondParam<A[key]>
    ) => ReturnType<A[key]> : any;
}

export type RootState = Readonly<VuexOptions['state']>;
export type RootGetters = Readonly<VuexOptions['getters']>;
export type ModuleNames = Readonly<keyof VuexOptions['modules']>;
export type State = Readonly<GetStateType<VuexOptions>>;
export type Getters = Readonly<GetGettersType<VuexOptions>>;
export type Actions = Readonly<GetActionsType<VuexOptions>>;
export type Mutations = Readonly<GetMutationsType<VuexOptions>>;

export type Store = OverWrite<StoreBase<State>, {
    getters: Getters;
    commit: <T extends keyof Mutations>(type: T, payload?: GetPayLoad<Mutations, T>, options?: CommitOptions) => 
        GetReturnType<Mutations, T>;
    dispatch: <T extends keyof Actions>(type: T, payload?: GetPayLoad<Actions, T>, options?: DispatchOptions) =>
        Promise<GetReturnType<Actions, T>>;
}>;

const store = new Vuex.Store(vuexOptions as any) as Store;

export default store;

if (module.hot) {
    module.hot.accept(['./main', './users', './engage', './campaign', './scenario'], () => {
        const main = require('./main').default;
        const users = require('./users').default;
        const engage = require('./engage').default;
        const campaign = require('./campaign').default;
        const scenario = require('./scenario').default;
        store.hotUpdate({
            modules: {
                main,
                users,
                engage,
                campaign,
                scenario
            }
        });
    })
}
