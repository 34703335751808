var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-wrap x-wrap--full"},[(_vm.allPeopleData)?_c('router-link',{attrs:{"to":{ name: 'UsersSettingOverview', params: { usersId: _vm.allPeopleData.id } }}},[_c('div',{class:_vm.$style.all},[_c('pt-icon',{attrs:{"icon":"icono-all-people","icon-style":{
                    width: '80px',
                    height: '80px',
                    fill: '#C1C7D0'
                }}}),_c('div',{class:_vm.$style['all-describe']},[_c('h3',[_vm._v(_vm._s(_vm.$t('users.all_people')))]),_c('p',[_vm._v(_vm._s(_vm.$t('users.all_people_description')))])]),_c('div',{class:_vm.$style['all-data']},[_c('p',[(_vm.allPeopleData.users === undefined)?_c('Skeleton'):[_vm._v(" "+_vm._s(_vm.numberUtils.toThousands(_vm.allPeopleData.users))+" ")],_c('span',[_vm._v(_vm._s(_vm.$t('users.metrics_users')))])],2),_c('p',[(_vm.allPeopleData.newActives === undefined)?_c('Skeleton'):[_vm._v(" "+_vm._s(_vm.numberUtils.toThousands(_vm.allPeopleData.newActives))+" ")],_c('span',[_vm._v(_vm._s(_vm.$t('users.metrics_new_users')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.user_group_2')}},[_c('pt-icon',{class:_vm.$style.helpIcon,attrs:{"icon":"pt-icon--help-new"}})],1)],2),_c('p',[(_vm.allPeopleData.actives === undefined)?_c('Skeleton'):[_vm._v(" "+_vm._s(_vm.numberUtils.toThousands(_vm.allPeopleData.actives))+" ")],_c('span',[_vm._v(_vm._s(_vm.$t('users.metrics_active_users')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.user_group_3')}},[_c('pt-icon',{class:_vm.$style.helpIcon,attrs:{"icon":"pt-icon--help-new"}})],1)],2)]),_c('pt-icon',{class:_vm.$style['all-icon'],attrs:{"icon":"icon-arrow-right","icon-style":{
                    width: '18px',
                    height: '18px',
                    fill: '#091E42'
                }}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }