/**
 * Users相关api
 */

import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'getUserGroups',
        query: `query getUserGroups($where: UsergroupWhereUniqueInput!){
            usergroups(where:$where) {
                id
                name
                content
                source
                description
                users
                actives
                newActives
                guideType
                engagements
            }
        }`
    },
    {
        name: 'getUserGroupConfigs',
        query: `query getUserGroupConfigs($input: UserGroupInput!){
            getUserGroupConfigs(input:$input) {
                id
                name
                content
                source
                description
                guideType
                engagements
                updateTime
            }
        }`
    },
    {
        name: 'getUserGroupData',
        query: `query getUserGroupData($input: UserGroupDataInput!){
            getUserGroupData(input:$input) {
                id
                users
                actives
                newActives
            }
        }`
    },
    {
        name: 'getDictData',
        query: `query getDictData($where:DictDataWhereUniqueInput!){
            dictData(where:$where)
        }`
    },
    {
        name: 'getUserGroupInfo',
        query: `query getUserGroupInfo($where: UsergroupWhereUniqueInput!){
            usergroup(where:$where){
                id
                name
                description
                content
                source
                guideType
            }
        }`
    },
    {
        name: 'detailData',
        query: `query detailData($where: DetailDataWhereUniqueInput!) {
            detailData(where:$where)
        }`
    },
    {
        name: 'standardData',
        query: `query standardData($where: StandardDataWhereUniqueInput!){
            standardData(where:$where)
        }`
    },
    {
        name: 'createUsergroup',
        query: `mutation createUsergroup($data: UsergroupCreateInput!){
            createUsergroup(data: $data) {
                id
                sid
                content
                name
                source
                description
                createTime
                updateTime
                status
                count
            }
        }`
    },
    {
        name: 'updateUsergroup',
        query: `mutation updateUsergroup($data: UsergroupUpdateInput!, $where: UsergroupWhereUniqueInput!){
            updateUsergroup(data: $data, where: $where) {
                id
                content
                name
                description
            }
        }`
    },
    {
        name: 'deleteUserGroup',
        query: ` mutation deleteUserGroup($where: UsergroupWhereUniqueInput!) {
            deleteUsergroup(where: $where) {
                id
                name
                count
            }
        }`
    },
    {
        name: 'getRelatedEngages',
        query: `query getRelatedEngages($input: EngageQueryInput!){
            getRelatedEngages(input: $input){
                engageId
                engageName
                status
                versions {
                    versionId
                    versionName
                    status
                    isControlGroup
                }
            }
        }`
    }
];

export default resourceWrapper(resources);
