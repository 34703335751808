<template>
    <div class="pt-table">
        <div class="pt-table__header">
            <div class="pt-table__row">
                <div
                    class="pt-table__col"
                    v-for="col in column"
                    :key="col.prop"
                    :style="{width: col.width}"
                >{{col.label}}</div>
            </div>
        </div>
        <div class="pt-table__container">
            <pt-scrollbar :wheelPropagation="true">
                <div class="pt-table__row" v-for="(row, $index) in data" :key="$index">
                    <div
                        class="pt-table__col"
                        v-for="col in column"
                        :key="col.prop"
                        :style="{width: col.width}"
                        :data-type="col.dataType"
                        :title="row[col.prop]"
                    >
                        <slot v-bind:col="row[col.prop]">
                            <span>{{row[col.prop]}}</span>
                        </slot>
                    </div>
                </div>
            </pt-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ptTable',

    props: {
        column: {
            type: Array,
            default: function() {
                return [
                    {
                        label: 'Name',
                        width: '50%',
                        prop: 'name'
                    }
                ];
            }
        },
        data: {
            type: Array,
            default: function() {
                return [
                    {
                        name: 'x'
                    }
                ];
            }
        }
    },

    data() {
        return {};
    },

    methods: {}
};
</script>

<style lang="scss">
.pt-table {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100%;

    &__header {
        color: #b0b7c3;
        font-size: 12px;
        line-height: 17px;
    }

    &__container {
        flex: 1;
        overflow: hidden;
        color: #091e42;

        .pt-table__row {
            height: 40px;
        }
    }

    &__row {
        display: flex;
        align-items: center;
    }

    &__col {
        display: flex;
        align-items: center;

        &[data-type='number'] {
            font-weight: 600;
        }

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 10px;
        }
    }
}
</style>