"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDefaultHighlightStyle = exports.deleteHighlight = exports.createHighlight = exports.highlightEle = void 0;
var isDOMElement_1 = require("./../../../utils/isDOMElement");
var style_object_1 = require("../../style-object");
var getScroll_1 = require("../../../utils/getScroll");
var defaultHighlightStyle = {
    position: 'absolute',
    zIndex: 8998,
    border: '2px solid #13CB6E',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    transition: 'all .3s ease',
    pointerEvents: 'none',
    left: '0px',
    top: '0px',
};
var highlightEleExist = false;
exports.highlightEle = null;
var targetEle = null;
var oldZIndex = '';
function createHighlight(target, styleObj, fromResize, insertDOM) {
    if (styleObj === void 0) { styleObj = __assign({}, defaultHighlightStyle); }
    return new Promise(function (resolve) {
        var bindDOM = (typeof insertDOM === 'string' ? document.querySelector(insertDOM) : insertDOM);
        if (!(0, isDOMElement_1.isDOMElement)(bindDOM))
            bindDOM = document.body;
        if (targetEle && oldZIndex !== '')
            targetEle.style.zIndex = oldZIndex;
        targetEle = target;
        var targetStyle = getComputedStyle(targetEle);
        var targetBoundaryFixed = targetEle.getBoundingClientRect();
        var scrollTop = (0, getScroll_1.getScrollTop)();
        var scrollLeft = (0, getScroll_1.getScrollLeft)();
        var targetBoundary = {
            width: targetBoundaryFixed.width,
            height: targetBoundaryFixed.height,
            top: targetBoundaryFixed.top + scrollTop,
            bottom: targetBoundaryFixed.bottom + scrollTop,
            left: targetBoundaryFixed.left + scrollLeft,
            right: targetBoundaryFixed.right + scrollLeft
        };
        oldZIndex = targetStyle.zIndex;
        // targetEle.style.zIndex = '10005';
        if (!styleObj.width)
            styleObj.width = "".concat(targetBoundary.width + 16, "px");
        if (!styleObj.height)
            styleObj.height = "".concat(targetBoundary.height + 16, "px");
        styleObj.left = targetBoundary.left
            - (parseInt(String(styleObj.width))
                - parseInt(String(targetStyle.width))) / 2
            + 'px';
        styleObj.top = targetBoundary.top
            - (parseInt(String(styleObj.height))
                - parseInt(String(targetStyle.height))) / 2
            + 'px';
        if (!exports.highlightEle) {
            exports.highlightEle = document.createElement('div');
            exports.highlightEle.classList.add('guide-highlight');
        }
        else
            highlightEleExist = true;
        exports.highlightEle.style.cssText = (0, style_object_1.formatStyleObjectToString)(__assign(__assign({}, defaultHighlightStyle), styleObj));
        if (!highlightEleExist || fromResize) {
            bindDOM.append(exports.highlightEle);
            resolve(exports.highlightEle);
        }
        else {
            // if (tooltipEle) tooltipEle.style.opacity = '0';
            exports.highlightEle.addEventListener('transitionend', function () {
                // if (tooltipEle) tooltipEle.style.opacity = '1';
                resolve(exports.highlightEle);
            });
        }
    });
}
exports.createHighlight = createHighlight;
function deleteHighlight() {
    if (targetEle) {
        targetEle.style.zIndex = oldZIndex;
        targetEle = null;
        oldZIndex = '';
    }
    if (exports.highlightEle) {
        exports.highlightEle.remove();
        exports.highlightEle = null;
        highlightEleExist = false;
    }
}
exports.deleteHighlight = deleteHighlight;
function setDefaultHighlightStyle(styleObj) {
    if (typeof styleObj !== 'object')
        return;
    defaultHighlightStyle = __assign(__assign({}, defaultHighlightStyle), styleObj);
}
exports.setDefaultHighlightStyle = setDefaultHighlightStyle;
var css = "\n    @keyframes guide-highlight-scale {\n        0% { transform: scale(1); }\n        50% { transform: scale(1.08);}\n        100% { transform: scale(1);}\n    }\n\n    .guide-highlight {\n        animation: guide-highlight-scale 1.4s ease-in-out infinite;     \n        contain: size layout paint;   \n    }\n\n";
var head = document.head || document.getElementsByTagName('head')[0];
var style = document.createElement('style');
style.type = 'text/css';
head === null || head === void 0 ? void 0 : head.appendChild(style);
style === null || style === void 0 ? void 0 : style.appendChild(document === null || document === void 0 ? void 0 : document.createTextNode(css));
