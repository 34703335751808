"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMouseDragScroll = void 0;
var composition_api_1 = require("@vue/composition-api");
function useMouseDragScroll() {
    var scrollDOM = (0, composition_api_1.ref)();
    var lastScrollLeft = 0;
    var startX = 0;
    var handleMouseMove = function (event) {
        if (!scrollDOM.value)
            return;
        if (Math.abs(event.x - startX) <= 16 || Math.abs(event.movementX) < 1)
            return;
        var newScrollLeft = scrollDOM.value.scrollLeft - event.movementX;
        if (lastScrollLeft === newScrollLeft)
            return;
        scrollDOM.value.scrollLeft = newScrollLeft;
        lastScrollLeft = newScrollLeft;
    };
    var handleMouseUp = function (event) {
        startX = 0;
        document.removeEventListener('mousemove', handleMouseMove, true);
        document.removeEventListener('mouseup', handleMouseUp, true);
    };
    var handleMouseDown = function (event) {
        if (event.button !== 0)
            return;
        startX = event.x;
        event.preventDefault();
        document.addEventListener('mouseup', handleMouseUp, true);
        document.addEventListener('mousemove', handleMouseMove, true);
    };
    (0, composition_api_1.onMounted)(function () {
        if (!scrollDOM.value)
            return;
        lastScrollLeft = scrollDOM.value.scrollLeft;
        scrollDOM.value.addEventListener('mousedown', handleMouseDown);
    });
    (0, composition_api_1.onUnmounted)(function () {
        var _a;
        (_a = scrollDOM.value) === null || _a === void 0 ? void 0 : _a.removeEventListener('mousedown', handleMouseDown);
    });
    return {
        scrollDOM: scrollDOM
    };
}
exports.useMouseDragScroll = useMouseDragScroll;
