import i18n from '@assets/i18n';
import cloneUtils from '@common/utils/clone.utils';

const generalDefaultSettings = {
    type: 'form',
    backgroundColor: 'transparent',
    fieldSpacing: 12,
    buttonSpacing: 4,
    topMargin: 4,
    bottomMargin: 4,
    buttonPosition: 'bottom',
    buttonAlignment: 'center'
};
const generalDefaultContent = {
    type: 'form'
};
export default {
    defaultContent: cloneUtils.deep(generalDefaultContent),
    spaceingOptions: {
        min: 0,
        max: 300
    },
    marginOptions: {
        min: 0,
        max: 100
    },
    buttonPositionOptions: [
        {
            code: 'bottom',
            name: i18n.t('creative_editor.item_button_position_bottom')
        },
        {
            code: 'right',
            name: i18n.t('creative_editor.item_button_position_right')
        }
    ],
    alignmentOptions: [
        {
            code: 'flex-start',
            icon: 'alignment-left'
        },
        {
            code: 'center',
            icon: 'alignment-center'
        },
        {
            code: 'flex-end',
            icon: 'alignment-right'
        }
    ],
    content: [
        {
            code: 'general',
            name: 'General',
            value: cloneUtils.deep(generalDefaultContent),
            default: true
        }
    ],
    settings: cloneUtils.deep(generalDefaultSettings)
};
