import i18n from '@assets/i18n';
import cloneUtils from '@/common/utils/clone.utils';
import validatorFactory from '@/common/utils/validators.utils';
import { widgetsMinCount, widgetsMaxCount } from '../../configs/creative.config';

const buttonDefaultContent = {
    type: 'button',
    text: i18n.t('creative_editor.theme_button'),
    fontColor: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#F98639',
    paddingTop: 10,
    paddingLeft: 30,
    action: {
        type: 'close', //['close', 'url', 'tel', 'gotoWidget]
        url: '',
        openNewTab: true,
        tel: '',
        gotoWidget: 'next'
    },
    borderRadius: 4,
    borderColor: '#F98639',
    borderWidth: 1
};
const validatorRule = {
    url: [
        {
            type: 'required',
            trigger: 'blur',
            errMsg: i18n.t('error.common_3007')
        },
        {
            type: 'isUrl',
            trigger: 'blur',
            errMsg: i18n.t('error.common_3007')
        }
    ],
    tel: [
        {
            type: 'required',
            trigger: 'blur',
            errMsg: i18n.t('error.phone_format_err')
        },
        {
            type: 'isTelphone',
            trigger: 'blur',
            errMsg: i18n.t('error.phone_format_err')
        }
    ],
    gotoWidget: [
        {
            type: 'required',
            errMsg: i18n.t('error.goto_widget_format_err')
        }
    ]
};
const blockContentValidatorFun = function(contentValue, widgetIndex) {
    const { text } = contentValue;
    const { type } = contentValue.action;
    const value = contentValue.action[type];
    let invalid = false;
    let invalidRule = '';
    console.log('blockContentValidatorFun', contentValue, widgetIndex);

    if (text && validatorRule[type]) {
        validatorRule[type].forEach(rule => {
            if (!invalid) {
                invalid = validatorFactory[rule.type](value);
                invalid && (invalidRule = rule);
            }
        });
    }
    return {
        invalid,
        invalidRule
    };
};

/**
 * 组件内容自动设置部分
 */
const blockContentAutoSettingFun = function(contentValue, widgetIndex) {
    const { type } = contentValue.action;

    if (type === 'gotoWidget') {
        // 最后一步的按钮动作默认为关闭
        if (widgetIndex === widgetsMaxCount - 1 && contentValue.action.gotoWidget === 'next') {
            contentValue.action.type = 'close';
            contentValue.action.gotoWidget = '';
        }

        // 第一步的按钮动作
        if (widgetIndex === widgetsMinCount - 1 && contentValue.action.gotoWidget === 'prev') {
            contentValue.action.gotoWidget = '';
        }
    } else if (type === 'close') {
        contentValue.action.gotoWidget = '';
    }
    return contentValue;
};

export default {
    defaultContent: cloneUtils.deep(buttonDefaultContent),
    hasMultiple: function(block) {
        return block.blockSettings.buttonNumber === 'two';
    },
    blockContentValidatorFun,
    blockContentAutoSettingFun,
    placeholder: {
        text: '',
        url: i18n.t('creative_editor.creative_weblink_placeholder'),
        tel: i18n.t('creative_editor.creative_call_placeholder')
    },
    typeOptions: [
        {
            code: 'button',
            name: i18n.t('creative_editor.button_type_button'),
            default: true
        },
        {
            code: 'link',
            name: i18n.t('creative_editor.button_type_link')
        }
    ],
    successOptions: [
        {
            code: 'gotoWidget',
            name: i18n.t('creative_editor.creative_goto_widget'),
            default: true
        },
        {
            code: 'url',
            name: i18n.t('creative_editor.form_submitted_url')
        }
    ],
    numberOptions: [
        {
            code: 'one',
            name: i18n.t('creative_editor.button_one_column'),
            default: true
        },
        {
            code: 'two',
            name: i18n.t('creative_editor.button_two_column')
        }
    ],
    layoutOptions: [
        {
            code: 'horizontal',
            icon: 'button-horizontal',
            default: true
        },
        {
            code: 'vertical',
            icon: 'button-vertical'
        }
    ],
    spaceingOptions: {
        min: 0,
        max: 300
    },
    fontSizeOptions: {
        min: 10,
        max: 30
    },
    fontWeightOptions: [
        {
            code: 'normal',
            name: i18n.t('creative_editor.font_normal')
        },
        {
            code: 'bold',
            name: i18n.t('creative_editor.font_bold')
        }
    ],
    borderRadiusOptions: {
        min: 0,
        max: 100
    },
    borderWidthOptions: {
        min: 0,
        max: 50
    },
    marginOptions: {
        min: 0,
        max: 100
    },
    alignmentOptions: [
        {
            code: 'left',
            icon: 'alignment-left'
        },
        {
            code: 'center',
            icon: 'alignment-center'
        },
        {
            code: 'right',
            icon: 'alignment-right'
        }
    ],
    paddingOptions: {
        min: 0,
        max: 200,
        items: [
            {
                code: 'paddingTop',
                name: i18n.t('creative_editor.item_padding_up')
            },
            {
                code: 'paddingLeft',
                name: i18n.t('creative_editor.item_padding_left')
            }
        ]
    },
    actionEventsOptions: [
        {
            code: 'url',
            name: i18n.t('creative_editor.creative_weblink'),
            tip: i18n.t('creative_editor.engage_edit_link_tips_btn'),
            default: true
        },
        {
            code: 'tel',
            name: i18n.t('creative_editor.creative_call'),
            tip: i18n.t('creative_editor.engage_edit_call_tips_btn')
        },
        {
            code: 'close',
            name: i18n.t('creative_editor.creative_close_widget')
        },
        {
            code: 'gotoWidget',
            name: i18n.t('creative_editor.creative_goto_widget')
        }
    ],
    gotoWidgetOptions: [
        {
            code: 'prev',
            name: i18n.t('creative_editor.prev_widget'),
            editBtnText: i18n.t('creative_editor.edit_prev_widget'),
            default: true,
            nextIndex: function(currentIndex) {
                return currentIndex - 1;
            },
            showIt: function(isFirstStep) {
                return !isFirstStep;
            }
        },
        {
            code: 'next',
            name: i18n.t('creative_editor.next_widget'),
            editBtnText: i18n.t('creative_editor.edit_next_widget'),
            nextIndex: function(currentIndex) {
                return currentIndex + 1;
            },
            showIt: function(isFirstStep, isLastStep) {
                return !isLastStep;
            }
        }
    ],
    urlValidatorRule: validatorRule.url,
    telValidatorRule: validatorRule.tel,
    gotoWidgetValidatorRule: validatorRule.gotoWidget,
    content: [
        {
            code: 'button1',
            name: 'Button1', //i18n.t('creative_editor.button_one'),
            value: cloneUtils.deep(buttonDefaultContent),
            default: true
        }
        // {
        //     code: 'button2',
        //     name: i18n.t('creative_editor.button_two'),
        //     value: cloneUtils.deep(buttonDefaultContent)
        // }
    ],
    settings: {
        backgroundColor: '',
        buttonNumber: 'one',
        marginTop: 8,
        marginBottom: 8,
        spacing: 8,
        alignment: 'center',
        layout: 'horizontal'
    }
};
