import store from '@/store';
import i18n from '@/assets/i18n';
import baseConfig from '@/common/configs/base.config';
import userService from '@/common/services/user.service';
import permissionUtils from '@/common/utils/permission';
import analyticsService from '@/common/services/analytics.service';
import interactiveDemoService from '@/components/interactive-demo/interactive-demo.service';
import { getCampaignReportRouteName } from '@/components/campaign/campaign.util';
import {
    isSingleSpa,
    gotoNewPte,
    sendPostMessage,
    isDev
} from '@/common/services/singleSpa.service';

export default {
    beforeChange(to, from, next) {
        // 切换路由，取消正在请求项
        store.commit('main/CLEAR_QUERY_TOKEN');

        if (!isDev && to.name === 'Login') {
            gotoNewPte();
            return;
        }

        if (isSingleSpa()) {
            const { token, uid, sid } = to.query;
            if (token && uid && sid) {
                localStorage.setItem(baseConfig.ACCOUNT_ID, uid);
                localStorage.setItem(baseConfig.LAST_PROFILE_ID, sid);
                localStorage.setItem(baseConfig.ACCOUNT_TOKEN, decodeURIComponent(token));
            }

            sendPostMessage('ExperienceRouteBeforeChange', {
                to: {
                    name: to.name,
                    params: to.params,
                    path: to.path,
                    meta: JSON.parse(JSON.stringify(to.meta))
                },
                from: {
                    name: from.name,
                    params: from.params,
                    path: from.path,
                    meta: JSON.parse(JSON.stringify(from.meta))
                }
            });
        }

        if (to.meta.isWhiteList) {
            next();
            return;
        }

        if (!userService.alreadyLoggedIn()) {
            userService.logout();
            return;
        }

        const aid = localStorage.getItem(baseConfig.ACCOUNT_ID);
        store.dispatch('main/getUserInfo', aid).then(
            () => {
                const isToMain = to.matched.some(item => item.name === 'Main');
                isToMain &&
                    (store.dispatch('main/onLoading', true), store.dispatch('main/scrollToTop'));
                next();
            },
            err => {
                userService.logout();
            }
        );
    },

    afterChange(to, from) {
        const { title, titleI18n, hideMainMenu } = to.meta;
        const pageTitle = title || (titleI18n && i18n.t(titleI18n));
        pageTitle && (document.title = pageTitle + ' | Ptengine Experience');
        store.dispatch('main/onLoading', false);

        const hideMenu = (hideMainMenu && hideMainMenu(to, from)) || to.params.hideMainMenu;
        store.commit('main/SET_HIDE_ASIDE', hideMenu);

        if (isSingleSpa()) {
            sendPostMessage('ExperienceRouteAfterChange', {
                to: {
                    name: to.name,
                    params: to.params,
                    path: to.path,
                    meta: JSON.parse(JSON.stringify(to.meta))
                },
                from: {
                    name: from.name,
                    params: from.params,
                    path: from.path,
                    meta: JSON.parse(JSON.stringify(to.meta))
                }
            });
        }
    },

    beforeEnterApp(to, from, next) {
        //TODO：new ptengine内暂时还没有激活流程，维持在ptx内激活
        if (
            !isDev &&
            !isSingleSpa() &&
            !['Auth', 'AccountActive', 'JoinProject'].includes(to.name)
        ) {
            gotoNewPte();
            return;
        }
        store.dispatch('main/onLoading', false);
        next();
    },

    beforeEnterMain(to, from, next) {
        store.dispatch('main/getProfiles').then(
            profiles => {
                let sid = to.params && to.params.sid;
                if (profiles && profiles.length > 0) {
                    const isDemoProfile = interactiveDemoService.isDemoProfile(sid);

                    if (!isDemoProfile) {
                        let profileInfo = null;
                        if (sid) {
                            profileInfo = profiles.find(item => item.sid === sid);
                        } else {
                            const lastId = localStorage.getItem(baseConfig.LAST_PROFILE_ID);
                            lastId && (profileInfo = profiles.find(item => item.sid === lastId));
                        }
                        sid = profileInfo?.sid || profiles[0]?.sid;
                    }

                    store.dispatch('main/getProfileInfo', sid).then(
                        profile => {
                            if (to.params.sid === profile.sid) {
                                const hasPermission = to.matched.every(item => {
                                    const { permissions } = item.meta;
                                    return permissions
                                        ? permissionUtils.includePermissionWithStore(permissions)
                                        : true;
                                });
                                hasPermission ? next() : next({ name: 'App' });
                            } else {
                                next(
                                    Object.assign({}, to, {
                                        params: {
                                            sid: profile.sid
                                        }
                                    })
                                );
                            }
                        },
                        () => {
                            console.log('query profile info failed.');
                            userService.logout();
                        }
                    );
                } else {
                    const signupSource = localStorage.getItem(baseConfig.ACCOUNT_SIGNUP_SOURCE);
                    if (signupSource === 'ptengine') {
                        analyticsService
                            .useGA({
                                event: 'sign_up',
                                category: 'account',
                                signup_source: 'ptengine'
                            })
                            .usePTX('sign_up', { signup_source: 'ptengine' })
                            // .useCamera(signupSource)
                            .track();
                        next({ name: 'ConnectProfile' });
                    } else {
                        next({ name: 'CreateProfile' });
                    }
                }
            },
            () => {
                console.log('query profile list failed.');
                userService.logout();
            }
        );
    },

    beforeEnterEngagement(to, from, next) {
        const demoType = interactiveDemoService.getDemoType(to.params?.sid, to.query?.type);
        if (demoType) {
            next();
        } else {
            (store.state.main?.packageInfo?.usage?.campaigns || 0) === 0
                ? next({
                      name:
                          getCampaignReportRouteName() === 'EngagementReport'
                              ? 'EngagementOnboarding'
                              : 'CampaignOnboarding'
                  })
                : next();
        }
        store.dispatch('main/onLoading', false);
    },

    beforeEnterDataSettings(to, from, next) {
        const { profileInfo } = store.state.main || { profileInfo: { isActived: 0 } };
        // if (profileInfo.isActived === 1) {
        next();
        // } else {
        //     next({
        //         name: 'Setup'
        //     });
        //     store.dispatch('main/onLoading', false);
        // }
    },

    beforeUpdateUsers(to, from, next) {
        (store.state.main?.packageInfo?.usage?.userGroups || 0) > 0
            ? next({ name: 'UsersReport' })
            : next();
        store.dispatch('main/onLoading', false);
    },

    beforeEnterUsers(to, from, next) {
        (store.state.main?.packageInfo?.usage?.userGroups || 0) > 0
            ? next({ name: 'UsersReport' })
            : next();
        store.dispatch('main/onLoading', false);
    },

    beforeEnterGoals(to, from, next) {
        (store.state.main?.packageInfo?.usage?.goals ?? 0) > 0
            ? next({ name: 'GoalsReport' })
            : next();
        store.dispatch('main/onLoading', false);
    }
};
