"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var swrWrapper_1 = require("./swrWrapper");
var resources = {
    getCampaigns: {
        name: 'engagements',
        query: "query engagements($where: EngageDataWhereUniqueInput!){\n            engagements(where:$where) {\n                id\n                name\n                engageVersion\n                baseURL\n                createTime\n                updateTime\n                createBy\n                updateBy\n                conditions\n                schedule\n                hasForm\n                hasVariant\n                abtestType\n                screenshot\n                baseURL\n                audiences: usergroups {\n                    name,\n                    source,\n                    id,\n                }\n                status\n                goals {\n                    id\n                    name\n                }\n                versions{\n                    id\n                    name\n                    rate\n                    controlGroup\n                    displayConfig\n                    screenshot\n                    terminals{\n                        id\n                        type\n                        popup\n                        stickybar\n                        inline\n                        code\n                        codeMode\n                        codeSync\n                        status\n                        widgets\n                    }\n                }\n                reportData\n                firstRunTime\n                lastPauseTime\n            }\n        }"
    },
    getEngagement: {
        name: 'getEngagement',
        query: "query getEngagement($where:EngageWhereUniqueInput!){\n            engagement(where:$where){\n                id\n                sid\n                name\n                engageVersion\n                baseURL                              \n                hasForm\n                abtestType\n                status\n                screenshot\n                hasVariant\n                schedule\n                conditions\n                isVersionEvent\n                firstRunTime\n                lastPauseTime\n                usergroups{\n                    id,\n                    name,\n                    source\n                }\n                goals {\n                    id\n                    name\n                    content\n                }\n                versions {\n                    id\n                    name\n                    rate\n                    isSync\n                    status\n                    displayConfig\n                    controlGroup\n                    screenshot\n                    terminals{\n                        id\n                        type\n                        popup\n                        stickybar\n                        inline\n                        codeMode\n                        codeSync\n                        code                       \n                        status\n                        widgets\n                        widgetLayout\n                    }\n                } \n            }\n        }"
    },
    getVersionData: {
        name: 'engageMultipleData',
        query: "query engageMultipleData($where: EngageDataWhereUniqueInput!){\n            engageMultipleData(where: $where)\n        }"
    },
    getAllGoalList: {
        name: 'getGoals',
        query: "query getGoals($where: GoalListWhereUniqueInput!){\n            goals(where:$where) {\n                id\n                name\n                content              \n                description\n                engagements\n                currentResult\n                lastResult\n            }\n        }"
    },
    getReportColumns: {
        name: 'accountProfile',
        query: "query accountProfile($where: AccountProfileUniqueInput!){\n            accountProfile(where:$where) {\n                campaignReportColumns\n            }\n        }"
    },
    updateReportColumns: {
        name: 'updateAccountProfile',
        query: "mutation updateAccountProfile($data: AccountProfileInput!, $where: AccountProfileUniqueInput!){\n            updateAccountProfile(data:$data, where:$where) {\n                campaignReportColumns\n            }\n        }"
    },
    getUserGroups: {
        name: 'getUsergroups',
        query: "query getUsergroups($where: UsergroupWhereUniqueInput!){\n            usergroups(where: $where){\n                id\n                source\n                name\n                content\n            }\n        }"
    },
    getCampaignDefaultName: {
        name: 'defaultEngageName',
        query: "query defaultEngageName($where: EngageWhereUniqueInput!){\n            defaultEngageName(where:$where) \n        }"
    },
    getPlatformData: {
        name: 'dataPlatformData',
        query: "query dataPlatformData($where:DataPlatformDataWhereUniqueInput!){\n            dataPlatformData(where: $where)\n        }"
    }
};
exports.default = (0, swrWrapper_1.default)(resources);
