export default {
    asideMenuHome: 'home',
    asideMenuUserGroup: 'user',
    asideMenuEngage: 'engage',
    asideMenuSettings: 'setting',
    usersCreate: 'createUserGroup',
    engagePreview: 'engagePreview',
    engageVariant: 'engageVariant',
    engageLayout: 'engageLayout',
    engageV2: 'engage2.0',
    popupTemplate: 'popupTemplate',
    popupForm: 'popupForm',
    shadowPopup: 'shadowPopup',
    userGroupTimes: 'userGroupTimes', // 过滤器次数选择项
    campaignReportV2: 'campaignReportV2',
    goalRex: '6bb98c2e-16e8-11ec-9306-28f10e200c1e',
    userPropertyUpload: 'userPropertyUpload' // 用户属性上传
};
