import dayjs from 'dayjs';
import RegConfigs from '@/common/configs/reg.config';

class loadingTextStore {
    constructor() {
        this.loadingTimer = null;
    }

    start(callback) {
        const _self = this;
        const loadingStart = Date.now();
        this.loadingTimer = setInterval(function() {
            if (_self.loadingTimer) {
                const timeConsuming = Date.now() - loadingStart;
                timeConsuming > 4000 && (callback(), _self.stop());
            }
        }, 1000);
    }

    stop() {
        this.loadingTimer && (clearInterval(this.loadingTimer), (this.loadingTimer = null));
    }
}

export default {
    clearGlobal: function() {
        var global = window.localStorage;
        // 这些项目不清除,日历控件维态
        var excludeAry = [
            'eventGuideModal',
            'recentheatmapobj',
            'changemaptypetips',
            'hasShowCodeTip',
            'prevsite',
            'engager'
        ];
        for (var item in global) {
            if (excludeAry.indexOf(item) > -1) {
                continue;
            }
            window.localStorage.removeItem(item);
        }
        document.cookie =
            'pt_z=;Path=/;Domain=.' +
            document.location.hostname
                .split('.')
                .slice(-2)
                .join('.') +
            ';expires=' +
            new Date(0).toGMTString();
    },
    // 一维数组转换为二维数组
    change2Array: function(arr, n) {
        // n>0
        const len = arr.length;
        const num = len % 0 === 0 ? len / n : Math.ceil(len / n);
        const result = [];
        for (let i = 0; i < num; i++) {
            const temp = arr.slice(i * n, i * n + n);
            result.push(temp);
        }
        return result;
    },
    // 函数节流
    throttle: function(fn, context, delay) {
        clearTimeout(fn.timeoutId);
        fn.timeoutId = setTimeout(function() {
            fn.call(context);
        }, delay);
    },
    // 对象的深拷贝
    deepExtend(target, source) {
        // let key;
        const _this = this;
        for (const key in source) {
            if (Object.prototype.toString.call(source[key]) === '[object Object]') {
                _this.deepExtend(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        }
        return target;
    },
    /**
     * @param {*} len  生成随机字符串的位数
     */
    randomString(len) {
        len = len || 8;
        const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; //默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
        const maxPos = $chars.length;
        let str = '';
        for (let i = 0; i < len; i++) {
            str += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return str;
    },
    /**
     * @param {*} script script标签即内容
     */
    runScript(script) {
        return new Promise(reslove => {
            // 直接 document.head.appendChild(script) 是不会生效的，需要重新创建一个
            const newScript = document.createElement('script');
            // 获取 inline script
            const renderScript = `
                try {
                    (function(){${script.innerHTML}})();
                } catch(e){}
            `;
            newScript.innerHTML = renderScript;
            // 存在 src 属性的话
            document.head.appendChild(newScript);
            document.head.removeChild(newScript);
            reslove();
        });
    },
    /**
     * Escape regexp symbols
     * @param {*} str
     */
    escapeRexCharacters(str) {
        if (typeof str !== 'string') return;
        // 需要被转义的正则元字符
        const metacharacters = ['?', '^', '$', '*', '+', '(', ')', '|'];
        metacharacters.map(function(character) {
            const reg = new RegExp('(\\' + character + ')', 'g');
            str = str.replace(reg, '\\$1');
        });
        return str;
    },

    completeProtocol(url) {
        // if url doesn't have any protocol and not even a relative or a label, use http:// as default
        return /^([A-Za-z][A-Za-z0-9+-.]*\:|#|\/)/.test(url) ? url : 'http://' + url;
    },
    formatDateValue(str) {
        return str < 10 ? '0' + str : str;
    },
    /**
     *
     * @param {*} addDayCount 要添加的天数
     */
    getAddDateStr(addDayCount) {
        const date = new Date();
        date.setDate(date.getDate() + addDayCount); // 获取addDayCount天后的日期
        const yyyy = date.getFullYear();
        const MM = this.formatDateValue(date.getMonth() + 1);
        const dd = this.formatDateValue(date.getDate());
        const hh = this.formatDateValue(date.getHours());
        const mm = this.formatDateValue(date.getMinutes());
        const ss = this.formatDateValue(date.getSeconds());
        console.log(`${yyyy}/${MM}/${dd} ${hh}:${mm}:${ss}`);
        return `${yyyy}/${MM}/${dd} ${hh}:${mm}:${ss}`;
    },
    getDateStr(dateStr) {
        const date = new Date(dateStr);
        const yyyy = date.getFullYear();
        const MM = this.formatDateValue(date.getMonth() + 1);
        const dd = this.formatDateValue(date.getDate());
        const hh = this.formatDateValue(date.getHours());
        const mm = this.formatDateValue(date.getMinutes());
        const ss = this.formatDateValue(date.getSeconds());
        console.log(`${yyyy}/${MM}/${dd} ${hh}:${mm}:${ss}`);
        return `${yyyy}/${MM}/${dd} ${hh}:${mm}:${ss}`;
    },

    /**
     * 获取明天的0点时间戳和后天23点59分59秒的时间戳
     * @param {*} key: start/end
     */
    getTimestamp(key = 'start') {
        const days = key === 'start' ? 1 : 2;
        const formatString = `YYYY/MM/DD ${key === 'start' ? '00:00:00' : '23:59:59'}`;
        return dayjs(
            dayjs()
                .add(days, 'day')
                .format(formatString)
        ).valueOf();
    },

    getFullDate(milliseconds) {
        const time = new Date(Number(milliseconds));
        const YY = time.getFullYear();
        const MM = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
        const DD = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
        const HH = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
        const mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
        const ss = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
        return `${YY}/${MM}/${DD} ${HH}:${mm}:${ss}`;
    },

    /**
     * async/await 获取error
     * example: const [err, data] = await awaitWrap(fetchData())
     * @param {*} promise
     */
    awaitWrap(promise) {
        return promise
            .then(data => {
                return [null, data];
            })
            .catch(err => [err, null]);
    },
    /**
     * 数组group
     * @returns {Object}: {a: [...], b: [...]}
     */
    groupBy(array, f) {
        let groups = {};
        array.forEach(function(o) {
            let group = f(o);
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        // return Object.keys(groups).map(function(group) {
        //     return groups[group];
        // });
        return groups;
    },

    /**
     * 截取用户名称
     * @param {String} userName
     * @returns {Array}
     */
    getUserIconName(userName) {
        let name = [];
        let firstName = null;
        let lastName = null;
        let words = userName.split(/\s+/g).reduce((prev, curr) => {
            if (curr !== '') {
                prev.push(curr);
            }
            return prev;
        }, []);
        let len = words.length;

        if (len === 1) {
            firstName = words.join('').substr(0, 2);
        } else {
            firstName = words[0].substr(0, 1);
            lastName = words[len - 1].substr(0, 1);
        }

        if (firstName) {
            name.push({
                name: firstName,
                isWord: RegConfigs.isWord.test(firstName)
            });
        }
        if (lastName) {
            name.push({
                name: lastName,
                isWord: RegConfigs.isWord.test(lastName)
            });
        }
        return name;
    },

    /**
     * 获取loading提示文案
     */
    loadingTextStore: () => new loadingTextStore(),
    i18nTransfer: (text, variables) => {
        if (!text) return '';
        if (!variables) return text;
        const r = text.match(/{.*?}/gi);
        if (!r) return text;
        for (let i = 0; i < r.length; i++) {
            text = text.replace(r[i], variables[r[i].replace('{', '').replace('}', '')]);
        }
        return text;
    }
};
