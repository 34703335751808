import type {RoleModuleKey} from '@/common/services/singleSpa.service';

const roleLimited = [
    'copyUsergroup',
    'createCampaign',
    'createGoal',
    'createUsergroup',
    'crossDomain',
    'deleteUsergroup',
    'editUsergroup',
    'engageWithUsergroup',
    'saveNewUsergroup',
    'saveUsergroup'
] as const;

/**
 * 新版角色key
 * 在 ptengine-frontend 项目内定义
 */
const roleMapping: Readonly<{[key in typeof roleLimited[number]]: RoleModuleKey}> = {
    copyUsergroup: 'userGroupCopy',
    createCampaign: 'experienceAdd',
    createGoal: 'goalAdd',
    createUsergroup: 'userGroupAdd',
    crossDomain: 'ptxCrossDomainSetting',
    deleteUsergroup: 'userGroupDelete',
    editUsergroup: 'userGroupUpdate',
    engageWithUsergroup: 'userGroupAdd',
    saveNewUsergroup: 'userGroupSaveAs',
    saveUsergroup: 'userGroupSave',
};

const roles = [
    {
        key: 'owner',
        value: '0',
        canEdit: true,
        name: 'user_setting.role_owner'
    },
    {
        key: 'admin',
        value: '2',
        canEdit: true,
        name: 'user_setting.role_admin',
        desc: 'user_setting.role_admin_desc'
    },
    {
        key: 'readonly',
        canEdit: false,
        value: '1',
        name: 'user_setting.role_readonly',
        desc: 'user_setting.role_readonly_desc'
    },
    {
        key: 'demo',
        canEdit: true,
        value: '3'
    }
];

export default {
    roles,
    roleLimited,
    roleMapping
};
