"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRouter = exports.useRoute = void 0;
var router_1 = require("@/router/router");
var composition_api_1 = require("@vue/composition-api");
// 与vue3 router保持一致
// 返回的是reactive对象，注意别解构使用，会丢失响应性，要解构使用得自行computed.
function useRoute() {
    var proxy = (0, composition_api_1.getCurrentInstance)().proxy;
    var route = (0, composition_api_1.reactive)(__assign({}, proxy === null || proxy === void 0 ? void 0 : proxy.$route));
    (0, composition_api_1.watch)(function () { return proxy === null || proxy === void 0 ? void 0 : proxy.$route; }, function () {
        if (typeof (proxy === null || proxy === void 0 ? void 0 : proxy.$route) === 'object')
            for (var property in proxy === null || proxy === void 0 ? void 0 : proxy.$route) {
                route[property] = proxy === null || proxy === void 0 ? void 0 : proxy.$route[property];
            }
    });
    return route;
}
exports.useRoute = useRoute;
function useRouter() {
    return router_1.default;
}
exports.useRouter = useRouter;
