




import { defineComponent, onMounted } from '@vue/composition-api';
import { isSingleSpa, sendPostMessage } from '@/common/services/singleSpa.service';
export default defineComponent({
    name: 'Error404',
    setup() {
        onMounted(() => {
            isSingleSpa() ? sendPostMessage('ExperienceReload', '') : window.location.reload();
        });
    }
});
