import store from '@/store';
import i18n from '@assets/i18n';
import uuidUtils from '@common/utils/uuid.utils';
import commonUtils from '@common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';

export default {
    createElement(nodes) {
        var that = this;
        if (typeof nodes === 'string' || typeof nodes === 'number') {
            if (nodes.indexOf('<') !== -1 && /<?\/[a-z]*>/.test(nodes)) {
                const tmp = document.createElement('div');
                tmp.innerHTML = nodes;
                return tmp;
            }
            return document.createTextNode(nodes);
        }
        var element = document.createElement(nodes.tag);
        that.setProps(element, nodes.props);
        var children = nodes.children;
        children && children.map(that.createElement.bind(that)).forEach(element.appendChild.bind(element));
        return element;
    },

    // 给dom元素设置属性
    setProps(element, attributes) {
        for (var key in attributes) {
            element.setAttribute(key, attributes[key]);
        }
    },

    getObjArrIndex(arr, key, value) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][key] === value) {
                return i;
            }
        }
        return -1;
    },

    // http://localhost:3100/v2/homepage-egg?engageName=promo&action=new
    getUrlParams(str) {
        if (str.indexOf('?') === -1) return;
        const targetStr = str.split('?')[1];
        const arrs = targetStr.split('&');
        const o = {};
        arrs.forEach(element => {
            const arr = element.split('=');
            o[arr[0]] = arr[1];
        });
        return o;
    },

    /**
     * @param {*} el 要获取el到body的offsetTop值
     */
    getOffsetTop(el) {
        let top = el.offsetTop;
        while (el.offsetParent !== null && el.offsetParent !== document.body) {
            top += el.offsetParent.offsetTop;
            el = el.offsetParent;
        }
        return top;
    },

    /**
     *
     * @param {*} src 要加载的js文件地址
     * @param {*} callback js文件加载完成后的回调
     */
    getScript(src, callback) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.charset = 'UTF-8';
        script.src = src;
        if (script.addEventListener) {
            script.addEventListener(
                'load',
                function() {
                    callback();
                },
                false
            );
        } else if (script.attachEvent) {
            script.attachEvent('onreadystatechange', function() {
                var target = window.event.srcElement;
                if (target.readyState === 'loaded') {
                    callback();
                }
            });
        }
        document.body.appendChild(script);
    },

    scrollTo(element, to, duration) {
        const start = element.scrollTop;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;

        /**
         * @param {*} t current time
         * @param {*} b start value
         * @param {*} c change in value
         * @param {*} d duration
         */
        const easeInOutQuad = function(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };
        const animateScroll = function() {
            currentTime += increment;
            const val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    },
    /**
     * 数据格式化。
     * 多版本迭代，统一数据结构
     * 接口返回的数据结构可见 ./engage.demo.json
     * 转换后的数据结构可见 ./engage.setting.json
     * For engageVersion 3.0
     */
    formatCampaign(engage) {
        let {
            id,
            name,
            baseURL,
            engageVersion,
            status,
            audiences,
            versions,
            conditions,
            schedule,
            screenshot,
            goals,
            hasVariant,
            hasForm,
            isVersionEvent
        } = engage;

        const audiencesArr =
            (audiences &&
                audiences.map(item => {
                    // 统一属性名称
                    item.audience_id && (item.id = item.audience_id);
                    delete item.audience_id;
                    return item;
                })) ||
            [];

        const versionsArr = versions.map(version => {
            const { id, name, rate, status, controlGroup, terminals } = version;
            const versionHasError = terminals.some(t => {
                const { popup, stickybar } = t || {};
                return (popup || []).some(p => p.popupHasError) || (stickybar || []).some(p => p.popupHasError);
            });
            return {
                versionId: id,
                versionName: name,
                versionRate: Number(rate) * 100,
                versionNameEditing: false,
                versionHasError,
                screenshot: version.screenshot,
                controlGroup,
                status: status === '0',
                terminals
            };
        });
        const conditionsArr = (conditions || []).map(con => {
            let { session, when } = con;
            (when || []).map(page => {
                return Object.assign(page, { uuid: uuidUtils.uuid() });
            });
            return {
                when,
                session
            };
        });

        return {
            id,
            engageVersion,
            name,
            hasForm,
            status,
            baseURL,
            hasVariant,
            isVersionEvent,
            usergroups: audiencesArr,
            conditions: conditionsArr,
            schedule,
            screenshot,
            goals,
            versions: versionsArr
        };
    },

    /**
     *  把前端audiences数组组成后台提交的格式 [{usergroupID:23456456765}]
     * @param {*} audiencesArr
     */
    serializeEngageAudience(audiencesArr) {
        if (!audiencesArr || !Array.isArray(audiencesArr)) return;
        const result = [];
        const allUser = audiencesArr.find(item => item.source === 'default');
        if (audiencesArr.length !== 0) {
            audiencesArr.map(item => {
                (item.usergroupID || item.id) && result.push({ usergroupID: item.usergroupID || item.id });
            });
        }

        return result;
    },
    /**
     * 把前端version数据 转换成后台保存的格式 versions:[{id, name, isSync, ...},{}]
     * @param {*} config
     */
    serializeCampaignVersions(config) {
        const { versions } = config;
        const result = [];
        versions.map(function(v) {
            const { versionId, versionName, versionRate, controlGroup, terminals, screenshot } = v;
            const terminalsConfig = terminals.map(t => {
                const { type, status, terminalStatus, popup, inline, stickybar, code } = t;
                return {
                    type,
                    status: terminalStatus || status,
                    popup,
                    inline,
                    stickybar,
                    code
                };
            });
            result.push({
                id: versionId,
                name: versionName,
                rate: Number(versionRate) / 100,
                status: 1,
                controlGroup,
                screenshot,
                terminals: terminalsConfig
            });
        });
        return result || [];
    },

    /**
     * 把前端的数据结构转化成最终保存的格式 保存engage时的最终结构
     * For engageVersion 3.0
     */
    serializeCampaignPostData(engage, userInfo, replaceId = false) {
        const engageClone = cloneUtils.deep(engage);
        const { sid } = store.getters['main/profileInfo'];

        const {
            id,
            engageVersion,
            name,
            usergroups,
            status,
            baseURL,
            versions,
            conditions,
            schedule,
            goals,
            screenshot,
            hasForm,
            hasVariant,
            isVersionEvent
        } = engageClone;
        return {
            sid,
            name,
            id,
            baseURL,
            hasForm,
            hasVariant,
            isVersionEvent,
            engageVersion,
            abtestType: '',
            status: Number(status),
            usergroups: this.serializeEngageAudience(usergroups),
            schedule,
            screenshot,
            goals: goals?.map(item => {
                const { id, goalID } = item;
                return {
                    goalID: goalID || id
                };
            }),
            conditions,
            versions: this.serializeCampaignVersions(
                {
                    versions,
                    schedule
                },
                replaceId
            )
        };
    },

    /**
     * 重置displayConfig meta中的schedule的信息。
     */
    resetScheduleMeta() {
        return {
            radio1: '1',
            radio2: '1',
            radio3: '1',
            startDate: commonUtils.getTimestamp('start'),
            endDate: commonUtils.getTimestamp('end'),
            time: ['00:00', '23:59'],
            week: [
                {
                    status: true,
                    text: i18n.t('common.week_sun_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_mon_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_tue_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_wed_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_thu_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_fri_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_sat_short')
                }
            ],
            done: false
        };
    }
};
