import ColorPicker from './ColorPicker.vue';
import KitsSlider from './KitsSlider.vue';
import KitsInputNumber from './KitsInputNumber.vue';
import KitsSelect from './KitsSelect.vue';
import KitsIconButton from './KitsIconButton.vue';
import KitsSwitch from './KitsSwitch.vue';
export default [
    {
        type: 'colorPicker',
        component: ColorPicker
    },
    {
        type: 'slider',
        component: KitsSlider
    },
    {
        type: 'inputNumber',
        component: KitsInputNumber
    },
    {
        type: 'select',
        component: KitsSelect
    },
    {
        type: 'iconButton',
        component: KitsIconButton
    },
    {
        type: 'switch',
        component: KitsSwitch
    }
];