import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'graphql',
        method: 'post',
        url: '/graphql',
        isGraphql: false
    }
];

export default resourceWrapper(resources);
