import fieldConfig from './field.config';

function getLabelDom(content) {
    const { labelName } = content.value;
    return `<span>${labelName}</span>`;
}
function getInputModule(content, setting, widgetId, belong) {
    //根据content的不同type生成不同的do
    const { type } = content.value;
    const fieldList = fieldConfig.fieldComponents;
    const { services } = fieldList.find(item => item.code === type);

    const block = {
        blockId: content.code + '-input',
        blockContent: content,
        blockSettings: setting
    };

    return {
        dom: services && services.getHtml(block),
        style: services && services.getStyle(block),
        script: services && services.getScript(block, widgetId, belong)
    };
}
export default {
    getHtml(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const setting = block.blockSettings || {};
        let fieldDom = [];
        if (content.length > 0) {
            content.forEach(item => {
                const { type, labelName } = item.value;
                let labelDom = getLabelDom(item);
                let inputModuleDom = getInputModule(item, setting.inputSettings).dom;
                fieldDom.push(`<div id="${id}-item" data-field-id="${item.value.field_id}" data-field-name="${labelName}" data-field-type="${type}" class="engage-layout engage-form-field">
                    <div id="${id}-label"> ${labelDom} </div>
                    <div id="${id}-input" class="spacing">${inputModuleDom}</div>     
                </div>
                `);
            });
        }

        return `<div id="${id}">
                    ${fieldDom.join('')}        
                </div>`;
    },
    getStyle(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const setting = block.blockSettings;
        let { fontColor, fontSize, fontWeight, layout, spacing, showLabel } = setting;

        let inputModuleStyleList = [];
        content.forEach(item => {
            inputModuleStyleList.push(getInputModule(item, setting.inputSettings).style);
        });
        // console.log('inputModuleStyleList', inputModuleStyleList);
        let layoutStyle = 'flex-direction: row; align-items: center';
        let spacingStyle = `margin-left: ${spacing}px`;
        let labelDisplayStyle = showLabel ? 'display: block' : 'display: none';
        if (layout === 'vertical') {
            layoutStyle = 'display: block;';
            spacingStyle = `margin-top: ${spacing}px`;
        }

        return `#${id} {
            color: ${fontColor};
            font-size: ${fontSize}px;
            font-weight: ${fontWeight};
            
        }
        #${id} .engage-error-tips {
            color: red;
            font-size: 13px;
            font-weight: 300;
         
            width: 100%;
        }
        #${id}-item {
            display: flex;
        }
       
        #${id}-item.engage-layout{
            ${layoutStyle}
        }
        #${id}-item .spacing {
            ${spacingStyle}
        }
        #${id}-label {
            ${layout === 'horizontal' ? 'width: 30%; text-align: right;' : 'width: 100%; text-align: left'};
            ${labelDisplayStyle}
        }
        #${id}-input {
            flex: 1;
        }
        ${inputModuleStyleList.join('')}
        `;
    },
    getScript(block, widgetId, belong) {
        const content = block.blockContent || [];
        const setting = block.blockSettings || {};

        let fieldScript = [];
        let fieldIdList = [];
        content.forEach(item => {
            let { type, isRequired } = item.value;
            let fieldId = item.value.field_id;
            fieldIdList.push({
                fieldId,
                validator: function(val) {
                    if (isRequired && fieldConfig.validator.isRequired(val)) {
                        //TODO 校验重复项 需要调用接口后端校验
                        if (type === 'email') {
                            return fieldConfig.validator.isValidEmail(val);
                        } else if (type === 'phone') {
                            return fieldConfig.validator.isValidPhone(val);
                        }
                    } else return false;
                }
            });
            fieldScript.push(getInputModule(item, setting.inputSettings, widgetId, belong).script);
        });
        return `${fieldScript.join('\n')}`;
    }
};
