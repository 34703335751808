import { widgetWrapPadding } from '../../configs/creative.config';

export default {
    getHtml(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const { layout } = block.blockSettings;
        let slideHTML = '';
        content.forEach(item => {
            const { img, imgName, text, width, height } = item;
            const reg = /\.(gif|jpg|jpeg|png|gif|jpg|png)$/;
            const name = imgName && imgName.replace(reg, '');
            const imgAlt = name ? `alt=${name}` : '';
            let leftCellDom = img
                ? `<img ${imgAlt} src="${img}" width="${width}" height="${height}">`
                : '';
            let rightCellDom = text;
            if (['imgRight', 'imgDown'].includes(layout)) {
                rightCellDom = leftCellDom;
                leftCellDom = text;
            }
            slideHTML += `<div class="swiper-slide">
                <div class="swiper-slide-cell-left">${leftCellDom}</div>
                <div class="swiper-slide-cell-right">${rightCellDom}</div>
            </div>`;
        });

        return `<div id="${id}" data-loading="true" data-engage-block="slide">
            <div id="${id}-loading"></div>
            <div class="swiper-container">
                <div class="swiper-wrapper">${slideHTML}</div>
                <div id="${id}-pagination" class="swiper-pagination"></div>
            </div>
            <div id="${id}-next">
                <svg x="0px" y="0px" viewBox="0 0 18 18"  xml:space="preserve"><rect style="fill:none;" width="18" height="18"/><g><path d="M6,15.9c-0.2,0-0.5-0.1-0.6-0.3c-0.4-0.4-0.4-0.9,0-1.3L10.7,9L5.4,3.6C5,3.3,5,2.7,5.4,2.4C5.7,2,6.3,2,6.6,2.4L13.3,9l-6.6,6.6C6.5,15.8,6.2,15.9,6,15.9z"/></g></svg>
            </div>
            <div id="${id}-prev">
                <svg x="0px" y="0px" viewBox="0 0 18 18" xml:space="preserve"><rect style="fill:none;" width="18" height="18"/><g><path d="M11.5,15.6L4.9,9l6.7-6.6c0.3-0.4,0.9-0.4,1.2,0c0.4,0.3,0.4,0.9,0,1.2L7.5,9l5.3,5.3c0.4,0.4,0.4,0.9,0,1.3c-0.1,0.2-0.4,0.3-0.6,0.3S11.7,15.8,11.5,15.6z"/></g></svg>
            </div>
        </div>`;
    },

    getStyle(block, { paddingLeft = widgetWrapPadding.left, width } = {}) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        const { alignment, layout, switchStyle } = settings;
        const blockMaxWidth = `max-width: ${
            width === '' || width === 'auto' ? '400px' : width + 'px'
        };`;
        const spacing = 12;
        let flexStyle = `
                display: flex;
                display: -webkit-flex;
                display: -ms-flexbox;
                flex-direction: row;
            `;

        let leftCellStyle = `padding-right: ${spacing}px;`;
        let rightCellStyle = `
            flex: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: ${alignment || 'left'};
        `;
        if (layout === 'imgRight') {
            leftCellStyle = rightCellStyle;
            rightCellStyle = `padding-left: ${spacing}px;`;
        } else if (layout === 'imgUp') {
            flexStyle = '';
            leftCellStyle = `text-align: center; margin-bottom: ${spacing}px;`;
            rightCellStyle = `text-align: ${alignment || 'left'}`;
        } else if (layout === 'imgDown') {
            flexStyle = '';
            leftCellStyle = `text-align: ${alignment || 'left'}`;
            rightCellStyle = `text-align: center;  margin-top: ${spacing}px;`;
        }

        let blockPadding = 'padding: 0 60px;';
        let paginationStyle = `
            #${id}-next,
            #${id}-prev {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 20px;
                margin-top: -26px;
                cursor: pointer;
                outline: none;
            }
            #${id}-next {
                left: auto;
                right: 20px;
            }
        `;
        if (switchStyle === 'bottom') {
            blockPadding = `padding: 0 ${paddingLeft}px 0 ${paddingLeft}px;`;
            paginationStyle = `
                #${id}-next,
                #${id}-prev {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 50%;
                    bottom: 2px;
                    z-index: 1;
                    margin-left: -58px;
                    cursor: pointer;
                    outline: none;
                }
                #${id}-next {
                    margin-left: 46px;
                }
            `;
        }

        return `
            #${id} {
                ${blockMaxWidth}
                ${blockPadding}
                position: relative;
            }
            #${id}[data-loading] {
                min-height: 40px;
            }
            #${id}[data-loading] #${id}-loading {
                display: block;
            }
            #${id}[data-loading] .swiper-container {
                display: none;
            }
            #${id}-loading {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 100;
                display: none;
                width: 100%;
                height: 100%;
                background: #fff;
            }
            #${id}-loading:after {
                content: 'loading...';
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                margin: -10px 0 0 -50px;
            }
            #${id} .swiper-container {
                padding-bottom: 32px;
            }
            #${id} .swiper-slide {
                ${flexStyle}
                overflow: hidden;
            }
            #${id} .swiper-slide p {
                padding: 0;
                margin: 0;
            }
            #${id} .swiper-slide-cell-left {
                ${leftCellStyle}
            }
            #${id} .swiper-slide-cell-right {
                ${rightCellStyle}
            }
            #${id}-pagination {
                bottom: 0;
            }
            #${id}-pagination .swiper-pagination-bullet {
                height: 6px;
                width: 6px;
                background-color: #C1C7D0;
            }
            #${id}-pagination .swiper-pagination-bullet-active {
                background-color: #8993A4;
            }
            ${paginationStyle}
            #${id}-next svg,
            #${id}-prev svg {
                width: 100%;
                height: 100%;
                fill: #69768d;
            }
            #${id} .swiper-button-disabled svg {
                fill: #dbdee3;
                pointer-events: none;
            }
        `;
    },

    /**
     * get block script
     * @param {String} belong: ['dialog', 'thumbnail', 'editor', 'normal']
     */
    getScript(block, widgetId, belong) {
        const blockId = block.blockId;
        const { autoPlay, playSpeed, switchStyle } = block.blockSettings || {};
        const { activeContentIndex } = block.blockEditorState || {
            activeContentIndex: 0
        };
        const paginationType = switchStyle === 'side' ? 'bullets' : 'fraction';
        const optionsAutoplay =
            ['thumbnail', 'editor'].includes(belong) ||
            !autoPlay ||
            playSpeed === 0
                ? false
                : { delay: playSpeed * 1000, disableOnInteraction: false };
        const initialSlide = ['editor'].includes(belong)
            ? activeContentIndex
            : 0;
        const swiperElParent = ['dialog'].includes(belong)
            ? '[data-preview-type="dialog"] '
            : '';
        const config = {
            widgetId,
            blockId,
            blockWrapperSelector: `${swiperElParent}#${blockId}`,
            swiperEl: `${swiperElParent}#${blockId} .swiper-container`,
            swiperOpt: {
                loop: true,
                initialSlide,
                pagination: {
                    el: `${swiperElParent}#${blockId}-pagination`,
                    type: paginationType,
                    clickable: true
                },
                navigation: {
                    nextEl: `${swiperElParent}#${blockId}-next`,
                    prevEl: `${swiperElParent}#${blockId}-prev`
                },
                autoplay: optionsAutoplay
            }
        };
        const mountedEvent = `function() {
            this.ready = false;
            var self = this,
                ls = lj = false,
                r = function() {
                    if(!ls || !lj) return;
                    var wrapper = document.querySelector(config.blockWrapperSelector);
                    wrapper && wrapper.removeAttribute('data-loading');
                    self.slider = new Swiper(config.swiperEl, config.swiperOpt);
                };
            if ("undefined" == typeof Swiper) {
                var u = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.1/js/swiper.min.js",
                    c = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.1/css/swiper.css", 
                    s = document.createElement('link'),
                    n = document.createElement("script");
                s.type = "text/css", s.rel = "stylesheet", s.href = c, s.onload = function(){ls = true, r()}, document.head.appendChild(s);
                n.src = u, n.onload = function() {lj = true, r()}, document.head.appendChild(n);
            } else (ls = lj = true, r());
        }`;
        const unmountedEvent = `function() {
            if (this.slider) {
                var isArray = this.slider instanceof Array;
                if (isArray) {
                    for(var i = 0; i < this.slider.length; i++) {
                        this.slider[i].destroy && this.slider[i].destroy(true, true);
                    }
                } else {
                    this.slider.destroy && this.slider.destroy(true, true)
                }
                this.slider = null
            }
        }`;
        const updatedEvent = `function() {
            if (this.slider) {
                var isArray = this.slider instanceof Array;
                if (isArray) {
                    for(var i = 0; i < this.slider.length; i++) {
                        this.slider[i].update && this.slider[i].update();
                    }
                } else {
                    this.slider.update && this.slider.update()
                }
            }
        }`;

        return `<script type="text/javascript">
(function(config) {
    window.ptEngage = window.ptEngage || {};
    window.ptEngage[config.widgetId] = window.ptEngage[config.widgetId] || [];
    var index = window.ptEngage[config.widgetId].findIndex((o) => o.blockId === config.blockId);
    index !== -1 && window.ptEngage[config.widgetId].splice(index, 1);
    window.ptEngage[config.widgetId].push({
        blockId: config.blockId,
        mounted: ${mountedEvent},
        unmounted: ${unmountedEvent},
        updated: ${updatedEvent}
    })
})(${JSON.stringify(config)});
</script>`;
    }
};
