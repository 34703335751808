





import Vue, { VNode } from 'vue';
import { defineComponent, watch, nextTick, onMounted, onUnmounted } from '@vue/composition-api';
import { start, stop, GuideOptions } from '../../libs/guide';
import { tooltipEle } from '../../libs/guide/tooltip';
import { isDOMElement } from '../../utils/isDOMElement';
import debounce from '../../utils/throttle-debounce/debounce';

export interface Props {
    open: Boolean;
    targetEle: string | HTMLElement | null;
    interactive: GuideOptions['interactive'];
    clickable: GuideOptions['clickable'];
    highlight: GuideOptions['highlight'];
    highlightStyle: GuideOptions['highlightStyle'];
    maskStyle: GuideOptions['maskStyle'];
    clickMaskToStop: GuideOptions['clickMaskToStop'];
    tooltipConfig: GuideOptions['tooltipConfig'] & { isText3Btn: boolean };
    whiteList: GuideOptions['whiteList'];
    lockScroll: GuideOptions['lockScroll'];
    insertDOM: GuideOptions['insertDOM'];
}

const props = {
    open: Boolean,
    targetEle: {
        type: [String, Object], // 聚焦元素，如果有tooltip将根据其自动定位
        default: undefined
    },
    interactive: {
        // 蒙层下元素是否可交互
        type: Boolean,
        default: true
    },
    clickable: {
        type: Boolean, // 蒙层下元素是否可点击，不可交互则必不可点击
        default: false
    },
    highlight: {
        // 是否高亮目标元素
        type: Boolean,
        default: true
    },
    highlightStyle: Object, // 高亮区域样式
    maskStyle: Object,
    clickMaskToStop: {
        type: Boolean,
        default: false
    },
    tooltipConfig: Object,
    whiteList: Array, // DOM元素或者string
    lockScroll: {
        type: Boolean,
        default: false
    },
    insertDOM: [String, Object]
};

type EmitFn = (evt?: MouseEvent) => void;

export default defineComponent({
    name: 'PtGuide',
    props,
    emits: ['clickTarget', 'clickMask', 'clickWhiteList', 'clickTooltipButton', 'start', 'stop'],
    setup(props: Props, { emit }) {
        let targetEle: HTMLElement | null = null;
        const handleClickTarget: EmitFn = event => emit('clickTarget', event);
        const handleClickMask: EmitFn = event => emit('clickMask', event);
        const handleClickWhiteList: EmitFn = event => emit('clickWhiteList', event);
        const handleClickTooltipButton: EmitFn = event => emit('clickTooltipButton', event);
        const handleStart: EmitFn = event => emit('start', event);
        const handleStop: EmitFn = event => emit('stop', event);

        let hasUnMount = false;
        const getStart = (fromResize: boolean) => {
            nextTick(() => {
                if (props.open && !hasUnMount) {
                    targetEle =
                        typeof props.targetEle === 'string' ? document.querySelector(props.targetEle) : props.targetEle;
                    if (!isDOMElement(targetEle)) targetEle = null;
                    start(
                        targetEle,
                        {
                            interactive: props.interactive,
                            clickable: props.clickable,
                            highlight: props.highlight,
                            highlightStyle: props.highlightStyle,
                            maskStyle: props.maskStyle,
                            clickMaskToStop: props.clickMaskToStop,
                            onClickTarget: handleClickTarget,
                            onClickMask: handleClickMask,
                            onClickWhiteList: handleClickWhiteList,
                            onStart: handleStart,
                            onStop: handleStop,
                            tooltipConfig: !props.tooltipConfig
                                ? undefined
                                : ({
                                      ...props.tooltipConfig,
                                      onClickTooltipButton:
                                          props?.tooltipConfig?.isText3Btn ?? true
                                              ? handleClickTooltipButton
                                              : undefined
                                  } as Props['tooltipConfig']),
                            whiteList: props.whiteList,
                            lockScroll: props.lockScroll,
                            insertDOM: props.insertDOM
                        },
                        fromResize
                    );
                }
            });
        };

        const _debounceStart = debounce(125, false, () => getStart(true)) as () => void;

        onMounted(() => {
            window.addEventListener('resize', _debounceStart);
        });

        onUnmounted(() => {
            hasUnMount = true;
            window.removeEventListener('resize', _debounceStart);
            stop();
        });

        watch(
            () => [props, props.open, props.tooltipConfig, props.targetEle],
            () => {
                if (!props.open || hasUnMount) return stop();
                getStart(false);
            },
            { immediate: true }
        );
    }
});
