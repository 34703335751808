<template lang="html">
<div class="pt-list" :style="{height: wrapStyle.height}">
    <pt-scrollbar ref="scrollbar" @onScrollY="handleScroll">
        <div class="pt-list__wrap" ref="list" :style="wrapStyle">
            <template v-for="(item, $index) in previewList">
                <slot v-bind:item="item" v-bind:index="$index" >
                    <div class="pt-list__item" @click.stop="choose(item)">
                        {{ item[labelKey] }}
                    </div>
                </slot>
            </template>
        </div>
    </pt-scrollbar>
</div>
</template>

<script>
export default {
    name: 'ptList',

    props: {
        list: {
            type: Array,
            required: true,
            default: []
        },
        rowHeight: {
            type: Number,
            default: 44
        },
        valueKey: {
            type: String,
            default: 'code'
        },
        labelKey: {
            type: String,
            default: 'name'
        },
        maxLength: {
            type: Number,
            default: 10
        },
        height: {
            type: Number
        }
    },

    computed: {
        wrapStyle() {
            return {
                paddingTop: this.lineTopHeight + 'px',
                paddingBottom: this.lineBottomHeight + 'px',
                height: this.height ? this.height + 'px' : 'auto'
            };
        }
    },
    data() {
        return {
            lastScrollTop: null,
            distance: 44,
            lineTopHeight: 0,
            lineBottomHeight: 0,
            previewList: [],
            displayCount: 0
        };
    },

    mounted() {
        this.initData();
        this.handleScroll();
    },
    watch: {
        list() {
            this.initData();
            this.handleScroll();
        }
    },
    methods: {
        initData() {
            let _contentHeight =
                this.$el.offsetHeight ||
                (this.list.length > this.maxLength
                    ? this.maxLength
                    : this.list.length) * this.rowHeight;

            // init all data
            this._rowsInWindow = Math.ceil(_contentHeight / this.rowHeight);
            this._above = this._rowsInWindow * 2;
            this._below = this._rowsInWindow;
            this._max = this._rowsInWindow * this.rowHeight;

            this.lastScrollTop = null;
            this.previewList = [];
            this.displayCount = 0;
        },

        handleScroll() {
            let _scrollTop = this.getScrollTop();
            let _height = this.$refs.list.offsetHeight;
            let _contentHeight = this.$el.offsetHeight;

            // Counts the number of rows on the current screen
            if (
                _scrollTop / this.rowHeight -
                    Math.floor(_scrollTop / this.rowHeight) >
                0.5
            ) {
                this.displayCount = Math.ceil(_scrollTop / this.rowHeight);
            } else {
                this.displayCount = Math.floor(_scrollTop / this.rowHeight);
            }

            // if the maximum height is exceeded, reset the previewList
            if (
                this.lastScrollTop === null ||
                Math.abs(_scrollTop - this.lastScrollTop) > this._max
            ) {
                this.lastScrollTop = _scrollTop;
            } else {
                return;
            }

            // get from and to count
            let _from = parseInt(_scrollTop / this.rowHeight) - this._above;
            if (_from < 0) {
                _from = 0;
            }
            let _to = _from + this._above + this._below + this._rowsInWindow;
            if (_to > this.list.length) {
                _to = this.list.length;
            }
            this.from = _from;
            this.to = _to;

            // set top height and bottom height
            this.lineTopHeight = _from * this.rowHeight;
            this.lineBottomHeight = (this.list.length - _to) * this.rowHeight;

            this.resetPreviewList(_from, _to);
        },

        getScrollTop() {
            return this.$refs.scrollbar && this.$refs.scrollbar.getScrollTop();
        },

        resetPreviewList(from, to) {
            this.previewList = this.list.slice(from, to);
        },

        choose(item) {
            this.$emit('change', item);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/import.scss';

.pt-list {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__wrap {
        margin: 0;
        padding: 0;
    }

    &__item {
        height: 26px;
        position: relative;
        color: #999;
        line-height: 26px;
        cursor: pointer;
        padding-left: 16px;
        margin-bottom: 2px;
        @extend %text-ellipsis;
    }

    /***** 外部样式修改 *******/
    /* &.filter-multiple-list {
        height: 230px;
    } */
}
</style>

