<template>
    <div class="block-settings__item">
        <div class="block-settings__item-title">{{ title }}</div>
        <div class="block-settings__item-container">
            <el-select size="small" v-model="currentValue" @change="handleChange">
                <el-option
                    v-for="item in options"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                ></el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
export default {
    name: 'KitsSelect',
    props: {
        title: String,
        target: String,
        value: {
            required: true
        },
        options: Array
    },
    data() {
        return {
            currentValue: this.value
        };
    },
    methods: {
        handleChange(val) {
            // console.log('color picker change', val);
            this.$emit('change', val, this.target);
        }
    }
};
</script>