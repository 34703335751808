"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useI18n = void 0;
var composition_api_1 = require("@vue/composition-api");
function useI18n() {
    var proxy = (0, composition_api_1.getCurrentInstance)().proxy;
    var locale = proxy.$locale.current();
    return {
        t: proxy === null || proxy === void 0 ? void 0 : proxy.$t.bind(proxy),
        locale: locale
    };
}
exports.useI18n = useI18n;
