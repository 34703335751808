import imageConfig from './image.config';
import { isNullOrUndefined } from '../../utils/creative.util';
import { widgetWrapPadding } from '../../configs/creative.config';

/**
 * Style 书写顺序
 * 1. 定位属性:
 *  position, display, float, left, top, right, bottom, overflow, clear, z-index.
 * 2. 自身属性:
 *  width, height, padding, border, margin, background
 * 3. 文字样式:
 *  font-family, font-size, font-style, font-weight, font-varient, color
 * 4. 文本属性:
 *  text-align, vertical-align, text-wrap, text-transform, text-indent,text-decoration, letter-spacing, word-spacing, white-space, text-overflow
 * 5. css3中新增属性:
 *  content, box-shadow, border-radius, transform
 */

function getImageDom(content) {
    const { img, imgName } = content.value;
    const {
        enable,
        type,
        url,
        openNewTab,
        tel,
        gotoWidget
    } = content.value.action;
    const target = openNewTab ? 'target="_blank"' : '';
    const reg = /\.(gif|jpg|jpeg|png|gif|jpg|png)$/;
    const name = imgName && imgName.replace(reg, '');
    const imgAlt = name ? `alt=${name}` : '';
    const imgDom = img
        ? `<img ${imgAlt} src="${img || imageConfig.defaultImage}">`
        : '';

    let html = imgDom;
    if (enable) {
        switch (type) {
            case 'url':
                html = `<a href="${url}" ${target} data-engage-action-click="true" data-engage-block="button-url">${imgDom}</a>`;
                break;
            case 'gotoWidget':
                html = `<a href="javascript:;" data-engage-action-click="true" data-engage-block="button-goto-widget" data-goto-widget="${gotoWidget}">${imgDom}</a>`;
                break;
            case 'tel':
                html = `<a href="tel:${tel}" data-engage-action-click="true" data-engage-block="button-tel">${imgDom}</a>`;
                break;
            default:
                html = '';
                break;
        }
    }
    return html;
}

function getImageStyle(content) {
    const { width, height, borderRadius } = content.value;
    return `
        width: ${width}px;
        max-width: ${width}px;
        height: ${height}px;
        max-height: ${height}px;
        border-radius: ${borderRadius}px;
    `;
}

export default {
    getHtml(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const hasMultiple = imageConfig.hasMultiple(block);
        const leftCellDom = getImageDom(content[0]);

        if (hasMultiple) {
            const rightCellDom = getImageDom(content[1]);
            return `
                <div class="clearfix" id="${id}" data-engage-block="image">
                    <div id="${id}-cell-left">${leftCellDom}</div>
                    <div id="${id}-cell-spacing"></div>
                    <div id="${id}-cell-right">${rightCellDom}</div>
                </div>
            `;
        }
        return `<div id="${id}" data-engage-block="image">${leftCellDom}</div>`;
    },

    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const settings = block.blockSettings || {};
        const hasMultiple = imageConfig.hasMultiple(block);
        const leftStyle = getImageStyle(content[0]);
        let { backgroundColor, marginTop, marginBottom, noPadding } = settings;
        const padding = noPadding ? 0 : `0 ${paddingLeft}px 0 ${paddingLeft}px`;
        if (isNullOrUndefined(marginTop)) marginTop = 0;
        if (isNullOrUndefined(marginBottom)) marginBottom = 0;
        if (isNullOrUndefined(backgroundColor)) backgroundColor = 'transparent';

        if (hasMultiple) {
            const { layout, spacing } = settings;
            const rightStyle = getImageStyle(content[1]);

            if (layout === 'vertical') {
                return `
                    #${id} {
                        text-align: center;
                        line-height: 0;
                        padding: ${padding};
                        margin: ${marginTop}px 0 ${marginBottom}px;
                        background-color: ${backgroundColor};
                    }
                    #${id}-cell-left img {${leftStyle}}
                    #${id}-cell-spacing {
                        width: 100%;
                        height: ${spacing}px;
                        overflow: hidden;
                    }
                    #${id}-cell-right img {${rightStyle}}
                `;
            } else {
                return `
                    #${id} {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 0;
                        padding: ${padding};
                        margin: ${marginTop}px 0 ${marginBottom}px;
                        background-color: ${backgroundColor};
                    }
                    #${id}-cell-left {float: left;}
                    #${id}-cell-left img {${leftStyle}}
                    #${id}-cell-spacing {
                        float: left;
                        width: ${spacing}px;
                        height: 1px;
                        overflow: hidden;
                    }
                    #${id}-cell-right {float: left;}
                    #${id}-cell-right img {${rightStyle}}
                `;
            }
        }
        return `
            #${id} {
                text-align: center;
                padding: ${padding};
                margin: ${marginTop}px 0 ${marginBottom}px;
                background-color: ${backgroundColor};
                line-height: 0;
            }
            #${id} img {${leftStyle}}
        `;
    }
};
