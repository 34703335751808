const authTypeList = [
    {
        type: 'CREATE_USER_GROUP',
        key: 'userGroups',
        editType: 'CREATE',
        title: 'plan.usergroup_limit',
        desc: 'plan.usergroup_limit_desc'
    },
    {
        type: 'CREATE_CAMPAIGN',
        key: 'campaigns',
        editType: 'CREATE',
        title: 'plan.campaign_lmit',
        desc: 'plan.campaign_lmit_desc'
    },
    {
        type: 'CREATE_GOAL',
        key: 'goals',
        editType: 'CREATE',
        title: 'plan.goal_limit',
        desc: 'plan.goal_limit_desc'
    },
    {
        type: 'EDIT_USER_GROUP',
        key: 'userGroups',
        editType: 'EDIT',
        title: 'plan.usergroup_limit',
        desc: 'plan.usergroup_limit_edit_desc'
    },
    {
        type: 'EDIT_CAMPAIGN',
        key: 'campaigns',
        editType: 'EDIT',
        title: 'plan.campaign_lmit',
        desc: 'plan.campaign_lmit_edit_desc'
    },
    {
        type: 'EDIT_GOAL',
        key: 'goals',
        editType: 'EDIT',
        title: 'plan.goal_limit',
        desc: 'plan.goal_limit_edit_desc'
    },
    {
        type: 'USE_AB_TEST',
        key: 'useABTest',
        title: 'plan.abtest_limit',
        desc: 'plan.abtest_limit_desc'
    },
    {
        type: 'USE_CSV_DOWNLOAD',
        key: 'useCSVDownload',
        title: 'plan.csv_limit',
        desc: 'plan.csv_limit_desc'
    },
    {
        type: 'USE_AI',
        key: 'useAI',
        title: 'Contact Us to Unlock AI',
        desc: 'You can create up to 3 campaign on Free Plan. Please contact us to create more user groups…'
    },
    {
        type: 'USE_CODE_MODE',
        key: 'useCodeMode',
        title: 'plan.codemode_limit',
        desc: 'plan.codemode_limit_desc'
    }
];
export default {
    authTypeList
};
