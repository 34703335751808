"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var base_config_1 = require("@/common/configs/base.config");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'LazyloadCard',
    props: ['intersectionRatio', 'customStyle', 'reload'],
    setup: function (props, _a) {
        var emit = _a.emit;
        var lazyCardRef = (0, composition_api_1.ref)();
        var observer = (0, composition_api_1.ref)();
        var intersectionHandler = function (entries) {
            var _a;
            if ((_a = entries[0]) === null || _a === void 0 ? void 0 : _a.isIntersecting) {
                emit('show');
                unObserver();
            }
        };
        var unObserver = function () {
            lazyCardRef.value && observer.value && observer.value.unobserve(lazyCardRef.value);
        };
        var init = function () {
            try {
                observer.value = new window.IntersectionObserver(intersectionHandler, {
                    threshold: props.intersectionRatio || base_config_1.default.COMMON_INTERSECTION_RATIO
                });
                lazyCardRef.value && observer.value.observe(lazyCardRef.value);
            }
            catch (e) {
                console.log(e);
                // 不支持InterSectionObserve的直接调用数据
                if (window.requestAnimationFrame) {
                    window.requestAnimationFrame(function () {
                        emit('show');
                    });
                }
                else {
                    emit('show');
                }
            }
        };
        (0, composition_api_1.onMounted)(function () {
            init();
        });
        (0, composition_api_1.onUnmounted)(function () {
            unObserver();
        });
        // 重新进行监听
        (0, composition_api_1.watch)(function () { return props.reload; }, function () {
            if (props.reload) {
                init();
            }
        });
        return {
            lazyCardRef: lazyCardRef,
            props: props
        };
    }
});
