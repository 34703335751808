import i18n from '@assets/i18n';
import uuidUtils from '@common/utils/uuid.utils';
import cloneUtils from '@/common/utils/clone.utils';
import InputConfig from '../../blocks/input/input.config';
import InputServices from '../../blocks/input/input.services';

const fieldDefaultContent = {
    type: 'name', //field type:  [email, name, phone, shortText, longText, dropdown, policy]
    labelName: 'Field',
    placeholder: '',
    isRequired: true,
    allowDuplicate: true,
    allowConnect: false, //是否将该属性回传
    connectProperty: '', //该字段会被回传到哪个属性
    lineNumber: 2, //文本行数,type为longText的时候有效
    dropdownOptions: '', //下拉菜单options id, type为dropdown时有效
    policyText: '' //policy内容，type为 policy时有效
};
const fieldComponents = [
    {
        code: 'email',
        name: i18n.t('creative_editor.field_email'),
        icon: 'email',
        config: InputConfig,
        services: InputServices
    },
    {
        code: 'name',
        name: i18n.t('creative_editor.field_name'),
        icon: 'name',
        config: InputConfig,
        services: InputServices
    },
    {
        code: 'phone',
        name: i18n.t('creative_editor.field_phone'),
        icon: 'phone',
        config: InputConfig,
        services: InputServices
    },
    {
        code: 'shortText',
        name: i18n.t('creative_editor.field_shorttext'),
        icon: 'shorttext',
        divided: true,
        config: InputConfig,
        services: InputServices
    },
    {
        code: 'longText',
        name: i18n.t('creative_editor.field_longtext'),
        icon: 'longtext',
        config: InputConfig,
        services: InputServices
    },
    {
        code: 'dropdown',
        name: i18n.t('creative_editor.field_dropdown'),
        icon: 'dropdown',
        config: InputConfig,
        services: InputServices
    }
];
const blockContentValidatorFun = function(contentValue, widgetIndex) {
    const { allowConnect, connectProperty } = contentValue;

    let invalid = false;
    let invalidRule = '';
    console.log('blockContentValidatorFun field', contentValue, widgetIndex);
    if (allowConnect && !connectProperty) {
        invalid = true;
        invalidRule = 'Please input a user property';
    }
    return {
        invalid,
        invalidRule
    };
};
export default {
    defaultContent: cloneUtils.deep(fieldDefaultContent),
    blockContentValidatorFun,
    spaceingOptions: {
        min: 0,
        max: 300
    },
    fontSizeOptions: {
        min: 10,
        max: 30
    },
    fontWeightOptions: [
        {
            code: 'normal',
            name: i18n.t('creative_editor.font_normal')
        },
        {
            code: 'bold',
            name: i18n.t('creative_editor.font_bold')
        }
    ],
    layoutOptions: [
        {
            code: 'horizontal',
            icon: 'button-horizontal',
            default: true
        },
        {
            code: 'vertical',
            icon: 'button-vertical'
        }
    ],
    lineNumberOptions: [
        {
            code: '2',
            name: '2'
        },
        {
            code: '3',
            name: '3'
        },
        {
            code: '4',
            name: '4'
        },
        {
            code: '5',
            name: '5'
        }
    ],
    fieldComponents: fieldComponents,
    content: [
        {
            code: `field_${uuidUtils.uuid_8bit()}`,
            name: 'field',
            fieldHasError: false,
            value: Object.assign(cloneUtils.deep(fieldDefaultContent), {
                type: 'email',
                labelName: i18n.t('creative_editor.field_email'),
                field_id: 'email_' + uuidUtils.uuid_8bit(),
                allowDuplicate: false,
                connectProperty: 'email'
            }),
            default: true
        },
        {
            code: `field_${uuidUtils.uuid_8bit()}`,
            name: 'field',
            fieldHasError: false,
            value: Object.assign(cloneUtils.deep(fieldDefaultContent), {
                type: 'name',
                labelName: i18n.t('creative_editor.field_name'),
                field_id: 'name_' + uuidUtils.uuid_8bit(),
                isRequired: false,
                connectProperty: 'name'
            })
        }
    ],
    settings: {
        showLabel: true,
        spacing: 4,
        layout: 'vertical',
        fontSize: 14,
        fontColor: '#091e42',
        fontWeight: 'normal',
        inputSettings: InputConfig.settings
    }
};
