<template>
    <pt-input
        ref="input"
        type="text"
        prefixIcon="pt-icon--search"
        class="pt-search"
        :placeholder="placeholder"
        :clearButton="true"
        :prefix="true"
        :size="size"
        :name="name"
        :inputStyle="inputStyle"
        :autofocus="autofocus"
        :value="searchKey"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
    ></pt-input>
</template>

<script type="text/jsx">
import { throttle } from '../../../utils/util.utils';
import cloneUtils from '../../../utils/clone.utils';
import regUtils from '../../../utils/reg.utils';

export default {
    name: 'ptSearch',

    props: {
        // 搜索类型: search || searchbar
        type: {
            type: String,
            default: 'search',
            enum: ['search', 'searchbar']
        },
        list: {
            type: Array,
            required: true
        },
        name: [String, Number], //input 原生name标签

        // this.attrName如果想搜索多个字段，支持传入数组的形式
        // :attrName="['name','description']"
        // 如果要访问对象下一层级的属性，可以通过.来获取，比如['name','info.name']
        // 如果遇到Array类型，可以使用.[]搜索全部项，比如['fields.[].name']
        attrName: {
            type: [Array, String],
            required: false,
            default: 'name'
        },
        attrOtherName: {
            //解决搜索条件有两个字段的情况
            type: String,
            required: false,
            default: 'name'
        },
        attrChild: {
            type: String,
            required: false,
            default: 'child'
        },
        size: {
            type: String,
            default: 'default',
            enum: ['default', 'big', 'large', 'xlarge']
        }, //同ptinput
        //背景 white/black 默认white
        theme: String,
        autofocus: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Search'
        }
    },

    mounted() {
        if (this.autofocus) {
            this.$el.querySelector('input').focus();
        }
    },

    data() {
        return {
            isActive: false,
            focus: false,
            hasInput: false,
            model: cloneUtils.deep(this.list),
            searchKey: this.value
        };
    },

    computed: {
        inputStyle() {
            if (this.type === 'searchbar') return 'underline';
            else return 'border';
        },
        // 正则转义
        searchModel() {
            return regUtils.escapeRegExp(this.searchKey);
        }
    },

    methods: {
        onInput(val) {
            console.log('val', val);
            this.hasInput = val !== '';
            this.searchKey = val;
        },

        onFocus(e) {
            this.focus = true;
            this.$emit('focus', e);
        },
        onBlur(e) {
            this.focus = false;
            this.$emit('blur', e);
        },

        hideInput() {
            this.isActive = this.hasInput;
        },

        clickSearchIcon() {
            this.isActive = true;
            this.$el.querySelector('input').focus();
        },

        clearStart() {
            this.$el.classList.add('on-focus');
        },

        clearEnd() {
            this.$el.classList.remove('on-focus');
            this.searchKey = '';
            this.hasInput = false;
            this.clickSearchIcon();
        },

        search(treeList, searchKey) {
            return treeList.reduce((prev, curr, index) => {
                // 如果有子元素,则继续进行walk
                let children = curr[this.attrChild];
                if (children && children.length) {
                    let filterRs = this.search(children, searchKey);
                    if (filterRs.length) {
                        let copy = Object.assign({}, curr);
                        copy[this.attrChild] = filterRs;
                        prev.push(copy);
                        return prev;
                    }
                }
                // 否则进行匹配
                var reg = new RegExp(searchKey, 'gi');
                if (this.attrName instanceof Array) {
                    for (let i = 0; i < this.attrName.length; i++) {
                        let attrItem = this.attrName[i];
                        let matchAttr = curr[attrItem];
                        if (curr[attrItem] === undefined) {
                            attrItem = attrItem.split('.');
                            let searchStep = [curr];
                            attrItem.forEach(item => {
                                let newSearchStep = [];
                                searchStep.forEach(item2 => {
                                    if (item === '[]') {
                                        if (item2 instanceof Array) {
                                            newSearchStep.push(...item2);
                                        }
                                    } else {
                                        if (item2[item] !== undefined) {
                                            newSearchStep.push(item2[item]);
                                        }
                                    }
                                });
                                searchStep = newSearchStep;
                            });
                            let isFind = false;
                            for (let j = 0; j < searchStep.length; j++) {
                                if (reg.test(searchStep[j])) {
                                    isFind = true;
                                    break;
                                }
                            }
                            if (isFind) {
                                prev.push(Object.assign({}, curr));
                                break;
                            }
                        } else {
                            if (reg.test(matchAttr)) {
                                prev.push(Object.assign({}, curr));
                                break;
                            }
                        }
                    }
                } else {
                    if (reg.test(curr[this.attrName]) || reg.test(curr[this.attrOtherName])) {
                        prev.push(Object.assign({}, curr));
                    }
                }
                return prev;
            }, []);
        },

        doSearch() {
            var throttleFn = throttle(() => {
                let data = cloneUtils.deep(this.list);
                this.model = this.searchModel ? this.search(data, this.searchModel) : data;

                // 参数分别为过滤后的数据，search原始数据，将search原始数据进行正则转义的数据
                this.$emit('change', this.model, this.searchKey, this.searchModel);
            });

            throttleFn();
        }
    },

    watch: {
        searchKey(val) {
            this.doSearch();
            this.$emit('input', val);
        },

        list() {
            this.model = cloneUtils.deep(this.list);
        },

        autofocus(n, o) {
            this.$el.querySelector('input').focus();
        }
    }
};
</script>

<style lang="scss">
.pt-search {
    &.has-content {
        width: 100%;
    }
}
</style>