import i18n from '@assets/i18n';
/**
 * node节点是否在可见区域内
 * @param nodeId
 * @returns {boolean}
 */
const domIsIn = node => {
    // 如果没有找到节点
    if (node == null) return false;

    // 窗口高度
    const clientHeight = document.body.offsetHeight;
    // 节点相对于视口的位置
    const nodeClientRect = node.getBoundingClientRect();
    return (
        (nodeClientRect.top > 0 && nodeClientRect.top < clientHeight) ||
        (nodeClientRect.bottom > 0 && nodeClientRect.bottom < clientHeight)
    );
};

const checkDomHasClass = (node, className) => {
    // 如果没有找到节点
    if (node == null) return false;
    if (!className) return true;

    let curNode = node;
    while (!(curNode.classList && curNode.classList.contains(className)) && curNode.nodeName !== 'BODY')
        curNode = curNode.parentNode;
    return curNode.classList.contains(className);
};

const getConditionCount = baseEl => {
    if (!baseEl || !baseEl.querySelector('.valueTitle')) return 0;
    const spans = baseEl.querySelectorAll('span.conditionKeyWord');
    if (Array.from(spans).length === 0) return 0;

    const { width: baseWidth } = baseEl.querySelector('.valueTitle').getBoundingClientRect() || { width: 0 };
    const firstWordEl = baseEl.querySelector('.valueTitle span.firstWord');
    const sepreateWordEl = baseEl.querySelector('span.conditionSepreateWord');
    let count = 0;
    let total = firstWordEl ? firstWordEl.getBoundingClientRect().width : 0;
    let sepreateWord = sepreateWordEl ? sepreateWordEl.getBoundingClientRect().width : 0;
    Array.from(spans).forEach((element, index) => {
        const { width } = element.getBoundingClientRect();
        total = total + width + sepreateWord + 4;
        if (total - sepreateWord > baseWidth - 50) {
            element.classList.add('ellipsisWord');
            const nextEl = spans[index + 1];
            if (nextEl) {
                nextEl.style.display = 'none';
                if (nextEl.previousSibling) {
                    // 找到前一个分割词的dom
                    nextEl.previousSibling.style.display = 'none';
                }
            }
            count++;
        }
    });
    return count;
};

const getConditionUrls = when => {
    let res = '';
    let relationText =
        when.condition === '![()]'
            ? i18n.t('common.and_lowercase')
            : i18n.t('common.or');
    if (Array.isArray(when.value)) {
        const val = when.value.map(item => {
            return getConditionTitle(item);
        });
        res += val.join(getConditionSepreateWord(relationText));
    } else {
        res += getConditionTitle(when.value);
    }
    return res;
};

const getConditionTitle = val => {
    if (!val) return '';
    return `<span class="conditionKeyWord">${val}</span>`;
};

const getConditionSepreateWord = val => {
    return `<span class="conditionSepreateWord">${val}</span>`;
};

const resetDom = dom => {
    if (dom) {
        const spans = dom.querySelectorAll('span.conditionKeyWord');
        if (Array.from(spans).length === 0) return;
        Array.from(spans).forEach((element, index) => {
            element.classList.remove('ellipsisWord');
            const nextEl = spans[index + 1];
            if (nextEl) {
                nextEl.style.display = 'block';
                if (nextEl.previousSibling) {
                    nextEl.previousSibling.style.display = 'block';
                }
            }
        });
    }
};

export default {
    domIsIn,
    checkDomHasClass,
    getConditionCount,
    getConditionUrls,
    getConditionTitle,
    getConditionSepreateWord,
    resetDom
};
