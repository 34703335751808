import Vue from 'vue';
import VueI18n from 'vue-i18n';
import localesConfigs from '@/common/configs/locales.config';
import localsService from '@/common/services/locals.service';

Vue.use(VueI18n);

let locale = localsService.getLanguage();
if (typeof locale === 'object' && locale.key) locale = locale.key; // 迁移过来的档案可能是个对象

const fallbackLocale = localesConfigs.find(locale => locale.fallbackLocale).key;
const messages = localesConfigs.reduce((acc, cur) => {
    const { key, file } = cur;

    //加载element国际化文案
    const eleLan = key === 'JP' ? 'ja' : key === 'ZH' ? 'zh-CN' : 'en';
    const elementLocale = require(`@base/element-ui/lib/locale/lang/${eleLan}`);
    acc[key] = Object.assign(file, elementLocale.default);
    return acc;
}, {});

const i18n = new VueI18n({
    locale,
    fallbackLocale,
    messages,
    t: key => messages[locale](key)
});

// set lang
Vue.prototype.$locale = {
    change(lang) {
        i18n.locale !== lang && (i18n.locale = lang);
    },
    current() {
        return i18n.locale;
    }
};
export default i18n;
