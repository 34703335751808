import RegConfig from './reg.config';

export default {
    /**
     * 解决浮点误差计算不精确问题。例如(0.1+0.2=0.30000000000000004)
     * @param {Number} num
     * @param {Number} precision 12,这是一个经验的选择，一般选12就能解决掉大部分0001和0009问题，而且大部分情况下也够用了，如果你需要更精确可以调高。
     */
    strip(num, precision = 12) {
        return parseFloat((+num).toPrecision(precision));
    },

    //计算百分比方法
    getPercent(num, total) {
        var value = '';
        num = parseFloat(this.strip(num));
        total = parseFloat(this.strip(total));
        if (isNaN(num) || isNaN(total) || total === 0) {
            value = '---';
        } else {
            value = Math.round((num / total) * 10000) / 100.0;
        }
        return value;
    },

    /**
     * 格式化 number 显示
     *
     * dataUnit 单位
     * type = null : shortValue + shortUnit + dataUnit
     * type = array : [shortValue , shortUnit + dataUnit]
     * type = short : 缩略,  30.23M, shortValue + shortUnit
     * type = shortArray : [shortValue, shortUnit]
     * type = thousands 千分位 12,233.21
     * type = thousandsArray
     * NumberMinUnitValue 数字最小单位值(默认为百万: 1,000,000)
     * ThousandsSep 是否使用千分位逗号分隔符
     */
    formatNumber(num, dataUnit, type, NumberMinUnitValue, extra) {
        var val = '';
        const ThousandsSep = !extra || extra.ThousandsSep !== false; // 如果没有extra为true
        const AbbrValue = extra && extra.AbbrValue; // 如果没有extra为false
        dataUnit = dataUnit || '';
        var shortUnit = '';

        if (num || num === 0) {
            if (!RegConfig.isNumber.test(num)) {
                val = num;
            } else {
                if ('h' === dataUnit) {
                    //将小时转换成毫秒
                    num = num * 60 * 60 * 1000;
                    dataUnit = 'ms';
                } else if ('m' === dataUnit) {
                    //将分钟转换成毫秒
                    num = num * 60 * 1000;
                    dataUnit = 'ms';
                } else if ('s' === dataUnit) {
                    num = num * 1000;
                    dataUnit = 'ms';
                }

                // 如果单位是秒(s),对时间进行格式化显示为: 1h2m3s4ms
                if ('ms' === dataUnit) {
                    var s = 1000;
                    var m = 1000 * 60;
                    var h = 1000 * 60 * 60;
                    var d = 1000 * 60 * 60 * 24;

                    // 修正数值位数
                    if (num !== 0) {
                        num = parseFloat(num).toFixed(0); // 不保留小数
                        num = parseFloat(num); // 去除末尾的0
                    }

                    // 如果是缩略的时间格式，则修正数值
                    if (!type || type === 'array' || type === 'short' || type === 'shortArray') {
                        if (num > d) {
                            num = num - (num % h);
                        } else if (num > h) {
                            num = num - (num % m);
                        } else if (num > m) {
                            num = num - (num % s);
                        }
                    }

                    var result = '';
                    if (num >= d) {
                        result += (num - (num % d)) / d + 'd'; // 取整
                        num = num % d; // 求余
                    }
                    if (num >= h) {
                        result += (num - (num % h)) / h + 'h'; // 取整
                        num = num % h; // 求余
                    }
                    if (num >= m) {
                        result += (num - (num % m)) / m + 'm';
                        num = num % m;
                    }
                    if (num >= s) {
                        result += (num - (num % s)) / s + 's';
                        num = num % s;
                    }

                    // 修正数值位数
                    if (num !== 0) {
                        num = parseFloat(num).toFixed(2); // 保留两位小数
                        num = parseFloat(num); // 去除末尾的0
                    }

                    if (!result || (result && num !== 0)) {
                        result += num + dataUnit;
                    }

                    // 时间格式不需要拼接dataUnit和shortUnit, 设为空串
                    dataUnit = '';
                    shortUnit = '';
                    val = result;
                } else {
                    if (!type || type === 'array' || type === 'short' || type === 'shortArray') {
                        if (AbbrValue && AbbrValue !== 'auto' && AbbrValue !== 'none') {
                            switch (AbbrValue) {
                                case 'to_thousands':
                                    val = num / 1000.0;
                                    shortUnit = 'K';
                                    break;
                                case 'to_million':
                                    val = num / 1000000.0;
                                    shortUnit = 'M';
                                    break;
                                case 'to_billion':
                                    val = num / 1000000000.0;
                                    shortUnit = 'B';
                                    break;
                            }
                        } else {
                            var _negative = false;
                            var k = 1000;
                            var m = 1000 * 1000;
                            var b = 1000 * 1000 * 1000;
                            var t = 1000 * 1000 * 1000 * 1000;
                            var e = 1000 * 1000 * 1000 * 1000 * 1000; //大于999t的显示为科学计数法
                            NumberMinUnitValue = NumberMinUnitValue || m;

                            if (num < 0) {
                                num = Math.abs(num); //转换为正数
                                _negative = true;
                            }
                            if (num >= NumberMinUnitValue) {
                                if (num >= e) {
                                    //大于999t的显示为科学计数法（转换为字符串显示）
                                    var valString = num.toString();
                                    var valLen = valString.length;
                                    if (valString.slice(1, 2) === 0) {
                                        val = valString.slice(0, 1) + 'e+' + valLen;
                                    } else {
                                        val = valString.slice(0, 1) + '.' + valString.slice(1, 2) + 'e+' + valLen;
                                    }

                                    shortUnit = '';
                                } else if (num > t) {
                                    val = num / t;
                                    shortUnit = 'T';
                                } else if (num > b) {
                                    val = num / b;
                                    shortUnit = 'B';
                                } else if (num >= m) {
                                    val = num / m;
                                    shortUnit = 'M';
                                } else if (num >= k) {
                                    if (num < 10 * k) {
                                        val = num;
                                        shortUnit = '';
                                    } else {
                                        //最新规则，大于10000才显示k
                                        val = num / k;
                                        shortUnit = 'K';
                                    }
                                } else {
                                    val = num;
                                }
                            } else {
                                val = num;
                            }

                            if (_negative) {
                                val = '-' + val;
                            }

                            if (!/e|E/.test(val.toString())) {
                                //科学计数法不需要转换保留两位小数等规则
                                val = parseFloat(val).toFixed(2); // 保留两位小数
                                val = parseFloat(val); // 2.90 >>> 2.9， 去除末尾的0
                            }
                            //四舍五入需要进一位的临近值处理 例如999995，应显示为1M，不是1000k
                            if (val === 1000 && shortUnit === 'K') {
                                val = 1;
                                shortUnit = 'M';
                            } else if (val === 1000 && shortUnit === 'M') {
                                val = 1;
                                shortUnit = 'B';
                            } else if (val === 1000 && shortUnit === 'B') {
                                val = 1;
                                shortUnit = 'T';
                            }
                        }

                        if (ThousandsSep && num >= k && dataUnit !== '%') {
                            //如果百万以下数字，三位需要逗号分隔，如果类型是百分比的值，不需要加逗号分隔
                            val = (val + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'); // 三位分隔
                        }
                    } else if (type === 'thousands' || type === 'thousandsArray') {
                        val = parseFloat(num).toFixed(2); // 保留两位小数
                        val = parseFloat(val); // 2.90 >>> 2.9， 去除末尾的0
                        //四舍五入需要进一位的临近值处理 例如999995，应显示为1M，不是1000k
                        if (val === 1000 && shortUnit === 'K') {
                            val = 1;
                            shortUnit = 'M';
                        } else if (val === 1000 && shortUnit === 'M') {
                            val = 1;
                            shortUnit = 'B';
                        } else if (val === 1000 && shortUnit === 'B') {
                            val = 1;
                            shortUnit = 'T';
                        }
                        if (num >= k && dataUnit !== '%') {
                            //如果百万以下数字，三位需要逗号分隔，如果类型是百分比的值，不需要加逗号分隔
                            val = (val + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'); // 三位分隔
                        }
                        val = (val + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'); // 三位分隔
                    }
                }
            }
        }
        let returnData = [];
        if (dataUnit === '$' || dataUnit === '€' || dataUnit === '¥') {
            returnData = [dataUnit, val + shortUnit];
        } else {
            returnData = [val + shortUnit, dataUnit];
        }

        if (type === 'array' || type === 'shortArray' || type === 'thousandsArray') {
            return returnData;
        } else {
            // 需要加K M B T这种修饰，但是不加 ￥$这种修饰
            if (extra && extra.FormatValue === 'CURRENCY') {
                return extra.SymbolValue + val + shortUnit;
            } else if (extra && extra.FormatValue === 'PERCENT') {
                return this.strip(num * 100) + '%';
            } else if (extra && extra.FormatValue === 'NUMBER') {
                return val + shortUnit;
            } else {
                return returnData.join('');
            }
        }
    },

    toThousands(num) {
        return (num !== undefined && this.formatNumber(num)) || 0;
    }
};
