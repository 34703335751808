<!--
组件说明：主要用于解决滚动条与滚动区域分离的情景。
-->
<template>
    <div
        class="pt-scrollbar"
        :class="{
            hasXScrollbar: xBarClass || suppressScrollX || !xBarVisible,
            hasYScrollbar: yBarClass || suppressScrollY || !yBarVisible
        }"
        ref="scrollbar"
    >
        <div class="pt-scrollbar__container" ref="scrollContentWrap" @wheel="onContentWheel">
            <slot></slot>
        </div>
        <div class="pt-scrollbar__bar-x" ref="xBar" v-if="!xBarClass">
            <div class="pt-scrollbar__bar-x__content" ref="xBarContainer"></div>
        </div>
        <div class="pt-scrollbar__bar-y" ref="yBar" v-if="!yBarClass">
            <div class="pt-scrollbar__bar-y__content" ref="yBarContainer"></div>
        </div>
    </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import helper from './editorHelper';
import { addClass, removeClass, hasClass } from '../../../utils/dom';

export default {
    name: 'ptScrollbarV2',

    props: {
        xBarClass: String,
        yBarClass: String,
        disabled: {
            type: Boolean,
            default: false
        },
        suppressScrollX: {
            type: Boolean,
            default: true
        },
        suppressScrollY: {
            type: Boolean,
            default: false
        },
        enableMouseDrag: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            containerWrapDom: null, // 滚动区域外层
            containerDom: null, // 滚动区域内容层
            xBarDom: null, // x轴
            yBarDom: null, // y轴
            xBarVisible: false, // x轴是否可用
            yBarVisible: false, // y轴是否可用
            xBarContainerDom: null, // x轴内容层
            yBarContainerDom: null, // y轴内容层
            lastScrollLeft: null, // 最后滚动x轴
            lastScrollTop: null, // 最后滚动y轴
            maxScrollHeight: null, // 内容高度
            maxScrollWidth: null, // 内容宽度
            currentScrollLeft: 0,
            // 滚动条插件实例
            xBarPs: null,
            yBarPs: null,
            events: [
                {
                    eventName: 'ps-x-reach-start',
                    eventFunc: 'onReachStartX'
                }
            ]
        };
    },

    mounted() {
        if (this.disabled) return;
        this.containerWrapDom = this.$refs.scrollContentWrap;
        this.containerDom = this.$slots.default[0].elm;
        this.xBarDom = this.$refs.xBar;
        this.yBarDom = this.$refs.yBar;

        // 如果指定外部滚动条层
        if (this.xBarClass) {
            this.xBarDom = document.querySelector('.' + this.xBarClass);
            this.srcollbarAppendContent('xBar');
        } else {
            this.xBarContainerDom = this.$refs.xBarContainer;
        }
        if (this.yBarClass) {
            this.yBarDom = document.querySelector('.' + this.yBarClass);

            this.srcollbarAppendContent('yBar');
        } else {
            this.yBarContainerDom = this.$refs.yBarContainer;
        }

        this.$nextTick(() => {
            this.initScrollbar();
            this.checkDomSize();
        });

        window.addEventListener('resize', this.handleWindowResize);
    },

    beforeDestroy() {
        this.xBarPs && this.xBarPs.destroy(this.xBarDom);
        this.yBarPs && this.yBarPs.destroy(this.yBarDom);
        this.$refs.scrollbar?.removeEventListener('mousedown', this.handleMouseDown);
        window.removeEventListener('resize', this.handleWindowResize);
    },

    methods: {
        handleWindowResize() {
            this.update();
        },
        handleMouseMove(event) {
            if (Math.abs(event.x - this.x) <= 9 || Math.abs(event.movementX) < 1) return;
            const newScrollLeft = (this.xBarDom.scrollLeft -= event.movementX);
            if ((this.lastScrollLeft = newScrollLeft)) return;
            this.xBarDom.scrollLeft = newScrollLeft;
            this.containerWrapDom.scrollLeft = this.xBarDom.scrollLeft;
            if (this.xBarDom.scrollLeft === 0) this.$emit('onReachEndX');
            this.currentScrollLeft = newScrollLeft;
            this.lastScrollLeft = this.currentScrollLeft;
            this.$emit('onScrollX', this.xBarDom.scrollLeft);
        },
        handleMouseUp() {
            this.x = null;
            document.removeEventListener('mousemove', this.handleMouseMove, true);
            document.removeEventListener('mouseup', this.handleMouseUp, true);
        },
        handleMouseDown(event) {
            this.x = event.x;
            event.preventDefault();
            document.addEventListener('mouseup', this.handleMouseUp, true);
            document.addEventListener('mousemove', this.handleMouseMove, true);
        },
        /**
         * 内容区域滚动事件监听
         */
        onContentWheel(e) {
            let delta = helper.getDeltaFromEvent(e);
            let left = delta[0];
            let top = delta[1];
            let scrollTop = this.containerWrapDom.scrollTop - top;
            let scrollLeft = this.containerWrapDom.scrollLeft + left;

            // xBar
            if (this.xBarVisible) {
                if (scrollLeft <= 0) {
                    scrollLeft = 0;
                }
                if (scrollLeft > this.maxScrollWidth) {
                    scrollLeft = this.maxScrollWidth;
                }

                if (scrollLeft !== this.lastScrollLeft) {
                    this.containerWrapDom.scrollLeft = scrollLeft;
                    this.xBarDom.scrollLeft = scrollLeft;
                    this.lastScrollLeft = scrollLeft;
                    this.$emit('hWheel', e);
                    this.$emit('hScroll', scrollLeft);
                }
                // e.stopPropagation()
            }

            // yBar
            if (this.yBarVisible) {
                if (scrollTop <= 0) {
                    scrollTop = 0;
                }
                if (scrollTop > this.maxScrollHeight) {
                    scrollTop = this.maxScrollHeight;
                }

                if (scrollTop !== this.lastScrollTop) {
                    this.containerWrapDom.scrollTop = scrollTop;
                    this.yBarDom.scrollTop = scrollTop;
                    this.lastScrollTop = scrollTop;
                    this.$emit('vWheel', e);
                    this.$emit('vScroll', scrollTop);
                }
            }

            // e.stopPropagation()
            e.preventDefault();
        },

        /**
         * 同步内容区域尺寸至滚动条区域
         */
        setBarSize() {
            let wrapRect = this.containerWrapDom.getBoundingClientRect();
            let innerRect = this.containerDom.getBoundingClientRect();

            let scrollHeight = wrapRect.height;
            let scrollWidth = wrapRect.width;
            let innerHeight = innerRect.height;
            let innerWidth = innerRect.width;

            this.yBarDom.style.height = scrollHeight + 'px';
            this.yBarContainerDom.style.height = innerHeight + 'px';
            this.maxScrollHeight = this.yBarContainerDom.clientHeight - this.yBarDom.clientHeight;

            this.xBarDom.style.width = scrollWidth + 'px';
            this.xBarContainerDom.style.width = innerWidth + 'px';
            this.maxScrollWidth = this.xBarContainerDom.clientWidth - this.xBarDom.clientWidth;
        },

        /**
         * 初始化滚动条插件及事件绑定
         */
        initScrollbar() {
            this.setBarSize();
            if (this.enableMouseDrag) this.$refs.scrollbar?.addEventListener('mousedown', this.handleMouseDown);
            this.xBarPs = new PerfectScrollbar(this.xBarDom, {
                minScrollbarLength: 20,
                suppressScrollY: true,
                swipeEasing: true
            });
            this.xBarDom.addEventListener('ps-scroll-x', e => {
                this.containerWrapDom.scrollLeft = this.xBarDom.scrollLeft;
                this.currentScrollLeft = this.xBarDom.scrollLeft;
                this.$emit('onScrollX', this.xBarDom.scrollLeft);
                this.$eventBus.$emit('x-scroll');
            });
            this.xBarDom.addEventListener('ps-x-reach-end', e => {
                this.$emit('onReachEndX');
            });
            this.xBarDom.addEventListener('ps-x-reach-start', e => {
                this.onReachStartX();
            });
            this.yBarPs = new PerfectScrollbar(this.yBarDom, {
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            this.yBarDom.addEventListener('ps-scroll-y', e => {
                this.containerWrapDom.scrollTop = this.yBarDom.scrollTop;
                this.$emit('onScrollY', this.yBarDom.scrollTop);
            });
            this.yBarDom.addEventListener('ps-y-reach-start', e => {
                this.$emit('onReachStartY');
            });
            this.yBarDom.addEventListener('ps-y-reach-end', e => {
                this.$emit('onReachEndY');
            });
        },

        /**
         * 校验滚动区域尺寸
         */
        checkDomSize() {
            if (!this.suppressScrollX && this.xBarDom.clientWidth < this.xBarContainerDom.clientWidth) {
                this.xBarVisible = true;
            } else this.$emit('onReachStartX');

            if (!this.suppressScrollY && this.yBarDom.clientHeight < this.yBarContainerDom.clientHeight) {
                this.yBarVisible = true;
            } else this.$emit('onReachStartY');
        },

        /**
         * 外部指定层内生成滚动条占位层
         */
        srcollbarAppendContent(type) {
            this[type + 'ContainerDom'] = document.createElement('div');
            addClass(this[type + 'Dom'], type === 'xBar' ? 'pt-scrollbar-x' : 'pt-scrollbar-y');
            this[type + 'ContainerDom'].className = type === 'xBar' ? 'pt-scrollbar-x__inner' : 'pt-scrollbar-y__inner';
            // console.log('dom', this[type+'Dom'].querySelector('.' + this[type+'ContainerDom'].className));
            let child = this[type + 'Dom'].querySelector('.' + this[type + 'ContainerDom'].className);
            if (child) {
                this.yBarDom.removeChild(child);
            }

            this[type + 'Dom'].appendChild(this[type + 'ContainerDom']);
        },

        //获取当前滚动条位置
        getScrollTop() {
            return typeof this.yBarDom.scrollTop !== 'undefined' ? this.yBarDom.scrollTop : 0;
        },

        /********* 外部调用事件 *********/
        // 设置滚动条位置
        setPosition() {
            this.xBarDom.scrollLeft = this.containerWrapDom.scrollLeft;
            this.yBarDom.scrollTop = this.containerWrapDom.scrollTop;
        },
        // 滚动到特定位置
        scrollToPos(scrollTop) {
            if (isNaN(scrollTop)) {
                let scrollHeight = this.$el.scrollHeight;
                let clientHeight = this.$el.clientHeight;

                switch (scrollTop) {
                    case 'top':
                        scrollTop = 0;
                        break;
                    case 'middle':
                        scrollTop = scrollHeight > clientHeight ? (scrollHeight - clientHeight) / 2 : 0;
                        break;
                    case 'bottom':
                        scrollTop = scrollHeight;
                        break;
                }
            }
            this.$el.scrollTop = scrollTop;
        },
        scrollToLeft(scrollLeft) {
            this.xBarDom.scrollLeft = scrollLeft;
            this.containerWrapDom.scrollLeft = this.xBarDom.scrollLeft;
            this.currentScrollLeft = this.xBarDom.scrollLeft;
            this.$emit('onScrollX', this.xBarDom.scrollLeft);
        },
        // 重置滚动条
        update() {
            if (this.disabled) return;
            this.setBarSize();
            this.checkDomSize();
            this.xBarPs?.update(this.xBarDom);
            this.yBarPs?.update(this.yBarDom);
        },
        //横滚滚到最左侧时的回调
        onReachStartX() {
            console.log('onReachStartX');
            this.$emit('onReachStartX');
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import.scss';
@import './css/main';

$scrollbarSize: 14px;

.pt-scrollbar {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0px auto;
    padding: 0 $scrollbarSize $scrollbarSize 0;

    &.hasXScrollbar {
        padding-right: 0;
    }

    &.hasYScrollbar {
        padding-bottom: 0;
    }

    &__container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    &__bar-x {
        height: $scrollbarSize;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        &__content {
            height: 100%;
        }
    }

    &__bar-y {
        width: $scrollbarSize;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;

        &__content {
            width: 100%;
        }
    }

    // 外部样式
    &-x {
        height: $scrollbarSize;
        position: relative;
        overflow: hidden;

        &__inner {
            height: 100%;
        }
    }
    // 外部样式
    &-y {
        width: $scrollbarSize;
        position: relative;
        overflow: hidden;

        &__inner {
            height: 100%;
        }
    }
}
</style>
