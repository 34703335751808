import Vue from 'vue';
import VueRouter from 'vue-router';
import routerServices from './router.services';
import appRouter from './app.router';
import Error404 from '@/components/commons/404.vue';

import MyApp from '@/pages/MyApp.vue';
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
    {
        name: 'MyApp',
        path: '/app',
        component: MyApp,
        redirect: to => {
            return { name: 'CampaignReport', query: to.query };
        },
        beforeEnter: routerServices.beforeEnterApp,
        children: appRouter
    },
    {
        name: '404',
        path: '/404',
        component: Error404,
        meta: {
            isWhiteList: true
        }
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(x, y, savedPosition) {
        if (savedPosition) return savedPosition;
        return { x: 0, y: 0 };
    }
});

router.onError(err => {
    const pattern = /^Loading chunk (\d)+ failed\./;
    const isChunkLoadingFailed = pattern.test(err.message);
    if (isChunkLoadingFailed) {
        window.location.replace(window.location.href);
    } else {
        console.log('router change error', err);
    }
});

router.beforeEach(routerServices.beforeChange);
router.afterEach(routerServices.afterChange);
export default router;
