<template>
    <textarea ref="mycode" class="code-editor" :value="code"></textarea>
</template>

<script>
// import CodeMirror from 'codemirror/lib/codemirror';
// import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/monokai.css';
// import 'codemirror/addon/lint/lint.css';
// import 'codemirror/addon/hint/show-hint.css';
// import 'codemirror/addon/hint/show-hint.js';
// import 'codemirror/addon/hint/xml-hint.js';
// import 'codemirror/addon/hint/html-hint.js';
// import 'codemirror/mode/xml/xml.js';
// import 'codemirror/mode/javascript/javascript.js';
// import 'codemirror/mode/css/css.js';
// import 'codemirror/mode/htmlmixed/htmlmixed.js';
// import 'codemirror/addon/lint/lint';
// import 'codemirror/addon/lint/javascript-lint';
// import 'codemirror/addon/lint/css-lint';

import { JSHINT } from 'jshint';
import { CSSLint } from 'csslint';
window.JSHINT = JSHINT;
window.CSSLint = CSSLint;

export default {
    name: 'ptCode',

    props: {
        code: String,
        mode: String,
        options: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },

    data() {
        //lin demo: https://codemirror.net/demo/lint.html
        const defaultOptions = {
            mode: this.mode,
            value: this.code,
            theme: 'monokai', // themes: https://codemirror.net/demo/theme.html#default
            lineNumbers: false,
            indentWithTabs: true,
            smartIndent: true,
            matchBrackets: true,
            autofocus: true,
            // 当前选择行是否加亮
            styleActiveLine: true,
            // 长行时文字是否换行
            lineWrapping: true,
            // 默认情况下，CodeMirror会将使用相同class的两个span合并成一个
            flattenSpans: false,
            // 当前编辑器是否可用 true => 可用  false=> 不可用
            readOnly: false
        };
        return {
            editor: null,
            editorOptions: Object.assign({}, defaultOptions, this.options)
        };
    },

    created() {
        window.addEventListener('resize', this.setSize, false);
    },

    mounted() {
        const _self = this;
        this.editor = CodeMirror.fromTextArea(this.$refs.mycode, this.editorOptions);
        this.editor.on('change', function (cm) {
            const value = cm.getValue();
            if (value !== _self.code) {
                _self.$emit('change', value);
            }
        });
        this.setSize();
    },

    beforeDestroy() {
        this.editor = null;
        window.removeEventListener('resize', this.setSize, false);
    },

    methods: {
        setSize() {
            if (this.editor) {
                const parentElement = this.$el.parentElement;
                const width = parentElement.offsetWidth;
                const height = parentElement.offsetHeight;
                this.editor.setSize('100%', height);
            }
        },

        refresh() {
            this.editor && this.editor.refresh();
        },

        updateValue() {
            this.editor.setValue(this.code);
            this.editor.refresh();
        }
    },

    watch: {
        mode(newVal) {
            if (this.editor) {
                this.editor.setOption('mode', newVal);
                this.updateValue();
            }
        }
    }
};
</script>

<style lang="scss">
.code-editor {
    height: 100%;
}

.CodeMirror-lint-tooltip {
    z-index: 2147483637 !important;
}
</style>
