"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var setting_config_1 = require("@/components/engagement/setting/setting.config");
var permission_1 = require("@/common/utils/permission");
var permissions_config_1 = require("@/common/configs/permissions.config");
var number_utils_1 = require("@/common/utils/number.utils");
var package_utils_1 = require("@/common/packages/verify-package/package.utils");
var index_1 = require("@/hooks/index");
var Skeleton_vue_1 = require("@/components/commons/Skeleton.vue");
var LazyloadCard_vue_1 = require("@/components/commons/LazyloadCard.vue");
var singleSpa_service_1 = require("@/common/services/singleSpa.service");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'GoalsReportCard',
    props: ['info', 'dateRange'],
    setup: function (props, _a) {
        var _this = this;
        var root = _a.root, emit = _a.emit;
        var goal = (0, composition_api_1.ref)(props.info);
        var commit = (0, index_1.useCommit)();
        var reload = (0, composition_api_1.ref)(false); // 是否需要重新监听 加载
        var hasGoalRexPermission = permission_1.default.includePermissionWithStore([permissions_config_1.default.goalRex]);
        var goalConditionList = setting_config_1.default.entryPatternList.filter(function (item) {
            if (item.value === 'Rex')
                return hasGoalRexPermission;
            return !item.hide;
        });
        var getDescription = function (goal) {
            if (!goal)
                return;
            var description = goal.description, content = goal.content;
            var type = content.type, subType = content.subType, condition = content.condition, value = content.value, where = content.where;
            if (description !== '') {
                return description;
            }
            else {
                var conditionText = goalConditionList.find(function (con) { return condition === con.value; });
                var subTypeKey = subType || 'page';
                if (type.toUpperCase() === 'URL') {
                    return root.$t('goals.condition_visitpage', {
                        condition: conditionText === null || conditionText === void 0 ? void 0 : conditionText.text,
                        value: value === null || value === void 0 ? void 0 : value.join(','),
                        pageType: subTypeKey === 'page'
                            ? root.$t('dimension.original_page')
                            : root.$t('dimension.combined_page')
                    });
                }
                else {
                    var property_1 = '';
                    var len_1 = where === null || where === void 0 ? void 0 : where.length;
                    where === null || where === void 0 ? void 0 : where.map(function (item, index) {
                        property_1 += "".concat(root.$t('goals.condition_event_property_symbol1')).concat(item.key).concat(root.$t('goals.condition_event_property_symbol2')).concat(index !== len_1 - 1 ? root.$t('goals.condition_event_property_separat') : '');
                    });
                    return property_1
                        ? root.$t('goals.condition_event_with_property', {
                            event: value && value.join(','),
                            property: property_1
                        })
                        : root.$t('goals.condition_event', {
                            event: value && value.join(',')
                        });
                }
            }
        };
        var chartSettings = {
            chart: {
                // width: 158,
                height: 136,
                padding: 0,
                marginTop: 4,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0
            },
            xAxis: {
                visible: false,
                type: 'linear'
            },
            yAxis: {
                visible: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                backgroundColor: 'transparent',
                outside: false,
                formatter: function () {
                    var points = this.points || [this.point];
                    var _a = points[0] || {}, x = _a.x, y = _a.y, key = _a.key;
                    return ((y !== undefined &&
                        "<div class=\"overview-chart--tooltip\">".concat(key, ": <span>").concat(number_utils_1.default.formatNumber(y), "</span></div>")) ||
                        '');
                }
            }
        };
        var handleCommand = function (goal, command) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                emit('updateCurrent', goal);
                switch (command) {
                    case 'edit':
                        package_utils_1.default.verifyPrivilege({ privilegeKey: 'goals', editType: 'EDIT' })
                            ? root.$router.push({ name: 'GoalEdit', params: { goalId: goal.id } })
                            : commit('main/SET_AUTH_DIALOG_VISIBLE', { visible: true, type: 'EDIT_GOAL' });
                        break;
                    case 'delete':
                        emit('delete');
                        break;
                }
                return [2 /*return*/];
            });
        }); };
        // 操作项
        var moreOptions = [
            {
                name: root.$t('users.users_operation_edit'),
                code: 'edit',
                roleKey: 'goalUpdate'
            },
            {
                name: root.$t('users.user_report_delete'),
                code: 'delete',
                roleKey: 'goalDelete',
                divided: true
            }
        ].filter(function (item) { return (0, singleSpa_service_1.checkModuleRole)(item.roleKey); });
        (0, composition_api_1.watch)(function () { return props.info; }, function () {
            goal.value = props.info;
        }, {
            deep: true
        });
        (0, composition_api_1.watch)(function () { return props.dateRange; }, function () {
            // emit('getData', goal.value);
            reload.value = true;
            setTimeout(function () {
                reload.value = false;
            }, 0);
        }, {
            deep: true
        });
        var handleCardShow = function () {
            // if (props.info?.chartData !== undefined && !reload.value) return;
            emit('getData', goal.value);
        };
        return {
            props: props,
            goal: goal,
            moreOptions: moreOptions,
            chartSettings: chartSettings,
            reload: reload,
            handleCommand: handleCommand,
            getDescription: getDescription,
            handleCardShow: handleCardShow
        };
    },
    components: {
        Skeleton: Skeleton_vue_1.default,
        LazyloadCard: LazyloadCard_vue_1.default
    }
});
