<template>
    <div :class="wrapClasses">
        <div :class="handlerClasses" v-if="!suffix">
            <a :class="upClasses" v-repeat-click="increase" @keydown.enter="increase" @mouse.down.stop="preventDefault">
                <i :class="innerDownClasses">
                    <pt-icon icon="pt-icon--arrow-up" :iconStyle="{ width: '100%', height: '100%' }"></pt-icon>
                </i>
            </a>
            <a
                :class="downClasses"
                v-repeat-click="decrease"
                @keydown.enter="decrease"
                @mouse.down.stop="preventDefault"
            >
                <i :class="innerDownClasses">
                    <pt-icon icon="pt-icon--arrow-down" :iconStyle="{ width: '100%', height: '100%' }"></pt-icon>
                </i>
            </a>
        </div>
        <div :class="handlerClasses" v-else>{{ suffixContent }}</div>
        <div :class="unitClasses" v-if="showUnitDropdown">
            <pt-dropdown ref="unitDropdown">
                <div slot="trigger" class="dropdown-trigger">
                    {{ currUnit }}
                </div>
                <div slot="dropdown" class="dropdown-list">
                   <div class="dropdown-item" @click="handleChangeUnit(unit.key)" v-for="unit in unitList" :key="unit.key">{{ unit.text }}</div>
                </div>
            </pt-dropdown>
        </div>
        <div :class="inputWrapClasses">
            <input
                ref="input"
                type="text"
                autocomplete="off"
                class="pt-ui-input__number_inner"
                :class="inputClasses"
                :disabled="disabled"
                :placeholder="placeholder"
                v-model="modelVal"
                @keydown.up.prevent="increase"
                @keydown.down.prevent="decrease"
                @keydown.enter="handleEnter"
                @focus="focus"
                @blur="blur"
                @keydown.38.stop="up"
                @keydown.40.stop="down"
                @input="input"
            />
            <div class="pt-ui-input__number-unit" v-if="unit">
                {{ unit }}
            </div>
        </div>
    </div>
</template>
<script>
import ptDropwon from '../../dropdown';
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String(padString || ' ');
        if (this.length > targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}
const prefixCls = 'pt-ui-input__number';
const iconPrefixCls = 'ivu-icon';

function isInteger(obj) {
    return typeof obj === 'number' && obj % 1 === 0;
}

function addNum(num1, num2) {
    let sq1, sq2, m;
    try {
        sq1 = num1.toString().split('.')[1].length;
    } catch (e) {
        sq1 = 0;
    }
    try {
        sq2 = num2.toString().split('.')[1].length;
    } catch (e) {
        sq2 = 0;
    }

    m = Math.pow(10, Math.max(sq1, sq2));
    return (num1 * m + num2 * m) / m;
}

export default {
    name: 'ptInputNumber',
    props: {
        isPadStart: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number,
            default: Infinity
        },
        min: {
            type: Number,
            default: -Infinity
        },
        step: {
            type: Number,
            default: 1
        },
        value: {
            type: [Number, String],
            default: 1
        },
        size: {
            type: String,
            default: 'default',
            enum: ['default', 'big', 'large', 'xlarge']
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: [String, Number],

        //是否允许为空
        isEmpty: {
            type: Boolean,
            default: true
        },

        //是否允许小数
        isDecimal: {
            type: Boolean,
            default: false
        },

        //是否允许负数
        isNegative: {
            type: Boolean,
            default: true
        },

        //当value为空时，是否需要校验极限值(特殊情况对应Y轴多个组件之间的比较)
        isCheckEmptyValue: {
            type: Boolean,
            default: true
        },

        theme: {
            type: String,
            default: 'light'
        },
        //添加后缀
        suffix: {
            type: Boolean,
            default: false
        },
        suffixContent: {
            type: String,
            default: ''
        },
        showUnitDropdown: {
            type: Boolean,
            default: false
        },
        currentUnit: {
            type: String,
            default: 'px'
        },
        precision: {
            type: Number,
            validator(val) {
                return val >= 0 && val === parseInt(val, 10);
            }
        },
        unit: String
    },
    data() {
        return {
            focused: false,
            upDisabled: false,
            downDisabled: false,
            modelVal: null, //input model
            prevVal: null, //上一次有效值
            lastInputVal: null, //上一次有效输入值
            isError: false,
            currUnit: '',
            unitList: [
                {
                    key: 'px',
                    text: 'px'
                },
                {
                    key: '%',
                    text: '%'
                },
                // {
                //     key: 'em',
                //     text: 'em'
                // },
                // {
                //     key: 'rem',
                //     text: 'rem'
                // }
            ]
        };
    },
    computed: {
        minDisabled() {
            return this._decrease(this.value, this.step) < this.min;
        },
        maxDisabled() {
            return this._increase(this.value, this.step) > this.max;
        },
        numPrecision() {
            const { value, step, getPrecision, precision } = this;
            const stepPrecision = getPrecision(step);
            if (precision !== undefined) {
                if (stepPrecision > precision) {
                    console.warn(
                        '[Element Warn][InputNumber]precision should not be less than the decimal places of step'
                    );
                }
                return precision;
            } else {
                return Math.max(getPrecision(value), stepPrecision);
            }
        },
        wrapClasses() {
            return [
                `${prefixCls}`,
                `${prefixCls}-theme-${this.theme}`,
                {
                    [`${prefixCls}--${this.size}`]: !!this.size,
                    [`${prefixCls}-disabled`]: this.disabled,
                    [`${prefixCls}-focused`]: this.focused,
                    [`${prefixCls}-error`]: this.isError,
                    [`${prefixCls}-no-blank`]: this.modelVal !== '' && !this.focused
                }
            ];
        },
        handlerClasses() {
            return this.suffix ? `${prefixCls}-handler-wrapper suffix ${this.showUnitDropdown ? 'has-unit' : ''}` : `${prefixCls}-handler-wrapper ${this.showUnitDropdown ? 'has-unit' : ''}`;
        },
        unitClasses() {
            return `${prefixCls}-unit-dropdown-wrapper`
        },
        upClasses() {
            return `${prefixCls}-handler`;
        },
        innerUpClasses() {
            return [
                `${prefixCls}-handler-inner ${prefixCls}-handler-inner-up`,
                {
                    [`${prefixCls}-handler-disabled`]: this.upDisabled
                }
            ];
        },
        downClasses() {
            return `${prefixCls}-handler`;
        },
        innerDownClasses() {
            return [
                `${prefixCls}-handler-inner ${prefixCls}-handler-inner-down`,
                {
                    [`${prefixCls}-handler-disabled`]: this.downDisabled
                }
            ];
        },
        inputWrapClasses() {
            return `${prefixCls}-input-wrapper`;
        },
        inputClasses() {
            return `${prefixCls}-input`;
        },
        inputValue() {
            if (this.isPadStart) {
                return this.modelVal ? this.modelVal.toString().padStart(2, '0') : '00';
            } else {
                return this.modelVal;
            }
        }
    },
    methods: {
        init() {
            this.modelVal = this.value;
            this.prevVal = this.value;
            this.lastInputVal = this.value;
            this.currUnit = this.currentUnit;
        },
        preventDefault(e) {
            e.preventDefault();
        },
        up(e) {
            const targetVal = Number(e.target.value);
            if (this.upDisabled && isNaN(targetVal)) {
                return false;
            }
            this.changeStep('up', e);
        },
        down(e) {
            const targetVal = Number(e.target.value);
            if (this.downDisabled && isNaN(targetVal)) {
                return false;
            }
            this.changeStep('down', e);
        },
        handleEnter() {
            this.$emit('enter');
        },
        handleChangeUnit(val) {
            this.currUnit = val;
            this.$emit('changeUnit', val);
        },
        changeStep(type, e) {
            if (this.disabled) {
                return false;
            }

            const targetVal = Number(e.target.value);
            let val = Number(this.modelVal);
            const step = Number(this.step);
            if (isNaN(val)) {
                return false;
            }

            // input a number, and key up or down
            if (!isNaN(targetVal)) {
                if (type === 'up') {
                    if (addNum(targetVal, step) <= this.max) {
                        val = targetVal;
                    } else {
                        return false;
                    }
                } else if (type === 'down') {
                    if (addNum(targetVal, -step) >= this.min) {
                        val = targetVal;
                    } else {
                        return false;
                    }
                }
            }

            if (type === 'up') {
                let o = isInteger(val) ? val : parseInt(val);
                val = Math.max(addNum(o, step), this.min);
            } else if (type === 'down') {
                let o = isInteger(val) ? val : parseInt(val) + 1;
                val = Math.min(addNum(o, -step), this.max);
            }

            if (!this.valIsError(val)) {
                this.modelVal = val;
                this.setValue(val);
                this.$nextTick(() => {
                    this.$refs.input.value = this.inputValue;
                });
            }
        },
        setValue(val) {
            this.prevVal = val;
            this.$emit('input', val ? +val : val);
            this.$emit('change', val ? +val : val);
        },
        toPrecision(num, precision) {
            if (precision === undefined) precision = this.numPrecision;
            return parseFloat(parseFloat(Number(num).toFixed(precision)));
        },
        getPrecision(value) {
            if (value === undefined) return 0;
            const valueString = value.toString();
            const dotPosition = valueString.indexOf('.');
            let precision = 0;
            if (dotPosition !== -1) {
                precision = valueString.length - dotPosition - 1;
            }
            return precision;
        },
        _increase(val, step) {
            if (typeof val !== 'number' && val !== undefined) return this.modelVal;

            const precisionFactor = Math.pow(10, this.numPrecision);
            // Solve the accuracy problem of JS decimal calculation by converting the value to integer.
            return this.toPrecision((precisionFactor * val + precisionFactor * step) / precisionFactor);
        },
        _decrease(val, step) {
            if (typeof val !== 'number' && val !== undefined) return this.modelVal;

            const precisionFactor = Math.pow(10, this.numPrecision);

            return this.toPrecision((precisionFactor * val - precisionFactor * step) / precisionFactor);
        },
        increase() {
            if (this.disabled || this.maxDisabled) return;
            const value = this.value || 0;
            const newVal = this._increase(value, this.step);
            this.setValue(newVal);
        },
        decrease() {
            if (this.disabled || this.minDisabled) return;
            const value = this.value || 0;
            const newVal = this._decrease(value, this.step);
            this.setValue(newVal);
        },
        focus() {
            this.focused = true;
        },
        blur(e) {
            this.focused = false;
            this.resetTargetVal(e);
            this.$nextTick(() => {
                e.target.value = this.inputValue;
            });
        },
        keyDown(e) {
            if (e.keyCode === 38) {
                e.preventDefault();
                this.up(e);
            } else if (e.keyCode === 40) {
                e.preventDefault();
                this.down(e);
            }
        },
        input(e) {
            let val = e.target.value.trim();
            let formated = parseFloat(val);

            if (this.isInputPass(val)) {
                this.lastInputVal = val;
            } else {
                this.lastInputVal = this.isValueNumber(formated) ? formated : this.lastInputVal;
            }

            this.modelVal = this.lastInputVal;
            this.isError = this.valIsError(this.modelVal);
            if (!this.isError) this.setValue(this.modelVal);
        },
        valIsError(val) {
            if (val === '') {
                return !this.isEmpty || this.isCheckEmptyValue;
            } else {
                return !this.isValueNumber(val) || val > this.max || val < this.min;
            }
        },
        changeVal(val) {
            if (this.isValueNumber(val) || val === 0) {
                val = Number(val);
                const step = this.step;

                this.upDisabled = val + step > this.max;
                this.downDisabled = val - step < this.min;
            } else {
                if (val === '' && this.isEmpty) {
                    this.upDisabled = false;
                    this.downDisabled = false;
                } else {
                    this.upDisabled = true;
                    this.downDisabled = true;
                }
            }
        },
        //设置输入框内容
        resetTargetVal() {
            if (this.isError) {
                this.modelVal = this.prevVal;
                this.isError = false;
            } else {
                this.modelVal = this.modelVal === '' ? '' : parseFloat(this.modelVal);
            }
        },
        //是否为数字
        isValueNumber(val) {
            let reg = /^\d+$/;

            if (this.isDecimal && this.isNegative) {
                reg = /(^-?\d+?(\.)?(\d+)$)|(^-?\d+$)/;
            } else if (this.isDecimal) {
                reg = /(^\d+(\.)?(\d+)$)|(^\d+$)/;
            } else if (this.isNegative) {
                reg = /^-?\d+$/;
            }
            return reg.test(val + '');
        },
        //输入校验
        isInputPass(val) {
            let reg = /^\d*$/;

            if (this.isDecimal && this.isNegative) {
                reg = /^-?(\d+)?(\.)?(\d+)?$/;
            } else if (this.isDecimal) {
                reg = /^(\d*)?(\.)?(\d*)?$/;
            } else if (this.isNegative) {
                reg = /^-?\d*$/;
            }
            return reg.test(val + '');
        }
    },
    mounted() {
        this.init();
        this.changeVal(this.modelVal);
        this.$nextTick(() => {
            this.$refs.input && (this.$refs.input.value = this.inputValue);
        });
    },
    watch: {
        value(val) {
            if (parseFloat(this.modelVal) !== parseFloat(val)) {
                this.$nextTick(() => {
                    this.$refs.input && (this.$refs.input.value = this.inputValue);
                });
            }
            this.init();

            this.changeVal(val);
        },

        modelVal(val) {
            this.changeVal(val);
        },

        max() {
            this.changeVal(this.modelVal);
        },

        min() {
            this.changeVal(this.modelVal);
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import';

$ui-input-height: 26px;
$ui-input-border-radius: 4px;
$ui-input-handler-width: 8px;

$input-font: 13px;
$input-large-font: 13px;
$input-small-font: 12px;

@mixin inputSize($fontSize, $height) {
    font-size: $fontSize;
    height: $height;

    //输入框
    &-input-wrapper {
        .pt-ui-input__number-input {
            line-height: $ui-input-height - 2;
        }
    }
    .suffix {
        font-size: $fontSize - 2;
        line-height: $ui-input-height - 2;
    }
}

.pt-ui-input__number {
    display: inline-block;
    width: 100%;
    border-radius: $ui-input-border-radius;
    // overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 13px;
    height: $ui-input-height;
    transition: background-color 0.2 s ease, border 0.2 s ease;
    position: relative;

    &:hover {
        .pt-ui-input__number-handler-wrapper {
            visibility: visible;
        }
    }

    //上下箭头
    &-handler-wrapper {
        width: $ui-input-handler-width;
        height: 100%;
        position: absolute;
        top: 0;
        right: 6px;
        visibility: hidden;
        display: inline-block;
        font-size: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.has-unit {
            right: 24px;
        }
        .pt-ui-input__number-handler {
            width: 8px;
            height: 8px;
            display: inline-flex;
            cursor: pointer;
            &:first-child {
                margin-bottom: 1px;
            }
            &:hover .pt-ui-input__number-handler-inner svg {
                fill: #999;
            }
            .pt-icon {
                width: 100%;
                height: 100%;
            }
            &-inner svg {
                width: 8px;
                height: 8px;
                fill: rgba(153, 153, 153, 0.6);
            }
        }
    }
    .suffix {
        visibility: visible;
        color: #8993a4;
    }

    // unit下拉框
    &-unit-dropdown-wrapper {
        position: absolute;
        right:0;
    }


    //输入框
    &-input-wrapper {
        height: 100%;
        padding-right: $ui-input-handler-width * 2;
        display: flex;
        align-items: center;

        .pt-ui-input__number-input {
            width: 100%;
            height: 100%;
            padding: 6px 0 5px 10px;
            border: 0;
            line-height: $ui-input-height - 2;
            transition: all 0.3s linear;
            background-color: transparent;
            color: #344563;

            &::-moz-placeholder {
                color: #bbb;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: #bbb;
            }
            &::-webkit-input-placeholder {
                color: #bbb;
            }
        }

        .pt-ui-input__number-unit {
            font-size: 14px;
            color: $pt-black-600;
            padding: 0 2px 0 8px;
        }
    }

    //白色模板
    &-theme-light {
        border: 1px solid #c1c7d0;
        border-radius: 4px;
        background-color: #fff;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        .pt-ui-input__number-handler {
            &:hover .pt-ui-input__number-handler-inner {
                fill: #999;
            }

            &-inner {
                fill: rgba(153, 153, 153, 0.6);
            }
        }

        &.pt-ui-input__number-focused {
            color: $pt-black-900;
            border-color: $pt-green-60;

            .pt-ui-input__number_handler-wrapper {
                visibility: visible;
            }

            .pt-ui-input__number-handler {
                &:hover .pt-ui-input__number-handler-inner {
                    fill: #999;
                }

                &-inner {
                    fill: rgba(153, 153, 153, 0.6);
                }
            }
        }

        &.pt-ui-input__number-no-blank {
            border: 1px solid #dcdfe6;
            color: #091e42;
            &:hover {
                border-color: #c0c4cc;
            }
            .pt-ui-input__number-handler {
                &-inner {
                    fill: #b3bac5;
                }

                &:hover .pt-ui-input__number-handler-inner {
                    fill: #b3bac5;
                }
            }
        }
    }

    //黑色模板
    &-theme-dark {
        border: 1px solid #c1c7d0;
        background-color: transparent;
        color: #fff;

        .pt-ui-input__number-handler {
            &:hover .pt-ui-input__number-handler-inner {
                fill: #999;
            }

            &-inner {
                fill: rgba(153, 153, 153, 0.6);
            }
        }

        &.pt-ui-input__number-focused {
            border: 1px solid #76ca20;
            background-color: transparent;
            color: #fff;

            .pt-ui-input__number_handler-wrapper {
                visibility: visible;
            }

            .pt-ui-input__number-handler {
                &:hover .pt-ui-input__number-handler-inner {
                    fill: #999;
                }

                &-inner {
                    fill: rgba(153, 153, 153, 0.6);
                }
            }
        }

        &.pt-ui-input__number-no-blank {
            background-color: #29b6f6;
            border: 1px solid #29b6f6;
            color: #2e2e3a;

            .pt-ui-input__number-handler {
                &:hover .pt-ui-input__number-handler-inner {
                    fill: #fff;
                }
                &-inner {
                    fill: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }

    //错误提示
    &-error {
        border: 1px solid #f65100 !important;
    }
}

/****** size ******/
.pt-ui-input__number--xlarge {
    @include inputSize($input-large-font, $size-height-xlarge);
}
.pt-ui-input__number--large {
    @include inputSize($input-large-font, $size-height-large);
}
.pt-ui-input__number--big {
    @include inputSize($input-large-font, $size-height-big);
}
.pt-ui-input__number--default {
    @include inputSize($input-font, $size-height-default);
}

.dropdown-trigger {
    height: 26px;
    line-height: 26px;
    padding: 0 4px;
    border-radius:0 4px 4px 0;
    font-size: 12px;
    background-color: $pt-black-20;
    &:hover {
        background-color: $pt-black-30;
    }
}
.dropdown-list {
    padding: 8px 0;
    background: $pt-white;
    border: 1px solid $pt-black-40;
    border-radius: 4px;
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    z-index: 10;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    .dropdown-item {
        padding: 0 20px;
        font-size: 12px;
        margin: 0;
        line-height: 34px;
        cursor: pointer;
        color: #344563;
        font-size: 14px;
        list-style: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            background-color: #f5f7fa;
        }
    }
}

</style>
