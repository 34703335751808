<template>
    <div class="block-settings__item">
        <div class="block-settings__item-title">{{ title}}</div>
        <div class="block-settings__item-container flex-layout">
            <div
                class="block-item__icon"
                v-for="item in options"
                :key="item.code"
                :class="{'active': value === item.code}"
                @click="handleChange(item.code)"
            >
                <pt-icon
                    :icon="'pt-icon--' + item.icon"
                    :iconStyle="{width: '100%', height: '100%'}"
                ></pt-icon>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'KitsIconButton',
    props: {
        title: String,
        target: String,
        value: {
            required: true
        },
        options: Array
    },
    data() {
        return {
            currentValue: this.value
        };
    },
    methods: {
        handleChange(val) {
            // console.log('color picker change', val);
            this.$emit('change', val, this.target);
        }
    }
};
</script>