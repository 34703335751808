import buttonConfig from './button.config';
import { isNullOrUndefined } from '../../utils/creative.util';
import { widgetWrapPadding } from '../../configs/creative.config';

/**
 * Style 书写顺序
 * 1. 定位属性:
 *  position, display, float, left, top, right, bottom, overflow, clear, z-index.
 * 2. 自身属性:
 *  width, height, padding, border, margin, background
 * 3. 文字样式:
 *  font-family, font-size, font-style, font-weight, font-varient, color
 * 4. 文本属性:
 *  text-align, vertical-align, text-wrap, text-transform, text-indent,text-decoration, letter-spacing, word-spacing, white-space, text-overflow
 * 5. css3中新增属性:
 *  content, box-shadow, border-radius, transform
 */

function getButtonDom(content) {
    const { action, text } = content.value;
    const { type, url, openNewTab, tel, gotoWidget } = action || {};
    if (!text) return '';

    let html = '';
    const target = openNewTab ? 'target="_blank"' : '';
    switch (type) {
        case 'url':
            html = `<a href="${url}" ${target} data-engage-action-click="true" data-engage-block="button-url">${text}</a>`;
            break;
        case 'tel':
            html = `<a href="tel:${tel}" data-engage-action-click="true" data-engage-block="button-tel">${text}</a>`;
            break;
        case 'gotoWidget':
            html = `<a href="javascript:;" data-engage-action-click="true" data-engage-block="button-goto-widget" data-goto-widget="${gotoWidget}">${text}</a>`;
            break;
        case 'close':
            html = `<a href="javascript:;" data-engage-action-close="true" data-engage-block="button-close">${text}</a>`;
            break;
        default:
            html = `<a href="javascript:;"  data-engage-action-click="true" data-engage-block="button">${text}</a>`;
            break;
    }
    return html;
}

function getButtonStyle(content) {
    const {
        type,
        fontColor,
        fontSize,
        fontWeight,
        backgroundColor,
        paddingTop,
        paddingLeft,
        borderRadius,
        borderColor,
        borderWidth
    } = content.value;
    const backgroundColorStyle = backgroundColor
        ? `
            background-color: ${backgroundColor};
        `
        : '';
    const fontColorStyle = fontColor
        ? `
            color: ${fontColor};
        `
        : '';
    return type === 'button'
        ? `
        display: inline-block;
        padding: ${paddingTop}px ${paddingLeft}px;
        border-radius: ${borderRadius}px;
        border: ${borderWidth}px solid ${borderColor || 'transparent'};
        ${backgroundColorStyle}
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        white-space: nowrap;
        ${fontColorStyle}
        text-decoration: none;
    `
        : `
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        white-space: nowrap;
        ${fontColorStyle}
        text-decoration: underline;
    `;
}

export default {
    getHtml(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const hasMultiple = buttonConfig.hasMultiple(block);
        const leftCellDom = getButtonDom(content[0]);

        if (hasMultiple) {
            const rightCellDom = getButtonDom(content[1]);
            return `
                <div class="clearfix" id="${id}">
                    <div id="${id}-cell-left">${leftCellDom}</div>
                    <div id="${id}-cell-spacing"></div>
                    <div id="${id}-cell-right">${rightCellDom}</div>
                </div>
            `;
        }
        return `<div id="${id}">${leftCellDom}</div>`;
    },

    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const settings = block.blockSettings || {};
        const hasMultiple = buttonConfig.hasMultiple(block);
        const leftStyle = getButtonStyle(content[0]);
        let {
            alignment,
            backgroundColor,
            marginTop,
            marginBottom,
            noPadding
        } = settings;
        const padding = noPadding ? 0 : `0 ${paddingLeft}px 0 ${paddingLeft}px`;
        if (isNullOrUndefined(marginTop)) marginTop = 0;
        if (isNullOrUndefined(marginBottom)) marginBottom = 0;
        if (isNullOrUndefined(backgroundColor)) backgroundColor = 'transparent';
        if (isNullOrUndefined(alignment)) alignment = 'center';

        if (hasMultiple) {
            const { layout, spacing } = settings;
            const rightStyle = getButtonStyle(content[1]);

            if (layout === 'vertical') {
                return `
                    #${id} {
                        text-align: ${alignment};
                        padding: ${padding};
                        margin: ${marginTop}px 0 ${marginBottom}px;
                        background-color: ${backgroundColor};
                    }
                    #${id} a:hover {text-decoration: none;}
                    #${id}-cell-left a {${leftStyle}}
                    #${id}-cell-spacing {
                        width: 100%;
                        height: ${spacing}px;
                        overflow: hidden;
                    }
                    #${id}-cell-right a {${rightStyle}}
                `;
            } else {
                const justifyContent = {
                    left: 'flex-start',
                    center: 'center',
                    right: 'flex-end'
                };
                return `
                    #${id} {
                        display: flex;
                        align-items: center;
                        justify-content: ${justifyContent[alignment]};
                        padding: ${padding};
                        margin: ${marginTop}px 0 ${marginBottom}px;
                        background-color: ${backgroundColor};
                    }
                    #${id} a:hover {text-decoration: none;}
                    #${id}-cell-left {float: left;}
                    #${id}-cell-left a {${leftStyle}}
                    #${id}-cell-spacing {
                        float: left;
                        width: ${spacing}px;
                        height: 1px;
                        overflow: hidden;
                    }
                    #${id}-cell-right a {${rightStyle}}
                `;
            }
        }

        return `
            #${id} {
                text-align: ${alignment};
                padding: ${padding};
                margin: ${marginTop}px 0 ${marginBottom}px;
                background-color: ${backgroundColor};
            }
            #${id} a {${leftStyle}}
            #${id} a:hover {text-decoration: none;}
        `;
    }
};
