import accountApis from '@/apis/account.apis';
import profileApis from '@/apis/profile.apis';
import userService from '@/common/services/user.service';
import authority from '@/common/utils/authority';
import baseConfig from '@/common/configs/base.config';
import { getCampaignReportRouteName } from '@/components/campaign/campaign.util';
import { isSingleSpa, getNewPteUserInfo } from '@/common/services/singleSpa.service';

export default {
    /**
     * set router loading status
     */
    onLoading(context, flag) {
        context.commit('UPDATE_LOADING_STATE', flag);
    },

    scrollToTop(context) {
        const { scrollbar } = context.state;
        scrollbar && scrollbar.scrollToPos('top');
    },

    updateScroll(context) {
        const { scrollbar } = context.state;
        scrollbar && scrollbar.update('top');
    },

    getUserInfo(context, aid) {
        if (context.state.userInfo) return context.state.userInfo;

        return accountApis
            .getAccountInfo(null, {
                where: {
                    aid
                }
            })
            .then(
                account => {
                    context.commit('UPDATE_USER_INFO', account);
                    context.commit('engage/UPDATE_CREATE_PORTAL', getCampaignReportRouteName(), { root: true });
                    userService.updateLocalUserInfo(account);
                    return Promise.resolve(account);
                },
                () => {
                    return Promise.reject(new Error('get account info failed.'));
                }
            );
    },

    getProfiles(context) {
        if (context.state.profiles) return context.state.profiles;

        const { id } = context.state.userInfo || {};
        if (!id) return null;

        return profileApis
            .getProfiles(null, {
                where: {
                    aid: id
                }
            })
            .then(
                profiles => {
                    context.commit('UPDATE_USER_PROFILES', profiles);
                    return Promise.resolve(profiles);
                },
                () => {
                    return Promise.reject(new Error('get account info failed.'));
                }
            );
    },

    getProfileInfo(context, sid) {
        const { profileInfo, userInfo } = context.state || {};
        if (profileInfo?.sid === sid || !userInfo?.id) return profileInfo;
        return Promise.all([
            profileApis.getProfileInfo(null, { where: { sid }, aid: userInfo.id }),
            profileApis.getProfilePackage(null, { profileId: sid })
        ])
            .then(res => {
                const [profile, packageInfo] = res || [];
                context.commit('users/UPDATE_USER_PROERTIES', null, { root: true }); //重置用户属性列表
                context.commit('UPDATE_PROFILE_INFO', profile);
                context.commit(
                    'UPDATE_PACKAGE_INFO',
                    packageInfo?.packageId ? packageInfo : getNewPteUserInfo()?.packageInfo || {}
                );
                authority.siteAuthority(profile);
                localStorage.setItem(baseConfig.LAST_PROFILE_ID, profile.sid);
                return Promise.resolve(profile);
            })
            .catch(() => Promise.reject(new Error('get profile info failed.')));
    },

    getProfileGuideInfo(context) {
        const { profileGuideInfo, profileInfo } = context.state;
        const { sid } = profileInfo || {};
        if (profileInfo && profileGuideInfo && profileGuideInfo.sid === sid) return profileGuideInfo;

        return profileApis
            .firstGuideProfile(null, {
                where: {
                    sid
                }
            })
            .then(
                guideInfo => {
                    context.commit('UPDATE_PROFILE_GUIDE_INFO', guideInfo);
                    return Promise.resolve(guideInfo);
                },
                () => {
                    return Promise.reject(new Error('get first guide profile info failed.'));
                }
            );
    },

    updateProfileGuideInfo(context, { key, val }) {
        const { profileGuideInfo, profileInfo } = context.state;
        const { sid } = profileInfo || {};
        const { guideList } = profileGuideInfo || {};

        if (guideList) guideList[key] = val;

        return profileApis
            .updateFirstGuideProfile(null, {
                data: {
                    sid,
                    guideList: {
                        [key]: val
                    }
                }
            })
            .then(
                () => {
                    context.commit('ADD_PROFILE_GUIDE_INFO', {
                        [key]: val
                    });
                },
                () => {
                    return Promise.reject(new Error('get first guide profile info failed.'));
                }
            );
    },

    getAccountProfile(context) {
        const { sid } = context.state.profileInfo || {};
        const { id } = context.state.userInfo || {};
        return (
            sid &&
            id &&
            profileApis
                .accountProfile(null, {
                    where: {
                        sid,
                        aid: id
                    }
                })
                .then(
                    res => {
                        context.commit('UPDATE_ACCOUNT_PROFILE', res);
                        return Promise.resolve(res);
                    },
                    () => {
                        return Promise.reject(new Error('get account profile info failed.'));
                    }
                )
        );
    },

    updateAccountProfile(context, { key, val }) {
        const { sid } = context.state.profileInfo || {};
        const { id } = context.state.userInfo || {};
        return (
            sid &&
            id &&
            profileApis
                .updateAccountProfile(null, {
                    where: {
                        sid,
                        aid: id
                    },
                    data: {
                        [key]: val
                    }
                })
                .then(
                    res => {
                        context.commit('UPDATE_ACCOUNT_PROFILE', res);
                        return Promise.resolve(res);
                    },
                    () => {
                        return Promise.reject(new Error('update account profile info failed.'));
                    }
                )
        );
    },

    getAllCollaborativeUser(context) {
        const { sid } = context.state.profileInfo || {};
        if (!sid) return null;

        return profileApis
            .getAllCollaborativeUser(null, {
                profileId: sid
            })
            .then(
                userList => {
                    context.commit('UPDATE_COLLABORATIVE_USER', userList);
                    return Promise.resolve(userList);
                },
                () => {
                    return Promise.reject(new Error('get all collaborative user failed.'));
                }
            );
    }
};
