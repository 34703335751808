const findEle = (parent, type) => {
    return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type);
};

export default {
    name: 'maxlength',

    bind: (el, binding) => {
        const $inp = findEle(el, 'input');
        el.$inp = $inp;
        $inp.handle = function() {
            const acturlLen = this.value.length;
            const targetLen = binding.value;
            const flag = acturlLen >= targetLen;
            if (flag) {
                el.$inp.value = this.value.substr(0, 3);
                return false;
            }
        };
        $inp.addEventListener('input', $inp.handle);
    },

    undbind: function(el) {
        el.$inp.removeEventListener('input', el.$inp.handle);
    }
};
