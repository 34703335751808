"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getElementPageTop = void 0;
function getElementPageTop(element) {
    var actualTop = element.offsetTop;
    while (element !== window.document.body && element !== null) {
        actualTop += element.offsetTop;
        element = element.offsetParent;
    }
    return actualTop;
}
exports.getElementPageTop = getElementPageTop;
