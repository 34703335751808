"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearBodyLocks = exports.unlock = exports.lock = void 0;
var util_1 = require("./util");
var lockedNum = 0;
var initialClientY = 0;
var initialClientX = 0;
var unLockCallback = null;
var documentListenerAdded = false;
var lockedElements = [];
var eventListenerOptions = (0, util_1.getEventListenerOptions)({ passive: false });
var setOverflowHiddenPc = function () {
    var $body = document.body;
    var bodyStyle = __assign({}, $body.style);
    var scrollBarWidth = window.innerWidth - $body.clientWidth;
    $body.style.overflow = 'hidden';
    $body.style.boxSizing = 'border-box';
    $body.style.paddingRight = "".concat(scrollBarWidth, "px");
    return function () {
        ['overflow', 'boxSizing', 'paddingRight'].forEach(function (x) {
            $body.style[x] = bodyStyle[x] || '';
        });
    };
};
var setOverflowHiddenMobile = function () {
    var $html = document.documentElement;
    var $body = document.body;
    var scrollTop = $html.scrollTop || $body.scrollTop;
    var htmlStyle = __assign({}, $html.style);
    var bodyStyle = __assign({}, $body.style);
    $html.style.height = '100%';
    $html.style.overflow = 'hidden';
    $body.style.top = "-".concat(scrollTop, "px");
    $body.style.width = '100%';
    $body.style.height = 'auto';
    $body.style.position = 'fixed';
    $body.style.overflow = 'hidden';
    return function () {
        $html.style.height = htmlStyle.height || '';
        $html.style.overflow = htmlStyle.overflow || '';
        ['top', 'width', 'height', 'overflow', 'position'].forEach(function (x) {
            $body.style[x] = bodyStyle[x] || '';
        });
        window.scrollTo(0, scrollTop);
    };
};
var preventDefault = function (event) {
    if (!event.cancelable)
        return;
    event.preventDefault();
};
var handleScroll = function (event, targetElement) {
    if (targetElement) {
        var scrollTop = targetElement.scrollTop, scrollLeft = targetElement.scrollLeft, scrollWidth = targetElement.scrollWidth, scrollHeight = targetElement.scrollHeight, clientWidth = targetElement.clientWidth, clientHeight = targetElement.clientHeight;
        var clientX = event.targetTouches[0].clientX - initialClientX;
        var clientY = event.targetTouches[0].clientY - initialClientY;
        var isVertical = Math.abs(clientY) > Math.abs(clientX);
        var isOnTop = clientY > 0 && scrollTop === 0;
        var isOnLeft = clientX > 0 && scrollLeft === 0;
        var isOnRight = clientX < 0 && scrollLeft + clientWidth + 1 >= scrollWidth;
        var isOnBottom = clientY < 0 && scrollTop + clientHeight + 1 >= scrollHeight;
        if ((isVertical && (isOnTop || isOnBottom)) || (!isVertical && (isOnLeft || isOnRight))) {
            return preventDefault(event);
        }
    }
    event.stopPropagation();
    return true;
};
var checkTargetElement = function (targetElement) {
    if (targetElement)
        return;
    if (targetElement === null)
        return;
    if (process.env.NODE_ENV === 'production')
        return;
    console.warn("If scrolling is also required in the floating layer, " + "the target element must be provided.");
};
var lock = function (targetElement) {
    if ((0, util_1.isServer)())
        return;
    checkTargetElement(targetElement);
    if ((0, util_1.detectOS)().ios) {
        // iOS
        if (targetElement) {
            var elementArray = Array.isArray(targetElement) ? targetElement : [targetElement];
            elementArray.forEach(function (element) {
                if (element && lockedElements.indexOf(element) === -1) {
                    element.ontouchstart = function (event) {
                        initialClientY = event.targetTouches[0].clientY;
                        initialClientX = event.targetTouches[0].clientX;
                    };
                    element.ontouchmove = function (event) {
                        if (event.targetTouches.length !== 1)
                            return;
                        handleScroll(event, element);
                    };
                    lockedElements.push(element);
                }
            });
        }
        if (!documentListenerAdded) {
            document.addEventListener('touchmove', preventDefault, eventListenerOptions);
            documentListenerAdded = true;
        }
    }
    else if (lockedNum <= 0) {
        unLockCallback = (0, util_1.detectOS)().android ? setOverflowHiddenMobile() : setOverflowHiddenPc();
    }
    lockedNum += 1;
};
exports.lock = lock;
var unlock = function (targetElement) {
    if ((0, util_1.isServer)())
        return;
    checkTargetElement(targetElement);
    lockedNum -= 1;
    if (lockedNum > 0)
        return;
    if (!(0, util_1.detectOS)().ios && typeof unLockCallback === 'function') {
        unLockCallback();
        return;
    }
    // iOS
    if (targetElement) {
        var elementArray = Array.isArray(targetElement) ? targetElement : [targetElement];
        elementArray.forEach(function (element) {
            var index = lockedElements.indexOf(element);
            if (index !== -1) {
                element.ontouchmove = null;
                element.ontouchstart = null;
                lockedElements.splice(index, 1);
            }
        });
    }
    if (documentListenerAdded) {
        document.removeEventListener('touchmove', preventDefault, eventListenerOptions);
        documentListenerAdded = false;
    }
};
exports.unlock = unlock;
var clearBodyLocks = function () {
    if ((0, util_1.isServer)())
        return;
    lockedNum = 0;
    if (!(0, util_1.detectOS)().ios && typeof unLockCallback === 'function') {
        unLockCallback();
        return;
    }
    // IOS
    if (lockedElements.length) {
        // clear events
        var element = lockedElements.pop();
        while (element) {
            element.ontouchmove = null;
            element.ontouchstart = null;
            element = lockedElements.pop();
        }
    }
    if (documentListenerAdded) {
        document.removeEventListener('touchmove', preventDefault, eventListenerOptions);
        documentListenerAdded = false;
    }
};
exports.clearBodyLocks = clearBodyLocks;
