import config from './interactive-demo.config';
import baseConfig from '@/common/configs/base.config';
import store from '@/store/';

const getDemoStepInfo = function(demoType, stepKey) {
    return (config.steps[demoType] || []).find(step => step.key === stepKey);
};

const getPrevStepInfo = function(demoType, stepKey) {
    const index = (config.steps[demoType] || []).findIndex(step => step.key === stepKey);
    return index !== -1 && (index === 0 ? config.steps[0] : config.steps[demoType][index - 1]);
};

const getCampaignSettingData = function(demoType, dataType) {
    return (config.data[demoType] || [])[dataType];
};

const getDemoProfile = function() {
    const { area } = store.state.main.userInfo || {};
    const { devDemoProfile, devEnDemoProfile, prodEnDemoProfile, prodDemoProfile } = config.demoProfiles;
    const isProd = /^x\.ptengine\.(jp|com)$/i.test(location.host);
    const areaIsEn = area.toLocaleUpperCase() === 'EN';
    return isProd ? (areaIsEn ? prodEnDemoProfile : prodDemoProfile) : areaIsEn ? devEnDemoProfile : devDemoProfile;
};

const getDemoType = function(sid, type) {
    const demoProfile = getDemoProfile();
    return demoProfile.sid === sid && type ? type : '';
};

const initCampaignSettingData = function(demoType, stepKey) {
    console.log('key =====> ', stepKey);
    const list = config.steps[demoType] || [];
    for (let i = 0; i < list.length; i++) {
        const { key, initStepData } = list[i];
        console.log('key initStepData', key);
        initStepData &&
            setTimeout(() => {
                initStepData.call(this);
            }, 300);
        if (key === stepKey) break;
    }
};

const isDemoProfile = function(sid) {
    const demoProfile = getDemoProfile();
    return demoProfile.sid === sid;
};

const addSidToLocalstore = function(sid) {
    const demoSids = localStorage.getItem(baseConfig.INTERACTIVE_DEMO_FLAG) || '';
    const newStore = demoSids && demoSids.split(',').includes(sid) ? demoSids : demoSids + ',' + sid;
    localStorage.setItem(baseConfig.INTERACTIVE_DEMO_FLAG, newStore);
};

const removeLocalstoreSid = function(sid) {
    const demoSids = localStorage.getItem(baseConfig.INTERACTIVE_DEMO_FLAG) || '';
    let list = [];
    if (demoSids) {
        list = demoSids.split(',');
        const index = list.findIndex(o => o === sid);
        index !== -1 && list.splice(index, 1);
    }
    localStorage.setItem(baseConfig.INTERACTIVE_DEMO_FLAG, list.join(','));
};

const localStorageHasDemoSid = function(sid) {
    const demoSids = localStorage.getItem(baseConfig.INTERACTIVE_DEMO_FLAG) || '';
    return demoSids && demoSids.split(',').includes(sid);
};

export default {
    isDemoProfile,
    initCampaignSettingData,
    getDemoStepInfo,
    getDemoType,
    getDemoProfile,
    getPrevStepInfo,
    getCampaignSettingData,
    addSidToLocalstore,
    removeLocalstoreSid,
    localStorageHasDemoSid
};
