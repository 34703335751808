"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mutations = {
    UPDATE_CAMPAIGN_SETTING_CACHE: function (state, campaign) {
        state.campaignSettingCache = campaign;
    },
    UPDATE_CREATE_PORTAL: function (state, newCreatePortal) {
        state.createPortal = newCreatePortal;
    },
    UPDATE_CAMPAIGN_DETAILS: function (state, campaign) {
        state.campaignDetails = campaign;
    },
    UPDATE_DETAILS_DATE_RANGE: function (state, dateRange) {
        state.campaignDetailsDateRange = dateRange;
    },
    UPDATE_DETAILS_ALL_TIMERANGE: function (state, allTimeRange) {
        state.campaignDetailsAllTimeRange = allTimeRange;
    },
    setScrollbarLeft: function (state, scrollBar) {
        if (!scrollBar)
            return false;
        state.scrollbarLeft = scrollBar;
        return true;
    },
    setScrollbarTop: function (state, scrollBar) {
        if (!scrollBar)
            return false;
        state.scrollbarTop = scrollBar;
        return true;
    },
    updateCount: function (state, newCount) {
        state.count = newCount;
    }
};
exports.default = mutations;
