


























import { defineComponent, ref, computed } from '@vue/composition-api';
import { validateOne } from '../../_utils/validators';

type Type = 'text' | 'outline' | 'contain';
type Color = 'blue' | 'green' | 'red' | 'black' | 'purple' | 'whiteness' | 'grey' | 'darkblue' | 'orange';
type Size = 'large' | 'medium' | 'normal' | 'small';
type HTMLType = 'button' | 'submit' | 'reset';
type EmitFn = (evt: Event) => void;
interface IconConfig {
    name: string;
    fill?: string;
    width?: string;
    height?: string;
    size?: string;
}

interface Props {
    type: Type;
    color: Color;
    size: Size;
    loading: boolean | 'before' | 'after' | 'cover';
    disabled: boolean;
    reverse: boolean;
    fullfilled: boolean;
    block: boolean;
    ghost: boolean;
    htmlType: HTMLType;
    href: HTMLAnchorElement['href'];
    target: HTMLAnchorElement['target'];
    icon: String | IconConfig;
    iconAfter: String | IconConfig;
}

const props = {
    type: {
        type: String,
        default: 'contain',
        validator: validateOne(['text', 'outline', 'contain'])
    },
    size: {
        type: String,
        default: 'normal',
        validator: validateOne(['large', 'medium', 'normal', 'small'])
    },
    color: {
        type: String,
        default: 'blue',
        validator: validateOne(['blue', 'green', 'red', 'black', 'purple', 'white', 'grey', 'darkblue', 'orange'])
    },
    reverse: {
        type: Boolean,
        default: false
    },
    ghost: {
        type: Boolean,
        default: false
    },
    block: {
        type: Boolean,
        default: false
    },
    fullfilled: {
        type: Boolean,
        default: false
    },
    htmlType: {
        type: String,
        default: 'button',
        validator: validateOne(['button', 'submit', 'reset'])
    },
    loading: {
        type: [Boolean, String],
        default: false,
        validator: validateOne(['before', 'after', 'cover', false, true])
    },
    disabled: {
        type: Boolean,
        default: false
    },
    href: [String, Boolean],
    target: String,
    icon: [String, Object],
    iconAfter: [String, Object]
};

const colorTypeMap = {
    // sass在当前webpack配置下打包时， 循环语句里的变量white、black会被编译为#fff, #000
    white: 'whiteness',
    black: 'dark',
    blue: 'blue',
    green: 'green',
    red: 'red',
    purple: 'purple',
    darkblue: 'darkblue',
    orange: 'orange',
    grey: 'grey'
};

export default defineComponent({
    name: 'PtButton',
    props,
    emits: ['click'],
    setup(props: Props, { emit, slots }) {
        const buttonRef = ref<HTMLButtonElement>();
        const handleClick: EmitFn = evt => {
            emit('click', evt);
            if (buttonRef.value) buttonRef.value?.blur();
        };

        const hasIconBefore = computed(() => Boolean(props.icon || slots.icon));
        const hasIconAfter = computed(() => !(props.icon || slots.icon) && Boolean(props.iconAfter || slots.iconAfter));
        const icon = computed(() => props.icon || props.iconAfter);
        const iconConfig = computed(() => ({
            name: (icon.value as IconConfig)?.name ?? icon.value,
            style:
                typeof icon !== 'object'
                    ? undefined
                    : {
                          ...icon.value,
                          ...{
                              width:
                                  (icon.value as IconConfig)?.size ??
                                  (icon.value as IconConfig)?.width ??
                                  (props.icon as IconConfig)?.height ??
                                  '16px',
                              height:
                                  (icon.value as IconConfig)?.size ??
                                  (icon.value as IconConfig)?.height ??
                                  (props.icon as IconConfig)?.width ??
                                  '16px'
                          }
                      }
        }));

        const DOMAttributes = computed(() => ({
            class: [
                'pt-button',
                `pt-button--${props.type}--${colorTypeMap[props.color]}${props.reverse ? '--reverse' : ''}`,
                `pt-button--${props.size}`,
                {
                    'pt-button--ghost': props.ghost,
                    'pt-button--block': props.block,
                    'pt-button--fullfilled': props.fullfilled,
                    'is-disabled': Boolean(props.loading) || props.disabled,
                    'is-loading-before': props.loading === 'before' || (props.loading === true && !hasIconAfter.value),
                    'is-loading-after': props.loading === 'after' || (props.loading === true && hasIconAfter.value),
                    'is-loading-cover': props.loading === 'cover'
                }
            ],
            disabled: props.loading || props.disabled,
            type: props.htmlType,
            href: typeof props.href === 'string' && props.href !== '' ? props.href : undefined,
            target: props.href ? props.target : undefined
        }));

        return {
            buttonRef,
            handleClick,
            DOMAttributes,
            iconConfig,
            hasIconBefore,
            hasIconAfter
        };
    }
});
