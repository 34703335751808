export const highchartsOthersColor = '#C8EAFC';

export const highchartsColorList = [
    '#58C70F',
    '#BF826E',
    '#7FE7D6',
    '#FF7558',
    '#6D7A04',
    '#E2B47C',
    '#804774',
    '#F2CA52',
    '#775F47',
    '#E95D79'
];

export const highchartsBaseOptions = {
    chart: {
        zoomType: null,
        marginTop: null,
        type: 'arespline',
        backgroundColor: 'transparent',
        style: {
            color: '#bbb'
        },
        events: {
            load: function() {},
            redraw: function() {}
        }
    },
    colors: highchartsColorList,
    title: {
        text: undefined
    },
    tooltip: {
        backgroundColor: 'transparent',
        hideDelay: 0,
        shadow: false,
        borderWidth: 0,
        shape: 'square',
        useHTML: true,
        shared: true,
        outside: true,
        formatter: function() {
            const points = this.points || [this.point];
            // let tooltips = `<span><b>${points[0].series.name}</b></span><br/>`;
            let tooltips = points
                .map(point => {
                    const { color, key, name, x, y } = point;
                    return `<span style="color:${color}">■  </span><span>${key || name || x}:</span> <b>${y}</b><br/>`;
                })
                .join('');
            return `
                <div class="chart-highchart-tooltip-shared">
                    <div class="chart-highchart-tooltip-shared-data clearfix">
                        ${tooltips}
                    </div>
                </div>
                `;
        },
        style: {
            color: '#999999',
            zIndex: '999',
            left: '28px',
            top: '8px',
            width: 'auto',
            height: 'auto',
            fontFamily: '"Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif'
        }
    },
    plotOptions: {
        series: {
            animation: false,
            // enableMouseTracking: false,
            borderWidth: 0,
            lineWidth: 2,
            states: {
                hover: {
                    halo: null,
                    lineWidth: 3
                }
            },
            marker: {
                symbol: 'circle',
                lineColor: null,
                radius: 3,
                enabled: false,
                states: {
                    hover: {
                        fillColor: '#FFFFFF',
                        lineWidth: 3,
                        lineWidthPlus: 3,
                        radius: 3,
                        radiusPlus: 3
                    }
                }
            },
            states: {
                hover: {
                    lineWidthPlus: 0,
                    radius: 3,
                    enabled: true,
                    halo: {
                        size: 0,
                        opacity: 0.5
                    }
                }
            },
            turboThreshold: 2000 //series数据量上线
        },
        line: {
            dataLabels: {
                enabled: false
            }
        },
        areaspline: {
            fillOpacity: 0.09
        },
        area: {
            fillOpacity: 0.09
        }
    },
    legend: {
        enabled: false
    },
    xAxis: {
        type: 'category',
        labels: {
            enabled: true, // 不显示x轴刻度标签
            style: {
                color: '#bdbdbd',
                fontSize: '12px',
                zIndex: '10'
            },
            useHTML: false
        },
        title: {
            enabled: false, // 不显示x轴名称
            text: '',
            style: {
                color: '#999999',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }
        },
        lineColor: '#f0f0f0',
        gridLineColor: '#f0f0f0',
        minorGridLineDashStyle: 'longdash',
        gridLineWidth: 0, // 不显示格线
        tickmarkPlacement: 'on', //两端去除边距
        minorTickWidth: 1,
        tickLength: 0, // 设置刻度线长度
        tickPositioner: function() {
            // 防止x轴的时间刻度超出, 导致显示不下
            var self = this;
            var positioner = this.tickPositions.filter(function(value) {
                return value >= self.dataMin && value <= self.dataMax;
            });
            return positioner;
        }
    },
    yAxis: {
        labels: {
            style: {
                color: '#bbb',
                fontSize: '12px'
            }
        },
        title: {
            enabled: false // 不显示x轴名称
        },
        gridLineColor: '#f0f0f0',
        tickLength: 20,
        tickColor: '#f0f0f0',
        tickWidth: 1,
        tickmarkPlacement: 'on',
        tickPixelInterval: '100',
        floor: 0
    },
    credits: {
        enabled: false
    },
    navigator: {
        enabled: false
    },
    exporting: {
        enabled: false
    }
};
