import { widgetWrapPadding } from '../../configs/creative.config';
import { isNullOrUndefined } from '../../utils/creative.util';
import blocksConfig from '../../configs/blocks.config';

function getSubModel(content, blockSettings = {}) {
    const config = blocksConfig.find(item => item.code === content.code);
    const { alignment } = blockSettings;
    if (config) {
        const block = {
            blockId: content.value.id,
            blockContent: content.value.content,
            blockSettings: Object.assign({}, content.value.settings, { alignment })
        };
        return {
            dom: config.services.getHtml(block),
            style: config.services.getStyle(block)
        };
    }
}

export default {
    getHtml(block) {
        const id = block.blockId;
        const { layout } = block.blockSettings;
        const dom = {};
        block.blockContent.forEach(content => {
            dom[content.code] = getSubModel(content).dom;
        });

        let leftCellDom = dom.image;
        let rightCellDom = `${dom.text}<div id="${id}-cell-spacing"></div>${dom.button}`;
        if (['imgRight', 'imgDown'].includes(layout)) {
            leftCellDom = rightCellDom;
            rightCellDom = dom.image;
        }
        return `
            <div id="${id}" data-engage-block="classic">
                <div id="${id}-cell-left">${leftCellDom}</div>
                <div id="${id}-cell-right">${rightCellDom}</div>
            </div>
        `;
    },
    
    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        const { layout, alignment, spacing } = settings;
        let { backgroundColor, marginTop, marginBottom, noPadding } = settings;
        const padding = noPadding ? 0 : `0 ${paddingLeft}px 0 ${paddingLeft}px`;
        const subModelStyle = block.blockContent.map(content => {
            let style = getSubModel(content, settings).style;
            if (content.code === 'button') {
                style = style.replace('text-align: center;', `text-align: ${alignment};`);
            }
            return style;
        }).join('');
        if (isNullOrUndefined(marginTop)) marginTop = 0;
        if (isNullOrUndefined(marginBottom)) marginBottom = 0;
        if (isNullOrUndefined(backgroundColor)) backgroundColor = 'transparent';
        
        let flexStyle = '';
        let leftCellPadding = `padding-right: ${spacing}px;`;
        let rightCellMargin = '';
        let leftFlexWidth = `
            display: flex;
            align-items: center;
        `;
        let rightFlexWidth = `
            flex: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        `;
        if (['imgLeft', 'imgRight'].includes(layout)) {
            flexStyle = `
                display: flex;
                display: -webkit-flex;
                display: -ms-flexbox;
                flex-direction: row;
            `;

            if (layout === 'imgRight') {
                leftFlexWidth = rightFlexWidth;
                rightFlexWidth = `
                    display: flex;
                    align-items: center;
                `;
            }
        } else {
            leftFlexWidth = '';
            rightFlexWidth = '';
            leftCellPadding = '';
            rightCellMargin = `margin-top: ${spacing}px;`;
        }

        let leftCellAlign = '';
        let rightCellAlign = `text-align: ${alignment};`;
        if (['imgRight', 'imgDown'].includes(layout)) {
            leftCellAlign = rightCellAlign;
            rightCellAlign = '';
        }

        return `
            #${id} {
                ${flexStyle}
                padding: ${padding};
                margin: ${marginTop}px 0 ${marginBottom}px;
                background-color: ${backgroundColor};
            }
            #${id}-cell-spacing {
                width: 100%;
                height: 20px;
            }
            #${id}-cell-left {
                ${leftCellPadding}
                ${leftCellAlign}
                ${leftFlexWidth}
            }
            #${id}-cell-right {
                ${rightCellAlign}
                ${rightCellMargin}
                ${rightFlexWidth}
            }
            ${subModelStyle}
        `;
    }
};
