<template>
    <div class="block-settings" :class="{ 'has-tab': !isSubModel }">
        <template v-if="!isSubModel">
            <div class="block-settings__header">
                <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
                <h3>{{ $t(block.blockName) }}</h3>
            </div>

            <div class="block-settings__tab">
                <settings-tab :tab="currentTab" @change="handleTabChange"></settings-tab>
            </div>
        </template>

        <div class="block-settings__content-tab" v-if="columnHasMultiseriate && currentTab === 'content'">
            <el-tabs v-model="currentContentCode" @tab-click="handleContentTabChange">
                <el-tab-pane
                    v-for="(item, $index) in content"
                    :key="item.code"
                    :label="$t('creative_editor.button_num', { number: $index + 1 })"
                    :name="item.code"
                ></el-tab-pane>
            </el-tabs>
        </div>

        <div class="block-settings__container" :class="{ 'has-content-tab': columnHasMultiseriate }">
            <div class="block-settings__item-wrap" v-if="currentTab === 'design'">
                <!-- Type -->
                <kits
                    v-if="currentTab === 'content'"
                    type="select"
                    :title="$t('creative_editor.button_type')"
                    target="type"
                    :value="currentContentValue.type"
                    :options="typeOptions"
                    @change="handleTypeChange"
                ></kits>

                <template v-if="currentContentValue.text">
                    <!-- Font color -->
                    <kits
                        type="colorPicker"
                        :title="$t('creative_editor.item_font_color')"
                        target="fontColor"
                        :key="'fontColor_' + currentContentCode"
                        :value="currentContentValue.fontColor"
                        @change="handleKitChange"
                    ></kits>
                    <!-- Font size & Stroke weight-->
                    <div class="block-settings__item-group two-cloumn">
                        <kits
                            type="inputNumber"
                            :title="$t('creative_editor.item_font_size')"
                            target="fontSize"
                            :key="'fontSize_' + currentContentCode"
                            :min="fontSizeOptions.min"
                            :max="fontSizeOptions.max"
                            :value="currentContentValue.fontSize"
                            @change="handleKitChange"
                        ></kits>
                        <kits
                            type="select"
                            :title="$t('creative_editor.item_stroke_weight')"
                            target="fontWeight"
                            :key="'fontWeight_' + currentContentCode"
                            :value="currentContentValue.fontWeight"
                            :options="fontWeightOptions"
                            @change="handleKitChange"
                        ></kits>
                    </div>

                    <div class="block-settings__hr"></div>

                    <template v-if="currentTypeIsButton">
                        <!-- Button background color -->
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.item_button_colort')"
                            target="backgroundColor"
                            :key="'btnBackgroundColor_' + currentContentCode"
                            :value="currentContentValue.backgroundColor"
                            v-model="currentContentValue.backgroundColor"
                            @change="handleKitChange"
                        ></kits>

                        <!-- Padding -->
                        <kits
                            v-for="item in paddingOptions.items"
                            :key="item.code + '_' + currentContentCode"
                            type="slider"
                            :title="item.name"
                            :target="item.code"
                            :min="paddingOptions.min"
                            :max="paddingOptions.max"
                            :value="currentContentValue[item.code]"
                            @change="handleKitChange"
                        ></kits>
                        <div class="block-settings__hr"></div>
                    </template>

                    <!-- Action -->
                    <div class="block-settings__item" v-if="currentTab === 'content'">
                        <div class="block-settings__item-title">{{ $t('creative_editor.creative_button_action') }}</div>
                        <div class="block-settings__item-container">
                            <div
                                class="block-action"
                                v-for="item in actionEventsOptions"
                                :key="item.code + '_' + currentContentCode"
                            >
                                <div class="block-action__radio">
                                    <el-radio
                                        v-model="currentContentValue.action.type"
                                        :label="item.code"
                                        @change="handleContentChange({ needValidator: true })"
                                        >{{ item.name }}</el-radio
                                    >
                                    <el-popover
                                        placement="bottom"
                                        width="300"
                                        trigger="hover"
                                        v-if="item.tip"
                                        :visible-arrow="false"
                                        :content="item.tip"
                                    >
                                        <pt-icon
                                            slot="reference"
                                            class="help-icon"
                                            icon="pt-icon--help-new"
                                            :iconStyle="{ width: '14px', height: '14px' }"
                                        ></pt-icon>
                                    </el-popover>
                                </div>
                                <div
                                    class="block-action__value"
                                    v-if="item.code === 'url' && currentContentValue.action.type === 'url'"
                                >
                                    <span
                                        class="block-action__value-sign"
                                        v-show="currentContentValue.action.type === 'url'"
                                        >*</span
                                    >
                                    <pt-input
                                        type="text"
                                        size="big"
                                        :disabled="currentContentValue.action.type !== 'url'"
                                        :placeholder="placeholder.url"
                                        :warning="checkActionResult && checkActionResult.hasError"
                                        :warningText="checkActionResult && checkActionResult.errMsg"
                                        v-model="currentContentValue.action.url"
                                        v-validator="urlValidatorRule"
                                        @blur="handleUrlIptBlur"
                                        @enter="handleUrlIptBlur"
                                        @change="handleContentChange({ needValidator: true })"
                                    ></pt-input>
                                    <el-checkbox
                                        :disabled="currentContentValue.action.type !== 'url'"
                                        v-model="currentContentValue.action.openNewTab"
                                        @change="handleContentChange"
                                        >{{ $t('creative_editor.creative_open_new') }}</el-checkbox
                                    >
                                </div>
                                <div
                                    class="block-action__value"
                                    v-if="item.code === 'tel' && currentContentValue.action.type === 'tel'"
                                >
                                    <span
                                        class="block-action__value-sign"
                                        v-show="currentContentValue.action.type === 'tel'"
                                        >*</span
                                    >
                                    <pt-input
                                        type="text"
                                        size="big"
                                        :maxlength="15"
                                        :showWordLimit="true"
                                        :disabled="currentContentValue.action.type !== 'tel'"
                                        :placeholder="placeholder.tel"
                                        :warning="checkActionResult && checkActionResult.hasError"
                                        :warningText="checkActionResult && checkActionResult.errMsg"
                                        v-model="currentContentValue.action.tel"
                                        v-validator="telValidatorRule"
                                        @change="handleContentChange({ needValidator: true })"
                                    ></pt-input>
                                </div>
                                <div
                                    class="block-action__value"
                                    v-if="
                                        item.code === 'gotoWidget' && currentContentValue.action.type === 'gotoWidget'
                                    "
                                >
                                    <el-select
                                        size="small"
                                        v-model="currentContentValue.action.gotoWidget"
                                        :class="{
                                            'select-error': checkActionResult && checkActionResult.hasError
                                        }"
                                        :placeholder="$t('creative_editor.goto_widget_placeholder')"
                                        @visible-change="handleActionSelectVisibleChange"
                                        @change="handleContentChange({ needValidator: true })"
                                    >
                                        <el-option
                                            v-for="item in currentGotoWidgetOptions"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                    <div
                                        class="add-step__err-tips"
                                        v-if="checkActionResult && checkActionResult.hasError"
                                    >
                                        {{ checkActionResult && checkActionResult.errMsg }}
                                    </div>
                                    <div class="add-step__tips" v-show="addStepText">
                                        <img
                                            v-if="language === 'en'"
                                            alt="add step tips"
                                            src="~@assets/images/creative/add-step-tips-en.png"
                                        />
                                        <img
                                            v-else-if="language === 'jp'"
                                            alt="add step tips"
                                            src="~@assets/images/creative/add-step-tips-jp.png"
                                        />
                                        <img
                                            v-else
                                            alt="add step tips"
                                            src="~@assets/images/creative/add-step-tips-zh.png"
                                        />
                                        <p>{{ $t('creative_editor.goto_widget_tips') }}</p>
                                        <pt-button @click="gotoWidget">
                                            {{ addStepText }}
                                        </pt-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="currentTypeIsButton">
                        <div class="block-settings__hr" v-if="currentTab === 'content'"></div>

                        <!-- Round corners -->
                        <kits
                            type="slider"
                            :title="$t('creative_editor.corner_radius')"
                            target="borderRadius"
                            :hasInput="false"
                            :min="borderRadiusOptions.min"
                            :max="borderRadiusOptions.max"
                            :value="currentContentValue.borderRadius"
                            @change="handleKitChange"
                        ></kits>
                        <!-- Borders & Width -->
                        <div class="block-settings__item-group two-cloumn">
                            <kits
                                type="colorPicker"
                                :title="$t('creative_editor.item_border_color')"
                                target="borderColor"
                                :hasInput="false"
                                :value="currentContentValue.borderColor"
                                @change="handleKitChange"
                            ></kits>
                            <kits
                                type="inputNumber"
                                :title="$t('creative_editor.item_button_width')"
                                target="borderWidth"
                                :min="borderWidthOptions.min"
                                :max="borderWidthOptions.max"
                                :value="currentContentValue.borderWidth"
                                @change="handleKitChange"
                            ></kits>
                        </div>
                    </template>
                </template>
            </div>
            <pt-scrollbar ref="blockScrollbar" v-else>
                <div class="block-settings__item-wrap">
                    <!-- content -->
                    <template v-if="currentTab === 'content'">
                        <!-- Type -->
                        <kits
                            v-if="currentTab === 'content'"
                            type="select"
                            :title="$t('creative_editor.button_type')"
                            target="type"
                            :value="currentContentValue.type"
                            :options="typeOptions"
                            @change="handleTypeChange"
                        ></kits>

                        <div class="block-settings__hr"></div>

                        <!-- Text -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.item_text') }}</div>
                            <div class="block-settings__item-container">
                                <el-input
                                    size="small"
                                    :placeholder="placeholder.text"
                                    v-model="currentContentValue.text"
                                    @change="handleContentChange({ needValidator: true })"
                                ></el-input>
                            </div>
                        </div>

                        <template v-if="currentContentValue.text">
                            <!-- Font color -->
                            <kits
                                type="colorPicker"
                                :title="$t('creative_editor.item_font_color')"
                                target="fontColor"
                                :key="'fontColor_' + currentContentCode"
                                :value="currentContentValue.fontColor"
                                @change="handleKitChange"
                            ></kits>
                            <!-- Font size & Stroke weight-->
                            <div class="block-settings__item-group two-cloumn">
                                <kits
                                    type="inputNumber"
                                    :title="$t('creative_editor.item_font_size')"
                                    target="fontSize"
                                    :key="'fontSize_' + currentContentCode"
                                    :min="fontSizeOptions.min"
                                    :max="fontSizeOptions.max"
                                    :value="currentContentValue.fontSize"
                                    @change="handleKitChange"
                                ></kits>
                                <kits
                                    type="select"
                                    :title="$t('creative_editor.item_stroke_weight')"
                                    target="fontWeight"
                                    :key="'fontWeight_' + currentContentCode"
                                    :value="currentContentValue.fontWeight"
                                    :options="fontWeightOptions"
                                    @change="handleKitChange"
                                ></kits>
                            </div>

                            <div class="block-settings__hr"></div>

                            <template v-if="currentTypeIsButton">
                                <!-- Button background color -->
                                <kits
                                    type="colorPicker"
                                    :title="$t('creative_editor.item_button_colort')"
                                    target="backgroundColor"
                                    :key="'btnBackgroundColor_' + currentContentCode"
                                    :value="currentContentValue.backgroundColor"
                                    v-model="currentContentValue.backgroundColor"
                                    @change="handleKitChange"
                                ></kits>

                                <!-- Padding -->
                                <kits
                                    v-for="item in paddingOptions.items"
                                    :key="item.code + '_' + currentContentCode"
                                    type="slider"
                                    :title="item.name"
                                    :target="item.code"
                                    :min="paddingOptions.min"
                                    :max="paddingOptions.max"
                                    :value="currentContentValue[item.code]"
                                    @change="handleKitChange"
                                ></kits>
                                <div class="block-settings__hr"></div>
                            </template>

                            <!-- Action -->
                            <div class="block-settings__item" v-if="currentTab === 'content'">
                                <div class="block-settings__item-title">
                                    {{ $t('creative_editor.creative_button_action') }}
                                </div>
                                <div class="block-settings__item-container">
                                    <div
                                        class="block-action"
                                        v-for="item in actionEventsOptions"
                                        :key="item.code + '_' + currentContentCode"
                                    >
                                        <div class="block-action__radio">
                                            <el-radio
                                                v-model="currentContentValue.action.type"
                                                :label="item.code"
                                                @change="handleContentChange({ needValidator: true })"
                                                >{{ item.name }}</el-radio
                                            >
                                            <el-popover
                                                placement="bottom"
                                                width="300"
                                                trigger="hover"
                                                v-if="item.tip"
                                                :visible-arrow="false"
                                                :content="item.tip"
                                            >
                                                <pt-icon
                                                    slot="reference"
                                                    class="help-icon"
                                                    icon="pt-icon--help-new"
                                                    :iconStyle="{ width: '14px', height: '14px' }"
                                                ></pt-icon>
                                            </el-popover>
                                        </div>
                                        <div
                                            class="block-action__value"
                                            v-if="item.code === 'url' && currentContentValue.action.type === 'url'"
                                        >
                                            <span
                                                class="block-action__value-sign"
                                                v-show="currentContentValue.action.type === 'url'"
                                                >*</span
                                            >
                                            <pt-input
                                                type="text"
                                                size="big"
                                                :disabled="currentContentValue.action.type !== 'url'"
                                                :placeholder="placeholder.url"
                                                :warning="checkActionResult && checkActionResult.hasError"
                                                :warningText="checkActionResult && checkActionResult.errMsg"
                                                v-model="currentContentValue.action.url"
                                                v-validator="urlValidatorRule"
                                                @blur="handleUrlIptBlur"
                                                @enter="handleUrlIptBlur"
                                                @change="handleContentChange({ needValidator: true })"
                                            ></pt-input>
                                            <el-checkbox
                                                :disabled="currentContentValue.action.type !== 'url'"
                                                v-model="currentContentValue.action.openNewTab"
                                                @change="handleContentChange"
                                                >{{ $t('creative_editor.creative_open_new') }}</el-checkbox
                                            >
                                        </div>
                                        <div
                                            class="block-action__value"
                                            v-if="item.code === 'tel' && currentContentValue.action.type === 'tel'"
                                        >
                                            <span
                                                class="block-action__value-sign"
                                                v-show="currentContentValue.action.type === 'tel'"
                                                >*</span
                                            >
                                            <pt-input
                                                type="text"
                                                size="big"
                                                :maxlength="15"
                                                :showWordLimit="true"
                                                :disabled="currentContentValue.action.type !== 'tel'"
                                                :placeholder="placeholder.tel"
                                                :warning="checkActionResult && checkActionResult.hasError"
                                                :warningText="checkActionResult && checkActionResult.errMsg"
                                                v-model="currentContentValue.action.tel"
                                                v-validator="telValidatorRule"
                                                @change="handleContentChange({ needValidator: true })"
                                            ></pt-input>
                                        </div>
                                        <div
                                            class="block-action__value"
                                            v-if="
                                                item.code === 'gotoWidget' &&
                                                    currentContentValue.action.type === 'gotoWidget'
                                            "
                                        >
                                            <el-select
                                                size="small"
                                                v-model="currentContentValue.action.gotoWidget"
                                                :class="{
                                                    'select-error': checkActionResult && checkActionResult.hasError
                                                }"
                                                :placeholder="$t('creative_editor.goto_widget_placeholder')"
                                                @visible-change="handleActionSelectVisibleChange"
                                                @change="handleContentChange({ needValidator: true })"
                                            >
                                                <el-option
                                                    v-for="item in currentGotoWidgetOptions"
                                                    :key="item.code"
                                                    :label="item.name"
                                                    :value="item.code"
                                                ></el-option>
                                            </el-select>
                                            <div
                                                class="add-step__err-tips"
                                                v-if="checkActionResult && checkActionResult.hasError"
                                            >
                                                {{ checkActionResult && checkActionResult.errMsg }}
                                            </div>
                                            <div class="add-step__tips" v-show="addStepText">
                                                <img
                                                    v-if="language === 'en'"
                                                    alt="add step tips"
                                                    src="~@assets/images/creative/add-step-tips-en.png"
                                                />
                                                <img
                                                    v-else-if="language === 'jp'"
                                                    alt="add step tips"
                                                    src="~@assets/images/creative/add-step-tips-jp.png"
                                                />
                                                <img
                                                    v-else
                                                    alt="add step tips"
                                                    src="~@assets/images/creative/add-step-tips-zh.png"
                                                />
                                                <p>{{ $t('creative_editor.goto_widget_tips') }}</p>
                                                <pt-button  @click="gotoWidget">
                                                    {{ addStepText }}
                                                </pt-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <template v-if="currentTypeIsButton">
                                <div class="block-settings__hr" v-if="currentTab === 'content'"></div>

                                <!-- Round corners -->
                                <kits
                                    type="slider"
                                    :title="$t('creative_editor.corner_radius')"
                                    target="borderRadius"
                                    :hasInput="false"
                                    :min="borderRadiusOptions.min"
                                    :max="borderRadiusOptions.max"
                                    :value="currentContentValue.borderRadius"
                                    @change="handleKitChange"
                                ></kits>
                                <!-- Borders & Width -->
                                <div class="block-settings__item-group two-cloumn">
                                    <kits
                                        type="colorPicker"
                                        :title="$t('creative_editor.item_border_color')"
                                        target="borderColor"
                                        :hasInput="false"
                                        :value="currentContentValue.borderColor"
                                        @change="handleKitChange"
                                    ></kits>
                                    <kits
                                        type="inputNumber"
                                        :title="$t('creative_editor.item_button_width')"
                                        target="borderWidth"
                                        :min="borderWidthOptions.min"
                                        :max="borderWidthOptions.max"
                                        :value="currentContentValue.borderWidth"
                                        @change="handleKitChange"
                                    ></kits>
                                </div>
                            </template>
                        </template>
                    </template>

                    <!-- settings -->
                    <template v-else-if="currentTab === 'settings'">
                        <!-- Number of columns -->
                        <kits
                            key="buttonNumber"
                            type="select"
                            :title="$t('creative_editor.buttons_numbers')"
                            target="buttonNumber"
                            :value="settings.buttonNumber"
                            :options="numberOptions"
                            @change="handleNumberChange"
                        ></kits>

                        <!-- layout -->
                        <kits
                            v-if="columnHasMultiseriate"
                            type="iconButton"
                            :title="$t('creative_editor.item_layout')"
                            target="layout"
                            :options="layoutOptions"
                            :value="settings.layout"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <!-- spacing -->
                        <kits
                            v-if="columnHasMultiseriate"
                            type="slider"
                            :title="$t('creative_editor.item_spacing')"
                            target="spacing"
                            :min="spaceingOptions.min"
                            :max="spaceingOptions.max"
                            :value="settings.spacing"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <!-- Alignment -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.creative_alignment') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <el-radio-group
                                    size="small"
                                    v-model="settings.alignment"
                                    @change="handleSettingsChange"
                                >
                                    <el-radio-button
                                        v-for="item in alignmentOptions"
                                        :key="item.code"
                                        :label="item.code"
                                    >
                                        <pt-icon :icon="'pt-icon--' + item.icon"></pt-icon>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>

                        <!-- Background color -->
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.creative_widget_bg')"
                            target="backgroundColor"
                            :value="settings.backgroundColor"
                            v-model="settings.backgroundColor"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <!-- Margin top -->
                        <kits
                            type="slider"
                            :title="$t('creative_editor.block_margin_top')"
                            target="marginTop"
                            :hasInput="false"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            :value="settings.marginTop"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <!-- Margin bottom -->
                        <kits
                            type="slider"
                            :title="$t('creative_editor.block_margin_bottom')"
                            target="marginBottom"
                            :hasInput="false"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            :value="settings.marginBottom"
                            @change="handleKitSettingsChange"
                        ></kits>
                    </template>
                    <!-- form -->
                    <template v-else-if="currentTab === 'form'">
                        <div class="block-settings__item">
                            <!-- Text -->
                            <div class="block-settings__item-title">{{ $t('creative_editor.item_text') }}</div>
                            <div class="block-settings__item-container">
                                <el-input
                                    size="small"
                                    :placeholder="placeholder.text"
                                    v-model="currentContentValue.text"
                                    @change="handleContentChange({ needValidator: true })"
                                ></el-input>
                            </div>
                        </div>
                        <div class="block-settings__hr"></div>

                        <!-- Success Behavior -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.item_submitted') }}</div>
                            <div class="block-settings__item-container">
                                <el-select
                                    size="small"
                                    v-model="currentContentValue.action.type"
                                    @change="handleSuccessBehaviourChange"
                                >
                                    <el-option
                                        v-for="item in successOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="block-settings__item" v-if="currentContentValue.action.type === 'url'">
                            <div class="block-settings__item-title">{{ $t('creative_editor.url') }}</div>
                            <div class="block-settings__item-container">
                                <pt-input
                                    type="text"
                                    size="big"
                                    :placeholder="$t('creative_editor.creative_weblink_placeholder')"
                                    :warning="checkActionResult && checkActionResult.hasError"
                                    :warningText="checkActionResult && checkActionResult.errMsg"
                                    v-model="currentContentValue.action.url"
                                    v-validator="urlValidatorRule"
                                    @blur="handleUrlIptBlur"
                                    @enter="handleUrlIptBlur"
                                    @change="handleContentChange({ needValidator: true })"
                                ></pt-input>
                            </div>
                        </div>
                        <div class="block-settings__item" v-else-if="currentContentValue.action.type === 'gotoWidget'">
                            <div class="add-step__tips">
                                <img
                                    v-if="language === 'en'"
                                    alt="add step tips"
                                    src="~@assets/images/creative/add-step-tips-en.png"
                                />
                                <img
                                    v-else-if="language === 'jp'"
                                    alt="add step tips"
                                    src="~@assets/images/creative/add-step-tips-jp.png"
                                />
                                <img v-else alt="add step tips" src="~@assets/images/creative/add-step-tips-zh.png" />
                                <p>{{ $t('creative_editor.form_submitted_tips') }}</p>
                                <pt-button  @click="gotoWidget">
                                    {{ $t('creative_editor.item_submitted_info') }}
                                </pt-button>
                            </div>
                        </div>
                    </template>
                </div>
            </pt-scrollbar>
        </div>
    </div>
</template>

<script>
    import config from './button.config';
    import SettingsTab from '../SettingsTab';
    import Kits from '../../kits/Kits.vue';
    import cloneUtils from '@common/utils/clone.utils';

    import { tabOptions, formDesignOptions, widgetsMinCount, widgetsMaxCount } from '../../configs/creative.config';

    export default {
        name: 'ButtonSettings',

        props: {
            widgetIndex: Number,
            block: {
                type: Object,
                required: true
            },
            // 是否为子组件
            isSubModel: {
                type: Boolean,
                default: function() {
                    return false;
                }
            },
            //是否是表单提交按钮
            refer: String,
            tab: String
        },

        data() {
            const currentTab = this.tab || '';
            const defaultTab =
                this.refer && this.refer === 'form'
                    ? formDesignOptions.find(item => item.code === currentTab)
                    : tabOptions.find(item => item.default);
            const defaultContentTab = config.content.find(item => item.default);
            const {
                placeholder,
                typeOptions,
                successOptions,
                marginOptions,
                paddingOptions,
                fontSizeOptions,
                alignmentOptions,
                fontWeightOptions,
                borderWidthOptions,
                borderRadiusOptions,
                actionEventsOptions,
                gotoWidgetOptions,
                numberOptions,
                layoutOptions,
                spaceingOptions,
                urlValidatorRule,
                telValidatorRule,
                gotoWidgetValidatorRule,
                blockContentValidatorFun
            } = config;
            return {
                defaultTab,
                defaultContentTab,

                placeholder,
                typeOptions,
                successOptions,
                marginOptions,
                paddingOptions,
                fontSizeOptions,
                alignmentOptions,
                fontWeightOptions,
                borderWidthOptions,
                borderRadiusOptions,
                actionEventsOptions,
                gotoWidgetOptions,
                numberOptions,
                layoutOptions,
                spaceingOptions,
                urlValidatorRule,
                telValidatorRule,
                gotoWidgetValidatorRule,
                blockContentValidatorFun,

                currentTab: '',
                currentContentCode: '',

                content: [],
                settings: {},

                checkActionResult: null,
                language: 'en'
            };
        },

        computed: {
            currentIsFirstStep() {
                return this.widgetIndex === widgetsMinCount - 1;
            },

            currentIsLastStep() {
                return this.widgetIndex === widgetsMaxCount - 1;
            },

            currentContentValue() {
                const contentConfig = this.content.find(item => item.code === this.currentContentCode);
                return (contentConfig && contentConfig.value) || {};
            },

            currentTypeIsButton() {
                return this.currentContentValue && this.currentContentValue.type === 'button';
            },

            currentGotoWidgetOptions() {
                return this.gotoWidgetOptions.reduce((acc, cur) => {
                    const showIt = cur.showIt(this.currentIsFirstStep, this.currentIsLastStep);
                    showIt && acc.push(cur);
                    return acc;
                }, []);
            },

            columnHasMultiseriate() {
                return this.settings.buttonNumber !== 'one';
            },

            addStepText() {
                const { type, gotoWidget } = this.currentContentValue.action;
                const opt = this.gotoWidgetOptions.find(item => item.code === gotoWidget);
                return (type === 'gotoWidget' && opt && opt.editBtnText) || '';
            }
        },

        created() {
            this.init();
        },

        methods: {
            init() {
                this.currentTab = this.defaultTab.code;
                this.currentContentCode = this.defaultContentTab.code;
                this.content = this.block.blockContent;
                this.settings = this.block.blockSettings;
                this.checkActionResult = this.checkAction();
                //判断如果是form中的button aciton type默认为gotoWidget
                if (this.refer === 'form' && this.content[0].value.action.type === 'close') {
                    this.content[0].value.action.type = 'gotoWidget';
                    this.content[0].value.action.gotoWidget = 'next';
                }
            },

            checkAction() {
                const { invalid, invalidRule } = this.blockContentValidatorFun(this.currentContentValue) || {};
                return {
                    hasError: invalid,
                    errMsg: (invalidRule && invalidRule.errMsg) || ''
                };
            },

            handleBack() {
                this.$emit('back');
            },

            handleTabChange(tab) {
                this.currentTab = tab;

                if (tab === 'content' && !this.columnHasMultiseriate) {
                    this.currentContentCode = this.content[0].code;
                }
                this.$refs.blockScrollbar && this.$refs.blockScrollbar.scrollToPos('top');
            },

            handleContentTabChange() {
                this.$nextTick(() => {
                    this.checkActionResult = this.checkAction();
                });
            },

            handleUrlIptBlur() {
                // 如果没填协议 自己给补上
                const reg = /^(http|https:\/\/)/;
                let url = this.currentContentValue.action.url;
                if (url !== '' && !reg.test(url)) {
                    this.$set(this.currentContentValue.action, 'url', `http://${url}`);
                }
            },

            handleLayoutClick(layout) {
                this.settings.layout = layout;
                this.handleSettingsChange();
            },

            handleTypeChange(val) {
                this.$nextTick(() => {
                    if (val.value === 'link') {
                        this.$set(this.currentContentValue, 'fontColor', '#000');
                    }
                    this.handleKitChange(val);
                });
            },

            handleNumberChange(val) {
                if (val.value === 'two') {
                    const newContent = Object.assign({}, cloneUtils.deep(this.content[0]), {
                        code: 'button2',
                        name: this.$t('creative_editor.button_two')
                    });
                    this.content.splice(1, 1, newContent);
                } else {
                    this.content.splice(1, 1);
                    this.currentContentCode = this.defaultContentTab.code;
                }
                this.handleKitSettingsChange(val);
            },

            handleActionSelectVisibleChange(visible) {
                this.checkActionResult = visible ? null : this.checkAction();
            },
            handleSuccessBehaviourChange(val) {
                if (val === 'gotoWidget') {
                    this.currentContentValue.action.gotoWidget = this.gotoWidgetOptions[1].code;
                }
                this.handleContentChange({ needValidator: true });
            },
            handleContentChange(params) {
                this.checkActionResult = null;
                this.$emit('contentChange', this.content, params);
            },

            handleSettingsChange() {
                this.$emit('settingsChange', this.settings);
            },
            handleKitChange(val) {
                this.$set(this.currentContentValue, val.type, val.value);
                this.handleContentChange();
            },
            handleKitSettingsChange(val) {
                this.$set(this.settings, val.type, val.value);
                this.$emit('settingsChange', this.settings);
            },

            gotoWidget() {
                const { gotoWidget } = this.currentContentValue.action;
                const opt = this.gotoWidgetOptions.find(item => item.code === gotoWidget);
                opt && this.$emit('gotoWidget', opt.nextIndex(this.widgetIndex));
            },

            notice() {
                let block = Object.assign({}, this.block, {
                    style: this.style,
                    content: this.content,
                    settings: this.settings
                });
                this.$emit('change', block);
            }
        },

        watch: {
            'block.blockId': function() {
                this.init();
            }
        },

        components: {
            SettingsTab,
            Kits
        }
    };
</script>

<style lang="scss">
    .add-step {
        &__err-tips {
            color: #f54336;
            font-size: 12px;
        }

        &__tips {
            max-width: 240px;
            position: relative;
            margin-top: 12px;

            img {
                width: 100%;
                max-width: 100%;
            }

            p {
                position: absolute;
                bottom: 64px;
                padding: 0 12px;
                color: #344563;
                font-size: 12px;
            }

            .pt-button {
                position: absolute;
                bottom: 20px;
                left: 12px;
                right: 12px;
                display: flex;
                width: calc(100% - 24px);
            }
        }
    }
</style>
