<template>
    <div class="block-settings" :class="{ 'has-tab': !isSubModel }">
        <template v-if="!isSubModel">
            <div class="block-settings__header">
                <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
                <h3>{{ $t(block.blockName) }}</h3>
            </div>

            <div class="block-settings__tab">
                <settings-tab :tab="currentTab" @change="handleTabChange"></settings-tab>
            </div>
        </template>

        <div class="block-settings__content-tab inline" v-if="currentTab === 'content'">
            <pt-scrollbar :suppressScrollX="false" :suppressScrollY="true">
                <div class="block-settings__content-item__wrap">
                    <div
                        class="block-settings__content-item"
                        v-for="(item, $index) in content"
                        :key="item.code"
                        :class="{ active: currentContentIndex === $index }"
                        @click="handleContentItemClick($index)"
                    >
                        {{ $t('creative_editor.slides_name', { number: $index + 1 }) }}
                    </div>
                </div>
            </pt-scrollbar>
        </div>

        <div class="block-settings__container">
            <pt-scrollbar ref="blockScrollbar">
                <div class="block-settings__item-wrap">
                    <!-- content -->
                    <template v-if="currentTab === 'content'">
                        <!-- Image -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-container">
                                <div class="block-upload" :class="{ 'has-img': Boolean(currentContentValue.img) }">
                                    <img
                                        class="block-upload__img"
                                        v-if="currentContentValue.img"
                                        :src="currentContentValue.img"
                                    />
                                    <el-upload
                                        class="block-upload__plugin"
                                        :list-type="currentContentValue.img ? 'text' : 'picture-card'"
                                        :action="uploadState.uploadUrl"
                                        :accept="uploadState.uploadAccept"
                                        :show-file-list="false"
                                        :before-upload="handleBeforeUpload"
                                        :on-success="handleImageUploadSuccess()"
                                        :http-request="handleImageUpload"
                                    >
                                        <pt-icon icon="pt-icon--plus" v-if="!currentContentValue.img"></pt-icon>
                                        <el-tooltip
                                            v-else
                                            :content="$t('common.button_change')"
                                            placement="top"
                                            effect="light"
                                        >
                                            <pt-icon icon="pt-icon--upload"></pt-icon>
                                        </el-tooltip>
                                    </el-upload>

                                    <div
                                        class="block-upload__clear"
                                        v-if="currentContentValue.img"
                                        @click="handleImageClear()"
                                    >
                                        <el-tooltip placement="top" effect="light" :content="$t('common.button_clear')">
                                            <pt-icon icon="pt-icon--delete"></pt-icon>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template v-if="currentContentValue.img">
                            <!-- Size -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">{{ $t('creative_editor.creative_size') }}</div>
                                <div class="block-settings__item-container two-column">
                                    <el-input
                                        class="ipt-size"
                                        size="small"
                                        maxlength="3"
                                        v-model="currentContentValue.width"
                                        type="number"
                                        min="0"
                                        @change="handleImageSizeChange($event, 'width')"
                                    >
                                        <pt-icon slot="suffix" icon="pt-icon--width" size="medium"></pt-icon>
                                    </el-input>
                                    <el-input
                                        class="ipt-size"
                                        size="small"
                                        maxlength="3"
                                        v-model="currentContentValue.height"
                                        type="number"
                                        min="0"
                                        @change="handleImageSizeChange($event, 'height')"
                                    >
                                        <pt-icon slot="suffix" icon="pt-icon--height" size="medium"></pt-icon>
                                    </el-input>
                                </div>
                                <div class="block-settings__item-container pt-mt5">
                                    <el-checkbox
                                        class="checkbox__small-font"
                                        v-model="currentContentValue.sizeLock"
                                        @change="handleContentChange"
                                        >{{ $t('creative_editor.img_ratio') }}</el-checkbox
                                    >
                                </div>
                            </div>

                            <div class="block-settings__hr"></div>
                        </template>

                        <!-- Text Editor -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-container" v-if="currentContentValue">
                                <pt-richtext
                                    ref="textEditor"
                                    :code="currentContentIndex"
                                    :value="currentContentValue.text"
                                    @change="handleTextChange"
                                ></pt-richtext>
                            </div>
                        </div>
                    </template>

                    <!-- settings -->
                    <template v-else>
                        <!-- Number of slides -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.slides_numbers') }}</div>
                            <div class="block-settings__item-container">
                                <el-select size="small" :value="settings.number" @change="handleNumberChange">
                                    <el-option
                                        v-for="item in numberOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>

                        <!-- Alignment -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.creative_alignment') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <el-radio-group
                                    size="small"
                                    v-model="settings.alignment"
                                    @change="handleSettingsChange"
                                >
                                    <el-radio-button
                                        v-for="item in alignmentOptions"
                                        :key="item.code"
                                        :label="item.code"
                                    >
                                        <pt-icon :icon="'pt-icon--' + item.icon"></pt-icon>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>

                        <!-- Layout-->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.item_layout') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <div
                                    class="block-item__icon"
                                    v-for="item in layoutOptions"
                                    :key="item.code"
                                    :class="{ active: settings.layout === item.code }"
                                    @click="handleLayoutClick(item.code)"
                                >
                                    <pt-icon
                                        :icon="'pt-icon--' + item.icon"
                                        :iconStyle="{ width: '100%', height: '100%' }"
                                    ></pt-icon>
                                </div>
                            </div>
                        </div>

                        <!-- Switch style -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">
                                {{ $t('creative_editor.slides_switch_style') }}
                            </div>
                            <div class="block-settings__item-container">
                                <el-select size="small" v-model="settings.switchStyle" @change="handleSettingsChange">
                                    <el-option
                                        v-for="item in switchStyleOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>

                        <div class="block-settings__hr"></div>

                        <!-- Enable autoplay -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title two-cloumn no-margin">
                                <span>{{ $t('creative_editor.slides_autoplay') }}</span>

                                <div class="title-options">
                                    <el-switch
                                        class="el-switch__pt-small"
                                        v-model="settings.autoPlay"
                                        :width="28"
                                        @change="handleSettingsChange"
                                    ></el-switch>
                                </div>
                            </div>
                        </div>

                        <!-- Autoplay speed -->
                        <div class="block-settings__item" v-if="settings.autoPlay">
                            <div class="block-settings__item-title">{{ $t('creative_editor.slides_play_speed') }}</div>
                            <div class="block-settings__item-container">
                                <pt-input-number
                                    size="large"
                                    v-model="settings.playSpeed"
                                    :min="playSpeedOptions.min"
                                    :max="playSpeedOptions.max"
                                    @change="handleSettingsChange"
                                ></pt-input-number>
                            </div>
                        </div>
                    </template>
                </div>
            </pt-scrollbar>
        </div>
    </div>
</template>

<script>
import config from './slide.config';
import SettingsTab from '../SettingsTab';
import { tabOptions, uploadState } from '../../configs/creative.config';
import blocksConfig from '../../configs/blocks.config';
import { getImgSize } from '../../utils/blocks.util';
import engageApis from '@/apis/engage.apis';
import PtRichtext from '@ptengine/pt-ui-x/components/richtext/index.js';

export default {
    name: 'SlideSettings',

    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        widgetIndex: Number
    },

    data() {
        const defaultTab = tabOptions.find(item => item.default);
        const {
            slideDefaultContent,
            typeOptions,
            numberOptions,
            layoutOptions,
            switchStyleOptions,
            playSpeedOptions,
            alignmentOptions
        } = config;

        return {
            defaultTab,
            slideDefaultContent,
            uploadState,
            numberOptions,
            layoutOptions,
            switchStyleOptions,
            playSpeedOptions,
            alignmentOptions,
            currentTab: '',
            currentContentIndex: 0,
            content: [],
            settings: {},
            editorState: {}
        };
    },
    created() {
        this.init();
    },

    computed: {
        currentContentValue() {
            return this.content[this.currentContentIndex] || {};
        }
    },

    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
            this.blockEditorState = this.block.blockEditorState || {};
        },

        handleBack() {
            this.$emit('back');
        },

        handleTabChange(tab) {
            this.currentTab = tab;
            this.$refs.blockScrollbar && this.$refs.blockScrollbar.scrollToPos('top');
        },
        handleImageUpload(file) {
            //console.log('handleImageUpload', file);
            var formData = new FormData();

            formData.append('upload', file.file); //通过append向form对象添加数据
            engageApis.imageUpload(null, formData).then(res => {
                this.$set(this.currentContentValue, 'img', res);
                this.handleImageSizeChange(this.currentContentValue.width, 'width');
                this.handleContentChange({ needValidator: true });
            });
        },
        handleBeforeUpload(file) {
            const isLtMaxSize = file.size <= this.uploadState.backgroundImageMaxSize;
            if (!isLtMaxSize) {
                this.$message.error(this.$t('common.setting_engage_img_tips'));
                this.$set(this.currentContentValue, 'imgName', '');
            } else {
                this.$set(this.currentContentValue, 'imgName', file.name);
            }
            return isLtMaxSize;
        },

        handleImageUploadSuccess() {
            const _self = this;
            return function(file, fileList) {
                getImgSize(file.content, function(width, height) {
                    _self.$set(_self.currentContentValue, 'img', file.content);
                    _self.$set(_self.currentContentValue, 'imgWidth', width);
                    _self.$set(_self.currentContentValue, 'imgHeight', height);
                    _self.handleImageSizeChange(_self.currentContentValue.width, 'width');
                    _self.handleContentChange({ needValidator: true });
                });
            };
        },

        handleImageSizeChange(value, type) {
            const { width, height, img, sizeLock, imgWidth, imgHeight } = this.currentContentValue;
            let size = {
                width,
                height
            };
            size[type] = value;
            if (sizeLock) {
                if (!Object.keys(size).some(item => item === '')) {
                    let _self = this;
                    let _setImgSize = function(iWidth, iHeight) {
                        const rate = iWidth / iHeight;
                        if (type === 'width') {
                            const height = parseInt(parseFloat(value) / rate);
                            _self.$set(_self.currentContentValue, 'height', height);
                        } else {
                            const width = parseInt(parseFloat(value) * rate);
                            _self.$set(_self.currentContentValue, 'width', width);
                        }

                        if (imgWidth) {
                            _self.$set(_self.currentContentValue, 'imgWidth', iWidth);
                            _self.$set(_self.currentContentValue, 'imgHeight', iHeight);
                        }
                        _self.$set(_self.currentContentValue, type, value);
                        _self.handleContentChange();
                    };

                    if (imgWidth && imgHeight) {
                        _setImgSize(imgWidth, imgHeight);
                    } else {
                        getImgSize(img, _setImgSize);
                    }
                }
            } else {
                this.$set(this.currentContentValue, type, value);
                this.handleContentChange();
            }
        },

        handleImageClear() {
            this.currentContentValue.img = null;
            this.handleContentChange({ needValidator: true });
        },

        handleTextChange(val) {
            this.$set(this.content[this.currentContentIndex], 'text', val);
            this.handleContentChange();
        },

        handleNumberChange(val) {
            const newNum = Number(this.numberOptions.find(item => item.code === val).name);
            const currentLen = this.content.length;
            if (newNum > currentLen) {
                let newArr = Array.from({ length: newNum - currentLen }, () =>
                    Object.assign({}, this.slideDefaultContent)
                );
                this.content.push(...newArr);
            } else {
                this.content.splice(newNum);
            }
            this.currentContentIndex > this.content.length - 1 && (this.currentContentIndex = 0);
            this.settings.number = val;
            this.handleContentChange();
        },

        handleContentChange(params) {
            this.$emit('contentChange', this.content, params);
        },

        handleLayoutClick(layout) {
            this.settings.layout = layout;
            this.handleSettingsChange();
        },

        handleSettingsChange() {
            this.$emit('settingsChange', this.settings);
        },

        handleContentItemClick(index) {
            this.currentContentIndex = index;
            this.blockEditorState.activeContentIndex = index;
            this.handleEditorStateChange();
        },

        handleEditorStateChange() {
            this.$emit('stateChange', this.blockEditorState);
        }
    },

    components: {
        SettingsTab,
        PtRichtext
    }
};
</script>
