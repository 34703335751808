// validator strategy
import validator from 'validator';

export default {
    required(val) {
        return !val;
    },
    maxLength(val, length) {
        val = val || '';
        return val.length > length;
    },
    minLength(val, length) {
        val = val || '';
        return val.length < length;
    },
    isUrl(val = '') {
        return !validator.isURL(val);
    },
    isEmail(val = '') {
        return !validator.isEmail(val);
    },
    isTelphone(val = '') {
        var reg = new RegExp(/^[\d-+]*$/);
        return !reg.test(val);
        // return !window.pt.isTelphone(val);
    },
    isYoutubeUrl(url = '') {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#&?]*).*/;
        let match = url.match(regExp);
        console.log('isYoutubeUrl', match && match[2].length === 11);
        return !(match && match[2].length === 11);
    }
};
