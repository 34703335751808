"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropHandlers = exports.constants = exports.smoothDnD = void 0;
var constants = require("./constants");
exports.constants = constants;
var container_1 = require("./container");
exports.smoothDnD = container_1.default;
var dropHandlers = require("./dropHandlers");
exports.dropHandlers = dropHandlers;
__exportStar(require("./exportTypes"), exports);
function delegateProperty(from, to, propName) {
    Object.defineProperty(from, propName, {
        set: function (val) {
            to[propName] = val;
        },
        get: function () { return to[propName]; }
    });
}
var deprecetedDefaultExport = function (element, options) {
    console.warn('default export is deprecated. please use named export "smoothDnD"');
    return (0, container_1.default)(element, options);
};
deprecetedDefaultExport.cancelDrag = function () {
    container_1.default.cancelDrag();
};
deprecetedDefaultExport.isDragging = function () {
    return container_1.default.isDragging();
};
delegateProperty(deprecetedDefaultExport, container_1.default, 'useTransformForGhost');
delegateProperty(deprecetedDefaultExport, container_1.default, 'maxScrollSpeed');
delegateProperty(deprecetedDefaultExport, container_1.default, 'wrapChild');
delegateProperty(deprecetedDefaultExport, container_1.default, 'dropHandler');
exports.default = deprecetedDefaultExport;
