<template>
    <div :class="[$style.main, visibleDemoBar && $style.hasDemoBar, hideMenu && $style.hideMenu]" :style="pageStyle">
        <main-menu></main-menu>
        <main-butter-bar></main-butter-bar>
        <div class="js_main-container" :class="$style.container" v-loading="routerIsLoading">
            <router-view></router-view>
        </div>
        <auth-dialog v-if="authDialogVisible.visible" :type="authDialogVisible.type"></auth-dialog>
        <account-setting v-if="visiblePopup.accountSetting"></account-setting>
        <demo-bar v-if="visibleDemoBar"></demo-bar>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import MainMenu from './MainMenu.vue';
import MainButterBar from './MainButterBar.vue';
import DemoBar from '@/common/packages/demo-bar/DemoBar';
import AuthDialog from '@common/packages/auth-dialog.vue';
import AccountSetting from '@/components/account/AccountSetting.vue';
import baseConfig from '@/common/configs/base.config';
import intercomSerice from '@/common/services/intercom.service';
import freshChatSerice from '@/common/services/fresh-chat.service';
import interactiveDemoService from '@/components/interactive-demo/interactive-demo.service';
import { isSingleSpa } from '@/common/services/singleSpa.service';
const SCROLLY_FLOAT_VALUE = 5; // 计算滚动条到底的浮动高度值,与底部相差5像素内都算到底
export default {
    name: 'Main',

    data() {
        const hideMenu = isSingleSpa();

        return {
            hideMenu,
            visiblePopup: {
                accountSetting: false,
                authDialog: false
            }
        };
    },

    created() {
        this.$eventBus.$on('popstate', this.handlePopstate, false);
        this.$eventBus.$on('visibleMainPopup', this.togglePopup, false);
        document.addEventListener('click', this.handleDocClick, false);
        window.addEventListener('resize', this.handleResize, false);
        window.addEventListener('scroll', this.handleScroll, false);
    },

    beforeDestroy() {
        this.$eventBus.$off('popstate', this.handlePopstate, false);
        this.$eventBus.$off('visibleMainPopup', this.togglePopup, false);
        document.removeEventListener('click', this.handleDocClick, false);
        window.removeEventListener('resize', this.handleResize, false);
        window.removeEventListener('scroll', this.handleScroll, false);
    },

    computed: {
        ...mapGetters('main', ['routerIsLoading', 'userInfo', 'profileInfo', 'authDialogVisible']),

        pageStyle() {
            const { pageStyle } = this.$route.meta;
            return pageStyle || {};
        },

        visibleDemoBar() {
            const demoType = interactiveDemoService.getDemoType(this.$route.params?.sid, this.$route.query?.type);
            return Boolean(demoType);
        }
    },

    mounted() {
        // freshChatSerice.freshChatShutup();
        this.scrollbar = this.$refs.mainScrollbar;
        this.scrollbar && this.SET_SCROLLBAR(this.scrollbar);
    },

    beforeDestroy() {
        this.SET_SCROLLBAR();
    },

    methods: {
        ...mapActions('main', ['getProfileInfo']),
        ...mapMutations('main', ['SET_SCROLLBAR']),

        handleScroll() {
            const scrollTop = window.scrollY;
            this.$eventBus.$emit('mainScroll', scrollTop);
            window.document.body.scrollHeight - scrollTop - SCROLLY_FLOAT_VALUE <= window.innerHeight &&
                this.handleReachEndY();
        },

        handleReachEndY() {
            this.$eventBus.$emit('mainScrollReachEndY');
        },

        /**
         * Intercom呼起逻辑在GTM内操作
         * 约定点击带有class(.js_contact)的dom点击事件
         */
        handleDocClick(e) {
            if (isSingleSpa()) {
                const classList = e.target.classList;
                classList && classList.contains('js_contact') && intercomSerice.contact();
            }
        },

        handlePopstate() {
            const { sid } = this.profileInfo || {};
            const newSid = this.$route.params.sid;
            newSid && sid !== newSid && (this.getProfileInfo(newSid), this.$eventBus.$emit('changeProfile'));
        },

        handleResize() {
            this.$nextTick(() => {
                this.scrollbar && this.scrollbar.update();
            });
        },

        togglePopup({ type, visible }) {
            this.$set(this.visiblePopup, type, visible);
        },

        getLastProfileId(profiles) {
            const lastId = this.$route.params.sid || localStorage.getItem(baseConfig.LAST_PROFILE_ID);
            return (lastId && profiles.includes(item => item.sid === sid)) || profiles[0].sid;
        }
    },

    components: {
        MainMenu,
        MainButterBar,
        AuthDialog,
        AccountSetting,
        DemoBar
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';
:global {
    .js_contact:not(.pt-button) {
        @extend %link;
    }
    .el-message {
        z-index: 9999 !important;
    }
    .el-message.el-message__demo {
        border: 1px solid $pt-success;
        background-color: #f1fff8;
        border-radius: 4px;
        z-index: 9999 !important;
        .el-message__content {
            color: $pt-black-600;
            padding-right: 56px !important;
            strong {
                font-weight: 600;
            }
        }
        .el-message__closeBtn {
            color: $pt-black-90;
            cursor: pointer;
        }
    }
}
.main {
    width: 100%;
    background-color: $pt-black-20;

    &.hasDemoBar {
        padding-bottom: 60px;
    }

    &.hideMenu {
    }

    .container {
        min-width: $page-min-width;
        min-height: $page-content-min-height;
        // overflow: hidden;
        position: relative;
    }
}
</style>
