<template>
    <div class="multi-select">
        <div
            class="multi-select__wrap"
            :class="{ focus: visible }"
            v-pt-clickoutside="handleClose"
            @click.stop="toggleMenu"
        >
            <div
                ref="mainEl"
                class="multi-select__list"
                :data-placeholder="placeholder"
                :class="{ 'has-tags': selected.length || query || hasInput }"
            >
                <pt-scrollbar ref="scrollbar">
                    <div class="inner" ref="tagEls">
                        <div class="tagBase" v-for="(tag, $index) in selected" :key="tag[valueKey]" :title="tag[labelKey]">
                            <div class="tag" :style="{ maxWidth: isOldVersion ? '100%' : (connectWordElWidth > 0 ? `calc( ${mainWidth}px - ${connectWordElWidth}px )` : `${mainWidth}px`)}">
                                <span>{{ tag[labelKey] }}</span>
                                <div class="remove" @click="remove($index)">
                                    <pt-icon
                                        icon="pt-icon--clear"
                                        :icon-style="{ width: '10px', height: '10px' }"
                                    ></pt-icon>
                                </div>
                            </div>
                            <span v-if="$index < selected.length - 1" class="connectWord">{{ tagPlaceholder }}</span>
                        </div>
                        <div class="placeholder" v-if="selected.length < 1 && !query && !menuVisibleOnFocus">
                            {{ placeholder }}
                        </div>
                        <input
                            class="tag-input"
                            ref="input"
                            type="text"
                            v-model.trim="query"
                            :size="inputSize"
                            :placeholder="selected.length >= 1 && tagPlaceholder ? tagPlaceholder : ''"
                            @blur="handleBlur"
                            @focus="handleFocus"
                            @input="handleInput"
                            @keypress.enter.stop="handleEnter"
                            @keydown.up.prevent="selectUp"
                            @keydown.down.prevent="selectDown"
                            @keydown.delete.stop="deletePrevTag"
                            @keydown.esc.stop.prevent="hideDropdown"
                        />
                    </div>
                </pt-scrollbar>
            </div>
        </div>

        <transition name="el-zoom-in-top">
            <div class="multi-select__dropdown" v-if="visible" @click="isClickOutEvent = false">
                <div class="multi-select__dropdown-empty" v-if="loading">
                    <span>{{ loadingText }}</span>
                </div>

                <template v-else>
                    <div class="multi-select__dropdown-wrap" v-if="searchList && searchList.length">
                        <pt-scrollbar ref="searchListScrollbar" @onReachEndY="onReachEndY">
                            <ul class="multi-select__dropdown-list">
                                <li
                                    class="multi-select__dropdown-item"
                                    v-for="(item, $index) in searchList"
                                    :key="item[labelKey]"
                                    :class="{ active: hasSelected(item), selected: $index === hoverIndex }"
                                    :title="item[labelKey]"
                                    @mouseenter="handleMouseenter($index)"
                                    @click.stop="selectOptionClick(item)"
                                >
                                    <span>{{ item[labelKey] }}</span>
                                </li>
                            </ul>
                        </pt-scrollbar>
                    </div>

                    <ul v-else-if="newOption">
                        <li
                            class="multi-select__dropdown-item"
                            :class="{ active: hasActive(newOption) }"
                            :title="newOption[labelKey]"
                            @click.stop="selectOptionClick(newOption)"
                        >
                            <span>{{ newOption[labelKey] }}</span>
                        </li>
                    </ul>

                    <div class="multi-select__dropdown-empty" v-else>
                        <span>{{ noMatchText }}</span>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import cloneUtils from '../../../utils/clone.utils';
import regUtils from '../../../utils/reg.utils';

export default {
    name: 'ptMultiSelect',

    props: {
        //提示列表
        options: Array,
        //绑定值
        value: Array,
        //作为 value 唯一标识的键名
        valueKey: {
            type: String,
            default: 'code'
        },
        //作为 name 唯一标识的键名
        labelKey: {
            type: String,
            default: 'name'
        },
        //是否正在从远程获取数据
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //远程加载时显示的文字
        loadingText: {
            type: String,
            default: function() {
                return this.$t('common.data_loading');
            }
        },
        //是否允许用户创建新条目
        allowCreate: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //失去焦点事件是否创建新条目
        blurCreate: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //搜索条件无匹配时显示的文字
        noMatchText: {
            type: String,
            default: function() {
                return this.$t('common.search_not_found');
            }
        },
        //默认占位符
        placeholder: String,
        // tag标签后的占位符
        tagPlaceholder: String,
        //是否为远程搜索
        remote: {
            type: Boolean,
            default() {
                return false;
            }
        },
        //远程搜索方法
        remoteMethod: Function,
        //允许取消选择
        cancelSelected: {
            type: Boolean,
            default() {
                return false;
            }
        },
        trimSpace: {
            type: Boolean,
            default() {
                return false;
            }
        },
        allowEmpty: {
            type: Boolean,
            default() {
                return true;
            }
        },
        expandAfterInit: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isOldVersion: { // 兼容v2版本
            type: Boolean,
            default() {
                return false;
            }
        }
    },

    data() {
        return {
            selected: [],
            query: '',
            hoverIndex: 0,
            hasInput: false,
            softFocus: false,
            searchList: [], //搜索用到
            searchListTmp: [], //滚屏加载时的原始数据
            scrollPage: 0, //滚动条当前已显示的条数
            visible: false, //是否显示下拉列表
            menuVisibleOnFocus: false,
            searchFinish: true, //搜索状态
            isClickOutEvent: true,
            isSilentBlur: false,
            isPaste: false, //是否为粘贴事件
            mainWidth: 0,
            connectWordElWidth: 0
        };
    },

    computed: {
        inputSize() {
            return !this.query ? 1 : this.query.length + 1;
        },

        // 正则转义
        searchModel() {
            return regUtils.escapeRegExp(this.query);
        },

        // 校验是否允许用户创建新条目
        newOption() {
            return (
                this.query &&
                this.allowCreate && {
                    [this.labelKey]: this.trimSpace && typeof this.query === 'string' ? this.query?.trim() : this.query,
                    [this.valueKey]: this.trimSpace && typeof this.query === 'string' ? this.query?.trim() : this.query
                }
            );
        },

        // 当前键盘选中条目
        currentSelected() {
            return this.hoverIndex !== -1 && this.searchList[this.hoverIndex];
        }
    },

    mounted() {
        this.init();
        if(this.$refs.mainEl){
            const { width } = this.$refs.mainEl.getBoundingClientRect();
            if(width){
                this.mainWidth = width - 10;
            }
        }
    },

    beforeDestroy() {
        this.$refs.mainEl.removeEventListener('paste', this.bindPaste);
    },

    methods: {
        init() {
            this.selected = this.value.map(item => {
                let itemInfo = this.options.find(o => o[this.valueKey] === item);
                return (
                    itemInfo || {
                        [this.valueKey]: item,
                        [this.labelKey]: item
                    }
                );
            });
            this.$refs.mainEl.addEventListener('paste', this.bindPaste);
            this.handQueryChange();
            this.setSearchList(this.options);
            if(this.expandAfterInit){
                this.handleFocus();
                this.$refs?.input?.focus();
            }
        },

        async bindPaste(e) {
            try {
                const text = (e.clipboardData || window.clipboardData).getData('text');
                const rArr = Array.from(new Set(text.split('\r\n')));
                const nArr = Array.from(new Set(text.split('\n')));
                const concatArr = rArr.length > 1 ? rArr : nArr;
                if(concatArr.length > 1){
                    e.preventDefault();
                    for(let i = 0; i < concatArr.length; i ++){
                        const item = this.trimSpace ? concatArr[i].trim() : concatArr[i];
                        const optionIndex = this.selected.findIndex(x => item === x[this.valueKey]);
                        if(this.allowCreate && item && typeof item === 'string' && optionIndex === -1){
                            const data = {
                                [this.labelKey]: item,
                                [this.valueKey]: item,
                            };
                            this.selected.push(data);
                        }
                    }
                    this.handQueryChange();
                    this.$emit('change', this.selected);
                }
            } catch (error) {
                console.log(error);
            }
        },

        setHoverIndex(index) {
            this.hoverIndex = index;
        },

        selectOption() {
            // if (!this.visible) {
            //     this.toggleMenu();
            // } else {
            if (this.searchList[this.hoverIndex]) {
                this.handleOptionSelect(this.searchList[this.hoverIndex]);
            } else if (this.newOption) {
                this.handleOptionSelect(this.newOption);
            }
            // }
        },

        selectDown() {
            this.hoverIndex++;
            if (this.hoverIndex === this.searchList.length) this.hoverIndex = -1;
            this.updateScrollbar(this.hoverIndex);
        },

        selectUp() {
            this.hoverIndex--;
            if (this.hoverIndex === -2) this.hoverIndex = this.searchList.length - 1;
            this.updateScrollbar(this.hoverIndex);
        },

        updateScrollbar(selectedIndex) {
            if (selectedIndex === -1 || !this.$refs.searchListScrollbar) return;
            let scrollbar = this.$refs.searchListScrollbar;
            let li = scrollbar.$el.querySelectorAll('li')[selectedIndex];
            if (li) {
                let liTop = li.offsetTop;
                let liHeight = li.offsetHeight;
                let scrollHeight = scrollbar.$el.offsetHeight;
                let scrollTop = scrollbar.getScrollTop();
                if (liTop < scrollTop || liTop + liHeight > scrollTop + scrollHeight) {
                    this.onReachEndY(); //主动调取数据
                    this.$nextTick(() => scrollbar.scrollToPos(liTop));
                }
            }
        },

        handleInput(event) {
            const val = event.target.value;
            this.handQueryChange(event.target.value);
        },

        handleEnter(event) {
            this.selectOption();
        },

        hasSelected(tag) {
            return this.selected.some(m => m[this.valueKey] === tag[this.valueKey]);
        },

        getValueIndex(option) {
            return this.selected.findIndex(item => item[this.valueKey] === option[this.valueKey]);
        },

        handleOptionSelect(option, byClick) {
            const value = this.value.slice();
            const optionIndex = this.getValueIndex(option);
            if (this.cancelSelected) {
                if (optionIndex > -1) {
                    this.selected.splice(optionIndex, 1);
                } else {
                    (this.allowEmpty || option[this.valueKey] !== '') && this.selected.push(option);
                }
            } else if (optionIndex === -1) {
                (this.allowEmpty || option[this.valueKey] !== '') && this.selected.push(option);
            }
            this.$emit('change', this.selected);
            this.handQueryChange();
            this.isSilentBlur = byClick;
            this.setSoftFocus();
            this.$nextTick(() => {
                this.$refs.scrollbar && this.$refs.scrollbar.scrollToPos('bottom'); //添加后滚动至底部
            });
        },

        setSoftFocus() {
            this.softFocus = true;
            this.$refs?.input?.focus();
        },

        remove(index) {
            this.selected.splice(index, 1);
            this.menuVisibleOnFocus = false;
            this.softFocus = false;
            this.$refs?.input?.blur();
            this.$emit('change', this.selected);
        },

        deletePrevTag(event) {
            if (this.query) return;
            this.hasInput = false;
            this.selected.pop();
            // this.inputScrollbarUpdate();
            this.$emit('change', this.selected);
        },

        handleConnectWidth() {
            if(!this.selected.length) return;
            this.$nextTick(() => {
                const connectWordEl = this.$refs.tagEls.querySelector('span.connectWord');
                if(!connectWordEl) return;
                const { width } = connectWordEl.getBoundingClientRect();
                if(width){
                    this.connectWordElWidth = Math.ceil(width);
                }
            });
        },

        handleClose() {
            setTimeout(() => {
                if (!this.isClickOutEvent) {
                    this.isClickOutEvent = true;
                } else {
                    this.hideDropdown();
                }
            }, 10);
        },

        handleFocus() {
            if (!this.softFocus) {
                this.showDropdown();
                this.menuVisibleOnFocus = true;
                this.$emit('focus', event);
            } else {
                this.softFocus = false;
            }
        },

        handleBlur(event) {
            setTimeout(() => {
                if (this.isSilentBlur) {
                    this.isSilentBlur = false;
                } else {
                    if (this.blurCreate) {
                        if (this.allowCreate && this.newOption) {
                            this.handleOptionSelect(this.newOption);
                        } else {
                            this.query = '';
                            this.setSearchList(this.options);
                            this.$emit('blur', event);
                        }
                    } else {
                        this.$emit('blur', event);
                    }
                }
                this.softFocus = false;
            }, 200);
        },

        handQueryChange(query) {
            this.query = query;
            this.hasInput = !!query;

            let searchModel = regUtils.escapeRegExp(query);
            let debounceFn = debounce(200, () => {
                let data = cloneUtils.deep(this.options);
                let searchList = searchModel ? this.search(data, searchModel) : null;
                this.setSearchList(searchList);

                this.$nextTick(() => {
                    this.setHoverIndex(-1);
                    if (this.$refs.searchListScrollbar) {
                        this.$refs.searchListScrollbar.update();
                    }
                });
            });

            if (this.remote && typeof this.remoteMethod === 'function') {
                this.setHoverIndex(-1);
                this.remoteMethod(query);
            } else {
                if (query && this.options) {
                    debounceFn();
                    this.showDropdown();
                } else {
                    this.setHoverIndex(-1);
                    this.setSearchList(this.options);
                }
            }
        },

        toggleMenu(e) {
            var target = e.target || e.srcElement;
            // 当当前是下拉展开状态且点击的是tag标签时则不触发focus操作
            if ((target?.classList?.contains('tag') || target.parentNode?.classList.contains('tag')) && this.visible)
                return;
            if (this.menuVisibleOnFocus) {
                this.menuVisibleOnFocus = false;
            } else {
                if (this.visible) {
                    this.hideDropdown();
                } else {
                    this.showDropdown();
                }
            }
            if(window.getSelection()?.type === 'Caret'){
                this.$refs.input.focus();
            }
        },

        handleMouseenter(index) {
            this.isSilentBlur = true;
            this.isClickOutEvent = false;
            // this.setHoverIndex(index);
        },

        selectOptionClick(item) {
            this.isSilentBlur = true;
            this.isClickOutEvent = false;
            this.handleOptionSelect(item, true);
        },

        showDropdown() {
            if (this.options.length > 0) {
                this.visible = true;
            }
        },

        hideDropdown() {
            this.visible = false;
            this.menuVisibleOnFocus = false;
        },

        hasActive(item) {
            return this.selected.some(m => m[this.labelKey] === item[this.labelKey]);
        },

        clear() {
            this.selected = [];
            this.handQueryChange();
            // this.inputScrollbarUpdate();
        },

        search(treeList, searchKey) {
            return treeList.reduce((prev, curr, index) => {
                // 否则进行匹配
                var reg = new RegExp(searchKey, 'gi');
                if (reg.test(curr[this.labelKey])) {
                    prev.push(Object.assign({}, curr));
                }
                return prev;
            }, []);
        },

        // 按当前下拉菜单高度获取显示的最大条数
        getPaginationByHeight() {
            let minItems = 7; //默认最小7条
            if (this.$refs.searchListScrollbar) {
                let height = this.$refs.searchListScrollbar.$el.offsetHeight;
                let liHeight = 38;
                let len = Math.floor(height / liHeight);
                return Math.max(len, minItems);
            } else {
                return minItems;
            }
        },

        setSearchList(list) {
            if (!list || !Array.isArray(list)) return;
            let len = this.getPaginationByHeight();
            let overflowLen = parseInt(len / 3);
            let screen = len + overflowLen; //初始化时，在最大条数上加一定溢出量，确保滚动条出现

            this.searchListTmp = list;
            this.searchList = [...list];
            // this.searchList = [...list].splice(0, screen);
            this.scrollPage = this.searchList.length;
        },

        // 当滚动至底
        onReachEndY() {
            if (this.scrollPage && this.searchList.length < this.searchListTmp.length) {
                let len = this.getPaginationByHeight();
                let list = [...this.searchListTmp].splice(this.scrollPage, len);

                this.searchList.push(...list);
                this.scrollPage += len;
            }
        }
    },

    watch: {
        options(newVal) {
            if (!this.searchModel) {
                this.setSearchList(newVal);
            }
            this.handQueryChange();
        },

        value(newVal) {
            this.init();
        },

        selected(newVal) {
            this.handleConnectWidth();
        }
    }
};
</script>

<style lang="scss">
@import '@/styles/import.scss';

.multi-select {
    position: relative;

    &__wrap {
        -webkit-appearance: none;
        background-color: $pt-white;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        outline: none;
        padding: 2px 0 2px 4px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        position: relative;

        &:hover {
            border-color: #c0c4cc;
        }

        &.focus {
            border-color: $pt-green-60;
        }
    }

    &__list {
        // &::before {
        //     content: attr(data-placeholder);
        //     font-size: 14px;
        //     color: #c0c4cc;
        //     position: absolute;
        //     left: 20px;
        //     top: 50%;
        //     transform: translateY(-50%);
        // }

        &.has-tags::before {
            display: none;
        }

        .scrollbar__holder {
            max-height: 200px;
        }

        &-wrap {
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            padding: 5px;

            &.focus {
                border-color: #76ca20;
            }
        }

        .inner {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            align-items: center;
            cursor: text;
            .placeholder {
                height: 22px;
                line-height: 22px;
                padding: 0 30px 0 12px;
                font-size: 12px;
                color: $pt-black-50;
                position: absolute;
            }

            .tagBase {
                display: flex;
                align-items: center;
                margin-right: 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .connectWord {
                font-size: 13px;
                line-height: 24px;
                margin: 0 2px;
                color: #8993a4;
            }

            .tag {
                box-sizing: border-box;
                border-color: transparent;
                margin: 1px 2px;
                background-color: #f2f7f2;
                height: 24px;
                padding: 0 8px;
                line-height: 22px;
                white-space: nowrap;
                border-radius: 4px;
                font-size: 12px;
                color: #091e42;
                display: flex;
                align-items: center;

                &.last-tag {
                    margin-bottom: 5px;
                }

                span {
                    @extend %text-ellipsis;
                    width: calc(100% - 16px);
                }

                .remove {
                    border-radius: 50%;
                    text-align: center;
                    position: relative;
                    cursor: pointer;
                    font-size: 12px;
                    width: 12px;
                    height: 12px;
                    vertical-align: middle;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 4px;

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: #9099a7;
                    }
                }
            }

            .tag-input {
                font-size: 13px;
                max-width: 100%;
                min-width: 48px;
                margin-left: 15px;
                border: none;
                outline: none;
                background-color: transparent;
                color: #666;
                line-height: 1;
                height: 24px;
                margin: 2px;
                width: auto;
                vertical-align: top;
                &::-moz-placeholder {
                    color: #9099a7;
                }
                &::-webkit-input-placeholder {
                    color: #9099a7;
                }
                &:-ms-input-placeholder {
                    color: #9099a7;
                }
            }
        }
    }

    &__dropdown {
        background: #fff;
        box-shadow: 0 2px 6px 0 #c0c4cc;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 1px;
        padding: 6px 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1;

        &::before {
            content: '';
            top: 1px;
            margin-left: -6px;
            border-top-width: 0;
            border-bottom-color: $pt-white;
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 6px;
        }

        &-wrap {
            max-height: 180px;
            overflow: hidden;
        }

        &-item {
            display: flex;
            line-height: 24px;
            font-size: 12px;
            color: #ccc;
            align-items: center;
            cursor: pointer;
            margin: 2px 6px;
            padding: 4px 6px;
            color: #344563;
            word-break: break-word;

            &:hover {
                color: #160000 !important;
                background-color: #f5f7fa !important;
            }

            &.active {
                color: $pt-green-60 !important;
            }

            &.selected {
                background: #f5f7fa;
                border-radius: 4px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .user-icon {
                width: 26px;
                height: 26px;
                margin-right: 8px;

                svg {
                    width: 14px;
                    height: 14px;

                    &.time {
                        display: none;
                    }
                }

                .text {
                    font-size: 12px;
                    white-space: nowrap;

                    .word {
                        font-size: 13px;
                    }
                }
            }

            span.info {
                text-overflow: ellipsis;
            }

            span.chosen {
                background: #000;
                border-radius: 4px;
                padding: 0 4px;
                font-size: 12px;
                color: #fff;
                line-height: 18px;
                margin-left: 8px;
                display: none;
            }
            span.owner {
                display: none;
                margin-left: 12px;
            }

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: 24px;
                min-height: 24px;
            }
        }

        &-empty {
            text-align: center;

            & > span {
                line-height: 34px;
                color: $pt-black-600;
            }
        }
    }
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center top;
}
.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0);
}
</style>
