<template>
    <div :class="$style.users">
        <router-view v-if="!usersLoading"></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Users',
    data() {
        return {
            usersLoading: false
        };
    },
    mounted() {
        this.$eventBus.$on('changeProfile', this.reload, false);
        if (this.$route.name === 'Users') {
            this.usergroupCount > 0
                ? this.$router.push({ name: 'UsersReport' })
                : this.$router.push({ name: 'UsersGroupEmpty' });
        }
    },
    updated() {
        if (this.$route.name === 'Users') {
            this.usergroupCount > 0
                ? this.$router.push({ name: 'UsersReport' })
                : this.$router.push({ name: 'UsersGroupEmpty' });
        }
    },
    beforeDestroy() {
        this.$eventBus.$off('changeProfile', this.reload, false);
    },
    computed: {
        ...mapGetters('main', ['packageInfo']),
        usergroupCount() {
            return this.packageInfo?.usage?.userGroups || 0;
        }
    },
    methods: {
        reload() {
            this.usersLoading = true;
            setTimeout(() => {
                this.usersLoading = false;
            }, 300);
        }
    }
};
</script>

<style lang="scss" module>
.users {
    height: 100%;
    overflow: hidden;
}
</style>
