"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var campaign_util_1 = require("@/components/campaign/campaign.util");
var state = {
    campaignSettingCache: undefined,
    createPortal: (0, campaign_util_1.getCampaignReportRouteName)(),
    count: 0,
    campaignDetails: undefined,
    campaignDetailsDateRange: {
        startTime: '',
        endTime: '',
        code: 'userUpdate'
    },
    scrollbarLeft: undefined,
    scrollbarTop: undefined,
};
exports.default = state;
