import { widgetWrapPadding } from '../../configs/creative.config';
import { isNullOrUndefined } from '../../utils/creative.util';

export default {
    getHtml(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const settings = block.blockSettings || {};

        let domContent = content[0].value.text;
        if (settings.columnNumber === 'two') {
            domContent = `
                <div id="${id}-cell-left">
                    ${content[0].value.text}
                </div>
                <div id="${id}-cell-right">
                    ${content[1].value.text}
                </div>
            `;
        }
        return `<div id="${id}" data-engage-block="text">${domContent}</div>`;
    },

    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        let {
            columnNumber,
            spacing,
            layout,
            alignment,
            backgroundColor,
            marginTop,
            marginBottom,
            noPadding
        } = settings;
        const padding = noPadding ? 0 : `0 ${paddingLeft}px 0 ${paddingLeft}px`;
        if (isNullOrUndefined(marginTop)) marginTop = 0;
        if (isNullOrUndefined(marginBottom)) marginBottom = 0;
        if (isNullOrUndefined(backgroundColor)) backgroundColor = 'transparent';

        if (columnNumber === 'two') {
            const spacingHalf = spacing / 2 + 'px';
            let leftCellWidth = `calc(50% - ${spacingHalf})`;
            let rightCellWidth = `calc(50% - ${spacingHalf})`;

            if (layout === 'with37') {
                leftCellWidth = `calc(30% - ${spacingHalf})`;
                rightCellWidth = `calc(70% - ${spacingHalf})`;
            } else if (layout === 'with73') {
                leftCellWidth = `calc(70% - ${spacingHalf})`;
                rightCellWidth = `calc(30% - ${spacingHalf})`;
            }
            return `
                #${id} {
                    display: flex;
                    justify-content: space-between;
                    align-items: stretch;
                    flex-wrap: nowrap;
                    padding: ${padding};
                    margin: ${marginTop}px 0 ${marginBottom}px;
                    background-color: ${backgroundColor};
                    color: #344563;
                    text-align: ${alignment};
                }
                #${id} p {
                    text-align: ${alignment};
                }
                #${id} a {
                    cursor: pointer;
                    color: #0066cc;
                }
                #${id}-cell-left {
                    flex: 0 0 ${leftCellWidth};
                    overflow: hidden;
                    word-break: break-word;
                }
                #${id}-cell-right {
                    flex: 0 0 ${rightCellWidth};
                    overflow: hidden;
                    word-break: break-word;
                }
            `;
        }

        return `
            #${id} {
                word-break: break-word;
                padding: ${padding};
                margin: ${marginTop}px 0 ${marginBottom}px;
                background-color: ${backgroundColor};
                color: #344563;
                text-align: ${alignment};
            }
            #${id} p {
                    text-align: ${alignment};
                }
            #${id} a {
                cursor: pointer;
                color: #337ab7;
                text-decoration: inherit;
                background-color: inherit;
            }
        `;
    }
};
