import docsUrl from '@common/configs/docsUrl';

export default {
    en: {
        language: 'EN',
        email: 'support@ptmind.com',
        links: {
            help: 'https://help.ptx.com',
            gtm: docsUrl.gtm
        }
    },
    jp: {
        language: 'JP',
        email: 'support@ptmind.com',
        links: {
            help: 'https://help.ptx.com',
            gtm: docsUrl.gtm
        }
    }
    // zh: {
    //     language: 'ZH',
    //     links: {
    //         help: 'https://help.ptx.com'
    //     }
    // }
};
