<template>
    <div class="block-settings">
        <div class="block-settings__container" :class="{ 'form-field-settings': currentTab === 'form' }">
            <div class="block-settings__item-wrap">
                <template v-if="currentTab === 'form'">
                    <!-- field list -->
                    <pt-scrollbar ref="fieldScrollbar">
                        <pt-dragkit
                            name="fields"
                            group="fields"
                            idProp="code"
                            ref="fields"
                            :value="content"
                            :options="dragkitOptions"
                            @dragstart="handleDragstart"
                            @dragging="handleDragging"
                            @dragend="handleDragend"
                            @dragleave="handleDragleave"
                            @dragsort="handleDragsort"
                        >
                            <template v-slot="slotProps">
                                <div
                                    :data-code="slotProps.node.code"
                                    class="widget-blocks__item"
                                    :class="{
                                        hover: slotProps.node.blockId === currentHoverFieldId,
                                        'visible-delete': slotProps.node.visibleDelete,
                                        dragging: slotProps.node.isPlaceholder
                                    }"
                                    @mouseleave="handleBlockMouseleave(slotProps.node)"
                                    @mouseenter="handleBlockMouseenter(slotProps.node)"
                                    @click="handleBlockEdit(slotProps.node, slotProps.index)"
                                >
                                    <div
                                        class="widget-blocks__item-delete-icon"
                                        @click.stop="handleDeleteIconClick(slotProps.node)"
                                    >
                                        <pt-icon
                                            icon="pt-icon--remove"
                                            :iconStyle="{ width: '14px', height: '14px' }"
                                        ></pt-icon>
                                    </div>
                                    <el-tooltip
                                        :content="$t('creative_editor.drag_handle')"
                                        placement="top"
                                        effect="dark"
                                    >
                                        <div class="widget-blocks__item-drag">
                                            <pt-icon
                                                icon="pt-icon--move"
                                                :iconStyle="{ width: '16px', height: '16px' }"
                                            ></pt-icon>
                                        </div>
                                    </el-tooltip>
                                    <div class="widget-blocks__item-content">
                                        {{ (slotProps.node.value && slotProps.node.value.labelName) || '' }}
                                        <pt-icon
                                            icon="pt-icon--warning"
                                            :iconStyle="{ width: '14px', height: '14px' }"
                                            v-show="slotProps.node.fieldHasError"
                                        ></pt-icon>
                                    </div>
                                    <div class="widget-blocks__item-options">
                                        <el-tooltip
                                            effect="dark"
                                            placement="top"
                                            :offset="13"
                                            :content="$t('creative_editor.block_edit')"
                                        >
                                            <div
                                                class="widget-blocks__item-options-icon"
                                                @click.stop="handleBlockEdit(slotProps.node, slotProps.index)"
                                            >
                                                <pt-icon
                                                    icon="pt-icon--editor2"
                                                    :iconStyle="{ width: '14px', height: '14px' }"
                                                ></pt-icon>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div class="widget-blocks__item-delete">
                                        <pt-button
                                            type="outline"
                                            color="black"
                                            size="small"
                                            @click.stop="handleDeleteCancel(slotProps.node)"
                                            >{{ $t('common.button_cancel') }}</pt-button
                                        >
                                        <pt-button
                                            color="red"
                                            size="small"
                                            @click.stop="handleFieldDelete(slotProps.node)"
                                            >{{ $t('creative_editor.block_delete_confirm') }}</pt-button
                                        >
                                    </div>
                                </div>
                            </template>
                        </pt-dragkit>
                        <el-dropdown
                            class="widget-blocks__item block-add"
                            @command="handleBlockAddClick"
                            trigger="click"
                        >
                            <div class="field-add">{{ $t('creative_editor.block_add') }}</div>
                            <el-dropdown-menu :style="dropdownStyle" slot="dropdown">
                                <el-dropdown-item
                                    v-for="item in fieldComponents"
                                    :command="item"
                                    :key="item.code"
                                    :divided="item.divided"
                                >
                                    <pt-icon :icon="'pt-icon--' + item.icon"></pt-icon>
                                    {{ item.name }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </pt-scrollbar>
                </template>
                <template v-else>
                    <!-- enable label -->
                    <kits
                        type="switch"
                        :title="$t('creative_editor.item_label_switch')"
                        target="showLabel"
                        :value="settings.showLabel"
                        @change="handleKitSettingsChange"
                    ></kits>
                    <template v-if="settings.showLabel">
                        <div class="block-settings__hr"></div>
                        <!-- layout -->
                        <kits
                            type="iconButton"
                            :title="$t('creative_editor.item_layout')"
                            target="layout"
                            :options="layoutOptions"
                            :value="settings.layout"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <div class="block-settings__hr"></div>
                        <!-- Spacing -->
                        <kits
                            type="slider"
                            :title="$t('creative_editor.item_content_spacing')"
                            target="spacing"
                            :min="spaceingOptions.min"
                            :max="spaceingOptions.max"
                            :value="settings.spacing"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <div class="block-settings__hr"></div>
                        <!-- Font color -->
                        <kits
                            type="colorPicker"
                            :title="$t('creative_editor.item_font_color')"
                            target="fontColor"
                            :value="settings.fontColor"
                            @change="handleKitSettingsChange"
                        ></kits>
                        <!-- Font size & Stroke weight-->
                        <div class="block-settings__item-group two-cloumn">
                            <kits
                                type="inputNumber"
                                :title="$t('creative_editor.item_font_size')"
                                target="fontSize"
                                :min="fontSizeOptions.min"
                                :max="fontSizeOptions.max"
                                :value="settings.fontSize"
                                @change="handleKitSettingsChange"
                            ></kits>
                            <kits
                                type="select"
                                :title="$t('creative_editor.item_stroke_weight')"
                                target="fontWeight"
                                :value="settings.fontWeight"
                                :options="fontWeightOptions"
                                @change="handleKitSettingsChange"
                            ></kits>
                        </div>
                    </template>
                </template>
            </div>
            <div
                class="block-settings__item-group absolute"
                :style="contentStyle"
                v-if="showFieldContent"
                :key="currentField.code"
            >
                <!-- Label Name -->
                <div class="block-settings__item">
                    <div class="block-settings__item-title">{{ $t('creative_editor.field_label') }}</div>
                    <div class="block-settings__item-container">
                        <el-input
                            size="small"
                            v-model="currentContentValue.labelName"
                            @change="handleContentChange({ needValidator: true })"
                        ></el-input>
                    </div>
                </div>
                <!-- Placeholder Name -->
                <div class="block-settings__item">
                    <div class="block-settings__item-title">{{ $t('creative_editor.field_placeholder') }}</div>
                    <div class="block-settings__item-container">
                        <el-input
                            size="small"
                            v-model="currentContentValue.placeholder"
                            @change="handleContentChange({ needValidator: true })"
                        ></el-input>
                    </div>
                </div>
                <div class="block-settings__hr"></div>
                <!-- Line number -->
                <kits
                    v-if="currentContentValue && currentContentValue.type === 'longText'"
                    type="select"
                    :title="$t('creative_editor.field_linenumber')"
                    target="lineNumber"
                    :options="lineNumberOptions"
                    :value="currentContentValue.lineNumber"
                    @change="handleKitChange"
                ></kits>
                <!-- Dropdown options -->
                <div class="block-settings__item" v-if="currentContentValue && currentContentValue.type === 'dropdown'">
                    <div class="block-settings__item-title">{{ $t('creative_editor.field_dropdown_options') }}</div>
                    <div class="block-settings__item-tips">{{ $t('creative_editor.field_dropdown_tips') }}</div>
                    <div class="block-settings__item-container">
                        <el-input
                            placeholder
                            type="textarea"
                            resize="none"
                            :rows="4"
                            v-model="currentContentValue.dropdownOptions"
                        ></el-input>
                    </div>
                </div>
                <!-- is required -->
                <div class="block-settings__item">
                    <div class="block-settings__item-container">
                        <el-checkbox
                            size="small"
                            v-model="currentContentValue.isRequired"
                            @change="handleContentChange"
                            >{{ $t('creative_editor.field_required') }}</el-checkbox
                        >
                    </div>
                </div>
                <!-- allow duplicate -->
                <div class="block-settings__item" v-if="currentContentValue.type !== 'dropdown'">
                    <div class="block-settings__item-container">
                        <el-checkbox
                            size="small"
                            v-model="currentContentValue.allowDuplicate"
                            @change="handleContentChange"
                            >{{ $t('creative_editor.field_duplicate') }}</el-checkbox
                        >
                    </div>
                </div>
                <!-- property -->
                <div class="block-settings__hr"></div>
                <div class="block-settings__item">
                    <div class="block-settings__item-container">
                        <el-checkbox
                            size="small"
                            v-model="currentContentValue.allowConnect"
                            @change="handleContentChange({ needValidator: true })"
                            >{{ $t('creative_editor.connect_property') }}</el-checkbox
                        >
                    </div>
                </div>
                <div class="block-settings__item" v-if="currentContentValue.allowConnect">
                    <div
                        class="block-settings__item-container"
                        :class="{ 'block-settings__item-container--error': propertyHasError }"
                    >
                        <el-autocomplete
                            v-model="currentContentValue.connectProperty"
                            size="small"
                            clearable
                            value-key="name"
                            :placeholder="$t('creative_editor.connect_property_placeholder')"
                            :fetch-suggestions="querySearchProperty"
                            @input="handleContentChange({ needValidator: true })"
                            @select="handleContentChange({ needValidator: true })"
                        ></el-autocomplete>
                        <div class="errortips" v-if="propertyHasError">
                            {{ $t('creative_editor.connect_property_err') }}
                        </div>
                    </div>
                </div>
                <!-- field id -->
                <div class="block-settings__hr"></div>
                <div class="block-settings__item">
                    <div class="block-settings__item-title">
                        {{ $t('creative_editor.field_id') }}
                        <el-popover
                            placement="top"
                            width="300"
                            trigger="hover"
                            :visible-arrow="false"
                            :content="$t('creative_editor.field_id_tips')"
                        >
                            <pt-icon
                                slot="reference"
                                class="help-icon"
                                icon="pt-icon--help-new"
                                :iconStyle="{ width: '14px', height: '14px' }"
                            ></pt-icon>
                        </el-popover>
                    </div>
                    <div class="block-settings__item-container">
                        <div>{{ currentField.value.field_id }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import uuidUtils from '@/common/utils/uuid.utils';
import commonUtils from '@/common/utils/common.utils';
import dataSettingApis from '@/apis/data-setting.apis';
import config from './field.config';
import Kits from '../../kits/Kits.vue';
import SettingsTab from '../SettingsTab';
import { mapGetters } from 'vuex';
import { tabOptions, formDesignOptions } from '../../configs/creative.config';
export default {
    name: 'FieldSettings',
    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        //是否是表单提交按钮
        refer: String,
        tab: String
    },
    data() {
        const currentTab = this.tab || '';
        const defaultTab =
            this.refer && this.refer === 'form'
                ? formDesignOptions.find(item => item.code === currentTab)
                : tabOptions.find(item => item.default);
        const defaultContentTab = config.content.find(item => item.default);
        const {
            spaceingOptions,
            fontSizeOptions,
            fontWeightOptions,
            layoutOptions,
            lineNumberOptions,
            fieldComponents,
            defaultContent
        } = config;
        const dragkitOptions = {
            nodeH: 56,
            padding: 8,
            maxNodeNum: 999,
            isCoverNode: false,
            clone: false,
            group: 'fields'
        };
        return {
            defaultContent,
            defaultTab,
            defaultContentTab,

            currentTab: '',
            currentContentCode: '',

            spaceingOptions,
            fontSizeOptions,
            fontWeightOptions,
            layoutOptions,
            fieldComponents,
            lineNumberOptions,

            content: [],
            settings: {},

            dragkitOptions,
            currentHoverFieldId: null,
            currentField: {},
            showFieldContent: false,
            userPropertyOptions: []
            //  propertyHasError: false
            //  contentTop: 10
        };
    },
    inject: ['formDesign'],
    computed: {
        ...mapGetters('main', ['profileInfo']),
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        },

        currentTypeIsButton() {
            return this.currentContentValue && this.currentContentValue.type === 'button';
        },

        columnHasMultiseriate() {
            return this.settings.buttonNumber !== 'one';
        },

        dropdownStyle() {
            //  console.log('this.$el', this.$el);
            let dropdownWidth = document.querySelector('.js--creative-editor-views').clientWidth;

            return {
                width: dropdownWidth - 40 + 'px'
            };
        },
        contentStyle() {
            const { type } = this.currentField.value;
            const totalHeight = document.querySelector('.form-field-settings').clientHeight;
            let contentStyle = {};
            let contentHeight, topOffset;
            switch (type) {
                case 'longText':
                    contentHeight = 540;
                    break;
                case 'dropdown':
                    contentHeight = 582;
                    break;
                default:
                    contentHeight = 450;
            }

            if (this.currentContentCode && this.content) {
                const contentCode = this.currentContentCode;
                let index = this.content.findIndex(function(o) {
                    return o.code === contentCode;
                });
                topOffset = 10 + index * 56;
            } else {
                topOffset = 10;
            }
            if (topOffset + contentHeight < totalHeight + 60) {
                return {
                    top: topOffset + 'px'
                };
            } else {
                return {
                    top: 0
                };
            }
        },
        propertyHasError() {
            const { allowConnect, connectProperty } = this.currentField.value;
            return allowConnect && !connectProperty;
        }
    },

    created() {
        this.init();
    },
    inject: ['editor'],
    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.currentContentCode = this.defaultContentTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
            this.currentField = this.content[0];
            this.fetchUserPropertiess();

            console.log('currentvalue init', this.currentField);
        },
        async fetchUserPropertiess() {
            const [err, res] = await commonUtils.awaitWrap(
                dataSettingApis.getUserProperties(null, {
                    where: {
                        sid: this.profileInfo.sid
                    }
                })
            );
            if (err) {
                console.log('get user properties failed.');
            } else {
                this.userPropertyOptions = res.filter(item => !item.source.includes('default'));
            }
        },
        querySearchProperty(queryString, callback) {
            var options = this.userPropertyOptions;
            var results = queryString ? options.filter(this.createFilter(queryString)) : options;
            // 调用 callback 返回建议列表的数据
            callback(results);
        },
        createFilter(queryString) {
            return option => {
                return option.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        handleBack() {
            this.$emit('back');
        },
        handleTabChange(tab) {
            this.currentTab = tab;

            if (tab === 'content' && !this.columnHasMultiseriate) {
                this.currentContentCode = this.content[0].code;
            }
            this.$refs.blockScrollbar && this.$refs.blockScrollbar.scrollToPos('top');
        },
        handleContentChange(params) {
            this.currentField.fieldHasError = this.propertyHasError;
            let index = this.content.findIndex(content => content.code === this.currentField.code);
            this.$set(this.content[index], 'fieldHasError', this.propertyHasError);
            this.$emit('contentChange', this.content, params);
        },
        handleKitChange(val) {
            this.$set(this.currentContentValue, val.type, val.value);
            this.handleContentChange();
        },
        handleKitSettingsChange(val) {
            console.log('handleKitSettingsChange', val);
            this.$set(this.settings, val.type, val.value);
            this.$emit('settingsChange', this.settings);
        },
        handleDragstart(container, group) {
            // console.log('handleDragstart', container, group);
        },

        handleDragging(container, group, event) {
            this.updateScrollbar(event);
        },
        updateScrollbar(e) {
            // 计算this.panelScroller的位置
            let distance = 0;
            if (this.$refs.fieldScrollbar) {
                let fieldScrollbar = this.$refs.fieldScrollbar.$el;
                let position = {
                    x: fieldScrollbar.offsetLeft,
                    y: fieldScrollbar.offsetTop
                };

                if (position) {
                    // 判断鼠标的位置，
                    if (e.pageY - position.y < 30 && e.pageY - position.y > 0) {
                        distance = e.pageY - position.y;
                        fieldScrollbar.scrollTop = fieldScrollbar.scrollTop - (30 - distance);
                    } else if (
                        position.y + fieldScrollbar.clientHeight - e.pageY < 30 &&
                        position.y + fieldScrollbar.clientHeight - e.pageY > 0
                    ) {
                        distance = position.y + fieldScrollbar.clientHeight - e.pageY;
                        fieldScrollbar.scrollTop = fieldScrollbar.scrollTop + (30 - distance);
                    }
                }
            }
        },
        handleDragend() {
            this.isDragMode = false;
        },

        handleDragleave() {
            // console.log('handleDragleave');
        },

        handleDragsort(container, fromIndex, toIndex) {
            console.log('handleDragsort', container, fromIndex, toIndex);
            //TODO
            const node = this.content.splice(fromIndex, 1)[0];
            this.content.splice(toIndex, 0, node);
            this.$emit('contentChange', this.content);
            // this.editor.$emit('blockSort', fromIndex, toIndex);
        },
        handleBlockAddClick(val) {
            //可能需要针对不同type的field做特殊处理
            let contentValue = Object.assign({}, this.defaultContent, {
                type: val.code,
                labelName: val.name,
                placeholder: '',
                field_id: `${val.code}_${uuidUtils.uuid_8bit()}`
            });
            if (['email', 'phone'].includes(val.code)) {
                contentValue.allowDuplicate = false;
            }
            if (['email', 'phone', 'name'].includes(val.code)) {
                contentValue.connectProperty = val.code;
            }
            let field = {
                name: 'field',
                code: `field_${uuidUtils.uuid_8bit()}`,
                fieldHasError: false,
                value: Object.assign({}, contentValue, {
                    type: val.code,
                    labelName: val.name,
                    placeholder: '',
                    field_id: `${val.code}_${uuidUtils.uuid_8bit()}`
                })
            };
            this.content.push(field);
            this.$emit('contentChange', this.content);

            this.currentField = field;

            this.currentContentCode = field.code;

            this.showFieldContent = true;
        },
        handleBlockEdit(block, index) {
            console.log('handleBlockEdit', block);
            if (block.code === this.currentContentCode) {
                this.showFieldContent = !this.showFieldContent;
            } else {
                this.showFieldContent = true;
            }
            //为了兼容form表单属性回传，只好在这硬把allowConnect，connectProperty硬塞进去了
            const { value } = block.value;
            if (value && value.allowConnect === undefined) {
                block.allowConnect = false;
                block.connectProperty = '';
            }
            this.currentField = block;

            this.currentContentCode = block.code;
        },
        handleFieldDelete(block) {
            if (block.code === this.currentContentCode) {
                this.showFieldContent = false;
            }

            let index = this.content.findIndex(item => item.code === block.code);
            this.content.splice(index, 1);
            this.$emit('contentChange', this.content);
        },

        handleDeleteCancel(block) {
            //  console.log('handleDeleteCancel', block);
            let index = this.content.findIndex(item => item.code === block.code);
            this.$set(this.content, index, Object.assign({}, block, { visibleDelete: false }));
        },
        handleBlockMouseenter(block) {
            this.currentHoverFieldId = block.code;
        },

        handleBlockMouseleave(block) {
            this.currentHoverFieldId = block.code;
            // this.editor.$emit('blockMouseenter');
        },
        handleDeleteIconClick(block) {
            let index = this.content.findIndex(item => item.code === block.code);
            this.$set(this.content, index, Object.assign({}, block, { visibleDelete: true }));
        }
    },
    components: {
        Kits,
        SettingsTab
    }
};
</script>
<style lang="scss">
@import '@/styles/import.scss';
.field-add {
    width: 100%;
    height: 100%;
    line-height: 56px;
    text-align: center;
}
.creative-editor {
    .form-settings {
        .block-settings__container.form-field-settings {
            height: calc(100% - 64px);
            .block-settings__item-wrap {
                height: 100%;
                padding: 8px 0;
            }
        }
    }
}
</style>
