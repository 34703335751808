import scenarioApis from '@/apis/scenario.apis';
import uuidUtils from '@common/utils/uuid.utils';
import engageSettingUtils from '@/components/engagement/setting/setting.utils';

export default {
    getScenarios(context) {
        const { scenarioList } = context.state;
        if (scenarioList) return scenarioList;

        return scenarioApis.scenarios(null, {}).then(
            res => {
                const scenarios = res.sort(function(a, b) {
                    return b.star - a.star;
                });
                scenarios.forEach(item => {
                    const creativeId = uuidUtils.uuid();
                    const { engageID, versions } = item;
                    const activeVersions = versions[0];
                    item.creative =
                        (activeVersions &&
                            engageSettingUtils.formatEngageCreative(
                                activeVersions.terminals,
                                creativeId,
                                true,
                                engageID
                            )) ||
                        {};
                });
                context.commit('UPDATE_SCENARIO_LIST', scenarios);
                return Promise.resolve(scenarios);
            },
            () => {
                return Promise.reject(new Error('get scenarios list failed.'));
            }
        );
    },

    getScenarioTags(context) {
        const { scenarioTags } = context.state;
        if (scenarioTags) return scenarioTags;

        return scenarioApis.scenarioTags().then(
            res => {
                context.commit('UPDATE_SCENARIO_TAGS', res);
                return Promise.resolve(res);
            },
            () => {
                return Promise.reject(new Error('get scenario tags failed.'));
            }
        );
    }
};
