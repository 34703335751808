import blocksConfig from '../../configs/blocks.config';
import { widgetWrapPadding } from '../../configs/creative.config';
import i18n from '@assets/i18n';
import store from '@/store';
import cloneUtils from '@common/utils/clone.utils';

function getSubModel(content, blockSettings = {}, widgetId, belong) {
    const config = blocksConfig.find(item => item.code === content.code);
    const { alignment } = blockSettings;
    if (config) {
        const subModelContent = cloneUtils.deep(content.value.content);
        if (content.code === 'button') {
            subModelContent[0].value.action.type = '';
        }
        const block = {
            blockId: content.value.id,
            blockContent: subModelContent,
            blockSettings: Object.assign({}, content.value.settings, blockSettings, { alignment })
        };

        return {
            dom: config.services.getHtml(block),
            style: config.services.getStyle(block),
            script: config.services.getScript && config.services.getScript(block, widgetId, belong)
        };
    }
}
function getGeneralStyle(generalSettings, id, paddingLeft) {
    const {
        backgroundColor,
        bottomMargin,
        topMargin,
        buttonSpacing,
        fieldSpacing,
        buttonPosition,
        buttonAlignment
    } = generalSettings;
    const buttonPositionStyle = buttonPosition === 'bottom' ? ' flex-direction: column' : 'flex-direction: row';
    const buttonAlignmentStyle =
        buttonPosition === 'bottom' ? `align-items: ${buttonAlignment}` : 'align-items: flex-end';

    return `#${id} {
        background-color: ${backgroundColor};
        margin-top: ${topMargin}px;
        margin-bottom: ${bottomMargin}px;
        padding: 12px ${paddingLeft || 30}px;
        display: flex;
        ${buttonPositionStyle};
        ${buttonAlignmentStyle}
    }
    #${id}-form-field {
        width: 100%;
        ${
            buttonPosition === 'bottom'
                ? 'margin-bottom: ' + buttonSpacing + 'px'
                : 'margin-right: ' + buttonSpacing + 'px'
        };
    }
    #${id}-form-field .engage-form-field{
        margin-bottom: ${fieldSpacing}px;
    }
    #${id}-form-field .engage-form-field:last-child{
        margin-bottom: 0;
    }
   `;
}
export default {
    getHtml(block) {
        const id = block.blockId;
        const dom = {};
        block.blockContent.forEach(content => {
            dom[content.code] = getSubModel(content).dom;
        });
        const fieldDom = `${dom.field}`;
        const btnDom = `${dom.button}`;
        return `<div id="${id}" data-engage-block="form">
                    <div id="${id}-form-field">${fieldDom}</div>
                    <div id="${id}-form-btn">${btnDom}</div>
                </div>`;
    },

    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const settings = block.blockSettings || {};

        const { alignment } = settings;
        const subModelStyle = block.blockContent
            .map(content => {
                let style = getSubModel(content, settings[content.code]).style;
                if (content.code === 'button') {
                    style = style.replace('text-align: center;', `text-align: ${alignment};`);
                }
                return style;
            })
            .join('');
        const generalStyle = getGeneralStyle(settings.general, id, paddingLeft);
        return `${generalStyle}
                ${subModelStyle}`;
    },

    getScript(block, widgetId, belong, engageId, versionId) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        const subModelScript = block.blockContent
            .map(content => {
                const script = getSubModel(content, settings[content.code], widgetId, belong).script;

                return script;
            })
            .join('');
        const blockData = block.blockData || {};
        const { dbApi, pushToDB } = blockData;

        const fieldContentValue = block.blockContent[0].value.content.map(content => content.value);
        const buttonActionValue = block.blockContent[1].value.content[0].value.action;

        const formElParent = ['dialog'].includes(belong) ? '[data-preview-type="dialog"] ' : '';
        const ptFormConfig = {
            widgetId: widgetId,
            blockId: id,
            engageId: engageId || '',
            versionId: versionId || '',
            buttonAction: cloneUtils.deep(buttonActionValue),
            formElParent: formElParent,
            belong: belong,
            errorTips: {
                required_fail: i18n.t('creative_editor.form_error_tips_required'),
                email_fail: i18n.t('creative_editor.form_error_tips_email'),
                phone_fail: i18n.t('creative_editor.form_error_tips_phone'),
                error_duplicate: i18n.t('creative_editor.form_error_tips_duplicate')
            }
        };
        const { sid } = store.getters['main/profileInfo'];

        let domain = '';

        if (process.env && process.env.NODE_ENV && ['local', 'development'].includes(process.env.NODE_ENV)) {
            domain = 'https://devxbackend.ptengine.jp';
        } else {
            domain = 'https://devxbackend.ptengine.jp';
        }

        const mountedEvent = `function(){
            var self = this;
        
            var formElWrapper = config.formElParent && document.querySelector(config.formElParent + '') || document;
          //  console.log('fieldDom selector', config.formElParent + '#' + config.blockId + '.engage-form-field');
            var fieldDomList = document.querySelectorAll(config.formElParent + '#' + config.blockId + ' .engage-form-field');
            var btnDom = formElWrapper.querySelector('#${id}-form-btn div');

            
            if(${belong === 'normal'}){
                fieldDomList = document.querySelectorAll('#${id} .engage-form-field');
                btnDom = document.querySelector('#${id}-form-btn div');
            }
            var fieldContentValue = ${JSON.stringify(fieldContentValue)};

            self.engageFormUtils = {
                getErrorTipDom: function(errorTips) {
                    var errorDom = document.createElement("div");
                    errorDom.classList.add('engage-error-tips');
                    errorDom.innerHTML = errorTips;

                    return errorDom
                },
                isEmpty: function (val) {
                    return !val || val === ''
                },
                isValidEmail: function (val) {
                    var validEmailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$/;
                    return validEmailReg.test(val);
                },
                isValidPhone: function (val) {
                    var validPhoneReg = /^[0-9_-]+$/;
                    return val && validPhoneReg.test(val);
                },
                createAjax: function(ajaxOptions) {
                    
                    var method = ajaxOptions.method;
                    var data = ajaxOptions.data;
                    var url = ajaxOptions.url;
                    var contentType = ajaxOptions.contentType || "application/json";
                    var callback = ajaxOptions.callback;
                    var async = ajaxOptions.async || true;

                    method = method || 'GET';
                    var xhr = new XMLHttpRequest();
                  
                    if ('withCredentials' in xhr) {
                        xhr.open(method, url, true);
                    } else if (typeof XDomainRequest != 'undefined') {
                        xhr = new XDomainRequest();
                        xhr.open(method, url, async);
                    } else {
                        xhr = null;
                    }
                    xhr.setRequestHeader("Content-Type", contentType); 
                    if(xhr) {
                        callback && (xhr.onreadystatechange = function(){
                      
                            if(xhr.readyState === 4 && (xhr.status === 200 || xhr.status ===201) ){
                                callback(xhr.responseText);
                            }
                        });
                        xhr.send(JSON.stringify(data));
                    }
                },   
                saveFormData: function(fieldValueList, fieldIDList) {

                    if(window.ptEngage && window.ptEngage[config.engageId] && window.ptEngage[config.engageId].submitCallback){
                        var context = window.ptEngage[config.engageId].context;
                        var current = window.ptEngage[config.engageId].current;
                        current.variant = {
                            formContent:  JSON.stringify(fieldValueList),
                            fieldIDList: JSON.stringify(fieldIDList)
                        };
                        window.ptEngage[config.engageId].submitCallback(context, current);
                       
                        var propertyList = {};
                        fieldIDList.forEach(function(item){
                            if(item.allowConnect && item.connectProperty){
                                propertyList[item.connectProperty] = item.fieldValue;
                            }   
                        });
                        if(JSON.stringify(propertyList)!== '{}'){
                            ptengine && ptengine.identify && ptengine.identify('', propertyList, 'engage');
                        }
                      
                      
                        
                    }
                    self.fieldContent = fieldValueList;
                    self.fieldIDList = fieldIDList;
                   
                   
                    if(${pushToDB}){
                        //POST API
                       
                        self.engageFormUtils.createAjax({
                            url: '${dbApi}',
                            method: 'POST',
                            data: {
                                fieldContent: fieldValueList
                            },
                            callback: function(res){
                             
                                return res;
                            }

                        });
                    }

                 
                        
                },
                handleFormSubmit: function(e) {

                    e.preventDefault();
               
          
                    self.field = null;
                    self.fieldIDList = null;
                    
                    var fieldValueList = [];
                    var fieldIDList = [];
                    var hasError = false;
                    var needCheckDuplicate = false;
                    console.log('fieldDomList', fieldDomList);
                    for(var i = 0; i< fieldDomList.length;  i++) {
                        var id = fieldDomList[i].getAttribute('data-field-id');
                        var value = fieldDomList[i].querySelector('.engage-input').value;
                        var type = fieldDomList[i].getAttribute('data-field-type');
                        var itemContent = fieldContentValue.filter(function(item){ return item.field_id === id})[0];
                        
                        var error = false;
                        var errorDom = fieldDomList[i].querySelector('.engage-error-tips');
                        if(errorDom) {
                            fieldDomList[i].children[1].removeChild(errorDom);
                            errorDom = null;
                        }
                        // validate field;
                        if(!itemContent.allowDuplicate){
                            needCheckDuplicate = true;
                        }
                        if(itemContent.isRequired) {
                            if(self.engageFormUtils.isEmpty(value)){
                                error = 'required_fail';
                                errorDom = self.engageFormUtils.getErrorTipDom(config.errorTips.required_fail);
                            }
                            else {
                                if(type === 'email' && !self.engageFormUtils.isValidEmail(value)){
                                    //email validator
                                    error = 'email_fail';
                                    errorDom = self.engageFormUtils.getErrorTipDom(config.errorTips.email_fail);
                                }
                                if(type === 'phone' && !self.engageFormUtils.isValidPhone(value)) {
                                    error = 'phone_fail';
                                    errorDom = self.engageFormUtils.getErrorTipDom(config.errorTips.phone_fail);
                                }
                            }    
                        }
                        else if(value && value !== ''){
                            if(type === 'email' && !self.engageFormUtils.isValidEmail(value)){
                                //email validator
                                error = 'email_fail';
                                errorDom = self.engageFormUtils.getErrorTipDom(config.errorTips.email_fail);
                            }
                            if(type === 'phone' && !self.engageFormUtils.isValidPhone(value)) {
                                error = 'phone_fail';
                                errorDom = self.engageFormUtils.getErrorTipDom(config.errorTips.phone_fail);
                            }

                        }


                        if(error && errorDom) {
                          
                            if(fieldDomList[i].children[1].append) {
                                
                                fieldDomList[i].children[1].append(errorDom);
                            }
                            else if(fieldDomList[i].children[1].appendChild){
                         
                                fieldDomList[i].children[1].appendChild(errorDom);
                            }
                            
                            hasError = true;
                        }
           
                        fieldValueList.push({
                            fieldID: fieldDomList[i].getAttribute('data-field-id'),
                            fieldValue: value,
                            fieldName: fieldDomList[i].getAttribute('data-field-name'),
                            fieldType: type,
                           
                            
                             
                        });
                        fieldIDList.push({
                            fieldID: id,
                            fieldName: fieldDomList[i].getAttribute('data-field-name'),
                            alowDuplicate: itemContent.allowDuplicate,
                            allowConnect: itemContent.allowConnect,
                            connectProperty: itemContent.connectProperty,
                            
                            fieldValue: value,
                            error: error,
                            errorDom: errorDom
                        });
                    }
                    
                    if(needCheckDuplicate){
                        //check duplicate
                        var field = fieldIDList.filter(function(item){return !item.alowDuplicate && item.fieldValue && item.fieldValue !== ''});
                        field = field && field.map(function(item){
                            return {
                                fieldID: item.fieldID,
                                fieldValue: item.fieldValue
                            }
                                
                        });
              
                        var ajaxOptions = {
                            url: '${domain}/formVerification',
                            method: 'POST',
                            async: false,
                            contentType: 'application/json',
                            data: {
                                field: field,
                                sid: "${sid}",
                                engageID: "${engageId}",
                                engageVersion: "${versionId}"
                            },
                            callback: function(res){
          
                                res = JSON.parse(res);
                                if(res && res.status === 'success'){
                                    var content = res.data;
                                    fieldIDList.forEach(function(item, index){
                                        console.log('content[item.fieldID]', content[item.fieldID]);
                                        if(content[item.fieldID] !== undefined) {
                                            if(!item.error && !content[item.fieldID] === true){
                                                item.error = 'error_duplicate';
                                                item.errorDom = self.engageFormUtils.getErrorTipDom(config.errorTips.error_duplicate);
                                                if(fieldDomList[index].children[1].append) {
                                               
                                                    fieldDomList[index].children[1].append(item.errorDom);
                                                }
                                                else if(fieldDomList[index].children[1].appendChild) {
                                         
                                                    fieldDomList[index].children[1].appendChild(item.errorDom);
                                                }
                                                
                                                hasError = true;
    
                                            }
                                        }
                                        
                                    });

                                    if(hasError) {
                                        return;
                                    }
                                    else if(${belong === 'normal'}) {
                                        self.engageFormUtils.saveFormData(fieldValueList, fieldIDList);
                                    }
                                    //GOTO NEXT WIDGET
                                    if(config.buttonAction) {
                                        if (config.buttonAction.type !== 'url'){
                                            console.log('window.ptEngage', window.ptEngage[config.engageId]);
                                            if(window.ptEngage && window.ptEngage[config.engageId]){
                                                window.ptEngage[config.engageId]._gotoNext(true);
                                                /* if(config.engageId !== ''){
                                                    window.ptEngage[config.engageId]._gotoNext(true);
                                                } */
                          
                                            }
                                            else if(window.ptEngage && window.ptEngage['preview']){
                                                window.ptEngage['preview']._gotoNext(true);
                                            }
                                        }
                                        else if(config.belong !== 'dialog'){
                                           window.open(config.buttonAction.url, '_self');
                                        }
                                    } 
                                    
                                }
                                
                            }
                        };
                        self.engageFormUtils.createAjax(ajaxOptions);

                    }
                    else if(hasError){
                        return;
                    }
                    else if(${belong === 'normal'}){
                        self.engageFormUtils.saveFormData(fieldValueList, fieldIDList);
                        
                    }
                    //GOTO NEXT WIDGET
                    if(!needCheckDuplicate && config.buttonAction) {
                        if (config.buttonAction.type !== 'url'){

                            if(window.ptEngage && window.ptEngage[config.engageId]){
                                window.ptEngage[config.engageId]._gotoNext(true);
                               
            
                            }
                            else if(window.ptEngage && window.ptEngage['preview']){
                                window.ptEngage['preview']._gotoNext(true);
                            }
                          
                        }
                        else if(config.belong !== 'dialog'){
                            window.open(config.buttonAction.url, '_self');
                        }
                    } 
                    
             
                    
                    
                },
                buttonAction: config.buttonAction
                
            };
       
      
           if(btnDom){
               
               if(btnDom.addEventListner) {
               
                   btnDom.addEventListener('click', self.engageFormUtils.handleFormSubmit);
               }
               else if(btnDom.attachEvent) {
                   btnDom.attachEvent('onclick', self.engageFormUtils.handleFormSubmit);
               }
               btnDom.addEventListener('click', self.engageFormUtils.handleFormSubmit);
           }
         
        }`;
        const unmountedEvent = `function(){
    
            var self = this;
            var formElWrapper = config.formElParent && document.querySelector(config.formElParent + '') || document;

            var fieldDomList = document.querySelectorAll(config.formElParent + '#' + config.blockId + ' .engage-form-field');
            var btnDom = formElWrapper.querySelector('#${id}-form-btn div');

            if(${belong === 'normal'}){
                btnDom = document.querySelector('#${id}-form-btn div');
            }
            if(btnDom) {
                if(btnDom.removeEventListner){
                    btnDom.removeEventListener('click', self.engageFormUtils.handleFormSubmit);
                }
                else if(btnDom.detachEvent) {
                    btnDom.detachEvent('onclick', self.engageFormUtils.handleFormSubmit);
                }
            }
          
        }`;
        const updatedEvent = `function(){
        
        }`;
        return `
        <script type="text/javascript">
         /** form validation code here start*/
            ;(function(config) {
                window.ptEngage = window.ptEngage || {};
                window.ptEngage[config.widgetId] = window.ptEngage[config.widgetId] || [];
                var index = -1;
                var formIndex = 0;
                for(formIndex=0; formIndex< window.ptEngage[config.widgetId].length; formIndex++){
                    var item = window.ptEngage[config.widgetId][formIndex];
                    if(item.blockId === config.blockId) {
                        index = formIndex;
                        return
                    }
                } 
                index !== -1 && window.ptEngage[config.widgetId].splice(index, 1);

                window.ptEngage[config.widgetId].push({
                    blockId: config.blockId,
                    mounted: ${mountedEvent},
                    unmounted: ${unmountedEvent},
                    updated: ${updatedEvent}
                }) 
            })(${JSON.stringify(ptFormConfig)});
        </script>
        ${subModelScript}
        `;
    }
};
