<template>
    <div class="block-settings">
        <div class="block-settings__header">
            <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
            <h3>{{ $t(block.blockName) }}</h3>
        </div>

        <div class="block-settings__container">
            <div class="block-settings__item-wrap">
                <!-- Link -->
                <div class="block-settings__item">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.theme_youtube') }}</div>
                    <div class="block-settings__item-container">
                        <pt-input
                            type="text"
                            size="big"
                            :autofocus="true"
                            :placeholder="placeholder.url"
                            v-model="currentContentValue.url"
                            v-validator="urlValidatorRule"
                            @blur="handleContentChange({needValidator: true})"
                            @change="handleContentChange({needValidator: true})"
                        ></pt-input>
                    </div>
                </div>

                <!-- Autoplay, 仅在PC端显示 -->
                <!-- youtube在移动设备下自动播放被限制，具体文档： https://developers.google.com/youtube/iframe_api_reference?hl=zh-cn#Autoplay_and_scripted_playback -->
                <div
                    class="block-settings__item"
                    v-if="currentDeviceIsPC && currentContentValue.url"
                >
                    <div class="block-settings__item-title two-cloumn no-margin">
                        <span>{{ $t('creative_editor.theme_autoplay') }}</span>

                        <div class="title-options">
                            <el-switch
                                class="el-switch__pt-small"
                                v-model="currentContentValue.autoplay"
                                :width="28"
                                @change="handleContentChange"
                            ></el-switch>
                        </div>
                    </div>
                </div>

                <!-- Margin top -->
                <div class="block-settings__item">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.block_margin_top') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-slider
                            class="block-slider pt-ml10 pt-mr20"
                            v-model="settings.marginTop"
                            :show-tooltip="false"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></el-slider>
                        <pt-input-number
                            class="max-width-60"
                            size="large"
                            v-model="settings.marginTop"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></pt-input-number>
                    </div>
                </div>

                <!-- Margin bottom -->
                <div class="block-settings__item">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.block_margin_bottom') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-slider
                            class="block-slider pt-ml10 pt-mr20"
                            v-model="settings.marginBottom"
                            :show-tooltip="false"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></el-slider>
                        <pt-input-number
                            class="max-width-60"
                            size="large"
                            v-model="settings.marginBottom"
                            :min="marginOptions.min"
                            :max="marginOptions.max"
                            @change="handleSettingsChange"
                        ></pt-input-number>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from './video.config';

export default {
    name: 'VideoSettings',
    inject: ['editor'],
    props: {
        block: {
            type: Object,
            required: true
        },
        widget: Object,
        widgetIndex: Number
    },

    data() {
        const defaultContentTab = config.content.find(item => item.default);
        const { placeholder, marginOptions, urlValidatorRule } = config;
        return {
            placeholder,
            marginOptions,
            urlValidatorRule,
            currentContentCode: defaultContentTab.code,

            content: [],
            settings: {}
        };
    },

    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        },

        currentDeviceIsPC() {
            //  const { device } = this.widget || {};
            return this.editor.currentDeviceId === 2;
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
        },

        handleBack() {
            this.$emit('back');
        },

        handleContentChange(params) {
            this.$emit('contentChange', this.content, params);
        },

        handleSettingsChange() {
            this.$emit('settingsChange', this.settings);
        }
    },

    watch: {
        'block.blockId': function() {
            this.init();
        }
    }
};
</script>