import i18n from '@assets/i18n';
import validatorFactory from '@/common/utils/validators.utils';

const urlValidatorRule = [
    {
        type: 'required',
        trigger: 'blur',
        errMsg: i18n.t('creative_editor.video_tips')
    },
    {
        type: 'isYoutubeUrl',
        trigger: 'blur',
        errMsg: i18n.t('creative_editor.video_tips')
    }
];

const blockContentValidatorFun = function(contentValue) {
    const url = contentValue.url;
    let invalid = false;
    let invalidRule = '';

    if (url) {
        urlValidatorRule.forEach(rule => {
            if (!invalid) {
                invalid = validatorFactory[rule.type](url);
                //  rule.type === 'required' ? validatorFactory[rule.type](url) : !validatorFactory[rule.type](url);
                invalid && (invalidRule = rule);
            }
        });
    } else {
        invalid = true;
    }
    console.log('video blockContentValidatorFun', invalid, invalidRule);
    return {
        invalid,
        invalidRule
    };
};

export default {
    blockContentValidatorFun,
    placeholder: {
        url: i18n.t('creative_editor.video_placeholder')
    },
    urlValidatorRule,
    marginOptions: {
        min: 0,
        max: 100
    },
    content: [
        {
            code: 'videmo1',
            name: i18n.t('creative_editor.video_one'),
            value: {
                url: '', //test url: https://www.youtube.com/watch?v=dgtPmOuF3qk
                autoplay: true
            },
            default: true
        }
    ],
    settings: {
        marginTop: 8,
        marginBottom: 8
    }
};
