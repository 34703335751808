<template>
    <div class="block-settings">
        <div class="block-settings__header" v-if="!isSubModel">
            <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
            <h3>{{ block.blockName }}</h3>
        </div>
        <div class="block-settings__container">
            <!--  <pt-scrollbar ref="blockScrollbar"> -->
            <div class="block-settings__item-wrap">
                <!-- Button position -->
                <kits
                    type="select"
                    :title="$t('creative_editor.item_button_position')"
                    target="buttonPosition"
                    :value="settings.buttonPosition"
                    :options="buttonPositionOptions"
                    @change="handleKitSettingsChange"
                ></kits>
                <!-- Alignment -->
                <div class="block-settings__item" v-if="settings.buttonPosition === 'bottom'">
                    <div
                        class="block-settings__item-title"
                    >{{ $t('creative_editor.item_btn_alignment') }}</div>
                    <div class="block-settings__item-container flex-layout">
                        <el-radio-group
                            size="small"
                            v-model="settings.buttonAlignment"
                            @change="handleKitSettingsChange"
                        >
                            <el-radio-button
                                v-for="item in alignmentOptions"
                                :key="item.code"
                                :label="item.code"
                            >
                                <pt-icon :icon="'pt-icon--' + item.icon"></pt-icon>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <!--  Background color -->
                <kits
                    type="colorPicker"
                    :title="$t('creative_editor.creative_widget_bg')"
                    target="backgroundColor"
                    :value="settings.backgroundColor"
                    v-model="settings.backgroundColor"
                    @change="handleKitSettingsChange"
                ></kits>
                <!-- Spacing between fields -->
                <kits
                    type="slider"
                    :title="$t('creative_editor.item_field_spacing')"
                    target="fieldSpacing"
                    :min="spaceingOptions.min"
                    :max="spaceingOptions.max"
                    :value="settings.fieldSpacing"
                    @change="handleKitSettingsChange"
                ></kits>
                <!-- Spacing between fields and button -->
                <kits
                    type="slider"
                    :title="$t('creative_editor.item_field_btn_spacing')"
                    target="buttonSpacing"
                    :min="spaceingOptions.min"
                    :max="spaceingOptions.max"
                    :value="settings.buttonSpacing"
                    @change="handleKitSettingsChange"
                ></kits>
                <!-- Top margin -->
                <kits
                    type="slider"
                    :title="$t('creative_editor.block_margin_top')"
                    target="topMargin"
                    :min="marginOptions.min"
                    :max="marginOptions.max"
                    :value="settings.topMargin"
                    @change="handleKitSettingsChange"
                ></kits>
                <!-- Down margin -->
                <kits
                    type="slider"
                    :title="$t('creative_editor.block_margin_bottom')"
                    target="bottomMargin"
                    :min="marginOptions.min"
                    :max="marginOptions.max"
                    :value="settings.bottomMargin"
                    @change="handleKitSettingsChange"
                ></kits>
            </div>
            <!--   </pt-scrollbar> -->
        </div>
    </div>
</template>

<script>
import Kits from '../../kits/Kits.vue';
import config from './general.config';
import { tabOptions, formDesignOptions } from '../../configs/creative.config';
export default {
    name: 'FormGeneralSettings',
    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        //是否是表单提交按钮
        refer: String,
        tab: String
    },
    data() {
        const currentTab = this.tab || '';
        const { defaultContent, marginOptions, spaceingOptions, buttonPositionOptions, alignmentOptions } = config;
        const defaultTab =
            this.refer && this.refer === 'form'
                ? formDesignOptions.find(item => item.code === currentTab)
                : tabOptions.find(item => item.default);
        const defaultContentTab = config.content.find(item => item.default);
        return {
            defaultTab,
            defaultContentTab,

            marginOptions,
            spaceingOptions,
            buttonPositionOptions,
            alignmentOptions,

            currentTab: '',
            currentContentCode: '',

            content: [],
            settings: {}
        };
    },
    inject: ['formDesign'],
    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.currentContentCode = this.defaultContentTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
        },
        handleBack() {
            this.$emit('back');
        },
        handleContentChange(params) {
            console.log('handleContent change', params);
            this.$emit('contentChange', this.content, params);
        },
        handleKitChange(val) {
            this.$set(this.currentContentValue, val.type, val.value);
            this.handleContentChange();
        },
        handleKitSettingsChange(val) {
            if (val.type) {
                this.$set(this.settings, val.type, val.value);
            }

            this.$emit('settingsChange', this.settings);
        }
    },
    components: {
        Kits
    }
};
</script>
