<template>
    <div class="block-settings image-settings" :class="{ 'has-tab': !isSubModel }">
        <template v-if="!isSubModel">
            <div class="block-settings__header">
                <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
                <h3>{{ $t(block.blockName) }}</h3>
            </div>

            <div class="block-settings__tab">
                <settings-tab :tab="currentTab" @change="handleTabChange"></settings-tab>
            </div>
        </template>

        <div class="block-settings__content-tab" v-if="columnHasMultiseriate && currentTab === 'content'">
            <el-tabs v-model="currentContentCode" @tab-click="handleContentTabChange">
                <el-tab-pane
                    v-for="(item, $index) in content"
                    :key="item.code"
                    :label="$t('creative_editor.image_num', { number: $index + 1 })"
                    :name="item.code"
                ></el-tab-pane>
            </el-tabs>
        </div>

        <div class="block-settings__container" :class="{ 'has-content-tab': columnHasMultiseriate }">
            <pt-scrollbar ref="blockScrollbar">
                <div class="block-settings__item-wrap">
                    <!-- content -->
                    <template v-if="currentTab === 'content'">
                        <!-- Image -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-container">
                                <div class="block-upload" :class="{ 'has-img': Boolean(currentContentValue.img) }">
                                    <img
                                        class="block-upload__img"
                                        v-if="currentContentValue.img"
                                        :src="currentContentValue.img"
                                    />

                                    <el-upload
                                        class="block-upload__plugin"
                                        name="upload"
                                        :list-type="currentContentValue.img ? 'text' : 'picture-card'"
                                        :action="uploadState.uploadUrl"
                                        :accept="uploadState.uploadAccept"
                                        :show-file-list="false"
                                        :headers="{
                                            'Access-Control-Allow-Origin': '*',
                                            'Content-Type': 'multipart/form-data'
                                        }"
                                        :before-upload="handleBeforeUpload"
                                        :on-success="handleImageUploadSuccess()"
                                        :http-request="handleImageUpload"
                                    >
                                        <!--   -->
                                        <pt-icon icon="pt-icon--plus" v-if="!currentContentValue.img"></pt-icon>
                                        <el-tooltip
                                            v-else
                                            :content="$t('common.button_change')"
                                            placement="top"
                                            effect="light"
                                        >
                                            <pt-icon icon="pt-icon--upload"></pt-icon>
                                        </el-tooltip>
                                    </el-upload>

                                    <div
                                        class="block-upload__clear"
                                        v-if="currentContentValue.img"
                                        @click="handleImageClear()"
                                    >
                                        <el-tooltip placement="top" effect="light" :content="$t('common.button_clear')">
                                            <pt-icon icon="pt-icon--delete"></pt-icon>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template v-if="currentContentValue.img">
                            <!-- Size -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">{{ $t('creative_editor.creative_size') }}</div>
                                <div class="block-settings__item-container two-column">
                                    <el-input
                                        class="ipt-size"
                                        size="small"
                                        maxlength="3"
                                        v-model="currentContentValue.width"
                                        type="number"
                                        min="0"
                                        @change="handleImageSizeChange($event, 'width')"
                                    >
                                        <pt-icon slot="suffix" icon="pt-icon--width" size="medium"></pt-icon>
                                    </el-input>
                                    <el-input
                                        class="ipt-size"
                                        size="small"
                                        maxlength="3"
                                        v-model="currentContentValue.height"
                                        type="number"
                                        min="0"
                                        @change="handleImageSizeChange($event, 'height')"
                                    >
                                        <pt-icon slot="suffix" icon="pt-icon--height" size="medium"></pt-icon>
                                    </el-input>
                                </div>
                                <div class="block-settings__item-container pt-mt5">
                                    <el-checkbox
                                        class="checkbox__small-font"
                                        v-model="currentContentValue.sizeLock"
                                        @change="handleContentChange"
                                        >{{ $t('creative_editor.img_ratio') }}</el-checkbox
                                    >
                                </div>
                            </div>

                            <div class="block-settings__hr"></div>

                            <!-- Action -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title two-cloumn">
                                    <span>{{ $t('creative_editor.creative_img_action') }}</span>

                                    <div class="title-options">
                                        <el-switch
                                            class="el-switch__pt-small"
                                            v-model="currentContentValue.action.enable"
                                            :width="28"
                                            @change="handleContentChange({ needValidator: true })"
                                        ></el-switch>
                                    </div>
                                </div>
                                <div class="block-settings__item-container" v-if="currentContentValue.action.enable">
                                    <div class="block-action" v-for="item in actionEventsOptions" :key="item.code">
                                        <div class="block-action__radio">
                                            <el-radio
                                                v-model="currentContentValue.action.type"
                                                :label="item.code"
                                                @change="handleContentChange({ needValidator: true })"
                                                >{{ item.name }}</el-radio
                                            >
                                            <el-popover
                                                placement="bottom"
                                                width="300"
                                                trigger="hover"
                                                v-if="item.tip"
                                                :visible-arrow="false"
                                                :content="item.tip"
                                            >
                                                <pt-icon
                                                    slot="reference"
                                                    class="help-icon"
                                                    icon="pt-icon--help-new"
                                                    size="medium"
                                                ></pt-icon>
                                            </el-popover>
                                        </div>
                                        <div
                                            class="block-action__value"
                                            v-if="item.code === 'url' && currentContentValue.action.type === 'url'"
                                        >
                                            <span
                                                class="block-action__value-sign"
                                                v-show="currentContentValue.action.type === 'url'"
                                                >*</span
                                            >
                                            <pt-input
                                                type="text"
                                                size="big"
                                                :disabled="currentContentValue.action.type !== 'url'"
                                                :placeholder="placeholder.url"
                                                :warning="checkActionResult && checkActionResult.hasError"
                                                :warningText="checkActionResult && checkActionResult.errMsg"
                                                v-model="currentContentValue.action.url"
                                                v-validator="urlValidatorRule"
                                                @blur="handleUrlIptBlur"
                                                @enter="handleUrlIptBlur"
                                                @change="handleContentChange({ needValidator: true })"
                                            ></pt-input>
                                            <el-checkbox
                                                :disabled="currentContentValue.action.type !== 'url'"
                                                v-model="currentContentValue.action.openNewTab"
                                                @change="handleContentChange"
                                                >{{ $t('creative_editor.creative_open_new') }}</el-checkbox
                                            >
                                        </div>
                                        <div
                                            class="block-action__value"
                                            v-if="item.code === 'tel' && currentContentValue.action.type === 'tel'"
                                        >
                                            <span
                                                class="block-action__value-sign"
                                                v-show="currentContentValue.action.type === 'tel'"
                                                >*</span
                                            >
                                            <pt-input
                                                type="text"
                                                size="big"
                                                :disabled="currentContentValue.action.type !== 'tel'"
                                                :placeholder="placeholder.tel"
                                                :warning="checkActionResult && checkActionResult.hasError"
                                                :warningText="checkActionResult && checkActionResult.errMsg"
                                                v-model="currentContentValue.action.tel"
                                                v-validator="telValidatorRule"
                                                @change="handleContentChange({ needValidator: true })"
                                            ></pt-input>
                                        </div>
                                        <div
                                            class="block-action__value"
                                            v-if="
                                                item.code === 'gotoWidget' &&
                                                    currentContentValue.action.type === 'gotoWidget'
                                            "
                                        >
                                            <el-select
                                                size="small"
                                                v-model="currentContentValue.action.gotoWidget"
                                                :class="{
                                                    'select-error': checkActionResult && checkActionResult.hasError
                                                }"
                                                :placeholder="$t('creative_editor.goto_widget_placeholder')"
                                                @visible-change="handleActionSelectVisibleChange"
                                                @change="handleContentChange({ needValidator: true })"
                                            >
                                                <el-option
                                                    v-for="item in currentGotoWidgetOptions"
                                                    :key="item.code"
                                                    :label="item.name"
                                                    :value="item.code"
                                                ></el-option>
                                            </el-select>
                                            <div
                                                class="add-step__err-tips"
                                                v-if="checkActionResult && checkActionResult.hasError"
                                            >
                                                {{ checkActionResult && checkActionResult.errMsg }}
                                            </div>
                                            <div class="add-step__tips" v-show="addStepText">
                                                <img
                                                    v-if="language === 'en'"
                                                    alt="add step tips"
                                                    src="~@assets/images/creative/add-step-tips-en.png"
                                                />
                                                <img
                                                    v-else-if="language === 'jp'"
                                                    alt="add step tips"
                                                    src="~@assets/images/creative/add-step-tips-jp.png"
                                                />
                                                <img
                                                    v-else
                                                    alt="add step tips"
                                                    src="~@assets/images/creative/add-step-tips-zh.png"
                                                />
                                                <p>{{ $t('creative_editor.goto_widget_tips') }}</p>
                                                <pt-button @click="gotoWidget">
                                                    {{ addStepText }}
                                                </pt-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="block-settings__hr"></div>

                            <!-- Round corners -->
                            <div class="block-settings__item">
                                <div class="block-settings__item-title">{{ $t('creative_editor.corner_radius') }}</div>
                                <div class="block-settings__item-container flex-layout">
                                    <el-slider
                                        class="block-slider pt-ml10 pt-mr20"
                                        v-model="currentContentValue.borderRadius"
                                        :show-tooltip="false"
                                        :min="borderRadiusOptions.min"
                                        :max="borderRadiusOptions.max"
                                        @change="handleContentChange"
                                    ></el-slider>
                                    <pt-input-number
                                        class="max-width-60"
                                        size="large"
                                        v-model="currentContentValue.borderRadius"
                                        :min="borderRadiusOptions.min"
                                        :max="borderRadiusOptions.max"
                                        @change="handleContentChange"
                                    ></pt-input-number>
                                </div>
                            </div>
                        </template>
                    </template>

                    <!-- settings -->
                    <template v-else>
                        <!-- Number of columns -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.images_numbers') }}</div>
                            <div class="block-settings__item-container">
                                <el-select size="small" v-model="settings.number" @change="handleNumberChange">
                                    <el-option
                                        v-for="item in numberOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>

                        <div class="block-settings__item" v-if="columnHasMultiseriate">
                            <div class="block-settings__item-title">{{ $t('creative_editor.item_layout') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <div
                                    class="block-item__icon"
                                    v-for="item in layoutOptions"
                                    :key="item.code"
                                    :class="{ active: settings.layout === item.code }"
                                    @click="handleLayoutClick(item.code)"
                                >
                                    <pt-icon
                                        :icon="'pt-icon--' + item.icon"
                                        :iconStyle="{ width: '100%', height: '100%' }"
                                    ></pt-icon>
                                </div>
                            </div>
                        </div>

                        <!-- spacing -->
                        <div class="block-settings__item" v-if="columnHasMultiseriate">
                            <div class="block-settings__item-title">{{ $t('creative_editor.item_spacing') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <el-slider
                                    class="block-slider pt-ml10 pt-mr20"
                                    v-model="settings.spacing"
                                    :show-tooltip="false"
                                    :min="spaceingOptions.min"
                                    :max="spaceingOptions.max"
                                    @change="handleSettingsChange"
                                ></el-slider>
                                <pt-input-number
                                    class="max-width-60"
                                    size="large"
                                    v-model="settings.spacing"
                                    :min="spaceingOptions.min"
                                    :max="spaceingOptions.max"
                                    @change="handleSettingsChange"
                                ></pt-input-number>
                            </div>
                        </div>

                        <!-- Edge to edge -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title two-cloumn no-margin">
                                <span>{{ $t('creative_editor.edge_to_edge') }}</span>

                                <div class="title-options">
                                    <el-switch
                                        class="el-switch__pt-small"
                                        v-model="settings.noPadding"
                                        :width="28"
                                        @change="handleSettingsChange"
                                    ></el-switch>
                                </div>
                            </div>
                        </div>

                        <!-- Background color -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.creative_widget_bg') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <el-color-picker
                                    class="pt-mr10"
                                    v-model="settings.backgroundColor"
                                    @change="handleSettingsChange"
                                ></el-color-picker>
                                <el-input
                                    size="small"
                                    v-model="settings.backgroundColor"
                                    @change="handleSettingsChange"
                                ></el-input>
                            </div>
                        </div>

                        <!-- Margin top -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">{{ $t('creative_editor.block_margin_top') }}</div>
                            <div class="block-settings__item-container flex-layout">
                                <el-slider
                                    class="block-slider pt-ml10 pt-mr20"
                                    v-model="settings.marginTop"
                                    :show-tooltip="false"
                                    :min="marginOptions.min"
                                    :max="marginOptions.max"
                                    @change="handleSettingsChange"
                                ></el-slider>
                                <pt-input-number
                                    class="max-width-60"
                                    size="large"
                                    v-model="settings.marginTop"
                                    :min="marginOptions.min"
                                    :max="marginOptions.max"
                                    @change="handleSettingsChange"
                                ></pt-input-number>
                            </div>
                        </div>

                        <!-- Margin bottom -->
                        <div class="block-settings__item">
                            <div class="block-settings__item-title">
                                {{ $t('creative_editor.block_margin_bottom') }}
                            </div>
                            <div class="block-settings__item-container flex-layout">
                                <el-slider
                                    class="block-slider pt-ml10 pt-mr20"
                                    v-model="settings.marginBottom"
                                    :show-tooltip="false"
                                    :min="marginOptions.min"
                                    :max="marginOptions.max"
                                    @change="handleSettingsChange"
                                ></el-slider>
                                <pt-input-number
                                    class="max-width-60"
                                    size="large"
                                    v-model="settings.marginBottom"
                                    :min="marginOptions.min"
                                    :max="marginOptions.max"
                                    @change="handleSettingsChange"
                                ></pt-input-number>
                            </div>
                        </div>
                    </template>
                </div>
            </pt-scrollbar>
        </div>
    </div>
</template>

<script>
import config from './image.config';
import SettingsTab from '../SettingsTab';
import { tabOptions, uploadState, widgetsMinCount, widgetsMaxCount } from '../../configs/creative.config';
import { getImgSize } from '../../utils/blocks.util';
import engageApis from '@/apis/engage.apis';
import cloneUtils from '@common/utils/clone.utils';

export default {
    name: 'ImageSettings',

    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        widgetIndex: Number
    },

    data() {
        const defaultTab = tabOptions.find(item => item.default);
        const defaultContentTab = config.content.find(item => item.default);
        const {
            placeholder,
            gotoWidgetOptions,
            borderRadiusOptions,
            actionEventsOptions,
            positionOptions,
            numberOptions,
            layoutOptions,
            marginOptions,
            spaceingOptions,
            urlValidatorRule,
            telValidatorRule,
            blockContentValidatorFun
        } = config;
        return {
            defaultTab,
            defaultContentTab,

            placeholder,
            uploadState,
            gotoWidgetOptions,
            borderRadiusOptions,
            actionEventsOptions,
            positionOptions,
            numberOptions,
            layoutOptions,
            marginOptions,
            spaceingOptions,
            urlValidatorRule,
            telValidatorRule,
            blockContentValidatorFun,

            currentTab: '',
            currentContentCode: '',

            content: [],
            settings: {},

            checkActionResult: null,
            language: window.pt.global.language,
            imageUpload: null
        };
    },

    computed: {
        currentIsFirstStep() {
            return this.widgetIndex === widgetsMinCount - 1;
        },

        currentIsLastStep() {
            return this.widgetIndex === widgetsMaxCount - 1;
        },

        currentGotoWidgetOptions() {
            return this.gotoWidgetOptions.reduce((acc, cur) => {
                const showIt = cur.showIt(this.currentIsFirstStep, this.currentIsLastStep);
                showIt && acc.push(cur);
                return acc;
            }, []);
        },

        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return (contentConfig && contentConfig.value) || {};
        },

        columnHasMultiseriate() {
            return config.hasMultiple(this.block);
        },

        addStepText() {
            const { type, gotoWidget } = this.currentContentValue.action;
            const opt = this.gotoWidgetOptions.find(item => item.code === gotoWidget);
            return (type === 'gotoWidget' && opt && opt.editBtnText) || '';
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.currentContentCode = this.defaultContentTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
            this.checkActionResult = this.checkAction();
        },

        checkAction() {
            const { invalid, invalidRule } = this.blockContentValidatorFun(this.currentContentValue) || {};
            return {
                hasError: invalid,
                errMsg: (invalidRule && invalidRule.errMsg) || ''
            };
        },

        handleBack() {
            this.$emit('back');
        },

        handleTabChange(tab) {
            this.currentTab = tab;

            if (tab === 'content' && !this.columnHasMultiseriate) {
                this.currentContentCode = this.content[0].code;
            }
            this.$refs.blockScrollbar && this.$refs.blockScrollbar.scrollToPos('top');
        },

        handleContentTabChange() {
            this.$nextTick(() => {
                this.checkActionResult = this.checkAction();
            });
        },

        handleUrlIptBlur() {
            // 如果没填协议 自己给补上
            const reg = /^(http|https:\/\/)/;
            let url = this.currentContentValue.action.url;
            if (url !== '' && !reg.test(url)) {
                this.$set(this.currentContentValue.action, 'url', `http://${url}`);
            }
        },

        handleImageSizeChange(value, type) {
            const { width, height, img, sizeLock, imgWidth, imgHeight } = this.currentContentValue;
            let size = {
                width,
                height
            };
            size[type] = value;
            if (sizeLock) {
                if (!Object.keys(size).some(item => item === '')) {
                    let _self = this;
                    let _setImgSize = function(iWidth, iHeight) {
                        const rate = iWidth / iHeight;
                        if (type === 'width') {
                            const height = parseInt(parseFloat(value) / rate);
                            _self.$set(_self.currentContentValue, 'height', height);
                        } else {
                            const width = parseInt(parseFloat(value) * rate);
                            _self.$set(_self.currentContentValue, 'width', width);
                        }

                        if (imgWidth) {
                            _self.$set(_self.currentContentValue, 'imgWidth', iWidth);
                            _self.$set(_self.currentContentValue, 'imgHeight', iHeight);
                        }
                        _self.$set(_self.currentContentValue, type, value);
                        _self.handleContentChange();
                    };

                    if (imgWidth && imgHeight) {
                        _setImgSize(imgWidth, imgHeight);
                    } else {
                        getImgSize(img, _setImgSize);
                    }
                }
            } else {
                this.$set(this.currentContentValue, type, value);
                this.handleContentChange();
            }
        },

        handleSizeLockToggle() {
            const sizeLock = !this.currentContentValue.sizeLock;
            this.$set(this.currentContentValue, 'sizeLock', sizeLock);
            this.handleContentChange();
        },

        handleActionSelectVisibleChange(visible) {
            this.checkActionResult = visible ? null : this.checkAction();
        },

        handleContentChange(params) {
            this.$emit('contentChange', this.content, params);
        },
        handleImageUpload(file) {
            console.log('handleImageUpload', file);
            var formData = new FormData();

            formData.append('upload', file.file); //通过append向form对象添加数据
            engageApis.imageUpload(null, formData).then(res => {
                console.log('imageUpload responst', res);
                this.$set(this.currentContentValue, 'img', res);
                this.handleContentChange({ needValidator: true });
            });
        },
        handleBeforeUpload(file) {
            const isLtMaxSize = file.size <= this.uploadState.backgroundImageMaxSize;
            if (!isLtMaxSize) {
                this.$message.error(this.$t('common.setting_engage_img_tips'));
                this.$set(this.currentContentValue, 'imgName', '');
            } else {
                this.$set(this.currentContentValue, 'imgName', file.name);
            }
            return isLtMaxSize;
        },

        handleImageUploadSuccess(res) {
            console.log('handleImageUploadSuccess', res);
            const _self = this;
            return function(file, fileList) {
                console.log('file', file);
                getImgSize(file.content, function(width, height) {
                    _self.$set(_self.currentContentValue, 'img', file.content);
                    _self.$set(_self.currentContentValue, 'imgWidth', width);
                    _self.$set(_self.currentContentValue, 'imgHeight', height);
                    _self.handleImageSizeChange(_self.currentContentValue.width, 'width');
                    _self.handleContentChange({ needValidator: true });
                });
            };
        },

        handleImageClear() {
            this.currentContentValue.img = null;
            this.handleContentChange({ needValidator: true });
        },

        handleNumberChange(val) {
            if (val === 'two') {
                const newContent = Object.assign({}, cloneUtils.deep(this.content[0]), {
                    code: 'image2',
                    name: this.$t('creative_editor.image_two')
                });
                this.content.splice(1, 1, newContent);
            } else {
                this.content.splice(1, 1);
                this.currentContentCode = this.defaultContentTab.code;
            }
            this.handleContentChange();
        },

        handleSettingsChange() {
            this.$emit('settingsChange', this.settings);
        },

        gotoWidget() {
            const { gotoWidget } = this.currentContentValue.action;
            const opt = this.gotoWidgetOptions.find(item => item.code === gotoWidget);
            opt && this.$emit('gotoWidget', opt.nextIndex(this.widgetIndex));
        },

        handleLayoutClick(layout) {
            this.settings.layout = layout;
            this.handleSettingsChange();
        },

        handlePositionClick(position) {
            this.currentContentValue.position = position;
            this.handleContentChange();
        }
    },

    watch: {
        'block.blockId': function() {
            this.init();
        }
    },

    components: {
        SettingsTab
    }
};
</script>
