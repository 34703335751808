export default {
    getHtml(block) {
        const id = block.blockId;
        return `<div id="${id}"></div>`;
    },
    
    getStyle(block) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        return `
            #${id} {
                width: 100%;
                height: ${settings.height}px;
                overflow: hidden;
            }
        `;
    }
};
