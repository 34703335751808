import GeneralConfig from './general.config';
import GeneralSettings from './GeneralSettings.vue';
import InputConfig from '../input/input.config';
import InputSettings from '../input/InputSettings.vue';

import FieldConfig from '../field/field.config';
import FieldSettings from '../field/FieldSettings.vue';

import ButtonConfig from '../button/button.config';
import ButtonSettings from '../button/ButtonSettings.vue';

import { getDefaultBlock } from '@/components/engagement/creative/editor/utils/blocks.util';
import blocksConfig from '@/components/engagement/creative/editor/configs/blocks.config';
import uuidUtils from '@common/utils/uuid.utils';
import cloneUtils from '@/common/utils/clone.utils';
import i18n from '@assets/i18n';
const FormComponents = [
    {
        name: 'creative_editor.theme_field',
        code: 'field',
        config: FieldConfig
    },
    {
        name: 'creative_editor.theme_button',
        code: 'button',
        config: ButtonConfig
    }
];

const FormDesignBlocks = [
    {
        name: 'creative_editor.form_general',
        code: 'form',
        formCode: 'general',
        config: GeneralConfig,
        component: GeneralSettings
    },
    {
        name: 'creative_editor.form_label',
        code: 'field',
        formCode: 'field',
        config: FieldConfig,
        component: FieldSettings
    },
    {
        name: 'creative_editor.form_input',
        code: 'input',
        formCode: 'input',
        config: InputConfig,
        component: InputSettings
    },
    {
        name: 'creative_editor.theme_button',
        code: 'button',
        formCode: 'button',
        config: ButtonConfig,
        component: ButtonSettings
    }
];
const btnDefaultSettingReset = {
    marginTop: 0,
    marginBottom: 0
};
const btnDefaultContentReset = {
    //  text: 'Submit'
    action: {
        type: 'gotoWidget', //['close', 'url', 'tel', 'gotoWidget]
        url: '',
        openNewTab: true,
        tel: '',
        gotoWidget: 'next'
    },
    text: i18n.t('creative_editor.button_submit')
};
const formContent = FormComponents.map(item => {
    const { name, code, config } = item;
    const { content, settings } = config;

    let valueContent = cloneUtils.deep(content);
    let valueSettings = cloneUtils.deep(settings);
    if (item.code === 'button') {
        valueContent[0].value = Object.assign(valueContent[0].value, btnDefaultContentReset);
        console.log('form value content', valueContent);
        valueSettings = Object.assign(valueSettings, btnDefaultSettingReset);
    }
    return {
        name,
        code,
        value: {
            id: `pt_${uuidUtils.uuid_8bit()}`,
            content: valueContent,
            settings: valueSettings
        },
        default: true
    };
});
/**
 * 组件内容自动设置部分
 */
const defaultEndMessage = i18n.t('creative_editor.form_submitted_default_msg');
const getBlockContentReplaceFun = function() {
    var textBlock = blocksConfig.find(item => item.code === 'text');
    textBlock.config &&
        textBlock.config.content[0] &&
        textBlock.config.content[0].value &&
        (textBlock.config.content[0].value.text = defaultEndMessage);
    return getDefaultBlock(textBlock);
};
export default {
    hasSubModules: true,
    content: formContent,
    getBlockContentReplaceFun,
    settings: {
        general: GeneralConfig.settings,
        field: FieldConfig.settings,
        input: InputConfig.settings
    },
    dataSettings: {
        pushToDB: false,
        dbApi: ''
    },
    formBlocks: FormDesignBlocks
};
