"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var actions = {
    test: function (a) {
        a.dispatch('campaign/test2', '123');
        return 1;
    },
    test2: function (context, payload) {
        context.dispatch('campaign/test', 123);
        return '123';
    }
};
exports.default = actions;
