"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropPlaceholderFlexContainerClass = exports.dropPlaceholderWrapperClass = exports.dropPlaceholderInnerClass = exports.dropPlaceholderDefaultClass = exports.preventAutoScrollClass = exports.noUserSelectClass = exports.disbaleTouchActions = exports.isDraggableDetached = exports.stretcherElementInstance = exports.stretcherElementClass = exports.extraSizeForInsertion = exports.containerClass = exports.ghostClass = exports.visibilityValue = exports.translationValue = exports.animationClass = exports.defaultGrabHandleClass = exports.wrapperClass = exports.defaultGroupName = exports.containerInstance = void 0;
exports.containerInstance = 'smooth-dnd-container-instance';
exports.defaultGroupName = '@@smooth-dnd-default-group@@';
exports.wrapperClass = 'smooth-dnd-draggable-wrapper';
exports.defaultGrabHandleClass = 'smooth-dnd-default-grap-handle';
exports.animationClass = 'animated';
exports.translationValue = '__smooth_dnd_draggable_translation_value';
exports.visibilityValue = '__smooth_dnd_draggable_visibility_value';
exports.ghostClass = 'smooth-dnd-ghost';
exports.containerClass = 'smooth-dnd-container';
exports.extraSizeForInsertion = 'smooth-dnd-extra-size-for-insertion';
exports.stretcherElementClass = 'smooth-dnd-stretcher-element';
exports.stretcherElementInstance = 'smooth-dnd-stretcher-instance';
exports.isDraggableDetached = 'smoth-dnd-is-draggable-detached';
exports.disbaleTouchActions = 'smooth-dnd-disable-touch-action';
exports.noUserSelectClass = 'smooth-dnd-no-user-select';
exports.preventAutoScrollClass = 'smooth-dnd-prevent-auto-scroll-class';
exports.dropPlaceholderDefaultClass = 'smooth-dnd-drop-preview-default-class';
exports.dropPlaceholderInnerClass = 'smooth-dnd-drop-preview-inner-class';
exports.dropPlaceholderWrapperClass = 'smooth-dnd-drop-preview-constant-class';
exports.dropPlaceholderFlexContainerClass = 'smooth-dnd-drop-preview-flex-container-class';
