const findEle = (parent, type) => {
    return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type);
};

export default {
    name: 'onlyNumber',

    bind: (el, binding) => {
        const $inp = findEle(el, 'input');
        el.$inp = $inp;
        $inp.handle = function() {
            const newVal = this.value.replace(/[0-9]/g, '');
            el.$inp.value = newVal;
            binding.value(newVal);
        };
        $inp.addEventListener('input', $inp.handle);
    },
    undbind: function(el) {
        el.$inp.removeEventListener('input', el.$inp.handle);
    }
};
