"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var string_utils_1 = require("@/common/utils/string.utils");
var common_utils_1 = require("@common/utils/common.utils");
function swrWrapper(resources) {
    var apis = {};
    Object.entries(resources).forEach(function (_a) {
        var _b;
        var apiKey = _a[0], resource = _a[1];
        var envConfigs = ((_b = process.env) === null || _b === void 0 ? void 0 : _b.VUE_APP_CONFIGS) || {};
        var host = envConfigs.backend || '';
        var _c = resource.url, url = _c === void 0 ? '/graphql' : _c, _d = resource.method, method = _d === void 0 ? 'post' : _d, query = resource.query, _e = resource.isGraphql, isGraphql = _e === void 0 ? true : _e, name = resource.name;
        apis[apiKey] = function (data, options) {
            if (data === void 0) { data = {}; }
            if (options === void 0) { options = {}; }
            var supplants = options.supplants, customParams = options.customParams, swrOptions = options.swrOptions, _a = options.afterFix, afterFix = _a === void 0 ? '' : _a, key = options.key, _b = options.isSwr, isSwr = _b === void 0 ? true : _b;
            var params = {
                headers: {},
                method: method.toUpperCase(),
                url: host + (supplants ? (0, string_utils_1.supplant)(url, supplants) : url)
            };
            var methodKey = params.method === 'GET' ? 'params' : 'data';
            if (data) {
                params[methodKey] = isGraphql
                    ? {
                        operationName: name,
                        query: query,
                        variables: data
                    }
                    : data;
            }
            if (customParams)
                params = Object.assign(params, customParams);
            var fetcher = function () { return axios_1.default
                .request(params)
                .then(function (res) {
                var resData = res.data;
                var _a = res.data || {}, code = _a.code, message = _a.message, status = _a.status;
                if (status && status.toLowerCase() === 'error') {
                    var rj = {
                        code: code,
                        message: message,
                        data: res.data
                    };
                    return Promise.reject(rj);
                }
                if (isGraphql) {
                    var data_1 = (res.data || {}).data;
                    if (data_1) {
                        var keys = Object.keys(data_1 || {});
                        resData = keys.length === 1 ? data_1[keys[0]] : data_1;
                    }
                    else {
                        return Promise.reject({ code: 'NO_CONTENT' });
                    }
                }
                return Promise.resolve(resData);
            })
                .catch(function (error) {
                if (axios_1.default.isCancel(error)) {
                    return Promise.reject(error);
                }
                if (error.response && error.response.data) {
                    return Promise.reject(error.response.data);
                }
                return Promise.reject(error);
            }); };
            if (isSwr) {
                return fetcher;
            }
            else
                return common_utils_1.default.awaitWrap(fetcher());
        };
    });
    return apis;
}
exports.default = swrWrapper;
;
