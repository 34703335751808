import i18n from '@assets/i18n';
const textDefaultContent =
    "<p style='line-height: 1.4;'><b><span style='font-size:24px;'>Check out!</span></b></p><p style='line-height: 1.4;'><span style='font-size: 14px;'>Add a small description for your promo, explain its features, benefits and advantages.</span></p>";
export default {
    defaultContent: textDefaultContent,
    alignmentOptions: [
        {
            code: 'left',
            icon: 'alignment-left'
        },
        {
            code: 'center',
            icon: 'alignment-center'
        },
        {
            code: 'right',
            icon: 'alignment-right'
        }
    ],
    columnsOptions: [
        {
            code: 'one',
            name: i18n.t('creative_editor.text_one_column'),
            default: true
        },
        {
            code: 'two',
            name: i18n.t('creative_editor.text_two_column')
        }
    ],
    layoutOptions: [
        {
            code: 'with55',
            icon: 'layout-with55',
            default: true
        },
        {
            code: 'with37',
            icon: 'layout-with37'
        },
        {
            code: 'with73',
            icon: 'layout-with73'
        }
    ],
    marginOptions: {
        min: 0,
        max: 100
    },
    spaceingOptions: {
        min: 0,
        max: 300
    },
    content: [
        {
            code: 'text1',
            name: 'Text1', //i18n.t('creative_editor.text_one'),
            value: {
                text: textDefaultContent
            },
            default: true
        }
        // {
        //     code: 'text2',
        //     name: i18n.t('creative_editor.text_two'),
        //     value: {
        //         text: textDefaultContent
        //     }
        // }
    ],
    settings: {
        backgroundColor: '',
        columnNumber: 'one',
        marginTop: 8,
        marginBottom: 8,
        spacing: 8,
        layout: 'with55',
        alignment: 'left',
        noPadding: false
    }
};
