<template>
    <div class="pt-highcharts" :class="{ 'overflow-visible': overflowVisible }"></div>
</template>

<script>
import Highcharts from 'highcharts';
import { highchartsBaseOptions, highchartsColorList } from './highcharts-config';
import cloneUtils from '../../../utils/clone.utils';
import { mergeDeep } from '../../../utils/common';

export default {
    name: 'ptHighcharts',

    props: {
        chartType: {
            type: String,
            default: 'line'
        },

        settings: {
            type: Object,
            default: function() {
                return {};
            }
        },

        series: {
            type: Array
            // validator: function(value) {
            //     return value.every(item => {
            //         const { name, data } = item;
            //         return name && data && Array.isArray(data);
            //     });
            // }
        },

        overflowVisible: {
            type: Boolean,
            default: function() {
                return true;
            }
        }
    },

    data() {
        return {
            options: null,
            chart: null
        };
    },

    created() {
        this.options = this.initOptions();
    },

    mounted() {
        this.draw();
    },

    beforeDestroy() {
        this.destroy();
    },

    methods: {
        initOptions() {
            let options = mergeDeep(cloneUtils.deep(highchartsBaseOptions), this.settings);
            options.chart.type = this.chartType;
            options.series = this.series;
            // console.log('highcharts options', JSON.stringify(options));
            return options;
        },

        draw() {
            // console.log(JSON.stringify(this.options));
            this.chart = Highcharts.chart(this.$el, this.options);
        },

        destroy() {
            this.chart && this.chart.destroy();
        },

        update(options) {
            this.chart && this.chart.update(options);
        },

        redraw() {
            this.chart && this.chart.redraw();
        },

        reflow() {
            this.chart && this.chart.reflow();
        }
    },

    watch: {
        chartType(newValue) {
            this.options.chart.type = newValue;
            this.update(this.options);
        },

        series: {
            handler: function(newVal) {
                this.options = this.initOptions();
                this.update(this.options);
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import.scss';

.pt-highcharts {
    height: 100%;
    width: 100%;

    &.overflow-visible {
        overflow: visible !important;

        .highcharts-container {
            overflow: visible !important;
        }
    }
}

.chart-highchart-tooltip-shared {
    line-height: 17px;
    border-radius: 2px;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
    word-break: break-all;
}
.chart-highchart-tooltip {
    border-radius: 2px;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
    word-break: break-all;
}
.chart-highchart-tooltip-data {
    padding: 2px 4px 2px 10px;
    background-color: $pt-white;
}
.chart-highchart-tooltip-shared-data {
    padding: 16px 16px 16px 25px;
    background-color: $pt-white;
}

.chart-highchart-tooltip-data span {
    color: $pt-black-300;
    //line-height: 17px;
    font-size: 12px;
    font-family: 'Helvetica';
}
.chart-highchart-tooltip-shared-data span {
    color: $pt-black-300;
    //line-height: 17px;
    font-size: 12px;
    font-family: 'Helvetica';
}
.chart-highchart-tooltip-shared-data b {
    color: $pt-black-300;
    line-height: 17px;
    font-size: 12px;
    font-weight: bold;
}
.chart-highchart-tooltip-data b {
    color: $pt-black-300;
    font-size: 16px;
}
.chart-hightchart-tooltip-symbol {
    border: 2px solid;
    background-color: $pt-black-300;
    width: 2px;
    height: 2px;
}
.chart-highchart-tooltip-info {
    padding: 2px 4px 2px 10px;
    color: #fff;
}

.chart-highchart-tooltip-info span {
    font-size: 12px;
    padding-right: 10px;
}
</style>
