import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'otherServiceApi',
        query: `query otherServiceApi($where: ServiceApiInput!){
            otherServiceApi(where:$where)
        }`
    },
    {
        name: 'dataPlatformData',
        query: `query dataPlatformData($where:DataPlatformDataWhereUniqueInput!){
            dataPlatformData(where: $where)
        }`
    }
];

export default resourceWrapper(resources);
