"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRememberScroll = void 0;
var composition_api_1 = require("@vue/composition-api");
var useStore_1 = require("./useStore");
var useRouter_1 = require("../utils/useRouter");
var getScroll_1 = require("@ptengine/pt-ui-x-utils/getScroll");
var scrollTop = 0;
var scrollLeft = 0;
var lastSid = '';
var useRememberScroll = function (scroll) {
    var _a = (0, useStore_1.useState)('campaign', ['scrollbarLeft', 'scrollbarTop']), scrollbarTop = _a.scrollbarTop, scrollbarLeft = _a.scrollbarLeft;
    var sid = (0, useRouter_1.useRoute)().params.sid;
    var leavedCampaign = (0, composition_api_1.ref)('');
    (0, composition_api_1.onActivated)(function () {
        (0, composition_api_1.nextTick)(function () {
            if (lastSid !== '' && lastSid !== sid)
                return;
            if (!scroll || !scrollbarLeft.value || !(scrollbarTop === null || scrollbarTop === void 0 ? void 0 : scrollbarTop.value))
                return;
            scrollbarTop.value.scrollTop = scrollTop;
            scrollbarLeft.value.scrollLeft = scrollLeft;
        });
    });
    (0, composition_api_1.onDeactivated)(function () {
        leavedCampaign.value = '';
    });
    var rememberScroll = function (campaignId) {
        var _a, _b;
        if (campaignId)
            leavedCampaign.value = campaignId;
        lastSid = sid.slice();
        console.log(lastSid, sid);
        scrollTop = (0, getScroll_1.getScrollTop)();
        scrollLeft = (_b = (_a = scrollbarLeft.value) === null || _a === void 0 ? void 0 : _a.scrollLeft) !== null && _b !== void 0 ? _b : 0;
    };
    return { rememberScroll: rememberScroll, leavedCampaign: leavedCampaign };
};
exports.useRememberScroll = useRememberScroll;
