"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDOMElement = void 0;
var isDOMElement = function (obj) {
    try {
        return obj instanceof HTMLElement;
    }
    catch (e) {
        return (typeof obj === "object"
            && obj.nodeType === 1
            && typeof obj.style === "object"
            && typeof obj.ownerDocument === "object");
    }
};
exports.isDOMElement = isDOMElement;
