const state = {
    scenarioList: null,
    scenarioTags: null
};

const getters = {
    scenarioList: state => state.scenarioList,
    scenarioTags: state => state.scenarioTags
};

export default {
    state,
    getters
};
