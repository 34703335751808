import Highcharts from 'highcharts';
import loadMap from 'highcharts/modules/map';
import './mapdata';
import { countryCode } from './country_code';
import arrayUtils from '../../../utils/array.utils';

// map/**.js内需要
global.Highcharts = require('highcharts');
loadMap(Highcharts);

const services = {
    optionsInit(mapCode, series, callBack) {
        function init() {
            getMapPath(mapCode, mapPath => {
                let baseMapJSON = Highcharts.maps[mapPath.replace('.js', '')];
                let mapData = getMapData(series);
                let options = getMapOptions(baseMapJSON, mapData);
                callBack(options);
            });
        }

        function getSeriesData(data) {
            let series = [];
            let metricsKey = data.metricsKey || '';
            let rows = data.rows;
            let region = rows[0][1];

            rows.forEach((row, rowIndex) => {
                let code = null;
                let value = null;
                let showValue = null;

                if (rowIndex !== 0) {
                    row.forEach((cell, cellIndex) => {
                        if (cellIndex === 0) {
                            code = cell;
                        } else {
                            let unit = data.unitMap[metricsKey];
                            let dateType = data.dataTypeMap[metricsKey];
                            let dataFormat = data.dataFormatMap[metricsKey];
                            value = cell;
                            showValue = cell; //chartService.formatDataViewer(cell, unit, dateType, dataFormat); // 格式化数据显示
                        }
                    });

                    series.push({
                        code: code,
                        region: region,
                        value: value,
                        showValue: showValue
                    });
                }
            });

            return series;
        }

        //获取地图设置路径
        function getMapPath(mapCode, callBack) {
            // path与vue.config.js路径一致
            let baseMapPath = `${location.protocol}//${location.host}/map/`;
            let mapPath = 'custom/world.js';

            if (mapCode !== 'world') {
                let country = countryCode.find(country => country.code === mapCode);
                let code = country ? country.highchartCode || country.code : mapCode;
                mapPath = Highcharts.mapDataIndex.Countries[code];
            }

            if (mapPath && Highcharts.maps[mapPath.replace('.js', '')]) {
                callBack(mapPath);
            } else {
                let paths = mapPath.split('/');
                $.getScript(baseMapPath + paths[paths.length - 1], function() {
                    callBack(mapPath);
                });
            }
        }

        //获取地图数据
        function getMapData(seriesData) {
            let mapData = [];
            seriesData.forEach(feature => {
                let views = parseFloat(feature.value);
                let showViews = feature.showValue ? feature.showValue : views;

                if (views == 0) {
                    views = null;
                    showViews = null;
                }
                if (feature && feature.code) {
                    let code = feature.code.toString().replace(' Prefecture', ''); //部分国家code特殊对应
                    let country = countryCode.find(c => code === c.code);
                    mapData.push({
                        //特殊处理
                        code: code,
                        highchartCode: country ? country.highchartCode || code : code, //个别国家的code与highchart官方的code不一致
                        value: views,
                        showValue: showViews,
                        region: feature.region
                    });
                }
            });

            return mapData;
        }

        //获取地图绘制参数
        function getMapOptions(baseMapJSON, mapData) {
            let sourceData = getPushData(mapData);
            let colorAxis = createMapLegendColorAxis(sourceData);
            let maxValue = arrayUtils.max(sourceData);
            let joinBy = ['name', 'code'];
            let options = {
                chart: {
                    events: {
                        load: function() {
                            createMapNavigation(this);
                        }
                    }
                },

                title: {
                    text: null
                },

                mapNavigation: {
                    enabled: false,
                    enableButtons: false,
                    enableDoubleClickZoom: false
                },

                credits: {
                    enabled: false
                },

                tooltip: {
                    backgroundColor: 'transparent',
                    hideDelay: 0,
                    shadow: false,
                    borderWidth: 0,
                    shape: 'square',
                    useHTML: true,
                    shared: true,
                    outside: true,
                    formatter: function() {
                        const { color, region, showValue, name } = this.point || {};
                        return (
                            name &&
                            `
                            <div class="chart-highchart-tooltip-shared">
                                <div class="chart-highchart-tooltip-shared-data clearfix">
                                    <span style="color:${color}">■  </span><span>${name}:</span> <b>${showValue}</b><br/>
                                </div>
                            </div>
                            `
                        );
                    }
                },

                legend: {
                    enabled: false,
                    layout: 'vertical',
                    align: 'left',
                    y: 5,
                    symbolHeight: 150,
                    symbolRadius: 2,
                    symbolWidth: 8,
                    verticalAlign: 'top',
                    floating: true,
                    navigation: {
                        style: {
                            color: '#BDBDBD'
                        }
                    },
                    valueDecimals: 0,
                    itemStyle: {
                        color: '#BDBDBD',
                        textDecoration: 'none'
                    },
                    itemHoverStyle: {
                        textDecoration: 'none'
                    }
                },

                colorAxis: {
                    max: maxValue,
                    tickPosition: 'inside',
                    tickPixelInterval: 40,
                    tickLength: 0,
                    labels: {
                        x: 5,
                        y: 0,
                        step: 1,
                        style: {
                            color: '#BDBDBD'
                        }
                    },
                    dataClasses: colorAxis
                },

                plotOptions: {
                    map: {
                        dataLabels: {
                            color: '#000000',
                            enabled: true,
                            formatter: function() {
                                let s;
                                if (this.point.value && this.point.value > 0) {
                                    s = this.point.properties && this.point.properties['name'];
                                }
                                return s;
                            }
                        }
                    },
                    series: {
                        mapData: baseMapJSON,
                        joinBy: joinBy,
                        name: name,
                        point: {
                            events: {
                                // click: function() {
                                //  let code = this.code;
                                //  //当数据源为GA时，才能点击
                                //  if (Highcharts.mapDataIndex.Countries[code]) {
                                //      mapCode = code;
                                //      console.log(code);
                                //      // scope.$apply(function () {
                                //      //  scope.widget.widgetDrawing = true;
                                //      //  scope.widget.baseWidget.mapCode = code;
                                //      // })
                                //  }
                                // }
                            }
                        },
                        states: {
                            hover: {
                                color: function() {
                                    return this.point.color;
                                },
                                borderColor: 'white',
                                borderWidth: 2,
                                brightness: 2
                            }
                        },
                        nullColor: '#EEEEEE',
                        borderWidth: 0
                    }
                },
                series: [
                    {
                        data: mapData
                    }
                ]
            };
            return options;
        }

        function getPushData(data) {
            let o = [];
            data.forEach(item => o.push(item.value));
            return o;
        }

        function createMapLegendColorAxis(array) {
            array = array.sort((a, b) => a - b);
            let colorArrays = ['#c3eac4', '#9cdc9f', '#70cf77', '#00A92E', '#009721', '#006700'];
            let result = [];
            let k = 5;
            let num = parseInt(array.length / k);

            for (let i = 1; i <= k; i++) {
                let r = array[i * num];
                result.push(i < k ? r : array[array.length - 1]);
            }

            let obj = [];
            result.forEach((item, i) => {
                let color = {
                    from: i > 0 ? result[i - 1] : 0,
                    to: result[i],
                    color: colorArrays[i]
                };
                obj.push(color);
            });
            return obj;
        }

        return init();
    },

    /**
     * 地图右下角按钮
     */
    createMapNavigation(chartDom, mapCode, chart, callBack) {
        //清除zoom样式层
        chartDom.querySelectorAll('.zoomCss').forEach(dom => {
            dom.remove();
        });

        let x = chart.chartWidth - 30;
        let y = chart.chartHeight - 40;
        let offset = 4;
        let r = 9;
        let distance = 27;

        //放大
        let groupZoomIn = chart.renderer
            .g()
            .attr({
                class: 'zoomCss',
                zIndex: 9999
            })
            .on('click', function() {
                chart.mapZoom(0.5);
            })
            .add();
        chart.renderer
            .circle(x, y, r)
            .attr({
                id: 'ZoomIn',
                'stroke-width': 2,
                //stroke: '#ffffff',
                fill: '#BDBDBD'
            })
            .on('mouseover', function() {
                $(this).attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $(this).attr('fill', '#BDBDBD');
            })
            .add(groupZoomIn);
        chart.renderer
            .path(['M', x, y - offset, 'L', x, y + offset, 'M', x - offset, y, 'L', x + offset, y])
            .attr({
                'stroke-width': 2,
                stroke: '#ffffff',
                zIndex: 2
            })
            .on('mouseover', function() {
                $('#' + 'ZoomIn').attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $('#' + 'ZoomIn').attr('fill', '#BDBDBD');
            })
            .add(groupZoomIn);

        //缩小
        y = y + distance;
        let groupZoomOut = chart.renderer
            .g()
            .attr({
                class: 'zoomCss',
                zIndex: 9999
            })
            .on('click', function() {
                chart.mapZoom(2);
            })
            .add();
        chart.renderer
            .circle(x, y, r)
            .attr({
                'stroke-width': 1,
                id: 'ZoomOut',
                //stroke: '#ffffff',
                fill: '#BDBDBD'
            })
            .on('mouseover', function() {
                $(this).attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $(this).attr('fill', '#BDBDBD');
            })
            .add(groupZoomOut);
        chart.renderer
            .path(['M', x - offset, y, 'L', x + offset, y])
            .attr({
                'stroke-width': 2,
                stroke: '#ffffff',
                zIndex: 2
            })
            .on('mouseover', function() {
                $('#' + 'ZoomOut').attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $('#' + 'ZoomOut').attr('fill', '#BDBDBD');
            })
            .add(groupZoomOut);

        //重置
        y = y - 2 * distance;
        let groupZoomReset = chart.renderer
            .g()
            .attr({
                class: 'zoomCss',
                zIndex: 9999
            })
            .on('click', function() {
                chart.mapZoom();
            })
            .add();
        chart.renderer
            .circle(x, y, r)
            .attr({
                id: 'zoomReset',
                'stroke-width': 1,
                //stroke: '#ffffff',
                fill: '#BDBDBD'
            })
            .on('mouseover', function() {
                $(this).attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $(this).attr('fill', '#BDBDBD');
            })
            .add(groupZoomReset);
        let m = 4.0;
        let narrowPath = [
            'M',
            x - 3,
            y - 6,
            'c',
            1.477 / m,
            -0.331 / m,
            3.189 / m,
            -0.507 / m,
            4.955 / m,
            -0.507 / m,
            'c',
            3.859 / m,
            0 / m,
            12.759 / m,
            0.924 / m,
            18.903 / m,
            8.666 / m,
            'l',
            0.021 / m,
            -0.021 / m,
            'l',
            3.984 / m,
            -9.189 / m,
            'c',
            0.135 / m,
            -0.308 / m,
            0.457 / m,
            -0.489 / m,
            0.789 / m,
            -0.442 / m,
            'c',
            0.166 / m,
            0.023 / m,
            0.313 / m,
            0.101 / m,
            0.426 / m,
            0.212 / m,
            'c',
            0.113 / m,
            0.113 / m,
            0.189 / m,
            0.264 / m,
            0.211 / m,
            0.432 / m,
            'l',
            2.992 / m,
            22.889 / m,
            'c',
            0.029 / m,
            0.229 / m,
            -0.047 / m,
            0.461 / m,
            -0.213 / m,
            0.625 / m,
            'c',
            -0.163 / m,
            0.163 / m,
            -0.396 / m,
            0.242 / m,
            -0.625 / m,
            0.213 / m,
            'l',
            -22.889 / m,
            -2.993 / m,
            'c',
            -0.334 / m,
            -0.042 / m,
            -0.596 / m,
            -0.304 / m,
            -0.643 / m,
            -0.636 / m,
            'c',
            -0.047 / m,
            -0.333 / m,
            0.134 / m,
            -0.654 / m,
            0.442 / m,
            -0.79 / m,
            'l',
            9.19 / m,
            -3.984 / m,
            'l',
            0.82 / m,
            -0.82 / m,
            'c',
            -3.938 / m,
            -5.565 / m,
            -9.898 / m,
            -6.459 / m,
            -13.273 / m,
            -6.459 / m,
            'c',
            -2 / m,
            0 / m,
            -3.37 / m,
            0.296 / m,
            -3.428 / m,
            0.308 / m,
            'c',
            -0.187 / m,
            0.041 / m,
            -0.374 / m,
            -0.076 / m,
            -0.414 / m,
            -0.265 / m,
            'l',
            -1.516 / m,
            -6.821 / m,
            'z'
        ];
        let narrow1 = chart.renderer
            .path(narrowPath)
            .attr({
                'stroke-width': 1,
                fill: '#ffffff',
                zIndex: 2
            })
            .on('mouseover', function() {
                $('#' + 'zoomReset').attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $('#' + 'zoomReset').attr('fill', '#BDBDBD');
            })
            .add(groupZoomReset);
        let narrow2 = chart.renderer
            .path(narrowPath)
            .attr({
                'stroke-width': 1,
                fill: '#ffffff',
                zIndex: 3,
                transform: 'rotate(180 ' + x + ' ' + y + ')'
            })
            .on('mouseover', function() {
                $('#' + 'zoomReset').attr('fill', '#757575');
            })
            .on('mouseout', function() {
                $('#' + 'zoomReset').attr('fill', '#BDBDBD');
            })
            .add(groupZoomReset);
        if (mapCode !== 'world') {
            //返回世界
            y = y - distance;
            let groupZoomWorld = chart.renderer
                .g()
                .attr({
                    class: 'zoomCss',
                    zIndex: 9999
                })
                .on('click', function() {
                    // mapCode = "world";
                    callBack('world');
                })
                .add();

            chart.renderer
                .circle(x, y, r)
                .attr({
                    id: 'world',
                    'stroke-width': 1,
                    //stroke: '#ffffff',
                    fill: '#BDBDBD'
                })
                .on('mouseover', function() {
                    $(this).attr('fill', '#757575');
                })
                .on('mouseout', function() {
                    $(this).attr('fill', '#BDBDBD');
                })
                .add(groupZoomWorld);

            let n = 3.8;
            chart.renderer
                .path([
                    'M',
                    x + 4,
                    y + 7,
                    'c',
                    5.68 / n,
                    -10.3 / n,
                    6.64 / n,
                    -26.01 / n,
                    -15.7 / n,
                    -25.49 / n,
                    'v',
                    12.69 / n,
                    'l',
                    -19.2 / n,
                    -19.2 / n,
                    19.2 / n,
                    -19.2 / n,
                    'v',
                    12.4 / n,
                    'c',
                    26.7 / n,
                    -0.6 / n,
                    29.7 / n,
                    23.6 / n,
                    15.6 / n,
                    38.7 / n,
                    'z'
                ])
                .attr({
                    'stroke-width': 1,
                    //stroke: '#ffffff',
                    fill: '#ffffff',
                    zIndex: 2
                })
                .on('mouseover', function() {
                    $('#' + 'world').attr('fill', '#757575');
                })
                .on('mouseout', function() {
                    $('#' + 'world').attr('fill', '#BDBDBD');
                })
                .add(groupZoomWorld);
        }
    }
};
export default services;
