/**
 * string supplant
 * example：
 *  string: 'http://ptengine.com/{id}'
 *  obj: {id: 'x'}
 * @param {String} string
 * @param {Object} obj
 * @returns {String}
 */
export function supplant(string, obj) {
    return string.replace(/{([^{}]*)}/g, function(a, b) {
        var r = obj[b];
        return typeof r === 'string' ||
            typeof r === 'number' ||
            typeof r === 'boolean'
            ? r
            : a;
    });
}

export function encodeUnicode(str) {
    var res = [];
    for (var i = 0; i < str.length; i++) {
        res[i] = ('00' + str.charCodeAt(i).toString(16)).slice(-4);
    }
    return '\\u' + res.join('\\u');
}

export function decodeUnicode(str) {
    str = str.replace(/\\/g, '%');
    return unescape(str);
}
