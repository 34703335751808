import store from '@/store';
export default {
    verifyPrivilege(options) {
        const { privilege, usage } = store.getters['main/packageInfo'];
        const { privilegeKey, editType } = options;
        const fieldType = privilege[privilegeKey] !== undefined ? typeof privilege[privilegeKey] : null;
        console.log('fieldType --->', fieldType);
        const editTypeList = ['CREATE', 'EDIT']; //到量的时候可以编辑但是不能创建，所以edit的时候判断小于privilege + 1
        if (fieldType === null) return true;
        if (fieldType === 'number') {
            return (
                Number(privilege[privilegeKey]) === -1 ||
                Number(usage[privilegeKey]) <
                    Number(privilege[privilegeKey] + editTypeList.findIndex(item => item === editType))
            );
        } else if (fieldType === 'boolean') {
            return privilege[privilegeKey];
        }
        return false;
    }
};
