import cn from '@/assets/i18n/locales/i18n-zh.json';
import en from '@/assets/i18n/locales/i18n-en.json';
import ja from '@/assets/i18n/locales/i18n-ja.json';

export default [
    {
        key: 'EN',
        file: en,
        name: 'English',
        dayjsKey: 'en',
        fallbackLocale: true,
        link: 'https://x.ptengine.com'
    },
    {
        key: 'JP',
        file: ja,
        isDefault: true,
        name: '日本語',
        dayjsKey: 'ja',
        link: 'https://x.ptengine.jp'
    },
    {
        key: 'ZH',
        file: cn,
        name: '中文',
        dayjsKey: 'zh-cn',
        link: 'https://x.ptengine.com'
    }
];
