import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'formDetail',
        query: `query formDetail($where: EngageFormWhereUniqueInput!){
            formDetail(where: $where)
        }`
    },
    {
        name: 'engagements',
        query: `query engagements($where: EngageDataWhereUniqueInput!){
            engagements(where:$where){
                id
                name
                engageVersion
                baseURL
                createTime
                conditions
                updateTime
                hasForm
                abtestType
                audiences: usergroups{
                    id
                }
                status
                schedule
                screenshot
                goals {
                    id
                    content
                    name
                }
                versions{
                    id
                    name
                    rate
                    isSync
                    status
                    displayConfig
                    controlGroup
                    screenshot
                    terminals{
                        id
                        type
                        popup
                        stickybar
                        inline
                        code
                        codeMode
                        codeSync
                        status
                        widgets
                        widgetLayout
                    }
                }
                reportData
                versionsData
                hasVariant
            }
        }`
    },
    {
        name: 'addEngagement',
        query: `mutation addEngagement($data: EngageCreateInput!){
            addEngagement(data:$data) {
                id
                sid
                name
                engageVersion
                baseURL
                status
                abtestType
                createTime
                updateTime
                hasForm
                createBy
                updateBy
                sourceType
                screenshot
                sourceID
                isTemplate
                isVersionEvent
                usergroups {
                    id
                    sid
                    name
                    source
                    content
                    description
                    createTime
                    updateTime
                    status
                }
                versions {
                    id
                    name
                    status
                    rate
                    isSync
               
                    controlGroup
                    displayConfig
                    createTime
                    updateTime
                    engageID
                    screenshot
                    terminals{
                        id
                        type
                        status
                        popup
                        code
                        stickybar
                        inline
                        codeMode
                        codeSync
                        widgets
                        engageVersionID
                        widgetLayout
                    }
                }
                reportData
                versionsData
                goals {
                    id
                    name
                    status
                    content
                    createTime
                    updateTime
                }
                hasVariant
                count
            }
        }`
    },

    {
        name: 'copyEngagement',
        query: `mutation copyEngagement($data: EngageCopyInput!){
            copyEngagement(data:$data) {
                id
                sid
                name
                engageVersion
                baseURL
                status
                abtestType
                createTime
                updateTime
                hasForm
                createBy
                updateBy
                sourceType
                screenshot
                sourceID
                isTemplate
                isVersionEvent
                usergroups {
                    id
                    sid
                    name
                    source
                    content
                    description
                    createTime
                    updateTime
                    status
                }
                versions {
                    id
                    name
                    status
                    rate
                    isSync
               
                    controlGroup
                    displayConfig
                    createTime
                    updateTime
                    engageID
                    screenshot
                    terminals{
                        id
                        type
                        status
                        popup
                        code
                        stickybar
                        inline
                        codeMode
                        codeSync
                        widgets
                        engageVersionID
                        widgetLayout
                    }
                }
                reportData
                versionsData
                goals {
                    id
                    name
                    status
                    content
                    createTime
                    updateTime
                }
                hasVariant
                count
            }
        }`
    },

    {
        name: 'updateEngage',
        query: `mutation updateEngage($data:EngageUpdateInput!,$where:EngageWhereUniqueInput!){
            updateEngage(data:$data, where:$where) {
                id
                sid
                name
                engageVersion
                baseURL
                status
                abtestType
                createTime
                updateTime
                hasForm
                createBy
                updateBy
                sourceType
                screenshot
                sourceID
                isTemplate
                isVersionEvent
                usergroups {
                    id
                    sid
                    name
                    source
                    content
                    description
                    createTime
                    updateTime
                    status
                }
                versions {
                    id
                    name
                    status
                    rate
                    isSync
                
                    controlGroup
                    displayConfig
                    createTime
                    updateTime
                    engageID
                    screenshot
                    terminals{
                        id
                        type
                        status
                        popup
                        code
                        stickybar
                        inline
                        codeMode
                        codeSync
                        widgets
                        widgetLayout
                        engageVersionID
                    }
                }
                reportData
                versionsData
                goals {
                    id
                    name
                    status
                    content
                    createTime
                    updateTime
                }
                hasVariant
            }
        }`
    },
    {
        name: 'updateEngagement',
        query: `mutation updateEngagement($data:EngageCreateInput!,$where:EngageWhereUniqueInput!){
            updateEngagement(data:$data, where:$where) {
                id
                sid
                name
                engageVersion
                baseURL
                status
                abtestType
                createTime
                updateTime
                hasForm
                createBy
                updateBy
                sourceType
                sourceID
                screenshot
                isTemplate
                isVersionEvent
                usergroups {
                    id
                    sid
                    name
                    source
                    content
                    description
                    createTime
                    updateTime
                    status
                }
                versions {
                    id
                    name
                    status
                    rate
                    isSync
                    controlGroup
                    displayConfig
                    createTime
                    updateTime
                    engageID
                    screenshot
                    terminals{
                        id
                        type
                        status
                        popup
                        code
                        stickybar
                        inline
                        codeMode
                        codeSync
                        widgets
                        widgetLayout
                        engageVersionID
                    }
                }
                reportData
                versionsData
                goals {
                    id
                    name
                    status
                    content
                    createTime
                    updateTime
                }
                hasVariant
            }
        }`
    },
    {
        name: 'removeEngagement',
        query: `mutation removeEngagement($updateBy: String!, $where: EngageWhereUniqueInput!) {
            removeEngagement(updateBy: $updateBy, where: $where) {
                id
                sid
                name
                engageVersion
                baseURL
                status
                abtestType
                createTime
                updateTime
                hasForm
                createBy
                updateBy
                sourceType
                sourceID
                isTemplate
                usergroups {
                    id
                    sid
                    name
                    source
                    content
                    description
                    createTime
                    updateTime
                    status
                }
                versions {
                    id
                    name
                    status
                    rate
                    isSync
                    controlGroup
                    displayConfig
                    createTime
                    updateTime
                    engageID
                    terminals{
                        id
                        type
                        status
                        popup
                        stickybar
                        inline
                        code
                        codeMode
                        codeSync
                        widgets
                        widgetLayout
                        engageVersionID
                    }
                }
                reportData
                versionsData
                goals {
                    id
                    name
                    status
                    content
                    createTime
                    updateTime
                }
                count
            }
        }`
    },
    {
        name: 'getEngagement',
        query: `query getEngagement($where:EngageWhereUniqueInput!){
            engagement(where:$where){
                id
                name
                engageVersion
                baseURL                              
                hasForm
                abtestType
                status
                screenshot
                hasVariant
                schedule
                conditions
                isVersionEvent
                firstRunTime
                lastPauseTime
                audiences: usergroups{
                    usergroupID: id,
                    name,
                    source
                }
                goals {
                    id
                    name
                    content
                }
                versions {
                    id
                    name
                    rate
                    isSync
                    status
                    displayConfig
                    controlGroup
                    screenshot
                    terminals{
                        id
                        type
                        popup
                        stickybar
                        inline
                        codeMode
                        codeSync
                        code                       
                        status
                        widgets
                        widgetLayout
                    }
                } 
            }
        }`
    },
    {
        name: 'engageCurveData',
        query: `query engageCurveData($where: EngageDataWhereUniqueInput!){
            engageCurveData(where: $where)
        }`
    },
    {
        name: 'engageMultipleData',
        query: `query engageMultipleData($where: EngageDataWhereUniqueInput!){
            engageMultipleData(where: $where)
        }`
    },
    {
        name: 'isEngageNameRepeat',
        query: `query isEngageNameRepeat($where: EngageWhereUniqueInput!){
            isEngageNameRepeat(where: $where)
        }`
    },
    {
        name: 'imageUpload',
        methoe: 'post',
        isGraphql: false,
        url: '/imageUpload'
    },
    {
        name: 'imageUploadByUrl',
        methoe: 'post',
        isGraphql: false,
        url: '/api/upload/image/url '
    },
    {
        name: 'graphql',
        method: 'post',
        url: '/ptmindservice/engage-service/graphql'
    },
    {
        name: 'previewKey',
        query: `mutation previewKey($data: PreviewEngagement!){
            previewKey(data: $data)
        }`
    },
    {
        name: 'removePreviewKey',
        query: `mutation removePreviewKey($where: PreviewKeyWhereUniqueInput!){
            removePreviewKey(where: $where)
        }`
    },
    {
        name: 'countEngage',
        query: `query countEngage($where: EngageWhereUniqueInput!){
        countEngage(where: $where){
            All: total
            Draft: draft
            Paused: inactived
            Live: actived
            }
        }`
    },
    {
        name: 'engageIds',
        query: `query engageIds($where:EngageDataWhereUniqueInput!){
            engageIds(where:$where)
        } `
    },
    {
        name: 'engagementData',
        query: `query engagementData($where:EngageDataWhereUniqueInput!){
            engagementData(where:$where){
                id
                name
                engageVersion
                baseURL                              
                hasForm
                abtestType
                status 
                hasVariant
                audiences: usergroups{
                    usergroupID: id,
                    name
                }
                goals {
                    id
                    name
                    content
                }
                versions {
                    id
                    name
                    rate
                    isSync
                    status
                  
                    displayConfig
                    controlGroup
                    terminals{
                        id
                        type
                        popup
                        stickybar
                        inline
                        code
                        codeMode
                        codeSync                       
                        status
                        widgets
                        
                    }
                }
                reportData
                versionsData
            }
        }`
    },
    {
        name: 'engageDetailReport',
        query: `query engageDetailReport($where:EngageDataWhereUniqueInput!){
            engageDetailReport(where: $where){
                reportData
                versionsData
            }
        }`
    },
    {
        name: 'getInsightDetailReport',
        query: `query getInsightDetailReport($where:EngageDataWhereUniqueInput!){
            getInsightDetailReport(where: $where)
        }`
    }
];

export default resourceWrapper(resources);
