export default {
    name: 'refresh',

    inserted: () => {
        window.onbeforeunload = function(el) {
            (el || window.event).returnValue = '确定离开此页吗？';
        };
    },

    update: () => {
        window.onbeforeunload = function(el) {
            (el || window.event).returnValue = '确定离开此页吗？';
        };
    }
};
