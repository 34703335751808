import uuidUtils from '@common/utils/uuid.utils';
import cloneUtils from '@common/utils/clone.utils';
import widgetConfig from '../blocks/widget/widget.config';
import blocksConfig from '../configs/blocks.config';
import { deviceConfig } from '../configs/creative.config';
import widgetServices from '../blocks/widget/widget.services';

export const getDefaultBlock = function(block) {
    const newBlock = cloneUtils.deep(block);
    const { name, code, config, isNotAllowMutiple } = newBlock;
    const { content, hasSubModules, settings, editorState, dataSettings } = config;
    if (hasSubModules && Array.isArray(content)) {
        content.forEach(item => {
            if (Object.prototype.hasOwnProperty.call(item.value, 'id')) {
                item.value.id = `pt_${uuidUtils.uuid_8bit()}`;
            }
        });
    }
    return {
        blockId: `pt_${uuidUtils.uuid_8bit()}`,
        blockName: name,
        blockCode: code,
        blockContent: content,
        blockSettings: settings,
        blockData: dataSettings || null,
        blockHasError: false,
        blockHide: isNotAllowMutiple,
        blockEditorState: editorState //仅在编辑器内使用
    };
};

export const getDefaultCodeModel = function(widgetId) {
    const device = deviceConfig.find(item => item.code === 'pc');
    const blocks = ['text', 'spacing', 'button'];
    const widgetBlocks = blocks.reduce((acc, cur) => {
        const config = blocksConfig.find(item => item.code === cur);
        if (config) {
            const newBlock = getDefaultBlock(config);
            acc.push(newBlock);
        }
        return acc;
    }, []);
    const widget = {
        device,
        widgetId,
        model: 'visualModel',
        codeModel: {
            html: '',
            css: ''
        },
        visualModel: {
            widgetSettings: widgetConfig.settings,
            widgetBlocks
        }
    };
    const html = widgetServices.getHtml({ widget, tagetModel: 'codeModel' });
    const css = widgetServices.getStyle({ widget });
    const script = widgetServices.getScript({ widget, belong: 'normal' });
    return {
        html,
        css,
        script
    };
};

/**
 * Get block rander dom
 * @param {Object} block
 * block: {
 *  blockCode: {String},
 *  blockContent: {String},
 *  blockId: {String},
 *  blockName: {String},
 *  blockSettings {Object}
 * }
 * @returns {String}
 */
export const getBlockDomAndStyle = function(block, widgetSettings, widgetId, belong, engageId, versionId) {
    const blockConfig = blocksConfig.find(item => item.code === block.blockCode);
    if (blockConfig) {
        const services = blockConfig.services;
        const html = services && services.getHtml && services.getHtml(block, widgetSettings, belong);
        const style = services && services.getStyle && services.getStyle(block, widgetSettings);
        const script =
            services && services.getScript && services.getScript(block, widgetId, belong, engageId, versionId);
        return {
            html,
            style,
            script
        };
    }
};

/**
 * get embed youtube url
 * @param {String} url
 * @param {Boolean} autoplay
 * @returns {String}
 */
export const getYouTubeEmbedUrl = function(url, autoplay) {
    if (url !== undefined || url !== '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}?autoplay=${autoplay ? 1 : 0}`;
        }
        return null;
    }
};

/**
 * validator Content
 * @param {Object} block
 * @param {Number} widgetIndex
 * @returns {Boolean} true: has error
 */
export const validatorContent = function(block, widgetIndex) {
    const currentBlockConfig = blocksConfig.find(item => item.code === block.blockCode).config;
    let components = [block];
    let invalid = false;

    if (currentBlockConfig.hasSubModules) {
        components = block.blockContent.map(item => {
            const { code, value } = item;
            const { content } = value;
            return {
                blockCode: code,
                blockContent: content
            };
        });
    }

    for (let i = 0, max = components.length; i < max; i++) {
        const component = components[i];
        const blockConfig = blocksConfig.find(item => item.code === component.blockCode).config;
        if (invalid) break;
        if (!blockConfig.blockContentValidatorFun) continue;

        const blockContent = component.blockContent || [];
        for (let j = 0, max = blockContent.length; j < max; j++) {
            if (!invalid) {
                const block = blockContent[j];
                const checkResult = blockConfig.blockContentValidatorFun(block.value, widgetIndex);
                checkResult && (invalid = checkResult.invalid);
            }
        }
    }
    console.log('validatorContent', invalid, block);
    return invalid;
};

export const autoSettingContent = function(block, widgetIndex) {
    const currentBlockConfig = blocksConfig.find(item => item.code === block.blockCode).config;
    let components = [block];

    if (currentBlockConfig.hasSubModules) {
        components = block.blockContent.map(item => {
            const { code, value } = item;
            const { content } = value;
            return {
                blockCode: code,
                blockContent: content
            };
        });
    }

    for (let i = 0, max = components.length; i < max; i++) {
        const component = components[i];
        const blockConfig = blocksConfig.find(item => item.code === component.blockCode).config;
        if (!blockConfig.blockContentAutoSettingFun) continue;

        const blockContent = component.blockContent || [];
        for (let j = 0, max = blockContent.length; j < max; j++) {
            const block = blockContent[j];
            block.value = blockConfig.blockContentAutoSettingFun(block.value, widgetIndex);
        }
    }
};

export const getImgSize = function(src, callback) {
    const image = new Image();
    image.src = src;
    image.onload = function() {
        callback(image.width, image.height);
    };
};

/* export const computedImageRate = function(src, width, height) {
    const image = new Image();
    image.src = src;
    image.onload = function() {
        const originW = image.width;
        const originH = image.height;
        const rate = originW / originH;
        !width && (width = originW); // 如果没有预设的宽度则按图片真实宽度设置
        height = parseInt(width / rate);
    };
}; */
