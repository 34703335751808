<template>
    <div :class="$style.report">
        <!-- header -->
        <page-header ref="pageHeader" size="full" :hasBorder="true">
            <h1 slot="title">
                <span>{{ $t('users.user_report_title') }}</span>
                <el-tooltip :content="$t('annotation.user_group_1')">
                    <pt-icon
                        icon="pt-icon--help-new"
                        :icon-style="{
                            width: '16px',
                            height: '16px',
                            margin: '0 0 10px 8px',
                            fill: '#5E6C84'
                        }"
                    ></pt-icon>
                </el-tooltip>
            </h1>
            <div ref="headerTools" :class="$style['header-tools']" slot="rightTools">
                <pt-icon
                    v-role="{
                        type: 'createUsergroup',
                        role: profileInfo.currentUserRole
                    }"
                    @click="toggleDialogVisible('steps', !dialogVisible.steps)"
                    icon="icon-recommend"
                    :icon-style="{
                        width: '24px',
                        height: '24px'
                    }"
                ></pt-icon>
                <verify-package
                    component="icon"
                    v-role="{
                        type: 'createUsergroup',
                        role: profileInfo.currentUserRole
                    }"
                    privilege-key="CREATE_USER_GROUP"
                    :resolveCallback="createUsergroup"
                    :rejectCallback="showAuthDialog"
                >
                    <pt-button icon="ptx-icon-add" size="medium" color="blue">{{ $t('users.new_users') }}</pt-button>
                </verify-package>
            </div>
        </page-header>
        <!-- 新手引导 -->
        <div class="x-wrap x-wrap--full" :class="$style.steps" v-show="dialogVisible.steps">
            <div :class="$style['steps-arrow']" :style="stepsArrowStyle"></div>
            <div :class="$style['steps-wrap']">
                <p>{{ $t('users.description') }}</p>
                <pt-icon
                    :class="$style['steps-close']"
                    @click="toggleDialogVisible('steps', false)"
                    icon="pt-icon--close"
                    :icon-style="{
                        width: '16px',
                        height: '16px'
                    }"
                ></pt-icon>
                <div :class="$style['steps-container']">
                    <div v-for="step in steps" :key="step.code">
                        <verify-package
                            :privilege-key="
                                step.status === 1 && !step.default ? 'EDIT_USER_GROUP' : 'CREATE_USER_GROUP'
                            "
                            component="link"
                            :resolveCallback="() => createUsergroup(step, true)"
                            :rejectCallback="showAuthDialog"
                        >
                            <div :class="[$style.box, $style[`box-${step.code}`]]">
                                <div :class="$style['box-title']">
                                    <h3>{{ $t(step.name) }}</h3>
                                    <el-tooltip
                                        placement="bottom"
                                        :content="$t(step.info)"
                                        v-if="step.code !== 'custom'"
                                    >
                                        <pt-icon
                                            icon="pt-icon--info"
                                            :icon-style="{
                                                width: '16px',
                                                height: '16px',
                                                fill: '#5E6C84'
                                            }"
                                        ></pt-icon>
                                    </el-tooltip>
                                </div>
                                <div :class="$style['box-description']" v-html="$t(step.description)">
                                    <!-- <p>詳細を確認して</p> -->
                                    <!-- <h2>定義する</h2> -->
                                </div>
                                <div :class="$style['box-status']" v-if="!loading">
                                    <template v-if="step.status === 1">
                                        <pt-icon
                                            icon="icon-tick-mark"
                                            :icon-style="{
                                                width: '20px',
                                                height: '20px',
                                                fill: '#091E42'
                                            }"
                                        ></pt-icon>
                                        <span>{{ $t('users.used') }}</span>
                                    </template>
                                    <div v-else :class="$style['box-status-icon']">
                                        <pt-icon
                                            icon="icon-arrow-right"
                                            :icon-style="{
                                                width: '18px',
                                                height: '18px',
                                                fill: '#091E42'
                                            }"
                                        ></pt-icon>
                                    </div>
                                </div>
                            </div>
                        </verify-package>
                    </div>
                </div>
            </div>
        </div>

        <!-- all People -->
        <users-report-all-people-card
            v-if="allPeopleData"
            :data="allPeopleData"
            :profileInfo="profileInfo"
            @updateData="handleUpdateAllPeopleData"
        ></users-report-all-people-card>

        <!-- usergroup card list -->
        <div class="x-wrap x-wrap--full">
            <div :class="$style.toolbar" v-if="cellListOrigin && cellListOrigin.length > 0">
                <div :class="$style['toolbar-search']">
                    <pt-input
                        v-model="searchStates.key"
                        prefixIcon="pt-icon--search-v2"
                        ref="searchInput"
                        size="large"
                        type="text"
                        inputStyle="underline"
                        :prefix="true"
                        :clearButton="clearSearch"
                        @focus="searchInputFocus"
                        :placeholder="searchStates.focus ? $t('users.search_placeholder') : ''"
                        @blur="searchInputBlur"
                    ></pt-input>
                </div>
                <div :class="$style['toolbar-sort']">
                    <el-dropdown trigger="click" @command="handleSortCommand">
                        <div class="el-dropdown-link" :class="$style['toolbar-sort__trigger']">
                            {{ $t('users.sort_by') }} {{ currentSort.name }}
                            <pt-icon
                                icon="pt-icon--hollow-arrow-down"
                                :icon-style="{
                                    width: '12px',
                                    height: '12px',
                                    fill: '#344563'
                                }"
                            ></pt-icon>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <template v-for="item in sortOptions">
                                <el-dropdown-item :key="item.code" :divided="item.divided" :command="item.code">{{
                                    item.name
                                }}</el-dropdown-item>
                            </template>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <div :class="$style.container" v-loading="loading" element-loading-background="transparent">
                <template v-if="!loading && cellListOrigin.length > 0">
                    <div
                        :class="$style.cell"
                        v-loading="!cell.isRendered"
                        v-for="cell in cellList"
                        :data-user-id="cell.id"
                        :key="cell.id"
                    >
                        <users-report-card
                            :info="cell"
                            :profileInfo="profileInfo"
                            @showAuthDialog="showAuthDialog"
                            @choose="val => (currentGroup = val)"
                            @getData="handleGetData"
                            @delete="dialogVisible.deleteGroup = true"
                        ></users-report-card>
                    </div>
                    <div :class="$style.nodata" v-if="cellList && cellList.length === 0">{{ $t('users.no_data') }}</div>
                </template>
            </div>
        </div>

        <el-dialog :title="deleteUsersTitle" :visible.sync="dialogVisible.deleteGroup" width="440px" center>
            <span>{{ deleteUsersTips }}</span>
            <span slot="footer" class="dialog-footer">
                <pt-button
                    type="outline"
                    color="black"
                    size="medium"
                    @click="toggleDialogVisible('deleteGroup', false)"
                >
                    {{ $t('common.button_cancel') }}
                </pt-button>
                <pt-button
                    v-if="currentGroup && !currentGroup.engagements"
                    size="medium"
                    color="red"
                    @click="handleCofirmDelete"
                    :style="{ marginLeft: '12px' }"
                >
                    {{ $t('common.button_delete') }}
                </pt-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import usersConfig from '../usergrp-config.js';
import usersApis from '@/apis/users.apis';
import commonUtils from '@/common/utils/common.utils';
import numberUtils from '@/common/utils/number.utils';
import cloneUtils from '@/common/utils/clone.utils';
import domUtils from '@/common/utils/dom.utils';
import PageHeader from '@/common/packages/page-header/PageHeader';
import UsersReportAllPeopleCard from '@/components/users/report/UsersReportAllPeopleCard';
import UsersReportCard from '@/components/users/report/UsersReportCard';
import VerifyPackage from '@/common/packages/verify-package/VerifyPackage';
import analyticsService from '@/common/services/analytics.service';
import { cloneDeep } from 'lodash-es';
import baseConfig from '@/common/configs/base.config';

export default {
    name: 'UsersReport',

    data() {
        const sortOptions = [
            {
                code: 'updateTime',
                name: this.$t('common.last_update_time'),
                dataType: 'number',
                default: true
            },
            {
                code: 'name',
                name: this.$t('users.users_report_name'),
                dataType: 'string'
            },
            //  因列表采用数据懒加载模式，故去掉通过用户数和活跃用户数排序的方式 @202303
            // {
            //     code: 'users',
            //     name: this.$t('users.metrics_users'),
            //     dataType: 'number',
            //     default: true
            // },
            // {
            //     code: 'actives',
            //     name: this.$t('users.metrics_active_users'),
            //     dataType: 'number'
            // },
            {
                code: 'engagements',
                name: this.$t('users.metrics_running'),
                dataType: 'number'
            }
        ];
        const dialogVisible = {
            deleteGroup: false,
            steps: false
        };
        const defaultSort = sortOptions.find(item => item.default);
        return {
            loading: true,
            listLoading: false,
            cellListOrigin: null, //原始数据
            headerStyle: {},
            sortOptions,
            steps: usersConfig.guideInfo,
            currentGroup: null, //当前操作组
            currentSort: defaultSort,
            currentSortDirection: 'desc',
            canSort: true,
            dialogVisible,
            allPeopleData: null,
            cellList: [],
            getUsersDataList: [],
            searchStates: {
                key: null,
                focus: false
            }
        };
    },

    computed: {
        ...mapState('main', ['profileGuideInfo', 'asideIsFolded']),
        ...mapGetters('main', ['profileInfo']),

        noDataTips() {
            return Boolean(this.searchStates.key) ? 'No search data.' : 'No data';
        },
        deleteUsersTitle() {
            return this.currentGroup && this.currentGroup.engagements
                ? this.$t('users.delete_users_title')
                : this.$t('common.delete_confirm');
        },

        deleteUsersTips() {
            return this.currentGroup && this.currentGroup.engagements
                ? this.$t('users.delete_users_tips', {
                      num: this.currentGroup.engagements,
                      user: this.currentGroup.name
                  })
                : this.$t('users.delete_users_tips_no_engagements');
        },

        stepsArrowStyle() {
            return {
                right: this.$i18n.locale === 'EN' ? '158px' : '234px'
            };
        }
    },

    created() {
        window.addEventListener('resize', this.handleResize, false);
        this.$eventBus.$on('mainScroll', this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false);
        this.$eventBus.$off('mainScroll', this.handleScroll);
    },

    async mounted() {
        const [err, data] = await commonUtils.awaitWrap(this.fetchUserGroups());
        if (err) {
            this.$route.name === 'UsersReport' && this.$message.error('get user groups failed.');
            return;
        }
        this.getProfileGuideInfo().then(
            guideInfo => {
                const { guideList } = guideInfo || {};
                this.steps = usersConfig.guideInfo.map(item => {
                    item.status = (guideList || {})[item.guideType];
                    return item;
                });
                this.allPeopleData = data.find(item => item.source === 'default');
                this.cellListOrigin = data.reduce((acc, cur) => {
                    if (cur.source !== 'default') {
                        let { name, source } = cur || {};
                        cur.nameFirstHalf = name.length > 5 ? name.substr(0, name.length - 4) : name;
                        cur.nameSecondHalf = name.length > 5 ? name.substr(name.length - 4) : '';
                        cur.canDelete = source !== 'default';
                        cur.isRendered = false;
                        acc.push(cur);
                    }
                    return acc;
                }, []);
                this.cellList = cloneUtils.deep(this.cellListOrigin);
                const { code, dataType } = this.currentSort;
                this.sort(code, dataType, this.currentSortDirection);
                this.loading = false;
            },
            err => {
                this.loading = false;
            }
        );
    },

    methods: {
        ...mapActions('main', ['getProfileGuideInfo']),
        ...mapMutations('main', ['UPDATE_COUNTS', 'SET_AUTH_DIALOG_VISIBLE', 'UPDATE_PACKAGE_USAGE']),
        ...mapMutations('engage', ['UPDATE_CREATE_PORTAL']),
        fetchUserGroups() {
            const { sid, timeZone } = this.profileInfo;
            return usersApis.getUserGroupConfigs(null, {
                input: {
                    sid,
                    timeZone
                }
            });
        },

        handleGetData(userGroup) {
            if (!userGroup) return;
            const { id, content } = userGroup;
            this.getUsersDataList.push({
                id,
                children: content.children,
                operator: content.operator
            });
            const debounceFunc = debounce(500, () => {
                this.batchGetData();
            });
            debounceFunc();
        },
        // 批量获取goal卡片的数据
        batchGetData() {
            if (!this.getUsersDataList?.length) return;
            const readySendData = cloneDeep(this.getUsersDataList);
            while (readySendData?.length > 0) {
                this.fetchUsergroupData(readySendData?.splice(0, baseConfig.MAX_SEND_LENGTH));
            }
            this.getUsersDataList = [];
        },

        fetchUsergroupData(list) {
            if (!list.length) return;
            const { sid, timeZone } = this.profileInfo;
            usersApis
                .getUserGroupData(null, {
                    input: {
                        sid,
                        timeZone,
                        contentList: list
                    }
                })
                .then(res => {
                    if (!res) return;
                    this.handleUpdateData(res);
                });
        },

        /**
         * 单个请求数据回来 更新列表数据
         */
        handleUpdateData(list) {
            if (!list.length) return;
            this.cellListOrigin = this.cellListOrigin.map(item => {
                const dataInfo = list.find(dataItem => {
                    return dataItem.id === item.id;
                });
                item = {
                    ...item,
                    ...dataInfo
                };
                return item;
            });
            this.cellList = this.cellList.map(item => {
                const dataInfo = list.find(dataItem => {
                    return dataItem.id === item.id;
                });
                item = {
                    ...item,
                    ...dataInfo
                };
                return item;
            });
        },
        handleUpdateAllPeopleData(dataInfo) {
            if (!dataInfo) return;

            this.allPeopleData = {
                ...this.allPeopleData,
                ...dataInfo
            };
        },

        getGuideStatus(key) {
            return (
                this.profileGuideInfo && this.profileGuideInfo.guideList && this.profileGuideInfo.guideList[key] === 1
            );
        },

        renderCell() {
            this.cellList &&
                this.$nextTick(() => {
                    this.cellList.forEach(cell => {
                        const $node = document.querySelector(`[data-user-id="${cell.id}"]`);
                        if (!cell.isRendered && domUtils.domIsIn($node)) {
                            cell.isRendered = true;
                        }
                    });
                });
        },

        handleResize() {
            this.renderCell();
        },

        handleScroll() {
            this.renderCell();
        },

        handleSortCommand(code) {
            const item = this.sortOptions.find(item => item.code === code);
            const direction =
                this.currentSort.code === code ? this.opposites(this.currentSortDirection) : this.currentSortDirection;
            if (item) {
                this.currentSort = item;
                this.currentSortDirection = direction;
                this.sort(code, item.dataType, direction);
            }
        },

        async handleCofirmDelete() {
            const [err, data] = await commonUtils.awaitWrap(this.usersDelete());
            if (err) {
                this.$message.error('delete user group failed.');
            } else {
                const index = this.cellList.findIndex(item => item.id === this.currentGroup.id);
                if (index !== -1) {
                    this.cellList.splice(index, 1);
                    (data?.count || 0) <= 0 && this.$router.push({ name: 'UsersGroupEmpty' });
                }
                typeof data?.count === 'number' && this.UPDATE_PACKAGE_USAGE({ type: 'userGroups', count: data.count });
                analyticsService.usePTX('ug_delete').track();
            }
            this.toggleDialogVisible('deleteGroup', false);
        },

        usersDelete() {
            return usersApis.deleteUserGroup(null, {
                where: {
                    id: this.currentGroup.id
                }
            });
        },

        opposites(direction) {
            var mapper = { desc: 'asc', asc: 'desc' };
            return direction ? mapper[direction] : 'desc';
        },

        gotoUsersSetting(usersId) {
            this.$router.push({
                name: 'UsersSetting',
                params: {
                    usersId
                }
            });
        },

        toggleDialogVisible(type, visible) {
            this.$set(this.dialogVisible, type, visible);
            type === 'steps' && this.renderCell();
        },

        sort(fileldKey, dataType, direction) {
            const comparators = {
                number: function(a, b, direction) {
                    if (a === b) return 0;
                    return direction > 0 ? a - b : b - a;
                },

                string: function(a, b, direction) {
                    if (a === b) return 0;
                    // null特殊对应，以免和空值一起排序时发生列表交替显示的情况
                    var comparison = direction > 0 ? b === null || a > b : a === null || a < b;
                    return comparison === false ? -1 : comparison - 0;
                }
            };
            const directionMapper = { asc: 1, desc: -1 };
            this.cellList.sort((a, b) => {
                let comparator = comparators[dataType];
                return comparator.call(null, a[fileldKey], b[fileldKey], directionMapper[direction]);
            });
            this.renderCell();
        },

        searchInputFocus() {
            this.searchStates.focus = true;
        },

        searchInputBlur() {
            if (!this.searchStates.key || this.searchStates.key === '') {
                this.searchStates.focus = false;
            }
        },

        clearSearch() {
            this.searchStates.key = '';
            this.searchInputBlur();
        },

        formatNumber(num) {
            return (num !== undefined && numberUtils.formatNumber(num)) || 0;
        },

        createUsergroup(step, hasQuery = false) {
            let queryObj = (hasQuery && { users: step?.guideType && step?.code }) || {};
            analyticsService.usePTX('ug_add_click', { position: 'ug_list' }).track();
            this.$router.push({ name: 'UsersCreate', query: queryObj });
        },
        showAuthDialog(type = 'CREATE_USER_GROUP') {
            this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: type });
        },
        updateTableHeaderPosition(scrollTop) {
            const pageHeaderDom = this.$refs.pageHeader;
            if (pageHeaderDom) {
                const tableDomTop = pageHeaderDom.$el.getBoundingClientRect().top;
                this.headerStyle =
                    (tableDomTop <= 0 && {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: scrollTop - pageHeaderDom.offsetTop + 'px'
                    }) ||
                    {};
            }
        }
    },

    watch: {
        asideIsFolded() {
            this.renderCell();
        },

        'searchStates.key'(val) {
            const list = cloneUtils.deep(this.cellListOrigin || []);
            if (!val) {
                this.cellList = list;
                this.renderCell();
            } else {
                let debounceFn = debounce(0, () => {
                    this.cellList = list.filter(item => item['name'].toLowerCase().includes(val.toLowerCase()));
                    this.listLoading = false;
                    this.renderCell();
                });
                this.listLoading = true;
                debounceFn();
            }
        }
    },

    components: {
        PageHeader,
        VerifyPackage,
        UsersReportCard,
        UsersReportAllPeopleCard
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

$cell-width: 296px;
$cell-height: 200px;
$arrow-width: 10px;
$arrow-height: 13px;

:global {
    .users-chart--tooltip {
        color: $pt-green-60;
        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%);
        font-weight: 600;
    }

    .report-steps-enter-active,
    .report-steps-leave-active {
        opacity: 1;
        transform: scaleY(1);
        transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
        transform-origin: center top;
    }
    .report-steps-enter,
    .report-steps-leave-active {
        opacity: 0;
        transform: scaleY(0);
    }
}

.report {
    padding-bottom: $page-content-padding-bottom;

    &-header {
        padding-bottom: 18px !important;
    }

    .header-tools {
        display: flex;
        align-items: center;

        & > svg {
            margin-right: 18px;
            cursor: pointer;
            fill: $pt-black-600;

            &:hover {
                fill: $pt-black-600;
            }
        }
    }

    .btn_new {
        @include button(xlarge);
    }

    .tools {
        margin: 0 $page-content-margin-horizontal;
    }

    .searchBox {
        width: 260px;
    }

    .noData {
        text-align: center;
        margin-top: 20px;
    }

    .steps {
        margin-top: $arrow-height;
        position: relative;
        margin-bottom: 32px;

        &-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 $arrow-width $arrow-height $arrow-width;
            border-color: transparent transparent $pt-black-30 transparent;
            position: absolute;
            top: -($arrow-height - 1px);
            z-index: 1;

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 $arrow-width - 1px $arrow-height - 1px $arrow-width - 1px;
                border-color: transparent transparent $pt-black-30 transparent;
                position: absolute;
                left: -($arrow-width - 1px);
                top: 1px;
            }

            &.show {
                opacity: 1;
            }
        }

        &-wrap {
            background-color: $pt-black-30;
            padding: 20px $page-content-margin-horizontal 28px;
            border-radius: 4px;
            position: relative;
            border: 1px solid $pt-black-30;

            & > p {
                color: $pt-black-600;
            }
        }

        &-container {
            min-height: 100px;
            margin-top: 18px;
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            grid-auto-columns: 1fr;
            position: relative;

            @include media-lg {
                grid-auto-flow: row;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            fill: $pt-black-90;

            &:hover {
                fill: $pt-black-300;
            }
        }
    }

    .nodata {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
    }

    .box {
        height: 200px;
        padding: 20px 20px 24px;
        background-color: $pt-white;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: auto 100px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        &:hover {
            box-shadow: 0 12px 12px 0 rgba(9, 30, 66, 0.12);
            transform: scale(1.02);

            .box-status .box-status-icon {
                svg {
                    fill: $pt-green-60 !important;
                }
            }
        }

        &-status-icon {
            width: 18px;
            position: relative;

            svg {
                position: absolute;
                top: 3px;
                right: 0px;
            }
        }

        &-anonymous {
            background-image: url('~@assets/images/users/users-a.svg');
        }
        &-known {
            background-image: url('~@assets/images/users/users-b.svg');
        }
        &-loyal {
            background-image: url('~@assets/images/users/users-c.svg');
        }
        &-custom {
            background-image: url('~@assets/images/users/users-add.svg');
            background-position: right 11.53% bottom 30px;
            background-size: auto 60px;

            .box-status {
                display: none;
            }
        }

        &-title {
            color: $pt-black-600;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 29px;
            display: flex;
            align-items: center;

            svg {
                margin-left: 12px;

                &:hover {
                    fill: $pt-green-60 !important;
                }
            }
        }

        &-description {
            flex: 1;
            color: $pt-black-90;
            letter-spacing: 0;
            line-height: 28px;
            padding-top: 20px;
            max-width: 62.2%;

            p {
                font-size: 13px;
                line-height: 19px;
            }

            h2 {
                font-size: 20px;
            }
        }

        &-status {
            height: 32px;
            color: $pt-black-90;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 4px;
                line-height: 20px;
            }
        }
    }

    .toolbar {
        height: 52px;
        padding-right: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-search {
        }

        &-sort {
            margin-left: 32px;

            &__trigger {
                cursor: pointer;
                svg {
                    margin-left: 6px;
                }
            }
        }
    }

    .container {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($cell-width, 1fr));
        grid-template-rows: repeat(auto-fill, minmax($cell-height, 1fr));
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        position: relative;
    }
}
</style>
