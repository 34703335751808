"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var composition_api_1 = require("@vue/composition-api");
vue_1.default.use(composition_api_1.default);
var directives_1 = require("./directives");
var button_1 = require("./components/button");
var link_1 = require("./components/link");
var icon_1 = require("./components/icon");
var code_1 = require("./components/code");
var list_1 = require("./components/list");
var dragkit_1 = require("./components/dragkit");
// import ptRichtext from '../components/richtext';
var dropdown_1 = require("./components/dropdown");
var scrollbar_1 = require("./components/scrollbar");
var v2_1 = require("./components/scrollbar/v2");
var multi_select_1 = require("./components/multi-select");
var input_number_1 = require("./components/input-number");
var carousel_1 = require("./components/carousel");
var carousel_item_1 = require("./components/carousel-item");
var highcharts_1 = require("./components/highcharts");
var highmaps_1 = require("./components/highmaps");
var tabs_1 = require("./components/tabs");
var table_1 = require("./components/table");
var input_1 = require("./components/input");
var popup_1 = require("./components/popup");
var popup_v2_1 = require("./components/popup-v2");
var radio_1 = require("./components/radio");
var search_1 = require("./components/search");
var select_1 = require("./components/select");
var select_v2_1 = require("./components/select-v2");
var option_1 = require("./components/select-v2/src/option");
var option_group_1 = require("./components/select-v2/src/option-group");
var loading_1 = require("./components/loading");
var pt_guide_1 = require("./components/pt-guide");
var pt_sortable_1 = require("./components/pt-sortable");
var tooltip_1 = require("./components/tooltip/tooltip");
var dialog_1 = require("./components/dialog");
var components = [
    button_1.default.Button,
    button_1.default.ButtonGroup,
    pt_sortable_1.default.PtSortableDraggable,
    pt_sortable_1.default.PtSortableContainer,
    link_1.default,
    icon_1.default,
    code_1.default,
    list_1.default,
    dragkit_1.default,
    dropdown_1.default,
    // ptRichtext,
    scrollbar_1.default,
    v2_1.default,
    multi_select_1.default,
    input_number_1.default,
    carousel_1.default,
    carousel_item_1.default,
    highcharts_1.default,
    highmaps_1.default,
    tabs_1.default,
    table_1.default,
    input_1.default,
    popup_1.default,
    popup_v2_1.default,
    option_1.default,
    option_group_1.default,
    radio_1.default,
    search_1.default,
    select_1.default,
    select_v2_1.default,
    pt_guide_1.default,
    tooltip_1.default,
    dialog_1.default,
];
var PtUI = {
    install: function (Vue) {
        components.map(function (component) {
            Vue.component(component.name, component);
        });
        directives_1.default.forEach(function (directive) {
            Vue.directive(directive.name, directive);
        });
        Vue.directive(loading_1.default.directive.name, loading_1.default.directive);
        Vue.prototype.$loading = loading_1.default.service;
    }
};
if (typeof window !== 'undefined' && window.Vue) {
    PtUI.install(window.Vue);
}
exports.default = PtUI;
