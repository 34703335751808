import i18n from '@/assets/i18n';
import baseConfig from '@/common/configs/base.config';
import localesConfig from '@/common/configs/locales.config';
import productConfig from '@/common/configs/product.config';
import userService from '@/common/services/user.service';

export default {
    hasLanguageKey(key) {
        return localesConfig.some(item => item.key === key);
    },

    getDefaultLocale() {
        const defaultLocale = localesConfig.find(item => item.isDefault);
        const href = window.location.href;
        let locale = defaultLocale;
        href.includes('ptengine.jp') && (locale = 'jp');
        href.includes('ptengine.cn') && (locale = 'zh');
        href.includes('ptengine.com') && (locale = 'en');
        let language = productConfig[locale] && productConfig[locale].language;

        return (this.hasLanguageKey(language) && language) || defaultLocale;
    },

    getLanguage() {
        // 用户登录并且本地已存储i18nkey
        const settingLan = localStorage.getItem(baseConfig.ACCOUNT_I18N);
        let language = this.getDefaultLocale();
        if (userService.alreadyLoggedIn() && this.hasLanguageKey(settingLan)) {
            language = settingLan;
        }
        console.log('settingLan', settingLan, 'language', language);
        return language;
    },

    updateLanguage(locale) {
        if (i18n.locale === locale) return;
        const language = this.hasLanguageKey(locale) ? locale : this.getDefaultLocale();
        i18n.locale = language;
        this.updateStorageLanguage(language);
    },

    updateStorageLanguage(i18n) {
        const { ACCOUNT_I18N } = baseConfig;
        localStorage.setItem(ACCOUNT_I18N, i18n);
    },

    removeStorageLanguage() {
        const { ACCOUNT_I18N } = baseConfig;
        localStorage.removeItem(ACCOUNT_I18N);
    }
};
