class AnalyticsService {
    constructor() {
        this.isDevEnv = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
        this.trackService = {
            ga: null,
            pte: null,
            ptx: null,
            // camera: null,
            identify: null
        };
    }

    _identifyUser() {
        const { uid, property } = this.trackService.identify || {};

        // Ptengine Experience
        uid && window.ptengine && window.ptengine.identify(uid, property);

        //FS
        const { email } = property || {};
        email &&
            window.FS &&
            window.FS.identify(uid, {
                email
            });
    }
    _ga() {
        this.trackService.ga &&
            window.dataLayer &&
            window.dataLayer.push &&
            typeof window.dataLayer.push === 'function' &&
            window.dataLayer.push({
                ...this.trackService.ga
            });
    }
    _pte() {
        this.trackService.pte &&
            window._pt_sp_2 &&
            window._pt_sp_2.push('setCustomVarV2', {
                eventName: this.trackService.pte.eventName
            });
    }
    _mixpanel() {
        const { name, property } = this.trackService.mixpanel || {};
        window.mixpanel && window.mixpanel.track(name, property);
    }
    _ptx() {
        const { name, property } = this.trackService.ptx || {};
        if (name) {
            window.ptengine && window.ptengine.track(name, property);
            console.table([{ name, property: property ? JSON.stringify(property) : '' }]);
        }

        window.parent !== window &&
            window.parent.postMessage(
                {
                    command: 'ExperienceTrackEvent',
                    message: {
                        name,
                        property
                    }
                },
                '*'
            );
    }
    // _camera() {
    //     this.trackService.camera && window.srec && window.srec('tag', this.trackService.camera);
    // }
    _reset() {
        this.trackService = {
            ga: null,
            pte: null,
            ptx: null
        };
    }
    useGA(event) {
        this.trackService.ga = event;
        return this;
    }
    usePTE(event) {
        this.trackService.pte = {
            eventName: event
        };
        return this;
    }
    usePTX(name, property) {
        this.trackService.ptx = { name, property };
        this.useMixpanel(name, property);
        return this;
    }
    useMixpanel(name, property) {
        this.trackService.mixpanel = { name, property };
        return this;
    }
    // useCamera(tag) {
    //     this.trackService.camera = tag;
    //     return this;
    // }
    identifyUser(userInfo, property) {
        const { id, name, email, phone, industry, company, websiteType, createTime, signupSource, i18n, status } =
            userInfo || {};

        property ||
            (property = {
                email, //'用户邮箱，即用户登录账户, 唯一',
                area: i18n, //'用户地区: (JP -> 日本)、(EN -> 欧美)、(ZH -> 中国)',
                status, // '用户状态: (0 -> 无效)、(1 -> 有效)、(2 -> 已删除)',
                create_time: createTime, //'用户创建时间，时间戳格式（13位）',
                name, // '用户名',
                phone, // '用户电话',
                industry, // '用户行业',
                company, // '用户公司名',
                website_type: websiteType,
                signup_source: signupSource //'注册用户来源',
            });
        this.trackService.identify = {
            uid: id,
            property
        };
        return this;
    }
    track() {
        this._identifyUser();
        this._ga();
        this._pte();
        this._ptx();
        this._mixpanel();
        // this._camera();
        this._reset();
    }
}

export default new AnalyticsService();
