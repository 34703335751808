import { isNullOrUndefined, formatTimeStampToCountdown } from '../../utils/creative.util';
import { widgetWrapPadding } from '../../configs/creative.config';
import config from './countdown.config';
import commonUtils from '@/common/utils/common.utils';
const labelsMap = config.labelsMap;
const ptUtils = {
    /**
     * @param {*} timeStampStr 要转换的时间字符串 格式：2019/10/18 10:00:00 .etc
     * @param {*} timezone  档案时区
     */
    getDateStrByProfileTimezone: function(timeStampStr, timezone) {
        let profileTimestamp = 0;
        const timeStamp = new Date(timeStampStr).getTime();
        if (timezone.trim()) {
            const today = new Date();
            const currentTimezoneOffset = today.getTimezoneOffset() * 60 * 1000 * -1; //换算成毫秒数
            const tempArr = timezone.replace(':', ' ').split(' ');
            let profileTimezonOffset;
            if (tempArr.length === 2) {
                profileTimezonOffset = Number(tempArr[0].slice(1)) * 60 * 60 * 1000 + Number(tempArr[1]) * 60 * 1000;
                profileTimezonOffset = Number(tempArr[0].slice(0, 1) + profileTimezonOffset);
            }
            const offset = currentTimezoneOffset - profileTimezonOffset; //得到当地时区和档案时区的毫秒数的差值

            profileTimestamp = timeStamp + offset;
            // console.log('profileTimestampStr', commonUtils.getDateStr(profileTimestamp));
        }
        return commonUtils.getDateStr(profileTimestamp); //毫秒数
    },
    /**
     * 根据当前倒计时时间 判断是否隐藏当前时间类型
     * @param {*} dateConfig 倒计时的 天 时 分 秒
     * @param {*} key 当前时间类型
     */
    isHideCurrentTime: function(dateConfig, key) {
        // console.log('isHideCurrentTime function dateConfig', dateConfig);
        let flag = false;
        switch (key) {
            case 'days':
                flag = dateConfig.days === 0;
                break;
            case 'hour':
                flag = dateConfig.days === 0 && dateConfig.hour === 0;
                break;
            case 'minute':
                flag = dateConfig.days === 0 && dateConfig.hour === 0 && dateConfig.minute === 0;
                break;
            default:
                flag = false;
                break;
        }
        return flag;
    },
    // 获取倒计时内部dom
    generateCountdownDom: function(dateConfig, hasLable, language, layout) {
        let dom = '';
        for (const key in dateConfig) {
            if (!this.isHideCurrentTime(dateConfig, key)) {
                const timeStr = dateConfig[key] < 10 ? '0' + dateConfig[key] : parseInt(dateConfig[key]).toString();
                const timeArr = timeStr.split('');
                const separatorDom = key !== 'second' ? '<span class="countdown-item__seperator">∶</span>' : '';
                const labelDom = hasLable
                    ? '<span class="countdown-item__label">' + labelsMap[language][key] + '</span>'
                    : '';
                if (!labelDom) {
                    dom +=
                        '<div class="countdown-item" data-countdown-item=' +
                        key +
                        '>' +
                        '<span class="countdown-item__card">' +
                        timeArr[0] +
                        '</span>' +
                        '<span class="countdown-item__card">' +
                        timeArr[1] +
                        '</span>' +
                        separatorDom +
                        '</div>';
                } else {
                    if (layout === 'down') {
                        dom +=
                            '<div class="countdown-item__wrap" data-countdown-item="' +
                            key +
                            '">' +
                            labelDom +
                            '<div class="countdown-item">' +
                            '<span class="countdown-item__card">' +
                            timeArr[0] +
                            '</span>' +
                            '<span class="countdown-item__card">' +
                            timeArr[1] +
                            '</span>' +
                            separatorDom +
                            '</div>' +
                            '</div>';
                    } else if (layout === 'up') {
                        dom +=
                            '<div class="countdown-item__wrap" data-countdown-item="' +
                            key +
                            '">' +
                            '<div class="countdown-item">' +
                            '<span class="countdown-item__card">' +
                            timeArr[0] +
                            '</span>' +
                            '<span class="countdown-item__card">' +
                            timeArr[1] +
                            '</span>' +
                            separatorDom +
                            '</div>' +
                            labelDom +
                            '</div>';
                    }
                }
            }
        }
        return dom;
    }
};
function getCountdownContentDom({
    type = config.defaultContent.type,
    date = config.defaultContent.date,
    timezone = config.defaultContent.timezone,
    endTime = config.defaultContent.endTime,
    labels = config.defaultContent.labels,
    layout
}) {
    const dateConfig = {};
    const { enable, language } = labels;
    const { profile, useVisitors } = timezone;
    if (type === 'fixedDate' && endTime) {
        const endDateStamp = useVisitors ? endTime : ptUtils.getDateStrByProfileTimezone(endTime, profile);
        Object.assign(dateConfig, formatTimeStampToCountdown(endDateStamp));
    } else {
        Object.assign(dateConfig, date);
    }
    return ptUtils.generateCountdownDom(dateConfig, enable, language, layout);
}

function getCountdownDom(content, settings) {
    const { type, date, endTime, timezone, labels } = content.value;
    const { layout } = settings;
    const countdownItemDom = getCountdownContentDom({ type, date, endTime, timezone, labels, layout });
    return countdownItemDom;
}
export default {
    getHtml(block) {
        const id = block.blockId;
        const content = block.blockContent || [];
        const settings = block.blockSettings || {};
        let domContent = '';
        const { endMessage, isRenderMsg = false } = settings;
        console.log('getHtml isRenderMsg', settings, isRenderMsg);
        if (!isRenderMsg) {
            domContent = getCountdownDom(content[0], settings, id);
        } else {
            domContent = `${endMessage}`;
        }
        return `
            <div id=${id} data-engage-block="countdown">
                ${domContent}
            </div>
        `;
    },
    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        const content = block.blockContent || [];
        const contentConfig = content[0].value;
        let { noPadding, marginTop, marginBottom, backgroundColor } = settings;
        const { numbers, labels } = contentConfig;
        const padding = noPadding ? '0' : `0 ${paddingLeft}px 0 ${paddingLeft}px`;
        if (isNullOrUndefined(marginTop)) marginTop = 0;
        if (isNullOrUndefined(marginBottom)) marginBottom = 0;
        if (isNullOrUndefined(backgroundColor)) backgroundColor = 'transparent';
        return `
            #${id} {
                display:flex;
                justify-content:center;
                align-items:center;
                padding:${padding};
                white-space:nowrap;
                background-color:${backgroundColor};
                margin-top:${marginTop}px;
                margin-bottom:${marginBottom}px;
            }
            #${id} .countdown-item__card {
                display:inline-block;
                width:${numbers.fontSize + 16}px;
                background-color: ${numbers.backgroundColor};
                color: ${numbers.fontColor};
                padding: 8px;
                border-radius: 4px;
                font-weight: ${numbers.fontWeight};
                font-size: ${numbers.fontSize}px;
                line-height: ${numbers.fontSize}px;
                text-align: center;
                vertical-align: middle;
                box-sizing: border-box;
            }
            #${id} .countdown-item__card:nth-of-type(1){
                margin-right:5px;
            }
            #${id} .countdown-item__label {
                display:block;
                font-size:${labels.fontSize}px;
                color:${labels.fontColor};
                text-align:center;
                margin-bottom:3px;
            }
            #${id} .countdown-item__seperator {
                display:inline-block;
                color:${numbers.separatorColor};
                font-size:${numbers.fontSize}px;
                font-weight:bolder;
                vertical-align:middle;
                margin:0 2px;
            }
        `;
    },

    /**
     * get block script
     * @param {String} belong: ['dialog', 'thumbnail', 'editor', 'normal']
     */
    getScript(block, widgetId) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        const content = block.blockContent[0] || [];
        const { endAction, endMessage, layout } = settings;
        const { endTime, timezone, labels } = content.value;
        const ptCountdownConfig = {
            widgetId: widgetId,
            blockId: id,
            // type: type,
            endAction: endAction,
            endMessage: endMessage,
            endTime: endTime,
            timezone: timezone,
            // date: date,
            layout: layout,
            language: labels.language,
            enable: labels.enable,
            timer: null
        };
        return `
            <script type="text/javascript">
            /** this is for countdown js code here start */
                (function(config){
                    var daySeconds = 24 * 60 * 60;
                    var hourSeconds = 60 * 60; 
                    var minuteSeconds = 60;
                    var labelsMap = ${JSON.stringify(labelsMap)};
                    var MASKLAYERPPREFIX = 'pt_mask_';
                    var ptUtils = {
                        formatDateValue:function(str){
                            return str < 10 ? '0' + str : str;
                        },
                        getDateStr:function(dateStr) {
                            const date = new Date(dateStr);
                            const yyyy = date.getFullYear();
                            const MM = this.formatDateValue(date.getMonth() + 1);
                            const dd = this.formatDateValue(date.getDate());
                            const hh = this.formatDateValue(date.getHours());
                            const mm = this.formatDateValue(date.getMinutes());
                            const ss = this.formatDateValue(date.getSeconds());
                            return yyyy + '/' + MM + '/' + dd + ' ' + hh + ':' + mm + ':' + ss;
                        },
                        getDateStrByProfileTimezone:function(timeStampstr, timezone) {
                            var profileTimestamp = 0;
                            var timeStamp = new Date(timeStampstr).getTime();
                            if (timezone.trim()) {
                                var today = new Date();
                                var currentTimezoneOffset = today.getTimezoneOffset() * 60 * 1000 * -1;
                                var tempArr = timezone.replace(':', ' ').split(' ');
                                var profileTimezonOffset;
                                if (tempArr.length === 2) {
                                    profileTimezonOffset = Number(tempArr[0].slice(1)) * 60 * 60 * 1000 + Number(tempArr[1]) * 60 * 1000;
                                    profileTimezonOffset = Number(tempArr[0].slice(0, 1) + profileTimezonOffset);
                                }
                                var offset = currentTimezoneOffset - profileTimezonOffset;
                                profileTimestamp = timeStamp + offset; 
                            }
                            return this.getDateStr(profileTimestamp);
                        },
                        isHideCurrentTime:function(dateConfig, key) {
                            var flag = false;
                            switch (key) {
                            case 'days':
                                flag = dateConfig.days === 0;
                                break;
                            case 'hour':
                                flag = dateConfig.days === 0 && dateConfig.hour === 0;
                                break;
                            case 'minute':
                                flag = dateConfig.days === 0 && dateConfig.hour === 0 && dateConfig.minute === 0;
                                break;
                            default:
                                flag = false;
                                break;
                            }
                            return flag;
                        },
                        generateCountdownDom:function(dateConfig, hasLable, language, layout) {
                            var dom = '';
                            for (const key in dateConfig) {
                                if (!this.isHideCurrentTime(dateConfig, key)) {
                                    var timeStr = dateConfig[key] < 10 ? '0'+ dateConfig[key] : parseInt(dateConfig[key]).toString();
                                    var timeArr = timeStr.split('');
                                    var separatorDom = key !== 'second' ? '<span class="countdown-item__seperator">∶</span>' : '';
                                    var labelDom = hasLable
                                        ? '<span class="countdown-item__label">' + labelsMap[language][key] + '</span>'
                                        : '';
                                    if (!labelDom) {
                                        dom += 
                                            '<div class="countdown-item" data-countdown-item='+ key + '>'+
                                                '<span class="countdown-item__card">'+ timeArr[0] + '</span>'+
                                                '<span class="countdown-item__card">'+ timeArr[1] +'</span>' +
                                                separatorDom +
                                            '</div>';
                                    } else {
                                        if (layout === 'down') {
                                            dom += 
                                                '<div class="countdown-item__wrap" data-countdown-item="'+ key +'">'+
                                                    labelDom +
                                                    '<div class="countdown-item">' +
                                                        '<span class="countdown-item__card">'+timeArr[0]+'</span>'+
                                                        '<span class="countdown-item__card">'+timeArr[1]+'</span>'+
                                                        separatorDom +
                                                    '</div>'+
                                                '</div>';
                                        } else if (layout === 'up') {
                                            dom += 
                                                '<div class="countdown-item__wrap" data-countdown-item="'+ key +'">'+
                                                    '<div class="countdown-item">' +
                                                        '<span class="countdown-item__card">'+timeArr[0]+'</span>' +
                                                        '<span class="countdown-item__card">'+timeArr[1]+'</span>' +
                                                        separatorDom +
                                                    '</div>' +
                                                    labelDom +
                                                '</div>';
                                        }
                                    }
                                }
                            }
                            return dom;
                        },
                        tryRemoveMaskLayer:function(id) {
                            var layer = document.querySelector('.engage-layer');
                            if (layer) {
                                var tryRemoveClassName = MASKLAYERPPREFIX + id;
                                var minClasses = ['engage-layer-open', tryRemoveClassName];
                                var htmlTag = document.getElementsByTagName("html")[0];
                                var bodyTag = document.body;
                                var classList = Array.prototype.slice.call(document.body.classList).filter(function(item){
                                    return item == "engage-layer-open" || /^pt_mask_/.test(item);
                                })
                                if (classList.reverse().join('') == minClasses.reverse().join('')) {
                                    bodyTag.removeChild(layer);

                                    minClasses.forEach(function(cls){
                                        htmlTag.classList.remove(cls);
                                        bodyTag.classList.remove(cls);
                                    })
                                } else {
                                    htmlTag.classList.remove(tryRemoveClassName);
                                    bodyTag.classList.remove(tryRemoveClassName);
                                }
                            }

                        }
                    };
                    var endTime = config.timezone.useVisitors ? config.endTime : ptUtils.getDateStrByProfileTimezone(config.endTime, config.timezone.profile);
                    var end = new Date(endTime).getTime();
                    var remainSeconds = 0;
                    var parent = document.querySelector('#' + config.blockId);
                    function updateCountdown(){
                        var now = new Date().getTime();
                        remainSeconds = Math.floor((end - now) / 1000);
                        // console.log('remainSeconds is ', remainSeconds);
                        if (remainSeconds <= 0) {
                            if(parent && config.endAction === 'showMsg') {
                                parent.innerHTML = config.endMessage;
                                return;
                            }else if(config.endAction === 'hide'){
                                var widget = document.querySelector('#'+config.widgetId);
                                // console.log('widget DOM ',widget);
                                widget && (widget.setAttribute('style','display:none!important;'));
                                widget && widget.parentNode && widget.parentNode.setAttribute('data-show-engage',false);
                            }
                            this.unmounted();
                        }
                        var remainSecondsCopy = remainSeconds;
                        var days = Math.floor(remainSecondsCopy / daySeconds);
                        remainSecondsCopy %= daySeconds;
                        var hour = Math.floor(remainSecondsCopy / hourSeconds);
                        remainSecondsCopy %= hourSeconds;
                        var minute = Math.floor(remainSecondsCopy / minuteSeconds);
                        remainSecondsCopy %= minuteSeconds;
                        var dom = ptUtils.generateCountdownDom({
                            days:days,
                            hour:hour,
                            minute:minute,
                            second:Math.floor(remainSecondsCopy)
                        }, config.enable, config.language, config.layout);
                        parent && (parent.innerHTML = dom);
                    };
                    // console.log('window.ptEngage', window.ptEngage);
                    window.ptEngage = window.ptEngage || {};
                    window.ptEngage[config.widgetId] = window.ptEngage[config.widgetId] || [];
                    window.ptEngage[config.widgetId].push({
                        mounted: function(){
                            updateCountdown.bind(this)();
                            this.timer && clearInterval(this.timer);
                            var now = new Date().getTime();
                            if(Math.floor((end - now) / 1000) > 0){
                                this.timer = setInterval(updateCountdown.bind(this), 1000);
                            }
                        },
                        unmounted: function(){
                            this.timer && clearInterval(this.timer);
                            this.timer = null;
                            this.callback && typeof this.callback === 'function' && this.callback((function(){
                                if(config.endAction === 'hide'){
                                    return ptUtils.tryRemoveMaskLayer;
                                }
                                return null;
                            })());
                            window.ptEngage && window.ptEngage[config.widgetId] && (window.ptEngage[config.widgetId]=[]);
                        }
                    });
                })(${JSON.stringify(ptCountdownConfig)});
            /** this is for countdown js code here end */
            </script>
        `;
    }
};
