/**
 * 产品用户相关api
 */

import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'logout',
        query: `query logout{
            logout
        }`
    },
    {
        name: 'superLogin',
        query: `query superLogin($where: SuperAccountWhereUniqueInput!){
            superLogin(where:$where){
                id
                name
                email
                phone
                industry
                company
                position
                websiteType
                token
                hasActivated
                hasAllow
                createTime
                updateTime
                signupSource
                i18n
                status
            }
        }`
    },
    {
        name: 'login',
        query: `query login($data: AccountLoginInput!, $where: AccountWhereUniqueInput!){
            login(data:$data, where:$where){
                id
                name
                email
                phone
                industry
                company
                position
                websiteType
                token
                hasActivated
                hasAllow
                createTime
                updateTime
                signupSource
                i18n
                status
            }
        }`
    },
    {
        name: 'createAccount',
        query: `mutation createAccount($data: AccountCreateInput!){
            createAccount(data: $data){
                id
                name
                email
                phone
                industry
                company
                position
                websiteType
                token
                hasActivated
                hasAllow
                createTime
                updateTime
                signupSource
                i18n
                status
            }
        }`
    },
    {
        name: 'activeAccount',
        query: `mutation activeAccount($where: AccountWhereUniqueInput!){
            activeAccount(where:$where){
                id
                name
                email
                phone
                industry
                company
                position
                websiteType
                token
                hasActivated
                hasAllow
                createTime
                updateTime
                signupSource
                i18n
                status
            }
        }`
    },
    {
        name: 'updatePassword',
        query: `mutation updatePassword($data:PasswordUpdateInput!, $where:AccountWhereUniqueInput!){
            updatePassword(data:$data, where:$where) {
                id
                email
                i18n
            }
        }`
    },
    {
        name: 'sendActiveEmail',
        query: `mutation sendActiveEmail($where: AccountWhereUniqueInput!){
            sendActiveEmail(where:$where)
        }`
    },
    {
        name: 'updateAccount',
        query: `mutation updateAccount($data: AccountUpdateInput!, $where: AccountWhereUniqueInput!){
            updateAccount(data:$data, where:$where){
                id
                name
                email
                status
                i18n
            }
        }`
    },
    {
        name: 'checkActiveProfile',
        query: `query checkActiveProfile($where: ProfileWhereUniqueInput!){
            checkActiveProfile(where: $where)
        }`
    },
    {
        name: 'getAccountInfo',
        query: `query getAccountInfo($where:AccountWhereUniqueInput!){
            account(where:$where){
                id
                ptiUid
                firstName
                name
                email
                password
                phone
                industry
                company
                position
                websiteType
                token
                hasActivated
                hasAllow
                createTime
                activatedTime
                updateTime
                signupSource
                permissionsDict
                permissions
                i18n
                status
                freeTrialLong
                accountType
                department
                companySize
                address
                onlyPti
                jobTitle
                loginUtm
                needSecondVerification
                beFirstLogin
                needGuide
                ptiToken
                ptiPwd
                role
                area
                useType
                beFromGoogle
                isNpPackage
            }
        }`
    },
    {
        name: 'createRegistration',
        query: `mutation createRegistration($data:RegistrationUpdateInput!){
            createRegistration(data:$data) {
                id,
                name,
                email,
                phone,
                industry,
                company,
                position,
                description,
                hasLogon,
                createTime,
                updateTime,
                i18n
            }
        } `
    },
    {
        name: 'resetPassword',
        query: `mutation resetPassword($data: PasswordUpdateInput!, $where: AccountWhereUniqueInput!){
            resetPassword(data: $data, where: $where)
        }`
    },
    {
        name: 'activeUpdatePassword',
        query: `mutation activeUpdatePassword($data: PasswordUpdateInput!, $where: AccountWhereUniqueInput!){
            activeUpdatePassword(data: $data, where: $where)
        }`
    },
    {
        name: 'validateDigitalSign',
        query: `mutation validateDigitalSign($where: AccountWhereUniqueInput!){
            validateDigitalSign(where: $where)
        }`
    },
    {
        name: 'validateActiveSign',
        query: `mutation validateActiveSign($where: AccountWhereUniqueInput!){
            validateActiveSign(where: $where)
        }`
    }
];

export default resourceWrapper(resources);
