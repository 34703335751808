<template>
    <div class="pt-dropdown">
        <div class="pt-dropdown__trigger" v-pt-clickoutside:[true]="handleOutsideClick" @click.stop="toggleDropdown">
            <slot name="trigger"></slot>
        </div>

        <transition name="pt-dropdown-in-top">
            <slot name="dropdown" v-if="visible"></slot>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ptDropdown',

    props: {
        outsideClickCallback: Function
    },

    data() {
        return {
            visible: false
        };
    },

    methods: {
        handleOutsideClick(e) {
            typeof this.outsideClickCallback === 'function'
                ? this.outsideClickCallback.call(this, e)
                : this.hideDropdown();
        },

        toggleDropdown() {
            this.visible ? this.hideDropdown() : this.showDropdown();
        },

        showDropdown() {
            this.visible = true;
        },

        hideDropdown() {
            this.visible = false;
        }
    }
};
</script>

<style lang="scss">
@import '@/styles/import.scss';

.pt-dropdown {
    position: relative;

    &__trigger {
        height: 100%;
        user-select: none;
        cursor: pointer;
    }
}

.pt-dropdown-in-top-enter-active,
.pt-dropdown-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center top;
}
.pt-dropdown-in-top-enter,
.pt-dropdown-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0);
}
</style>
