"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDefaultMaskStyle = exports.deleteMask = exports.createMask = exports.maskEle = void 0;
var style_object_1 = require("../../style-object");
var highlight_1 = require("../highlight");
var isDOMElement_1 = require("../../../utils/isDOMElement");
var index_1 = require("../index");
var defaultMaskStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
    zIndex: 9999,
    transition: 'all .3s ease',
    contain: 'strict'
};
var targetEle = null;
var clickMaskToStopFlag = false;
var maskEleExist = false;
exports.maskEle = null;
var clickTargetFunc;
var clickMaskFunc;
var clickWhiteListFunc;
var whiteList = [];
var staticWhiteList = [];
var dynamicWhiteList = [];
function createMask(target, config) {
    var _a;
    targetEle = target;
    whiteList = [];
    staticWhiteList = [];
    dynamicWhiteList = [];
    clickMaskToStopFlag = config === null || config === void 0 ? void 0 : config.clickMaskToStop;
    clickTargetFunc = config === null || config === void 0 ? void 0 : config.onClickTarget;
    clickMaskFunc = config === null || config === void 0 ? void 0 : config.onClickMask;
    clickWhiteListFunc = config === null || config === void 0 ? void 0 : config.onClickWhiteList;
    __spreadArray([targetEle, highlight_1.highlightEle, '.guide-tooltip'], ((_a = config === null || config === void 0 ? void 0 : config.whiteList) !== null && _a !== void 0 ? _a : []), true).forEach(function (whitelistDOM) {
        if (typeof whitelistDOM === 'string')
            whiteList.push(whitelistDOM);
        else if ((0, isDOMElement_1.isDOMElement)(whitelistDOM))
            staticWhiteList.push(whitelistDOM);
    });
    if (!exports.maskEle)
        exports.maskEle = document.createElement('div');
    else
        maskEleExist = true;
    exports.maskEle.style.cssText = (0, style_object_1.formatStyleObjectToString)(__assign(__assign(__assign({}, defaultMaskStyle), config === null || config === void 0 ? void 0 : config.styleObj), { cursor: (config === null || config === void 0 ? void 0 : config.clickMaskToStop) ? 'pointer' : 'default', pointerEvents: (config === null || config === void 0 ? void 0 : config.interactive) ? 'none' : 'auto' }));
    if (!maskEleExist)
        document.body.append(exports.maskEle);
    if (!(config === null || config === void 0 ? void 0 : config.clickable)) {
        document.addEventListener('click', stopClickPropagation, true);
        document.addEventListener('mousedown', stopMouseDownPropagation, true);
        document.addEventListener('keydown', stopKeyboardEvent);
    }
}
exports.createMask = createMask;
function deleteMask() {
    if (exports.maskEle) {
        exports.maskEle.remove();
        exports.maskEle = null;
        maskEleExist = false;
    }
    if (targetEle)
        targetEle = null;
    clickMaskToStopFlag = false;
    clickTargetFunc = undefined;
    clickMaskFunc = undefined;
    clickWhiteListFunc = undefined;
    whiteList = [];
    staticWhiteList = [];
    dynamicWhiteList = [];
    document.removeEventListener('click', stopClickPropagation, true);
    document.removeEventListener('mousedown', stopMouseDownPropagation, true);
    document.removeEventListener('keydown', stopKeyboardEvent);
}
exports.deleteMask = deleteMask;
function setDefaultMaskStyle(styleObj) {
    if (typeof styleObj !== 'object')
        return;
    defaultMaskStyle = __assign(__assign({}, defaultMaskStyle), styleObj);
}
exports.setDefaultMaskStyle = setDefaultMaskStyle;
function stopClickPropagation(event) {
    var _a;
    dynamicWhiteList = [];
    whiteList.forEach(function (domString) {
        var DOM = Array.prototype.slice.call(document.querySelectorAll(domString));
        if (DOM.length)
            dynamicWhiteList.push.apply(dynamicWhiteList, DOM);
    });
    var allWhiteList = __spreadArray(__spreadArray([], staticWhiteList, true), dynamicWhiteList, true);
    if (!allWhiteList.some(function (whiteListDOM) { return whiteListDOM === null || whiteListDOM === void 0 ? void 0 : whiteListDOM.contains(event.target); })) {
        event.preventDefault();
        event.stopPropagation();
        clickMaskFunc === null || clickMaskFunc === void 0 ? void 0 : clickMaskFunc(event);
        if (clickMaskToStopFlag)
            (0, index_1.stop)();
        return;
    }
    for (var i = 0, len = allWhiteList.length; i < len; i++) {
        if ((_a = allWhiteList[i]) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
            if (i === 0)
                clickTargetFunc === null || clickTargetFunc === void 0 ? void 0 : clickTargetFunc(event);
            else
                clickWhiteListFunc === null || clickWhiteListFunc === void 0 ? void 0 : clickWhiteListFunc(event);
            return;
        }
    }
    event.preventDefault();
    event.stopPropagation();
}
function stopMouseDownPropagation(event) {
    dynamicWhiteList = [];
    whiteList.forEach(function (domString) {
        var DOM = Array.prototype.slice.call(document.querySelectorAll(domString));
        if (DOM.length)
            dynamicWhiteList.push.apply(dynamicWhiteList, DOM);
    });
    var allWhiteList = __spreadArray(__spreadArray([], staticWhiteList, true), dynamicWhiteList, true);
    if (!allWhiteList.some(function (whiteListDOM) { return whiteListDOM === null || whiteListDOM === void 0 ? void 0 : whiteListDOM.contains(event.target); })) {
        event.preventDefault();
    }
}
function stopKeyboardEvent(event) {
    if (event.key === 'Tab')
        event.preventDefault();
}
