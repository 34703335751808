import { widgetWrapPadding } from '../../configs/creative.config';
import { isNullOrUndefined } from '../../utils/creative.util';
import blocksConfig from '../../configs/blocks.config';

function getSubModel(content, blockSettings) {
    const config = blocksConfig.find(item => item.code === content.code);
    const { alignment } = blockSettings;
    if (config) {
        const block = {
            blockId: content.value.id,
            blockContent: content.value.content,
            blockSettings: Object.assign({}, content.value.settings, { alignment })
        };
        return {
            dom: config.services.getHtml(block),
            style: config.services.getStyle(block)
        };
    }
}

export default {
    getHtml(block) {
        const id = block.blockId;
        const { layout } = block.blockSettings;
        const dom = {};
        block.blockContent.forEach(content => {
            dom[content.code] = getSubModel(content, block.blockSettings).dom;
        });
        const leftCellDom = layout === 'imgLeft' ? dom.image : dom.text;
        const rightCellDom = layout === 'imgLeft' ? dom.text : dom.image;
        return `
            <div id="${id}">
                <div id="${id}-cell-left">${leftCellDom}</div>
                <div id="${id}-cell-right">${rightCellDom}</div>
            </div>
        `;
    },
    
    getStyle(block, { paddingLeft = widgetWrapPadding.left } = {}) {
        const id = block.blockId;
        const settings = block.blockSettings || {};
        const { layout, spacing } = settings;
        let { backgroundColor, marginTop, marginBottom, noPadding } = settings;
        const padding = noPadding ? 0 : `0 ${paddingLeft}px 0 ${paddingLeft}px`;
        const subModelStyle = block.blockContent.map(content => {
            return getSubModel(content, settings).style;
        }).join('');
        const justifyContent = layout === 'imgRight' ? 'justify-content: flex-end;' : '';
        if (isNullOrUndefined(marginTop)) marginTop = 0;
        if (isNullOrUndefined(marginBottom)) marginBottom = 0;
        if (isNullOrUndefined(backgroundColor)) backgroundColor = 'transparent';
        return `
            #${id} {
                display: flex;
                display: -webkit-flex;
                display: -ms-flexbox;
                ${justifyContent}
                padding: ${padding};
                margin: ${marginTop}px 0 ${marginBottom}px;
                background-color: ${backgroundColor};
            }
            #${id}-cell-left {
                padding-right: ${spacing}px;
            }
            #${id}-cell-right {}
            ${subModelStyle}
        `;
    }
};
