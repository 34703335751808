<template>
    <pt-button
        size="medium"
        color="blue"
        v-if="component === 'button'"
        v-pt-clickoutside="handleOutsideClick"
        :loading="checking"
        @click="handleClick"
    >
        <slot></slot>
    </pt-button>
    <span v-else @click="handleClick" v-pt-clickoutside="handleOutsideClick">
        <slot></slot>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import packageUtils from './package.utils';
import authConfig from '@common/packages/auth.config';
import { checkPackageAuth, isNpPackage } from '@/common/services/singleSpa.service';

export default {
    name: 'VerifyPackage',
    props: {
        component: {
            type: String,
            default: 'button'
        },
        size: {
            type: String,
            default: 'large'
        },
        color: {
            type: String,
            default: 'blue'
        },
        disabled: {
            type: Boolean,
            default: false
        },

        authKey: String,
        privilegeKey: String,

        resolveCallback: Function,
        rejectCallback: Function
    },
    data() {
        return {
            checking: false
        };
    },
    computed: {
        ...mapGetters('main', ['profileInfo', 'packageInfo'])
    },
    created() {
        this.$eventBus.$on('authCheckResolve', this.handleAuthCheckResolve, false);
    },
    beforeDestroy() {
        this.$eventBus.$off('authCheckResolve', this.handleAuthCheckResolve, false);
    },
    methods: {
        async handleClick() {
            if (this.disabled) {
                this.passed();
                return;
            }
            const authOptions = authConfig.authTypeList.find(item => item.type === this.privilegeKey);
            const { type, key, editType } = authOptions || {};

            // 新套餐用户及档案走统一校验流程
            checkPackageAuth(this.privilegeKey, editType, () => {
                isNpPackage()
                    ? this.passed()
                    : packageUtils.verifyPrivilege({ privilegeKey: key, editType: editType })
                    ? this.passed()
                    : this.rejected();
            });
        },
        handleOutsideClick(e) {
            this.$el.contains(e.target) || this.$emit('clickOutside', e);
        },
        handleAuthCheckResolve({ limitType, effective }) {
            if (limitType === this.privilegeKey) effective && this.passed();
        },
        passed() {
            this.resolveCallback && typeof this.resolveCallback === 'function' && this.resolveCallback();
        },
        rejected() {
            this.rejectCallback && typeof this.rejectCallback === 'function' && this.rejectCallback();
        }
    }
};
</script>
