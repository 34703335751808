<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        viewBox="0 0 18 18"
        :aria-labelledby="icon"
        :style="style"
        class="pt-icon"
        @click="click"
    >
        <use :xlink:href="['#' + icon]" />
    </svg>
</template>

<script>
export default {
    name: 'ptIcon',
    props: {
        icon: {
            type: String,
            default: 'box'
        },
        iconStyle: {
            type: Object,
            default: function() {
                return {
                    width: '20px',
                    height: '20px'
                };
            }
        }
    },
    data() {
        return {
            style: Object.assign(
                {
                    width: '20px',
                    height: '20px'
                },
                this.iconStyle
            )
        };
    },
    methods: {
        click() {
            this.$emit('click');
        }
    }
};
</script>
<style lang="scss">
@import '@/styles/import';
.pt-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    fill: $pt-black-50;
    /* svg {
            fill: #8993a4;
        } */
}
</style>
