import store from '@/store';
import interactiveDemoService from './interactive-demo.service';
import type {Version, Terminal, Schedule} from '@/common/types/CampaignTypes';
const data = {
    defaultData: {
        goals: [],
        usergroups: [
            {
                id: 'fa037521-af1f-4154-be33-040bc386274a',
                source: 'default',
                name: 'All People',
                content: {},
                isInvalid: false
            }
        ],
        conditions: [
            {
                when: [] as any[],
                session: {
                    operator: 'and',
                    children: []
                }
            } 
        ]
    },

    customerAcquisition: {
        goals: [
            {
                id: '3f202781-951c-44e3-81f7-ce14ed9abafa',
                name: 'interactive_demo.customer_goal1',
                content: { type: 'url', condition: '[()]', value: ['pt'] },
                description: '',
                engagements: 1,
                currentResult: null,
                lastResult: null,
                checked: true
            }
        ],
        conditions: [
            {
                when: [] as any[],
                session: {
                    operator: 'and',
                    children: [
                        {
                            uuid: '87ede552-b1d7-4d32-8c59-9637d19ef707',
                            matchRange: 'current_session',
                            occurCondition: '>=',
                            occurTimes: 1,
                            occurFrom: 1,
                            occurTo: 5,
                            error: { type: true, value: false },
                            type: 'utmCampaign',
                            name: 'Campaign Name',
                            propertyType: 'STATIC',
                            patternType: 'STRING',
                            value: ['FB_summercp'],
                            condition: '=='
                        },
                        {
                            uuid: '298da804-a614-47d5-8d5d-9ab0f22a14ea',
                            matchRange: 'current_session',
                            occurCondition: '>=',
                            occurTimes: 1,
                            occurFrom: 1,
                            occurTo: 5,
                            error: { type: true, value: false },
                            type: 'visitType',
                            name: 'Visit Type',
                            propertyType: 'STATIC',
                            patternType: 'STRING',
                            value: ['NV'],
                            condition: '=='
                        }
                    ] as any[]
                }
            }
        ]
    },

    upsellOffers: {
        goals: [
            {
                id: '3f202781-951c-44e3-81f7-ce14ed9abafa',
                name: 'interactive_demo.upsell_goal1',
                content: { type: 'url', condition: '[()]', value: ['pt'] },
                description: '',
                engagements: 1,
                currentResult: null,
                lastResult: null,
                checked: true
            }
        ],
        usergroups: [
            {
                id: '0c1e1db6-8bc1-44df-97b5-87d369d0a804',
                source: 'custom',
                name: 'interactive_demo.upsell_usergroup1',
                content: {
                    children: [],
                    operator: 'and'
                },
                checked: true
            }
        ]
    }
};

const steps = {
    customerAcquisition: [
        {
            key: 'step1',
            order: 'interactive_demo.customer_step1_order',
            desc: 'interactive_demo.customer_step1_desc',
            next: 'interactive_demo.customer_step1_next',
            nextStep: 'step2',
            targetEle: '.js_goals_edit',
            initStepData: function() {
                console.log('step1 init data ---->');
                (this as any).currentSetting = null;
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.defaultData.goals;
                (this as any).campaign.conditions = data.defaultData.conditions;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step2',
            order: 'interactive_demo.customer_step2_order',
            desc: 'interactive_demo.customer_step2_desc',
            next: 'interactive_demo.customer_step2_next',
            tooltipPosition: 'top',
            nextStep: 'step3',
            targetEle: '.js_goals_done',
            initStepData: function() {
                (this as any).currentSetting = 'goals';
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.defaultData.goals;
                (this as any).campaign.conditions = data.defaultData.conditions;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step3',
            order: 'interactive_demo.customer_step3_order',
            desc: 'interactive_demo.customer_step3_desc',
            next: 'interactive_demo.customer_step3_next',
            nextStep: 'step4',
            targetEle: '.js_session_edit',
            initStepData: function() {
                (this as any).currentSetting = null;
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.customerAcquisition.goals;
                (this as any).campaign.conditions = data.defaultData.conditions;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step4',
            order: 'interactive_demo.customer_step4_order',
            desc: 'interactive_demo.customer_step4_desc',
            next: 'interactive_demo.customer_step4_next',
            tooltipPosition: 'top',
            nextStep: 'step5',
            targetEle: '.js_session_done',
            initStepData: function() {
                console.log('step4 init data ---->');
                (this as any).currentSetting = 'session';
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.customerAcquisition.goals;
                (this as any).campaign.conditions = data.defaultData.conditions;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step5',
            order: 'interactive_demo.customer_step5_order',
            desc: 'interactive_demo.customer_step5_desc',
            next: 'interactive_demo.customer_step5_next',
            nextStep: 'step6',
            targetEle: '.js_go_editor',
            initStepData: function() {
                (this as any).currentSetting = null;
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.customerAcquisition.goals;
                (this as any).campaign.conditions = data.customerAcquisition.conditions;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            },
            handleNextClick: function() {}
        },
        {
            key: 'step6',
            order: 'interactive_demo.customer_step6_order',
            desc: 'interactive_demo.customer_step6_desc',
            next: 'interactive_demo.customer_step6_next',
            initStepData: function() {},
            handleNextClick: function() {}
        },
        {
            key: 'step7',
            order: 'interactive_demo.customer_step7_order',
            desc: 'interactive_demo.customer_step7_desc',
            next: 'interactive_demo.customer_step7_next',
            initStepData: function() {},
            handleNextClick: function() {}
        },
        {
            key: 'step8',
            order: 'interactive_demo.customer_step8_order',
            desc: 'interactive_demo.customer_step8_desc',
            next: 'interactive_demo.customer_step8_next',
            targetEle: '.js_campaign_save',
            initStepData: function() {
                (this as any).state.isEdited = true;
            },
            handleNextClick: function() {}
        },
        {
            key: 'step9',
            order: 'interactive_demo.customer_step9_order',
            desc: 'interactive_demo.customer_step9_desc',
            next: 'interactive_demo.customer_step9_next',
            end: true,
            tooltipStyle: {
                padding: '32px',
                width: '400px',
                top: '80px',
                right: '20px'
            },
            initStepData: function() {
                const countMeta = {
                    All: 1,
                    Live: 1,
                    Paused: 0,
                    Draft: 2
                };
                store.commit('engage/SET_COUNT_META', countMeta);
            },
            handleNextClick: function() {}
        }
    ],
    upsellOffers: [
        {
            key: 'step1',
            order: 'interactive_demo.upsell_step1_order',
            desc: 'interactive_demo.upsell_step1_desc',
            next: 'interactive_demo.upsell_step1_next',
            nextStep: 'step2',
            targetEle: '.js_goals_edit',
            initStepData: function() {
                console.log('step1 init data ---->');
                (this as any).currentSetting = null;
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.defaultData.goals;
                (this as any).campaign.usergroups = data.defaultData.usergroups;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step2',
            order: 'interactive_demo.upsell_step2_order',
            desc: 'interactive_demo.upsell_step2_desc',
            next: 'interactive_demo.upsell_step2_next',
            tooltipPosition: 'top',
            nextStep: 'step3',
            targetEle: '.js_goals_done',
            initStepData: function() {
                (this as any).currentSetting = 'goals';
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.defaultData.goals;
                (this as any).campaign.usergroups = data.defaultData.usergroups;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step3',
            order: 'interactive_demo.upsell_step3_order',
            desc: 'interactive_demo.upsell_step3_desc',
            next: 'interactive_demo.upsell_step3_next',
            nextStep: 'step4',
            targetEle: '.js_usergroup_edit',
            initStepData: function() {
                (this as any).currentSetting = null;
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.upsellOffers.goals;
                (this as any).campaign.usergroups = data.defaultData.usergroups;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step4',
            order: 'interactive_demo.upsell_step4_order',
            desc: 'interactive_demo.upsell_step4_desc',
            next: 'interactive_demo.upsell_step4_next',
            tooltipPosition: 'top',
            nextStep: 'step5',
            targetEle: '.js_usergroup_done',
            initStepData: function() {
                console.log('step4 init data ---->');
                (this as any).state.isEdited = false;
                (this as any).currentSetting = 'usergroup';
                (this as any).campaign.goals = data.upsellOffers.goals;
                (this as any).campaign.usergroups = data.defaultData.usergroups;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            }
        },
        {
            key: 'step5',
            order: 'interactive_demo.upsell_step5_order',
            desc: 'interactive_demo.upsell_step5_desc',
            next: 'interactive_demo.upsell_step5_next',
            nextStep: 'step6',
            targetEle: '.js_go_editor',
            initStepData: function() {
                (this as any).currentSetting = null;
                (this as any).state.isEdited = false;
                (this as any).campaign.goals = data.upsellOffers.goals;
                (this as any).campaign.usergroups = data.upsellOffers.usergroups;
                (this as any).campaign.baseURL = interactiveDemoService.getDemoProfile().baseURL;
            },
            handleNextClick: function() {}
        },
        {
            key: 'step6',
            order: 'interactive_demo.upsell_step6_order',
            desc: 'interactive_demo.upsell_step6_desc',
            next: 'interactive_demo.upsell_step6_next',
            initStepData: function() {},
            handleNextClick: function() {}
        },
        {
            key: 'step7',
            order: 'interactive_demo.upsell_step7_order',
            desc: 'interactive_demo.upsell_step7_desc',
            next: 'interactive_demo.upsell_step7_next',
            initStepData: function() {},
            handleNextClick: function() {}
        },
        {
            key: 'step8',
            order: 'interactive_demo.upsell_step8_order',
            desc: 'interactive_demo.upsell_step8_desc',
            next: 'interactive_demo.upsell_step8_next',
            targetEle: '.js_campaign_save',
            initStepData: function() {
                (this as any).state.isEdited = true;
            },
            handleNextClick: function() {}
        },
        {
            key: 'step9',
            order: 'interactive_demo.upsell_step9_order',
            desc: 'interactive_demo.upsell_step9_desc',
            next: 'interactive_demo.upsell_step9_next',
            end: true,
            tooltipStyle: {
                padding: '32px',
                width: '400px',
                top: '300px',
                right: '20px'
            },
            initStepData: function() {
                const countMeta = {
                    All: 1,
                    Live: 1,
                    Paused: 0,
                    Draft: 2
                };
                store.commit('engage/SET_COUNT_META', countMeta);
            },
            handleNextClick: function() {}
        }
    ]
};

const demoProfiles = {
    devDemoProfile: {
        sid: '53ct40mr',
        name: 'Ptengine Demo',
        type: null,
        domain: 'demox.ptengine.jp',
        timeZone: 'Asia/Tokyo',
        profileSource: 'ptx',
        createTime: 1606486556108,
        updateTime: 1606486695698,
        status: 1,
        isActived: 1,
        baseURL: 'http://demox.ptengine.jp/index-dev.html'
    },
    devEnDemoProfile: {
        sid: '48evuv19',
        name: 'Ptengine Demo',
        type: null,
        domain: 'demox.ptengine.com',
        timeZone: 'Asia/Tokyo',
        profileSource: 'ptx',
        createTime: 1606486556108,
        updateTime: 1606486695698,
        status: 1,
        isActived: 1,
        baseURL: 'http://demox.ptengine.com/index-dev-en.html'
    },
    prodDemoProfile: {
        sid: '21gxxpqu',
        name: 'Ptengine Demo',
        type: null,
        domain: 'ptengine.jp',
        timeZone: 'Asia/Tokyo',
        profileSource: 'ptx',
        createTime: 1606752810398,
        updateTime: 1606752810398,
        status: 1,
        isActived: 1,
        baseURL: 'https://demox.ptengine.jp/'
    },
    prodEnDemoProfile: {
        sid: '21gxxpqu',
        name: 'Ptengine Demo',
        type: null,
        domain: 'ptengine.com',
        timeZone: 'Asia/Tokyo',
        profileSource: 'ptx',
        createTime: 1606752810398,
        updateTime: 1606752810398,
        status: 1,
        isActived: 1,
        baseURL: 'https://demox.ptengine.com/index-en.html'
    }
};

const renderCampaign = {
    id: '1606461148116846',
    engageVersion: '3.0',
    baseURL: 'http://datatest11.ptmind.com/pte_ptx/index.html',
    screenshotEn: 'https://testptxengagepic.ptengine.jp/1618492205056-f818c452-0d6b-4cc3-9eab-5b173f60ef16.jpeg',
    screenshot: 'https://ptxengagepic.ptengine.jp/1606753280691-0f7972eb-bc6c-4413-b341-d527bcc7b08b.jpeg',
    name: 'Promo Widget(7)',
    status: 1 as 0 | 2 |1,
    isRendered: true,
    abtestType: "",
    createTime: 0,
    updateTime: 0,
    audiences: [
        {
            id: '8e38427f-750a-4051-8456-9eeb4c03e6d9',
            source: 'default',
            name: '全てのユーザー',
            content: {},
            isInvalid: false
        }
    ],

    goals: data.customerAcquisition.goals,
    schedule: [
        {
            versionData: [],
            versionMeta: {
                start: '1',
                stop: '1',
                delivery: '1',
                startDate: 1606492800000,
                stopDate: 1606492800000,
                time: ['00:00', '23:59'],
                week: [
                    { status: true, code: 'sun' },
                    { status: true, code: 'mon' },
                    { status: true, code: 'tue' },
                    { status: true, code: 'wed' },
                    { status: true, code: 'thu' },
                    { status: true, code: 'fri' },
                    { status: true, code: 'sat' }
                ]
            },
            timezone: { profile: 'Asia/Tokyo', useVisitor: false }
        }
    ] as Schedule[],
    conditions: [{ when: <any>[], session: { operator: 'and', children: [] } }],
    versions: [
        {
            id: '1606461148117151',
            name: 'Variation 1',
            rate: 100,
            controlGroup: false,
            status: 0,
            terminals: [
                { id: '6ebdc92e9361acee', type: 2, status: 1, popup: [], stickybar: [], inline: [] },
                { id: '24449a85850a01b5', type: 1, status: 0, popup: [], stickybar: [], inline: [] }
            ] as Terminal[]
            //versionHasError: false
        }
    ] as Version[],
    hasVariant: false,
    hasForm: false,
    isVersionEvent: false,
    reportData: {
        count: [
            {
                columnKey: 'engageView',
                columnName: 'engagement.impression',
                needDemo: true,
                description: { value: 'engagement.engage_help_views', rate: 'engagement.engage_help_views' },
                mainView: 'value',
                index: 0,
                positive: 1,
                value: 0,
                pastvalue: 0,
                trend: '--'
            },
            {
                columnKey: 'engageClick',
                columnName: 'engagement.click',
                description: { value: 'engagement.engage_help_click', rate: 'engagement.engage_help_ctr' },
                mainView: 'rate',
                index: 1,
                positive: 1,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageSubmit',
                columnName: 'engagement.submission',
                description: {
                    value: 'engagement.engage_help_submission',
                    rate: 'engagement.engage_help_submission_rate'
                },
                mainView: 'rate',
                index: 2,
                positive: 1,
                hideForZh: true,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageClose',
                columnName: 'engagement.close',
                description: {
                    value: 'engagement.engage_help_close_count',
                    rate: 'engagement.engage_help_close_rate'
                },
                mainView: 'rate',
                index: 3,
                positive: 0,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageExit',
                columnName: 'engagement.exits',
                description: {
                    value: 'engagement.engage_help_exit_count',
                    rate: 'engagement.engage_help_exit_rate'
                },
                mainView: 'rate',
                index: 4,
                positive: 0,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageGoal',
                columnName: 'engagement.goal_set_title',
                description: { value: 'engagement.engage_help_cv_count', rate: 'engagement.engage_help_cv_rate' },
                mainView: 'rate',
                needDemo: true,
                index: 5,
                positive: 1,
                value: {},
                pastvalue: {},
                rate: {},
                pastrate: {},
                trend: {}
            }
        ],
        userCount: [
            {
                columnKey: 'engageView',
                columnName: 'engagement.impression',
                description: { value: 'engagement.engage_help_views', rate: 'engagement.engage_help_views' },
                mainView: 'value',
                index: 0,
                needDemo: true,
                positive: 1,
                value: 157,
                pastvalue: 0,
                trend: '+'
            },
            {
                columnKey: 'engageClick',
                columnName: 'engagement.click',
                description: { value: 'engagement.engage_help_click', rate: 'engagement.engage_help_ctr' },
                mainView: 'rate',
                index: 1,
                positive: 1,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageClose',
                columnName: 'engagement.close',
                description: {
                    value: 'engagement.engage_help_close_count',
                    rate: 'engagement.engage_help_close_rate'
                },
                mainView: 'rate',
                index: 3,
                positive: 0,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageExit',
                columnName: 'engagement.exits',
                description: {
                    value: 'engagement.engage_help_exit_count',
                    rate: 'engagement.engage_help_exit_rate'
                },
                mainView: 'rate',
                index: 4,
                positive: 0,
                value: 0,
                pastvalue: 0,
                rate: 0,
                pastrate: 0,
                trend: '--'
            },
            {
                columnKey: 'engageGoal',
                columnName: 'engagement.goal_set_title',
                description: { value: 'engagement.engage_help_cv_count', rate: 'engagement.engage_help_cv_rate' },
                mainView: 'rate',
                needDemo: true,
                index: 5,
                positive: 1,
                value: {
                    '3f202781-951c-44e3-81f7-ce14ed9abafa': 29
                },
                pastvalue: {
                    '3f202781-951c-44e3-81f7-ce14ed9abafa': 0
                },
                rate: {
                    '3f202781-951c-44e3-81f7-ce14ed9abafa': 0.18
                },
                pastrate: {
                    '3f202781-951c-44e3-81f7-ce14ed9abafa': 0
                },
                trend: {
                    '3f202781-951c-44e3-81f7-ce14ed9abafa': '+'
                }
            },
            {
                columnKey: 'avgVisitTime',
                columnName: 'campaign_report.col_visit_duration',
                description: {
                    value: 'engagement.engage_help_cv_count',
                    rate: 'engagement.engage_help_cv_rate'
                },
                dataType: 'timestamp',
                mainView: 'value',
                index: 4,
                positive: 1,
                value: '02:18',
                trend: '--'
            },
            {
                columnKey: 'sessionViewDepth',
                columnName: 'campaign_report.col_session_depth',
                description: {
                    value: 'engagement.engage_help_cv_count',
                    rate: 'engagement.engage_help_cv_rate'
                },
                mainView: 'value',
                index: 4,
                positive: 1,
                value: 3.6,
                trend: '--'
            },
            {
                columnKey: 'bounceRate',
                columnName: 'campaign_report.col_bounce_rate',
                description: {
                    value: 'engagement.engage_help_cv_count',
                    rate: 'engagement.engage_help_cv_rate'
                },
                mainView: 'rate',
                positive: 0,
                rate: 0.5382,
                trend: '--'
            },
        ]
    }
};

export default {
    demoProfiles,
    renderCampaign,
    steps,
    data
};
