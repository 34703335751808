<template>
    <div class="pt-tabs" :class="['pt-tabs--' + theme, 'pt-tabs--' + size]">
        <div class="pt-tabs__nav">
            <div
                class="pt-tabs__item"
                v-for="(tab, $index) in tabs"
                :key="tab[label]"
                :class="{ 'is-active': currentActiveLabel === tab[label] }"
                :tab="tab[label]"
                @click="handleTabClick($event, tab, $index)"
            >
                <slot v-bind:tab="tab" v-bind:index="$index">
                    <span>{{ tab[name] }}</span>
                </slot>
            </div>
        </div>
        <div class="pt-tabs__active-bar" :style="{ width: splitDom.width + 'px', left: splitDom.left + 'px' }"></div>
    </div>
</template>

<script>
export default {
    name: 'ptTabs',

    props: {
        tabs: {
            type: Array,
            default: function() {
                return [];
            }
        },
        label: {
            type: String,
            default: 'code'
        },
        name: {
            type: String,
            default: 'name'
        },
        size: {
            type: String,
            enum: ['default', 'large']
        },
        value: String,
        theme: {
            type: String,
            default: 'blue',
            enum: ['gray', 'blue', 'green']
        }
    },

    data() {
        return {
            currentActiveLabel: this.value,
            splitDom: {
                width: 0,
                left: 0
            }
        };
    },

    mounted() {
        if (this.currentActiveLabel) {
            const currentActiveDom = this.getActiveDom(this.currentActiveLabel);
            this.setSplitDom(currentActiveDom);
        }
    },

    methods: {
        handleTabClick(event, tab, index) {
            this.currentActiveLabel = tab[this.label];
            this.setSplitDom(event.target);
            this.$emit('change', tab);
        },

        getActiveDom(label) {
            return this.$el.querySelector(`[tab=${label}]`);
        },

        setSplitDom(dom) {
            this.$set(this.splitDom, 'width', dom.offsetWidth);
            this.$set(this.splitDom, 'left', dom.offsetLeft);
        }
    },

    watch: {
        value(val) {
            this.currentActiveLabel = val;
            const currentActiveDom = this.getActiveDom(this.currentActiveLabel);
            this.setSplitDom(currentActiveDom);
        }
    }
};
</script>

<style lang="scss">
@import '../../../styles/import.scss';

@mixin theme($color) {
    .pt-tabs__item {
        &:hover,
        &.is-active {
            /* color: $color; */
        }
    }
    .pt-tabs__active-bar {
        background-color: $color;
    }
}

.pt-tabs {
    display: flex;
    height: 34px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    transition: left cubic-bezier(0, 0.7, 0.25, 1) 1s;
    &--large {
        height: 48px;
        .pt-tabs__nav {
            padding: 0;
        }
        .pt-tabs__item {
            margin: 0;
            font-size: 14px;

            font-weight: 500;
            a {
                padding: 0 20px;
                line-height: 48px;
                color: $pt-black-300;
            }
            &.is-active {
                color: $pt-black-900;
                a {
                    color: $pt-black-900;
                }
            }
        }
        .pt-tabs__active-bar {
            height: 4px;
        }
    }
    &--default {
        height: 56px;
        .pt-tabs__nav {
            padding: 0;
            border-bottom: none;
        }
        .pt-tabs__item {
            font-size: 13px;

            font-weight: 500;
            a,
            span {
                padding: 0 12px;
                line-height: 56px;
                color: $pt-black-300;
            }
            &.is-active {
                color: $pt-black-900;
                a,
                span {
                    color: $pt-black-900;
                }
            }
        }
        .pt-tabs__active-bar {
            height: 2px;
        }
    }

    &__nav {
        padding: 0 20px;
        display: flex;
        border-bottom: 1px solid #dfe7f5;
    }

    &__active-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        background-color: #00a92e;
        z-index: 1;
        transition: 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
        list-style: none;
    }

    &__item {
        line-height: 15px;
        height: 100%;
        margin: 0 16px;
        color: $pt-black-300;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        font-weight: 500;
        span {
            padding: 8px;
        }

        a {
            padding: 8px;
            color: $pt-black-300;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &.is-active {
            color: $pt-black-900;
        }

        &.is-active {
            font-weight: 500;
        }
    }

    //************theme******************
    &--gray {
        @include theme($pt-black-900);
    }

    &--blue {
        @include theme($pt-green-60);
    }

    &--green {
        @include theme($pt-green-60);
    }
}
</style>
