"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeStyle = exports.addCursorStyleToBody = exports.addStyleToHead = void 0;
var constants_1 = require("./constants");
var verticalWrapperClass = {
    'overflow': 'hidden',
    'display': 'block'
};
var horizontalWrapperClass = {
    'height': '100%',
    'display': 'table-cell',
    'vertical-align': 'top',
};
var stretcherElementHorizontalClass = {
    'display': 'inline-block'
};
var css = (_a = {},
    _a[".".concat(constants_1.containerClass)] = {
        'position': 'relative',
        'min-height': '30px',
        'min-width': '30px'
    },
    _a[".".concat(constants_1.containerClass, ".horizontal")] = {
        'display': 'table',
    },
    _a[".".concat(constants_1.containerClass, ".horizontal > .").concat(constants_1.stretcherElementClass)] = stretcherElementHorizontalClass,
    _a[".".concat(constants_1.containerClass, ".horizontal > .").concat(constants_1.wrapperClass)] = horizontalWrapperClass,
    _a[".".concat(constants_1.containerClass, ".vertical > .").concat(constants_1.wrapperClass)] = verticalWrapperClass,
    _a[".".concat(constants_1.wrapperClass)] = {
        'box-sizing': 'border-box'
    },
    _a[".".concat(constants_1.wrapperClass, ".horizontal")] = horizontalWrapperClass,
    _a[".".concat(constants_1.wrapperClass, ".vertical")] = verticalWrapperClass,
    _a[".".concat(constants_1.wrapperClass, ".animated")] = {
        'transition': 'transform ease',
    },
    _a[".".concat(constants_1.ghostClass)] = {
        'box-sizing': 'border-box',
        // 'background-color': 'transparent',
        // '-webkit-font-smoothing': 'subpixel-antialiased'
    },
    _a[".".concat(constants_1.ghostClass, ".animated")] = {
        'transition': 'all ease-in-out'
    },
    _a[".".concat(constants_1.ghostClass, " *")] = {
        'pointer-events': 'none'
    },
    _a[".".concat(constants_1.disbaleTouchActions, " *")] = {
        'touch-action': 'none',
        '-ms-touch-action': 'none'
    },
    _a[".".concat(constants_1.noUserSelectClass)] = {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    _a[".".concat(constants_1.dropPlaceholderInnerClass)] = {
        'flex': '1'
    },
    _a[".".concat(constants_1.containerClass, ".horizontal > .").concat(constants_1.dropPlaceholderWrapperClass)] = {
        'height': '100%',
        'overflow': 'hidden',
        'display': 'table-cell',
        'vertical-align': 'top',
    },
    _a[".".concat(constants_1.containerClass, ".vertical > .").concat(constants_1.dropPlaceholderWrapperClass)] = {
        'overflow': 'hidden',
        'display': 'block',
        'width': '100%',
    },
    _a[".".concat(constants_1.dropPlaceholderFlexContainerClass)] = {
        'width': '100%',
        'height': '100%',
        'display': 'flex',
        'justify-content': 'stretch',
        'align-items': 'stretch'
    },
    _a[".".concat(constants_1.dropPlaceholderDefaultClass)] = {
        'background-color': 'rgba(150, 150, 150, 0.1)',
        'border': '1px solid #ccc',
    },
    _a);
function convertToCssString(css) {
    return Object.keys(css).reduce(function (styleString, propName) {
        var propValue = css[propName];
        if (typeof (propValue) === 'object') {
            return "".concat(styleString).concat(propName, "{").concat(convertToCssString(propValue), "}");
        }
        return "".concat(styleString).concat(propName, ":").concat(propValue, ";");
    }, '');
}
function addStyleToHead() {
    if (typeof (window) !== 'undefined') {
        var head = window.document.head || window.document.getElementsByTagName("head")[0];
        var style = window.document.createElement("style");
        style.id = 'smooth-dnd-style-definitions';
        var cssString = convertToCssString(css);
        style.type = 'text/css';
        if (style.styleSheet) {
            style.styleSheet.cssText = cssString;
        }
        else {
            style.appendChild(window.document.createTextNode(cssString));
        }
        head.appendChild(style);
    }
}
exports.addStyleToHead = addStyleToHead;
function addCursorStyleToBody(cursor) {
    if (cursor && typeof (window) !== 'undefined') {
        var head = window.document.head || window.document.getElementsByTagName("head")[0];
        var style = window.document.createElement("style");
        var cssString = convertToCssString({
            'body *': {
                cursor: "".concat(cursor, " !important")
            }
        });
        style.type = 'text/css';
        if (style.styleSheet) {
            style.styleSheet.cssText = cssString;
        }
        else {
            style.appendChild(window.document.createTextNode(cssString));
        }
        head.appendChild(style);
        return style;
    }
    return null;
}
exports.addCursorStyleToBody = addCursorStyleToBody;
function removeStyle(styleElement) {
    if (styleElement && typeof (window) !== 'undefined') {
        var head = window.document.head || window.document.getElementsByTagName("head")[0];
        head.removeChild(styleElement);
    }
}
exports.removeStyle = removeStyle;
