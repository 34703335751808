<template>
    <div :class="$style.goals_empty">
        <div :class="$style.banner">
            <div :class="$style.topic"></div>
            <div :class="$style.title">{{ $t('goals.empty_title') }}</div>
            <div :class="$style.desc">{{ $t('goals.empty_description') }}</div>
            <el-tooltip :disabled="canSetGoal">
                <div :class="$style.footer">
                    <pt-button size="medium" :disabled="!canSetGoal" @click="handleClick">
                        {{ $t('goals.set_goal') }}
                    </pt-button>
                </div>

                <div class="no-access-tips" slot="content">
                    <span>{{ $t('user_setting.goal_no_access') }}</span>
                    <a @click="goToUserSettings" v-if="!isSingleSpa">{{ $t('user_setting.check_access') }}</a>
                </div>
            </el-tooltip>
        </div>
    </div>
</template>
<script>
import analyticsService from '@/common/services/analytics.service';
import { changeAppRouter, checkModuleRole, isSingleSpa } from '@/common/services/singleSpa.service';

export default {
    name: 'GoalsEmpty',

    data() {
        return {
            canSetGoal: checkModuleRole('goalAdd'),
            isSingleSpa: isSingleSpa()
        };
    },

    methods: {
        handleClick() {
            analyticsService.usePTX('goal_add_click', { position: 'goal_onbo' }).track();
            this.$router.push({ name: 'GoalsCreate', params: { from: 'GoalsEmpty' } });
        },

        goToUserSettings() {
            changeAppRouter('MemberManage');
        }
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
:global {
    .no-access-tips {
        a {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.goals_empty {
    .banner {
        width: 100%;
        height: 100vh;
        background-color: $pt-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media-sm {
            .topic {
                height: 150px;
            }
        }

        .topic {
            width: 57.58%;
            max-width: 730px;
            height: 230px;
            // margin-top: 80px;
            background: url(~@assets/images/goal/GoalEmpty.png) no-repeat center center;
            background-size: contain;
        }
        .title {
            width: 80%;
            color: $pt-black-900;
            text-align: center;
            margin: 40px auto 15px;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 36px;
        }
        .desc {
            width: 80%;
            max-width: 640px;
            text-align: center;
            color: $pt-black-900;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20px;
        }
        .footer {
            margin: 18px 0 0;

            button {
                width: 288px;
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 80px;
        grid-row-gap: 30px;
        margin: 40px auto 0;
        padding-bottom: 120px;

        .item {
            display: flex;
            flex-direction: column;

            .item_icon {
                svg {
                    fill: $pt-green-60;
                }
            }
            .item_title {
                margin-top: 18px;
                // height: 22px;
                color: $pt-black-600;
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
            }
            .item_desc {
                margin-top: 8px;
                font-size: 13px;
                color: $pt-black-600;
                line-height: 18px;
            }
        }
    }
}
</style>
