<template>
    <div :class="$style.accountProfile">
        <div :class="[$style.box, iptError.email && $style.boxError]">
            <div :class="$style.title">{{ $t('account.account_setting.email') }}</div>
            {{ email }}
            <div :class="$style.warning" v-if="iptError.email.hasError">
                <span>{{ iptError.email.errorTips }}</span>
            </div>
        </div>
        <div :class="[$style.box, iptError.email && $style.boxError]">
            <div :class="$style.title">{{ $t('account.account_setting.name') }}</div>
            <pt-input
                size="large"
                v-model="name"
                :disabled="userInfo.signupSource === 'ptengine'"
                :warning="iptError.name.hasError"
                :placeholder="$t('account.account_setting.name')"
                @focus="onFocus('name')"
                @blur="e => checkValue('name', e.target.value)"
            ></pt-input>
            <div :class="$style.warning" v-if="iptError.email.hasError">
                <span>{{ iptError.email.errorTips }}</span>
            </div>
        </div>
        <div :class="$style.box">
            <div :class="$style.title">{{ $t('account.account_setting.language') }}</div>
            <div :class="$style.content">
                <el-select
                    :class="$style.select"
                    v-model="i18n"
                    size="small"
                    :placeholder="$t('common.select_placeholder')"
                    @change="handleI18nChange"
                >
                    <el-option v-for="item in locales" :key="item.key" :label="item.name" :value="item.key"></el-option>
                </el-select>
            </div>
        </div>
        <pt-button
            block
            :class="$style.saveBtn"
            :disabled="iptHasError || userInfoNoChange"
            :loading="saveLoading"
            @click="save"
            >{{ $t('common.button_save') }}</pt-button
        >
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import commonUtils from '@/common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';
import localsService from '@/common/services/locals.service';
import regConfig from '@/common/configs/reg.config';
import localesConfig from '@/common/configs/locales.config';

export default {
    name: 'accountProfileSetting',

    props: {
        userInfo: Object,
        profileInfo: Object
    },

    data() {
        const { email, i18n, name } = this.userInfo || {};
        return {
            email,
            name,
            i18n: i18n.toUpperCase(),
            currentUserInfo: cloneUtils.deep(this.userInfo),
            locales: localesConfig,
            saveLoading: false,
            iptError: {
                email: {
                    hasError: false,
                    errorTips: null
                },
                name: {
                    hasError: false,
                    errorTips: null
                }
            }
        };
    },

    computed: {
        iptHasError() {
            const keys = Object.keys(this.iptError);
            return keys.some(key => this.iptError[key].hasError);
        },

        userInfoNoChange() {
            return isEqual(this.userInfo, this.currentUserInfo);
        }
    },

    methods: {
        handleI18nChange(val) {
            this.currentUserInfo.i18n = val;
        },

        onFocus(type) {
            this.$set(this.iptError[type], 'hasError', false);
        },

        checkValue(type, value) {
            switch (type) {
                case 'email':
                    if (value) {
                        this.currentUserInfo.email = value;
                        const isEmail = regConfig.isEmail.test(value);
                        isEmail || this.setErrorInfo('email', 'error');
                    } else {
                        this.email = this.currentUserInfo.email;
                    }
                    break;
                case 'name':
                    if (value) {
                        this.currentUserInfo.name = value;
                    } else {
                        this.name = this.currentUserInfo.name;
                    }
                    break;
            }
        },

        checkName(name) {
            this.checkEmail(email);
        },

        checkEmail(email) {},

        /**
         * 存储
         */
        save() {
            if (this.iptHasError) return;
            this.saveLoading = true;
            const _self = this;
            const sendData = {};
            const resolve = function(res) {
                localsService.updateStorageLanguage(res.i18n);
                location.reload();
                _self.saveLoading = false;
            };
            const reject = function() {
                _self.saveLoading = false;
            };
            ['email', 'i18n', 'name'].forEach(key => {
                const newVal = this.currentUserInfo[key];
                const oldVal = this.userInfo[key];
                newVal !== oldVal && (sendData[key] = newVal);
            });
            this.$emit('update', sendData, resolve, reject);
        },

        /**
         * 设置错误提示信息
         */
        setErrorInfo(type, errorType) {
            this.$set(this.iptError[type], 'hasError', true);
            this.$set(this.iptError[type], 'errorTips', this.$t(`account.account_setting.${type}_${errorType}_tips`));
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

$svgWidth: 22px;
$imgWidth: 140px;
$imgWidthHalf: 70px;

.accountProfile {
    .box {
        margin: 20px 74px 0;
        position: relative;

        .title {
            font-size: 12px;
            color: $pt-black-600;
            margin-bottom: 8px;
            font-weight: bold;
        }

        .content {
            .select {
                width: 100%;
            }

            .pt-dropdown {
                width: 100%;

                &__input {
                    width: 100%;
                }

                &__icon {
                    width: 20px !important;
                    right: 0 !important;
                }

                &__inner {
                    width: 100%;
                    border-radius: 4px;
                }

                .pt-ui-search__search-icon:after {
                    top: 2px;
                }

                &__dropdown-wrap-body,
                &__dropdown-body {
                    max-height: 222px;
                }
            }

            .timezone-item {
                @extend %text-ellipsis;
            }
        }
    }

    .boxError {
        margin-bottom: 28px;
    }

    .saveBtn {
        width: 120px;
        margin: 40px auto 0;
    }

    .warning {
        line-height: 1;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: 4px;

        span {
            color: $pt-danger-hover;
            font-size: 12px;
        }
    }

    // media query
    @include media-xs {
        margin: 0 37px;
        flex-direction: column;

        .info {
            flex: 1;
            margin-right: 0;

            .img {
                width: 100px;
                height: 100px;
                margin: 0 auto;
            }

            .name,
            .email {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .other {
            padding-top: 18px;

            .box + .pt-ui-button {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
</style>
