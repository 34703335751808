import resourceWrapper from './resourceWrapper';
const resources = [
    {
        name: 'getGoals',
        query: `query getGoals($where: GoalListWhereUniqueInput!){
            goals(where:$where) {
                id
                name
                content              
                description
                engagements
                currentResult
                lastResult
            }
        }`
    },
    {
        name: 'getGoalsConfig',
        query: `query getGoalsConfig($profileId: String!){
            getGoalsConfig(profileId: $profileId) {
                id
                name
                content
                description
                engagements
                updateTime
            }
        }`
    },
    {
        name: 'getGoalsData',
        query: `query getGoalsData($input: GoalListDataInput!){
            getGoalsData(input: $input){
                id,
                currentResult,
                lastResult
            }
        }`
    },
    {
        name: 'getGoal',
        query: `query getGoal($where: GoalWhereUniqueInput!){
            goal(where:$where) {
                id
                name
                content              
                description
                withBandit
            }
        }`
    },
    {
        name: 'createGoal',
        query: `mutation createGoal($data: GoalCreateInput!){
            createGoal(data: $data) {
                id
                content
                name
                description
                createTime
                updateTime
                count
            }
        }`
    },
    {
        name: 'updateGoal',
        query: `mutation updateGoal($data: GoalUpdateInput!, $where: GoalWhereUniqueInput!, $republish: Boolean){
            updateGoal(data: $data, where: $where, republish: $republish) {
                id
                content
                name
                description
            }
        }`
    },
    {
        name: 'deleteGoal',
        query: ` mutation deleteGoal($where: GoalWhereUniqueInput!) {
            deleteGoal(where: $where) {
                id
                name
                count
            }
        }`
    }
];
export default resourceWrapper(resources);
