export default {
    ACCOUNT_ID: 'UID',
    ACCOUNT_EMAIL: 'UEMAIL',
    ACCOUNT_TOKEN: 'UTOKEN',
    SUPER_ACCOUNT: 'SUPERACCOUNT',
    ACCOUNT_I18N: 'LANGUAGE',
    LAST_PROFILE_ID: 'LAST_PROFILE_ID',
    ACCOUNT_SIGNUP_SOURCE: 'USOURCE',
    ANTI_FLICKER_TIP: 'ANTI_FLICKER_TIP',
    GOOGLE_API_KEY: 'AIzaSyC7kf6ze_2w-kmFkETx66pXWWyiAX0HdTk',
    GOOGLE_CLIENT_ID: '793584886590-kv21lcd0q9raf1hq70fvuc39sos1oh3f.apps.googleusercontent.com',
    ENGAGE_EDITOR_TOKEN: 'ptengine_engage_editor_token',
    ENGAGE_CONTROL_GROUP_TOKEN: 'ptengine_engage_control_group',
    CAMPAIGN_EDITOR_NAME: 'ptengine_campaign_editor',
    PREVIEW_TOKEN: 'preview_ptx_token',
    INTERACTIVE_DEMO_FLAG: 'INTERACTIVE_DEMO_PROFILES',
    PROFILE_EDITOR_URL: 'PROFILE_EDITOR_URL',
    COMMON_INTERSECTION_RATIO: 0.2,
    MAX_SEND_LENGTH: 12, // usergroup、goal 最多一次发送多少条数据

    // 引导任务Key
    GUIDE_KEY: {
        USERS: ['anonymous_users', 'known_users', 'loyal_users']
    },

    VIP_PROFILES: {
        maxGoalLen: 1000,
        maxVersionsLen: 15,

        goal: [
            '4ca02132',
            '2c531cb1',
            '43czk01e',
            '57qglb9j',
            '57117aa6',
            '543f078e',
            '60nx3v75',
            '4c5c721d',
            '5e2f5ac6'
        ],
        versions: ['43czk01e', '57qglb9j', '57117aa6'],
        userQueryDayLimit: ['26dx532o', '57117aa6', '566d12f9', '49598h6e']
    }
};
