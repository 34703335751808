<template>
    <div :class="$style.report">
        <page-header size="full" :hasBorder="true">
            <h1 slot="title">
                <span>{{ $t('engagement.goal_set_title') }}</span>
                <el-tooltip :content="$t('annotation.goals_1')">
                    <pt-icon
                        icon="pt-icon--help-new"
                        :icon-style="{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84',
                            margin: '0 0 4px 6px'
                        }"
                    ></pt-icon>
                </el-tooltip>
            </h1>
            <!-- 右侧工具 -->
            <div ref="headerTools" :class="$style['header-tools']" slot="rightTools">
                <!-- 创建按钮 -->
                <verify-package
                    component="icon"
                    privilege-key="CREATE_GOAL"
                    v-role="{
                        type: 'createGoal',
                        role: profileInfo.currentUserRole
                    }"
                    :resolveCallback="createGoal"
                    :rejectCallback="showAuthDialog"
                >
                    <pt-button icon="ptx-icon-add" size="medium" color="blue">{{
                        $t('goals.button_new_goal')
                    }}</pt-button>
                </verify-package>
            </div>
        </page-header>
        <div :class="$style.toolbar">
            <div>
                <pt-input
                    v-model="searchStates.value"
                    prefixIcon="pt-icon--search-v2"
                    ref="searchInput"
                    size="large"
                    type="text"
                    inputStyle="underline"
                    :prefix="true"
                    :clearButton="clearSearch"
                    @focus="searchInputFocus"
                    :placeholder="searchStates.focus ? $t('users.search_placeholder') : ''"
                    @blur="searchInputBlur"
                    @input="debounceHandleInput"
                ></pt-input>
            </div>
            <!-- 时间切换下拉 -->
            <el-dropdown @command="handleDateChange" trigger="click">
                <span :class="$style.timeSelector">
                    {{ dateRange.text }}
                    <pt-icon icon="pt-icon--hollow-arrow-down" :class="$style.arrowIcon"></pt-icon>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="option in timeSelectOptions"
                        :key="'key_metric_' + option.code"
                        :command="option"
                        >{{ option.text }}</el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="x-wrap x-wrap--full">
            <div v-if="!filteredGoalList.length && !loading" :class="$style.noData">
                {{ $t('goals.no_goal_found') }}
            </div>
            <div :class="$style.container" v-loading="loading" element-loading-background="transparent">
                <GoalsReportCard
                    v-for="goal in filteredGoalList"
                    :key="goal.id"
                    :info="goal"
                    :dateRange="dateRange"
                    @updateCurrent="handleUpdateCurrentGoal"
                    @getData="handleGetData"
                    @delete="toggleDialogVisible('deleteGoal', true)"
                ></GoalsReportCard>
            </div>
        </div>
        <el-dialog :title="deleteGoalTitle" :visible.sync="dialogVisible.deleteGoal" width="440px" center>
            <span>{{ deleteGoalTips }}</span>
            <span slot="footer" class="dialog-footer">
                <pt-button
                    type="outline"
                    color="black"
                    size="medium"
                    class="pt-mr10"
                    @click="toggleDialogVisible('deleteGoal', false)"
                >
                    {{ $t('common.button_cancel') }}
                </pt-button>
                <pt-button
                    v-if="currentGoal && !currentGoal.engagements"
                    color="red"
                    size="medium"
                    @click="handleConfirmDelete"
                >
                    {{ $t('common.button_delete') }}
                </pt-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import goalApis from '@/apis/goal.apis';
import dateUtils from '@/common/utils/date.utils';
import commonUtils from '@common/utils/common.utils';
import PageHeader from '@/common/packages/page-header/PageHeader';
import GoalsReportCard from '@/components/goals/report/GoalsReportCard';
import VerifyPackage from '@/common/packages/verify-package/VerifyPackage';
import analyticsService from '@/common/services/analytics.service';
import baseConfig from '@/common/configs/base.config';
import { debounce } from 'throttle-debounce';
import { cloneDeep } from 'lodash-es';

export default {
    name: 'GoalsReport',
    data() {
        const timeSelectOptions = [
            {
                code: 'today',
                text: this.$t('home.time_today'),
                startTime: dateUtils.getMomentTime({
                    num: 0,
                    type: 'day',
                    isStart: false,
                    timezone: pt.global.timezone
                }),
                endTime: dateUtils.getMomentTime({
                    num: 0,
                    type: 'day',
                    isStart: false,
                    timezone: pt.global.timezone
                })
            },
            {
                code: 'past7days',
                text: this.$t('home.time_past7days'),
                startTime: dateUtils.getMomentTime({
                    num: -6,
                    type: 'day',
                    isStart: false,
                    timezone: pt.global.timezone
                }),
                endTime: dateUtils.getMomentTime({
                    num: 0,
                    type: 'day',
                    isStart: false,
                    timezone: pt.global.timezone
                }),
                default: true
            },
            {
                code: 'past30days',
                text: this.$t('home.time_past30days'),
                startTime: dateUtils.getMomentTime({
                    num: -29,
                    type: 'day',
                    isStart: false,
                    timezone: pt.global.timezone
                }),
                endTime: dateUtils.getMomentTime({
                    num: 0,
                    type: 'day',
                    isStart: false,
                    timezone: pt.global.timezone
                })
            }
        ];
        const defaultTimeRange = timeSelectOptions.find(date => date.default);

        const dialogVisible = {
            deleteGoal: false
        };

        return {
            timeSelectOptions,
            dateRange: defaultTimeRange,
            goalList: [],
            getGoalDataList: [], // 准备发送数据请求的goal数组
            currentGoal: null,
            dialogVisible,
            loading: true,
            filteredGoalList: [],
            searchStates: {
                value: '',
                focus: false
            }
        };
    },
    computed: {
        ...mapGetters('main', ['profileInfo', 'packageInfo', 'authDialogVisible']),
        deleteGoalTitle() {
            return this.currentGoal && this.currentGoal.engagements
                ? this.$t('goals.delete_goal_title', { num: this.currentGoal.engagements })
                : this.$t('common.delete_confirm');
        },
        deleteGoalTips() {
            return this.currentGoal && this.currentGoal.engagements
                ? this.$t('goals.delete_goal_tips', { num: this.currentGoal.engagements, goal: this.currentGoal.name })
                : this.$t('goals.delete_goal_tips_no_engagements');
        }
    },
    created() {
        this.debounceHandleInput = debounce(500, this.handleInput);
    },

    mounted() {
        this.fetchGoalList();
    },
    methods: {
        ...mapMutations('main', ['UPDATE_COUNTS', 'SET_AUTH_DIALOG_VISIBLE', 'UPDATE_PACKAGE_USAGE']),
        createGoal() {
            analyticsService.usePTX('goal_add_click', { position: 'goal_list' }).track();
            this.$router.push({ name: 'GoalsCreate' });
        },
        fetchGoalList() {
            goalApis
                .getGoalsConfig(null, {
                    profileId: this.profileInfo.sid
                })
                .then(
                    res => {
                        this.goalList = res;
                        this.filteredGoalList = this.goalList;
                        this.loading = false;
                    },
                    err => {
                        this.loading = false;
                    }
                );
        },
        // 获取goal相关数据
        fetchGoalDataList(list) {
            goalApis
                .getGoalsData(null, {
                    input: {
                        sid: this.profileInfo.sid,
                        startTime: this.dateRange.startTime,
                        endTime: this.dateRange.endTime,
                        goalList: list,
                        sequentialFlag: 1
                    }
                })
                .then(res => {
                    const goalListData = res.map(item => {
                        const { currentResult, lastResult } = item;
                        const rate =
                            lastResult && lastResult.count && lastResult.count > 0
                                ? ((Math.abs(lastResult.count - currentResult.count) / lastResult.count) * 100).toFixed(
                                      2
                                  )
                                : 0;
                        const chartData =
                            currentResult && currentResult.list && this.formatChartData(currentResult.list, name);
                        item.count = currentResult && currentResult.count;
                        item.lastCount = lastResult && lastResult.count;
                        item.trend = this.getTrend(
                            (currentResult && currentResult.count) || 0,
                            (lastResult && lastResult.count) || 0
                        );
                        item.rate = rate;
                        item.chartData = chartData;
                        return item;
                    });
                    this.goalList = this.goalList.map(goal => {
                        const needUpdateGoalData = goalListData.find(item => item.id === goal.id);
                        if (needUpdateGoalData) {
                            goal = {
                                ...goal,
                                ...needUpdateGoalData
                            };
                        }
                        return goal;
                    });
                    this.filteredGoalList = !!this.searchStates.value
                        ? this.goalList.filter(goal =>
                              goal.name.toLowerCase().includes(this.searchStates.value.toLowerCase())
                          )
                        : this.goalList;
                });
        },
        handleGetData(goal) {
            const { id, content } = goal;
            this.getGoalDataList.push({
                id,
                content
            });
            let debounceFunc = debounce(500, () => {
                this.batchGetGoalCellData();
            });
            debounceFunc();
        },
        // 批量获取goal卡片的数据
        batchGetGoalCellData() {
            if (!this.getGoalDataList?.length) return;
            const readySendData = cloneDeep(this.getGoalDataList);
            while (readySendData?.length > 0) {
                this.fetchGoalDataList(readySendData?.splice(0, baseConfig.MAX_SEND_LENGTH));
            }
            this.getGoalDataList = [];
        },
        getTrend(current, last) {
            if (current > last) {
                return 'up';
            } else if (current < last) {
                return 'down';
            } else return '--';
        },
        formatChartData(listData, name) {
            let result = { name: name, data: [] };
            const { startTime, endTime } = this.dateRange;
            const categories =
                startTime === endTime
                    ? Array(24)
                          .fill('')
                          .map((item, index) => {
                              const num = index < 10 ? `0${index}` : index;
                              return `${num}:00`;
                          })
                    : dateUtils.getDays(startTime, endTime);
            if (listData && listData.length > 0) {
                listData.forEach(item => {
                    result.data.push([item.date, item.count]);
                });
            }

            return [result];
        },

        handleDateChange(date) {
            this.dateRange = date;
        },

        toggleDialogVisible(type, visible) {
            this.$set(this.dialogVisible, type, visible);
        },
        handleUpdateCurrentGoal(goal) {
            this.currentGoal = goal;
        },
        async handleConfirmDelete() {
            const [err, data] = await commonUtils.awaitWrap(this.goalDelete());
            if (err) {
                this.$message.error('delete goal failed.');
            } else {
                const index = this.goalList.findIndex(item => item.id === this.currentGoal.id);
                typeof data?.count === 'number' && this.UPDATE_PACKAGE_USAGE({ type: 'goals', count: data.count });
                analyticsService.usePTX('goal_delete').track();
                if (index !== -1) {
                    this.goalList.splice(index, 1);
                    (this.packageInfo?.usage?.goals || 0) <= 0 && this.$router.push({ name: 'GoalsEmpty' });
                }
            }
            this.toggleDialogVisible('deleteGoal', false);
        },
        goalDelete() {
            return goalApis.deleteGoal(null, {
                where: {
                    id: this.currentGoal.id
                }
            });
        },
        showAuthDialog() {
            this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: 'CREATE_GOAL' });
        },
        searchInputFocus() {
            this.searchStates.focus = true;
        },
        searchInputBlur() {
            !!this.searchStates.value && (this.searchStates.focus = false);
        },
        clearSearch() {
            this.searchStates.value = '';
            this.searchInputBlur();
        },
        handleInput(val) {
            this.filteredGoalList = this.goalList.filter(goal => goal.name.toLowerCase().includes(val.toLowerCase()));
        }
    },
    components: {
        PageHeader,
        VerifyPackage,
        GoalsReportCard
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
$cell-width: 320px;
$cell-height: 320px;
$arrow-width: 10px;
$arrow-height: 13px;

:global {
    .overview-chart--tooltip {
        color: $pt-green-60;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        font-weight: 600;
    }
}

.report {
    padding-bottom: $page-content-padding-bottom;

    &-header {
        padding-bottom: 18px !important;
    }
    .timeSelector {
        // color: $pt-green-60;
        cursor: pointer;
        margin-left: 32px;
        .arrowIcon {
            width: 12px !important;
            height: 12px !important;
            margin-left: 6px;
            fill: #344563;
        }
    }
    .header-tools {
        display: flex;
        align-items: center;

        & > svg {
            margin-right: 18px;
            cursor: pointer;
            fill: $pt-black-600;

            &:hover {
                fill: $pt-black-600;
            }
        }
    }
    .toolbar {
        margin-top: -16px;
        height: 52px;
        padding-right: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .btn_new {
        @include button(xlarge);
    }

    .tools {
        margin: 0 $page-content-margin-horizontal;
    }

    .searchBox {
        width: 260px;
    }

    .noData {
        text-align: center;
        margin-top: 20px;
    }

    .steps {
        margin-top: $arrow-height;
        position: relative;

        &-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 $arrow-width $arrow-height $arrow-width;
            border-color: transparent transparent $pt-black-30 transparent;
            position: absolute;
            top: -($arrow-height - 1px);
            left: 20px;
            z-index: 1;
            opacity: 0;

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 $arrow-width - 1px $arrow-height - 1px $arrow-width - 1px;
                border-color: transparent transparent $pt-black-30 transparent;
                position: absolute;
                left: -($arrow-width - 1px);
                top: 1px;
            }

            &.show {
                opacity: 1;
            }
        }

        &-wrap {
            background-color: $pt-black-30;
            padding: 20px $page-content-margin-horizontal 28px;
            border-radius: 4px;
            position: relative;
            border: 1px solid $pt-black-30;

            & > p {
                color: $pt-black-600;
            }
        }

        &-container {
            min-height: 100px;
            margin-top: 18px;
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            grid-auto-columns: 1fr;
            position: relative;

            @include media-lg {
                grid-auto-flow: row;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            fill: $pt-black-90;

            &:hover {
                fill: $pt-black-300;
            }
        }
    }

    .nodata {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
    }

    .box {
        height: 200px;
        padding: 20px 20px 24px;
        background-color: $pt-white;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: auto 100px;
        display: flex;
        flex-direction: column;

        &:hover {
            box-shadow: 0 12px 12px 0 rgba(9, 30, 66, 0.12);
            transform: scale(1.02);

            .box-status .box-status-icon {
                svg {
                    fill: $pt-green-60 !important;
                }
            }
        }

        &-status-icon {
            width: 18px;
            position: relative;

            svg {
                position: absolute;
                top: 3px;
                right: 0px;
            }
        }

        &-anonymous {
            background-image: url('~@assets/images/users/users-a.svg');
        }
        &-known {
            background-image: url('~@assets/images/users/users-b.svg');
        }
        &-loyal {
            background-image: url('~@assets/images/users/users-c.svg');
        }
        &-custom {
            background-image: url('~@assets/images/users/users-add.svg');
            background-position: right 11.53% bottom 30px;
            background-size: auto 60px;

            .box-status {
                display: none;
            }
        }

        &-title {
            color: $pt-black-600;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 29px;
            display: flex;
            align-items: center;

            svg {
                margin-left: 12px;

                &:hover {
                    fill: $pt-green-60 !important;
                }
            }
        }

        &-description {
            flex: 1;
            color: $pt-black-90;
            letter-spacing: 0;
            line-height: 28px;
            padding-top: 20px;
            max-width: 62.2%;

            p {
                font-size: 13px;
                line-height: 19px;
            }

            h2 {
                font-size: 20px;
            }
        }

        &-status {
            height: 32px;
            color: $pt-black-90;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 4px;
                line-height: 20px;
            }
        }
    }

    .all {
        padding: 24px 30px;
        border-radius: 4px;
        margin-top: 52px;
        border: 1px solid $pt-black-40;
        display: flex;
        align-items: center;
        position: relative;
        background-color: $pt-white;

        &:hover {
            box-shadow: 0 4px 4px 0 rgba(9, 30, 66, 0.12);

            .all-icon {
                display: block;
            }
        }

        @include media-lg {
            flex-direction: column;
            align-items: start;

            & > svg {
                display: none;
            }

            &-describe {
                margin: 0 0 6px 0 !important;
                max-width: inherit !important;
            }
        }

        & > svg {
            flex: 0 0 80px;
        }

        &-describe {
            margin: 0 46px 0 30px;
            max-width: 220px;

            h3 {
                color: $pt-black-600;
                font-size: 16px;
                font-weight: 500;
            }

            p {
                color: $pt-black-300;
                font-size: 13px;
                line-height: 20px;
                margin-top: 8px;
            }
        }

        &-data {
            display: flex;
            flex-wrap: wrap;

            p {
                color: $pt-green-60;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 33px;
                white-space: nowrap;

                &:not(:last-child) {
                    margin-right: 36px;
                }

                span {
                    color: $pt-black-300;
                    font-size: 13px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 19px;
                }
            }
        }

        &-icon {
            display: none;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .container {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($cell-width, 1fr));
        grid-template-rows: repeat(auto-fill, minmax($cell-height, 1fr));
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        position: relative;
    }
}
</style>
