import axios from 'axios';
import store from '@/store';
import axiosCancel from 'axios-cancel';
import baseConfig from '@/common/configs/base.config';
import userSerice from '@/common/services/user.service';
import cookieUtils from '@/common/utils/cookie.utils';
import uuidUtils from '@/common/utils/uuid.utils';

axiosCancel(axios, {
    debug: false // default
});

axios.interceptors.request.use(
    function(config) {
        const { ACCOUNT_TOKEN, ACCOUNT_ID, LAST_PROFILE_ID } = baseConfig;
        const token = cookieUtils.getItem(ACCOUNT_TOKEN) || localStorage.getItem(ACCOUNT_TOKEN);
        const uid = localStorage.getItem(ACCOUNT_ID);
        const sid = localStorage.getItem(LAST_PROFILE_ID);
        token && (config.headers['Authorization'] = `Bearer ${token}`);
        uid && (config.headers['uid'] = uid);
        sid && (config.headers['sid'] = sid);
        config.headers['TraceId'] = uuidUtils.uuid();

        config.cancelToken = new axios.CancelToken(cancel => {
            store.commit('main/PUSH_QUERY_TOKEN', { cancelToken: cancel });
        });
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        // Session invalid
        const { response } = error || {};
        if (response && response.status === 403) {
            userSerice.logout();
            return;
        }
        return Promise.reject(error);
    }
);
