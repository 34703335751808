<template>
    <pt-popup-v2 width="460px" v-if="visible" :show-close="false">
        <div :class="$style.auth_dialog">
            <div :class="$style.title">
                {{ $t('error.app_ver_expire') }}
            </div>
            <pt-button size="medium" @click="handleClick">{{ $t('common.button_refresh') }}</pt-button>
        </div>
    </pt-popup-v2>
</template>

<script>
export default {
    name: 'RefreshApp',

    data() {
        return {
            visible: false
        };
    },

    created() {
        this.$eventBus.$on('appVersionExpire', this.showPopup);
    },

    beforeDestroy() {
        this.$eventBus.$off('appVersionExpire', this.showPopup);
    },

    methods: {
        showPopup() {
            this.visible = true;
        },

        handleClick() {
            window.location.reload();
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';
.auth_dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $pt-black-600;
    padding: 68px 0 60px;

    .title {
        font-size: 16px;
        line-height: 28px;
        width: 100%;
        font-weight: 600;
        text-align: center;
        margin-bottom: 36px;
    }
}
</style>
