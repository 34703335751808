"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var number_utils_1 = require("@/common/utils/number.utils");
var Skeleton_vue_1 = require("@/components/commons/Skeleton.vue");
var users_apis_1 = require("@/apis/users.apis");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'UsersReportAllPeopleCard',
    props: ['data', 'profileInfo'],
    setup: function (props, _a) {
        var emit = _a.emit;
        var allPeopleData = (0, composition_api_1.ref)(props.data);
        (0, composition_api_1.onMounted)(function () {
            handleLoadData();
        });
        return {
            allPeopleData: allPeopleData,
            numberUtils: number_utils_1.default
        };
        function handleLoadData() {
            var _a = props.profileInfo, sid = _a.sid, timeZone = _a.timeZone;
            var _b = allPeopleData.value, id = _b.id, content = _b.content;
            var contentList = [
                {
                    id: id,
                    children: content.children,
                    operator: content.operator
                }
            ];
            users_apis_1.default
                .getUserGroupData(null, {
                input: {
                    sid: sid,
                    timeZone: timeZone,
                    contentList: contentList
                }
            })
                .then(function (res) {
                if (!res[0])
                    return;
                // const { users, actives, newActives, id } = res[0];
                allPeopleData.value = __assign(__assign({}, allPeopleData.value), res[0]);
                emit('updateData', res[0]);
            });
        }
    },
    components: {
        Skeleton: Skeleton_vue_1.default
    }
});
