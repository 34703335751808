<template>
    <div class="block-settings">
        <div class="block-settings__header">
            <pt-button type="text" color="black" size="medium" @click="handleBack">{{ $t('common.button_back') }}</pt-button>
            <h3>{{ $t(block.blockName) }}</h3>
        </div>

        <div class="block-settings__container">
            <div class="block-settings__item-wrap">
                <div class="block-settings__item">
                    <div class="block-settings__item-title">
                        {{ $t('creative_editor.item_spacing') }}
                    </div>
                    <div class="block-settings__item-container flex-layout">
                        <el-slider 
                            class="block-slider pt-ml10 pt-mr20"
                            v-model="settings.height" 
                            :show-tooltip="false"
                            :min="heightOptions.min" 
                            :max="heightOptions.max"
                            @change="handleSettingsChange">
                        </el-slider>
                        <pt-input-number 
                            size="large"
                            v-model="settings.height" 
                            :min="heightOptions.min" 
                            :max="heightOptions.max"
                            @change="handleSettingsChange">
                        </pt-input-number>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from './spacing.config';

export default {
    name: 'SpacingSettings',

    props: {
        block: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            heightOptions: config.heightOptions,
            settings: this.block.blockSettings
        };
    },

    methods: {
        handleBack() {
            this.$emit('back');
        },
        
        handleSettingsChange() {
            this.$emit('settingsChange', this.settings);
        }
    },

    watch: {
        'block.blockId': function() {
            this.settings = this.block.blockSettings;
        }
    }
};
</script>