import baseConfig from '@/common/configs/base.config';
import graphqlApis from '@/apis/graphql';
import deviceInfo from '@/common/utils/device.utils';

function isPromise(ret) {
    return ret && typeof ret.then === 'function' && typeof ret.catch === 'function';
}

const errorHandler = (error, vm, info) => {
    const { ACCOUNT_ID, LAST_PROFILE_ID } = baseConfig;
    const { href } = window.location;
    const uid = localStorage.getItem(ACCOUNT_ID);
    const sid = localStorage.getItem(LAST_PROFILE_ID);
    const params = {
        operationName: 'errorLog',
        query: `mutation errorLog($data: ErrorLogCreateUniqueInput!){
            errorLog(data: $data)
        }`,
        variables: {
            data: {
                sid,
                uid,
                href,
                info,
                deviceInfo: JSON.stringify(deviceInfo),
                error: error.toString()
            }
        }
    };

    // 排除主动cancel的日志
    if (params.variables.data.error.indexOf('RequestManager.cancelRequest') !== -1) return Promise.reject(error);

    graphqlApis.graphql(null, params);
    console.log(`Error: ${error.toString()}, uid: ${uid}, sid: ${sid}, info: ${info}, href: ${href}`);
    return Promise.reject(error);
};

function registerActionHandle(actions) {
    Object.keys(actions).forEach(key => {
        let fn = actions[key];
        actions[key] = function(...args) {
            let ret = fn.apply(this, args);
            if (isPromise(ret)) {
                return ret.catch(errorHandler);
            } else {
                // 默认错误处理
                return ret;
            }
        };
    });
}
const registerVuex = instance => {
    if (instance.$options['store']) {
        let actions = instance.$options['store']['_actions'] || {};
        if (actions) {
            let tempActions = {};
            Object.keys(actions).forEach(key => {
                tempActions[key] = actions[key][0];
            });
            registerActionHandle(tempActions);
        }
    }
};
const registerVue = instance => {
    if (instance.$options.methods) {
        let actions = instance.$options.methods || {};
        if (actions) {
            registerActionHandle(actions);
        }
    }
};

let VueError = {
    install: (Vue, options) => {
        /**
         * 全局异常处理
         * @param {*} error
         * @param {*} vm
         */
        console.log('VueErrorInstallSuc');
        Vue.config.errorHandler = errorHandler;
        // Vue.config.warnHandler = errorHandler;
        Vue.mixin({
            beforeCreate() {
                // registerVue(this); // 有循环报错问题 先注掉
                // registerVuex(this);  // 有循环报错问题 先注掉
            }
        });
        Vue.prototype.$throw = errorHandler;
    }
};

export default VueError;
