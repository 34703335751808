<template>
    <div class="block-settings__item">
        <div class="block-settings__item-title two-cloumn no-margin">
            <span>{{ title }}</span>

            <div class="title-options">
                <el-switch
                    class="el-switch__pt-small"
                    v-model="currentValue"
                    :width="28"
                    @change="handleChange"
                ></el-switch>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'KitsSwitch',
    props: {
        title: String,
        target: String,
        value: {
            required: true
        }
    },
    data() {
        return {
            currentValue: this.value
        };
    },
    methods: {
        handleChange(val) {
            // console.log('color picker change', val);
            this.$emit('change', val, this.target);
        }
    }
};
</script>