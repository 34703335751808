"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateReportColumns = exports.useReportColumns = void 0;
var campaignTest_apis_1 = require("@/apis/campaignTest.apis");
var index_1 = require("@/store/index");
var useReportColumns = function (where) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _, res;
    var _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                if (!where)
                    where = {};
                if (!(where === null || where === void 0 ? void 0 : where.sid))
                    where.sid = (_c = (_b = index_1.default.state.main) === null || _b === void 0 ? void 0 : _b.profileInfo) === null || _c === void 0 ? void 0 : _c.sid;
                if (!(where === null || where === void 0 ? void 0 : where.aid))
                    where.aid = (_e = (_d = index_1.default.state.main) === null || _d === void 0 ? void 0 : _d.userInfo) === null || _e === void 0 ? void 0 : _e.id;
                return [4 /*yield*/, campaignTest_apis_1.default.getReportColumns({ where: where }, { isSwr: false })];
            case 1:
                _a = (_f.sent()), _ = _a[0], res = _a[1];
                return [2 /*return*/, res === null || res === void 0 ? void 0 : res.campaignReportColumns];
        }
    });
}); };
exports.useReportColumns = useReportColumns;
var updateReportColumns = function (conditions) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _, res;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!conditions || !conditions.campaignReportColumns)
                    return [2 /*return*/, null];
                if (!(conditions === null || conditions === void 0 ? void 0 : conditions.sid))
                    conditions.sid = (_b = index_1.default.state.main.profileInfo) === null || _b === void 0 ? void 0 : _b.sid;
                if (!(conditions === null || conditions === void 0 ? void 0 : conditions.aid))
                    conditions.aid = (_d = (_c = index_1.default.state.main) === null || _c === void 0 ? void 0 : _c.userInfo) === null || _d === void 0 ? void 0 : _d.id;
                return [4 /*yield*/, campaignTest_apis_1.default.updateReportColumns({
                        where: { sid: conditions.sid, aid: conditions === null || conditions === void 0 ? void 0 : conditions.aid },
                        data: { campaignReportColumns: conditions.campaignReportColumns }
                    }, { isSwr: false })];
            case 1:
                _a = _e.sent(), _ = _a[0], res = _a[1];
                return [2 /*return*/, res === null || res === void 0 ? void 0 : res.campaignReportColumns];
        }
    });
}); };
exports.updateReportColumns = updateReportColumns;
