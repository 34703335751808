"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
exports.default = (0, composition_api_1.defineComponent)({
    name: 'Skeleton',
    props: ['customStyle', 'itemStyle', 'variant', 'light'],
    setup: function (props) {
        var computedVariant = (0, composition_api_1.computed)(function () {
            var _a;
            return (_a = props.variant) !== null && _a !== void 0 ? _a : 'caption';
        });
        return {
            computedVariant: computedVariant,
            props: props
        };
    }
});
