import { getDefaultBlock, validatorContent } from '../utils/blocks.util';
import blocksConfig from '@/components/engagement/creative/editor/configs/blocks.config';

export const breakWidgetClickEvent = function(e) {
    const target = e.target || e.srcElement;
    if (target.tagName.toLowerCase() === 'a') {
        if (window.event) {
            window.event.returnValue = false;
        } else {
            e.preventDefault();
        }
    }
};

export const isNull = function(arg) {
    return arg === null;
};

export const isNullOrUndefined = function(arg) {
    return arg == null;
};

/**
 * @params timestampStr 截止日期字符串 格式：2019/10/19 00:00:00
 * 翻译剩余 days hour minute second
 */
export const formatTimeStampToCountdown = function(endtimeStr) {
    if (endtimeStr === undefined) return;
    const endTimeStamp = new Date(endtimeStr).getTime();
    const currentTimeStamp = new Date().getTime();
    const remainTimeStamp = endTimeStamp > currentTimeStamp ? endTimeStamp - currentTimeStamp : 0;
    return {
        days: parseInt(remainTimeStamp / 1000 / 60 / 60 / 24),
        hour: parseInt((remainTimeStamp / 1000 / 60 / 60) % 24),
        minute: parseInt((remainTimeStamp / 1000 / 60) % 60),
        second: parseInt((remainTimeStamp / 1000) % 60)
    };
};

/**
 * 验证当前时间类型为0时是否需要隐藏
 * @param {*} dateConfig { day:2, hour:23, minute:23, second:23}
 * @param {*} key  day || hour || minute || second
 */
export const isHideCurrentTime = function(dateConfig, key) {
    let flag = false;
    switch (key) {
        case 'days':
            flag = dateConfig.days === 0;
            break;
        case 'hour':
            flag = dateConfig.days === 0 && dateConfig.hour === 0;
            break;
        case 'minute':
            flag = dateConfig.days === 0 && dateConfig.hour === 0 && dateConfig.minute === 0;
            break;
        default:
            flag = false;
            break;
    }
    return flag;
};

export const checkProtocol = function(url) {
    const reg = /^(https?:\/\/)/;
    return reg.test(url);
};

/**
 * 此文件放engagement 相关的业务service
 */
/**
 * 包装css 在每个css规则前加一个父类包裹
 * @param {*} css 要处理的css字符串
 * @param {*} id engageId div[data-pt-engage-id="${id}"][data-device="${device}"]
 * @param {*} device 当前设备
 */
export const scopedCSS = function(css, id, device) {
    var re = new RegExp('([^\r\n,{}]+)(,(?=[^}]*{)|s*{)', 'g');
    var prefix = `div[data-pt-engage-id="${id}"]`;
    if (device) {
        prefix = prefix + `[data-device="${device}"]`;
    }
    css = css.replace(re, function(g0, g1, g2) {
        if (g1.match(/^\s*(@media|@.*keyframes|to|from|@font-face|1?[0-9]?[0-9])/)) {
            return g1 + g2;
        }

        if (g1.match(/:scope/)) {
            g1 = g1.replace(/([^\s]*):scope/, function(h0, h1) {
                if (h1 === '') {
                    return '> *';
                } else {
                    return '> ' + h1;
                }
            });
        }

        g1 = g1.replace(/^(\s*)/, '$1' + prefix + ' ');

        return g1 + g2;
    });

    return css;
};

/**
 * 包装html
 * @param {*} htmlString 要处理的html字符串
 * @param {*} id          engageId
 */
export const wrapHtml = function(htmlString, id, device) {
    return `<div data-pt-engage-id="${id}" data-device="${device}" style="width:100%;">${htmlString}</div>`;
};

export const getBlockInfoByTemplate = function(component) {
    let blockCode = component.type;
    if (component.type === 'image+text+button') {
        blockCode = 'classicGroup';
    }
    const blockInfo = blocksConfig.find(item => item.code === blockCode);
    if (blockInfo) {
        const actionType = {
            1: 'url',
            2: 'tel',
            3: 'close'
        };
        const block = getDefaultBlock(blockInfo);
        if (blockCode === 'classicGroup') {
            // block.blockSettings.alignment = 'left';
            block.blockContent.forEach(item => {
                const componentValue = component.value.find(o => o.type === item.code);
                if (item.code === 'text') {
                    // item.value.settings.alignment = componentValue.value.alignment;
                    item.value.content.forEach(c => {
                        c.value.text = componentValue.value.description;
                    });
                    block.blockSettings.alignment = componentValue.value.alignment;
                } else if (item.code === 'button') {
                    const { text, textColor, bgColor, action } = componentValue.value;
                    const { type, linkVal, callVal, isOpenNewWindow } = action;
                    item.value.content.forEach(c => {
                        c.value.text = text;
                        c.value.fontColor = textColor;
                        c.value.backgroundColor = bgColor;
                        c.value.borderColor = null;
                        c.value.action = Object.assign({}, c.value.action, {
                            type: actionType[type.toString()],
                            openNewTab: isOpenNewWindow,
                            tel: callVal,
                            url: linkVal
                        });
                    });
                } else if (item.code === 'image') {
                    const { src, size, float, action } = componentValue.value;
                    const { type, linkVal, callVal, status, isOpenNewWindow } = action;
                    const imgPosition = {
                        up: 'imgUp',
                        down: 'imgBottom',
                        left: 'imgLeft',
                        right: 'imgRight'
                    };
                    item.value.content.forEach(c => {
                        c.value.img = src;
                        c.value.sizeLock = size.isByRatio;
                        c.value.width = size.width;
                        c.value.height = size.height;
                        c.value.action = Object.assign({}, c.value.action, {
                            enable: status,
                            type: actionType[type.toString()],
                            openNewTab: isOpenNewWindow,
                            tel: callVal,
                            url: linkVal
                        });
                    });
                    block.blockSettings.layout = imgPosition[float];
                }
            });
        } else if (blockCode === 'image') {
            const { src, size, action } = component.value;
            const { type, linkVal, callVal, isOpenNewWindow, status } = action;

            block.blockSettings.marginTop = 0;
            block.blockSettings.marginBottom = 0;

            block.blockContent.forEach(item => {
                item.value.img = src;
                item.value.width = size.width;
                item.value.height = size.height;
                item.value.action = Object.assign({}, item.value.action, {
                    enable: status,
                    type: actionType[type.toString()],
                    openNewTab: isOpenNewWindow,
                    tel: callVal,
                    url: linkVal
                });
            });
        }

        // 需要校验内容
        block.blockHasError = validatorContent(block);
        return block;
    }
    return null;
};
