import i18n from '@/assets/i18n';

export const metricConfig = {
    count: [
        {
            columnKey: 'engageView',
            columnName: 'engagement.col_view',
            tips: 'campaign_report.col_view_tip',
            mainView: 'value'
        },
        {
            columnKey: 'engageClick',
            columnName: 'campaign_report.col_click',
            tips: 'campaign_report.col_click_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'engageSubmit',
            columnName: 'campaign_report.col_form_submit',
            tips: 'campaign_report.col_form_submit_tip',
            mainView: 'rate',
            hideForZh: true
        },
        {
            columnKey: 'engageClose',
            columnName: 'campaign_report.col_close',
            tips: 'campaign_report.col_close_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'engageGoal',
            columnName: 'campaign_report.col_goal',
            tips: 'campaign_report.col_goal_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'avgVisitTime',
            columnName: 'campaign_report.col_visit_duration',
            tips: 'campaign_report.col_visit_duration_tip',
            dataType: 'timestamp',
            mainView: 'value'
        },
        {
            columnKey: 'avgTimeUnshow',
            columnName: 'campaign_report.col_visit_duration',
            dataType: 'timestamp',
            mainView: 'value'
        },
        {
            columnKey: 'sessionViewDepth',
            columnName: 'campaign_report.col_session_depth',
            tips: 'campaign_report.col_session_depth_tip',
            mainView: 'value'
        },
        {
            columnKey: 'avgViewDepthUnshow',
            columnName: 'campaign_report.col_session_depth',
            mainView: 'value'
        },
        {
            columnKey: 'bounceRate',
            columnName: 'campaign_report.col_bounce_rate',
            tips: 'campaign_report.col_bounce_rate_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'bounceRateUnshow',
            columnName: 'campaign_report.col_bounce_rate',
            mainView: 'rate'
        }
    ],
    userCount: [
        {
            columnKey: 'engageView',
            columnName: 'campaign_report.col_view',
            tips: 'campaign_report.col_view_tip',
            mainView: 'value'
        },
        {
            columnKey: 'engageClick',
            columnName: 'campaign_report.col_click',
            tips: 'campaign_report.col_click_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'engageSubmit',
            columnName: 'campaign_report.col_form_submit',
            tips: 'campaign_report.col_form_submit_tip',
            mainView: 'rate',
            hideForZh: true
        },
        {
            columnKey: 'engageClose',
            columnName: 'campaign_report.col_close',
            tips: 'campaign_report.col_close_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'engageGoal',
            columnName: 'campaign_report.col_goal',
            tips: 'campaign_report.col_goal_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'avgVisitTime',
            columnName: 'campaign_report.col_visit_duration',
            tips: 'campaign_report.col_visit_duration_tip',
            dataType: 'timestamp',
            mainView: 'value'
        },
        {
            columnKey: 'avgTimeUnshow',
            columnName: 'campaign_report.col_visit_duration',
            dataType: 'timestamp',
            mainView: 'value'
        },
        {
            columnKey: 'sessionViewDepth',
            columnName: 'campaign_report.col_session_depth',
            tips: 'campaign_report.col_session_depth_tip',
            mainView: 'value'
        },
        {
            columnKey: 'avgViewDepthUnshow',
            columnName: 'campaign_report.col_session_depth',
            mainView: 'value'
        },
        {
            columnKey: 'bounceRate',
            columnName: 'campaign_report.col_bounce_rate',
            tips: 'campaign_report.col_bounce_rate_tip',
            mainView: 'rate'
        },
        {
            columnKey: 'bounceRateUnshow',
            columnName: 'campaign_report.col_bounce_rate',
            mainView: 'rate'
        }
    ]
};

export const overviewTableColumns = [
    {
        key: 'name',
        name: 'campaign_report.col_ver_name',
        width: '220px',
        fixed: true,
        sortable: false,
        dataType: 'string'
    },
    { key: 'rate', name: 'campaign_report.col_rate', width: '120px', sortable: false, dataType: 'string' },
    {
        key: 'engageView',
        name: 'campaign_report.col_view',
        width: '120px',
        sortable: false,
        dataType: 'number',
        mainText: 'count'
    },
    {
        key: 'engageClick',
        name: 'campaign_report.col_click',
        width: '136px',
        sortable: false,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count'
    },
    {
        key: 'engageSubmit',
        name: 'campaign_report.col_form_submit',
        width: '184px',
        sortable: false,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count',
        align: 'center'
    },
    {
        key: 'engageGoal',
        name: 'campaign_report.col_goal',
        width: '240px',
        sortable: false,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count'
    },
    {
        key: 'engageClose',
        name: 'campaign_report.col_close',
        width: '130px',
        sortable: false,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count'
    },
    {
        key: 'avgVisitTime',
        name: 'campaign_report.col_visit_duration',
        width: '200px',
        sortable: false,
        dataType: 'number',
        mainText: 'count',
        align: 'center'
    },
    {
        key: 'sessionViewDepth',
        name: 'campaign_report.col_session_depth',
        width: '205px',
        sortable: false,
        dataType: 'number',
        mainText: 'count',
        align: 'center'
    },
    {
        key: 'bounceRate',
        name: 'campaign_report.col_bounce_rate',
        width: '172px',
        sortable: false,
        dataType: 'number',
        mainText: 'rate',
        align: 'center'
    }
] as const;

export const insightTableColumns = [
    {
        key: 'name',
        name: 'campaign_report.col_name',
        width: '220px',
        fixed: true,
        sortable: true,
        dataType: 'string'
    },

    {
        key: 'engageView',
        name: 'campaign_report.col_view',
        width: '120px',
        sortable: true,
        dataType: 'number',
        mainText: 'count'
        // tips: 'campaign_report.col_view_tip'
    },
    {
        key: 'engageClick',
        name: 'campaign_report.col_click',
        width: '180px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count'
        // tips: 'campaign_report.col_click_tip'
    },
    {
        key: 'engageSubmit',
        name: 'campaign_report.col_form_submit',
        width: '184px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count',
        align: 'center'
        // tips: 'campaign_report.col_form_submit_tip'
    },
    {
        key: 'engageGoal',
        name: 'campaign_report.col_goal',
        width: '200px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count'
        // tips: 'campaign_report.col_goal_tip'
    },
    {
        key: 'engageClose',
        name: 'campaign_report.col_close',
        width: '180px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count'
        // tips: 'campaign_report.col_close_tip'
    },
    {
        key: 'avgVisitTime',
        name: 'campaign_report.col_visit_duration',
        width: '150px',
        sortable: true,
        dataType: 'number',
        mainText: 'count',
        align: 'center'
        // tips: 'campaign_report.col_visit_duration_tip'
    },
    {
        key: 'sessionViewDepth',
        name: 'campaign_report.col_session_depth',
        width: '150px',
        sortable: true,
        dataType: 'number',
        mainText: 'count',
        align: 'center'
        // tips: 'campaign_report.col_session_depth_tip'
    },
    {
        key: 'bounceRate',
        name: 'campaign_report.col_bounce_rate',
        width: '150px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        align: 'center'
        // tips: 'campaign_report.col_bounce_rate_tip'
    }
] as const;
export const conditionPatternList: any = {
    '[()]': i18n.t('common.include'),
    '![()]': i18n.t('common.exclude'),
    '[(': i18n.t('common.head_match'),
    ')]': i18n.t('common.end_match'),
    '==': i18n.t('common.exact_match'),
    Rex: i18n.t('common.regex')
};
