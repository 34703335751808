<template>
    <div :class="[$style.demo_bar, 'js_demo_bar_step']">
        <div :class="$style.progress_bar">
            <div
                :class="[$style.progress_item, step.range.includes(currentStep) && $style.isActive]"
                v-for="step in demoProgress"
                :key="step.key"
                @click="switchSteps(step.key)"
            >
                {{ step.name }}
            </div>
        </div>
        <pt-button color="black" @click="showConfirmDialog = true" v-if="currentStep !== 'step9'">{{
            $t('interactive_demo.quit_demo')
        }}</pt-button>
        <pt-popup-v2 width="440px" v-if="showConfirmDialog" @close="showConfirmDialog = false">
            <div :class="$style.dialog_content">{{ $t('interactive_demo.quit_demo_confirm') }}</div>
            <div :class="$style.dialog_footer">
                <pt-button
                    type="outline"
                    color="black"
                    size="medium"
                    :class="$style.dialog_btn"
                    @click="showConfirmDialog = false"
                    >{{ $t('interactive_demo.continue_demo') }}</pt-button
                >
                <pt-button size="medium" @click="confirmLeave">{{ $t('interactive_demo.quit_demo_modal') }}</pt-button>
            </div>
        </pt-popup-v2>

        <!-- TODO: 对应demo悬浮框下其他模块获取焦点问题 -->
        <input
            type="text"
            ref="ipt"
            autofocus="autofocus"
            style="width: 0; border: none; background: transparent; position: relative; left: -9999999px"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import roleUtils from '@/common/utils/role';
import interactiveDemoService from '@/components/interactive-demo/interactive-demo.service';
import PermissionConfig from '@/common/configs/permissions.config';
import analyticsService from '@/common/services/analytics.service';
import permissionUtils from '@/common/utils/permission';
export default {
    name: 'DemoBar',
    data() {
        const demoProgress = [
            {
                key: 'step1',
                name: this.$t('interactive_demo.progress_step1'),
                range: ['step1', 'step2']
            },
            {
                key: 'step3',
                name: this.$t('interactive_demo.progress_step2'),
                range: ['step3', 'step4']
            },
            {
                key: 'step5',
                name: this.$t('interactive_demo.progress_step3'),
                range: ['step5', 'step6', 'step7', 'step8']
            },
            {
                key: 'step9',
                name: this.$t('interactive_demo.progress_step4'),
                range: ['step9']
            }
        ];
        return {
            showConfirmDialog: false,
            demoProgress
            //  currentStep: step || 'step9',
            // demoType: type || 'customerAcquisition'
        };
    },
    computed: {
        ...mapGetters('main', ['profiles']),

        currentStep() {
            return this.$route?.query?.step;
        },
        demoType() {
            return this.$route?.query?.type;
        }
    },
    methods: {
        ...mapActions('main', ['getProfileInfo']),
        confirmLeave() {
            const sid = this.$route.query.rid || this.profiles[0]?.sid;
            ['step5', 'step6', 'step7'].includes(this.currentStep) &&
                this.$eventBus.$emit('hideMask', { key: this.currentStep, name: this.currentStep });
            this.showConfirmDialog = false;
            if (this.demoType == 'upsellOffers') {
                analyticsService.usePTX('demo_quit', { demo_type: 'upsell', quit_step: this.currentStep }).track();
            } else {
                analyticsService.usePTX('demo_quit', { demo_type: 'acquisition', quit_step: this.currentStep }).track();
            }
            sid &&
                (interactiveDemoService.removeLocalstoreSid(sid),
                this.getProfileInfo(sid).then(profile => {
                    roleUtils.changeRouterToCampaignReport();
                }));
        },

        switchSteps(step) {
            console.log('switch steps', step);
            if (step === this.currentStep) return;
            const hasV2Permission = permissionUtils.includePermissionWithStore([PermissionConfig.campaignReportV2]);
            const step9Router = hasV2Permission ? 'CampaignOverview' : 'EngagementReport';
            const routerName = step === 'step9' ? step9Router : 'CampaignCreate';
            this.$router.push({
                name: routerName,
                query: {
                    type: this.demoType,
                    tab: 'live',
                    step: step,
                    rid: this.$route.query?.rid || this.profiles[0]?.sid
                },
                params: {
                    campaignId: 'demo_campaign'
                }
            });
            if (
                (this.currentStep === 'step5' && ['step1', 'step3', 'step2', 'step4'].includes(step)) ||
                ['step6', 'step7'].includes(this.currentStep)
            )
                this.$eventBus.$emit('hideMask', { key: step });
        }
    },

    watch: {
        $route: {
            handler: function() {
                setTimeout(() => {
                    this.$el.click();
                    this.$refs?.ipt?.focus?.();
                }, 400);
            },
            immediate: true
        }
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
.demo_bar {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(270deg, $pt-green-60 0%, $pt-green-60 100%);
    box-shadow: 0 -8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 0 40px;
    display: flex;
    align-items: center;
    z-index: 9999999 !important;

    .progress_bar {
        flex: 1;
        display: flex;
        .progress_item {
            color: $pt-white;
            height: 24px;
            line-height: 18px;
            font-size: 12px;
            min-width: 100px;
            border-radius: 12px;
            padding: 4px 10px;
            background-color: transparent;
            text-align: center;
            cursor: pointer;
            margin-right: 12px;
            &:hover {
                background-color: rgba(9, 30, 66, 0.2);
            }
            &.isActive {
                background-color: rgba(9, 30, 66, 0.2);
            }
        }
    }
}
.dialog_content {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    color: $pt-black-600;
    font-size: 16px;
    text-align: center;
}
.dialog_footer {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 40px;
    .dialog_btn {
        margin-right: 24px;
    }
}
</style>
