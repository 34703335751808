"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDefaultGuideOption = exports.stop = exports.start = void 0;
var isDOMElement_1 = require("./../../utils/isDOMElement");
var body_scroll_lock_1 = require("../body-scroll-lock");
var mask_1 = require("./mask");
var highlight_1 = require("./highlight");
var tooltip_1 = require("./tooltip");
var inGuide = false;
var defaultGuideOptions = {
    clickMaskToStop: false,
    interactive: true,
    clickable: false,
    highlight: true,
    lockScroll: true,
};
var stopFunc;
function start(targetEle, guideOptions, fromResize) {
    var _a;
    if (typeof targetEle === 'string')
        targetEle = document.querySelector(targetEle);
    if (targetEle !== null && !(0, isDOMElement_1.isDOMElement)(targetEle))
        return;
    if (targetEle === null || (highlight_1.highlightEle && !(guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.highlight)))
        (0, highlight_1.deleteHighlight)();
    console.log(tooltip_1.tooltipEle, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.tooltipConfig);
    if (tooltip_1.tooltipEle && !(guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.tooltipConfig))
        (0, tooltip_1.deleteTooltip)();
    if (!guideOptions)
        guideOptions = __assign({}, defaultGuideOptions);
    else
        guideOptions = __assign(__assign({}, defaultGuideOptions), guideOptions);
    (_a = guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.onStart) === null || _a === void 0 ? void 0 : _a.call(guideOptions);
    inGuide = true;
    stopFunc = guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.onStop;
    if (guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.lockScroll)
        (0, body_scroll_lock_1.lock)();
    if (targetEle && guideOptions.highlight)
        (0, highlight_1.createHighlight)(targetEle, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.highlightStyle, fromResize, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.insertDOM).then(function (highlightEle) {
            if (guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.tooltipConfig)
                (0, tooltip_1.createTooltip)(highlightEle || targetEle, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.tooltipConfig, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.insertDOM);
        });
    else if (guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.tooltipConfig)
        (0, tooltip_1.createTooltip)(targetEle, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.tooltipConfig, guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.insertDOM);
    (0, mask_1.createMask)(targetEle, {
        styleObj: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.maskStyle,
        interactive: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.interactive,
        clickable: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.clickable,
        clickMaskToStop: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.clickMaskToStop,
        whiteList: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.whiteList,
        onClickTarget: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.onClickTarget,
        onClickMask: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.onClickMask,
        onClickWhiteList: guideOptions === null || guideOptions === void 0 ? void 0 : guideOptions.onClickWhiteList,
    });
}
exports.start = start;
function stop() {
    (0, body_scroll_lock_1.clearBodyLocks)();
    (0, mask_1.deleteMask)();
    (0, highlight_1.deleteHighlight)();
    (0, tooltip_1.deleteTooltip)();
    stopFunc === null || stopFunc === void 0 ? void 0 : stopFunc();
}
exports.stop = stop;
function setDefaultGuideOption(guideOptions) {
    if (typeof guideOptions !== 'object')
        return;
    defaultGuideOptions = __assign(__assign({}, defaultGuideOptions), guideOptions);
}
exports.setDefaultGuideOption = setDefaultGuideOption;
