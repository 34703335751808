const hasOwnProperty = Object.prototype.hasOwnProperty;
export function hasOwn(obj, key) {
    return hasOwnProperty.call(obj, key);
}

function extend(to, _from) {
    for (let key in _from) {
        to[key] = _from[key];
    }
    return to;
}

export function toObject(arr) {
    var res = {};
    for (let i = 0; i < arr.length; i++) {
        if (arr[i]) {
            extend(res, arr[i]);
        }
    }
    return res;
}

export const getValueByPath = function(object, prop) {
    prop = prop || '';
    const paths = prop.split('.');
    let current = object;
    let result = null;
    for (let i = 0, j = paths.length; i < j; i++) {
        const path = paths[i];
        if (!current) break;

        if (i === j - 1) {
            result = current[path];
            break;
        }
        current = current[path];
    }
    return result;
};

export const throttle = function(fn, delay = 400, scope) {
    let timer;
    return function() {
        let context = scope || this;
        let args = arguments;

        clearTimeout(timer);
        timer = setTimeout(function() {
            fn.apply(context, args);
            timer = null;
        }, delay);
    };
};
