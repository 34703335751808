import permissionUtils from '@/common/utils/permission';

var HANDLER = '_vue_clickpermission_handler';

function bind(el, binding) {
    const { keys, canVisible, canClick, clickCallback } = binding.value;

    // @NOTE: Vue binds directives in microtasks, while UI events are dispatched
    //        in macrotasks. This causes the listener to be set up before
    //        the "origin" click event (the event that lead to the binding of
    //        the directive) arrives at the document root. To work around that,
    //        we ignore events until the end of the "initial" macrotask.
    // @REFERENCE: https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
    // @REFERENCE: https://github.com/simplesmiler/vue-clickaway/issues/8
    var initialMacrotaskEnded = false;
    setTimeout(function() {
        initialMacrotaskEnded = true;
    }, 0);

    el[HANDLER] = function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        initialMacrotaskEnded && typeof clickCallback === 'function' && clickCallback(ev);
    };

    if (keys) {
        const hasPermission = permissionUtils.includePermissionWithStore(keys);
        if (!hasPermission) {
            canVisible === false ? el.classList.add('no-permission') : el.classList.remove('no-permission');
            canClick === false && el.addEventListener('click', el[HANDLER], false);
        } else {
            el.classList.remove('no-permission');
            el.removeEventListener('click', el[HANDLER], false);
        }
    } else {
        throw new Error(`need roles! Like v-permission="{keys: 'xxx', canVisible: false]"`);
    }
}

function unbind(el) {
    el.removeEventListener('click', el[HANDLER], false);
    delete el[HANDLER];
}

export default {
    name: 'permission',
    bind: bind,
    update: function(el, binding) {
        if (binding.value === binding.oldValue) return;
        bind(el, binding);
    },
    unbind: unbind
};
