const hostName = window?.location?.host;
const isDev = hostName && (hostName?.startsWith('devx.') || hostName?.startsWith('localhost'));
const jp_docsHost = isDev ? 'https://devhelp.ptengine.jp/docs/' : 'https://help.ptengine.jp/docs/';
const en_docsHost = 'https://help.ptengine.com/docs/';

const utmTag = '?utm_source=product';

//日本帮助站链接
const jp_docs = {
    install_pte: 'ptx-code-setting',
    install_ptx: 'ptx-code-setting',
    identifyUser: 'ptx-user-identification',
    goal: 'ptx-what-is-goal',
    usergroup: 'ptx-introduction-usergroups',
    campaign: 'ptx-introduction-personalization',
    inline: 'ptx-inline',
    abTest: 'ptx-how-to-do-abtest',
    eventSetting: 'ptx-event-settings',
    faqTrackingMark: 'ptx-faq-tracking-mark',
    gtmCodeSetting: 'ptx-code-setting#2-toc-title',
    gtmReadMe: 'ptx-google-tag-manager-read-me',
    inlineAntiFlickerTag: 'ptx-inline-anti-flicker-tag',
    faqManualSetting: 'ptx-code-setting#1-toc-title',
    GroupCannotSetOut: 'ptx-group-cannot-set-out',
    gtm: 'ptx-google-tag-manager',
    setOut: 'ptx-group-cannot-set-out',
    crossDomain: 'ptx-crossdomain-setting',
    heatmap: 'ptx-work-with-heatmap',
    pathAbsoluteRelative: 'ptx-path-absolute-relative',
    dataSetting: 'ptx-datasetting'
};

//欧美帮助站
const en_docs = {
    install_pte: 'ptx-code-setting',
    install_ptx: 'ptx-code-setting',
    identifyUser: 'ptx-user-identification',
    usergroup: 'ptx-introduction-usergroups',
    campaign: 'ptx-introduction-personalization',
    inline: 'ptx-inline',
    abTest: 'ptx-how-to-do-abtest',
    gtmCodeSetting: 'ptx-code-setting#2-toc-title',
    inlineAntiFlickerTag: 'ptx-inline-anti-flicker-tag',
    faqManualSetting: 'ptx-code-setting#1-toc-title',
    GroupCannotSetOut: 'ptx-group-cannot-set-out',
    gtm: 'ptx-google-tag-manager',
    crossDomain: 'ptx-crossdomain-setting',
    heatmap: 'ptx-work-with-heatmap',
    dataSetting: 'ptx-datasetting'
};


const area = hostName.indexOf('ptengine.com')>0 ? 'en' : 'jp' ;
const docs = area === 'en' ?  en_docs : jp_docs ;
const docsHost = area === 'en' ?  en_docsHost : jp_docsHost ;

Object.keys(docs).forEach(key => (docs[key] = docsHost + docs[key] + utmTag));

export default docs;
