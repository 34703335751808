import EngageSettingStore from '@/components/engagement/setting/setting.store';
import { getCampaignReportRouteName } from '@/components/campaign/campaign.util';

const state = {
    countMeta: null,
    engageInDebug: false,
    engageMenuList: [
        {
            name: 'All',
            code: 'all',
            count: null,
            i18n: 'pt.engagement.state_all'
        },
        {
            name: 'Live',
            code: 'live',
            default: true,
            count: null,
            i18n: 'pt.engagement.state_running'
        },
        {
            name: 'Paused',
            code: 'paused',
            count: null,
            i18n: 'pt.engagement.state_off'
        },
        {
            name: 'Draft',
            code: 'draft',
            count: null,
            i18n: 'pt.engagement.state_draft'
        }
    ], //engage report tab count
    currentDebugEngageId: null,
    createPortal: getCampaignReportRouteName() //创建engage的入口routerName: ['CampaignReport', 'UsersReport', 'Scenario', 'EngagementReport]
};

const getters = {
    countMeta: state => state.countMeta,
    engageMenuList: state => state.engageMenuList,
    currentDebugEngageId: state => state.currentDebugEngageId,
    engageInDebug: state => state.engageInDebug,
    createPortal: state => state.createPortal,
    engageSettingStore: () => new EngageSettingStore()
};

export default {
    state,
    getters
};
