<template>
    <div :class="$style.accountSetting">
        <pt-popup
            :custom-class="$style.popup"
            :warn="true"
            :hide-header="true"
            :hide-footer="true"
            :custom-height="popupHeight"
            @closePopup="close"
        >
            <div :class="$style.container" slot="content">
                <pt-tabs
                    v-if="userInfo.signupSource !== 'ptengine'"
                    :value="activeTab"
                    :tabs="tabOptions"
                    :class="$style.tabs"
                    @change="handleTabChange"
                ></pt-tabs>
                <div :class="$style.wrap">
                    <account-profile-setting
                        v-if="showProfileSetting"
                        :userInfo="userInfo"
                        :profileInfo="profileInfo"
                        @update="updateAccount"
                    ></account-profile-setting>
                    <account-password-setting
                        v-else
                        :userInfo="userInfo"
                        @update="updateAccount"
                    ></account-password-setting>
                </div>
            </div>
        </pt-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import accountApis from '@/apis/account.apis';
import AccountProfileSetting from './AccountProfileSetting';
import AccountPasswordSetting from './AccountPasswordSetting';

export default {
    name: 'AccountSetting',

    data() {
        const tabOptions = [
            {
                code: 'profile',
                name: this.$t('account.account_setting.account'),
                default: true
            },
            {
                code: 'password',
                name: this.$t('account.account_setting.password')
            }
        ];
        const defaultTab = tabOptions.find(item => item.default);
        return {
            activeTab: defaultTab.code,
            tabOptions
        };
    },

    computed: {
        ...mapState('main', ['userInfo', 'profileInfo']),

        showProfileSetting() {
            return this.activeTab && this.activeTab === 'profile';
        },

        popupHeight() {
            return this.showProfileSetting ? 384 : 450;
        }
    },

    methods: {
        handleTabChange(tab) {
            this.activeTab = tab.code;
        },

        close() {
            this.$eventBus.$emit('visibleMainPopup', {
                type: 'accountSetting',
                visible: false
            });
        },

        updateAccount(data = {}, result, reject) {
            accountApis
                .updateAccount(null, {
                    where: {
                        aid: this.userInfo.id,
                        email: this.userInfo.email
                    },
                    data
                })
                .then(
                    res => {
                        result && typeof result === 'function' && result(res);
                    },
                    rj => {
                        this.$message.error(this.$t('error.update_account'));
                        reject && typeof reject === 'function' && reject();
                    }
                );
        }
    },

    components: {
        AccountProfileSetting,
        AccountPasswordSetting
    }
};
</script>

<style lang="scss" module>
.accountSetting {
    .popup {
        max-width: 708px !important;
        top: 10% !important;
        transform: none !important;
    }

    .container {
        margin-bottom: 48px;

        .tabs {
            justify-content: center;
        }

        .wrap {
            margin-top: 40px;
        }
    }
}
</style>
