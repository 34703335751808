<template>
    <div class="block-settings__item">
        <div class="block-settings__item-title">{{ title }}</div>
        <div class="block-settings__item-container flex-layout">
            <el-color-picker
                class="pt-mr10"
                popper-class="block-color-picker"
                v-model="currentColor"
                @change="handleChange"
            ></el-color-picker>
            <el-input v-if="hasInput" size="small" v-model="currentColor" @change="handleChange"></el-input>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ColorPicker',
    props: {
        title: String,
        target: String,
        value: {
            required: true
        },
        hasInput: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentColor: this.value
        };
    },

    methods: {
        handleChange(val) {
            // console.log('color picker change', val);
            this.$emit('change', val, this.target);
        }
    }
};
</script>

<style lang="scss">
.block-color-picker {
    z-index: 2147483601 !important;
}
</style>