import i18n from '@assets/i18n';
import store from '@/store';

export default {
    init(type, fileName, chartData) {
        this.fileName = `${fileName}.csv`;
        this.data = this._formatCSV(chartData, type);
        return this;
    },
    download() {
        // @see https://github.com/mholt/PapaParse/issues/175
        var csvData = new Blob([this.data], {
            type: 'text/csv,charset=utf-8'
        });
        var encodedUri = window.URL.createObjectURL(csvData);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', this.fileName);
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
    },
    _fetchCSVHeader(type = 'userList') {
        const { name } = store.getters['main/profileInfo'];
        const profileInfo = [i18n.t('common.profileName') + ': ' + name + '\n'];
        let header = [profileInfo];
        header.push('\n');
        return header;
    },
    _formatChartData(chartData, type) {
        if (chartData && chartData.length > 0) {
            return chartData.map(item => {
                item = item.map(string => (string === null ? '' : `\"${string}\"`)); //处理逗号分隔问题
                item.push('\n');
                return item;
            });
        }
    },
    _formatCSV(chartData, type) {
        const headerStr = this._fetchCSVHeader(type);
        const dataStr = this._formatChartData(chartData);

        return '\ufeff' + headerStr.concat(dataStr);
    }
};
