<template>
    <div class="block-settings" :class="{ 'has-tab': !isSubModel }">
        <template v-if="!isSubModel">
            <div class="block-settings__header">
                <pt-button type="text" color="black" size="medium" @click="handleBack">{{
                    $t('common.button_back')
                }}</pt-button>
                <h3>{{ $t(block.blockName) }}</h3>
            </div>

            <div class="block-settings__tab">
                <settings-tab :tab="currentTab" @change="handleTabChange"></settings-tab>
            </div>
        </template>

        <div class="block-settings__content-tab" v-if="columnHasMultiseriate && currentTab === 'content'">
            <el-tabs v-model="currentContentCode">
                <el-tab-pane
                    v-for="(item, $index) in content"
                    :key="item.code"
                    :label="$t('creative_editor.text_num', { number: $index + 1 })"
                    :name="item.code"
                ></el-tab-pane>
            </el-tabs>
        </div>

        <div class="block-settings__container">
            <div class="block-settings__item-wrap">
                <!-- content -->
                <template v-if="currentTab === 'content'">
                    <div class="block-settings__item">
                        <div class="block-settings__item-container" v-if="currentContentValue">
                            <pt-richtext
                                ref="textEditor"
                                :code="currentContentCode"
                                :value="currentContentValue.text"
                                @change="handleTextChange"
                            ></pt-richtext>
                        </div>
                    </div>
                </template>

                <!-- settings -->
                <template v-else>
                    <!-- Number of columns -->
                    <div class="block-settings__item">
                        <div class="block-settings__item-title">{{ $t('creative_editor.texts_numbers') }}</div>
                        <div class="block-settings__item-container">
                            <el-select size="small" v-model="settings.columnNumber" @change="handleNumberChange">
                                <el-option
                                    v-for="item in columnsOptions"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>

                    <!-- Layout -->
                    <div class="block-settings__item" v-if="columnHasMultiseriate">
                        <div class="block-settings__item-title">{{ $t('creative_editor.item_layout') }}</div>
                        <div class="block-settings__item-container flex-layout">
                            <div
                                class="block-item__icon"
                                v-for="item in layoutOptions"
                                :key="item.code"
                                :class="{ active: settings.layout === item.code }"
                                @click="handleLayoutClick(item.code)"
                            >
                                <pt-icon
                                    :icon="'pt-icon--' + item.icon"
                                    :iconStyle="{ width: '100%', height: '100%' }"
                                ></pt-icon>
                            </div>
                        </div>
                    </div>

                    <!-- Spacing -->
                    <div class="block-settings__item" v-if="columnHasMultiseriate">
                        <div class="block-settings__item-title">{{ $t('creative_editor.item_spacing') }}</div>
                        <div class="block-settings__item-container flex-layout">
                            <el-slider
                                class="block-slider pt-ml10 pt-mr20"
                                v-model="settings.spacing"
                                :show-tooltip="false"
                                :min="spaceingOptions.min"
                                :max="spaceingOptions.max"
                                @change="handleSettingsChange"
                            ></el-slider>
                            <pt-input-number
                                class="max-width-60"
                                size="large"
                                v-model="settings.spacing"
                                :min="spaceingOptions.min"
                                :max="spaceingOptions.max"
                                @change="handleSettingsChange"
                            ></pt-input-number>
                        </div>
                    </div>

                    <!-- Alignment -->
                    <div class="block-settings__item">
                        <div class="block-settings__item-title">{{ $t('creative_editor.creative_alignment') }}</div>
                        <div class="block-settings__item-container flex-layout">
                            <el-radio-group size="small" v-model="settings.alignment" @change="handleSettingsChange">
                                <el-radio-button v-for="item in alignmentOptions" :key="item.code" :label="item.code">
                                    <pt-icon :icon="'pt-icon--' + item.icon"></pt-icon>
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>

                    <!-- Background color -->
                    <div class="block-settings__item">
                        <div class="block-settings__item-title">{{ $t('creative_editor.creative_widget_bg') }}</div>
                        <div class="block-settings__item-container flex-layout">
                            <el-color-picker
                                class="pt-mr10"
                                v-model="settings.backgroundColor"
                                @change="handleSettingsChange"
                            ></el-color-picker>
                            <el-input
                                size="small"
                                v-model="settings.backgroundColor"
                                @change="handleSettingsChange"
                            ></el-input>
                        </div>
                    </div>

                    <!-- Margin top -->
                    <div class="block-settings__item">
                        <div class="block-settings__item-title">{{ $t('creative_editor.block_margin_top') }}</div>
                        <div class="block-settings__item-container flex-layout">
                            <el-slider
                                class="block-slider pt-ml10 pt-mr20"
                                v-model="settings.marginTop"
                                :show-tooltip="false"
                                :min="marginOptions.min"
                                :max="marginOptions.max"
                                @change="handleSettingsChange"
                            ></el-slider>
                            <pt-input-number
                                class="max-width-60"
                                size="large"
                                v-model="settings.marginTop"
                                :min="marginOptions.min"
                                :max="marginOptions.max"
                                @change="handleSettingsChange"
                            ></pt-input-number>
                        </div>
                    </div>

                    <!-- Margin bottom -->
                    <div class="block-settings__item">
                        <div class="block-settings__item-title">{{ $t('creative_editor.block_margin_bottom') }}</div>
                        <div class="block-settings__item-container flex-layout">
                            <el-slider
                                class="block-slider pt-ml10 pt-mr20"
                                v-model="settings.marginBottom"
                                :show-tooltip="false"
                                :min="marginOptions.min"
                                :max="marginOptions.max"
                                @change="handleSettingsChange"
                            ></el-slider>
                            <pt-input-number
                                class="max-width-60"
                                size="large"
                                v-model="settings.marginBottom"
                                :min="marginOptions.min"
                                :max="marginOptions.max"
                                @change="handleSettingsChange"
                            ></pt-input-number>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import config from './text.config';
import SettingsTab from '../SettingsTab';
import { tabOptions } from '../../configs/creative.config';
import PtRichtext from '@ptengine/pt-ui-x/components/richtext/index.js';
import cloneUtils from '@common/utils/clone.utils';

export default {
    name: 'TextSettings',

    props: {
        block: {
            type: Object,
            required: true
        },
        // 是否为子组件
        isSubModel: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        widgetIndex: Number
    },

    data() {
        const defaultTab = tabOptions.find(item => item.default);
        const defaultContentTab = config.content.find(item => item.default);
        const { alignmentOptions, columnsOptions, layoutOptions, marginOptions, spaceingOptions } = config;
        return {
            defaultTab,
            defaultContentTab,

            alignmentOptions,
            columnsOptions,
            layoutOptions,
            marginOptions,
            spaceingOptions,

            currentTab: '',
            currentContentCode: '',

            content: [],
            settings: {}
        };
    },

    computed: {
        currentContentValue() {
            const contentConfig = this.content.find(item => item.code === this.currentContentCode);
            return contentConfig && contentConfig.value;
        },

        columnHasMultiseriate() {
            return this.settings.columnNumber !== 'one';
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.currentTab = this.defaultTab.code;
            this.currentContentCode = this.defaultContentTab.code;
            this.content = this.block.blockContent;
            this.settings = this.block.blockSettings;
            this.updateText();
        },

        handleBack() {
            this.$emit('back');
        },

        handleTabChange(tab) {
            this.currentTab = tab;
        },

        handleNumberChange(val) {
            if (val === 'two') {
                const newContent = Object.assign({}, cloneUtils.deep(this.content[0]), {
                    code: 'text2',
                    name: this.$t('creative_editor.text_two')
                });
                this.content.splice(1, 1, newContent);
            } else {
                this.content.splice(1, 1);
                this.currentContentCode = this.defaultContentTab.code;
                this.updateText();
            }
            this.handleContentChange();
        },

        handleTextChange(val) {
            this.$set(this.currentContentValue, 'text', val);
            this.handleContentChange();
        },

        handleContentChange(val) {
            this.$emit('contentChange', this.content);
        },

        handleSettingsChange() {
            this.$emit('settingsChange', this.settings);
        },

        handleLayoutClick(layout) {
            this.settings.layout = layout;
            this.handleSettingsChange();
        },

        updateText() {
            if (this.$refs.textEditor) {
                this.$refs.textEditor.update();
            }
        }
    },

    watch: {
        'block.blockId': function() {
            this.init();
        }
    },

    components: {
        SettingsTab,
        PtRichtext
    }
};
</script>

<style lang="scss">
@import '@/styles/import.scss';

.split-layout {
    height: 32px;
    width: 34px;
    padding: 4px;
    border: 1px solid $pt-black-50;
    border-radius: 4px;
    background-color: $pt-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    margin-right: 12px;
    cursor: pointer;

    &:hover,
    &.active {
        border-color: $pt-green-60;
        box-shadow: 0 2px 4px 0 $pt-black-40;

        .split-layout__column {
            background-color: $pt-green-60;
        }
    }

    &__column {
        border-radius: 1px;
        height: 24px;
        background-color: $pt-black-50;
        overflow: hidden;

        &.with50 {
            width: 11px;
        }
        &.with65 {
            width: 15px;
        }
        &.with35 {
            width: 7px;
        }
    }
}
</style>
