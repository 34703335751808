export const continentsCode = [
    {"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},
    {"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},
    {"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},
    {"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},
    {"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},
    {"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"}
];

/**
 * 有部分国家code和higchart返回的数据对不上,特意加上highchartCode对应
 * 此列表已google数据源为准，与highchart有差异的，加上highchartCode对应
 */
export const countryCode = [
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"阿富汗","en_US":"Afghanistan","ja_JP":"アフガニスタン"},"code":"Afghanistan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"阿拉伯联合酋长国","en_US":"United Arab Emirates","ja_JP":"アラブ首長国連邦"},"code":"United Arab Emirates"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"也门","en_US":"Yemen","ja_JP":"イェメン"},"code":"Yemen"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"以色列","en_US":"Israel","ja_JP":"イスラエル"},"code":"Israel"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"伊拉克","en_US":"Iraq","ja_JP":"イラク"},"code":"Iraq"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"伊朗","en_US":" Iran","ja_JP":"イラン"},"code":"Iran"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"印度","en_US":"India","ja_JP":"インド"},"code":"India"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"印度尼西亚","en_US":"Indonesia","ja_JP":"インドネシア"},"code":"Indonesia"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"阿曼","en_US":"Oman","ja_JP":"オマーン"},"code":"Oman"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"卡塔尔","en_US":"Qatar","ja_JP":"カタール"},"code":"Qatar"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"柬埔寨","en_US":"Cambodia","ja_JP":"カンボジア"},"code":"Cambodia"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"塞浦路斯","en_US":"Cyprus","ja_JP":"キプロス"},"code":"Cyprus"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"科威特","en_US":"Koweit","ja_JP":"クウェート"},"code":"Kuwait"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"沙特阿拉伯","en_US":"Saudi Arabia","ja_JP":"サウジアラビア"},"code":"Saudi Arabia"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"叙利亚","en_US":"Syria","ja_JP":"シリア"},"code":"Syria"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"锡金","en_US":"Sikkim","ja_JP":"シッキム"},"code":"Sikkim"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"新加坡","en_US":"Singapore","ja_JP":"シンガポール"},"code":"Singapore"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"斯里兰卡","en_US":"Sri Lanka","ja_JP":"スリランカ"},"code":"Sri Lanka"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"泰国","en_US":"Thailand","ja_JP":"タイ"},"code":"Thailand"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"中国","en_US":"China","ja_JP":"中国"},"code":"China"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"韩国","en_US":"Korea","ja_JP":"韓国"},"code":"South Korea"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"北朝鲜","en_US":"North Korea","ja_JP":"北朝鮮"},"code":"North Korea"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"土耳其","en_US":"Turkey","ja_JP":"トルコ"},"code":"Turkey"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"日本","en_US":"Japan","ja_JP":"日本"},"code":"Japan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"尼泊尔","en_US":"Nepal","ja_JP":"ネパール"},"code":"Nepal"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"巴基斯坦","en_US":"Pakistan","ja_JP":"パキスタン"},"code":"Pakistan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"巴林","en_US":"Bahrain","ja_JP":"バーレーン"},"code":"Bahrain"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"巴勒斯坦","en_US":"Palestine","ja_JP":"パレスチナ"},"code":"Palestine"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"孟加拉","en_US":"Bangladesh","ja_JP":"バングラデシュ"},"code":"Bangladesh"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"东帝汶","en_US":"East Timor","ja_JP":"東チモール"},"code":"Timor-Leste","highchartCode":"East Timor"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"缅甸","en_US":"Myanmar(Burma)","ja_JP":"ミャンマー"},"code":"Myanmar (Burma)","highchartCode": "Myanmar"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"菲律宾","en_US":"Philippines","ja_JP":"フィリピン"},"code":"Philippines"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"不丹","en_US":"Bhutan","ja_JP":"ブータン"},"code":"Bhutan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"文莱","en_US":"Brunei","ja_JP":"ブルネイ"},"code":"Brunei"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"越南","en_US":"Viet Nam","ja_JP":"べトナム"},"code":"Vietnam"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"马来西亚","en_US":"Malaysia","ja_JP":"マレーシア"},"code":"Malaysia"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"马尔代夫","en_US":"Maldives","ja_JP":"モルジブ"},"code":"Maldives"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"蒙古","en_US":"Mongolia","ja_JP":"モンゴル"},"code":"Mongolia"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"约旦","en_US":"Jordan","ja_JP":"ヨルダン"},"code":"Jordan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"老挝","en_US":"Laos","ja_JP":"ラオス"},"code":"Laos"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"黎巴嫩","en_US":"Lebanon","ja_JP":"レバノン"},"code":"Lebanon"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"乌兹别克斯坦","en_US":"Uzbekistan","ja_JP":"ウズベキスタン"},"code":"Uzbekistan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"哈萨克斯坦","en_US":"Kazakhstan","ja_JP":"カザフスタン"},"code":"Kazakhstan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"吉尔吉斯斯坦","en_US":"Kirgizstan","ja_JP":"キルギスタン"},"code":"Kyrgyzstan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"塔吉克斯坦","en_US":"Tajikistan","ja_JP":"タジキスタン"},"code":"Tajikistan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"亚美尼亚","en_US":"Armenia","ja_JP":"アルメニア"},"code":"Armenia"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"土库曼斯坦","en_US":"Turkmenistan","ja_JP":"トルクメニスタン"},"code":"Turkmenistan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"阿塞拜疆","en_US":"Azerbaijan","ja_JP":"アゼルバイジャン"},"code":"Azerbaijan"},
    {"path":"","continent":{"zh_CN":"亚洲","en_US":"Asia","ja_JP":"アジア"},"name":{"zh_CN":"格鲁吉亚","en_US":"Georgia","ja_JP":"グルジア"},"code":"Georgia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"冰岛","en_US":"Iceland","ja_JP":"アイスランド"},"code":"Iceland"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"法罗群岛","en_US":"Faroe Islands","ja_JP":"フェロー諸島"},"code":"Faroe Islands"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"丹麦","en_US":"Denmark","ja_JP":"デンマーク"},"code":"Denmark"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"挪威","en_US":"Norway","ja_JP":"ノルウェー"},"code":"Norway"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"瑞典","en_US":"Sweden","ja_JP":"スウェ‐デン"},"code":"Sweden"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"芬兰","en_US":"Finland","ja_JP":"フィンランド"},"code":"Finland"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"俄罗斯","en_US":"Russia","ja_JP":"ロシア"},"code":"Russia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"乌克兰","en_US":"Ukraine","ja_JP":"ウクライナ"},"code":"Ukraine"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"白俄罗斯","en_US":"Belarus","ja_JP":"べラルーシ"},"code":"Belarus"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"摩尔多瓦","en_US":"Moldova","ja_JP":"モルドバ"},"code":"Moldova"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"立陶宛","en_US":"Lithuania","ja_JP":"リトアニア"},"code":"Lithuania"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"爱沙尼亚","en_US":"Estonia","ja_JP":"エストニア"},"code":"Estonia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"拉脱维亚","en_US":"Latvia","ja_JP":"ラトビア"},"code":"Latvia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"波兰","en_US":"Poland","ja_JP":"ポーランド"},"code":"Poland"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"捷克","en_US":"Czech","ja_JP":"チェコ"},"code":"Czech Republic"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"匈牙利","en_US":"Hungary","ja_JP":"ハンガリー"},"code":"Hungary"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"德国","en_US":"Germany","ja_JP":"ドイツ"},"code":"Germany"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"奥地利","en_US":"Austria","ja_JP":"オーストリア"},"code":"Austria"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"列支敦士登","en_US":"Liechtenstein","ja_JP":"リヒテンシュタイン"},"code":"Liechtenstein"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"瑞士","en_US":"Switzerland","ja_JP":"スイス"},"code":"Switzerland"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"荷兰","en_US":"Netherlands","ja_JP":"オランダ"},"code":"Netherlands","highchartCode":"The Netherlands"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"比利时","en_US":"Belgium","ja_JP":"べルギー"},"code":"Belgium"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"卢森堡","en_US":"Luxembourg","ja_JP":"ルクセンブルグ"},"code":"Luxembourg"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"英国","en_US":"United Kingdom","ja_JP":"英国"},"code":"United Kingdom"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"爱尔兰","en_US":"Ireland","ja_JP":"アイルランド"},"code":"Ireland"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"法国","en_US":"France","ja_JP":"フランス"},"code":"France"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"摩纳哥","en_US":"Monaco","ja_JP":"モナコ"},"code":"Monaco"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"安道尔","en_US":"Andorra","ja_JP":"アンドラ"},"code":"Andorra"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"西班牙","en_US":"Spain","ja_JP":"スペイン"},"code":"Spain"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"葡萄牙","en_US":"Portugal","ja_JP":"ポルトガル"},"code":"Portugal"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"意大利","en_US":"Italy","ja_JP":"イタリア"},"code":"Italy"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"梵蒂冈","en_US":"Vatican","ja_JP":"バチカン"},"code":"Vatican"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"圣马力诺","en_US":"San Marino","ja_JP":"サンマリノ"},"code":"San Marino"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"马耳他","en_US":"Malta","ja_JP":"マルタ"},"code":"Malta"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"克罗地亚","en_US":"Croatia","ja_JP":"クロアチア"},"code":"Croatia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"斯洛伐克","en_US":"Slovakia","ja_JP":"スロバキア"},"code":"Slovakia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"斯洛文尼亚","en_US":"Slovenia","ja_JP":"スロべニア"},"code":"Slovenia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"波黑","en_US":"The Republic of Bosnia and Hercegovina","ja_JP":"ボスニア・ヘルツェゴビナ"},"code":"Bosnia and Herzegovina"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"马其顿","en_US":"Macedonia","ja_JP":"マケドニア"},"code":"Macedonia (FYROM)","highchartCode":"Macedonia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"塞尔维亚","en_US":"Serbia","ja_JP":"セルビア"},"code":"Serbia","highchartCode":"Republic of Serbia"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"黑山","en_US":"The Republic of Montenegro","ja_JP":"モンテネグロ"},"code":"Montenegro"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"塞尔维亚和黑山","en_US":"Serbia and Montenegro","ja_JP":"セルビアとモンテネグロ"},"code":"Serbia and Montenegro"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"罗马尼亚","en_US":"Romania","ja_JP":"ルーマニア"},"code":"Romania"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"保加利亚","en_US":"Bulgaria","ja_JP":"ブルガリア"},"code":"Bulgaria"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"阿尔巴尼亚","en_US":"Albania","ja_JP":"アルバニア"},"code":"Albania"},
    {"path":"","continent":{"zh_CN":"欧洲","en_US":"Europe","ja_JP":"ヨーロッパ"},"name":{"zh_CN":"希腊","en_US":"Greece","ja_JP":"ギリシア"},"code":"Greece"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"埃及","en_US":"Egypt","ja_JP":"エジプト"},"code":"Egypt"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"利比亚","en_US":"Libya","ja_JP":"リビア"},"code":"Libya"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"突尼斯","en_US":"Tunisia","ja_JP":"チュニジア"},"code":"Tunisia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"阿尔及利亚","en_US":"Algeria","ja_JP":"アルジェリア"},"code":"Algeria"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"摩洛哥","en_US":"Morocco","ja_JP":"モロッコ"},"code":"Morocco"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"西撒哈拉","en_US":"Western Sahara ","ja_JP":"西サハラ"},"code":"Western Sahara "},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"毛里塔尼亚","en_US":"Mauritania","ja_JP":"モーリタニア"},"code":"Mauritania"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"塞内加尔","en_US":"Senegal","ja_JP":"セネガル"},"code":"Senegal"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"冈比亚","en_US":"Gambia","ja_JP":"ガンビア"},"code":"Gambia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"马里","en_US":"Mali","ja_JP":"マリ"},"code":"Mali"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"佛得角","en_US":"Cape Verde","ja_JP":"カーボベルデ"},"code":"Cape Verde"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"几内亚比绍","en_US":"Guinea-Bissau","ja_JP":"ギニアビサオ（ギニアビサウ）"},"code":"Guinea-Bissau","highchartCode":"Guinea Bissau"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"几内亚","en_US":"Guinea","ja_JP":"ギニア"},"code":"Guinea"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"塞拉利昂","en_US":"Sierra Leone","ja_JP":"シェラレオネ"},"code":"Sierra Leone"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"利比里亚","en_US":"Liberia","ja_JP":"リべリア"},"code":"Liberia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"科特迪瓦","en_US":"Cote dIvoire","ja_JP":"コートジボワール"},"code":"Cote dIvoire"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"加纳","en_US":"Ghana","ja_JP":"ガーナ"},"code":"Ghana"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"多哥","en_US":"Togo","ja_JP":"トーゴ"},"code":"Togo"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"贝宁","en_US":"Benin ","ja_JP":"べナン(ベニン)"},"code":"Benin"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"尼日尔","en_US":"Niger","ja_JP":"ニジェール"},"code":"Niger"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"尼日利亚","en_US":"Nigeria","ja_JP":"ナイジェリア"},"code":"Nigeria"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"喀麦隆","en_US":"Cameroun","ja_JP":"カメルーン"},"code":"Cameroun"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"赤道几内亚","en_US":"Equatorial Guinea","ja_JP":"赤道ギニア"},"code":"Equatorial Guinea"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"乍得","en_US":"Chad","ja_JP":"チャド"},"code":"Chad"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"中非","en_US":"Central African","ja_JP":"中央アフリカ"},"code":"Central African Republic"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"苏丹","en_US":"Sudan","ja_JP":"スーダン"},"code":"Sudan"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"埃塞俄比亚","en_US":"Ethiopia","ja_JP":"エチオピア"},"code":"Ethiopia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"吉布提","en_US":"Djibouti","ja_JP":"ジブチ"},"code":"Djibouti"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"索马里","en_US":"Somalia","ja_JP":"ソマリア"},"code":"Somalia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"肯尼亚","en_US":"Kenya","ja_JP":"ケニア"},"code":"Kenya"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"乌干达","en_US":"Uganda","ja_JP":"ウガンダ"},"code":"Uganda"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"坦桑尼亚","en_US":"Tanzania","ja_JP":"タンザニア"},"code":"Tanzania"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"卢旺达","en_US":"Rwanda","ja_JP":"ルワンダ"},"code":"Rwanda"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"布隆迪","en_US":"Burundi","ja_JP":"ブルンジ"},"code":"Burundi"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"民主刚果","en_US":"The Democratic Republic of Congo","ja_JP":"コンゴ民主共和国"},"code":"Democratic Republic of the Congo"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"刚果","en_US":"Congo","ja_JP":"コンゴ"},"code":"Republic of the Congo"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"加蓬","en_US":"Gabon","ja_JP":"ガボン"},"code":"Gabon"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"圣普","en_US":"","ja_JP":"サントメプリンシペ"},"code":""},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"安哥拉","en_US":"Angola","ja_JP":"アンゴラ"},"code":"Angola"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"赞比亚","en_US":"Zambia","ja_JP":"ザンビア"},"code":"Zambia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"马拉维","en_US":"Malawi","ja_JP":"マラウイ"},"code":"Malawi"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"莫桑比克","en_US":"Mozambique","ja_JP":"モザンビーク"},"code":"Mozambique"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"科摩罗","en_US":"Comoros","ja_JP":"コモロ"},"code":"Comoros"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"马达加斯加","en_US":"Madagascar","ja_JP":"マダガスカル"},"code":"Madagascar"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"塞舌尔","en_US":"Seychelles","ja_JP":"セイシェル"},"code":"Seychelles"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"毛里求斯","en_US":"Mauritius","ja_JP":"モーリシャス"},"code":"Mauritius"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"津巴布韦","en_US":"Zimbabwe","ja_JP":"ジンバブエ"},"code":"Zimbabwe"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"博茨瓦纳","en_US":"Botswana","ja_JP":"ボツワナ"},"code":"Botswana"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"纳米比亚","en_US":"Namibia","ja_JP":"ナミビア"},"code":"Namibia"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"南非","en_US":"South Africa","ja_JP":"南アフリカ"},"code":"South Africa"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"斯威士兰","en_US":"Swaziland","ja_JP":"スワジランド"},"code":"Swaziland"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"莱索托","en_US":"Lesotho","ja_JP":"レソト"},"code":"Lesotho"},
    {"path":"","continent":{"zh_CN":"非洲","en_US":"Africa","ja_JP":"アフリカ"},"name":{"zh_CN":"厄立特里亚","en_US":"Eritrea","ja_JP":"エリトリア"},"code":"Eritrea"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"澳大利亚","en_US":"Australia","ja_JP":"オーストラリア"},"code":"Australia"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"新西兰","en_US":"New Zealand","ja_JP":"ニュージーランド"},"code":"New Zealand"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"巴布亚新几内亚","en_US":"Papua New Guinea","ja_JP":"パプアニューギニア"},"code":"Papua New Guinea"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"所罗门群岛","en_US":"Solomon Islands","ja_JP":"ソロモン諸島"},"code":"Solomon Islands"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"瓦努阿图","en_US":"Vanuatu","ja_JP":"バヌアツ"},"code":"Vanuatu"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"新喀里多尼亚","en_US":"New Caledonia","ja_JP":"ニューカレドニア"},"code":"New Caledonia"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"斐济","en_US":"Fiji","ja_JP":"フイジー"},"code":"Fiji"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"基里巴斯","en_US":"Kiribati","ja_JP":"キリバス"},"code":"Kiribati"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"瑙鲁","en_US":"Nauru","ja_JP":"ナウル"},"code":"Nauru"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"密克罗尼西亚（米克罗尼西亚）","en_US":"Micronesia","ja_JP":"ミクロネシア"},"code":"Micronesia"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"马绍尔","en_US":"Marshall","ja_JP":"マーシャル"},"code":"Marshall"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"北马里亚纳","en_US":"Northern Mariana","ja_JP":"北マリアナ"},"code":"Northern Mariana Islands"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"关岛","en_US":"Guam","ja_JP":"グアム島"},"code":"Guam"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"图瓦卢","en_US":"Tuvalu","ja_JP":"ツバル"},"code":"Tuvalu"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"瓦利斯群岛和富图纳群岛","en_US":"Wallis lslands and Futuna lslands","ja_JP":"ウォリス・フトゥーナ"},"code":"Wallis and Futuna"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"萨摩亚（西萨摩亚）","en_US":"Samoa","ja_JP":"サモア独立国（西サモア）"},"code":"Samoa"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"美属萨摩亚（东萨摩亚）","en_US":"American Samoa（EasternSamoa）","ja_JP":"米サモア"},"code":"American Samoa"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"纽埃","en_US":"Niue","ja_JP":"ニウエ"},"code":"Niue"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"诺福克岛","en_US":"Norfolk lsland","ja_JP":"ノーフォーク島"},"code":"Norfolk lsland"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"帕劳","en_US":"Palau","ja_JP":"パラオ"},"code":"Palau"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"托克劳","en_US":"Tokelau","ja_JP":"トケラウ"},"code":"Tokelau"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"库克群岛(新)","en_US":"Cook Islands","ja_JP":"クック諸島"},"code":"Cook Islands"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"汤加","en_US":"Tonga","ja_JP":"トンガ"},"code":"Tonga"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"法属波利尼西亚","en_US":"French Polynesia","ja_JP":"ポリネシア"},"code":"French Polynesia"},
    {"path":"","continent":{"zh_CN":"大洋洲","en_US":"Oceania","ja_JP":"オセアニア"},"name":{"zh_CN":"皮特凯恩群岛(英)","en_US":"Pitcairn Islands","ja_JP":"ピトケアン（ピトケルン、ピットケルン）"},"code":"Pitcairn Islands"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"格陵兰","en_US":"Greenland","ja_JP":"グリーンランド"},"code":"Greenland"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"加拿大","en_US":"Canada","ja_JP":"カナダ"},"code":"Canada"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"圣皮埃尔和密克隆","en_US":"St Pierre and Miquelon","ja_JP":"サンピエール・ミクロン"},"code":"St Pierre and Miquelon"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"美国","en_US":"United States","ja_JP":"米国"},"code":"United States","highchartCode":"United States of America"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"百慕大群岛","en_US":"Bermuda Island","ja_JP":"バミューダ諸島、バーミューダ諸島"},"code":"Bermuda Island"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"墨西哥","en_US":"Mexico ","ja_JP":"メキシコ"},"code":"Mexico"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"危地马拉","en_US":"Guatemala","ja_JP":"グアテマラ"},"code":"Guatemala"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"伯利兹","en_US":"Belize","ja_JP":"ベリーズ"},"code":"Belize"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"萨尔瓦多","en_US":"El Salvador","ja_JP":"エルサルバドル"},"code":"El Salvador"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"洪都拉斯","en_US":"Honduras","ja_JP":"ホンジュラス"},"code":"Honduras"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"尼加拉瓜","en_US":"Nicaragua","ja_JP":"ニカラグア"},"code":"Nicaragua"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"哥斯达黎加","en_US":"Costa Rica","ja_JP":"コスタリカ"},"code":"Costa Rica"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"巴拿马","en_US":"Panama","ja_JP":"パナマ"},"code":"Panama"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"巴哈马","en_US":"Bahamas","ja_JP":"バハマ"},"code":"The Bahamas"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"特克斯和凯科斯群岛","en_US":"The Turks and Caicos Islands","ja_JP":"タークス・カイコス諸島"},"code":"The Turks and Caicos Islands"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"古巴","en_US":"Cuba","ja_JP":"キューバ"},"code":"Cuba"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"开曼群岛","en_US":"Cayman Islands","ja_JP":"ケイマン諸島"},"code":"Cayman Islands"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"牙买加","en_US":"Jamaica","ja_JP":"ジャマイカ"},"code":"Jamaica"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"海地","en_US":"Haiti","ja_JP":"ハイチ"},"code":"Haiti"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"多米尼加","en_US":"Dominican","ja_JP":"ドミニカ"},"code":"Dominican Republic"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"波多黎各","en_US":"Puerto Rico","ja_JP":"プエルトリコ"},"code":"Puerto Rico"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"圣基茨和尼维斯","en_US":"Saint Kitts and Nevis","ja_JP":"セントクリストファー・ネビス"},"code":"Saint Kitts and Nevis"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"安圭拉","en_US":"Anguilla","ja_JP":"アングィラ"},"code":"Anguilla"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"安提瓜和巴布达","en_US":"Antigua and Barbuda","ja_JP":"アンチグア・バーブーダ"},"code":"Antigua and Barbuda"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"蒙特塞拉特","en_US":"Montserrat","ja_JP":"モントセラト"},"code":"Montserrat"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"瓜德罗普","en_US":"Guadeloupe","ja_JP":"グアドループ"},"code":"Guadeloupe"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"多米尼克","en_US":"Dominica","ja_JP":"ドミニカ"},"code":"Dominica"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"马提尼克","en_US":"Martinique ","ja_JP":"マルティニーク"},"code":"Martinique"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"圣卢西亚","en_US":" St. Lucia ","ja_JP":"セントルシア"},"code":"St. Lucia"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"圣文森特和格林纳丁斯","en_US":"Saint Vincent and The Grenadines","ja_JP":"セントビンセントおよびグレナディーン"},"code":"Saint Vincent and The Grenadines"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"巴巴多斯","en_US":"Barbados","ja_JP":"バルバドス"},"code":"Barbados"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"格林纳达","en_US":"Grenada","ja_JP":"グレナダ、グラナダ、グルナード"},"code":"Grenada"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"特立尼达和多巴哥","en_US":"Trinidad and Tobago","ja_JP":"トリニダード・トバゴ="},"code":"Trinidad and Tobago"},
    {"path":"","continent":{"zh_CN":"北美洲","en_US":"North America","ja_JP":"北アメリカ"},"name":{"zh_CN":"阿鲁巴","en_US":"Aruba","ja_JP":"アルーバ、アルバ"},"code":"Aruba"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"code":"South America"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"哥伦比亚共和国","en_US":"Republic of Colombia","ja_JP":"コロンビア共和国"},"code":"Republic of Colombia"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"委内瑞拉","en_US":"Venezuela","ja_JP":"ベネズエラ、ヴェネズエラ"},"code":"Venezuela"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"圭亚那","en_US":"Guyana","ja_JP":"ガイアナ"},"code":"Guyana"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"苏里南","en_US":"Suriname","ja_JP":"スリナム"},"code":"Suriname"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"法属圭亚那","en_US":"French Guiana","ja_JP":"ギアナ"},"code":"French Guiana"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"厄瓜多尔","en_US":"Ecuador","ja_JP":"エクアドル"},"code":"Ecuador"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"秘鲁","en_US":"Peru","ja_JP":"ペルー"},"code":"Peru"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"巴西","en_US":"Brazil","ja_JP":"ブラジル"},"code":"Brazil"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"玻利维亚","en_US":"Bolivia","ja_JP":"ボリビア"},"code":"Bolivia"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"智利","en_US":"Chile","ja_JP":"チリ"},"code":"Chile"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"阿根廷","en_US":"Argentina","ja_JP":"アルゼンチン"},"code":"Argentina"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"巴拉圭","en_US":"Paraguay","ja_JP":"パラグアイ"},"code":"Paraguay"},
    {"path":"","continent":{"zh_CN":"南美洲","en_US":"South America","ja_JP":"南アメリカ"},"name":{"zh_CN":"乌拉圭","en_US":"Uruguay","ja_JP":"ウルグアイ"},"code":"Uruguay"}
];