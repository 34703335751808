import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'addScenario',
        query: `mutation addScenario($data: ScenarioCreateInput!){
            addScenario(data: $data) {
                id
            }
        }`
    },
    {
        name: 'updateScenario',
        query: `mutation updateScenario($data: ScenarioCreateInput!, $where: ScenarioWhereUniqueInput!){
            updateScenario(data: $data, where: $where) {
                id
            }
        }`
    },
    {
        name: 'deleteScenario',
        query: `mutation deleteScenario($where: ScenarioWhereUniqueInput!){
            deleteScenario(where:$where) {
                id
            }
        }`
    },
    {
        name: 'addScenarioTag',
        query: `mutation addScenarioTag($data: ScenarioTagCreateInput!){
            addScenarioTag(data: $data)
        }`
    },
    {
        name: 'updateScenarioTag',
        query: `mutation updateScenarioTag($data: ScenarioTagCreateInput!, $where: ScenarioTagWhereUniqueInput!){
            updateScenarioTag(data: $data, where: $where) {
                id
                type
                tag
            }
        }`
    },
    {
        name: 'scenarios',
        query: `query scenarios {
            scenarios{
                id
                name
                industry
                purpose
                status
                engageID
                description
                hasForm
                star
                
                versions {
                    id
                    name
                    rate
                    isSync
                    status
                    displayConfig
                    controlGroup
                    terminals{
                        id
                        type
                        codeMode
                        codeSync
                        status
                        widgets
                    }
                }
                sourceType
                usergroups{
                    usergroupID: id,
                    name
                    source
                    description
                }
            }
        }`
    },
    {
        name: 'scenarioTags',
        query: `query scenarioTags{
            scenarioTags{
                id
                tag
            }
        }`
    }
];

export default resourceWrapper(resources);
