var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.role == 'pull')?_c('div',{attrs:{"name":_vm.name,"dk-container":"true"}},[_vm._t("default")],2):_c('div',{staticClass:"dk-container-wrapper",class:{
        'dk-start-container': _vm.isStartContainer,
        'dk-current-container': _vm.isCurrentContainer,
        'dk-same-group': _vm.isSameGroup,
        'dk-empty': !_vm.value || _vm.value.length == 0
    }},[(_vm.data)?_c('div',{staticClass:"dk-container",style:({ height: _vm.totalHeight + 'px' }),attrs:{"dk-container":"true","name":_vm.name}},[_c('transition-group',{attrs:{"name":"flip-list","tag":"div"}},_vm._l((_vm.data),function(item,index){return _c('div',{key:item.__id,staticClass:"dk-item dk-animate-item",class:{
                    'dk-placeholder-item': item.isPlaceholder,
                    'dk-cover-item': _vm.coverMode && index == _vm.toIndex
                },style:(_vm.getNodeStyle(index)),attrs:{"dk-draggable":"true","data-dk-id":item.__id}},[_vm._t("default",null,{"node":item,"index":index})],2)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDragTip),expression:"isShowDragTip"}],staticClass:"dk-item-prompt-text"},[_vm._v(" "+_vm._s(_vm.tipText)+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }