import store from '@/store';
import i18n from '@assets/i18n';
import uuidUtils from '@common/utils/uuid.utils';
import commonUtils from '@common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';
import widgetConfig from '@/components/engagement/creative/editor/blocks/widget/widget.config';
import widgetServices from '@/components/engagement/creative/editor/blocks/widget/widget.services';
import { getBlockInfoByTemplate } from '@/components/engagement/creative/editor/utils/creative.util';
import { deviceConfig } from '@/components/engagement/creative/editor/configs/creative.config';
import { version } from 'element-ui';

export default {
    createElement(nodes) {
        var that = this;
        if (typeof nodes === 'string' || typeof nodes === 'number') {
            if (nodes.indexOf('<') !== -1 && /<?\/[a-z]*>/.test(nodes)) {
                const tmp = document.createElement('div');
                tmp.innerHTML = nodes;
                return tmp;
            }
            return document.createTextNode(nodes);
        }
        var element = document.createElement(nodes.tag);
        that.setProps(element, nodes.props);
        var children = nodes.children;
        children && children.map(that.createElement.bind(that)).forEach(element.appendChild.bind(element));
        return element;
    },

    // 给dom元素设置属性
    setProps(element, attributes) {
        for (var key in attributes) {
            element.setAttribute(key, attributes[key]);
        }
    },

    getObjArrIndex(arr, key, value) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][key] === value) {
                return i;
            }
        }
        return -1;
    },

    // http://localhost:3100/v2/homepage-egg?engageName=promo&action=new
    getUrlParams(str) {
        if (str.indexOf('?') === -1) return;
        const targetStr = str.split('?')[1];
        const arrs = targetStr.split('&');
        const o = {};
        arrs.forEach(element => {
            const arr = element.split('=');
            o[arr[0]] = arr[1];
        });
        return o;
    },

    /**
     * @param {*} el 要获取el到body的offsetTop值
     */
    getOffsetTop(el) {
        let top = el.offsetTop;
        while (el.offsetParent !== null && el.offsetParent !== document.body) {
            top += el.offsetParent.offsetTop;
            el = el.offsetParent;
        }
        return top;
    },

    /**
     *
     * @param {*} src 要加载的js文件地址
     * @param {*} callback js文件加载完成后的回调
     */
    getScript(src, callback) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.charset = 'UTF-8';
        script.src = src;
        if (script.addEventListener) {
            script.addEventListener(
                'load',
                function() {
                    callback();
                },
                false
            );
        } else if (script.attachEvent) {
            script.attachEvent('onreadystatechange', function() {
                var target = window.event.srcElement;
                if (target.readyState === 'loaded') {
                    callback();
                }
            });
        }
        document.body.appendChild(script);
    },

    scrollTo(element, to, duration) {
        const start = element.scrollTop;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;

        /**
         * @param {*} t current time
         * @param {*} b start value
         * @param {*} c change in value
         * @param {*} d duration
         */
        const easeInOutQuad = function(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };
        const animateScroll = function() {
            currentTime += increment;
            const val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    },
    /**
     * 数据格式化。
     * 多版本迭代，统一数据结构
     * 接口返回的数据结构可见 ./engage.demo.json
     * 转换后的数据结构可见 ./engage.setting.json
     * For engageVersion 3.0
     */
    formatCampaign(engage) {
        const creative = [];
        const start = [];
        const stop = [];
        const session = [];
        const schedule = [];
        // const session = [];
        // const schedule = [];
        let {
            id,
            name,
            baseURL,
            engageVersion,
            status,
            audiences,
            versions,
            conditions,
            // schedule,
            goals,
            hasVariant
        } = engage;
        const engageId = id;
        // const engageId = id;
        const audiencesArr =
            (audiences &&
                audiences.map(item => {
                    // 统一属性名称
                    item.audience_id && (item.id = item.audience_id);
                    delete item.audience_id;
                    return item;
                })) ||
            [];
        let creativeId = uuidUtils.uuid();
        let versionStartId = uuidUtils.uuid();
        let versionStopId = versionStartId;
        let versionSessionId = versionStartId;
        let versionScheduleId = uuidUtils.uuid();
        const versionsArr = versions.map(version => {
            // const { id, name, draft, rate, isSync, controlGroup, config, terminals } = version;
            const {
                id,
                name,
                rate,
                versionId,
                versionName,
                screenshot,
                status,
                versionRate,
                controlGroup,
                displayConfig,
                terminals
            } = version;

            creativeId = uuidUtils.uuid();

            // creative
            if (creative.length === 0) {
                creative.push(this.formatEngageCreativeV3(terminals, creativeId, engageId, versionId, '3.0'));
            }
            // start
            //fix: 线上config.when 不为数组的情况，也不知道是哪个版本搞出来的。。。
            let onPage = displayConfig && displayConfig.when;
            if (onPage && !onPage instanceof Array) {
                onPage = [onPage];
            }
            start.length === 0 &&
                displayConfig &&
                start.push({
                    startId: versionStartId,
                    versionMeta: displayConfig.meta.start,
                    versionData: displayConfig.start,
                    onPage: onPage
                });
            //session
            const defaultSessionMeta = {
                userBehaviourContent: { children: [], operator: 'and' },
                visitPropContent: { children: [], operator: 'and' }
            };
            session.length === 0 &&
                displayConfig &&
                session.push({
                    sessionId: versionSessionId,
                    versionMeta: displayConfig.meta.session || defaultSessionMeta,
                    versionData: displayConfig.session || []
                });
            // stop
            stop.length === 0 &&
                displayConfig &&
                stop.push({
                    stopId: versionStopId,
                    versionMeta: displayConfig.meta.stop,
                    versionData: displayConfig.stop
                });

            // schedule
            schedule.length === 0 &&
                displayConfig &&
                schedule.push({
                    scheduleId: versionScheduleId,
                    versionMeta: displayConfig.meta.schedule,
                    versionData: displayConfig.schedule,
                    timezone: displayConfig.timezone
                });

            return {
                versionId: versionId || id,
                versionName: versionName || name,
                versionRate: Number(versionRate || rate) * 100,
                screenshot: screenshot,
                controlGroup,
                status: status === '0',
                creativeId,
                versionStartId,
                versionSessionId,
                versionStopId,
                versionScheduleId
            };
        });
        return {
            engageId,
            engageVersion,
            engageName: name,
            engageStatus: status,
            baseURL,
            audiences: audiencesArr,
            creative,
            conditions,
            schedule,
            start,
            stop,
            session,
            goals,
            versions: versionsArr,
            hasVariant
        };
    },

    /**
     * 数据格式化。
     * 多版本迭代，统一数据结构
     * 接口返回的数据结构可见 ./engage.demo.json
     * 转换后的数据结构可见 ./engage.setting.json
     */
    formatEngage(engage, previewUrl, hasRemoveBrandingRule = true) {
        const creative = [];
        const start = [];
        const stop = [];
        const session = [];
        const schedule = [];
        let {
            engage_id,
            engageId, // 列表与编辑接口返回的不一致
            engage_name,
            engageName, // 列表与编辑接口返回的不一致
            id,
            baseURL,
            engageVersion,
            name,
            hasForm,
            abtestType,
            //   abtestRate,
            goals,
            status,
            audiences,
            versions,
            hasVariant,
            firstRunTime,
            lastPauseTime
        } = engage;
        engageId = engageId || engage_id || id;
        // const engageId = id;
        const audiencesArr =
            (audiences &&
                audiences.map(item => {
                    // 统一属性名称
                    item.audience_id && (item.id = item.audience_id);
                    delete item.audience_id;
                    return item;
                })) ||
            [];
        let versionCreativeId = uuidUtils.uuid();
        let versionStartId = uuidUtils.uuid();
        let versionStopId = versionStartId;
        let versionSessionId = versionStartId;
        let versionScheduleId = uuidUtils.uuid();
        const versionsArr = versions.map(version => {
            // const { id, name, draft, rate, isSync, controlGroup, config, terminals } = version;
            const { id, name, screenshot, status, rate, isSync, controlGroup, displayConfig, terminals } = version;

            abtestType === 'creative' && (versionCreativeId = uuidUtils.uuid());
            if (abtestType === 'start') {
                versionStartId = uuidUtils.uuid();
                versionStopId = versionStartId;
                versionSessionId = versionStartId;
            }

            abtestType === 'schedule' && (versionScheduleId = uuidUtils.uuid());

            // creative
            if (abtestType === 'creative' || creative.length === 0) {
                creative.push(
                    this.formatEngageCreative(terminals, versionCreativeId, hasRemoveBrandingRule, engageId, id)
                );
            }

            // start
            //fix: 线上config.when 不为数组的情况，也不知道是哪个版本搞出来的。。。
            let onPage = displayConfig && displayConfig.when;
            if (onPage && !onPage instanceof Array) {
                onPage = [onPage];
            }
            (abtestType === 'start' || start.length === 0) &&
                displayConfig &&
                start.push({
                    startId: versionStartId,
                    versionMeta: displayConfig.meta.start,
                    versionData: displayConfig.start,
                    onPage: onPage
                });
            //session
            const defaultSessionMeta = {
                userBehaviourContent: { children: [], operator: 'and' },
                visitPropContent: { children: [], operator: 'and' }
            };
            (abtestType === 'start' || session.length === 0) &&
                displayConfig &&
                session.push({
                    sessionId: versionSessionId,
                    versionMeta: displayConfig.meta.session || defaultSessionMeta,
                    versionData: displayConfig.session || []
                });
            // stop
            (abtestType === 'start' || stop.length === 0) &&
                displayConfig &&
                stop.push({
                    stopId: versionStopId,
                    versionMeta: displayConfig.meta.stop,
                    versionData: displayConfig.stop
                });

            // schedule
            (abtestType === 'schedule' || schedule.length === 0) &&
                displayConfig &&
                schedule.push({
                    scheduleId: versionScheduleId,
                    versionMeta: displayConfig.meta.schedule,
                    versionData: displayConfig.schedule,
                    timezone: displayConfig.timezone
                });

            return {
                versionId: id,
                versionName: name,
                versionRate: Number(rate) * 100,
                versionScreenshot: version.screenshot,
                versionIsSync: isSync,
                versionIsControlGroup: controlGroup,
                versionIsDraft: status === '0',
                versionPreviewUrl: (displayConfig && displayConfig.meta && displayConfig.meta.previewUrl) || previewUrl,
                versionCreativeId,
                versionStartId,
                versionSessionId,
                versionStopId,
                versionScheduleId
            };
        });
        return {
            engageId: engageId || engage_id,
            engageIsInline: engageVersion === '3.0',
            engageVersion,
            engageName: engageName || engage_name || name,
            engageStatus: status,
            baseURL,
            hasForm,
            abtestType,
            audiences: audiencesArr,
            creative,
            start,
            session,
            stop,
            schedule,
            goals,
            versions: versionsArr,
            hasVariant,
            firstRunTime,
            lastPauseTime
        };
    },
    formatEngageCreativeV3(terminal, creativeId, engageId, versionId, engageVersion) {
        let creativePopup = [];
        let creativeInline = [];
        let creativeStickybar = [];
        const terminals = terminal
            .map(item => {
                let { id, type, status, terminalId, terminalType, terminalStatus, popup, inline, stickybar } = item;

                /*  let popupIDList = [];
                let stickybarList = [];
                let inlineList = [];
                popup.forEach(p => {
                    popupIDList.push(p.id);
                });

                stickybar.forEach(p => {
                    stickybarList.push(p.id);
                });
                inline.forEach(p => {
                    inlineList.push(p.id);
                });
                creativePopup.concat(popup);
                creativeStickybar.concat(stickybar);
                creativeInline.concat(inline); */
                const currentTerminalType = terminalType || type;
                const config = deviceConfig.find(device => device.id === Number(currentTerminalType));
                return {
                    terminalId: terminalId || id,
                    terminalType: currentTerminalType,
                    terminalStatus: terminalStatus || status,
                    terminalInfo: config,
                    engageId,
                    versionId,
                    popup,
                    stickybar,
                    inline
                };
            })
            .sort((a, b) => a.terminalInfo.order - b.terminalInfo.order);

        return {
            creativeId,
            terminals: terminals || []
        };
    },
    formatEngageCreative(terminal, creativeId, hasRemoveBrandingRule = true, engageId, versionId, engageVersion) {
        let creativePopup = [];
        let creativeInline = [];
        let creativeStickybar = [];
        const terminals = terminal
            .map(item => {
                let {
                    type,
                    codeMode,
                    codeSync,
                    commonSync,
                    status,
                    visualModel,
                    components,
                    widgetSize,
                    widgets,
                    widgetLayout,
                    popup,
                    inline,
                    stickybar
                } = item;
                const widgetMode = codeMode ? 'codeModel' : 'visualModel';
                const widgetNodes = codeMode ? codeSync : (visualModel && visualModel.widgetNodes) || '';
                const config = deviceConfig.find(device => device.id === Number(type));

                if (engageVersion !== '3.0') {
                    if (components) {
                    }
                    //V1版本
                    if ((!visualModel || !visualModel.widgetSettings) && !widgets && components && !codeMode) {
                        commonSync || (commonSync = {});
                        const { width, height, mode } = widgetSize || {
                            width: 'auto',
                            height: 'auto'
                        };
                        const widgetSettings = Object.assign({}, cloneUtils.deep(widgetConfig.settings), {
                            position: commonSync.position,
                            width: mode === 'w' ? 'auto' : width,
                            height: mode === 'h' ? 'auto' : height,
                            backgroundColor: commonSync.backgroundColor,
                            visibleBranding: commonSync.branding
                        });
                        const blocks = components.map(item => getBlockInfoByTemplate(item));
                        visualModel = {
                            widgetBlocks: cloneUtils.deep(blocks),
                            widgetSettings
                        };

                        // 老版本Image类型特殊对象
                        if (components[0].type === 'image') {
                            visualModel.widgetSettings.paddingTop = 0;
                            visualModel.widgetSettings.paddingLeft = 0;
                            visualModel.widgetSettings.visibleShadow = false;
                        }
                    }

                    let widgetArr = widgets || [
                        {
                            widgetId: `pt_${uuidUtils.uuid_8bit()}`,
                            widgetMode,
                            widgetNodes,
                            codeModel: codeSync,
                            visualModel: visualModel
                        }
                    ];
                    hasRemoveBrandingRule ||
                        (widgetArr = widgetArr.map(item => {
                            const { visualModel } = item;
                            visualModel &&
                                visualModel.widgetSettings &&
                                (visualModel.widgetSettings.visibleBranding = true);
                            return item;
                        }));
                    return {
                        terminalType: type,
                        terminalInfo: config || {},
                        terminalStatus: status ? 1 : 0,
                        engageId,
                        versionId,
                        widgets: widgetArr,
                        widgetLayout
                    };
                } else {
                    let popupIDList = [];
                    let stickybarList = [];
                    let inlineList = [];
                    popup.forEach(p => {
                        popupIDList.push(p.id);
                    });

                    stickybar.forEach(p => {
                        stickybarList.push(p.id);
                    });
                    inline.forEach(p => {
                        inlineList.push(p.id);
                    });
                    creativePopup.concat(popup);
                    creativeStickybar.concat(stickybar);
                    creativeInline.concat(inline);
                    return {
                        terminalType: type,
                        terminalInfo: config || {},
                        terminalStatus: status ? 1 : 0,
                        engageId,
                        versionId,
                        popupIDList,
                        stickybarList,
                        inlineList
                    };
                }
            })
            .sort((a, b) => a.terminalInfo.order - b.terminalInfo.order);

        return {
            creativeId,
            terminals: terminals || [],
            popup: creativePopup,
            inline: creativeInline,
            stickybar: creativeStickybar
        };
    },

    /**
     *  把前端audiences数组组成后台提交的格式 [{usergroupID:23456456765}]
     * @param {*} audiencesArr
     */
    serializeEngageAudience(audiencesArr) {
        if (!audiencesArr || !Array.isArray(audiencesArr)) return;
        const result = [];
        const allUser = audiencesArr.find(item => item.source === 'default');
        if (audiencesArr.length !== 0) {
            audiencesArr.map(item => {
                (item.usergroupID || item.id) && result.push({ usergroupID: item.usergroupID || item.id });
            });
        }

        return result;
    },
    /**
     * 把前端version数据 转换成后台保存的格式 versions:[{id, name, isSync, ...},{}]
     * @param {*} config
     */
    serializeCampaignVersions(config, replaceId = false) {
        const { versions, creative, start, stop, session, schedule } = config;
        const result = [];
        versions.map(function(v) {
            const { versionId, versionName, versionRate, versionIsControlGroup, creativeId } = v;

            const currentCreative = creative.find(item => item.creativeId === creativeId);
            const currentTerminals = (currentCreative && currentCreative.terminals) || [];
            const terminals = currentTerminals.map(t => {
                const { terminalType, terminalStatus, screenshot, popup, inline, stickybar } = t;
                return {
                    type: Number(terminalType),
                    status: terminalStatus,
                    popup,
                    inline,
                    stickybar,
                    screenshot
                };
            });
            result.push({
                id: versionId,
                name: versionName,
                rate: Number(versionRate) / 100,
                status: 1,
                controlGroup: versionIsControlGroup,
                terminals,
                displayConfig: {
                    meta: {
                        start: start[0] && start[0].versionMeta,
                        when: start[0] && start[0].onPage,
                        stop: stop[0] && stop[0].versionMeta,
                        session: session[0] && session[0].versionMeta,
                        schedule: schedule[0] && schedule[0].versionMeta,
                        timezone: schedule[0] && schedule[0].timezone,
                        previewUrl: ''
                    },
                    start: start[0] && start[0].versionData,
                    stop: stop[0] && stop[0].versionData,
                    session: session[0] && session[0].versionData,
                    when: start[0].onPage,
                    timezone: schedule[0] && schedule[0].timezone,
                    schedule: schedule[0] && schedule[0].versionData
                }
            });
        });
        return result || [];
    },
    /**
     * 把前端version数据 转换成后台保存的格式 versions:[{id, name, isSync, ...},{}]
     * @param {*} config
     */
    serializeEngageVersions(config, replaceId = false) {
        const { abtestType, versions, start, stop, session, schedule, creative } = config;
        const result = [];
        versions.map(function(v) {
            const {
                versionId,
                versionName,
                versionIsSync,
                versionIsDraft,
                versionRate,
                versionIsControlGroup,
                versionStartId,
                versionSessionId,
                versionStopId,
                versionCreativeId,
                versionScreenshot,
                versionPreviewUrl
            } = v;
            const currentStart = start && Array.isArray(start) && start.find(item => item.startId === versionStartId);
            const currentStop = stop && Array.isArray(stop) && stop.find(item => item.stopId === versionStopId);
            const currentSession =
                session && Array.isArray(session) && session.find(item => item.sessionId === versionSessionId);
            const currentCreative =
                creative &&
                Array.isArray(creative) &&
                (abtestType === 'start' ? creative[0] : creative.find(item => item.creativeId === versionCreativeId));
            const terminals = (currentCreative && currentCreative.terminals) || [];
            const currentTerminals = terminals.map(tm => {
                const { terminalType, terminalStatus, widgets, widgetLayout } = tm;
                replaceId &&
                    widgets.forEach(widget => {
                        widget.widgetId = `pt_${uuidUtils.uuid_8bit()}`;
                        widget.visualModel &&
                            widget.visualModel.widgetBlocks &&
                            widget.visualModel.widgetBlocks.forEach(block => {
                                block.blockId = `pt_${uuidUtils.uuid_8bit()}`;
                            });
                    });
                return {
                    type: Number(terminalType),
                    status: terminalStatus,
                    widgets: widgets,
                    widgetLayout: widgetLayout
                };
            });
            let onPage = currentStart && currentStart.onPage;
            if (onPage && !onPage instanceof Array) {
                onPage = [onPage];
            }

            result.push({
                id: versionId,
                name: versionName,
                rate: Number(versionRate) / 100,
                status: versionIsDraft ? 0 : 1,
                controlGroup: versionIsControlGroup,
                isSync: versionIsSync,
                screenshot: versionScreenshot,
                displayConfig: {
                    meta: {
                        start: currentStart && currentStart.versionMeta,
                        when: currentStart && currentStart.onPage,
                        stop: currentStop && currentStop.versionMeta,
                        session: currentSession && currentSession.versionMeta,
                        schedule: schedule[0] && schedule[0].versionMeta,
                        timezone: schedule[0] && schedule[0].timezone,
                        previewUrl: versionPreviewUrl
                    },
                    start: currentStart && currentStart.versionData,
                    stop: currentStop && currentStop.versionData,
                    session: currentSession && currentSession.versionData,
                    when: onPage,
                    timezone: schedule[0] && schedule[0].timezone,
                    schedule: schedule[0] && schedule[0].versionData
                },
                terminals: currentTerminals
            });
        });
        return result || [];
    },

    /**
     * 把前端的数据结构转化成最终保存的格式 保存engage时的最终结构
     */
    serializeEngagePostData(engage, userInfo, replaceId = false) {
        const engageClone = cloneUtils.deep(engage);
        const { sid } = store.getters['main/profileInfo'];
        const {
            engageId,
            engageVersion,
            engageName,
            engageStatus,
            hasForm,
            abtestType,
            //  abtestRate,
            audiences,
            versions,
            creative,
            start,
            stop,
            session,
            schedule,
            goals,
            hasVariant
        } = engageClone;

        return {
            sid,
            name: engageName,
            id: engageId,
            engageVersion,
            abtestType,
            hasForm: hasForm,
            goals: goals?.map(item => {
                const { id, goalID } = item;
                return {
                    goalID: goalID || id
                };
            }),
            hasVariant,
            status: Number(engageStatus),
            usergroups: this.serializeEngageAudience(audiences),
            versions: this.serializeEngageVersions(
                {
                    abtestType,
                    versions,
                    creative,
                    start,
                    session,
                    stop,
                    schedule
                },
                replaceId
            )
        };
    },
    /**
     * 把前端的数据结构转化成最终保存的格式 保存engage时的最终结构
     * For engageVersion 3.0
     */
    serializeCampaignPostData(engage, userInfo, replaceId = false) {
        const engageClone = cloneUtils.deep(engage);
        const { sid } = store.getters['main/profileInfo'];
        const {
            engageId,
            engageVersion,
            engageName,
            engageStatus,
            baseURL,
            audiences,
            versions,
            creative,
            start,
            stop,
            session,
            //  conditions,
            schedule,
            goals,
            hasVariant
        } = engageClone;
        let conditions = this.serializeEngageCondition(start, session);
        return {
            sid,
            name: engageName,
            id: engageId,
            baseURL,
            engageVersion,
            hasForm: false,
            goals,
            hasVariant,
            status: Number(engageStatus),
            usergroups: this.serializeEngageAudience(audiences),
            schedule,
            conditions,
            versions: this.serializeCampaignVersions(
                {
                    versions,
                    start,
                    stop,
                    session,
                    schedule,
                    creative
                },
                replaceId
            )
        };
    },
    /**
     * 结构化conditions
     * For engageVersion 3.0
     */
    serializeEngageCondition(start, session) {
        const currentStart = start[0];
        const currentSession = session[0];
        let onPage = currentStart && currentStart.onPage;
        if (onPage && !onPage instanceof Array) {
            onPage = [onPage];
        }
        return [
            {
                when: onPage,
                session: currentSession.versionData
            }
        ];
    },
    /**
     * 重置displayConfig meta中的schedule的信息。
     */
    resetScheduleMeta() {
        return {
            radio1: '1',
            radio2: '1',
            radio3: '1',
            startDate: commonUtils.getTimestamp('start'),
            endDate: commonUtils.getTimestamp('end'),
            time: ['00:00', '23:59'],
            week: [
                {
                    status: true,
                    text: i18n.t('common.week_sun_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_mon_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_tue_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_wed_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_thu_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_fri_short')
                },
                {
                    status: true,
                    text: i18n.t('common.week_sat_short')
                }
            ],
            done: false
        };
    },
    /**
     * 生成widgetNodes
     */
    generateWidgetHtmlAndCss(creative, engageId, versionId) {
        const newCreative = cloneUtils.deep(creative);
        newCreative.forEach(version => {
            version.terminals.forEach(terminal => {
                terminal.widgets &&
                    terminal.widgets.forEach(widget => {
                        const { codeModel, widgetMode, visualModel, widgetNodes } = widget;
                        let widgetCode = codeModel;
                        if (widgetMode === 'visualModel') {
                            visualModel.widgetBlocks.forEach(block => delete block.blockEditorState);
                            const html = widgetServices.getHtml({ widget });
                            const css = widgetServices.getStyle({ widget });
                            const script = widgetServices.getScript({
                                widget,
                                belong: 'normal',
                                engageId,
                                versionId
                            });
                            widgetCode = {
                                html,
                                css,
                                script
                            };
                        } else if (widgetMode === 'layoutMode') {
                            widgetCode = widgetNodes;
                        }
                        widget.widgetNodes = widgetCode;
                    });
            });
        });
        return newCreative;
    },
    /**
     * 计算当前Engage 是否含有form表单
     */
    generateVersionsFormStatus(versions, creative) {
        const terminals = [].concat.apply(
            [],
            creative.map(item => item.terminals)
        );
        const widgets = [].concat.apply(
            [],
            terminals.map(item => item.widgets)
        );
        const hasForm = widgets.some(widget => {
            const { visualModel, codeModel, widgetMode } = widget;
            if (widgetMode === 'codeModel') {
                return codeModel.html && codeModel.html.indexOf('data-engage-block ="form"') !== -1;
            } else if (widgetMode === 'visualMode') {
                return visualModel.widgetBlocks && visualModel.widgetBlocks.some(block => block.blockCode === 'form');
            } else {
                return false;
            }
        });

        return hasForm;
    },
    /**
     * 初始化terminal
     */
    initTerminalsV3() {
        return [
            {
                terminalId: uuidUtils.uuid_16bit(),
                terminalType: 2,
                terminalStatus: 1,
                order: 1,
                popup: [],
                stickybar: [],
                inline: []
            },
            {
                terminalId: uuidUtils.uuid_16bit(),
                terminalType: 1,
                terminalStatus: 1,
                order: 2,
                popup: [],
                stickybar: [],
                inline: []
            }
        ];
    },
    /**
     * 初始化terminal
     */
    initTerminals() {
        return [
            {
                type: 2,
                status: 1,
                popup: [
                    /* {
                        id: uuidUtils.uuid_16bit(),
                        name: 'popup_' + uuidUtils.uuid_8bit(),
                        widgetLayout: '',
                        widgets: [
                            {
                                widgetId: `pt_${uuidUtils.uuid_8bit()}`,
                                widgetSettings: {
                                    position: 'pos-center',
                                    showMask: true
                                },
                                widgetNodes: {
                                    html: '',
                                    css: ''
                                },
                            }
                        ],
                        trigger :[]
                    } */
                ],
                stickybar: [],
                inline: []
            },
            {
                type: 1,
                codeMode: false,
                codeSync: {
                    html: '',
                    css: ''
                },
                status: 1,
                popup: [],
                stickybar: [],
                inline: []
            }
        ];
    }
};
