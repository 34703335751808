import baseConfig from '@/common/configs/base.config';
import regConfigs from '@/common/configs/reg.config';
import urlUtils from '@/common/utils/url.utils';
import cookieUtils from '@/common/utils/cookie.utils';
import localsService from '@/common/services/locals.service';
import analyticsService from '@/common/services/analytics.service';
import authority from '@/common/utils/authority';

/**
 * Clear the current user's local storage information
 */
const clearLocalUserInfo = function() {
    const domain = urlUtils.getDomain(location.host);
    const { ACCOUNT_ID, ACCOUNT_EMAIL, ACCOUNT_TOKEN, ACCOUNT_SIGNUP_SOURCE, SUPER_ACCOUNT } = baseConfig;
    [ACCOUNT_ID, ACCOUNT_EMAIL, ACCOUNT_TOKEN, ACCOUNT_SIGNUP_SOURCE, SUPER_ACCOUNT].forEach(item => {
        cookieUtils.removeItem(item, domain);

        //一并删除子域名下的cookie
        if (cookieUtils.getItem(item)) cookieUtils.removeItem(item);
    });
    [ACCOUNT_TOKEN].forEach(item => localStorage.removeItem(item));
    localsService.removeStorageLanguage();
};

/**
 * Update user information to local
 * @param userInfo
 * @param rememberMe
 */
const updateLocalUserInfo = function(userInfo = {}, rememberMe) {
    const domain = urlUtils.getDomain(location.host);
    const { token, email, id, i18n, signupSource } = userInfo;
    const { ACCOUNT_ID, ACCOUNT_EMAIL, ACCOUNT_TOKEN, ACCOUNT_SIGNUP_SOURCE } = baseConfig;
    [
        {
            name: ACCOUNT_ID,
            value: id
        },
        {
            name: ACCOUNT_EMAIL,
            value: email
        },
        {
            name: ACCOUNT_TOKEN,
            value: token
        },
        {
            name: ACCOUNT_SIGNUP_SOURCE,
            value: signupSource
        }
    ].forEach(item => {
        const { name, value } = item;
        value && cookieUtils.setItem(name, value, domain);
    });

    localStorage.setItem(ACCOUNT_ID, id);
    localStorage.setItem(ACCOUNT_EMAIL, email);
    localStorage.setItem(ACCOUNT_SIGNUP_SOURCE, signupSource);
    if (rememberMe) {
        localStorage.setItem(ACCOUNT_TOKEN, token);
    }

    authority.loginAuthority(userInfo);
    localsService.updateLanguage(i18n);
    analyticsService
        .identifyUser(userInfo)
        // .useCamera(signupSource)
        .track();
};

/**
 * Verify login status
 */
const alreadyLoggedIn = function() {
    const { ACCOUNT_TOKEN } = baseConfig;
    const token = cookieUtils.getItem(ACCOUNT_TOKEN) || localStorage.getItem(ACCOUNT_TOKEN);
    return Boolean(token);
};

/**
 * logout
 */
const logout = function() {
    console.log('ptx logout ---->');
    clearLocalUserInfo();

    if (window.parent !== window) {
        window.parent.postMessage(
            {
                command: 'ExperienceLogout'
            },
            '*'
        );
    } else {
        // Avoid cookie not cleared
        setTimeout(() => {
            location.href = '/app/login';
        }, 50);
    }
};

/**
 * 截取用户名称
 * @param {String} userName
 * @returns {Array}
 */
const getUserIconName = function(userName) {
    let name = [];
    let firstName = null;
    let lastName = null;
    let words = userName.split(/\s+/g).reduce((prev, curr) => {
        if (curr !== '') {
            prev.push(curr);
        }
        return prev;
    }, []);
    let len = words.length;

    if (len === 1) {
        firstName = words.join('').substr(0, 2);
    } else {
        firstName = words[0].substr(0, 1);
        lastName = words[len - 1].substr(0, 1);
    }

    if (firstName) {
        name.push({
            name: firstName,
            isWord: regConfigs.isWord.test(firstName)
        });
    }
    if (lastName) {
        name.push({
            name: lastName,
            isWord: regConfigs.isWord.test(lastName)
        });
    }
    return name;
};

export default {
    alreadyLoggedIn,
    logout,
    clearLocalUserInfo,
    updateLocalUserInfo,
    getUserIconName
};
