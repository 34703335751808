export default {
    // 空间域名校验（只能包含数字、小写字母、"-"或者"_"，并且不能以"-"、"_"开头和结尾。最多50个字符）
    domainReg: /(^[a-z0-9]{1,50}$)|(^[a-z0-9][a-z0-9-_]{0,48}[a-z0-9]$)/,

    //判断字符串是否为数字(正数、负数、小数)
    isNumber: /^(-|\+)?\d+(\.\d+)?$/,

    // 邮箱验证
    isEmail: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,

    // 用户名截取(中文和日文需要增加特殊样式)
    isName: /[\u4e00-\u9fa5]|[\u0800-\u4e00]/,

    // 是否为空
    isBlank: /^\s*$/,

    // 热图url
    isHeatmap: /^((https|http|ftp|rtsp|mms)?:\/\/).*(heatmap_share.html#ptengine=).*/,

    //空间邀请、内部注册、dashboard邀请 name 的校验（由于日文，去除了全角判断）
    invitationName: /((?=[\x21-\x7e]|[\u3000-\u303F]|[¥…’“·]+)[^A-Za-z])/g,

    // 是否为字母、数字、下划线
    isWord: /^\w*$/
};
