import { isSingleSpa, sendPostMessage } from '@/common/services/singleSpa.service';

export default {
    UPDATE_ENV_CONFIGS(state, envConfigs) {
        state.envConfigs = envConfigs;
    },

    UPDATE_LOADING_STATE(state, flag) {
        state.routerIsLoading = flag;
    },

    UPDATE_USER_INFO(state, userInfo) {
        const { permissions, permissionsDict } = userInfo || {};
        state.userInfo = userInfo;
        state.permissionList = permissions;
        state.permissionsDict = permissionsDict;
    },

    UPDATE_USER_PROFILES(state, profiles) {
        state.profiles = profiles;
    },

    UPDATE_ACCOUNT_PROFILE(state, accountProfileInfo) {
        state.accountProfileInfo = accountProfileInfo;
    },

    UPDATE_COLLABORATIVE_USER(state, userList) {
        state.profileCollaborativeUser = userList;
    },

    UPDATE_PROFILE_INFO(state, profileInfo) {
        state.profileInfo = profileInfo;
    },

    UPDATE_PACKAGE_INFO(state, packageInfo) {
        state.packageInfo = packageInfo;
    },

    UPDATE_PACKAGE_USAGE(state, { type, count }) {
        if (count !== null && count !== undefined) {
            const usage = state.packageInfo.usage;
            usage && ((usage[type] = count), sendPostMessage('ExperienceUpdatePackageInfo'));
        }
    },

    /**
     * @param {String} key: ['engageCount', 'usergroupCount']
     * @param {String} type: ['add', 'subtract']
     * @param {Number} num
     */
    UPDATE_COUNTS(state, { key, type = 'add', num = 1 }) {
        const { profileInfo } = state || {};
        if (Object.prototype.hasOwnProperty.call(profileInfo, key)) {
            const count = profileInfo[key] || 0;
            profileInfo[key] = type === 'add' ? count + num : count - num;
        }
        state.profileInfo = profileInfo;
    },

    UPDATE_PROFILE_GUIDE_INFO(state, guideInfo) {
        state.profileGuideInfo = guideInfo;
    },

    UPDATE_ASIDE_FOLDED(state, status) {
        state.asideIsFolded = status;
    },

    ADD_PROFILE_GUIDE_INFO(state, { key, val }) {
        state.profileGuideInfo && state.profileGuideInfo.guideList && (state.profileGuideInfo.guideList[key] = val);
    },
    /**
     * 控制权限弹窗显示和隐藏
     */
    SET_AUTH_DIALOG_VISIBLE(state, status) {
        // console.log(status);
        // if (status?.type && isSingleSpa()) {
        //     const currentAuth = authConfig.authTypeList.find(item => item.type === status.type);
        //     sendPostMessage('ExperienceReachLimit', { limitType: status.type, privilege: state.packageInfo.privilege[currentAuth.key] });
        // }
        state.authDialogVisible = status;
    },
    SET_SCROLLBAR(state, scrollbar) {
        state.scrollbar = scrollbar;
    },

    SET_PROFILE_TRACKING_CODE(state, code) {
        state.profileTrackingCode = code;
    },

    SET_HIDE_ASIDE(state, value) {
        state.hideMainMenu = value;
    },

    PUSH_QUERY_TOKEN(state, payload) {
        state.queryToken.push(payload.cancelToken);
    },

    CLEAR_QUERY_TOKEN({ queryToken }) {
        queryToken = [];
    }
};
