import { jStat } from 'jstat';

function NORMDIST(x, mean, sd, cumulative) {
    if (isNaN(x) || isNaN(mean) || isNaN(sd)) return NaN;

    return cumulative ? jStat.normal.cdf(x, mean, sd) : jStat.normal.pdf(x, mean, sd);
}

export default function Calculate(props) {
    const { controlVisitors, controlConversions, variationVisitors, variationConversions } = props;
    const controlConversionRate = controlConversions / controlVisitors;
    const controlStandardError = Math.sqrt((controlConversionRate * (1 - controlConversionRate)) / controlVisitors);
    const variationConversionRate = variationConversions / variationVisitors;
    const variationStandardError = Math.sqrt(
        (variationConversionRate * (1 - variationConversionRate)) / variationVisitors
    );
    const zScore =
        (controlConversionRate - variationConversionRate) /
        Math.sqrt(Math.pow(controlStandardError, 2) + Math.pow(variationStandardError, 2));
    const res = NORMDIST(zScore, 0, 1, true);
    return isNaN(res) ? '50%' : ((res < 0.5 ? 1 - res : res) * 100).toFixed(0) + '%';
}
