import routerServices from './router.services';

import Main from '@/components/main/Main.vue';
import Loading from '@/components/main/Loading.vue';
import Users from '@/components/users/Users.vue';
import UsersReport from '@/components/users/report/UsersReport.vue';
import UsersGroupEmpty from '@/components/users/UsersGroupEmpty.vue';
import Goals from '@/components/goals/Goals.vue';
import GoalsEmpty from '@/components/goals/GoalsEmpty.vue';
import GoalsReport from '@/components/goals/report/GoalsReport.vue';

const DataSettings = () => import('@/components/data-settings/DataSettings.vue');
const EventSettings = () => import('@/components/data-settings/EventSettings.vue');
const PlanUsage = () => import('@/components/plan/PlanUsage');
const Setup = () => import('@/components/setup/Setup.vue');
const CodeVerify = () => import('@/components/setup/CodeVerify.vue');
const CodeOverview = () => import('@/components/setup/CodeOverview.vue');
const SetEvents = () => import('@/components/setup/SetEvents.vue');
const IdentifyUsers = () => import('@/components/setup/IdentifyUsers.vue');
const SetupSuccess = () => import('@/components/setup/SetupSuccess.vue');
const UsersCreate = () => import('@/components/users/setting/UsersCreate.vue');
const UsersSetting = () => import('@/components/users/setting/UsersSetting.vue');
const UsersSettingOverview = () => import('@/components/users/setting/UsersSettingOverview.vue');
const UsersSettingReport = () => import('@/components/users/setting/UsersSettingReport.vue');
const UsersSettingList = () => import('@/components/users/setting/UserList.vue');
const EventPropertySettings = () => import('@/components/data-settings/EventPropertySettings.vue');
const ConversionSettings = () => import('@/components/data-settings/ConversionSettings.vue');
const UserPropertySettings = () => import('@/components/data-settings/UserPropertySettings.vue');
const GoalsCreate = () => import('@/components/goals/setting/GoalsCreate.vue');

import permissionsConfig from '@/common/configs/permissions.config';

export default [
    {
        path: 'loading',
        name: 'Loading',
        component: Loading,
        meta: {
            isWhiteList: true
        }
    },
    {
        path: ':sid',
        name: 'Main',
        component: Main,
        beforeEnter: routerServices.beforeEnterMain,
        redirect: { name: 'CampaignReport' },
        children: [
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    titleI18n: 'users.page_title',
                    permissions: [permissionsConfig.asideMenuUserGroup]
                },
                children: [
                    {
                        path: 'empty',
                        name: 'UsersGroupEmpty',
                        component: UsersGroupEmpty,
                        meta: {
                            titleI18n: 'users.page_title'
                        }
                    },
                    {
                        path: 'report',
                        name: 'UsersReport',
                        component: UsersReport,
                        meta: {
                            titleI18n: 'users.page_title'
                        }
                    },
                    {
                        path: 'create',
                        name: 'UsersCreate',
                        component: UsersCreate,
                        meta: {
                            titleI18n: 'users.page_title',
                            permissions: [permissionsConfig.usersCreate]
                        }
                    },
                    {
                        path: 'edit/:usersId',
                        name: 'UserEdit',
                        component: UsersCreate
                    },
                    {
                        path: ':usersId',
                        name: 'UsersSetting',
                        component: UsersSetting,
                        redirect: { name: 'UsersSettingOverview' },
                        children: [
                            {
                                path: 'overview',
                                name: 'UsersSettingOverview',
                                component: UsersSettingOverview,
                                meta: {
                                    titleI18n: 'users.overview_title',
                                    hideMainMenu: function(currRoute) {
                                        const { usersId } = currRoute.params;
                                        return usersId && usersId === 'create';
                                    }
                                }
                            },
                            {
                                path: 'report',
                                name: 'UsersSettingReport',
                                component: UsersSettingReport,
                                meta: {
                                    titleI18n: 'users.behavior_report_title',
                                    hideMainMenu: function(currRoute) {
                                        const { usersId } = currRoute.params;
                                        return usersId && usersId === 'create';
                                    }
                                }
                            },
                            {
                                path: 'list',
                                name: 'UsersSettingList',
                                component: UsersSettingList,
                                meta: {
                                    titleI18n: 'users.users_list_title',
                                    hideMainMenu: function(currRoute) {
                                        const { usersId } = currRoute.params;
                                        return usersId && usersId === 'create';
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'goals',
                name: 'Goals',
                component: Goals,
                meta: {
                    titleI18n: 'goals.page_title'
                },
                redirect: { name: 'GoalsEmpty' },
                children: [
                    {
                        path: 'empty',
                        name: 'GoalsEmpty',
                        component: GoalsEmpty,
                        beforeEnter: routerServices.beforeEnterGoals,
                        meta: {
                            titleI18n: 'goals.page_title'
                        }
                    },
                    {
                        path: 'report',
                        name: 'GoalsReport',
                        component: GoalsReport,
                        meta: {
                            titleI18n: 'goals.page_title'
                        }
                    },
                    {
                        path: 'create',
                        name: 'GoalsCreate',
                        component: GoalsCreate,
                        meta: {
                            titleI18n: 'goals.page_title'
                        }
                    },
                    {
                        path: 'edit/:goalId',
                        name: 'GoalEdit',
                        component: GoalsCreate,
                        meta: {
                            titleI18n: 'users.page_title'
                        }
                    }
                ]
            },
            {
                path: 'data',
                name: 'DataSettings',
                component: DataSettings,
                beforeEnter: routerServices.beforeEnterDataSettings,
                redirect: { name: 'EventSettings' },
                meta: {
                    titleI18n: 'menu.data_settings',
                    permissions: [permissionsConfig.asideMenuSettings]
                },
                children: [
                    {
                        path: 'event',
                        name: 'EventSettings',
                        component: EventSettings,
                        meta: {
                            parentRouter: 'DataSettings',
                            titleI18n: 'data_settings.event_title'
                        }
                    },
                    {
                        path: 'event_property',
                        name: 'EventPropertySettings',
                        component: EventPropertySettings,
                        meta: {
                            parentRouter: 'DataSettings',
                            titleI18n: 'data_settings.event_property_title'
                        }
                    },
                    {
                        path: 'conversion',
                        name: 'ConversionSettings',
                        component: ConversionSettings,
                        meta: {
                            parentRouter: 'DataSettings',
                            titleI18n: 'menu.data_settings'
                        }
                    },
                    {
                        path: 'user_property',
                        name: 'UserPropertySettings',
                        component: UserPropertySettings,
                        meta: {
                            parentRouter: 'DataSettings',
                            titleI18n: 'data_settings.user_property_title'
                        }
                    }
                ]
            },
            {
                path: 'plan',
                name: 'PlanUsage',
                component: PlanUsage,
                meta: {
                    titleI18n: 'plan.page_title'
                }
            },
            {
                path: 'setup',
                name: 'Setup',
                component: Setup,
                meta: {
                    titleI18n: 'setup.page_title',
                    hideMainMenu: function(currRoute) {
                        const { register } = currRoute.query;
                        return Boolean(register);
                    }
                },
                children: [
                    {
                        path: 'overview',
                        name: 'CodeOverview',
                        component: CodeOverview,
                        meta: {
                            parentRouter: 'Setup',
                            titleI18n: 'setup.page_title',
                            pageStyle: {
                                'background-color': '#fff'
                            },
                            hideMainMenu: function() {
                                return true;
                            }
                        }
                    },
                    {
                        path: 'setEvents',
                        name: 'SetEvents',
                        component: SetEvents,
                        meta: {
                            parentRouter: 'Setup',
                            titleI18n: 'setup.page_title',
                            pageStyle: {
                                'background-color': '#fff'
                            },
                            hideMainMenu: function() {
                                return true;
                            }
                        }
                    },
                    {
                        path: 'identifyUsers',
                        name: 'IdentifyUsers',
                        component: IdentifyUsers,
                        meta: {
                            parentRouter: 'Setup',
                            titleI18n: 'setup.page_title',
                            titleI18n: 'setup.page_title',
                            pageStyle: {
                                'background-color': '#fff'
                            },
                            hideMainMenu: function() {
                                return true;
                            }
                        }
                    },
                    {
                        path: 'verify',
                        name: 'CodeVerify',
                        component: CodeVerify,
                        meta: {
                            parentRouter: 'Setup',
                            titleI18n: 'setup.page_title',
                            pageStyle: {
                                'background-color': '#fff'
                            },
                            hideMainMenu: function() {
                                return true;
                            }
                        }
                    },
                    {
                        path: 'success',
                        name: 'SetupSuccess',
                        component: SetupSuccess,
                        meta: {
                            parentRouter: 'Setup',
                            titleI18n: 'setup.page_title',
                            hideMainMenu: function() {
                                return true;
                            }
                        }
                    }
                ]
            }
        ]
    }
];
